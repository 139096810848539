import React, { useRef, useState } from "react";
import CommonInputJd from "./CommonInputJd";
import { StyledFlexColumnView } from "../../../common-components/StyledComponents";
import {
  Autocomplete,
  Grid2,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CalenderIco from "../../../../assets/icons/CalenderIco";
import CommonTextField from "../../../../component/Input";
import ChevronDownIco from "../../../../assets/icons/ChevronDownIco";

function InputJobData({ inputJdView }) {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const handleClear = () => {
    setFileName(""); // Clear the file name
  };
  return (
    <>
      {inputJdView === 1 ? (
        <CommonInputJd
          title={"Enrich Job Post with Job Code/VMS ID"}
          buttonName={"Import Job Post"}
        >
          <StyledFlexColumnView>
            <Typography
              sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
            >
              {"Job Code/VMS ID"}
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter Job Code/VMS ID"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #D0D5DD",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#667085",
                  opacity: 1,
                  fontSize: "16px",
                  fontWeight: 400,
                },
              }}
            />
          </StyledFlexColumnView>
        </CommonInputJd>
      ) : null}

      {inputJdView === 2 ? (
        <CommonInputJd
          title={"Create Job Post with Talairo Helix"}
          buttonName={"Generate Job Post"}
        >
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <StyledFlexColumnView>
                <Typography
                  sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                >
                  {"Domain/Industry"}
                </Typography>
                <Autocomplete
                  disablePortal
                  options={[]}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "5px",
                      border: "1px solid #D0D5DD",
                      alignItems: "center",
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#667085",
                      opacity: 1,
                      fontSize: "16px",
                      fontWeight: 400,
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="Select a Domain/Industry"
                      variant="outlined"
                    />
                  )}
                  popupIcon={
                    <IconButton sx={{ padding: 0 }}>
                      <ChevronDownIco />
                    </IconButton>
                  }
                />
              </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={12}>
              <StyledFlexColumnView>
                <Typography
                  sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                >
                  {"Location"}
                </Typography>
                <Autocomplete
                  disablePortal
                  options={[]}
                  // value={"Technoloy"}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "5px",
                      border: "1px solid #D0D5DD",
                      alignItems: "center",
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#667085",
                      opacity: 1,
                      fontSize: "16px",
                      fontWeight: 400,
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="Select a Location"
                      variant="outlined"
                    />
                  )}
                  popupIcon={
                    <IconButton sx={{ padding: 0 }}>
                      <ChevronDownIco />
                    </IconButton>
                  }
                />
              </StyledFlexColumnView>
            </Grid2>

            <Grid2 size={12}>
              <StyledFlexColumnView>
                <Typography
                  sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                >
                  {"Expected Start Date"}
                </Typography>
                <TextField
                  readOnly
                  //   type="date"
                  fullWidth
                  placeholder="Select a Start Date"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #D0D5DD",
                      // padding: "0px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "12px",
                      borderRight: "1px solid #D0D5DD",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #D0D5DD",
                    },
                    "& input:read-only": {
                      borderRight: "1px solid #D0D5DD",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#667085",
                      opacity: 1,
                      fontSize: "16px",
                      fontWeight: 400,
                    },
                  }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ margin: "10px 1px 10px 14px" }}
                        >
                          <CalenderIco />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </StyledFlexColumnView>
            </Grid2>

            <Grid2 size={12}>
              <StyledFlexColumnView>
                <Typography
                  sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                >
                  {"Job Description"}
                </Typography>
                <TextField
                  multiline
                  rows={5}
                  fullWidth
                  placeholder="Enter Job Description"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #D0D5DD",
                      // padding: "0px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#667085",
                      opacity: 1,
                      fontSize: "16px",
                      fontWeight: 400,
                    },
                    //   "& .MuiInputBase-input": {
                    //     padding: "12px",
                    //   },
                  }}
                />
              </StyledFlexColumnView>
            </Grid2>
          </Grid2>
        </CommonInputJd>
      ) : null}

      {inputJdView === 3 ? (
        <CommonInputJd
          title={"Generate Job Post"}
          buttonName={"Generate Job Post"}
        >
          <StyledFlexColumnView>
            <Typography
              sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
            >
              {"Job Title"}
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter Job Title"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #D0D5DD",
                },
                "& .MuiInputBase-input": {
                  padding: "12px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#667085",
                  opacity: 1,
                  fontSize: "16px",
                  fontWeight: 400,
                },
              }}
            />
          </StyledFlexColumnView>
        </CommonInputJd>
      ) : null}

      {inputJdView === 4 || inputJdView === 5 ? (
        <CommonInputJd
          title={"Create Job Post using Resume"}
          buttonName={"Convert Resume into Job Post"}
        >
          <StyledFlexColumnView>
            <Typography
              sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
            >
              {"Resume"}
            </Typography>
            <CommonTextField
              readOnly={true}
              handleFileChange={handleFileChange}
              value={fileName}
              padding="10px"
              placeholder={"Choose a file"}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  color: "#667085",
                  opacity: 1,
                  fontSize: "16px",
                  fontWeight: 400,
                },
              }}
            />
            {/* <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #D0D5DD",
                },
                "& input.Mui-readOnly": {
                  borderRight: "1px solid #D0D5DD",
                },
              }}
              value={fileName}
              placeholder={"Choose a file"}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledFlexRowView>
                      {fileName && (
                        <div
                          style={{
                            borderRight: "1px solid #D0D5DD",
                            marginRight: "4px",
                          }}
                        >
                          <IconButton
                            onClick={handleClear}
                            sx={{
                              // padding: "5px",
                              color: "#D0D5DD",
                              marginRight: "4px",
                            }}
                            edge="end"
                          >
                            <Clear />
                          </IconButton>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="" 
                        style={{ display: "none" }}
                        id="upload-button"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="upload-button">
                        <Button
                          variant="text"
                          component="span"
                          startIcon={<FileUploadOutlined />}
                          sx={{
                            fontSize: "inherit",
                            color: "#475467",
                            padding: "5px",
                          }}
                        >
                          Choose File
                        </Button>
                      </label>
                    </StyledFlexRowView>
                  </InputAdornment>
                ),
              }}
            /> */}
          </StyledFlexColumnView>
        </CommonInputJd>
      ) : null}
    </>
  );
}

export default InputJobData;
