import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DotsGrid from "../../../assets/icons/DotsGrid";
import JobPencilIco from "../../../assets/icons/JobPencilIco";
import JobPenIco from "../../../assets/icons/JobPenIco";
import JobStarIco from "../../../assets/icons/JobStarIco";
import CustomLinearProgress from "../../../component/Linearprogress";
import { getColor, HandleSkeletion } from "../../../utils/constants";
import {
  CommonGrid2,
  DotColor,
  StatusChange,
  StyledFlexRowView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  StyledTypoGrey,
  TableContainerBorder,
} from "../../common-components/StyledComponents";

function RelevancyTable({
  isLoading,
  resultHeaderData,
  onDragEnd,
  dragContent,
  alignment,
  iconVisible = false,
}) {
  const { setFieldValue, values } = useFormikContext();

  return (
    <Field name={"relevancy_index"}>
      {({ field, form }) => {
        return (
          <TableContainerBorder
            marginTop={"0px"}
            scrollStyle={true}
            height={
              isLoading ? "auto" : dragContent?.length >= 6 ? "340px" : "auto"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {resultHeaderData.map((item, index) => (
                    <StyledTableTitleTableCell>
                      {item?.name}
                      {item?.icon}
                    </StyledTableTitleTableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <TableRow>
                      {Array.from({ length: 4 }).map((_, index) => (
                        <TableCell key={index}>
                          <HandleSkeletion height={30} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <DragDropContext
                  onDragEnd={(result) =>
                    onDragEnd(result, values, setFieldValue)
                  }
                >
                  <Droppable droppableId="droppable-job-candidates">
                    {(provided) => (
                      <TableBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {dragContent &&
                          dragContent?.length !== 0 &&
                          dragContent.map((candidate, index) => (
                            <Draggable
                              key={`candidate-${index}`}
                              draggableId={`candidate-${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <TableRow
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TableCell style={{ width: "50vw" }}>
                                    <CommonGrid2
                                      display={"flex"}
                                      gap={"10px"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        fontSize={"15px"}
                                        fontWeight={500}
                                        color={"#101828"}
                                        fontFamily={"Inter"}
                                      >
                                        {candidate.skill}
                                      </Typography>
                                      {iconVisible ? (
                                        candidate?.type ===
                                          "relevant_hard_skill" ||
                                        candidate?.type ===
                                          "relevant_soft_skill" ? (
                                          <JobStarIco />
                                        ) : candidate?.type ===
                                            "extracted_hard_skill" ||
                                          candidate?.type ===
                                            "extracted_soft_skill" ? (
                                          <JobPencilIco />
                                        ) : (
                                          <JobPenIco />
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </CommonGrid2>
                                  </TableCell>
                                  <TableCell style={{ width: "20vw" }}>
                                    <StatusChange>
                                      <DotColor
                                        color={getColor(
                                          candidate.relevancy_score
                                        )}
                                      />
                                      {candidate.skill_availability_status}
                                    </StatusChange>
                                  </TableCell>
                                  <TableCell style={{ width: "20vw" }}>
                                    <StyledFlexRowView
                                      sx={{ alignItems: "center" }}
                                    >
                                      <CustomLinearProgress
                                        variant="determinate"
                                        value={candidate.relevancy_score}
                                        height={"10px"}
                                        borderRadius={"5px"}
                                        barbackgroundColor={getColor(
                                          candidate.relevancy_score
                                        )}
                                      />

                                      <StyledTypoGrey>{`${candidate.relevancy_score}%`}</StyledTypoGrey>
                                    </StyledFlexRowView>
                                  </TableCell>
                                  <StyledTableBodyTableCell
                                    textAlign={"center"}
                                    style={{ width: "10vw" }}
                                  >
                                    <IconButton
                                      {...provided.dragHandleProps}
                                      disabled={
                                        alignment === "View" ? true : false
                                      }
                                      style={{
                                        padding: "0px",
                                        opacity: alignment === "View" ? 0.3 : 1,
                                      }}
                                    >
                                      <DotsGrid />
                                    </IconButton>
                                  </StyledTableBodyTableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Table>
          </TableContainerBorder>
        );
      }}
    </Field>
  );
}

export default RelevancyTable;
