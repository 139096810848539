import React, { useState } from "react";
import SnackBar from "../../../../component/SnackBar";
import { useFormik } from "formik";
import TalairoLogo from "../../../../assets/icons/TalairoLogo";
import CommonButton from "../../../../component/Button";
import { Box, Typography, useTheme } from "@mui/material";
import { CustomDiv } from "../../../chat-bot/style";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import CommonTextField from "../../../../component/Input";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ForgotPasswordCall } from "../../../../redux/action/Login";

export default function ForgotPassword() {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState({
    disabled: false,
    loading: false,
  });
  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values, form) => {
      try {
        setButtonLoading({ disabled: true, loading: true });
        let payload = {
          field: values.email,
        };
        const data = await ForgotPasswordCall(payload);
        setSnackbarState({
          open: true,
          severity: "success",
          message: data.message,
        });
        setButtonLoading({ disabled: true, loading: false });
      } catch (error) {
        setButtonLoading({ disabled: false, loading: false });
        setSnackbarState({
          open: true,
          severity: "error",
          message:
            error?.data?.error || error?.message || "Something went wrong",
        });
      }
    },
  });

  return (
    <CustomDiv width="100%">
      <form onSubmit={formik.handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          gap="1.2rem"
          sx={{
            [theme.breakpoints.down("sm")]: {
              padding: "15px",
            },
            [theme.breakpoints.up("sm")]: {
              padding: "40px 40px 20px 40px",
            },
          }}
        >
          <TalairoLogo height="40px" />

          <Typography
            textAlign="center"
            fontWeight={700}
            fontSize="30px"
            color="#223548"
          >
            Forgot Your Password?
          </Typography>
          <Typography textAlign="center" color="#5B738B">
            Enter your email address and we will send you instructions to reset
            your password
          </Typography>

          <CommonGrid2
            display={"flex"}
            flexDirection={"column"}
            gap={"0.4rem"}
            marginTop={"1rem"}
          >
            <Typography fontWeight={600} color="#5B738B">
              Email ID
            </Typography>
            <CommonTextField
              placeholder={"Enter Email address"}
              padding={"10px"}
              backgroundColor={"#EBF1F4"}
              placeholderFontSize={"14px"}
              border={"none"}
              borderRadius={"5px"}
              error={formik.touched["email"] && Boolean(formik.errors["email"])}
              value={formik.values["email"]}
              helperText={formik.touched["email"] && formik.errors["email"]}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              color={"#8c8d8e"}
            />
          </CommonGrid2>
          <CommonButton
            marginTop={"0.5rem"}
            type="submit"
            value="Continue"
            padding="10px 16px"
            borderRadius="6px"
            fontSize="15px"
            loading={buttonLoading.loading}
            disabled={buttonLoading.disabled}
          />
          <CommonGrid2 textAlign={"center"} marginTop={"0.2rem"}>
            <Typography
              variant="h6"
              color="primary"
              fontWeight={600}
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/auth/login")}
            >
              Back to the platform
            </Typography>
          </CommonGrid2>
        </Box>
      </form>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </CustomDiv>
  );
}
