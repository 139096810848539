import {
    Button,
    Grid2,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import { StyledFlexColumnView } from "../../common-components/StyledComponents";
import ChangePassword from "./ChangePassword";
import ChangeUsername from "./ChangeUsername";
import ResetPassword from "./ResetPassword";

function General() {
    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [openUserNameDialog, setOpenUserNameDialog] = useState(false);

    const handleChangePassword = () => {
        setOpenPasswordDialog(true);
    };
    const handleClosePasswordDialog = () => {
        setOpenPasswordDialog(false);
    };

    const handleChangeReset = () => {
        setOpenPasswordDialog(false);
        setOpenResetDialog(true)
    };
    const handleCloseResetDialog = () => {
        setOpenResetDialog(false);
    };
    const handleChangeUserName = () => {
        setOpenUserNameDialog(true)
    };
    const handleCloseUserNameDialog = () => {
        setOpenUserNameDialog(false);
    };

    return (
        <Grid2 container spacing={3}>
            <>
                <Grid2 size={6}>
                    <Grid2 container spacing={3}>
                        <Grid2 size={8}>
                            <StyledFlexColumnView>
                                <Typography
                                    sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                                >
                                    {"Username"}
                                </Typography>
                                <TextField
                                    fullWidth
                                    value="Zahir"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            backgroundColor: "#FFFFFF",
                                            border: "1px solid #D0D5DD",
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "12px",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            color: "#667085",
                                            opacity: 1,
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        },
                                    }}
                                />
                            </StyledFlexColumnView>
                        </Grid2>
                        <Grid2 size={4}>
                            <div style={{ paddingTop: "30px" }}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        color: "#344054",
                                        border: "1px solid #D0D5DD",
                                        fontWeight: "600",
                                        "&:hover": {
                                            backgroundColor: "rgb(127, 86, 217)",
                                            color: "#fff",
                                        },
                                    }}
                                    onClick={handleChangeUserName}
                                >
                                    {"Change Username"}
                                </Button>
                            </div>
                        </Grid2>
                    </Grid2>
                </Grid2>

                <Grid2 size={4}>
                    <StyledFlexColumnView>
                        <Typography
                            sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                        >
                            {"Password"}
                        </Typography>
                        <TextField
                            fullWidth
                            value="+1000000000"
                            variant="outlined"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    backgroundColor: "#FFFFFF",
                                    border: "1px solid #D0D5DD",
                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                },
                                "& .MuiInputBase-input::placeholder": {
                                    color: "#667085",
                                    opacity: 1,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                },
                            }}
                        />
                    </StyledFlexColumnView>
                </Grid2>
                <Grid2 size={2}>
                    <div style={{ paddingTop: "30px" }}>
                        <Button
                            variant="outlined"
                            fullWidth
                            sx={{
                                color: "#344054",
                                border: "1px solid #D0D5DD",
                                fontWeight: "600",
                                "&:hover": {
                                    backgroundColor: "rgb(127, 86, 217)",
                                    color: "#fff",
                                },
                            }}
                            onClick={handleChangePassword}

                        >{"Change Password"}</Button>
                    </div>
                </Grid2>
            </>
            <ChangePassword
                open={openPasswordDialog}
                onClose={handleClosePasswordDialog}
                onApply={() => {
                    handleClosePasswordDialog();
                }}
                onReset={handleChangeReset}
            />
            <ResetPassword
                open={openResetDialog}
                onClose={handleCloseResetDialog}
                onApply={() => {
                    console.log("Username Changed");
                    handleCloseResetDialog();
                }}
            />
            <ChangeUsername
                open={openUserNameDialog}
                onClose={handleCloseUserNameDialog}
                onApply={() => {
                    handleCloseUserNameDialog();
                }}
            />
        </Grid2 >
    );
}

export default General;
