export default function WarningIco() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="#FEF0C7"
      />
      <g clip-path="url(#clip0_514_19007)">
        <path
          d="M11.9987 9.33398V12.0007M11.9987 14.6673H12.0054M18.6654 12.0007C18.6654 15.6826 15.6806 18.6673 11.9987 18.6673C8.3168 18.6673 5.33203 15.6826 5.33203 12.0007C5.33203 8.31875 8.3168 5.33398 11.9987 5.33398C15.6806 5.33398 18.6654 8.31875 18.6654 12.0007Z"
          stroke="#DC6803"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_514_19007">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
