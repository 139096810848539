import {
    Autocomplete,
    Grid2,
    IconButton,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import ChevronDownIco from "../../../../assets/icons/ChevronDownIco";
import CommonTextField from "../../../../component/Input";
import { CommonGrid2, StyledFlexColumnView } from "../../../common-components/StyledComponents";

function PrimaryInformation() {
    const [selectedDate, setSelectedDate] = useState(null);
    const [openDatePicker, setOpenDatePicker] = useState(false);

    const handleDateSelect = () => {
        setOpenDatePicker(true);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setOpenDatePicker(false); // Close the date picker after selection
    };
    const primaryLocation = [
        { label: "San Francisco, CA", flag: "🇺🇸" },
        { label: "New York, NY", flag: "🇺🇸" },
        { label: "Toronto, ON", flag: "🇨🇦" },
    ];
    const clientLocation = [
        { label: "Las Vegas, TX", flag: "🇺🇸" },

    ];

    return (
        <Grid2 container spacing={3}>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"First Name"}
                    </Typography>
                    <TextField
                        fullWidth
                        // defaultValue={"Zahir"}
                        value={"Zahir"}
                        // placeholder="Enter Email"
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Middle Name"}
                    </Typography>
                    <TextField
                        fullWidth
                        // defaultValue={"Zahir"}
                        // value={"Zahir"}
                        placeholder="Enter your middle name"
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Last Name"}
                    </Typography>
                    <TextField
                        fullWidth
                        // defaultValue={"Zahir"}
                        value={"Mays"}
                        // placeholder="Enter Email"
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Avatar"}
                    </Typography>
                    <CommonTextField
                        readOnly={true}
                        padding="10px"
                        // placeholder={"Attach Resume"}
                        sx={{
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Title"}
                    </Typography>
                    <TextField
                        fullWidth
                        // defaultValue={"Zahir"}
                        value={"Product Designer"}
                        // placeholder="Enter Email"
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Seniority Level"}
                    </Typography>
                    <Autocomplete
                        disablePortal
                        options={["Senior"]}
                        defaultValue={["Senior"]}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "5px",
                                border: "1px solid #D0D5DD",
                                alignItems: "center",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                // placeholder="Select Tax Term"
                                variant="outlined"
                            />
                        )}
                        popupIcon={
                            <IconButton sx={{ padding: 0 }}>
                                <ChevronDownIco />
                            </IconButton>
                        }
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={2}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Minimal Hourly Rate"}
                    </Typography>
                    <TextField
                        fullWidth
                        // defaultValue={"Zahir"}
                        value={"$100.00"}
                        // placeholder="Enter Email"
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={2}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Maximal Hourly Rate"}
                    </Typography>
                    <TextField
                        fullWidth
                        // defaultValue={"Zahir"}
                        value={"$120.00"}
                        // placeholder="Enter Email"
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#344054",
                            marginBottom: "8px",
                        }}
                    >
                        {"Primary Location"}
                    </Typography>
                    <Autocomplete
                        disablePortal
                        options={primaryLocation}
                        getOptionLabel={(option) => option.label}
                        defaultValue={primaryLocation[0]}
                        renderOption={(props, option) => (
                            <li {...props} style={{ display: "flex", alignItems: "center" }}>
                                <span
                                    style={{
                                        marginRight: "8px",
                                        fontSize: "18px",
                                        borderRadius: "50%",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "24px",
                                        height: "24px",
                                        backgroundColor: "#f5f5f5",
                                    }}
                                >
                                    {option.flag}
                                </span>
                                {option.label}
                            </li>
                        )}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "5px",
                                border: "1px solid #D0D5DD",
                                alignItems: "center",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            <span
                                                style={{
                                                    marginRight: "8px",
                                                    fontSize: "18px",
                                                    borderRadius: "50%",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "24px",
                                                    height: "24px",
                                                    backgroundColor: "#f5f5f5", // Optional background
                                                }}
                                            >
                                                {primaryLocation[0].flag}
                                            </span>
                                            {params.InputProps.startAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        popupIcon={
                            <IconButton sx={{ padding: 0 }}>
                                <ChevronDownIco />
                            </IconButton>
                        }
                    />
                </div>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Day and Month of Birth"}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="date"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 item xs={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#344054",
                            marginBottom: "8px",
                        }}
                    >
                        {"Portfolio"}
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {/* Base URL */}
                        <TextField
                            fullWidth
                            value={"http://"}
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                flex: 0.3,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px 0 0 8px", // Left-rounded only
                                    // backgroundColor: "#F9FAFB",
                                    // border: "1px solid #D0D5DD",
                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "#667085",
                                },
                            }}
                        />
                        {/* Editable Username */}
                        <TextField
                            fullWidth
                            value={"zahirmays"}
                            variant="outlined"
                            sx={{
                                flex: 1,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "0 8px 8px 0", // Right-rounded only
                                    // border: "1px solid #D0D5DD",
                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                },
                            }}
                        />
                    </div>
                </div>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Primary Email Address"}
                    </Typography>
                    <TextField
                        fullWidth
                        value="hello@zahirmays.fyi"
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Primary Mobile Phone"}
                    </Typography>
                    <TextField
                        fullWidth
                        // value="Apple"
                        placeholder="Enter your primary mobile phone number"
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#344054",
                            marginBottom: "8px",
                        }}
                    >
                        {"Client / Job Location"}
                    </Typography>
                    <Autocomplete
                        disablePortal
                        options={clientLocation}
                        getOptionLabel={(option) => option.label}
                        defaultValue={clientLocation[0]}
                        renderOption={(props, option) => (
                            <li {...props} style={{ display: "flex", alignItems: "center" }}>
                                <span
                                    style={{
                                        marginRight: "8px",
                                        fontSize: "18px",
                                        borderRadius: "50%",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "24px",
                                        height: "24px",
                                        backgroundColor: "#f5f5f5",
                                    }}
                                >
                                    {option.flag}
                                </span>
                                {option.label}
                            </li>
                        )}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "5px",
                                border: "1px solid #D0D5DD",
                                alignItems: "center",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            <span
                                                style={{
                                                    marginRight: "8px",
                                                    fontSize: "18px",
                                                    borderRadius: "50%",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "24px",
                                                    height: "24px",
                                                    backgroundColor: "#f5f5f5", // Optional background
                                                }}
                                            >
                                                {clientLocation[0].flag}
                                            </span>
                                            {params.InputProps.startAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        popupIcon={
                            <IconButton sx={{ padding: 0 }}>
                                <ChevronDownIco />
                            </IconButton>
                        }
                    />
                </div>
            </Grid2>
            <Grid2 item xs={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#344054",
                            marginBottom: "8px",
                        }}
                    >
                        {"LinkedIn"}
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            fullWidth
                            value={"http://linkedin.com/in/"}
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                flex: 0.7,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px 0 0 8px",

                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "#667085",
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            value={"zahirmays"}
                            variant="outlined"
                            sx={{
                                flex: 1,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "0 8px 8px 0",
                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                },
                            }}
                        />
                    </div>
                </div>
            </Grid2>
            <Grid2 size={4}></Grid2>
            <CommonGrid2 size={4}>
                <CommonGrid2
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        Are you willing to relocate?
                    </Typography>
                    <Switch defaultChecked />
                </CommonGrid2>
            </CommonGrid2>
        </Grid2>
    );
}

export default PrimaryInformation;
