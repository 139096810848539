import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledFlexColumnView } from "../../../common-components/StyledComponents";
import CommonTextField from "../../../../component/Input";
import XCloseIco from "../../../../assets/icons/XCloseIco";
import CommonAccordion from "../../../common-components/CommonAccordion";

function DistributionList() {
  return (
    <Grid2 container spacing={3}>
      <Grid2 size={6}>
        <CommonAccordion title={"Distribution List"}>
          <StyledFlexColumnView>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Preferred Agencies"
                sx={{ fontSize: "14px", fontWeight: 500, color: "#344054" }}
              />
              <FormControlLabel
                control={<Checkbox />}
                sx={{ fontSize: "14px", fontWeight: 500, color: "#344054" }}
                label="Internal Recruiters"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="All"
                sx={{ fontSize: "14px", fontWeight: 500, color: "#344054" }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Custom / Other"
                sx={{ fontSize: "14px", fontWeight: 500, color: "#344054" }}
              />
            </FormGroup>

            <Autocomplete
              multiple
              disablePortal
              options={["john@mail.com", "david@mail.com"]}
              defaultValue={["john@mail.com", "david@mail.com"]}
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  padding: "5px",
                  border: "1px solid #D0D5DD",
                  alignItems: "flex-start",
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                },
                "& .MuiAutocomplete-tag": {
                  marginBottom: "5px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#667085",
                  opacity: 1,
                  fontSize: "16px",
                  fontWeight: 400,
                },
              }}
              renderInput={(params) => (
                <TextField
                  multiline
                  rows={8}
                  {...params}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                />
              )}
              popupIcon={
                <IconButton sx={{ padding: 0, display: "none" }}></IconButton>
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    //   sx={{ padding: "1px" }}
                    {...getTagProps({ index })}
                    //   onDelete={() => handleRemove(option)}
                    deleteIcon={
                      <IconButton
                        sx={{
                          padding: "0px",
                          "& > svg": {
                            width: "16px",
                            height: "16px",
                          },
                        }}
                      >
                        <XCloseIco />
                      </IconButton>
                    }
                    sx={{
                      color: "#1E1F21",
                      gap: "12px", // Applies to the spacing between elements inside the Chip
                      borderRadius: "6px",
                      border: "1px solid #D0D5DD",
                      padding: "2px 2px 2px 6px !important",
                      backgroundColor: "white",
                      display: "flex", // Ensure gap works by maintaining flex display
                      alignItems: "center", // Align content centrally if needed
                      "& > svg": {
                        width: "14px",
                        height: "14px",
                      },
                      // marginLeft: "10px",
                    }}
                  />
                ))
              }
            />
          </StyledFlexColumnView>
        </CommonAccordion>
      </Grid2>

      <Grid2 size={6}>
        <CommonAccordion title={"Pre-ID Candidate Info"}>
          <StyledFlexColumnView>
            <StyledFlexColumnView>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
              >
                {"Email"}
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Email"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #D0D5DD",
                  },
                  "& .MuiInputBase-input": {
                    padding: "12px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#667085",
                    opacity: 1,
                    fontSize: "16px",
                    fontWeight: 400,
                  },
                }}
              />
            </StyledFlexColumnView>

            <StyledFlexColumnView>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
              >
                {"Phone"}
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Job Code/VMS ID"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #D0D5DD",
                  },
                  "& .MuiInputBase-input": {
                    padding: "12px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#667085",
                    opacity: 1,
                    fontSize: "16px",
                    fontWeight: 400,
                  },
                }}
              />
            </StyledFlexColumnView>

            <StyledFlexColumnView>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
              >
                {"Preferred Agency"}
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Job Code/VMS ID"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #D0D5DD",
                  },
                  "& .MuiInputBase-input": {
                    padding: "12px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#667085",
                    opacity: 1,
                    fontSize: "16px",
                    fontWeight: 400,
                  },
                }}
              />
            </StyledFlexColumnView>

            <StyledFlexColumnView>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
              >
                {"Candidate Resume"}
              </Typography>
              <CommonTextField
                readOnly={true}
                //   handleFileChange={handleFileChange}
                //   value={fileName}
                padding="10px"
                placeholder={"Attach Resume"}
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    color: "#667085",
                    opacity: 1,
                    fontSize: "16px",
                    fontWeight: 400,
                  },
                }}
              />
            </StyledFlexColumnView>
          </StyledFlexColumnView>
        </CommonAccordion>
      </Grid2>
    </Grid2>
  );
}

export default DistributionList;
