import { combineReducers } from "redux";

import CandidateReducer from "../redux/reducer/CandidateReducer";
import ClientPartnerReducer from "../redux/reducer/ClientPartnerReducer";
import CoverSheetReducer from "../redux/reducer/CoverSheetReducer";
import HarizonReducer from "../redux/reducer/HarizonReducer";
import JobReducer from "../redux/reducer/JobReducer";
import QuickMatchReducer from "../redux/reducer/QuickMatchReducer";
import ResumeIntelligenceReducer from "../redux/reducer/ResumeIntelligence";

const reducer = combineReducers({
  quickMatchState: QuickMatchReducer,
  jobs: JobReducer,
  resumeIntelligence: ResumeIntelligenceReducer,
  candidate: CandidateReducer,
  coverSheet: CoverSheetReducer,
  harizon: HarizonReducer,
  client_partner: ClientPartnerReducer,
});

export default reducer;
