import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledFlexRowView } from "../../../common-components/StyledComponents";

const feedback = [
  {
    message:
      "Zahir is a great fit for this position and should be highly considered.",
  },
  {
    message: "He's a Figma wizard, Dumbledore would be proud!",
  },
  {
    message:
      "Unfortunately, he’s a bit weak with soft skills, especially communication.",
  },
];

function FeedbackCard({ message }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "12px",
        mb: 1,
        boxShadow: "box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        borderRadius: "12px",
        border: "1px solid #EAECF0 !important",
      }}
    >
      <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#667085" }}>
        {message}
      </Typography>
    </Paper>
  );
}

function Recommendations({ matchedData }) {
  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          "& .MuiAccordionSummary-expandIconWrapper": {
            border: "1px solid #D0D5DD",
            borderRadius: "8px",
            padding: "4px",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px !important", // Adjust margin when expanded
            minHeight: "55px",
          },
          padding: "0px !important",
        }}
      >
        <StyledFlexRowView>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: "#101828",
              display: "flex",
              alignItems: "center",
            }}
          >
            Recomendations
          </Typography>
        </StyledFlexRowView>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px" }}>
        <Box>
          {matchedData?.insights?.feedback?.recommendations?.map(
            (item, index) => (
              <FeedbackCard key={index} message={item} />
            )
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default Recommendations;
