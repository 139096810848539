import { URL } from "../../api/Api";
import { COVER } from "../Action";
import { catchError, GET, PUT } from "../axios/Axios";

export const CoverSheetGetCall = (params) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/matchengine/coversheet`, params);
      dispatch({ type: COVER.coverGet, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const CoverSheetPutCall = async (params, payload) => {
  try {
    const response = await PUT(
      `${URL}/matchengine/coversheet`,
      payload,
      params
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const CoverSheetJobDescription = async (job_id) => {
  try {
    const response = await GET(
      `${URL}/matchengine/coversheet/description/${job_id}`,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const CoverSheetJobDescriptionPUTCall = async (id, params) => {
  try {
    const response = await PUT(
      `${URL}/matchengine/coversheet/status/${id}`,
      {},
      params
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const VisaStatusDetails = async (params = {}) => {
  try {
    const response = await GET(`${URL}/intake/visa/list/`, params);
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
