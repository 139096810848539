import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import {
  DotColor,
  StatusChange,
  StyledFlexColumnView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";
import { HandleSkeletion } from "../../../../utils/constants";

function Attributes({ title, data, isLoading }) {
  const headerData = ["Skill", "Expertise Level", "Last Usage"];
  return (
    <StyledFlexColumnView>
      <Typography
        fontSize={"16px"}
        fontWeight={600}
        color={"#344054"}
        display={"flex"}
      >
        {title}
      </Typography>
      <TableContainerBorder marginTop={"0px"} scrollStyle height={data.length}>
        <Table>
          <TableHead>
            <TableRow>
              {headerData.map((item) => (
                <StyledTableTitleTableCell>{item}</StyledTableTitleTableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <>
              {Array.from({ length: 5 }).map((_, index) => (
                <TableRow>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <TableCell key={index}>
                      <HandleSkeletion height={30} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ) : (
            <TableBody>
              {data?.map((row, index) => {
                const color =
                  row.label === "Expert" || row.softskill === "high"
                    ? "red"
                    : row.softskill === "Medium" || row.label === "Novice"
                    ? "orange"
                    : "green";
                return (
                  <TableRow key={index}>
                    <StyledTableBodyTableCell>
                      {row.hardskill || row.softskill || row.tool}
                    </StyledTableBodyTableCell>
                    <TableCell>
                      <StatusChange>
                        <DotColor color={color} />

                        {row.label}
                      </StatusChange>
                    </TableCell>
                    <StyledTableBodyTableCell>
                      {row.last_used_year}
                    </StyledTableBodyTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainerBorder>
    </StyledFlexColumnView>
  );
}

export default Attributes;
