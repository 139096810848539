import { Avatar, Box, Grid2, Paper, Typography } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import React from "react";
import AlertCircleIco from "../../../../assets/icons/AlertCircleIco";
import CheckIco from "../../../../assets/icons/CheckIco";

const data = [
  { label: "Overall", score: 96 },
  { label: "Experience", score: 80 },
  { label: "Personality", score: 88 },
  { label: "Hard Skills", score: 94 },
  { label: "Soft Skills", score: 72 },
];

const feedback = [
  {
    icon: <CheckIco />,
    message:
      "Zahir is a great fit for this position and should be highly considered.",
  },
  {
    icon: <CheckIco />,
    message: "He's a Figma wizard, Dumbledore would be proud!",
  },
  {
    icon: <AlertCircleIco />,
    message:
      "Unfortunately, he’s a bit weak with soft skills, especially communication.",
  },
];

function SkillRating({ label, score }) {
  const color = score >= 75 ? "#47CD89" : score >= 30 ? "#FDB022" : "red";

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mx={1.5}>
      <Gauge
        width={90}
        height={90}
        value={score}
        cornerRadius="50%"
        text={({ value, valueMax }) => `${score}%`}
        sx={(theme) => ({
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 14,
            color: "#101828",
            fontWeight: 600,
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: color,
          },
          // [`& .${gaugeClasses.referenceArc}`]: {
          //   fill: "",
          // },
        })}
      />

      <Typography sx={{ fontSize: "12px", color: "#475467", fontWeight: 500 }}>
        {label}
      </Typography>
    </Box>
  );
}

function FeedbackCard({ icon, message }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "start",
        padding: "12px",
        mb: 1,
        boxShadow: "box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        borderRadius: "12px",
        border: "1px solid #EAECF0 !important",
      }}
    >
      <Avatar
        sx={{
          bgcolor: "transparent",
          marginRight: "4px",
          height: "25px !important",
        }}
      >
        {icon}
      </Avatar>
      <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#667085" }}>
        {message}
      </Typography>
    </Paper>
  );
}
function MatchingSummary({ matchedData }) {
  return (
    <Box>
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          mb: 2,
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          borderRadius: "12px",
          border: "1px solid #EAECF0 !important",
        }}
      >
        <Grid2 container justifyContent="center">
          {matchedData?.match_summary?.map((item, index) => (
            <Grid2 item key={index}>
              <SkillRating label={item.name} score={item.score} />
            </Grid2>
          ))}
        </Grid2>
      </Paper>

      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 600,
          color: "#101828",
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {"Analysis"}
      </Typography>
      <FeedbackCard
        message={matchedData?.insights?.feedback?.analysis}
        icon={<CheckIco />}
      />

      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 600,
          color: "#101828",
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {"Red Flags"}
      </Typography>

      {matchedData?.insights?.feedback?.red_flags?.map((item, index) => (
        <FeedbackCard key={index} message={item} icon={<AlertCircleIco />} />
      ))}
    </Box>
  );
}

export default MatchingSummary;
