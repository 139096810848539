import { Autocomplete, Chip, styled, TextField } from "@mui/material";
import React from "react";

export const CustomAutocomplete = styled(Autocomplete)(({ error }) => ({
  "& .MuiAutocomplete-inputRoot": {
    alignItems: "start",
    alignContent: "flex-start",
    padding: "6px",
    // border: error ? "none" : "1px solid #D0D5DD",
    borderRadius: "5px",
    alignItems: "center",
    gap: "4px",
  },
  "& .undefined": {
    border: "none !important",
  },
  "& .MuiChip-root": {
    gap: "10px",
    border: "1px solid",
    "& .MuiChip-deleteIcon": {
      width: "20px",
    },
  },
  "& fieldset": {
    border: error ? "1px solid red" : "1px solid #D0D5DD",
  },
  "& .extracted": {
    background: "rgba(0, 0, 0, 0.08)",
  },
  "& .inferred": {
    color: "rgb(0, 112, 242)",
    background: "rgb(235, 248, 255)",
    border: "1px solid rgb(0, 112, 242)",
  },
  "& .user_added": {
    color: "rgb(7, 148, 85)",
    background: "rgb(219, 250, 230)",
    border: "1px solid rgb(7, 148, 85)",
  },
  "& .MuiAutocomplete-tag": {
    margin: "0px 3px",
    borderRadius: "5px",
    fontSize: "12px",
    padding: "2px 6px",
  },
  "& input.MuiInputBase-input": {
    padding: "6px !important",
    "&::placeholder": {
      color: error && "#d32f2f",
      ...(error && { opacity: 1 }),
    },
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1.4px",
  },
}));

export const AutocompleteText = (props) => {
  const {
    onChange,
    value,
    title,
    key,
    onDelete,
    placeholder,
    isDisabled,
    options,
    onKeyDown,
    inputValue,
    onInputChange,
    customClass,
    limitTags = 2,
    freeSolo = true,
  } = props.autoData;

  return (
    <CustomAutocomplete
      disabled={isDisabled}
      key={key !== undefined && key}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        onInputChange(event, newInputValue);
      }}
      title={title !== undefined && title}
      multiple
      onChange={(event, newValue) => {
        onChange && onChange(event, newValue);
      }}
      error={props?.error}
      options={options || []}
      getOptionLabel={(option) => option?.content || option?.name || ""}
      freeSolo={freeSolo}
      // disablePortal={false}
      value={
        (freeSolo
          ? value
          : options?.filter((opt) =>
              value?.some((val) => val.name === opt.name)
            )) || []
      }
      limitTags={limitTags}
      onKeyDown={(event, newValue) => {
        onKeyDown && onKeyDown(event);
      }}
      renderTags={(value, getTagProps) =>
        value &&
        value.length !== 0 &&
        value.map((option, index) => (
          <Chip
            key={option.content + index}
            variant={`outlined ${option && option.name}`}
            border={option && option.name ? "#D0D5DD" : "none"}
            label={option && (option?.content || option?.name)}
            {...getTagProps({ index })}
            onDelete={() => onDelete(option)}
          />
        ))
      }
      renderOption={(props, option) => {
        return <li {...props}>{option?.name ?? option}</li>;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={customClass}
          variant="outlined"
          error={props?.error}
          helperText={props?.helperText}
          // InputProps={{
          //   ...params.InputProps,
          //   // endAdornment: null,
          // }}
          placeholder={placeholder}
        />
      )}
    />
  );
};
