import { URL } from "../../api/Api";
import { catchError, GET, PUT } from "../axios/Axios";

export const AgnecyDetailsGetCall = async () => {
  try {
    const response = await GET(`${URL}/customers/organization/retrive`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const AgnecyDetailsUpdateCall = async (id, payload) => {
  try {
    const response = await PUT(`${URL}/customers/organization/${id}`, payload);
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
