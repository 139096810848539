import {
    Autocomplete,
    Grid2,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import ChevronDownIco from "../../../../assets/icons/ChevronDownIco";
import { StyledFlexColumnView } from "../../../common-components/StyledComponents";

function PreviousExperience() {
    const [selectedExperience, setSelectedExperience] = useState("");
    return (
        <Grid2 container spacing={3}>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Have you worked in the past with Porsche?"}
                    </Typography>
                    <Autocomplete
                        disablePortal
                        options={["Yes", "No"]}
                        value={selectedExperience}
                        onChange={(event, newValue) => setSelectedExperience(newValue || "")}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "5px",
                                border: "1px solid #D0D5DD",
                                alignItems: "center",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                placeholder="Select answer"
                                variant="outlined"
                            />
                        )}
                        popupIcon={
                            <IconButton sx={{ padding: 0 }}>
                                <ChevronDownIco />
                            </IconButton>
                        }
                    />
                </StyledFlexColumnView>
            </Grid2>
            {selectedExperience === "Yes" && (
                <>
                    <Grid2 size={4}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Employment Period"}
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="date"
                                // value={visaValidFrom || ""}
                                // onChange={(e) => setVisaValidFrom(e.target.value)}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #D0D5DD",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "12px",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                            />
                        </StyledFlexColumnView>
                    </Grid2>
                    <Grid2 size={4}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Manager Name"}
                            </Typography>
                            <TextField
                                fullWidth
                                value=""
                                placeholder="Enter manager name"
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #D0D5DD",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "12px",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                            />
                        </StyledFlexColumnView>
                    </Grid2>
                    <Grid2 size={4}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Department"}
                            </Typography>
                            <TextField
                                fullWidth
                                value=""
                                placeholder="Enter department"
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #D0D5DD",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "12px",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                            />
                        </StyledFlexColumnView>
                    </Grid2>

                    <Grid2 size={4}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Type of Employment"}
                            </Typography>
                            <Autocomplete
                                disablePortal
                                defaultValue={["Contractor"]}
                                options={["Contractor"]}
                                // value={selectedVisaType}
                                // onChange={(event, newValue) => setSelectedVisaType(newValue || "")}
                                sx={{
                                    "& .MuiAutocomplete-inputRoot": {
                                        padding: "5px",
                                        border: "1px solid #D0D5DD",
                                        alignItems: "center",
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        // placeholder="Select Visa Type"
                                        variant="outlined"
                                    />
                                )}
                                popupIcon={
                                    <IconButton sx={{ padding: 0 }}>
                                        <ChevronDownIco />
                                    </IconButton>
                                }
                            />
                        </StyledFlexColumnView>
                    </Grid2>
                </>
            )}
        </Grid2>
    );
}

export default PreviousExperience;
