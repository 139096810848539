import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AccordionDetails,
  AccordionSummary,
  Grid2,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  addJdNotes,
  deleteJdNote,
  fixedJob,
  getJdNotes,
  getJobDataDetail,
  updateJdNote,
} from "../../../api/Api";
import Image from "../../../assets/images/JobPrimary.png";
import CommonButton from "../../../component/Button";
import SnackBar from "../../../component/SnackBar";
import ToogleButton from "../../../component/ToogleButton";
import {
  JobJobEdit,
  JobJobReScore,
  JobJobSubmit,
} from "../../../redux/action/Job";
import { DELETE, GET, POST, PUT } from "../../../redux/axios/Axios";
import CreateNote from "../../common-components/CreateNote";
import DeleteNote from "../../common-components/DeleteNote";
import {
  CommonGrid2,
  CustomAccordion,
} from "../../common-components/StyledComponents";
import DialogCandidateHub from "../jobs-candidate/components/DialogCandidateHub";
import Notes from "../jobs-candidate/components/Notes";
import AddtionalInfomation from "./additional-information";
import AddNewSkillDialog from "./Dialog";
import PrimaryInformation from "./primary-information";
import Result from "./result";
import SecondaryInformation from "./secondary-information";
import Skills from "./skills";
import SkillDeleteDialog from "./skills/SkillDeleteDialog";
import Results from "../jobs-summary/components/Results";
import Skill from "./skill/index";
import InterviewSettings from "../create-job/components/InterviewSettings";
import SeededPreScreen from "../create-job/components/SeededPreScreen";
import RelevancyTable from "./RelevancyTable";

const JobInformation = ({
  alignItems,
  // jobData,
  jobId,
  handleDownloadJdOpen,
  fetchData,
}) => {
  const ToogleData = [
    { name: "View", value: "View" },
    { name: "Edit", value: "Edit" },
  ];
  const [alignment, setAlignment] = React.useState(alignItems || "View");
  const [skillDialog, setSkillDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    skill: "",
    experience_in_yrs: "",
    criticality_score: "",
    title: "Create",
    classificationData: [],
    classification: false,
    classificationDataValue: "",
    key: "",
  });
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "Email", added_by: "username", type: "" },
  ]);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [deleteNoteDialogOpen, setDeleteNoteDialogOpen] = useState(false);
  const [noteId, setNoteId] = useState("");
  const [notesTableList, setNotesTableList] = useState([]);
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [notesTableListLoading, setNotesTableListLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const JobJobEditData = useSelector((state) => state.jobs.jobJobEdit);
  const [jobData, setJobData] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [open, setOpen] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const criticalityData = [
    { name: "NTH", value: "nice to have" },
    { name: "Desired", value: "preferred" },
    { name: "Required", value: "required" },
  ];
  const tableContainerRef = React.useRef(null);

  const [reScore, setReScore] = useState({
    disabled: false,
    noLoading: false,
    yesLoading: false,
    rescore: false,
    reRunLoading: false,
    noteOpen: false,
  });

  const handleToogleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      if (newAlignment === "Edit" && jobData?.unscored) {
        setReScore((prev) => ({
          ...prev,
          rescore: true,
        }));
      } else setAlignment(newAlignment);
    }
  };

  const value = (value) => (value ? value : "-");

  const jobModifyData = {
    job_title: "",
    company_name: "",
    industry: "",
    available_industry: [],
    payRateValue: {
      max: "",
      min: "",
      minCurrency: "USD",
      maxCurrency: "USD",
    },
    pay_rate: {
      max: "",
      min: "",
      currency: "USD",
    },
    management_level: "",
    available_management_level: [],
    employment: "",
    available_employment_types: [],
    Date_Range: {
      start_date: "",
      end_date: "",
    },
    skillDropDownData: [],
    occupational_classification: "",
    available_occupational_classification: [],
    employeeTarget: "",

    autoCompleteValue: "",
    id: "",
    reports_to: "",
    req_id: "",
    bu_department_team: "",
    company_profile: "",
    available_company_profiles: [],
    education: "",
    available_education: "",
    dei: false,
    govt_security_clearance: false,
    languages: [],
    certificates: [],
    employer_targets: [],
    location: "",
    location_targets: [],
    shift: [],
    skillData: {
      extracted_hard_skills: [],
      extracted_soft_skills: [],
      low_priority: [],
      high_priority: [],
      recommended: [],
      medium_priority: [],
    },
    score_certificates: false,
    score_education: false,
    score_employer_targets: false,
    score_hard_skills: false,
    score_languages: false,
    score_location_targets: false,
    score_soft_skills: false,
    score_visa: false,
  };
  const [jobValue, setJobValue] = useState(jobModifyData);

  useEffect(() => {
    fetchNotesTableList(jobId || "9b7b1a45-8d5a-4eee-acca-76198084fd76");
  }, [jobId]);

  useEffect(() => {
    if (alignment === "View" || alignItems !== undefined) {
      fetchJobDataDetail(jobId || "9b7b1a45-8d5a-4eee-acca-76198084fd76");
    } else if (alignment === "Edit") {
      setJobValue(jobModifyData);
      fetchJobEdit(jobId || "9b7b1a45-8d5a-4eee-acca-76198084fd76");
    }
  }, [alignment]);

  const handleDynmaicList = (data) => {
    return data.flatMap((item) =>
      item.content.map((contentItem) => ({
        name: item.name,
        content: contentItem,
      }))
    );
  };
  useEffect(() => {
    if (Object.keys(JobJobEditData).length !== 0) {
      setJobValue({
        job_title: JobJobEditData.job_title,
        company_name: JobJobEditData.company_name,
        industry: JobJobEditData.industry,
        available_industry: JobJobEditData.available_industry,
        payRateValue: {
          ...JobJobEditData.pay_rate,
          minCurrency: JobJobEditData.pay_rate?.currency,
          maxCurrency: JobJobEditData.pay_rate?.currency,
        },
        management_level: JobJobEditData.management_level,
        available_management_level: JobJobEditData.available_management_level,
        employment: JobJobEditData.employment,
        available_employment_types: JobJobEditData.available_employment_types,
        Date_Range: {
          start_date: JobJobEditData.start_date,
          end_date: JobJobEditData.end_date,
        },
        skillDropDownData: [
          { name: "User Added" },
          ...JobJobEditData.extracted_hard_skills.map((item) => {
            return { name: item.classification };
          }),
        ],
        occupational_classification: JobJobEditData.occupational_classification,
        available_occupational_classification:
          JobJobEditData.available_occupational_classification,
        id: JobJobEditData.id,
        reports_to: JobJobEditData.reports_to,
        req_id: JobJobEditData.req_id,
        bu_department_team: JobJobEditData.bu_department_team,
        company_profile: JobJobEditData.company_profile,
        available_company_profiles: JobJobEditData.available_company_profiles,
        education: JobJobEditData.education,
        available_education: JobJobEditData.available_education,
        dei: JobJobEditData.dei,
        govt_security_clearance: JobJobEditData.govt_security_clearance,
        languages: handleDynmaicList(JobJobEditData.languages),
        certificates: handleDynmaicList(JobJobEditData.certificates),
        employer_targets: handleDynmaicList(JobJobEditData.employer_targets),
        location: JobJobEditData.location,
        location_targets: JobJobEditData.location_targets.map((item) => {
          return { content: item, id: "" };
        }),
        shift:
          JobJobEditData.shift.length === 0
            ? []
            : JobJobEditData?.shift?.map((item) => {
                return { content: item, id: "" };
              }),
        pay_rate: {
          ...JobJobEditData.pay_rate,
          max: JobJobEditData.pay_rate.max,
          min: JobJobEditData.pay_rate.min,
          currency: JobJobEditData.pay_rate.currency || "USD",
        },
        skillData: {
          extracted_hard_skills: JobJobEditData?.extracted_hard_skills,
          extracted_soft_skills: JobJobEditData?.extracted_soft_skills,
          low_priority: JobJobEditData.low_priority,
          high_priority: JobJobEditData.high_priority,
          recommended: JobJobEditData.recommended,
          medium_priority: JobJobEditData.medium_priority,
        },
        score_certificates: JobJobEditData.score_certificates,
        score_education: JobJobEditData.score_education,
        score_employer_targets: JobJobEditData.score_employer_targets,
        score_hard_skills: JobJobEditData.score_hard_skills,
        score_languages: JobJobEditData.score_languages,
        score_location_targets: JobJobEditData.score_location_targets,
        score_soft_skills: JobJobEditData.score_soft_skills,
        score_visa: JobJobEditData.score_visa,
      });
    }
  }, [JobJobEditData]);

  const validationSchema = Yup.object().shape({
    job_title: Yup.string().required("Title is required"),
    company_name: Yup.string().required("Company is required"),
    industry: Yup.string().required("Industry is required"),
    management_level: Yup.string().required("Seniority level is required"),
    employment: Yup.string().required("Employment type is required"),
    location: Yup.string().required("Location is required"),

    reports_to: Yup.string().required("Report_To is required"),
    req_id: Yup.string().required("Req_Id is required"),
    bu_department_team: Yup.string().required("BU/Dept/Team is required"),
    company_profile: Yup.string().required("Req_Id is required"),
    // education: Yup.string().required("Highest Level of Education is required"),
    shift: Yup.array()
      .min(1, "Please select at least one option")
      .required("This field is required"),
    location_targets: Yup.array()
      .min(1, "Please select at least one option")
      .required("This field is required"),
    certificates: Yup.array()
      .min(1, "Please select at least one option")
      .required("This field is required"),
    employer_targets: Yup.array()
      .min(1, "Please select at least one option")
      .required("This field is required"),
    languages: Yup.array()
      .min(1, "Please select at least one option")
      .required("This field is required"),
    pay_rate: Yup.object().shape({
      min: Yup.number()
        .required("Minimum pay rate is required")
        .typeError("Minimum pay rate must be a number")
        .min(0, "Minimum pay rate cannot be negative"),
      max: Yup.number()
        .required("Maximum pay rate is required")
        .typeError("Maximum pay rate must be a number")
        .moreThan(
          Yup.ref("min"),
          "Maximum pay rate must be greater than the minimum"
        ),
    }),
    skillData: Yup.object({
      extracted_hard_skills: Yup.array().of(
        Yup.object({
          skill: Yup.array().of(
            Yup.object({
              skill: Yup.string().required("Skill is required"),
              experience_in_yrs: Yup.number()
                .required("Experience is required")
                .positive("Experience must be a positive number")
                .integer("Experience must be an integer"),
            })
          ),
        })
      ),
      extracted_soft_skills: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .positive("Experience must be a positive number")
            .integer("Experience must be an integer"),
          //   })
        })
      ),
    }),
    Date_Range: Yup.object()
      .shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
      })
      .test("Date Range is required", (value) => {
        return value?.start_date || value?.end_date;
      }),
  });

  const primaryViewData = [
    {
      title: "Title",
      name: value(jobData?.job_details?.job_title),
      component: "input",
      titleName: "job_title",
    },
    {
      title: "Company",
      name: value(jobData?.job_details?.company_name),
      component: "input",
      titleName: "company_name",
    },
    {
      title: "Industry",
      name: value(jobData?.job_details?.industry),
      component: "dropDown",
      titleName: "industry",
      dropDownData: "available_industry",
    },
    {
      title:
        alignment === "View"
          ? "Hourly Target Rate"
          : "Minimal Hourly Target Rate",
      name: value(jobData?.job_details?.pay_rate),
      component: "input-select",
      value: jobValue.payRateValue.min,
      currency: jobValue.payRateValue.currency,

      titleName: "pay_rate",
      currency: "min",
      currencyValue: "currency",
    },
    ...(alignment === "Edit"
      ? [
          {
            title: "Maximal Hourly Target Rate",
            name: value(jobData?.job_details?.pay_rate),
            component: "input-select",
            value: jobValue.payRateValue.max,
            currency: jobValue.payRateValue.currency,

            titleName: "pay_rate",
            currency: "max",
            currencyValue: "currency",
          },
        ]
      : []),
    {
      title: "Seniority Level",
      name: "Senior",
      component: "dropDown",
      titleName: "management_level",
      dropDownData: "available_management_level",
    },
    {
      title: "Employment Type",
      name: value(jobData?.job_details?.employment),
      component: "dropDown",
      value: jobValue.employment,
      data: jobValue.available_employment_types,
      titleName: "employment",
      dropDownData: "available_employment_types",
    },
    {
      title: "Location",
      image: jobData?.job_details?.location ? Image : null,
      name: value(jobData?.job_details?.location),
      component: "input",
      titleName: "location",
    },
    ...(alignment === "View"
      ? [
          {
            title: "Start Date",
            name: value(jobData?.job_details?.start_date),
          },
          {
            title: "End Date",
            name: value(jobData?.job_details?.end_date),
          },
        ]
      : [
          {
            title: "Date Range",
            name: "Oct 30 2024",
            component: "calender",
            titleName: "Date_Range",
          },
        ]),
  ];

  const SecondaryViewData = [
    {
      title: "Occupational Classification",
      name: value(jobData?.job_details?.occupational_classification),
      component: "dropDown",
      titleName: "occupational_classification",
      dropDownData: "available_occupational_classification",
    },
    {
      title: "Req ID",
      name: value(jobData?.job_details?.req_id),
      component: "input",
      titleName: "req_id",
    },
    {
      title: "Shift",
      shift: [jobData?.job_details?.shift],
      component: "autoComplete",
      titleName: "shift",
      placeholder: "Enter Shift",
    },
    {
      title: "Reports To",
      name: value(jobData?.job_details?.reports_to),
      component: "input",
      titleName: "reports_to",
    },
    {
      title: "BU/Dept/Team",
      name: value(jobData?.job_details?.bu_department_team),
      component: "input",
      titleName: "bu_department_team",
    },
    {
      title: "Company Profile",
      name: jobData?.job_details?.company_profile,
      component: "dropDown",
      titleName: "company_profile",
      dropDownData: "available_company_profiles",
    },
  ];

  const additionalData = {
    type: "additional",
    data: [
      {
        title: "Highest Level of Education",
        name: "Bachelor’s Degree",
        component: "dropDown",
        titleName: "education",
        dropDownData: "available_education",
        check: jobValue.score_education,
        toggleName: "score_education",
      },
      {
        title: "Location Targets",
        additional: [
          ...(jobData?.job_details?.location_targets?.map((location) => ({
            image: Image,
            name: location,
          })) || []),
        ],
        component: "autoComplete",
        check: false,
        titleName: "location_targets",
        placeholder: "Enter Location Targets",
        check: jobValue.score_location_targets,
        toggleName: "score_location_targets",
      },
      {
        title: "Certificates",
        additional: jobData?.job_details?.certificates?.flatMap(
          (certificate) => certificate.content
        ),
        component: "autoComplete",
        check: false,
        titleName: "certificates",
        placeholder: "Enter Certificates",
        check: jobValue.score_certificates,
        toggleName: "score_certificates",
      },
      {
        title: "Employer Targets",
        component: "autoComplete",
        check: false,
        titleName: "employer_targets",
        placeholder: "Enter Employer Targets",
        additional: jobData?.job_details?.employer_targets?.flatMap(
          (employerTargets) => employerTargets.content
        ),
        check: jobValue.score_employer_targets,
        toggleName: "score_employer_targets",
      },
      {
        title: "Languages",
        additional: jobData?.job_details?.languages?.flatMap(
          (languages) => languages.content
        ),
        component: "autoComplete",
        titleName: "languages",
        placeholder: "Enter Languages",
        check: jobValue.score_languages,
        toggleName: "score_languages",
      },
      {
        title: "Visa Status",
        name: jobData?.job_details?.visa_employment_needs?.content,
        component: "dropDown",
        check: jobValue.score_visa,
        toggleName: "score_visa",
      },
      {
        title: "Goverment / Security Classification",
        name: jobData?.job_details?.govt_security_clearance ? "Yes" : "NO",
        check: jobValue.govt_security_clearance,
        toggleName: "govt_security_clearance",
      },
      {
        title: "DEI",
        name: jobData?.job_details?.dei ? "Yes" : "NO",
        check: jobValue.dei,
        toggleName: "dei",
      },
    ],
  };

  const headerData = [
    { name: "Skill" },
    { name: "Criticality" },
    { name: "Years" },
    ...(alignment === "Edit" ? [{ name: "" }] : []),
  ];

  const toggleRow = (index) => {
    setOpen((prev) => {
      const newState = { ...prev, [index]: !prev[index] };

      return newState;
    });
    setTimeout(() => {
      if (tableContainerRef.current) {
        const targetRow = tableContainerRef.current.querySelector(
          `.primary_${index}`
        );
        if (targetRow) {
          const targetCellTop = targetRow.getBoundingClientRect().top;
          const containerTop =
            tableContainerRef.current.getBoundingClientRect().top;
          const cellOffset = targetCellTop - containerTop;
          tableContainerRef.current.scrollTo({
            top: tableContainerRef.current.scrollTop + cellOffset - 60,
            behavior: "smooth",
          });
        }
      }
    }, 0);
  };

  const menuItemData = [
    {
      name: "USD",
    },
    { name: "INR" },
  ];

  const handleDeleteSkillRow = (data, parentData, form, type) => {
    setDialogData({
      key:
        type === "primary" ? "extracted_hard_skills" : "extracted_soft_skills",
      data: data,
      form: form,
    });
    setDeleteDialog(true);
  };

  const handleClose = () => {
    setSkillDialog(false);
  };

  const handleAddNewSkill = (key, form) => {
    console.log(jobValue.skillDropDownData, "jobValue.skillDropDownData");
    setSkillDialog(true);
    setDialogData({
      title: "Create",
      classification: true,
      classificationData: jobValue.skillDropDownData,
      classificationDataValue: jobValue.skillDropDownData[0].name,
      experience_in_yrs: "",
      criticality_score: 0,
      skill: "",
      key: key,
      form: form,
    });
  };

  const handleSkillClick = async (values, form) => {
    setButtonLoading(true);
    setTimeout(() => {
      const skillDataKey = values.key;
      const currentSkillData =
        dialogData.form.values["skillData"][skillDataKey];
      const { classification, skill, ...rest } = values;

      let updatedSkillData;

      if (skillDataKey === "extracted_hard_skills") {
        const classificationIndex = currentSkillData.findIndex(
          (item) => item.classification === classification
        );

        if (classificationIndex !== -1) {
          const updatedClassification = {
            ...currentSkillData[classificationIndex],
            skill: [
              ...currentSkillData[classificationIndex].skill,
              { skill, ...rest },
            ],
          };

          updatedSkillData = [
            ...currentSkillData.slice(0, classificationIndex),
            updatedClassification,
            ...currentSkillData.slice(classificationIndex + 1),
          ];
        } else {
          updatedSkillData = [
            ...currentSkillData,
            {
              classification,
              skill: [{ skill, ...rest }],
              tot_experience_in_yrs: rest.experience_in_yrs,
            },
          ];
        }
      } else {
        updatedSkillData = [...currentSkillData, values];
      }

      const formData = {
        ...dialogData.form.values["skillData"],
        [skillDataKey]: updatedSkillData,
      };
      dialogData.form.setFieldValue("skillData", formData);
      setSnackbarState({
        open: true,
        severity: "success",
        message: "updated Successfully",
      });
      setButtonLoading(false);
      setIsLoading(true);
      handleClose();
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteSkill = () => {
    setButtonLoading(true);
    let filterData;

    setTimeout(() => {
      if (dialogData.key === "extracted_soft_skills") {
        filterData = dialogData.form.values["skillData"][dialogData.key].filter(
          (item) => item !== dialogData.data
        );
      } else {
        if (dialogData?.data?.classification) {
          filterData = dialogData.form.values["skillData"][
            dialogData.key
          ].filter((item) => item !== dialogData.data);
        } else
          filterData = dialogData.form.values["skillData"][dialogData.key].map(
            (item) => {
              return {
                ...item,
                skill: item.skill.filter(
                  (list, idx) => list !== dialogData.data
                ),
              };
            }
          );
      }

      const formData = {
        ...dialogData.form.values["skillData"],
        [dialogData.key]: filterData,
      };
      dialogData.form.setFieldValue("skillData", formData);

      setSnackbarState({
        open: true,
        severity: "success",
        message: `${
          dialogData?.data?.classification
            ? dialogData?.data?.classification
            : dialogData.data.skill
        } Removed Successfully`,
      });
      setButtonLoading(false);
      handleDeleteDialogClose();
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const fetchJobDataDetail = async (jobId) => {
    try {
      setIsLoading(true);
      setTableLoading(true);
      const res = await GET(getJobDataDetail(jobId));
      setJobData(res?.data);
      setIsLoading(false);
      setTableLoading(false);
      setReScore((prev) => ({
        ...prev,
        noLoading: false,
        yesLoading: false,
        disabled: false,
        rescore: false,
        reRunLoading: false,
        noteOpen: false,
      }));
    } catch (error) {
      setIsLoading(false);
      setTableLoading(false);
      setReScore((prev) => ({
        ...prev,
        noLoading: false,
        yesLoading: false,
        disabled: false,
        rescore: false,
        reRunLoading: false,
        noteOpen: false,
      }));
    }
  };

  const fetchJobsList = async (job_id) => {
    try {
      const response = await GET(fixedJob(job_id));
      console.log("API Response:", response);
      setSnackbarState({
        open: true,
        message: response?.data?.message || "Fix applied successfully",
        severity: "success",
      });
      setIsLoading(true);
      fetchJobDataDetail(jobId || "9b7b1a45-8d5a-4eee-acca-76198084fd76");
    } catch (err) {
      console.error("API Error:", err);
      setSnackbarState({
        open: true,
        message: "An error occurred while applying the fix.",
        severity: "error",
      });
      setIsLoading(true);
    }
  };

  //notes
  const handleClickAddNote = () => {
    setOpenNoteDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setNoteId("");
    setOpenNoteDialog(false);
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleDeleteNote = (row) => {
    setDeleteNoteDialogOpen(true);
    setNoteId(row?.id);
    setNoteList([
      {
        note: row?.note,
        interaction: row?.interaction,
        added_by: row?.added_by,
        created_at: row?.created_at,
      },
    ]);
  };

  const handleDeleteNoteDialogClose = () => {
    setDeleteNoteDialogOpen(false);
    setNoteId("");
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleUpdateNote = (row) => {
    if (row?.id) {
      setNoteId(row?.id);
      setOpenNoteDialog(true);
      setNoteList([
        {
          note: row?.note,
          interaction: row?.interaction,
          added_by: row?.added_by,
        },
      ]);
    }
  };

  const fetchNotesTableList = async (jobId) => {
    setNotesTableListLoading(true);
    const res = await GET(getJdNotes(jobId));
    if (res) {
      setNotesTableList(res?.data);
      setNotesTableListLoading(false);
    }
  };

  const handleCreateNote = async () => {
    setAddNoteLoading(true);
    if (jobId) {
      const payload = noteList;
      console.log({ noteList });
      if (noteId) {
        const res = await PUT(updateJdNote(noteId), noteList[0]);
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });

          setReScore((prev) => ({
            ...prev,
            rescore: true,
            noteOpen: true,
          }));
        } else {
        }
      } else {
        const res = await POST(addJdNotes(jobId), payload);
        console.log(res?.data, "note");
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      }
      setReScore((prev) => ({
        ...prev,
        rescore: true,
        noteOpen: true,
      }));
      setAddNoteLoading(false);
      fetchNotesTableList(jobId);
      setOpenNoteDialog(false);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
    }
  };

  const handleDeleteNoteById = async (row) => {
    const res = await DELETE(deleteJdNote(noteId));
    console.log(res?.data, "note");
    if (res?.success) {
      console.log(res?.data?.success, "tr");
      setSnackbarState({
        open: true,
        severity: "success",
        message: res?.message,
      });
      // setAddNoteLoading(false);
      fetchNotesTableList(jobId);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
      setDeleteNoteDialogOpen(false);
      setNoteId("");
    } else {
    }
  };

  const fetchJobEdit = async (jobId) => {
    setIsLoading(true);

    try {
      const data = await dispatch(JobJobEdit(jobId));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  const handleNoteTextChange = (index, event) => {
    setNoteList((prevNoteList) => {
      const updatedNoteList = [...prevNoteList];
      updatedNoteList[index] = {
        ...updatedNoteList[index],
        note: event.target.value,
      };
      return updatedNoteList;
    });
  };

  const handleSkillToogleChange = (
    event,
    newAlignment,
    rowIndex,
    skillIndex = null,
    form,
    type
  ) => {
    if (newAlignment !== null) {
      const dataKey =
        type === "primary" ? "extracted_hard_skills" : "extracted_soft_skills";

      const updatedSkillData = form?.values?.["skillData"]?.[dataKey]?.map(
        (row) =>
          Array.isArray(row?.skill)
            ? { ...row, skill: row.skill.map((skill) => ({ ...skill })) }
            : { ...row }
      );

      if (type === "primary" && skillIndex !== null) {
        const skillToUpdate =
          updatedSkillData?.[rowIndex]?.["skill"]?.[skillIndex];
        if (skillToUpdate) {
          skillToUpdate["category"] = newAlignment;
        }
      } else if (type === "secondary") {
        const rowToUpdate = updatedSkillData?.[rowIndex];
        if (rowToUpdate) {
          rowToUpdate["category"] = newAlignment;
        }
      }

      form.setFieldValue("skillData", {
        ...form?.values?.["skillData"],
        [dataKey]: updatedSkillData,
      });
    }
  };

  const handleSkillTextChange = (
    event,
    rowIndex,
    skillIndex,
    form,
    type,
    fieldName
  ) => {
    const { value } = event.target;
    const dataKey =
      type === "primary" ? "extracted_hard_skills" : "extracted_soft_skills";
    const updatedSkillData = form?.values?.["skillData"]?.[dataKey]?.map(
      (row) =>
        Array.isArray(row?.skill)
          ? { ...row, skill: row.skill.map((skill) => ({ ...skill })) }
          : { ...row }
    );

    if (type === "primary") {
      const skillToUpdate =
        updatedSkillData?.[rowIndex]?.["skill"]?.[skillIndex];
      if (skillToUpdate) {
        skillToUpdate[fieldName] = value;
      }
    } else if (type === "secondary") {
      const rowToUpdate = updatedSkillData?.[rowIndex];
      if (rowToUpdate) {
        rowToUpdate[fieldName] = value;
      }
    }

    form.setFieldValue("skillData", {
      ...form?.values?.["skillData"],
      [dataKey]: updatedSkillData,
    });
  };

  const SecondaryViewDataView = [
    {
      title: "Occupational Classification",
      name: value(jobData?.job_details?.occupational_classification),
      component: "dropDown",
      titleName: "occupational_classification",
      dropDownData: "available_occupational_classification",
    },
    {
      title: "Req ID",
      name: value(jobData?.job_details?.req_id),
      component: "input",
      titleName: "req_id",
    },
    {
      title: "Shift",
      shift: [jobData?.job_details?.shift],
      component: "autoComplete",
      titleName: "shift",
      placeholder: "Enter Shift",
    },
    {
      title: "Reports To",
      name: value(jobData?.job_details?.reports_to),
      component: "input",
      titleName: "reports_to",
    },
    {
      title: "BU/Dept/Team",
      name: value(jobData?.job_details?.bu_department_team),
      component: "input",
      titleName: "bu_department_team",
    },
    {
      title: "Company Profile",
      name: jobData?.job_details?.company_profile,
      component: "dropDown",
      titleName: "company_profile",
      dropDownData: "available_company_profiles",
    },
    {
      title: "Highest Level of Education",
      name: "Bachelor’s Degree",
      component: "dropDown",
      titleName: "education",
      dropDownData: "available_education",
      check: jobValue.score_education,
      toggleName: "score_education",
      type: "additional",
    },
    {
      title: "Location Targets",
      additional: [
        ...(jobData?.job_details?.location_targets?.map((location) => ({
          image: Image,
          name: location,
        })) || []),
      ],
      component: "autoComplete",
      check: false,
      titleName: "location_targets",
      placeholder: "Enter Location Targets",
      check: jobValue.score_location_targets,
      toggleName: "score_location_targets",
      type: "additional",
    },
    {
      title: "Certificates",
      additional: jobData?.job_details?.certificates?.flatMap(
        (certificate) => certificate.content
      ),
      component: "autoComplete",
      check: false,
      titleName: "certificates",
      placeholder: "Enter Certificates",
      check: jobValue.score_certificates,
      toggleName: "score_certificates",
      type: "additional",
    },
    {
      title: "Employer Targets",
      component: "autoComplete",
      check: false,
      titleName: "employer_targets",
      placeholder: "Enter Employer Targets",
      additional: jobData?.job_details?.employer_targets?.flatMap(
        (employerTargets) => employerTargets.content
      ),
      check: jobValue.score_employer_targets,
      toggleName: "score_employer_targets",
      type: "additional",
    },
    {
      title: "Languages",
      additional: jobData?.job_details?.languages?.flatMap(
        (languages) => languages.content
      ),
      component: "autoComplete",
      titleName: "languages",
      placeholder: "Enter Languages",
      check: jobValue.score_languages,
      toggleName: "score_languages",
      type: "additional",
    },
    {
      title: "Visa Status",
      name: jobData?.job_details?.visa_employment_needs?.content,
      component: "dropDown",
      check: jobValue.score_visa,
      toggleName: "score_visa",
      type: "additional",
    },
    {
      title: "Goverment / Security Classification",
      name: jobData?.job_details?.dei ? "Yes" : "NO",
      check: jobValue.govt_security_clearance,
      toggleName: "govt_security_clearance",
      type: "additional",
    },
    {
      title: "DEI",
      name: jobData?.job_details?.dei ? "Yes" : "NO",
      check: jobValue.dei,
      toggleName: "dei",
      type: "additional",
    },
  ];

  const ordinalNumbers = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "sixth",
    "Seventh",
    "Eigth",
    "Ninth",
    "Tenth",
  ];
  const interviewTypes = jobData?.job_details?.interview_type || [];
  const InterviewSettingData = interviewTypes.map((type, index) => ({
    title: `${ordinalNumbers[index] || `${index + 1}th`} Touch`,
    name: value(type),
    component: "input",
    titleName: `first-touch_${index + 1}`,
  }));

  const accordionDataView = [
    {
      title: "Primary Information",
      component: (
        <PrimaryInformation
          primaryViewData={primaryViewData}
          alignment={alignment}
          isLoading={isLoading}
          menuItemData={menuItemData}
        />
      ),
    },
    {
      title: "Secondary Information",
      component:
        alignment === "Edit" ? (
          <SecondaryInformation
            SecondaryViewData={SecondaryViewData}
            alignment={alignment}
            isLoading={isLoading}
            // autoCompleteData={autoCompleteData}
          />
        ) : (
          <SecondaryInformation
            SecondaryViewData={SecondaryViewDataView}
            alignment={alignment}
            isLoading={isLoading}
          />
        ),
    },
    {
      title: "Skills",
      component:
        alignment === "Edit" ? (
          <Skills
            primaryData={
              // alignment === "Edit"
              //   ? jobValue.skillData.extracted_hard_skills
              jobData?.job_details?.extracted_hard_skills
            }
            secondaryData={
              // alignment === "Edit"
              // ? jobValue.skillData.extracted_soft_skills
              jobData?.job_details?.extracted_soft_skills
            }
            headerData={headerData}
            alignment={alignment}
            handleDeleteSkillRow={handleDeleteSkillRow}
            handleAddNewSkill={handleAddNewSkill}
            tableLoading={tableLoading}
            toggleRow={toggleRow}
            open={open}
            criticalityData={criticalityData}
            handleSkillToogleChange={handleSkillToogleChange}
            tableContainerRef={tableContainerRef}
            handleSkillTextChange={handleSkillTextChange}
            isLoading={isLoading}
          />
        ) : (
          <CommonGrid2 spacing={5} container>
            <CommonGrid2 size={12}>
              <Skill
                highPriority={jobData?.job_details?.high_priority}
                lowPriority={jobData?.job_details?.low_priority}
                mediumPriority={jobData?.job_details?.medium_priority}
                recommended={jobData?.job_details?.recommended}
                headerData={headerData}
                alignment={alignment}
                handleDeleteSkillRow={handleDeleteSkillRow}
                handleAddNewSkill={handleAddNewSkill}
                tableLoading={tableLoading}
                toggleRow={toggleRow}
                open={open}
                criticalityData={criticalityData}
                handleSkillToogleChange={handleSkillToogleChange}
                tableContainerRef={tableContainerRef}
                handleSkillTextChange={handleSkillTextChange}
                isLoading={isLoading}
              />
            </CommonGrid2>
            <CommonGrid2 size={12}>
              <InterviewSettings
                InterviewSettingData={InterviewSettingData}
                alignment={alignment}
                isLoading={isLoading}
                rowSize={true}
              />
            </CommonGrid2>
            {jobData &&
              jobData?.job_details &&
              jobData?.job_details?.pre_screen_questions?.length !== 0 && (
                <CommonGrid2 size={12}>
                  <SeededPreScreen
                    alignment={alignment}
                    preScreen={jobData?.job_details?.pre_screen_questions}
                  />
                </CommonGrid2>
              )}
          </CommonGrid2>
        ),
    },
    ...(alignment === "Edit"
      ? [
          {
            title: "Additional Information",
            component: (
              <AddtionalInfomation
                SecondaryViewData={additionalData}
                alignment={alignment}
                isLoading={isLoading}
              />
            ),
          },
        ]
      : []),
    {
      title: "Notes",
      component: (
        <Notes
          alignment={alignment}
          isShown={true}
          jobData={jobData}
          openNoteDialog={openNoteDialog}
          deleteNoteDialogOpen={deleteNoteDialogOpen}
          noteId={noteId}
          handleClickAddNote={handleClickAddNote}
          handleCloseNoteDialog={handleCloseNoteDialog}
          handleDeleteNote={handleDeleteNote}
          handleDeleteNoteDialogClose={handleDeleteNoteDialogClose}
          handleUpdateNote={handleUpdateNote}
          notesTableList={notesTableList}
          noteList={noteList}
          handleNoteTextChange={handleNoteTextChange}
          notesTableListLoading={notesTableListLoading || isLoading}
          isLoading={isLoading}
        />
      ),
    },
  ];
  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleSubmitJob = async (values, actions) => {
    setIsLoading(true);
    setEditButtonLoading(true);

    const result = (valueData) => {
      if (!valueData) return [];
      const processedData = valueData.reduce((acc, { name, content }) => {
        const existing = acc.find((item) => item.name === name);
        if (existing) {
          existing.content.push(content);
        } else {
          acc.push({ name, content: [content] });
        }
        return acc;
      }, []);

      const extracted = processedData.find((item) => item.name === "extracted");
      const otherData = processedData.filter(
        (item) => item.name !== "extracted"
      );

      if (!extracted) {
        return [{ name: "extracted", content: [] }, ...otherData];
      }

      return [extracted, ...otherData];
    };

    const data = {
      ...JobJobEditData,
      ...values,
      start_date: values.Date_Range.start_date,
      end_date: values.Date_Range.end_date,
      employer_targets: result(values.employer_targets),
      certificates: result(values.certificates),
      languages: result(values.languages),
      shift: values.shift.map((item) => item.content),
      location_targets: values.location_targets.map((item) => item.content),
      extracted_hard_skills: values.skillData.extracted_hard_skills,
      extracted_soft_skills: values.skillData.extracted_soft_skills,
    };

    try {
      const jobEdit = await JobJobSubmit(jobValue.id, data);
      setSnackbarState({
        open: true,
        severity: "success",
        message: jobEdit.message,
      });
      setAlignment("View");
      setIsLoading(false);
      setEditButtonLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setIsLoading(false);
      setEditButtonLoading(false);
    }
  };

  const handleScrollToError = (errors) => {
    if (
      errors &&
      errors["skillData"] &&
      errors["skillData"]["extracted_hard_skills"] &&
      errors["skillData"]["extracted_hard_skills"]?.length !== 0
    ) {
      errors["skillData"]["extracted_hard_skills"].map((item, index) => {
        setOpen((prev) => {
          const newState = { ...prev, [index]: true };
          return newState;
        });
      });
    }
    setTimeout(() => {
      const errorElement = document.querySelector(".Mui-error");
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  };

  const Footer = () => {
    return (
      <CommonGrid2 display={"flex"} gap={"1rem"}>
        <CommonButton
          value={"No"}
          variant="outlined"
          color="#344054"
          width={"100%"}
          padding={"10px"}
          marginTop={"2rem"}
          fontWeight={"700"}
          fontSize={"15px"}
          border={"#D0D5DD"}
          onClick={() =>
            handleClickScore("no", reScore.noteOpen ? false : true)
          }
          disabled={reScore.disabled}
          loading={reScore.noLoading}
        />
        <CommonButton
          value={"Yes"}
          width={"100%"}
          padding={"10px"}
          marginTop={"2rem"}
          fontWeight={"700"}
          fontSize={"15px"}
          disabled={reScore.disabled}
          loading={reScore.yesLoading}
          onClick={() =>
            handleClickScore("yes", reScore.noteOpen ? false : true)
          }
        />
      </CommonGrid2>
    );
  };

  const handleClickScore = async (condition, check) => {
    setReScore((prev) => ({
      ...prev,
      yesLoading: condition === "yes" ? true : false,
      noLoading: condition === "no" ? true : false,
      disabled: true,
      reRunLoading: condition === "re-run" ? true : false,
    }));
    setIsLoading(true);

    let payload = {
      score: condition === "re-run" || condition === "yes" ? true : false,
    };
    try {
      const data = await JobJobReScore(jobId, payload);

      if (check) {
        setAlignment("Edit");
      } else {
        await fetchData();
        await fetchJobDataDetail(jobId);
      }
      setReScore((prev) => ({
        ...prev,
        noLoading: false,
        yesLoading: false,
        disabled: false,
        rescore: false,
        noteOpen: false,
      }));
      setSnackbarState({
        open: true,
        severity: "success",
        message: data.message,
        // duration: 500,
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setIsLoading(false);
      setReScore((prev) => ({
        ...prev,
        noLoading: false,
        yesLoading: false,
        disabled: false,
        reRunLoading: false,
        noteOpen: false,
      }));
    }
  };
  const handleRescoreClose = () => {
    setIsLoading(false);
    setReScore({
      noLoading: false,
      disabled: false,
      yesLoading: false,
      rescore: false,
      setReScore: false,
      noteOpen: false,
    });
  };

  const resultHeaderData = ["Relevancy Index", "", "Relevancy Score", ""];

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={jobValue}
        validationSchema={validationSchema}
        validateOnMount={true}
        key={jobValue?.id || Date.now()}
        onSubmit={async (values, actions) => {
          handleSubmitJob(values, actions);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => {
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Grid2 container>
                <CommonGrid2
                  size={{ xs: 12, md: 4, sm: 12 }}
                  item
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Typography variant="h3">Product Designer</Typography>
                </CommonGrid2>
                <CommonGrid2
                  item
                  size={{ xs: 12, md: 8, sm: 12 }}
                  gap="10px"
                  display="flex"
                  justifyContent={"end"}
                >
                  {alignment === "Edit" && !alignItems && (
                    <CommonButton
                      value="Submit"
                      padding="5px 2rem"
                      fontWeight="600"
                      type="submit"
                      disabled={editButtonLoading}
                      loading={editButtonLoading}
                      onClick={() => {
                        setTimeout(() => {
                          handleScrollToError(errors);
                        }, 0);
                      }}
                    />
                  )}
                  {alignment !== "Edit" && !alignItems && (
                    <CommonButton
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      fontWeight="600"
                      value="Re-run"
                      padding="0px 20px"
                      disabled={reScore.reRunLoading || isLoading}
                      loading={reScore.reRunLoading}
                      onClick={() => handleClickScore("re-run", false)}
                    />
                  )}
                  <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    fontWeight="600"
                    value="Add Note"
                    padding="0px 20px"
                    onClick={handleClickAddNote}
                    disabled={isLoading}
                  />
                  {alignItems === "Edit" ? (
                    <>
                      <CommonButton
                        variant="contained"
                        color="white"
                        background="#7F56D9"
                        fontWeight="600"
                        padding="5px 20px"
                        value="Next Step"
                      />
                    </>
                  ) : (
                    <>
                      <CommonButton
                        variant="outlined"
                        color="#344054"
                        border="#D0D5DD"
                        fontWeight="600"
                        value="Download"
                        padding="0px 20px"
                        onClick={handleDownloadJdOpen}
                      />
                      <ToogleButton
                        ToogleData={ToogleData}
                        handleChange={handleToogleChange}
                        alignment={alignment}
                        border={"1px solid #D0D5DD"}
                        borderRadius={"8px"}
                        activeColor={"#344054"}
                        color={"#344054"}
                        activeBackgroundColor="#F9FAFB"
                        backgroundColor="#FFFF"
                        disabled={isLoading}
                      />
                    </>
                  )}
                </CommonGrid2>
              </Grid2>
              {alignment === "View" && (
                <div>
                  <Result
                    jobData={jobData}
                    isLoading={isLoading}
                    fetchJobsList={fetchJobsList}
                    snackbarState={snackbarState}
                    handleCloseSnackbar={handleCloseSnackbar}
                  />
                  <Grid2 marginTop={"3rem"}>
                    <RelevancyTable
                      alignment={alignment}
                      isLoading={isLoading}
                      resultHeaderData={resultHeaderData}
                      onDragEnd={() => {}}
                      dragContent={jobData?.job_details?.relevancy_index}
                    />
                  </Grid2>
                </div>
              )}
              {accordionDataView.map((item, index) => (
                <CustomAccordion
                  // borderBottom={"1px solid #EAECF0"}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowUpIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography
                      variant="h4"
                      margin={"15px 0px"}
                      fontWeight={600}
                    >
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {React.cloneElement(item.component, {
                      formikValues: values,
                      formikErrors: errors,
                      formikTouched: touched,
                      formikHandleChange: handleChange,
                      formikHandleBlur: handleBlur,
                      setFieldValue,
                    })}
                  </AccordionDetails>
                </CustomAccordion>
              ))}
              {alignment === "Edit" && (
                <Result
                  jobData={jobData}
                  isLoading={isLoading}
                  fetchJobsList={fetchJobsList}
                  snackbarState={snackbarState}
                  handleCloseSnackbar={handleCloseSnackbar}
                />
              )}
              <AddNewSkillDialog
                open={skillDialog}
                handleClose={handleClose}
                dialogData={dialogData}
                criticalityData={criticalityData}
                handleSkillClick={handleSkillClick}
                buttonLoading={buttonLoading}
              />
              <SkillDeleteDialog
                deleteDialog={deleteDialog}
                handleDeleteDialogClose={handleDeleteDialogClose}
                handleDeleteSkill={handleDeleteSkill}
                buttonLoading={buttonLoading}
                title="Delete Skill"
                content={
                  dialogData?.data?.classification
                    ? dialogData?.data?.classification
                    : dialogData?.data?.skill
                }
              />
            </Form>
          );
        }}
      </Formik>
      <DeleteNote
        deleteNoteDialogOpen={deleteNoteDialogOpen}
        handleDeleteNoteDialogClose={handleDeleteNoteDialogClose}
        noteList={noteList}
        handleDeleteNoteById={handleDeleteNoteById}
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <DialogCandidateHub
        open={reScore.rescore}
        handleClose={handleRescoreClose}
        footer={<Footer />}
        icon={""}
        title="Re-Score"
        content={
          reScore.noteOpen
            ? "Do you want to rescore?"
            : "You have unscored changes, do you want to rescore?"
        }
      />

      <CreateNote
        openNoteDialog={openNoteDialog}
        handleCloseNoteDialog={handleCloseNoteDialog}
        jobData={jobData}
        noteList={noteList}
        handleNoteTextChange={handleNoteTextChange}
        handleCreateNote={handleCreateNote}
        id={noteId}
        setNoteList={setNoteList}
        addNoteLoading={addNoteLoading}
      />
    </div>
  );
};
export default JobInformation;
