import { Download } from "@mui/icons-material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  CircularProgress,
  Grid2,
  MenuItem,
  TableCell,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addDameNotes,
  deleteDameNote,
  DownloadResume,
  getDameNotes,
  shareCoverSheet,
  updateDameNote,
} from "../../../api/Api";
import CandidateHub from "../../../assets/icons/CandidateHub";
import DeleteIco from "../../../assets/icons/DeleteIco";
import EyeIco from "../../../assets/icons/EyeIco";
import CommonButton from "../../../component/Button";
import SnackBar from "../../../component/SnackBar";
import {
  AttachementCreateCall,
  AttachementDelete,
  AttachementDownload,
  AttachementTableGetCall,
  JobCandidateQueationEnable,
  JobJobShare,
  JobResumeDuplicate,
  StatusChange,
} from "../../../redux/action/Job";
import {
  DELETE,
  GET,
  GETDOWNLOAD,
  POST,
  PUT,
} from "../../../redux/axios/Axios";
import AddUpdateNote from "../../common-components/AddUpdateNote";
import CommonAccordion from "../../common-components/CommonAccordion";
import DeleteNote from "../../common-components/DeleteNote";
import {
  CommonGrid2,
  StyledFlexRowView,
} from "../../common-components/StyledComponents";
import ShareQuickMatch from "../../quick-match/components/ShareQuickMatch";
import Notes from "../job-information/notes";
import SkillDeleteDialog from "../job-information/skills/SkillDeleteDialog";
import { CustomizeMenu } from "../style";
import Accomplishment from "./components/Accomplishment";
import AttachmentDialog from "./components/AttachementDialog";
import Attachment from "./components/Attachment";
import InteractionDialog from "./components/Dialogbox";
import DialogCandidateHub from "./components/DialogCandidateHub";
import Experience from "./components/Experience";
import Interactions from "./components/interaction";
import MatchingSummary from "./components/MatchingSummary";
import Profile from "./components/Profile";
import Recommendations from "./components/Recommendations";
import StrengthWeak from "./components/StrengthWeak";

function JobsCandidate({
  matchedData,
  resumeId,
  resumeLoader,
  filteredResumeTab,
  fetchResumeData,
  fetchData,
  handleQuestionMarkClick,
  iconVisible,
  chatBotData,
}) {
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "Email", added_by: "username" },
  ]);
  const query = new URLSearchParams(useLocation().search);
  const queryValue = query?.get("value");
  const navigate = useNavigate();
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [deleteNoteDialogOpen, setDeleteNoteDialogOpen] = useState(false);
  const [noteId, setNoteId] = useState("");
  const [notesTableList, setNotesTableList] = useState([]);
  const [attachementRowData, setAttachementRowData] = useState({});
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [notesTableListLoading, setNotesTableListLoading] = useState(false);
  const [viewResume, setViewResume] = useState({
    open: false,
    disabled: false,
    duplicateLoading: false,
    replacedLoading: false,
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [menuViewLoader, setMenuViewLoader] = useState(false);
  const [downloadMenuLoader, setDownloadMenuLoader] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState({
    open: false,
    loading: false,
  });
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [candidateProfileValue, setCandidateProfileValue] =
    useState("In Progress");
  const [copied, setCopied] = useState(false);

  const [statusDialog, setStatusDialog] = useState({
    open: false,
    disabled: false,
    noLoading: false,
    yesLoading: false,
    value: "",
  });

  const [noteHeaderData, setNoteHeaderData] = useState([
    { name: "Title", direction: "asc", sort: false, width: "50%" },
    { name: "Created By", direction: "asc", sort: false, width: "20%" },
    { name: "Date", direction: "asc", sort: false, width: "15%" },
    { width: "15%" },
  ]);

  const [interactionHeaderdata, setInteractionHeaderdata] = useState([
    { name: "ID", direction: "asc", sort: false, width: "15%" },
    { name: "Made By", direction: "asc", sort: false, width: "5%" },
    { name: "Type", direction: "asc", sort: false, width: "15%" },
    { name: "Action", direction: "asc", sort: false, width: "15%" },
    { name: "Status", direction: "asc", sort: false, width: "15%" },
    { name: "Created At", direction: "asc", sort: false, width: "15%" },
    { width: "5%" },
  ]);
  const [interActionData, setInterActionData] = useState({});
  const [interActionOpen, setInterActionOpen] = useState(false);

  const [attachmentList, setAttachmentList] = useState({
    data: [],
    loading: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const attachementOpen = Boolean(anchorEl);
  const [attachmentDialog, setAttachmentDialog] = useState({
    loading: false,
    disabled: false,
    open: false,
  });
  const [attachementMenuLoader, setAttachementMenuLoader] = useState({
    download: false,
    view: false,
  });
  const [deleteButton, setDeleteButton] = useState({
    loading: false,
    open: false,
    content: "",
    id: "",
  });

  const [enableQuestion, setEnableQuestion] = useState({
    open: false,
    status:
      matchedData?.insights?.feedback?.enable_pre_screen_questions || false,
    disabled: false,
    confirmLoading: false,
    leaveLoading: false,
    content: "",
  });

  const handleDownloadAttachment = async () => {
    try {
      setAttachementMenuLoader((prev) => ({
        ...prev,
        download: true,
      }));

      const response = await AttachementDownload(attachementRowData.id);
      let filename = attachementRowData.file_name || "download";
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(blobUrl);

      setAttachementMenuLoader((prev) => ({
        ...prev,
        download: false,
      }));
      setAnchorEl(null);
    } catch (error) {
      setAttachementMenuLoader((prev) => ({
        ...prev,
        download: false,
      }));
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleDeleteAttachmentClick = () => {
    setAnchorEl(null);
    setDeleteButton((prev) => ({
      ...prev,
      open: true,
      id: attachementRowData.id,
      content: attachementRowData.name,
    }));
  };

  const handleDeleteAttachment = async () => {
    try {
      setDeleteButton((prev) => ({
        ...prev,
        loading: true,
      }));
      const data = await AttachementDelete(deleteButton?.id);
      await fetchAttachmentTableList(resumeId);
      handelAttachmentDialogClose();
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
    } catch (error) {
      setDeleteButton((prev) => ({
        ...prev,
        loading: false,
      }));
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleFileView = async () => {
    try {
      setAttachementMenuLoader((prev) => ({
        ...prev,
        view: true,
      }));

      const fileExtension = attachementRowData.type.toLowerCase();

      const response = await AttachementDownload(attachementRowData.id);

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const officeViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
        attachementRowData.file_path
      )}`;

      const isDocValidFile = (fileName) => {
        const ext = fileName?.trim().split(".").pop()?.toLowerCase();
        return ext && ["doc", "docx"].includes(ext);
      };

      const isPdfValidFile = (fileName) => {
        const ext = fileName?.trim().split(".").pop()?.toLowerCase();
        return ext && ["pdf"].includes(ext);
      };

      if (isDocValidFile(attachementRowData.file_name)) {
        window.open(officeViewerUrl, "_blank");
      } else if (isPdfValidFile(attachementRowData.file_name)) {
        window.open(blobUrl, "_blank");
      }
      setAnchorEl(null);
      setAttachementMenuLoader((prev) => ({
        ...prev,
        view: false,
      }));
      setTimeout(() => URL.revokeObjectURL(blobUrl), 60000);
    } catch (error) {
      setAttachementMenuLoader((prev) => ({
        ...prev,
        view: false,
      }));
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const attachmentMenuData = [
    {
      title: "View",
      disabled: attachementMenuLoader.view || attachementMenuLoader.download,
      image: attachementMenuLoader.view ? (
        <CircularProgress
          size={20}
          thickness={5}
          style={{ color: "#667085" }}
        />
      ) : (
        <EyeIco />
      ),
      onClick: () => {
        handleFileView();
      },
    },
    {
      title: "Delete",
      image: <DeleteIco />,
      disabled: attachementMenuLoader.download || attachementMenuLoader.view,
      onClick: handleDeleteAttachmentClick,
    },
    {
      title: "Download",
      disabled: attachementMenuLoader.download || attachementMenuLoader.view,
      image: attachementMenuLoader.download ? (
        <CircularProgress
          size={20}
          thickness={5}
          style={{ color: "#667085" }}
        />
      ) : (
        <Download sx={{ fontSize: "20px" }} />
      ),
      onClick: handleDownloadAttachment,
    },
  ];

  const [attachementHeaderData, setAttachementHeaderData] = useState([
    { name: "Name", width: "23%" },
    { name: "Type", width: "20%" },
    { name: "Status" },
    { name: "Created by" },
    { name: "Created Date" },
    {},
  ]);

  const handleClickAddNote = () => {
    setOpenNoteDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setNoteId("");
    setOpenNoteDialog(false);
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleDeleteNote = (row) => {
    setDeleteNoteDialogOpen(true);
    setNoteId(row?.id);
    setNoteList([
      {
        note: row?.note,
        interaction: row?.interaction,
        added_by: row?.added_by,
        created_at: row?.created_at,
      },
    ]);
  };

  const handleDeleteNoteDialogClose = () => {
    setDeleteNoteDialogOpen(false);
    setNoteId("");
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleUpdateNote = (row) => {
    if (row?.id) {
      setNoteId(row?.id);
      setOpenNoteDialog(true);
      setNoteList([
        {
          note: row?.note,
          interaction: row?.interaction,
          added_by: row?.added_by,
        },
      ]);
    }
  };

  const fetchNotesTableList = async (jobId) => {
    setNotesTableListLoading(true);
    const res = await GET(getDameNotes(jobId));
    if (res) {
      setNotesTableList(res?.data);
      setNotesTableListLoading(false);
    }
  };

  const handleCreateNote = async () => {
    setAddNoteLoading(true);
    if (resumeId) {
      const payload = noteList;
      if (noteId) {
        const res = await PUT(updateDameNote(noteId), noteList[0]);
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      } else {
        const res = await POST(addDameNotes(resumeId), payload);
        console.log(res?.data, "note");
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      }
      setAddNoteLoading(false);
      fetchNotesTableList(resumeId);
      setOpenNoteDialog(false);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
    }
  };

  const handleDeleteNoteById = async (row) => {
    const res = await DELETE(deleteDameNote(noteId));
    console.log(res?.data, "note");
    if (res?.success) {
      console.log(res?.data?.success, "tr");
      setSnackbarState({
        open: true,
        severity: "success",
        message: res?.message,
      });
      fetchNotesTableList(resumeId);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
      setDeleteNoteDialogOpen(false);
      setNoteId("");
    } else {
    }
  };

  const fetchAttachmentTableList = async (resumeId) => {
    try {
      setAttachmentList((prev) => ({
        ...prev,
        loading: true,
      }));
      const data = await AttachementTableGetCall(resumeId);
      setAttachmentList((prev) => ({
        ...prev,
        loading: false,
        data: data?.data,
      }));
    } catch (error) {
      setAttachmentList((prev) => ({
        ...prev,
        loading: false,
      }));
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    fetchNotesTableList(resumeId);
    fetchAttachmentTableList(resumeId);
  }, [resumeId]);

  useEffect(() => {
    setEnableQuestion((prev) => ({
      ...prev,
      status:
        matchedData?.insights?.feedback?.enable_pre_screen_questions || false,
    }));
  }, [matchedData]);

  const handleNoteTextChange = (index, e) => {
    const newNoteList = [...noteList];
    newNoteList[index].note = e.target.value;
    setNoteList(newNoteList);
  };

  const handleDownloadResume = () => {
    if (resumeId) {
      setDownloadMenuLoader(true);
      GETDOWNLOAD(DownloadResume(resumeId), {
        params: { type: "match_resume" },
        responseType: "blob",
      })
        .then((response) => {
          const contentType = response.headers["content-type"];
          const extension =
            contentType === "application/pdf"
              ? "pdf"
              : contentType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? "docx"
              : "doc";

          const fileName = `${matchedData?.file_name}`;

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setDownloadMenuLoader(false);
        })
        .catch((error) => {
          if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
              const responseText = reader.result;
              try {
                const json = JSON.parse(responseText);
                const errorMessage =
                  json.error || "An unexpected error occurred.";
                setSnackbarState({
                  open: true,
                  severity: "error",
                  message: errorMessage,
                });
              } catch (e) {
                setSnackbarState({
                  open: true,
                  severity: "error",
                  message:
                    "An unexpected error occurred while parsing the error message.",
                });
              }
            };
            reader.readAsText(error.response.data);
          } else {
            setSnackbarState({
              open: true,
              severity: "error",
              message: "An unexpected error occurred.",
            });
          }
          setDownloadMenuLoader(false);
        });
    } else {
      setSnackbarState({
        open: true,
        severity: "error",
        message: "Resume was not parsed successfully. Unable to download.",
      });
      setDownloadMenuLoader(false);
    }
  };

  const handleInteractionAction = (data) => {
    setInterActionOpen(true);
    setInterActionData(data);
  };

  const handleScoreChange = (label) => {
    fetchResumeData(resumeId, queryValue - 2, "", { spider_key: label });
  };

  const handleDuplicate = async (action) => {
    let params = {
      action: action,
      match_resume_id: matchedData.id,
      resume_id: matchedData.resume_id,
    };
    setViewResume((prev) => ({
      ...prev,
      disabled: true,
      duplicateLoading: action === "duplicate" ? true : false,
      replacedLoading: action === "replaced" ? true : false,
    }));
    try {
      const data = await JobResumeDuplicate(params);
      setSnackbarState({
        open: true,
        severity: "success",
        message: data?.message,
      });
      handleViewCandidateClose();
      navigate(`/candidates/candidate-view?view-id=${data?.data?.id}`);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setViewResume((prev) => ({
        ...prev,
        disabled: false,
        duplicateLoading: false,
        replacedLoading: false,
      }));
    }
  };

  const FooterViewResume = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Duplicate"}
        variant="outlined"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        color="#344054"
        border="#D0D5DD"
        disabled={viewResume.disabled}
        loading={viewResume.duplicateLoading}
        onClick={() => handleDuplicate("duplicate")}
      />
      <CommonButton
        value={"Replace Existing"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={viewResume.disabled}
        loading={viewResume.replacedLoading}
        onClick={() => handleDuplicate("replaced")}
      />
    </CommonGrid2>
  );

  const handleViewCandidateClose = () => {
    setViewResume({
      open: false,
      disabled: false,
      duplicateLoading: false,
      replacedLoading: false,
    });
  };

  const handleViewCandidate = () => {
    setViewResume((prev) => ({
      ...prev,
      open: true,
      disabled: false,
      duplicateLoading: false,
      replacedLoading: false,
    }));
  };

  const handleView = async () => {
    setMenuViewLoader(true);
    let params = {
      match_resume_id: matchedData.id,
      resume_id: matchedData.resume_id,
    };

    try {
      const data = await JobResumeDuplicate(params);
      fetchResumeData(resumeId, queryValue - 2, "", "");
      setSnackbarState({
        open: true,
        severity: "success",
        message: data?.message,
      });

      // navigate(`/candidates/candidate-view?view-id=${data?.data?.id}`);
      setMenuViewLoader(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setMenuViewLoader(false);
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen({ open: false, loading: false });
  };

  const handleShareInvite = async (shareData) => {
    setShareDialogOpen((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      let payload = {
        url: window.location.href,
        to_mail: shareData.map((item) => item.email),
        cc_mail: [],
      };
      const data = await JobJobShare(payload, "jobResume");
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      handleShareDialogClose();
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setShareDialogOpen((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleShareClick = () => {
    setShareDialogOpen({ open: true, loading: false });
  };

  const handleRequestInfo = async () => {
    setIsRequestLoading(true);
    const url = process.env.REACT_APP_URL;

    try {
      const payload = {
        url: `${url}/coversheet?id=${resumeId}`,
        match_resume_id: resumeId,
      };
      const response = await POST(shareCoverSheet(), payload);
      setSnackbarState({
        open: true,
        message: response.message || "Request successful!",
        severity: "success",
      });
      setIsRequestLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message || "Request failed!",
        severity: "error",
      });
      setIsRequestLoading(false);
    }
  };

  const handleChangeStatus = async (e) => {
    setStatusDialog((prev) => ({
      ...prev,
      open: true,
      value: e.target.value,
    }));
  };

  const handleStatusChange = async (condition) => {
    if (condition === "no") {
      setStatusDialog((prev) => ({
        ...prev,
        noLoading: true,
        disabled: true,
      }));
      setTimeout(() => {
        handleStatusClose();
      }, 1000);
    } else if (condition === "yes") {
      try {
        setStatusDialog((prev) => ({
          ...prev,
          yesLoading: true,
          disabled: true,
        }));

        setCandidateProfileValue(statusDialog.value);
        let params = {
          status: statusDialog.value,
        };
        const data = await StatusChange(resumeId, params);
        if (statusDialog.value === "Selected") {
          navigate("/jobs");
        } else {
          await fetchData();
          handleStatusClose();
        }
      } catch (error) {
        setSnackbarState({
          open: true,
          message: error.message || "Request failed!",
          severity: "error",
        });
        setStatusDialog((prev) => ({
          ...prev,
          yesLoading: false,
          disabled: false,
        }));
      }
    }
  };
  const SatusFooter = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"No"}
        variant="outlined"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        color="#344054"
        border="#D0D5DD"
        disabled={statusDialog.disabled}
        loading={statusDialog.noLoading}
        onClick={() => handleStatusChange("no")}
      />
      <CommonButton
        value={"Yes"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={statusDialog.disabled}
        loading={statusDialog.yesLoading}
        onClick={() => handleStatusChange("yes")}
      />
    </CommonGrid2>
  );

  const handleStatusClose = () => {
    setStatusDialog({
      open: false,
      disabled: false,
      noLoading: false,
      yesLoading: false,
    });
  };

  const handleAttachment = () => {
    setAttachmentDialog((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const handleAttachmentClose = () => {
    setAttachmentDialog({
      loading: false,
      disabled: false,
      open: false,
    });
  };

  const handleAttachmentSubmit = async (values, { setFieldValue }) => {
    try {
      const updatedFiles = values.file.map((file) => ({
        ...file,
        delete: false,
      }));
      setFieldValue("file", updatedFiles);
      setAttachmentDialog((prev) => ({
        ...prev,
        loading: true,
        disabled: true,
      }));

      const formData = new FormData();

      values.file.forEach((fileObj) => {
        formData.append("file", fileObj.file);
      });
      formData.append("name", values.name);
      const data = await AttachementCreateCall(resumeId, formData);
      await fetchAttachmentTableList(resumeId);
      handleAttachmentClose();
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
    } catch (error) {
      const updatedFiles = values.file.map((file) => ({
        ...file,
        delete: true,
      }));
      setFieldValue("file", updatedFiles);
      setAttachmentDialog((prev) => ({
        ...prev,
        loading: false,
        disabled: false,
      }));
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleIconClick = (event, rowData) => {
    setAttachementRowData(rowData);
    setAnchorEl(event.currentTarget);
  };

  const handleAttachmentTableBodyData = (row, index) => (
    <>
      <TableCell>{row.name || "-"}</TableCell>
      <TableCell>{row.type || "-"}</TableCell>
      <TableCell>{row.status || "-"}</TableCell>
      <TableCell>{row.created_by || "-"}</TableCell>
      <TableCell>
        {moment(row.created_at).format("DD MMM YYYY") || "-"}
      </TableCell>
      <TableCell>
        <MoreVertOutlinedIcon
          style={{ cursor: "pointer" }}
          onClick={(e) => handleIconClick(e, row)}
        />

        <CustomizeMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={attachementOpen}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {attachmentMenuData.map((item, index) => (
            <MenuItem
              key={index}
              onClick={item.disabled ? () => {} : item.onClick}
              aria-controls={item.ariaControls}
              aria-haspopup={item.ariaHasPopup}
              aria-expanded={item.ariaExpanded}
              style={{ opacity: item.disabled ? 0.5 : 1 }}
              disabled={item?.disabled}
            >
              <StyledFlexRowView>
                {item.image}
                <Typography>{item.title}</Typography>
              </StyledFlexRowView>
            </MenuItem>
          ))}
        </CustomizeMenu>
      </TableCell>
    </>
  );

  const handelAttachmentDialogClose = () => {
    setDeleteButton({
      loading: false,
      open: false,
      content: "",
      id: "",
    });
  };

  const EnableFooter = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Leave"}
        variant="outlined"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        color="#344054"
        border="#D0D5DD"
        disabled={enableQuestion.disabled}
        loading={enableQuestion.leaveLoading}
        onClick={() => handleEnableQuestion("Leave")}
      />
      <CommonButton
        value={"Confirm"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={enableQuestion.disabled}
        loading={enableQuestion.confirmLoading}
        onClick={() => handleEnableQuestion("Confirm")}
      />
    </CommonGrid2>
  );

  const handleQuestionEnable = async (event) => {
    setEnableQuestion((prev) => ({
      ...prev,
      open: true,
      status: event.target.checked,
      content:
        event.target.checked === true
          ? "Enabling this will include these questions in the candidate's coversheet. Do you want to proceed?"
          : "Do you want to disable these questions",
    }));
  };

  const handleEnableQuestion = async (condition) => {
    if (condition === "Leave") {
      setEnableQuestion((prev) => ({
        ...prev,
        leaveLoading: true,
        disabled: true,
      }));
      setTimeout(() => {
        handleEnableQuestionClose();
      }, 1000);
    } else if (condition === "Confirm") {
      try {
        setEnableQuestion((prev) => ({
          ...prev,
          confirmLoading: true,
          disabled: true,
        }));
        let payload = {
          id: resumeId,
          status: enableQuestion.status,
        };
        const data = await JobCandidateQueationEnable(payload);
        await fetchResumeData(resumeId, queryValue - 2, "");
        handleEnableQuestionClose();
        setSnackbarState({
          open: true,
          message: data.message,
          severity: "success",
        });
      } catch (error) {
        setEnableQuestion((prev) => ({
          ...prev,
          confirmLoading: false,
          disabled: false,
        }));
        setSnackbarState({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  };

  const handleEnableQuestionClose = () => {
    setEnableQuestion({
      open: false,
      status: matchedData?.insights?.feedback?.enable_pre_screen_questions,
      disabled: false,
      confirmLoading: false,
      leaveLoading: false,
      content: "",
    });
  };

  const handleCopyMouseLeave = () => {
    setTimeout(() => setCopied(false), 200);
  };

  const handleCopyClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
    } catch (error) {}
  };

  return (
    <Grid2 container spacing={4}>
      <Grid2 size={12}>
        <Profile
          matchedData={matchedData}
          filteredResumeTab={filteredResumeTab}
          resumeId={resumeId}
          handleDownloadResume={handleDownloadResume}
          handleClickAddNote={handleClickAddNote}
          loading={resumeLoader}
          handleViewCandidate={handleViewCandidate}
          handleView={handleView}
          menuViewLoader={menuViewLoader}
          downloadMenuLoader={downloadMenuLoader}
          handleShareClick={handleShareClick}
          isRequestLoading={isRequestLoading}
          handleRequestInfo={handleRequestInfo}
          candidateProfileValue={candidateProfileValue}
          handleChangeStatus={handleChangeStatus}
          handleCopyMouseLeave={handleCopyMouseLeave}
          handleCopyClick={handleCopyClick}
          copied={copied}
          handleQuestionMarkClick={handleQuestionMarkClick}
          iconVisible={iconVisible}
          chatBotData={chatBotData}
          candidateProfileData
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          defaultOpen={true}
          title={"Matching Summary"}
          children={
            <MatchingSummary
              matchedData={matchedData}
              handleScoreChange={handleScoreChange}
              loading={resumeLoader}
              spiderGraph={true}
              handleQuestionMarkClick={handleQuestionMarkClick}
              iconVisible={iconVisible}
              chatBotData={chatBotData}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Strengths and Weaknesses"}
          children={
            <StrengthWeak
              size={6}
              matchedData={matchedData}
              loading={resumeLoader}
            />
          }
        />
      </Grid2>
      {/* {matchedData?.insights?.feedback?.accomplishments?.length !== 0 && (
        <Grid2 size={12}>
          <CommonAccordion
            title={"Accomplishments"}
            children={
              <Accomplishment
                size={6}
                matchedData={matchedData}
                loading={resumeLoader}
              />
            }
          />
        </Grid2>
      )} */}

      <Grid2 size={12}>
        <CommonAccordion
          title={"Recommendations and Next Steps"}
          children={
            <Recommendations
              matchedData={matchedData}
              loading={resumeLoader}
              handleQuestionEnable={handleQuestionEnable}
              enableQuestion={enableQuestion}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Experience"}
          children={
            <Experience
              matchedData={matchedData?.experience}
              isLoading={resumeLoader}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Notes"}
          children={
            <Notes
              disabled={!matchedData?.rice_created}
              isShown={true}
              handleClickAddNote={handleClickAddNote}
              handleDeleteNoteClick={handleDeleteNote}
              handleUpdateNoteClick={handleUpdateNote}
              notesTableList={notesTableList}
              notesTableListLoading={notesTableListLoading || resumeLoader}
              isLoading={resumeLoader}
              headerData={noteHeaderData}
            />
          }
        />
      </Grid2>

      <Grid2 size={12}>
        <Attachment
          tableData={attachmentList?.data}
          attachementHeaderData={attachementHeaderData}
          handleAttachment={handleAttachment}
          handleAttachmentTableBodyData={handleAttachmentTableBodyData}
          isLoading={resumeLoader || attachmentList.loading}
        />
      </Grid2>

      <Grid2 size={12}>
        <CommonAccordion
          title={"Interactions"}
          children={
            <Interactions
              tableLoader={resumeLoader}
              matchedData={matchedData}
              headerData={interactionHeaderdata}
              handleInteractionAction={handleInteractionAction}
            />
          }
        />
      </Grid2>
      <InteractionDialog
        open={interActionOpen}
        handleClose={() => setInterActionOpen(false)}
        interActionData={interActionData}
      />

      <DeleteNote
        deleteNoteDialogOpen={deleteNoteDialogOpen}
        handleDeleteNoteDialogClose={handleDeleteNoteDialogClose}
        noteList={noteList}
        handleDeleteNoteById={handleDeleteNoteById}
      />
      <AddUpdateNote
        openNoteDialog={openNoteDialog}
        handleCloseNoteDialog={handleCloseNoteDialog}
        id={noteId}
        handleCreateNote={handleCreateNote}
        noteList={noteList}
        handleNoteTextChange={handleNoteTextChange}
        addNoteLoading={addNoteLoading}
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />

      <ShareQuickMatch
        title="Share Candidate Profile"
        shareDialogOpen={shareDialogOpen.open}
        handleShareDialogClose={handleShareDialogClose}
        handleShareInvite={handleShareInvite}
        isLoading={shareDialogOpen.loading}
      />

      <DialogCandidateHub
        open={viewResume.open}
        handleClose={handleViewCandidateClose}
        icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        footer={<FooterViewResume />}
        title="Candidate Already Exists"
        titlepadding={"0px 0px 16px"}
        content={
          <>
            You are about to save a candidate under the name{" "}
            <strong style={{ fontWeight: 700 }}>
              {matchedData?.candidate_name}
            </strong>{" "}
            and the role{" "}
            <strong style={{ fontWeight: 700 }}>
              {matchedData?.inferred_title}
            </strong>{" "}
            in the Candidate Hub.
          </>
        }
      />

      <DialogCandidateHub
        open={statusDialog.open}
        handleButttonClose={handleStatusClose}
        footer={<SatusFooter />}
        title={"Do you want to confirm this"}
        titlepadding={"1rem 0px 0px"}
      />
      <AttachmentDialog
        open={attachmentDialog.open}
        handleClose={handleAttachmentClose}
        handleSubmit={handleAttachmentSubmit}
        attachmentDialog={attachmentDialog}
      />

      <SkillDeleteDialog
        deleteDialog={deleteButton.open}
        handleDeleteDialogClose={handelAttachmentDialogClose}
        handleDeleteSkill={handleDeleteAttachment}
        buttonLoading={deleteButton.loading}
        title="Delete Attachment"
        content={deleteButton.content}
      />

      <DialogCandidateHub
        open={enableQuestion.open}
        handleButttonClose={handleEnableQuestionClose}
        footer={<EnableFooter />}
        title={"Confirm Action"}
        content={enableQuestion.content}
        titlepadding={"0rem 0px 1rem"}
      />
    </Grid2>
  );
}

export default JobsCandidate;
