import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Dialog } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import enUS from "date-fns/locale/en-US";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CommonTextField from "../../../component/Input";
import { CommonGrid2 } from "../../common-components/StyledComponents";

const DateRangePickerComp = ({ startDateProp, endDateProp, handleChange }) => {
  let dates = moment(new Date());
  let today = dates.format("L");
  let yesterday = dates.subtract(1, "day").format("L");
  let tomorrow = dates.add(2, "days").format("L");

  const [startDate, setStartDate] = useState(startDateProp || new Date());
  const [endDate, setEndDate] = useState(endDateProp || new Date());

  const selectionRange = {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: "selection",
  };

  useEffect(() => {
    setStartDate(startDateProp || new Date());
    setEndDate(endDateProp || new Date());

    setInputText(() => {
      if (startDateProp === "NA" || startDateProp === "") {
        return endDateProp === "NA" || endDateProp === ""
          ? ""
          : moment(endDateProp).format("DD MMM YYYY");
      }
      if (endDateProp === "NA" || endDateProp === "") {
        return moment(startDateProp).format("DD MMM YYYY");
      }
      return `${moment(startDateProp).format("DD MMM YYYY")} to ${moment(
        endDateProp
      ).format("DD MMM YYYY")}`;
    });
  }, [startDateProp, endDateProp]);

  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  }

  const onClickChange = () => {
    textDisaplay();
    handleChange(
      `${moment(startDate).format("DD MMM YYYY")}`,
      `${moment(endDate).format("DD MMM YYYY")}`
    );
    setOpen(false);
  };

  // open close
  const [open, setOpen] = useState(false);
  const [inputText, setInputText] = useState();

  const textDisaplay = () => {
    if (
      moment(selectionRange.startDate).format("DD MMM YYYY") ===
      moment(selectionRange.endDate).format("DD MMM YYYY")
    ) {
      const selectedDate = moment(selectionRange.startDate).format(
        "DD MMM YYYY"
      );
      if (selectedDate === moment(today).format("DD MMM YYYY")) {
        return setInputText(selectedDate);
      } else if (selectedDate === moment(yesterday).format("DD MMM YYYY")) {
        return setInputText(selectedDate);
      } else {
        return setInputText(selectedDate);
      }
    } else {
      return setInputText(
        `${moment(selectionRange.startDate).format("DD MMM YYYY")} to ${moment(
          selectionRange.endDate
        ).format("DD MMM YYYY")}`
      );
    }
  };

  return (
    <div>
      <Box onClick={() => setOpen((open) => !open)}>
        <CommonTextField
          padding="10px"
          value={inputText}
          datePickerCalender={true}
          calenderIcon={<CalendarTodayOutlinedIcon />}
        />
      </Box>
      <Dialog open={open}>
        <DateRangePicker
          onChange={handleSelect}
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          ranges={[selectionRange]}
          direction="vertical"
          // maxDate={new Date()}
          showSelectionPreview={true}
          scroll={{ enabled: true }}
          months={1}
          locale={enUS}
        />

        <CommonGrid2
          display={"flex"}
          columnGap={1}
          justifyContent={"flex-end"}
          margin={1}
        >
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
          <Button variant="contained" color="success" onClick={onClickChange}>
            Apply
          </Button>
        </CommonGrid2>
      </Dialog>
    </div>
  );
};

export default DateRangePickerComp;
