import { URL } from "../../api/Api";
import { CANDIDATE } from "../Action";
import { catchError, GET, getToken, POST } from "../axios/Axios";

export const SearchAndListOfResumes = async (payload, params) => {
  try {
    const response = await POST(`${URL}/resume/search/filter`, payload, params);

    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const CandidateViewCall = (job_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/resume/resumedata/${job_id}`, {});
      dispatch({ type: CANDIDATE.candidateView, data: response.data });

      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const AddToJobPoolList = async () => {
  try {
    const response = await GET(`${URL}/resume/job/match/`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const AddToJobPool = async (payload) => {
  try {
    const token = await getToken();
    const response = await fetch(`${URL}/resume/job/match/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 401 || response.status === 403) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
      return;
    } else if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (error.message.includes("401") || error.message.includes("403")) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
    }
    throw catchError(error);
  }
};
