import { Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../../../component/Button";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import CommonAccordion from "../../common-components/CommonAccordion";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MixedComponent from "../mixed-component";
import General from "./component/General";
import {
  RoleCall,
  TeamGetCall,
  UserManagementPost,
  UserManagementPut,
} from "../../../redux/action/UserManagement";
import SnackBar from "../../../component/SnackBar";

const AddUser = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const query = new URLSearchParams(useLocation().search);
  const getId = query.get("id");
  const getShow = query.get("show");
  const [expandedPanel, setExpandedPanel] = useState([
    "General",
    "Information",
  ]);
  const [buttonLoading, setButtonLoading] = useState({
    disabled: false,
    loading: false,
    cancelLoading: false,
  });
  const [userData, setUserData] = useState({
    username: "",
    password: "",
    full_name: "",
    avatar: "",
    email: "",
    company_name: "",
    title: "",
    time_zone: "",
    time_zone_data: [
      { name: "Eastern Standard Time(EST)" },
      { name: "Central Standard Time(CST)" },
      { name: "Mountain Standard Time(MST)" },
      { name: "Pacific Standard Time(PST)" },
      { name: "Indian Standard Time(IST)" },
      { name: "Gulf Standard Time(GST)" },
      { name: "Greenwich Standard Time(GST)" },
    ],
    company_address: "",
    role_id: "",
    role: "",
  });

  const primaryViewData = [
    {
      title: "Full Name",
      component: "input",
      titleName: "full_name",
      placeholder: "Enter Full Name",
      placeholderFontSize: "15px",
      disabled: getShow === "view",
    },
    {
      title: "Avatar",
      component: "fileUpload",
      titleName: "avatar",
      placeholder: "Select Avatar",
      placeholderFontSize: "15px",
      disabled: true,
    },
    {
      title: "Company Name",
      component: "input",
      titleName: "company_name",
      placeholder: "Enter Company Name",
      placeholderFontSize: "15px",
      disabled: getShow === "view",
    },
    {
      title: "Title",
      component: "input",
      titleName: "title",
      placeholder: "Enter Title",
      placeholderFontSize: "15px",
      disabled: getShow === "view",
    },
    {
      title: "Email Address",
      component: "input",
      titleName: "email",
      placeholder: "Enter Email Address",
      placeholderFontSize: "15px",
      disabled: getShow === "view",
    },
    {
      title: "Timezone",
      component: "dropDown",
      titleName: "time_zone",
      dropDownData: "time_zone_data",
      placeholder: "Select Zone",
      disabled: getShow === "view",
    },
    {
      title: "Company Address",
      component: "input",
      titleName: "company_address",
      placeholder: "Enter Company Address",
      placeholderFontSize: "15px",
      disabled: getShow === "view",
    },
    {
      title: "Role",
      component: "dropDown",
      titleName: "role_id",
      dropDownData: "role",
      placeholder: "Select Role",
      valueChange: true,
      disabled: getShow === "view",
    },
  ];

  const validationSchema = Yup.object({
    username: Yup.string().required("User Name is required"),
    full_name: Yup.string().required("Full Name is required"),
    company_name: Yup.string().required("Company Name is required"),
    title: Yup.string().required("Title is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    time_zone: Yup.string().required("TimeZone is required"),
    company_address: Yup.string().required("Company Address is required"),
    role_id: Yup.string().required("Role is required"),
  });

  const handleFetchCall = async () => {
    try {
      setIsLoading(true);
      let teamData;
      if (getId) {
        teamData = await TeamGetCall(true, getId);
      }
      const data = await RoleCall();
      setUserData((prev) => ({
        ...prev,
        role: data?.roles,
        ...(getId
          ? {
              username: teamData?.data?.username,
              password: teamData?.data?.password,
              full_name: teamData?.data?.full_name,

              email: teamData?.data?.email,
              company_name: teamData?.data?.company_name,
              title: teamData?.data?.title,
              time_zone: teamData?.data?.time_zone,

              company_address: teamData?.data?.company_address,
              role_id: data?.roles?.find(
                (role) => role.name === teamData?.data?.role_name
              )?.id,
            }
          : {}),
      }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  useEffect(() => {
    handleFetchCall();
  }, []);

  const handleSubmit = async (values) => {
    setButtonLoading((prev) => ({
      ...prev,
      disabled: true,
      addLoading: true,
    }));
    let payload = {
      ...values,
    };
    try {
      const data = getId
        ? await UserManagementPut(getId, payload)
        : await UserManagementPost(payload);
      setSnackbarState({
        open: true,
        severity: "success",
        message: data.message,
      });
      setTimeout(() => {
        navigate("/user-management");
        setButtonLoading((prev) => ({
          ...prev,
          disabled: true,
          addLoading: true,
        }));
      }, 800);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.data?.error || error.message,
      });
      setButtonLoading((prev) => ({
        ...prev,
        disabled: false,
        addLoading: false,
      }));
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleChangeAccor = (panel) => (_, isExpanded) => {
    setExpandedPanel((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
    );
  };

  const handleScrollToErrorCheck = (error) => {
    setExpandedPanel((prev) => {
      const newPanels = new Set(prev);
      if (error.username || error.password) {
        newPanels.add("General");
      } else if (Object.keys(error).length !== 0) {
        newPanels.add("Information");
      }
      return Array.from(newPanels);
    });
    setTimeout(() => {
      const errorElement = document.querySelector(".Mui-error");
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);
  };

  const handleCancel = () => {
    setButtonLoading((prev) => ({
      ...prev,
      cancelLoading: true,
      disabled: true,
    }));
    setTimeout(() => {
      navigate("/user-management");
      setButtonLoading((prev) => ({
        ...prev,
        cancelLoading: false,
        disabled: false,
      }));
    }, 1000);
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={userData}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          handleSubmit(values);
        }}
      >
        {({ validateForm }) => {
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Grid2 size={6}>
                <Grid2 container spacing={2} marginBottom={"2rem"}>
                  <CommonGrid2
                    size={{ xs: 12, md: 4, sm: 12 }}
                    item
                    alignItems={"center"}
                    display={"flex"}
                    gap={"1rem"}
                  >
                    <Typography variant="h3">User Management</Typography>
                  </CommonGrid2>

                  <CommonGrid2
                    item
                    size={{ xs: 12, md: 8, sm: 12 }}
                    gap="10px"
                    display="flex"
                    justifyContent={"end"}
                  >
                    <CommonButton
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      fontWeight="600"
                      value="Cancel"
                      padding="8px 20px"
                      disabled={buttonLoading.disabled}
                      loading={buttonLoading.cancelLoading}
                      onClick={handleCancel}
                    />
                    {getShow !== "view" && (
                      <CommonButton
                        variant="contained"
                        color="white"
                        background="#7F56D9"
                        fontWeight="600"
                        value={getId ? "Update" : "Add"}
                        padding="8px 20px"
                        type="submit"
                        disabled={buttonLoading.disabled}
                        loading={buttonLoading.addLoading}
                        onClick={() => {
                          setTimeout(async () => {
                            let data = await validateForm();
                            handleScrollToErrorCheck(data);
                          }, 0);
                        }}
                      />
                    )}
                  </CommonGrid2>
                </Grid2>
              </Grid2>
              <Grid2 size={12}>
                <CommonAccordion
                  title={"General"}
                  children={<General loading={isLoading} getShow={getShow} />}
                  defaultOpen
                  open={expandedPanel.includes("General")}
                  onChange={handleChangeAccor("General")}
                />
              </Grid2>
              <Grid2 size={12} paddingTop={3}>
                <CommonAccordion
                  title={"Information"}
                  defaultOpen
                  open={expandedPanel.includes("Information")}
                  onChange={handleChangeAccor("Information")}
                  children={
                    <MixedComponent
                      editData={primaryViewData}
                      rowSize
                      loading={isLoading}
                    />
                  }
                />
              </Grid2>
            </Form>
          );
        }}
      </Formik>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </>
  );
};

export default AddUser;
