import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  DialogContent,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import validator from "validator"; // Importing email validator
import * as Yup from "yup";
import ShareIco from "../../../assets/icons/ShareIco";
import XCloseIco from "../../../assets/icons/XCloseIco";
import CommonButton from "../../../component/Button";
import DropDownSelect from "../../../component/Select";
import {
  CommonGrid2,
  StyledFlexRowView,
} from "../../common-components/StyledComponents";
import { CommonDialogStyle } from "./StyledComponent";

function ShareQuickMatch({
  shareDialogOpen,
  handleShareDialogClose,
  handleShareInvite,
  isLoading,

  title = "Share Quick Match",
  condition = false,
  statusOptions,
  CustomMenuItem,
  handleDeleteCondition,
}) {
  const [inputValue, setInputValue] = useState("");
  const handleShareDelete = (values, setFieldValue, option) => {
    const updatedValue = values.shareData.filter((item, i) => item !== option);
    setFieldValue("shareData", updatedValue);
  };

  const validationSchema = Yup.object({
    shareData: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        })
      )
      .test(
        "at-least-one-email",
        "At least one email is required",
        (value) => Array.isArray(value) && value.length > 0
      )
      .test(
        "unique-emails",
        "Duplicate emails are not allowed",
        function (value) {
          if (!Array.isArray(value)) return true;

          const emails = value.map((item) => item.email);
          const uniqueEmails = new Set(emails);

          return emails.length === uniqueEmails.size;
        }
      ),
  });

  const handleKeyDown = (e, setFieldValue, values) => {
    const inputValue = e.target.value.trim();

    // if (e.key === "Tab" && inputValue) {
    //   e.preventDefault();
    //   setInputValue("");
    //   if (validator.isEmail(inputValue)) {
    //     const newShareData = [...values.shareData, { email: inputValue }];
    //     setFieldValue("shareData", newShareData);
    //     e.target.value = "";
    //     setInputValue("");
    //   }
    // }

    // if (e.key === "Enter") {
    //   if (validator.isEmail(inputValue)) {
    //     const newShareData = [...values.shareData, { email: inputValue }];
    //     setFieldValue("shareData", newShareData);
    //     e.target.value = "";
    //   }
    // }
    if ((e.key === "Tab" || e.key === "Enter") && inputValue) {
      e.preventDefault();
      setInputValue("");

      if (validator.isEmail(inputValue)) {
        const newShareData = [
          ...values.shareData,
          { email: inputValue, type: "" },
        ];
        setFieldValue("shareData", newShareData);
        e.target.value = "";
      }
    } else if (e.key === "Backspace" && e.target.value === "") {
      const updatedShareData = [...values.shareData];
      updatedShareData.pop();
      setFieldValue("shareData", updatedShareData);
    }
  };

  return (
    <CommonDialogStyle
      maxWidth="sm"
      fullWidth
      open={shareDialogOpen}
      // onClose={handleShareDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={{ shareData: [] }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values, "sfjbsjkfbsfgjkkgsjnsgknj");
          handleShareInvite(values.shareData);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid2 container spacing={2}>
                  <Grid2 size={12}>
                    <CommonGrid2
                      display={"flex"}
                      justifyContent={"space-between"}
                      flexDirection={"row"}
                      gap={"10px"}
                      alignItems={"center"}
                    >
                      <IconButton>
                        <ShareIco size={"25"} />
                      </IconButton>
                      {/* <IconButton onClick={handleShareDialogClose}>
                        <XCloseIco />
                      </IconButton> */}
                      <CommonButton
                        onClick={handleShareDialogClose}
                        padding={"10px"}
                        background={"rgba(127, 86, 217, 0.04)"}
                        border="rgba(127, 86, 217, 0.5)"
                        height={"100%"}
                        value={<XCloseIco />}
                        boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
                      />
                    </CommonGrid2>
                  </Grid2>

                  <Grid2 size={12}>
                    <Typography
                      fontSize={"18px"}
                      color={"#101828"}
                      fontWeight={600}
                    >
                      {title}
                    </Typography>
                  </Grid2>
                  <Grid2 size={12}>
                    <StyledFlexRowView alignItems={"flex-start"}>
                      <div style={{ width: "100%" }}>
                        <Autocomplete
                          multiple
                          freeSolo
                          options={[]}
                          limitTags={2}
                          value={values.shareData}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.email}
                                {...getTagProps({ index })}
                                onDelete={() => {
                                  handleShareDelete(
                                    values,
                                    setFieldValue,
                                    option
                                  );
                                }}
                              />
                            ))
                          }
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: null,
                                  onKeyDown: (e) =>
                                    handleKeyDown(e, setFieldValue, values),
                                }}
                                placeholder="Enter Email id"
                                error={
                                  touched.shareData && Boolean(errors.shareData)
                                }
                                helperText={
                                  touched.shareData && errors.shareData
                                    ? errors.shareData
                                    : ""
                                }
                              />
                            );
                          }}
                        />
                        <span style={{ fontSize: "10px" }}>
                          (Please type the next email ID and press the 'Enter'
                          button)
                        </span>
                      </div>
                      <CommonButton
                        variant={
                          values.shareData?.length !== 0
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          values.shareData?.length !== 0 ? "white" : "#98A2B3"
                        }
                        background={
                          values.shareData?.length !== 0 ? "#7F56D9" : "#fff"
                        }
                        border={
                          values.shareData?.length !== 0 ? "none" : "#D0D5DD"
                        }
                        fontWeight="600"
                        value="Share"
                        padding={"6.5px"}
                        width={"150px"}
                        type="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      />
                    </StyledFlexRowView>
                  </Grid2>

                  {values.shareData.map((user, index) => (
                    <CommonGrid2
                      item
                      display={"flex"}
                      justifyContent={"space-between"}
                      flexDirection={"row"}
                      gap={"10px"}
                      alignItems={"center"}
                      marginTop={"8px"}
                      key={index}
                      size={12}
                    >
                      <CommonGrid2 size={6} display={"flex"}>
                        <Chip
                          avatar={
                            <Avatar
                              sx={{
                                width: "40px !important",
                                fontSize: "20px !important",
                                height: "40px !important",
                                color: "#fff !important",
                              }}
                            >
                              {user.email.charAt(0)}
                            </Avatar>
                          }
                          label={user.email}
                        />
                      </CommonGrid2>
                      <CommonGrid2
                        size={6}
                        item
                        display={"flex"}
                        justifyContent={"end"}
                        alignItems={"center"}
                      >
                        {condition && (
                          <DropDownSelect
                            marginTop="0px"
                            removeAnimation={true}
                            data={statusOptions}
                            sizeMedium={"-11px"}
                            fullWidth={false}
                            title={"Select Status"}
                            handleRenderValue={(selected) => selected}
                            valueChange={true}
                            CustomMenuItem={CustomMenuItem}
                            customStyle={{
                              "& .MuiSelect-select": {
                                padding: "4px 32px 4px 10px !important",
                                borderRadius: "6px",
                              },
                              "& label": {
                                fontSize: "13px",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                boxShadow: "0px 0px 1px 0px #101828",
                                borderWidth: "1.5px",
                              },
                              "& .MuiSelect-icon": {
                                right: "0rem",
                              },

                              width: "48%",
                            }}
                            value={user?.type || ""}
                            handleChange={(event) => {
                              const updatedShareData = values.shareData.map(
                                (item, i) =>
                                  i === index
                                    ? { ...item, type: event.target.value }
                                    : item
                              );
                              setFieldValue("shareData", updatedShareData);
                            }}
                          />
                        )}

                        <Button
                          onClick={() =>
                            handleShareDelete(values, setFieldValue, user)
                          }
                          sx={{
                            color: "#B42318",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          Remove
                        </Button>
                      </CommonGrid2>
                    </CommonGrid2>
                  ))}
                </Grid2>
              </DialogContent>
            </Form>
          );
        }}
      </Formik>
    </CommonDialogStyle>
  );
}

export default ShareQuickMatch;
