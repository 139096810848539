import { Box, Button, Grid2, Typography } from "@mui/material";
import React from "react";
import CommonButton from "../../../../component/Button";
import FileUpload from "./FileUpload";

function UploadCandidate({
  handleCandidateDragOver,
  handleCandidateDragLeave,
  handleCandidateDrop,
  handleCandidateFileUpload,
  candidateFiles,
  getFileIcon,
  isCandidateDragging,
  handleCandidateDeleteFile,
  handleGenerateUploadClick,
  tabData,
  isLoading,
  buttonDisabled,
}) {
  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Box width={"600px"} margin={"auto"}>
          <Typography fontWeight={500} fontSize={"16px"} marginBottom={2}>
            Upload Candidates
          </Typography>
          <FileUpload
            onDragOver={handleCandidateDragOver}
            onDragLeave={handleCandidateDragLeave}
            onDrop={handleCandidateDrop}
            onChange={handleCandidateFileUpload}
            isDragging={isCandidateDragging}
            files={candidateFiles}
            getFileIcon={getFileIcon}
            handleDeleteFile={handleCandidateDeleteFile}
            disabled={isLoading}
          />

          <CommonButton
            width={"100%"}
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Generate Report"
            padding={"10px 0px"}
            onClick={handleGenerateUploadClick}
            disabled={tabData[2].disabled === false || buttonDisabled}
            loading={tabData[2].disabled === false || isLoading}
          />
        </Box>
      </Grid2>
    </Grid2>
  );
}

export default UploadCandidate;
