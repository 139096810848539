import React, { useEffect, useState } from "react";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import { Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import CommonTextField from "../../../component/Input";

export default function PreScreenQuestion() {
  const { values, errors, setFieldValue, handleChange } = useFormikContext();

  const debounceTimer = React.useRef(null);
  const [questionData, setQuestionData] = useState(
    values["pre_screen_question_answers"]
  );
  useEffect(() => {
    setQuestionData(values["pre_screen_question_answers"]);
  }, [values]);

  const handleListChange = (e, index) => {
    const newAnswer = e.target.value;
    const updatedQuestions = values.pre_screen_question_answers.map((item, i) =>
      i === index ? { ...item, answer: newAnswer } : item
    );

    setQuestionData(updatedQuestions);

    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      setFieldValue("pre_screen_question_answers", updatedQuestions);
    }, 500);
  };

  return (
    <CommonGrid2 container spacing={2.5}>
      {questionData.map((item, index) => (
        <CommonGrid2
          item
          size={12}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
        >
          <Typography variant="h6">{item.question}</Typography>

          <CommonTextField
            placeholder={"Enter the answer"}
            //   disabled={item?.disabled}
            padding="13px"
            error={Boolean(
              errors["pre_screen_question_answers"]?.[index]?.answer
            )}
            // placeholderFontSize={"15px"}
            helperText={errors["pre_screen_question_answers"]?.[index]?.answer}
            value={item.answer}
            onChange={(e) => handleListChange(e, index)}
          />
        </CommonGrid2>
      ))}
    </CommonGrid2>
  );
}
