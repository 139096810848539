export default function ChatBotDownArrowIco() {
  return (
    <svg
      width="141"
      height="24"
      viewBox="0 0 141 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M23.1861 11.2988C16.5815 22.1742 6.96143 23.8412 0.264336 24L71 24L70.445 4.85203e-08L40.4469 2.67104e-06C35.424 3.11015e-06 28.8231 2.01672 23.1861 11.2988Z"
        fill="white"
      />
      <path
        d="M117.259 11.2988C123.863 22.1742 133.484 23.8412 140.181 24L70.2359 24L70 0L99.9981 -2.62252e-06C105.021 -3.06163e-06 111.622 2.01671 117.259 11.2988Z"
        fill="white"
      />
      <path
        d="M66 9.50061L71 14.5006L76 9.50061"
        stroke="#98A2B3"
        stroke-width="1.95524"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
