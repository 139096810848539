import {
  Slider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ErrorFound, HandleSkeletion } from "../../../../utils/constants";
import {
  CommonGrid2,
  StyledFlexColumnView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";

function Attributes({
  title,
  data,
  attributeHeaderData,
  tableLoader,
  success,
}) {
  const [experienceValues, setExperienceValues] = useState(
    data?.map((row) => [row.low, row.high])
  );

  useEffect(() => {
    setExperienceValues(data?.map((row) => [row.low, row.high]));
  }, [data]);

  const handleSliderChange = (index, newValue) => {
    const updatedValues = [...experienceValues];
    updatedValues[index] = newValue;
    setExperienceValues(updatedValues);
  };

  return (
    <StyledFlexColumnView>
      <Typography
        fontSize={"16px"}
        fontWeight={600}
        color={"#344054"}
        display={"flex"}
      >
        {title}
      </Typography>
      <TableContainerBorder marginTop={"0px"}>
        <Table>
          <TableHead>
            <TableRow>
              {attributeHeaderData.map((item, index) => (
                <StyledTableTitleTableCell
                  width={index == 1 && "500px"}
                  textAlign={index == 2 && "center"}
                >
                  {item}
                </StyledTableTitleTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableLoader && (
              <TableRow>
                {Array.from({ length: 3 }).map((_, index) => (
                  <TableCell key={index}>
                    <HandleSkeletion height={30} />
                  </TableCell>
                ))}
              </TableRow>
            )}
            {data &&
              data !== undefined &&
              data?.map((row, index) => (
                <TableRow key={row.skill}>
                  <StyledTableBodyTableCell>
                    {tableLoader ? <HandleSkeletion height={30} /> : row.skill}
                  </StyledTableBodyTableCell>

                  <TableCell>
                    {tableLoader ? (
                      <HandleSkeletion height={30} />
                    ) : (
                      <Slider
                        value={experienceValues[index]}
                        min={0}
                        max={10}
                        onChange={(event, newValue) =>
                          handleSliderChange(index, newValue)
                        }
                        valueLabelDisplay="auto"
                        sx={{
                          height: "8px",
                          width: "80%",
                          color: "#7F56D9",
                          "& .MuiSlider-thumb": {
                            backgroundColor: "white",
                            border: "2px solid #7F56D9",
                          },
                          "& .MuiSlider-rail": {
                            backgroundColor: "#969696",
                          },
                        }}
                      />
                    )}
                  </TableCell>

                  <StyledTableBodyTableCell textAlign={"center"}>
                    {tableLoader ? <HandleSkeletion height={30} /> : row.median}
                  </StyledTableBodyTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {(data === undefined || data?.length === 0) && !tableLoader && (
          <ErrorFound title={"No data Found..."} />
        )}
      </TableContainerBorder>

      <CommonGrid2
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"end"}
        gap={"10px"}
      >
        <Typography fontSize={"16px"} fontWeight={500} color={"#475467"}>
          {"Total :"}
        </Typography>
        <Typography fontSize={"16px"} fontWeight={"bold"} color={"#101828"}>
          {"28"}
        </Typography>
      </CommonGrid2>
    </StyledFlexColumnView>
  );
}

export default Attributes;
