import React from "react";
// import Grid from "@mui/material/Grid2";
import AuthFrame from "../../assets/images/auth-frame.png";
import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";

function AuthLayout() {
  return (
    // <>Login</>
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#FFFFFF",
        maxWidth: "550px", // Max width for the container
        width: "100%",
        paddingX: {
          xs: "16px", // Padding for small screens
          sm: "32px",
          md: "40px",
        },
        paddingY: "40px", // Padding at top and bottom
        borderRadius: "10px",

        display: "flex",
        flexDirection: "column", // Flex column for centering
        justifyContent: "center", // Center vertically
        alignItems: "center", // Center horizontally
        minHeight: "100vh", // Full viewport height
        margin: "auto",
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          padding: "16px",
          borderRadius: "15px",
        }}
      >
        {/* Left side with form */}
        <Grid item xs={12}>
          <Outlet />
        </Grid>

        {/* Right side with image */}
        {/* <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", // Ensures the image section fills the available height
            }}
          >
            <img
              src={AuthFrame}
              alt="Auth illustration"
              style={{ width: "100%", height: "608px", borderRadius: "10px" }}
            />
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default AuthLayout;
