import { Menu, MenuItem, styled, Typography } from "@mui/material";
import React from "react";
import { StyledFlexRowView } from "../../modules/common-components/StyledComponents";

const CustomMenu = styled(Menu)(({ boxShadow }) => ({
  // marginLeft: "-0px",
  marginTop: "8px",

  "& .MuiMenu-paper": {
    borderRadius: "10px",
    border: "1px solid #EAECF0",
    boxShadow:
      boxShadow ||
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
  "& .MuiMenuItem-root": {
    padding: "8px 16px",
  },
}));
const CustomMenuItem = ({
  handleMenuClose,
  anchorEl,
  open,
  menuData,
  boxShadow,
}) => {
  return (
    <CustomMenu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      boxShadow={boxShadow}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {menuData.map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            if (!item.disabled) {
              item.onClick?.();
              handleMenuClose();
            }
          }}
          disabled={item.disabled}
        >
          <StyledFlexRowView>
            {item.image}
            <Typography>{item.title}</Typography>
          </StyledFlexRowView>
        </MenuItem>
      ))}
    </CustomMenu>
  );
};
export default CustomMenuItem;
