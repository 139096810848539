function DotsGrid() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0007 5.00065C10.4609 5.00065 10.834 4.62755 10.834 4.16732C10.834 3.70708 10.4609 3.33398 10.0007 3.33398C9.54041 3.33398 9.16732 3.70708 9.16732 4.16732C9.16732 4.62755 9.54041 5.00065 10.0007 5.00065Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0007 10.834C10.4609 10.834 10.834 10.4609 10.834 10.0007C10.834 9.54041 10.4609 9.16732 10.0007 9.16732C9.54041 9.16732 9.16732 9.54041 9.16732 10.0007C9.16732 10.4609 9.54041 10.834 10.0007 10.834Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0007 16.6673C10.4609 16.6673 10.834 16.2942 10.834 15.834C10.834 15.3737 10.4609 15.0007 10.0007 15.0007C9.54041 15.0007 9.16732 15.3737 9.16732 15.834C9.16732 16.2942 9.54041 16.6673 10.0007 16.6673Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.834 5.00065C16.2942 5.00065 16.6673 4.62755 16.6673 4.16732C16.6673 3.70708 16.2942 3.33398 15.834 3.33398C15.3737 3.33398 15.0007 3.70708 15.0007 4.16732C15.0007 4.62755 15.3737 5.00065 15.834 5.00065Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.834 10.834C16.2942 10.834 16.6673 10.4609 16.6673 10.0007C16.6673 9.54041 16.2942 9.16732 15.834 9.16732C15.3737 9.16732 15.0007 9.54041 15.0007 10.0007C15.0007 10.4609 15.3737 10.834 15.834 10.834Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.834 16.6673C16.2942 16.6673 16.6673 16.2942 16.6673 15.834C16.6673 15.3737 16.2942 15.0007 15.834 15.0007C15.3737 15.0007 15.0007 15.3737 15.0007 15.834C15.0007 16.2942 15.3737 16.6673 15.834 16.6673Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16732 5.00065C4.62755 5.00065 5.00065 4.62755 5.00065 4.16732C5.00065 3.70708 4.62755 3.33398 4.16732 3.33398C3.70708 3.33398 3.33398 3.70708 3.33398 4.16732C3.33398 4.62755 3.70708 5.00065 4.16732 5.00065Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16732 10.834C4.62755 10.834 5.00065 10.4609 5.00065 10.0007C5.00065 9.54041 4.62755 9.16732 4.16732 9.16732C3.70708 9.16732 3.33398 9.54041 3.33398 10.0007C3.33398 10.4609 3.70708 10.834 4.16732 10.834Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16732 16.6673C4.62755 16.6673 5.00065 16.2942 5.00065 15.834C5.00065 15.3737 4.62755 15.0007 4.16732 15.0007C3.70708 15.0007 3.33398 15.3737 3.33398 15.834C3.33398 16.2942 3.70708 16.6673 4.16732 16.6673Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DotsGrid;
