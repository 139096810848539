import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Switch, Tooltip, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { AutocompleteText } from "../../../component/AutoComplete";
import CommonTextField from "../../../component/Input";
import CommonPhoneInput from "../../../component/PhoneInput";
import DropDownSelect from "../../../component/Select";
import { HandleSkeletion } from "../../../utils/constants";
import { CustomDiv } from "../../chat-bot/style";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import DateRangePickerComp from "../../jobs/common-card.js/DatePicker";
import { ErrorContent } from "../../jobs/style";
import { CustomDatePicker } from "./style";

const MixedComponent = ({ editData, loading, marginTop }) => {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const { values, errors, setFieldValue, handleBlur } = useFormikContext();

  const [localValue, setLocalValue] = React.useState({
    first_name: values.first_name || "",
    middle_name: values.middle_name || "",
    last_name: values.last_name || "",
    title: values.title || "",
    location: values.location || "",
    job_location: values.job_location || "",
    email: values.email || "",
    phone_number: values.phone_number || "",
    department: values.department || "",
    manager_name: values.manager_name || "",
    emplopyer_name: values.emplopyer_name || "",
    emplopyer_phone: values.emplopyer_phone || "",
    emplopyer_email: values.emplopyer_email || "",
    emplopyer_contact_name: values.emplopyer_contact_name || "",
    H1B_holder_company_name: values.H1B_holder_company_name || "",
    min: values["rate"]?.["min"] || "",
    max: values["rate"]?.["max"] || "",
    linkedin_url: values.linkedin_url || "",
    portfolio: values.portfolio || "",
    hour_rate: values.hour_rate || "",
    contract_hour_rate: values.contract_hour_rate || "",
    employee_hour_rate: values.employee_hour_rate || "",
    certificates_value: values.certificates_value || "",
    industry_value: values.industry_value || "",
    languages_value: values.languages_value || "",
    notice_period: values.notice_period || "",
  });

  useEffect(() => {
    setLocalValue({
      first_name: values.first_name || "",
      middle_name: values.middle_name || "",
      last_name: values.last_name || "",
      title: values.title || "",
      location: values.location || "",
      job_location: values.job_location || "",
      email: values.email || "",
      phone_number: values.phone_number || "",
      department: values.department || "",
      manager_name: values.manager_name || "",
      emplopyer_name: values.emplopyer_name || "",
      emplopyer_phone: values.emplopyer_phone || "",
      emplopyer_email: values.emplopyer_email || "",
      emplopyer_contact_name: values.emplopyer_contact_name || "",
      H1B_holder_company_name: values.H1B_holder_company_name || "",
      min: values["rate"]?.["min"] || "",
      max: values["rate"]?.["max"] || "",
      linkedin_url: values.linkedin_url || "",
      portfolio: values.portfolio || "",
      hour_rate: values.hour_rate || "",
      contract_hour_rate: values.contract_hour_rate || "",
      employee_hour_rate: values.employee_hour_rate || "",
      certificates_value: values.certificates_value || "",
      industry_value: values.industry_value || "",
      languages_value: values.languages_value || "",
      notice_period: values.notice_period || "",
    });
  }, [values]);

  const debounceTimer = React.useRef(null);

  const handleInputChange = (e, item) => {
    setLocalValue((prev) => ({
      ...prev,
      [item.titleName]: e.target.value,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setFieldValue(item.titleName, e.target.value);
    }, 500);
  };

  const handleCurrencyChange = (e, item) => {
    setLocalValue((prev) => ({
      ...prev,
      [item.currency]: e.target.value,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      const updatedPayRate = {
        ...values[item.titleName],
        [item.currency]: e.target.value,
      };
      setFieldValue(item.titleName, updatedPayRate);
    }, 500);
  };

  const handleNumberChange = (e, countryData, item) => {
    const getValue = e;
    setLocalValue((prev) => ({
      ...prev,
      [item.titleName]: e,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setFieldValue(item.country, countryData);
      setFieldValue(item.titleName, getValue);
    }, 300);
  };

  return (
    <CommonGrid2
      container
      columnSpacing={3}
      rowSpacing={loading ? 0 : 3}
      marginTop={marginTop}
    >
      {editData?.map((item, index) => {
        return (
          <CommonGrid2
            item
            size={{
              xs: 12,
              md: item.customSize ? item.customSize : item.size ? 6 : 4,
              sm: 6,
            }}
            key={index + item.title}
            gap={"8px"}
            display={"flex"}
            flexDirection={item.component === "switch" ? "row" : "column"}
            justifyContent={"flex-start"}
          >
            {loading ? (
              <HandleSkeletion height={100} />
            ) : (
              <>
                {item.component !== "split-screen" && (
                  <CommonGrid2
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Tooltip arrow title={item.title} placement="top">
                      <Typography
                        variant="p"
                        color="#344054"
                        fontSize="16px"
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                      >
                        {item.title}
                      </Typography>
                    </Tooltip>

                    {item.type === "additional" && (
                      <Field name={item.toggleName}>
                        {({ field, form }) => {
                          const toggleValue =
                            form.values[item.toggleName] || false;
                          return (
                            <Switch
                              checked={toggleValue}
                              disabled={item.disabled}
                              onChange={(event) => {
                                form.setFieldValue(
                                  item.toggleName,
                                  event.target.checked
                                );
                              }}
                            />
                          );
                        }}
                      </Field>
                    )}
                  </CommonGrid2>
                )}
                {item.component === "input" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          placeholder={
                            item?.placeholder ? item?.placeholder : item.title
                          }
                          disabled={item?.disabled}
                          padding="10px"
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          placeholderFontSize={item?.placeholderFontSize}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={localValue[item.titleName]}
                          onChange={(e) => handleInputChange(e, item)}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "split-screen" && (
                  <CommonGrid2 container spacing={2}>
                    {item.data.map((innerItem) => (
                      <CommonGrid2
                        size={innerItem.size}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"8px"}
                      >
                        <Tooltip arrow title={innerItem.title} placement="top">
                          <Typography
                            variant="p"
                            color="#344054"
                            fontSize="16px"
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                            overflow={"hidden"}
                          >
                            {innerItem.title}
                          </Typography>
                        </Tooltip>
                        {innerItem.component === "phone-number" && (
                          <Field name={innerItem.titleName}>
                            {({ field, form }) => {
                              const isError = Boolean(
                                form.touched[innerItem.titleName] &&
                                  form.errors[innerItem.titleName]
                              );
                              return (
                                <>
                                  <CommonPhoneInput
                                    placeholder={innerItem?.placeholder}
                                    padding="14px 0px 14px 60px"
                                    country="us"
                                    value={localValue[innerItem.titleName]}
                                    onChange={(e, countryData) => {
                                      handleNumberChange(
                                        e,
                                        countryData,
                                        innerItem
                                      );
                                    }}
                                  />
                                  {isError && (
                                    <ErrorContent
                                      margin="-2px 0px 0px"
                                      className="Mui-error"
                                    >
                                      {form.errors[innerItem.titleName]}
                                    </ErrorContent>
                                  )}
                                </>
                              );
                            }}
                          </Field>
                        )}
                        {innerItem.component === "input" && (
                          <Field name={item.titleName}>
                            {({ field, form }) => {
                              return (
                                <CommonTextField
                                  {...field}
                                  placeholder={
                                    innerItem?.placeholder
                                      ? innerItem?.placeholder
                                      : innerItem.title
                                  }
                                  disabled={innerItem?.disabled}
                                  padding="12px"
                                  error={Boolean(
                                    form.touched[innerItem.titleName] &&
                                      form.errors[innerItem.titleName]
                                  )}
                                  placeholderFontSize={
                                    innerItem?.placeholderFontSize
                                  }
                                  helperText={
                                    form.touched[innerItem.titleName] &&
                                    form.errors[innerItem.titleName]
                                  }
                                  maxLength={5}
                                  value={localValue[innerItem.titleName]}
                                  onChange={(e) =>
                                    handleInputChange(e, innerItem)
                                  }
                                />
                              );
                            }}
                          </Field>
                        )}
                      </CommonGrid2>
                    ))}
                  </CommonGrid2>
                )}
                {item.component === "dropDown" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleDropdownChange = (event) => {
                        form.setFieldValue(item.titleName, event.target.value);
                      };

                      return (
                        <DropDownSelect
                          marginTop="0px"
                          title={
                            item?.placeholder ? item?.placeholder : item.title
                          }
                          disabled={item?.disabled}
                          removeAnimation={true}
                          data={form.values[item.dropDownData]}
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={form.values[item.titleName]}
                          handleChange={handleDropdownChange}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "calender" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const isError =
                        form.touched[item.titleName] &&
                        Boolean(form.errors[item.titleName]);

                      const helperText = isError
                        ? form.errors[item.titleName]
                        : "";

                      return (
                        <>
                          <DateRangePickerComp
                            startDateProp={
                              form.values?.[item.titleName]?.start_date
                            }
                            endDateProp={
                              form.values?.[item.titleName]?.end_date
                            }
                            handleChange={(start_date, end_date) => {
                              form.setFieldValue(item.titleName, {
                                start_date: start_date,
                                end_date: end_date,
                              });
                            }}
                            disabled={item.disabled}
                            error={isError}
                          />
                          {isError && <ErrorContent>{helperText}</ErrorContent>}
                        </>
                      );
                    }}
                  </Field>
                )}

                {item.component === "autoComplete" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleAutoCompleteChange = (e, newValue) => {
                        const checkData = form?.values[item.titleName];
                        if (e?.key === "Backspace" && e.target.value === "") {
                          const updatedValue = [
                            ...(form.values[item.titleName] || []),
                          ];
                          updatedValue.pop();
                          form.setFieldValue(item.titleName, updatedValue);
                        } else {
                          const formattedValues = newValue.map(
                            (item, index) => {
                              if (typeof item === "string") {
                                return {
                                  content: item,
                                  ...(checkData[0]?.name
                                    ? { name: "inferred" }
                                    : { id: index }),
                                };
                              }
                              return item;
                            }
                          );

                          form.setFieldValue(item.titleName, formattedValues);
                        }
                      };

                      const handleDelete = (option) => {
                        const updatedValue = form.values[item.titleName].filter(
                          (item, i) => item !== option
                        );
                        form.setFieldValue(item.titleName, updatedValue);
                      };

                      const handleKeyDown = (e) => {
                        const checkData = form?.values[item.titleName];
                        const dataValue = e.target.value;
                        if (e.key === "Tab") {
                          e.preventDefault();
                          setLocalValue((prev) => ({
                            ...prev,
                            [item.inputValue]: "",
                          }));

                          if (dataValue !== "") {
                            const data = {
                              content: dataValue,
                              ...(checkData[0]?.name
                                ? { name: "inferred" }
                                : { id: index }),
                            };

                            const formattedValues = [...checkData, data];
                            form.setFieldValue(item.titleName, formattedValues);
                            form.setFieldValue(item.inputValue, "");
                          }
                        }
                      };

                      const isError =
                        form.touched[item.titleName] &&
                        form.errors[item.titleName];
                      const helperText = isError
                        ? form.errors[item.titleName]
                        : "";
                      return (
                        <>
                          <AutocompleteText
                            autoData={{
                              options: [],
                              key: `autocomplete-${index}`,
                              title: "Select Emails",
                              placeholder: item.placeholder,
                              isDisabled: item.disabled,
                              value: form.values[item.titleName] || "",
                              onDelete: handleDelete,
                              onKeyDown: handleKeyDown,
                              onChange: handleAutoCompleteChange,

                              inputValue: localValue[item.inputValue],
                              onInputChange: (e, newValue) => {
                                setLocalValue((prev) => ({
                                  ...prev,
                                  [item.inputValue]: newValue,
                                }));
                              },
                              limitTags: item.disabled
                                ? form.values[item.titleName]?.length
                                : true,
                            }}
                            error={isError}
                          />
                          {isError && (
                            <ErrorContent margin={"-5px 0px 0px"}>
                              {helperText}
                            </ErrorContent>
                          )}
                        </>
                      );
                    }}
                  </Field>
                )}

                {item.component === "fileUpload" && (
                  <CommonTextField
                    readOnly={true}
                    handleFileChange={handleFileChange}
                    value={fileName}
                    padding="10px"
                  />
                )}

                {item.component === "input-link" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          disabled={item.disabled}
                          placeholder={item.placeholder}
                          padding="10px"
                          startIcon={"http://"}
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          placeholderFontSize={item?.placeholderFontSize}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={localValue[item.titleName]}
                          onChange={(e) => handleInputChange(e, item)}
                          customStyle={{
                            "& .MuiInputAdornment-root": {
                              maxHeight: "none",
                              height: "100%",
                              borderRight: "1px solid #D0D5DD",
                              paddingRight: "10px",
                            },
                          }}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "input-link-linkedin" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          placeholder={item.placeholder}
                          padding="10px"
                          // endIcon={form.values["first_name"]}
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          placeholderFontSize={item?.placeholderFontSize}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={localValue[item.titleName]}
                          onChange={(e) => handleInputChange(e, item)}
                          disabled={item.disabled}
                          customStyle={{
                            "& .MuiInputAdornment-root": {
                              maxHeight: "none",
                              height: "100%",
                              borderLeft: "1px solid #D0D5DD",
                              paddingLeft: "1rem",
                            },
                          }}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "single-calender" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const isError =
                        form.touched[item.titleName] &&
                        Boolean(form.errors[item.titleName]);

                      const helperText = isError
                        ? form?.errors[item.titleName]
                        : "";

                      return (
                        <>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CustomDatePicker
                              disabled={item.disabled}
                              onChange={(newValue) => {
                                const formattedDate = newValue
                                  ? dayjs(newValue).format("DD MMM YYYY")
                                  : "";

                                form.setFieldValue(
                                  item?.titleName,
                                  formattedDate
                                );
                              }}
                              value={
                                form?.values?.[item?.titleName]
                                  ? dayjs(
                                      form.values[item.titleName],
                                      "DD MMM YYYY"
                                    )
                                  : null
                              }
                              format={"DD MMM YYYY"}
                              error={isError}
                              views={["year", "month", "day"]}
                              slotProps={{
                                textField: {
                                  disabled: true,
                                  inputProps: { readOnly: true },
                                  sx: {
                                    "& .MuiInputBase-input": {
                                      backgroundColor: "#f0f0f0",
                                      color: "#666",
                                      cursor: "not-allowed",
                                    },
                                  },
                                },
                              }}
                              slots={{
                                openPickerIcon: () => (
                                  <CommonGrid2
                                    display={"flex"}
                                    fontSize={"15px"}
                                    gap={"5px"}
                                    alignItems={"center"}
                                    color={"#000"}
                                    fontWeight={600}
                                  >
                                    <CalendarTodayIcon
                                      style={{ height: "20px" }}
                                    />
                                    <Typography
                                      fontWeight={600}
                                      fontSize={"13px"}
                                    >
                                      Select
                                    </Typography>
                                  </CommonGrid2>
                                ),
                              }}
                            />
                          </LocalizationProvider>
                          {isError && (
                            <ErrorContent
                              margin={"-5px 0px 0px"}
                              className="Mui-error"
                            >
                              {helperText}
                            </ErrorContent>
                          )}
                        </>
                      );
                    }}
                  </Field>
                )}
                {item.component === "dob_month" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const isError =
                        form.touched[item.titleName] &&
                        Boolean(form.errors[item.titleName]);

                      const helperText = isError
                        ? form?.errors[item.titleName]
                        : "";

                      return (
                        <>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CustomDatePicker
                              disabled={item.disabled}
                              onChange={(newValue) => {
                                const formattedDate = newValue
                                  ? dayjs(newValue).format("DD MMM")
                                  : "";

                                form.setFieldValue(
                                  item?.titleName,
                                  formattedDate
                                );
                              }}
                              value={
                                form?.values?.[item?.titleName]
                                  ? dayjs(form.values[item.titleName], "DD MMM")
                                  : null
                              }
                              openTo="month"
                              format={"DD MMM"}
                              error={isError}
                              views={["month", "day"]}
                              slots={{
                                calendarHeader: (props) => (
                                  <CustomDiv
                                    padding={"1rem 2rem"}
                                    fontSize="16px"
                                    fontWeight={600}
                                  >
                                    {dayjs(props.currentMonth).format("MMMM")}
                                  </CustomDiv>
                                ),
                                openPickerIcon: () => (
                                  <CommonGrid2
                                    display={"flex"}
                                    fontSize={"15px"}
                                    gap={"5px"}
                                    alignItems={"center"}
                                    color={"#000"}
                                    fontWeight={600}
                                  >
                                    <CalendarTodayIcon
                                      style={{ height: "20px" }}
                                    />
                                  </CommonGrid2>
                                ),
                              }}
                              slotProps={{
                                textField: {
                                  placeholder: "DD MMM",
                                  disabled: true,
                                  inputProps: { readOnly: true },
                                  sx: {
                                    "& .MuiInputBase-input": {
                                      backgroundColor: "#f0f0f0",
                                      color: "#666",
                                      cursor: "not-allowed",
                                    },
                                  },
                                },
                                popper: {
                                  sx: {
                                    "& .MuiDateCalendar-root": {
                                      height: "auto",
                                      marginBottom: "1rem",
                                    },
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                          {isError && (
                            <ErrorContent
                              margin={"-5px 0px 0px"}
                              className="Mui-error"
                            >
                              {helperText}
                            </ErrorContent>
                          )}
                        </>
                      );
                    }}
                  </Field>
                )}
                {item.component === "hour" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          placeholder={item.placeholder}
                          onChange={(e) => handleInputChange(e, item)}
                          padding="10px"
                          disabled={item.disabled}
                          error={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          placeholderFontSize={item?.placeholderFontSize}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          startIcon={"$"}
                          value={localValue[item.titleName]}
                        />
                      );
                    }}
                  </Field>
                )}

                {item.component === "phone-number" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const isError = Boolean(
                        form.touched[item.titleName] &&
                          form.errors[item.titleName]
                      );
                      return (
                        <>
                          <CommonPhoneInput
                            placeholder={item?.placeholder}
                            padding="14px 0px 14px 60px"
                            country="us"
                            value={localValue[item.titleName]}
                            onChange={(e, countryData) => {
                              handleNumberChange(e, countryData, item);
                            }}
                          />
                          {isError && (
                            <ErrorContent
                              margin="-2px 0px 0px"
                              className="Mui-error"
                            >
                              {form.errors[item.titleName]}
                            </ErrorContent>
                          )}
                        </>
                      );
                    }}
                  </Field>
                )}
              </>
            )}
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
};
export default MixedComponent;
