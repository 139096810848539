import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { TableCell } from "@mui/material";
import { useState } from "react";
import BoxEditIco from "../../../assets/icons/BoxEditIco";
import DeleteIco from "../../../assets/icons/DeleteIco";
import CustomMenuItem from "../../../component/Menu";
import CommonTable from "../../candidates/Table";


export default function Groups() {
    const [selectedRows, setSelectedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClickDot = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuData = [
        {
            title: "Edit Group",
            image: <BoxEditIco />,
        },
        { title: "Delete Group", image: <DeleteIco /> },
    ];

    const sortedData = [
        { group: "Olivia Rhye", people: "1", date: "Oct 10, 2024" },
        { group: "Lana Steiner", people: "12", date: "Oct 10, 2024" },
        { group: "Kate Morrison", people: "10", date: "Oct 10, 2024" }

    ];
    const headerData = ["Group", "People", "Creation Date", ""];

    const handleRowCheckboxChange = (row) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(row.group)) {
                return prevSelectedRows.filter((group) => group !== row.group);
            } else {
                return [...prevSelectedRows, row.group];
            }
        });
    };

    const handleTableBodyData = (row) => {
        return (
            <>

                <TableCell>
                    {row.group}
                </TableCell>

                <TableCell>
                    {row.people}
                </TableCell>

                <TableCell>{row.date}</TableCell>

                <TableCell width={"2%"} aria-haspopup="true">
                    <MoreVertOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) => handleClickDot(event)}
                    />
                </TableCell>
            </>
        );
    };

    return (
        <>

            <CommonTable
                data={sortedData}
                columns={headerData}
                onRowCheckboxChange={handleRowCheckboxChange}
                handleTableBodyData={handleTableBodyData}
            />
            <CustomMenuItem
                open={open}
                anchorEl={anchorEl}
                handleMenuClose={handleMenuClose}
                menuData={menuData}
            />
        </>
    );
}
