import React from "react";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import { Typography } from "@mui/material";
import CommonButton from "../../../../component/Button";
import { CustomDiv } from "../../../chat-bot/style";
import CommonTable from "../../../candidates/Table";

export default function Attachment({
  tableData,
  attachementHeaderData,
  handleAttachment,
  handleAttachmentTableBodyData,
  isLoading,
  disabled,
}) {
  return (
    <CustomDiv>
      <CommonGrid2 display={"flex"} alignItems={"center"}>
        <Typography
          fontSize={"20px"}
          fontWeight={600}
          color={"#101828"}
          display={"flex"}
          alignItems={"center"}
          width={"100%"}
        >
          Attachments
        </Typography>
        <CommonButton
          value="Add Attachments"
          padding="10px 2rem"
          fontWeight="600"
          onClick={handleAttachment}
          disabled={isLoading || disabled}
        />
      </CommonGrid2>

      <CommonTable
        data={tableData}
        columns={attachementHeaderData}
        handleTableBodyData={handleAttachmentTableBodyData}
        loadingRowLength={2}
        loading={isLoading}
      />
    </CustomDiv>
  );
}
