export default function JobPenIco() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        fill="#DBFAE6"
      />
      <g clip-path="url(#clip0_759_39930)">
        <path
          d="M8.15026 9.98697L7.66639 16.2654C7.65481 16.427 7.69605 16.5882 7.78496 16.724L10.9615 12.8951C10.477 12.3499 10.4622 11.533 10.9276 10.9712C12.2327 9.5084 14.4001 11.3096 13.2014 12.8576C12.7368 13.415 11.9367 13.5536 11.3113 13.1853L8.13473 17.0142C8.28494 17.076 8.45178 17.0867 8.60882 17.0443L14.6885 15.4113C14.9647 15.3355 15.1736 15.1095 15.2281 14.8284L15.8321 11.6944C15.8143 11.6944 11.608 8.18989 11.5922 8.1768L8.62489 9.34734C8.35747 9.45255 8.17316 9.70051 8.15022 9.98712L8.15026 9.98697Z"
          fill="#079455"
        />
        <path
          d="M12.8536 12.5687C13.1699 12.1313 13.0928 11.524 12.6774 11.1793C12.262 10.8347 11.6508 10.871 11.2794 11.2627C10.4474 12.3296 11.9585 13.5802 12.8536 12.5687Z"
          fill="#079455"
        />
        <path
          d="M11.932 7.8705L16.0705 11.3039C16.3604 11.5444 16.79 11.5044 17.0306 11.2145L18.2219 9.77849C18.4625 9.48857 18.4224 9.05892 18.1325 8.8184L13.994 5.38499C13.7041 5.14447 13.2745 5.18447 13.034 5.47439L11.8426 6.91042C11.6021 7.20033 11.6421 7.62998 11.932 7.8705Z"
          fill="#079455"
        />
        <path
          d="M8.55285 16.9365L8.55371 16.9364L11.4924 14.1773L14.6279 13.5302L14.816 11.7373L11.7376 9.18328L10.395 10.0185L9.95318 12.9003C9.20829 13.7981 7.73205 15.7345 7.78416 16.2978C7.84932 17.002 7.8496 17.002 8.55285 16.9365Z"
          fill="#079455"
        />
      </g>
    </svg>
  );
}
