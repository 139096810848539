import React, { useEffect, useState } from "react";
import SnackBar from "../../component/SnackBar";
import { Grid2, IconButton, TableCell, Typography } from "@mui/material";
import { CommonGrid2 } from "../common-components/StyledComponents";
import ToogleButton from "../../component/ToogleButton";
import { Form, Formik } from "formik";
import CommonAccordion from "../common-components/CommonAccordion";
import { CustomDiv } from "../chat-bot/style";
import MixedComponent from "./mixed-component";
import CommonButton from "../../component/Button";
import CommonTable from "../candidates/Table";
import EyeIco from "../../assets/icons/EyeIco";
import DeleteIco from "../../assets/icons/DeleteIco";
import AddBranch from "./AddBranch";
import { CommonTippy, CustomizeTableCell } from "../jobs/style";
import SkillDeleteDialog from "../jobs/job-information/skills/SkillDeleteDialog";
import AddMember from "./AddMember";
import * as Yup from "yup";
import {
  AgnecyDetailsGetCall,
  AgnecyDetailsUpdateCall,
} from "../../redux/action/AgencyInformation";
import CommonCard from "./mixed-component/ViewData";
import parsePhoneNumber from "libphonenumber-js";

export default function AgencyInformation() {
  const [expandedPanel, setExpandedPanel] = useState(["Primary Information"]);
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [activePage, setActivePage] = useState("View");
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    id: "",
  });
  const [addBranchDialog, setAddBranchDialog] = useState({
    open: false,
    loading: false,
    content: "",
    index: "",
  });
  const [addMemberDialog, setAddMemberDialog] = useState({
    open: false,
    loading: false,
    content: "",
    index: "",
  });

  const [agencyDelete, setAgencyDelete] = useState({
    open: false,
    loading: false,
    content: "",
    index: "",
    name: "",
    condition: "",
  });

  const headerBranchData = [
    { name: "Office No.", width: "20%" },
    { name: "City", width: "25%" },
    { name: "State", width: "25%" },
    { name: "Country", width: "30%" },
    ...(activePage === "Edit" ? [{ width: "10%" }] : []),
  ];

  const headerMemberData = [
    { name: "No." },
    { name: "Name" },
    { name: "Title" },
    { name: "Phone" },
    { name: "Email" },
    { name: "Remarks" },
    ...(activePage === "Edit" ? [{ width: "0%" }] : []),
  ];

  const ToogleData = [
    {
      name: "View",
      value: "View",
    },
    { name: "Edit", value: "Edit" },
  ];
  const details = {
    organization_name: "",
    display_name: "",
    agency_id: "",
    primary_country: "",
    primary_currency: "",
    primary_currency_data: [{ name: "INR" }, { name: "USD" }],
    primary_address: "",
    email_address: "",
    contact_number: "",
    website: "",
    business_classification: "",
    business_classification_data: [
      { name: "Individual" },
      { name: "Partnership" },
      { name: "C OR S CORP AND LLC (under LLC-Partnership, C or S)" },
    ],
    record_status: "",
    record_status_data: [{ name: "Active" }, { name: "Inactive" }],
    branch_location: [],

    diversity_status: "",
    diversity_status_data: [
      { name: "MINORITY" },
      { name: "WOMAN OWNED" },
      { name: "VETERAN" },
      { name: "SMALL BUSINESS" },
    ],
    id: "",
    contact_details: [],
    contact_number_country: "",
  };

  const [informationData, setInformationData] = useState(details);

  const validationSchema = Yup.object({
    organization_name: Yup.string().required("Business Name is required"),
    display_name: Yup.string().required("Business Display Name is required"),
    agency_id: Yup.string().required("Agency Id is required"),
    primary_country: Yup.string().required("Primary Country Id is required"),
    primary_currency: Yup.string().required("Primary Currency Id is required"),
    primary_address: Yup.string().required("Address is required"),
    email_address: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    // contact_number: Yup.string()
    //   .matches(/^(?!0+$)\d{10}$/, "Invalid Phone number")
    //   .required("Phone Number is required"),

    contact_number: Yup.string()
      .required("Phone number is required")
      .test(
        "is-valid-phone",
        "Phone number is not valid for the selected country",
        function (value) {
          const { contact_number_country } = this.parent;
          if (!value || !contact_number_country?.countryCode) return false;
          const formattedValue = value.startsWith("+") ? value : `+${value}`;
          const phoneNumber = parsePhoneNumber(
            formattedValue,
            contact_number_country.countryCode
          );
          return phoneNumber?.isValid();
        }
      ),
    website: Yup.string()
      .required("Website is required")
      .test(
        "is-valid-website",
        "Enter a valid website (e.g., www.example.com or https://example.com)",
        (value) => {
          if (!value) return false;
          const wwwPattern =
            /^www\.[a-zA-Z0-9-]{1,256}\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?$/;
          const httpsPattern =
            /^(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

          return wwwPattern.test(value) || httpsPattern.test(value);
        }
      ),
    business_classification: Yup.string().required(
      "Bussiness Classification is required"
    ),
    record_status: Yup.string().required("Record Status Id is required"),
    diversity_status: Yup.string().required("Diversity Status is required"),
    branch_location: Yup.array().min(
      1,
      "At least one office information is required"
    ),
    contact_details: Yup.array().min(
      1,
      "At least one contact details is required"
    ),
  });

  const fetchViewCall = async () => {
    setIsLoading(true);

    try {
      let data = await AgnecyDetailsGetCall();
      setInformationData((prev) => ({
        ...prev,
        organization_name: data?.data?.organization_name,
        display_name: data?.data?.display_name,
        agency_id: data?.data?.agency_id,
        primary_country: data?.data?.primary_country,
        primary_currency: data?.data?.primary_currency || "",
        primary_address: data?.data?.primary_address,
        email_address: data?.data?.email_address,
        contact_number: data?.data?.contact_number,
        website: data?.data?.website,
        business_classification: data?.data?.business_classification || "",
        record_status: data?.data?.record_status || "",
        branch_location: data?.data?.branch_location,
        diversity_status: data?.data?.diversity_status || "",
        contact_details: data?.data?.contact_details,
        contact_number_country: data?.data?.contact_number_country,
        id: data?.data?.id,
      }));
      setIsLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (activePage === "View") {
      setInformationData(details);
      fetchViewCall();
    }
  }, [activePage]);

  const handleBranchTableBodyData = (row, index) => {
    return (
      <>
        <CustomizeTableCell>{index + 1}</CustomizeTableCell>
        <CustomizeTableCell>{row.city || "-"}</CustomizeTableCell>
        <CustomizeTableCell>{row.state || "-"}</CustomizeTableCell>
        <CustomizeTableCell>{row.country || "-"}</CustomizeTableCell>
        {activePage === "Edit" && (
          <TableCell>
            <CommonGrid2 display={"flex"} gap={"1rem"} justifyContent={"end"}>
              <IconButton
                onClick={() => {
                  setAddBranchDialog((prev) => ({
                    ...prev,
                    open: true,
                    content: row,
                    index: index,
                  }));
                }}
              >
                <EyeIco />
              </IconButton>
              <IconButton
                onClick={() => {
                  setAgencyDelete((prev) => ({
                    ...prev,
                    open: true,
                    content: row,
                    name: row.city,
                    index: index,
                    condition: "branch",
                  }));
                }}
              >
                <DeleteIco />
              </IconButton>
            </CommonGrid2>
          </TableCell>
        )}
      </>
    );
  };

  const formatPhoneNumberWithFlag = (phone, country) => {
    if (!phone) return "-";

    const phoneNumber = parsePhoneNumber(
      phone,
      country?.countryCode.toUpperCase()
    );
    if (!phoneNumber) return "-";

    const countryFlag = phoneNumber.country
      ? getCountryFlag(phoneNumber.country)
      : "🌍";

    return `${countryFlag} ${phoneNumber.formatInternational()}`;
  };

  const getCountryFlag = (countryCode) => {
    return countryCode
      .split("")
      .map((char) => String.fromCodePoint(127397 + char.charCodeAt(0)))
      .join("");
  };

  const handleMemberTableBodyData = (row, index) => {
    return (
      <>
        <CustomizeTableCell>{index + 1}</CustomizeTableCell>
        <CustomizeTableCell>{row.name || "-"}</CustomizeTableCell>
        <CustomizeTableCell>{row.title || "-"}</CustomizeTableCell>
        <CustomizeTableCell>
          {formatPhoneNumberWithFlag(
            row.phone_number,
            row?.phone_number_country
          ) || "-"}
        </CustomizeTableCell>
        <CustomizeTableCell>{row.email || "-"}</CustomizeTableCell>
        <CustomizeTableCell>{row.remarks || "-"}</CustomizeTableCell>
        {activePage === "Edit" && (
          <TableCell>
            <CommonGrid2 display={"flex"} gap={"1rem"} justifyContent={"end"}>
              <IconButton
                onClick={() => {
                  setAddMemberDialog((prev) => ({
                    ...prev,
                    open: true,
                    content: row,
                    index: index,
                  }));
                }}
              >
                <EyeIco />
              </IconButton>
              <IconButton
                onClick={() => {
                  setAgencyDelete((prev) => ({
                    ...prev,
                    open: true,
                    content: row,
                    name: row.name,
                    index: index,
                    condition: "member",
                  }));
                }}
              >
                <DeleteIco />
              </IconButton>
            </CommonGrid2>
          </TableCell>
        )}
      </>
    );
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleToogleChange = (event, newAlignment) => {
    if (newAlignment !== null) setActivePage(newAlignment);
  };

  const primaryInformationData = [
    {
      title: "Business Name",
      component: "input",
      titleName: "organization_name",
      placeholder: "Enter Name",
      placeholderFontSize: "15px",
      name: informationData?.organization_name || "-",
    },
    {
      title: "Business Display Name",
      component: "input",
      titleName: "display_name",
      placeholder: "Enter Name",
      placeholderFontSize: "15px",
      name: informationData?.display_name || "-",
    },
    {
      title: "Agency ID",
      component: "input",
      titleName: "agency_id",
      placeholder: "Enter Agency Id",
      placeholderFontSize: "15px",
      name: informationData?.agency_id || "-",
    },
    {
      title: "Primary Country",
      component: "input",
      titleName: "primary_country",
      placeholder: "Select Country",
      placeholderFontSize: "15px",
      name: informationData?.primary_country || "-",
    },
    {
      title: "Primary Currency",
      component: "dropDown",
      titleName: "primary_currency",
      placeholder: "Select Country",
      dropDownData: "primary_currency_data",
      //   valueChange: true,
      name: informationData?.primary_currency || "-",
    },

    {
      title: "Primary Address",
      component: "input",
      titleName: "primary_address",
      placeholder: "Enter Primary Address",
      placeholderFontSize: "15px",
      name: informationData?.primary_address || "-",
    },

    {
      title: "Email Address",
      component: "input",
      titleName: "email_address",
      placeholder: "Enter Email Address",
      placeholderFontSize: "15px",
      name: informationData?.email_address || "-",
    },
    {
      title: "Contact Number",
      component: "phone-number",
      titleName: "contact_number",
      placeholder: "Enter Contact Number",
      country: "contact_number_country",
      name: informationData?.contact_number || "-",
    },
    {
      title: "Website",
      component: "input",
      titleName: "website",
      placeholder: "Enter Website",
      placeholderFontSize: "15px",
      link: informationData?.website || "-",
    },

    {
      title: "Business Classification",
      component: "dropDown",
      titleName: "business_classification",
      dropDownData: "business_classification_data",
      placeholder: "Select Classification",
      // valueChange: true,
      name: informationData?.business_classification || "-",
    },
    {
      title: "Record Status",
      component: "dropDown",
      titleName: "record_status",
      placeholder: "Select Record Status",
      dropDownData: "record_status_data",
      name: informationData?.record_status || "-",
    },
  ];

  const diversityStatusData = [
    {
      title: "Diversity Status",
      component: "dropDown",
      titleName: "diversity_status",
      placeholder: "Select Diversity Status",
      dropDownData: "diversity_status_data",
      // valueChange: true,
      name: informationData?.diversity_status || "-",
    },
  ];

  const handleAddBranchButttonClose = () => {
    setAddBranchDialog({
      open: false,
      loading: false,
      content: "",
      index: "",
    });
  };

  const handleAddMemberButttonClose = () => {
    setAddMemberDialog({
      open: false,
      loading: false,
      content: "",
      index: "",
    });
  };

  const handleAddBranch = () => {
    setAddBranchDialog((prev) => ({
      ...prev,
      open: true,
      content: "",
      index: "",
    }));
    handleAgencyDeleteClose();
  };

  const handleAddMember = () => {
    setAddMemberDialog((prev) => ({
      ...prev,
      open: true,
      content: "",
      index: "",
    }));
    handleAgencyDeleteClose();
  };

  const handleSubmitBranch = (data, setFieldValue, values) => {
    setAddBranchDialog((prev) => ({
      ...prev,
      loading: true,
    }));

    setTimeout(() => {
      // setFieldValue("branch_location", [...values.branch_location, data]);
      if (addBranchDialog.index !== "") {
        setFieldValue(
          "branch_location",
          values.branch_location.map((branch, idx) =>
            idx === addBranchDialog.index ? { ...branch, ...data } : branch
          )
        );
      } else {
        setFieldValue("branch_location", [...values.branch_location, data]);
      }
      handleAddBranchButttonClose();
    }, 1000);
  };

  const handleSubmitMember = (data, setFieldValue, values) => {
    setAddMemberDialog((prev) => ({
      ...prev,
      loading: true,
    }));

    setTimeout(() => {
      // setFieldValue("contact_details", [...values.contact_details, data]);
      if (addMemberDialog.index !== "") {
        setFieldValue(
          "contact_details",
          values.contact_details.map((branch, idx) =>
            idx === addMemberDialog.index ? { ...branch, ...data } : branch
          )
        );
      } else {
        setFieldValue("contact_details", [...values.contact_details, data]);
      }
      handleAddMemberButttonClose();
    }, 1000);
  };

  const handleAgencyDeleteClose = () => {
    setAgencyDelete({
      open: false,
      loading: false,
      content: "",
      name: "",
      index: "",
      condition: "",
    });
  };

  const handleAgencyDeleteButton = (setFieldValue, values) => {
    setAgencyDelete((prev) => ({
      ...prev,
      loading: true,
    }));
    setTimeout(() => {
      if (agencyDelete.condition === "branch") {
        const data = values.branch_location.filter(
          (item, index) => index !== agencyDelete.index
        );
        setFieldValue("branch_location", data);
      } else if (agencyDelete.condition === "member") {
        const data = values.contact_details.filter(
          (item, index) => index !== agencyDelete.index
        );
        setFieldValue("contact_details", data);
      }
      handleAgencyDeleteClose();
    }, 1000);
  };

  const handleChangeAccor = (panel) => (_, isExpanded) => {
    setExpandedPanel((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
    );
  };

  const handleScrollToError = (error) => {
    setExpandedPanel((prev) => {
      const newPanels = new Set(prev);

      if (
        error.organization_name ||
        error.display_name ||
        error.agency_id ||
        error.primary_country ||
        error.primary_currency ||
        error.primary_address ||
        error.email_address ||
        error.contact_number ||
        error.website ||
        error.business_classification ||
        error.record_status
      ) {
        newPanels.add("Primary Information");
      }
      if (error.branch_location) {
        newPanels.add("Office Information");
      }
      if (error.diversity_status) {
        newPanels.add("Diversity Status");
      }
      if (error.contact_details) {
        newPanels.add("Contact Details");
      }

      return Array.from(newPanels);
    });

    setTimeout(() => {
      const errorElement = document.querySelector(".Mui-error");
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 300);
  };

  const handleSubmitAgency = async (values) => {
    setButtonLoading(true);

    try {
      let data = await AgnecyDetailsUpdateCall(informationData?.id, values);
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });

      setButtonLoading(false);
      setActivePage("View");
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setButtonLoading(false);
    }
  };

  return (
    <CustomDiv>
      <Formik
        enableReinitialize={true}
        initialValues={informationData}
        validationSchema={validationSchema}
        onSubmit={handleSubmitAgency}
      >
        {({ validateForm, values, errors, setFieldValue }) => {
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Grid2 container spacing={2} marginBottom={"2rem"}>
                <CommonGrid2
                  size={{ xs: 12, md: 4, sm: 12 }}
                  item
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Typography fontSize={"24px"} fontWeight={600}>
                    Agency Information
                  </Typography>
                </CommonGrid2>

                <CommonGrid2
                  item
                  size={{ xs: 12, md: 8, sm: 12 }}
                  gap="10px"
                  display="flex"
                  justifyContent={"end"}
                >
                  {activePage === "Edit" && (
                    <CommonButton
                      value="Submit"
                      padding="5px 2rem"
                      fontWeight="600"
                      type="submit"
                      disabled={buttonLoading}
                      loading={buttonLoading}
                      onClick={async () => {
                        let data = await validateForm();
                        setTimeout(() => {
                          handleScrollToError(data);
                        }, 0);
                      }}
                    />
                  )}

                  <ToogleButton
                    ToogleData={ToogleData}
                    alignment={activePage}
                    handleChange={(event, selectedValue) =>
                      handleToogleChange(event, selectedValue)
                    }
                    border={"1px solid #D0D5DD"}
                    borderRadius={"8px"}
                    activeColor={"#344054"}
                    color={"#344054"}
                    activeBackgroundColor="#F9FAFB"
                    backgroundColor="#FFFF"
                    disabled={buttonLoading}
                  />
                </CommonGrid2>
              </Grid2>

              <Grid2 size={12} container spacing={4}>
                <Grid2 size={12} fontFamily={"Inter"}>
                  <CommonAccordion
                    title={"Primary Information"}
                    open={expandedPanel.includes("Primary Information")}
                    onChange={handleChangeAccor("Primary Information")}
                    children={
                      activePage === "Edit" ? (
                        <MixedComponent
                          marginTop={"0.7rem"}
                          editData={primaryInformationData}
                          loading={false}
                          rowSize
                        />
                      ) : (
                        <CommonCard
                          viewData={primaryInformationData}
                          viewMarginTop={"1rem"}
                          loading={isLoading}
                        />
                      )
                    }
                  />
                </Grid2>
                <Grid2 size={12} fontFamily={"Inter"}>
                  <CommonAccordion
                    title={"Office Information"}
                    open={expandedPanel.includes("Office Information")}
                    onChange={handleChangeAccor("Office Information")}
                    children={
                      <>
                        <Grid2
                          container
                          spacing={2}
                          marginTop={"0.5rem"}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Typography variant="h5" fontWeight={600}>
                            Office Locations
                          </Typography>

                          {activePage === "Edit" && (
                            <CommonTippy
                              color="red"
                              interactive={true}
                              zIndex={9}
                              className={
                                values.branch_location?.length === 0
                                  ? "Mui-error"
                                  : ""
                              }
                              content={
                                "At least one office information is required"
                              }
                              visible={
                                values.branch_location.length === 0 &&
                                expandedPanel.includes("Office Information")
                              }
                              fontSize={"12px"}
                            >
                              <div>
                                <CommonButton
                                  variant="contained"
                                  color="white"
                                  background="#7F56D9"
                                  fontWeight="600"
                                  value="Add Office"
                                  padding="8px 15px"
                                  borderRadius="7px"
                                  onClick={handleAddBranch}
                                />
                              </div>
                            </CommonTippy>
                          )}
                        </Grid2>
                        <CommonTable
                          data={values.branch_location}
                          columns={headerBranchData}
                          loadingRowLength={3}
                          onSortChange={(col) => console.log("Sort by", col)}
                          handleTableBodyData={handleBranchTableBodyData}
                          withPagination={false}
                          loading={isLoading}
                        />
                      </>
                    }
                  />
                </Grid2>
                <Grid2 size={12} fontFamily={"Inter"}>
                  <CommonAccordion
                    open={expandedPanel.includes("Diversity Status")}
                    onChange={handleChangeAccor("Diversity Status")}
                    title={"Diversity Status"}
                    children={
                      activePage === "Edit" ? (
                        <MixedComponent
                          editData={diversityStatusData}
                          loading={false}
                          marginTop={"0.7rem"}
                          rowSize
                        />
                      ) : (
                        <CommonCard
                          viewData={diversityStatusData}
                          viewMarginTop={"1rem"}
                          loading={isLoading}
                          rowSize
                        />
                      )
                    }
                  />
                </Grid2>

                <Grid2 size={12} fontFamily={"Inter"}>
                  <CommonAccordion
                    title={"Contact Details"}
                    open={expandedPanel.includes("Contact Details")}
                    onChange={handleChangeAccor("Contact Details")}
                    children={
                      <>
                        <Grid2
                          container
                          spacing={2}
                          marginTop={"0.5rem"}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Typography variant="h5" fontWeight={600}>
                            EXECUTIVE MANAGEMENT: ACCESS RIGHTS: ALL
                          </Typography>
                          {activePage === "Edit" && (
                            <CommonTippy
                              color="red"
                              interactive={true}
                              zIndex={9}
                              className={
                                values.contact_details?.length === 0
                                  ? "Mui-error"
                                  : ""
                              }
                              content={
                                "At least one contact details is required"
                              }
                              visible={
                                values.contact_details.length === 0 &&
                                expandedPanel.includes("Contact Details")
                              }
                              fontSize={"12px"}
                            >
                              <div>
                                <CommonButton
                                  variant="contained"
                                  color="white"
                                  background="#7F56D9"
                                  fontWeight="600"
                                  value="Add Member"
                                  padding="8px 15px"
                                  borderRadius="7px"
                                  onClick={handleAddMember}
                                />
                              </div>
                            </CommonTippy>
                          )}
                        </Grid2>
                        <CommonTable
                          data={values.contact_details}
                          columns={headerMemberData}
                          loadingRowLength={3}
                          onSortChange={(col) => console.log("Sort by", col)}
                          handleTableBodyData={handleMemberTableBodyData}
                          withPagination={false}
                          loading={isLoading}
                        />
                      </>
                    }
                  />
                </Grid2>
              </Grid2>
              <AddBranch
                open={addBranchDialog.open}
                handleButttonClose={handleAddBranchButttonClose}
                setFieldValue={setFieldValue}
                handleSubmitBranch={handleSubmitBranch}
                addBranchDialog={addBranchDialog}
                formikValues={values}
              />

              <AddMember
                open={addMemberDialog.open}
                handleButttonClose={handleAddMemberButttonClose}
                setFieldValue={setFieldValue}
                handleSubmitMember={handleSubmitMember}
                addMemberDialog={addMemberDialog}
                formikValues={values}
              />

              <SkillDeleteDialog
                deleteDialog={agencyDelete.open}
                handleDeleteDialogClose={handleAgencyDeleteClose}
                handleDeleteSkill={() =>
                  handleAgencyDeleteButton(setFieldValue, values)
                }
                buttonLoading={agencyDelete.loading}
                title={"Delete"}
                content={agencyDelete.name}
              />
            </Form>
          );
        }}
      </Formik>

      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </CustomDiv>
  );
}
