import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
  import React from "react";
  import DotsVerticalIco from "../../../../assets/icons/DotsVerticalIco";
  import {
    StatusChange,
    StyledFlexRowView,
    StyledTableBodyTableCell,
    StyledTableTitleTableCell,
    StyledTypo,
    TableContainerBorder,
  } from "../../../common-components/StyledComponents";
  import { HandleSkeletion } from "../../../../utils/constants";
  import EyeIco from "../../../../assets/icons/EyeIco";
  
  function Interactions({
    matchedData,
    headerData,
    tableLoader,
    handleInteractionAction,
  }) {
    const interactionsData = matchedData?.interactions || [];
    console.log(interactionsData, "interactionsDatainteractionsData");
    return (
      <TableContainerBorder sx={{ marginTop: "0px !important" }}>
        <Table>
          <TableHead>
            <TableRow>
              {headerData?.map((item) => {
                return (
                  <StyledTableTitleTableCell>{item.name}</StyledTableTitleTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {tableLoader ? (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <TableRow>
                  {Array.from({ length: headerData?.length }).map(
                    (_, index) => (
                      <TableCell key={index}>
                        <HandleSkeletion height={30} />
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </>
          ) : (
            <TableBody>
              {interactionsData.map((interactions) => (
                <TableRow key={interactions.id}>
                  <StyledTableBodyTableCell>
                    {interactions.order_no}
                  </StyledTableBodyTableCell>
                  <StyledTableBodyTableCell>
                    {interactions.created_by}
                  </StyledTableBodyTableCell>
                  <StyledTableBodyTableCell>
                    {interactions.type}
                  </StyledTableBodyTableCell>
                  <StyledTableBodyTableCell>
                    {interactions.action}
                  </StyledTableBodyTableCell>
                  <TableCell>
                    <StatusChange>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#17B26A",
                        }}
                      />
                      {interactions.status}
                    </StatusChange>
                  </TableCell>
                  <StyledTableBodyTableCell>
                    {interactions.created_at}
                  </StyledTableBodyTableCell>
                  {/* <StyledTableBodyTableCell>
                    {interactions.created_time}
                  </StyledTableBodyTableCell> */}
                  {/* <TableCell>
                    <StyledFlexRowView>
                      <StyledTypo> {interactions.created_by}</StyledTypo>
                    </StyledFlexRowView>
                  </TableCell> */}
                  <TableCell>
                    {interactions.updated_fields.length !== 0 && (
                      <IconButton
                        onClick={() => {
                          handleInteractionAction(interactions);
                        }}
                      >
                        <EyeIco />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainerBorder>
    );
  }
  
  export default Interactions;
  