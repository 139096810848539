import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import CommonButton from "../../../component/Button";
import XCloseIco from "../../../assets/icons/XCloseIco";
import { CustomDiv } from "../../chat-bot/style";
import { Field, Form, Formik } from "formik";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import * as Yup from "yup";
import CommonTextField from "../../../component/Input";

export default function AddInformation({
  open,
  handleButttonClose,
  addInformationDialog,
  handleInformation,
  setFieldValue,
  informationValues,
}) {
  const initialValues = {
    type: "",
    name: {
      value: "",
      titleName: "name",
      component: "input",
      placeholder: "Enter Name",
      title: "Name",
    },
    job_title: {
      value: "",
      titleName: "job_title",
      component: "input",
      placeholder: "Enter Job Title",
      title: "Job Title",
    },
    contact_phone: {
      value: "",
      titleName: "contact_phone",
      component: "input",
      placeholder: "Enter Contact Phone",
      title: "Contact Phone",
    },
    email: {
      value: "",
      titleName: "email",
      component: "input",
      placeholder: "Enter Email",
      title: "Email",
    },
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("Type is required"),
    name: Yup.object({
      value: Yup.string().required("Name is required"),
    }),
    job_title: Yup.object({
      value: Yup.string().required("Job Title is required"),
    }),
    contact_phone: Yup.object({
      value: Yup.string()
        .matches(/^(?!0+$)\d{10}$/, "Invalid Phone number")
        .required("Phone Number is required"),
    }),

    email: Yup.object({
      value: Yup.string()
        .required("Email is required")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        ),
    }),
  });

  const primaryViewData = [
    {
      title: "Name",
      component: "input",
      titleName: "name",
      placeholder: "Enter the Name",
    },
    {
      title: "Job Title",
      component: "input",
      titleName: "job_title",
      placeholder: "Enter the job title",
    },
    {
      title: "Contact Phone",
      component: "input",
      titleName: "contact_phone",
      placeholder: "Enter the Contact Phone",
    },
    {
      title: "Email",
      component: "input",
      titleName: "email",
      placeholder: "Enter the email",
    },
  ];

  const handleSubmit = (values) => {
    const data = [
      {
        type: values.type,
        data: Object.values((({ type, ...rest }) => rest)(values)),
      },
    ];

    handleInformation(data, setFieldValue, informationValues);
    // setFieldValue("contactInformation", data);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "10px",
        },
      }}
    >
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <CustomDiv></CustomDiv>
        <CommonButton
          onClick={handleButttonClose}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          value={<XCloseIco />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>

      <DialogContent style={{ textAlign: "center", paddingTop: "0px" }}>
        <Typography variant="h3" textAlign={"center"}>
          {"Add Information"}
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <Grid2 spacing={1.5} container marginTop={"1rem"}>
                  <CommonGrid2
                    size={12}
                    gap={"6px"}
                    display={"flex"}
                    flexDirection={"column"}
                    textAlign={"start"}
                  >
                    <Typography fontSize={"15px"}>{"Type"}</Typography>
                    <Field name={"type"}>
                      {({ field, form }) => {
                        return (
                          <CommonTextField
                            placeholder={"Enter the Type"}
                            padding="10px"
                            error={
                              form.touched["type"] && Boolean(errors["type"])
                            }
                            value={values["type"]}
                            onChange={(e) => {
                              setFieldValue("type", e.target.value);
                            }}
                            helperText={form.touched["type"] && errors["type"]}
                          />
                        );
                      }}
                    </Field>
                  </CommonGrid2>
                  <Grid2 spacing={2} container size={12}>
                    {primaryViewData.map((item) => (
                      <CommonGrid2
                        size={6}
                        item
                        gap={"6px"}
                        display={"flex"}
                        flexDirection={"column"}
                        textAlign={"start"}
                      >
                        <Typography fontSize={"15px"}>{item.title}</Typography>
                        <Field name={item.titleName}>
                          {({ field, form }) => {
                            return (
                              <CommonTextField
                                placeholder={item.placeholder}
                                placeholderFontSize={"15px"}
                                padding="10px"
                                error={
                                  form.touched["type"] &&
                                  Boolean(errors[item.titleName])
                                }
                                value={values[item.titleName]["value"]}
                                onChange={(e) => {
                                  setFieldValue(item.titleName, {
                                    ...values[item.titleName],
                                    value: e.target.value,
                                  });
                                }}
                                helperText={
                                  form.touched[item.titleName]?.["value"] &&
                                  errors[item.titleName]?.["value"]
                                }
                              />
                            );
                          }}
                        </Field>
                      </CommonGrid2>
                    ))}
                  </Grid2>
                </Grid2>
                <CommonButton
                  value={"Add"}
                  width={"100%"}
                  padding={"10px"}
                  type="submit"
                  marginTop={"1.5rem"}
                  disabled={addInformationDialog.loading}
                  loading={addInformationDialog.loading}
                />
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
