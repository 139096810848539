export default function JudicaIco({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.47147"
        y="13.79"
        width="10.3583"
        height="7.53333"
        transform="rotate(-60 3.47147 13.79)"
        stroke="#667085"
        stroke-width="1.88333"
      />
      <mask
        id="path-2-outside-1_746_41253"
        maskUnits="userSpaceOnUse"
        x="10.1309"
        y="10.2031"
        width="13"
        height="11"
        fill="black"
      >
        <rect fill="white" x="10.1309" y="10.2031" width="13" height="11" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.9156 13.2031L13.1309 14.5623L19.0441 18.3644C19.5831 18.7109 20.3023 18.5365 20.6227 17.9816C20.9431 17.4267 20.7345 16.7166 20.1649 16.4231L13.9156 13.2031Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9156 13.2031L13.1309 14.5623L19.0441 18.3644C19.5831 18.7109 20.3023 18.5365 20.6227 17.9816C20.9431 17.4267 20.7345 16.7166 20.1649 16.4231L13.9156 13.2031Z"
        fill="white"
      />
      <path
        d="M13.1309 14.5623L11.4998 13.6206L10.6022 15.1755L12.1123 16.1464L13.1309 14.5623ZM13.9156 13.2031L14.7782 11.529L13.1822 10.7066L12.2846 12.2615L13.9156 13.2031ZM19.0441 18.3644L18.0255 19.9485L19.0441 18.3644ZM20.6227 17.9816L18.9917 17.0399L18.9917 17.0399L20.6227 17.9816ZM20.1649 16.4231L19.3023 18.0972L20.1649 16.4231ZM14.7619 15.504L15.5466 14.1448L12.2846 12.2615L11.4998 13.6206L14.7619 15.504ZM20.0626 16.7803L14.1494 12.9782L12.1123 16.1464L18.0255 19.9485L20.0626 16.7803ZM18.9917 17.0399C19.209 16.6635 19.697 16.5451 20.0626 16.7803L18.0255 19.9485C19.4691 20.8767 21.3956 20.4096 22.2537 18.9233L18.9917 17.0399ZM19.3023 18.0972C18.9158 17.8981 18.7743 17.4164 18.9917 17.0399L22.2537 18.9233C23.1118 17.4369 22.5532 15.535 21.0275 14.7489L19.3023 18.0972ZM13.053 14.8773L19.3023 18.0972L21.0275 14.7489L14.7782 11.529L13.053 14.8773Z"
        fill="#667085"
        mask="url(#path-2-outside-1_746_41253)"
      />
      <rect
        x="7.36405"
        y="5.16108"
        width="2.825"
        height="9.41666"
        rx="1.4125"
        transform="rotate(-60 7.36405 5.16108)"
        stroke="#667085"
        stroke-width="1.88333"
      />
      <rect
        x="1.71366"
        y="14.9462"
        width="2.825"
        height="9.41666"
        rx="1.4125"
        transform="rotate(-60 1.71366 14.9462)"
        stroke="#667085"
        stroke-width="1.88333"
      />
    </svg>
  );
}
