import React, { useEffect, useState } from "react";
import { CustomDiv } from "../../chat-bot/style";
import { Typography } from "@mui/material";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import { Field, useFormikContext } from "formik";
import CommonTextField from "../../../component/Input";
import CommonButton from "../../../component/Button";
import DeleteIco from "../../../assets/icons/DeleteIco";
import { HandleSkeletion } from "../../../utils/constants";

export default function ContactInformation({
  loading,
  rowSize,
  handleDeleteInformation,
}) {
  const debounceTimer = React.useRef(null);
  const [informationData, setInformationData] = useState([]);
  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    setInformationData(values.contact_information);
  }, [values]);

  const handleInformationInputChange = (e, innerItem, index) => {
    const { value } = e.target;

    setInformationData((prev) =>
      prev.map((item, idx) => {
        return idx === index
          ? {
              ...item,
              data: item.data.map((field) =>
                field.titleName === innerItem.titleName
                  ? { ...field, value }
                  : field
              ),
            }
          : item;
      })
    );

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      setFieldValue(
        "contact_information",
        values.contact_information.map((item, idx) =>
          idx === index
            ? {
                ...item,
                data: item.data.map((field) =>
                  field.titleName === innerItem.titleName
                    ? { ...field, value }
                    : field
                ),
              }
            : item
        )
      );
    }, 500);
  };
  const getFormData = useFormikContext();

  return (
    <CustomDiv>
      {informationData.map((item, index) => {
        return (
          <CustomDiv margin={index === 0 ? "0px" : "1.5rem 0px 0px"}>
            <CommonGrid2
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5" fontWeight={600} margin={"0px 0px 5px"}>
                {item.type}
              </Typography>
              <CommonButton
                variant="outlined"
                color="#344054"
                borderRadius={"6px"}
                padding={"11px"}
                border="#D0D5DD"
                fontWeight="600"
                minWidth="auto"
                svg={true}
                value={<DeleteIco />}
                onClick={() =>
                  handleDeleteInformation(item, index, getFormData)
                }
              />
            </CommonGrid2>
            <CommonGrid2
              container
              columnSpacing={3}
              rowSpacing={loading ? 0 : 3}
            >
              {item.data?.map((innerItem, innerIndex) => {
                return (
                  <CommonGrid2
                    size={{
                      xs: 12,
                      md: rowSize ? 6 : 4,
                      sm: 6,
                    }}
                    key={innerIndex + innerItem.title}
                    gap={"5px"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                  >
                    {loading ? (
                      <HandleSkeletion height={100} />
                    ) : (
                      <>
                        <CommonGrid2
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          width={"100%"}
                        >
                          <CommonGrid2
                            display={"flex"}
                            alignItems={"center"}
                            width={"100%"}
                            gap={"10px"}
                          >
                            <Typography
                              variant="p"
                              color="#344054"
                              fontSize="16px"
                            >
                              {innerItem.title}
                            </Typography>
                          </CommonGrid2>
                        </CommonGrid2>
                        {innerItem.component === "input" && (
                          <Field>
                            {({ field, form }) => {
                              return (
                                <CommonTextField
                                  {...field}
                                  placeholder={
                                    innerItem?.placeholder || innerItem.title
                                  }
                                  padding="12px"
                                  error={Boolean(
                                    form.touched["contact_information"]?.[index]
                                      ?.data?.[innerIndex]?.value &&
                                      form.errors["contact_information"]?.[
                                        index
                                      ]?.data?.[innerIndex]?.value
                                  )}
                                  helperText={
                                    form.touched["contact_information"]?.[index]
                                      ?.data?.[innerIndex]?.value &&
                                    form.errors["contact_information"]?.[index]
                                      ?.data?.[innerIndex]?.value
                                  }
                                  value={innerItem.value}
                                  onChange={(e) => {
                                    handleInformationInputChange(
                                      e,
                                      innerItem,
                                      index
                                    );
                                  }}
                                  placeholderFontSize={"15px"}
                                />
                              );
                            }}
                          </Field>
                        )}
                      </>
                    )}
                  </CommonGrid2>
                );
              })}
            </CommonGrid2>
          </CustomDiv>
        );
      })}
    </CustomDiv>
  );
}
