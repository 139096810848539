import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import Featuredicon from "../../../assets/images/featuredicon.png";
import { StyledDialog, StyledFlexColumnView } from "../../common-components/StyledComponents";



const ChangeUsername = ({
    open,
    onClose,
    onApply
}) => {
    const [username, setUsername] = useState("");
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };
    return (
        <>
            <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose} >
                <img
                    src={Featuredicon}
                    style={{ position: "absolute", padding: "8px", width: "80px" }}
                />
                <DialogActions>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </DialogActions>
                <DialogTitle>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                        {"Change Username"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid2 container direction="column" spacing={3}>
                        <Grid2 size={12}>
                            <StyledFlexColumnView>
                                <Typography
                                    sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                                >
                                    {"Username"}
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={username}
                                    onChange={handleUsernameChange}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            backgroundColor: "#FFFFFF",
                                            border: "1px solid #D0D5DD",
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "12px",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            color: "#667085",
                                            opacity: 1,
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        },
                                    }}
                                />
                            </StyledFlexColumnView>
                        </Grid2>
                        <Button
                            variant="outlined"
                            color="white"
                            fontWeight="600"
                            value="Change Username"
                            padding="8px"
                            onClick={onApply}
                            border="1px solid #D0D5DD"
                            disabled={!username.trim()}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "rgb(127, 86, 217)",
                                    color: "#fff",
                                    border: "1px solid #D0D5DD",
                                },
                            }}

                        >{"Change Username"}</Button>
                    </Grid2>
                </DialogContent>
            </StyledDialog >

        </>
    );
};

export default ChangeUsername;
