import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import CommonButton from "../../component/Button";
import XCloseIco from "../../assets/icons/XCloseIco";
import { CustomDiv } from "../chat-bot/style";
import { Field, Form, Formik } from "formik";
import { CommonGrid2 } from "../common-components/StyledComponents";
import * as Yup from "yup";
import CommonTextField from "../../component/Input";

export default function AddBranch({
  open,
  handleButttonClose,
  addBranchDialog,
  handleSubmitBranch,
  setFieldValue,
  formikValues,
}) {
  const initialValues = {
    city: addBranchDialog.content?.city || "",
    state: addBranchDialog.content?.state || "",
    country: addBranchDialog.content?.country || "",
  };

  const validationSchema = Yup.object({
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
  });

  const primaryViewData = [
    {
      title: "City",
      component: "input",
      titleName: "city",
      placeholder: "Enter the city",
    },
    {
      title: "State",
      component: "input",
      titleName: "state",
      placeholder: "Enter the state",
    },
    {
      title: "Country",
      component: "input",
      titleName: "country",
      placeholder: "Enter the Country",
    },
  ];

  const handleSubmit = (values) => {
    handleSubmitBranch(values, setFieldValue, formikValues);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "10px",
        },
      }}
    >
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <CustomDiv></CustomDiv>
        <CommonButton
          onClick={handleButttonClose}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          value={<XCloseIco />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>

      <DialogContent style={{ textAlign: "center", paddingTop: "0px" }}>
        <Typography variant="h3" textAlign={"center"}>
          {addBranchDialog.index === "" ? "Add" : "Update"} Branch
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <Grid2 spacing={2} container size={12}>
                  {primaryViewData.map((item) => (
                    <CommonGrid2
                      size={12}
                      item
                      gap={"6px"}
                      display={"flex"}
                      flexDirection={"column"}
                      textAlign={"start"}
                    >
                      <Typography fontSize={"15px"}>{item.title}</Typography>
                      <Field name={item.titleName}>
                        {({ field, form }) => {
                          return (
                            <CommonTextField
                              placeholder={item.placeholder}
                              placeholderFontSize={"15px"}
                              padding="13px"
                              error={
                                form.touched[item.titleName] &&
                                Boolean(errors[item.titleName])
                              }
                              value={values[item.titleName]}
                              onChange={(e) => {
                                setFieldValue(item.titleName, e.target.value);
                              }}
                              helperText={
                                form.touched[item.titleName] &&
                                errors[item.titleName]
                              }
                            />
                          );
                        }}
                      </Field>
                    </CommonGrid2>
                  ))}
                </Grid2>

                <CommonButton
                  value={addBranchDialog.index === "" ? "Add" : "Update"}
                  width={"100%"}
                  padding={"10px"}
                  type="submit"
                  marginTop={"1.5rem"}
                  disabled={addBranchDialog.loading}
                  loading={addBranchDialog.loading}
                />
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
