import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import { Chip, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import ChatBotInputIco from "../../assets/icons/ChatBotInputIco";
import Ellipse from "../../assets/icons/Ellipse";
import IcanioIco from "../../assets/icons/IcanioIco";
import { ReactComponent as ChatBotIcon } from "../../assets/images/ChatBotIcon.svg";
import { ReactComponent as Haley } from "../../assets/images/Haley.svg";
import CommonTextField from "../../component/Input";
import ChatBotDownArrowIco from "../../assets/icons/ChatBotDownArrowIco";
import ChatBotUpArrowIco from "../../assets/icons/ChatBotUpArrowIco";

import {
  CommonGrid2,
  CustomTooltip,
} from "../common-components/StyledComponents";
import {
  ChatBotFooter,
  CustomDiv,
  CustomMarkDown,
  InnerContainer,
  WholePageEllipse,
  WholePageOpacity,
} from "./style";

const ChatBot = ({
  iconVisible,
  handleClick,
  chatBotData,
  handleChatBotFetchData,
  handleSendMessage,
  handleChatBotChange,
  isBottomVisible,
  setIsBottomVisible,
  handleButtonClickByLabel,
  handleUpArrow,
}) => {
  const messagesEndRef = useRef(null);

  const handleIconClick = () => {
    handleSendMessage();
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    handleChatBotFetchData();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatBotData.message]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBottomVisible(entry.isIntersecting);
      },
      { threshold: 1.0 }
    );

    if (messagesEndRef.current) {
      observer.observe(messagesEndRef.current);
    }

    return () => {
      if (messagesEndRef.current) {
        observer.unobserve(messagesEndRef.current);
      }
    };
  });

  return iconVisible ? (
    <CustomTooltip
      title={
        <CustomDiv>
          <Typography color="#0070F2" fontWeight={600} fontSize={"16px"}>
            Hi, {JSON.parse(localStorage.getItem("credential"))?.username}
          </Typography>
          <Typography color="#000" fontSize={"14px"} marginTop={"5px"}>
            My name is Hailey. I am an here to help you surf this site, Ask me
            anything you want to know
          </Typography>
          <Typography
            color="#000"
            fontSize={"10px"}
            fontWeight={500}
            textAlign={"end"}
          >
            Click on the icon to start Chatting
          </Typography>
        </CustomDiv>
      }
      arrowHeight={{
        width: "23px",
        height: "16px",
        marginBottom: "-1rem !important",
      }}
      boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
      beforeArrowHeight={{
        width: "23px",
        height: "16px",
      }}
      borderRadius={"10px"}
      backgroundColor="#fff"
      placement="top-end"
      border={"2px solid #D5DADD"}
    >
      <IconButton
        onClick={() => handleClick(false)}
        style={{ padding: "0px", bottom: iconVisible && "17rem" }}
      >
        <ChatBotIcon />
      </IconButton>
    </CustomTooltip>
  ) : (
    <InnerContainer right="5rem">
      {chatBotData.pageLoader && (
        <CustomDiv>
          <WholePageEllipse>
            <Ellipse />
          </WholePageEllipse>
          <WholePageOpacity></WholePageOpacity>
        </CustomDiv>
      )}
      <CommonGrid2
        overflowY="scroll"
        padding="12px 5px 12px 12px"
        height="380px"
        scrollStyle={true}
      >
        {chatBotData.message.map((msg, index) => (
          <div
            key={index}
            style={{
              textAlign: msg.sender === "user" ? "right" : "left",
              margin: "10px 0",
            }}
          >
            <Typography
              fontSize={"13px"}
              textAlign={"left"}
              style={{
                display: "inline-block",
                padding: "5px 10px",
                backgroundColor:
                  msg.sender === "user"
                    ? "#7F56D9"
                    : msg.sender === "error"
                    ? "red"
                    : "#fff",
                color:
                  msg.sender === "user" || msg.sender === "error"
                    ? "#fff"
                    : "#000",
                borderRadius:
                  msg.sender === "user" ? "8px 8px 1px 8px" : "8px 8px 8px 1px",
                maxWidth: msg.sender === "user" ? "80%" : "99%",
                wordBreak: "break-word",
                boxShadow: "1px 1px 5px 1px rgb(16 24 40 / 11%)",
              }}
            >
              <CustomMarkDown>
                {typeof msg.text === "string"
                  ? msg.text.trim()
                  : JSON.stringify(msg.text)}
              </CustomMarkDown>
            </Typography>
          </div>
        ))}

        {chatBotData.loader && (
          <CustomDiv position={"relative"}>
            <Ellipse />
          </CustomDiv>
        )}
        <div ref={messagesEndRef} />
      </CommonGrid2>
      {!isBottomVisible && (
        <CustomDiv position={"relative"}>
          <IconButton
            onClick={scrollToBottom}
            style={{
              color: "#7F56D9",
              position: "absolute",
              left: "46.25%",
              background: "#fff",
              borderRadius: "50%",
              top: "-3rem",
              zIndex: 999,
              padding: "0px",
            }}
          >
            <ArrowCircleDownRoundedIcon style={{ fontSize: "25px" }} />
          </IconButton>
        </CustomDiv>
      )}

      {chatBotData.bubbleCondition && chatBotData?.bubble && (
        <CustomDiv position="relative" zIndex={999}>
          <CustomDiv textAlign="center">
            {chatBotData.arrow ? (
              <CustomDiv
                textAlign="center"
                margin="0px 0px -5px 0px"
                position="absolute"
                width="100%"
                top="-0.9rem"
                onClick={() => handleUpArrow("up")}
              >
                <ChatBotUpArrowIco />
              </CustomDiv>
            ) : (
              <CustomDiv
                textAlign="center"
                width="94%"
                position="absolute"
                bottom="0px"
                transform="translate(14px, 0px)"
              >
                <CustomDiv
                  margin="0px 0px -0.4rem"
                  onClick={() => handleUpArrow("down")}
                >
                  <ChatBotDownArrowIco />
                </CustomDiv>
                <CustomDiv
                  background="#fff"
                  borderRadius="5px 5px 0px 0px"
                  padding="1rem 10px 10px"
                  boxShadow={"1px 1px 5px 1px rgb(16 24 40 / 11%)"}
                >
                  <CommonGrid2
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    alignItems="center"
                    gap="10px"
                  >
                    {chatBotData.bubble.map((item) => (
                      <Chip
                        label={item.question}
                        variant="outlined"
                        style={{
                          color: "#0069BB",
                          borderRadius: "7px",
                          border: "1px solid #0069BB ",
                          fontSize: "12px",
                        }}
                        onClick={() => handleButtonClickByLabel(item)}
                      />
                    ))}
                  </CommonGrid2>
                </CustomDiv>
              </CustomDiv>
            )}
          </CustomDiv>
        </CustomDiv>
      )}

      <CustomDiv margin={"10px 10px 5px 10px"}>
        <CommonTextField
          datePickerCalender
          backgroundColor="#fff"
          padding={"10px"}
          value={chatBotData.value}
          fontSize="14px"
          placeholder={"Ask Hailey anything.."}
          calenderIcon={<ChatBotInputIco />}
          handleIconClick={handleIconClick}
          placeholderFontSize="14px"
          onChange={handleChatBotChange}
          disabled={chatBotData.loader}
        />
        <IconButton
          onClick={() => handleClick(true)}
          sx={{
            position: "absolute",
            bottom: "0rem",
            right: "-4.5rem",
            padding: "0px",
            "&:hover": {
              padding: "0px",
              boxShadow: "none",
              backgroundColor: "transparent !important",
            },
          }}
        >
          <Haley />
        </IconButton>
      </CustomDiv>

      <CommonGrid2
        display={"flex"}
        gap={"10px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ChatBotFooter>Powered by</ChatBotFooter>
        <IcanioIco />
      </CommonGrid2>
    </InnerContainer>
  );
};
export default ChatBot;
