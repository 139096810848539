import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import AlertCircleIco from "../../../../assets/icons/AlertCircleIco";
import TickIcon from "../../../../assets/icons/TickIcon";
import CommonButton from "../../../../component/Button";
import { HandleSkeletion, hexToRgba } from "../../../../utils/constants";
import {
  CommonGrid2,
  StyledFlexRowView,
} from "../../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../../quick-match/components/StyledComponent";
import { CommonTippy, CustomizeSpan } from "../../style";
import { ReactComponent as ChatBotIcon } from "../../../../assets/images/ChatBotIcon.svg";

const Result = ({
  jobData,
  isLoading,
  fetchJobsList,
  toolTipOpen = false,
  job_id,
  fixLoader = false,
}) => (
  <CommonGrid2
    container
    marginTop={"2rem"}
    spacing={3}
    flexDirection={"column"}
  >
    <Typography variant="h4" margin={"15px 0px"} fontWeight={600}>
      {"Results"}
    </Typography>

    <CommonGrid2
      spacing={3}
      container
      flexWrap={"nowrap"}
      alignItems={"center"}
    >
      <CommonGrid2 width={"200px"}>
        <Box
          position="relative"
          display="inline-flex"
          borderRadius="50%"
          padding="0.9rem"
          border="1px solid #EAECF0"
        >
          <CircularProgress
            variant="determinate"
            value={100}
            size={160}
            thickness={5}
            sx={{
              color: "#EAECF0",
            }}
          />

          <CircularProgress
            variant="determinate"
            value={jobData?.score}
            size={160}
            thickness={5}
            sx={{
              color: "#66bb6a",
              position: "absolute",
              "& .MuiCircularProgress-circle": {
                strokeLinecap: "round",
              },
            }}
          />

          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {isLoading ? (
              <HandleSkeletion height={80} width={"80px"} />
            ) : (
              <>
                <Typography variant="div" component="div" color="#475467">
                  {jobData?.score_label}
                </Typography>
                <Typography
                  variant="h2"
                  component="div"
                  fontWeight="bold"
                  color="#101828"
                >
                  {`${Math.round(jobData?.score)}%`}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </CommonGrid2>

      <CommonGrid2
        gap={isLoading ? "0px" : "1rem"}
        display={"flex"}
        flexDirection={"column"}
        width={"calc(100% - 245px)"}
      >
        {isLoading ? (
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <HandleSkeletion height={80} key={index} />
            ))}
          </>
        ) : (
          <>
            {jobData?.insights?.jd_recruiter_cheat_sheet && (
              <CommonGrid2
                container
                // border={"1px solid #EAECF0"}
                border={"1px solid #0070F2"}
                borderRadius={"10px"}
                padding={"12px"}
                // boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
                boxShadow={"0px 0px 2px 1px rgba(0, 113, 242, 0.58)"}
                gap={"10px"}
              >
                <StyledSpaceBetweenBox width={"100%"}>
                  <StyledFlexRowView gap="0px">
                    <div>
                      <ChatBotIcon width={"70"} />
                    </div>
                    <div>
                      <Typography fontSize={"16px"} color="#0070F2">
                        <CustomizeSpan fontWeight={700} color="#667085">
                          {" "}
                          Search Strategy:
                        </CustomizeSpan>{" "}
                        To refine your search on job platforms, consider using
                        combinations of these keywords:
                      </Typography>
                      <ul>
                        {jobData?.insights?.jd_recruiter_cheat_sheet.map(
                          (listItem) => (
                            <li style={{ fontSize: "17px", color: "#0070F2" }}>
                              {listItem}
                            </li>
                          )
                        )}
                      </ul>
                      {/* <Typography fontSize={"16px"} color="#0070F2">
                        <CustomizeSpan fontWeight={700} color="#667085">
                          {" "}
                          Tips:
                        </CustomizeSpan>{" "}
                        Adjust the "OR" clauses to broaden the search or include
                        alternative keywords. Use location-specific filters for
                        Saint Paul, MN, or "remote/hybrid work" as appropriate.
                        Add years of experience (e.g., "3+ years" or "5 years")
                        if platforms support such filters.
                      </Typography> */}
                    </div>
                  </StyledFlexRowView>
                </StyledSpaceBetweenBox>
              </CommonGrid2>
            )}
            {jobData?.insights?.insights?.map((item, index) => (
              <CommonGrid2
                key={index}
                container
                border={"1px solid #0070F2"}
                borderRadius={"10px"}
                padding={"12px"}
                boxShadow={"0px 0px 2px 1px rgba(0, 113, 242, 0.58)"}
                gap={"10px"}
              >
                <StyledSpaceBetweenBox
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <StyledFlexRowView>
                    <div
                      style={{
                        background: item.fix
                          ? "unset"
                          : hexToRgba("#DCFAE6", 0.6),
                        borderRadius: "50%",
                        padding: "5px 10px",
                        ...(item.fix && {
                          display: "flex",
                          alignItems: "center",
                        }),
                      }}
                    >
                      {item.fix ? (
                        <AlertCircleIco />
                      ) : (
                        <TickIcon color={"green"} />
                      )}
                    </div>

                    <Typography
                      variant="h4"
                      fontWeight={400}
                      color="#0070F2"
                      fontSize={17}
                    >
                      {item.content}
                    </Typography>
                  </StyledFlexRowView>

                  {item.fix && (
                    <CommonTippy
                      content={"Processing... please wait some minutes"}
                      visible={fixLoader}
                      placement="top"
                      disabled={toolTipOpen}
                      color="#7F56D9"
                      fontWeight={600}
                      fontSize={"12px"}
                      textAlign="center"
                    >
                      <CommonTippy
                        content="If you fix this, any changes that have been made may be lost."
                        placement="top"
                        color="#7F56D9"
                        disabled={toolTipOpen || fixLoader}
                        fontWeight={600}
                        fontSize={"12px"}
                        textAlign="center"
                      >
                        <div>
                          <CommonButton
                            variant="outlined"
                            color="#344054"
                            border="#D0D5DD"
                            fontWeight="600"
                            onClick={() => fetchJobsList(job_id)}
                            disabled={fixLoader}
                            loading={fixLoader}
                            value={item?.value ? item?.value : "Fix"}
                            style={{
                              marginLeft: "auto",
                            }}
                          />
                        </div>
                      </CommonTippy>
                    </CommonTippy>
                  )}
                </StyledSpaceBetweenBox>
              </CommonGrid2>
            ))}
            {/* <CommonGrid2
                  container
                  border={"1px solid #EAECF0"}
                  borderRadius={"10px"}
                  padding={"12px"}
                  background="#F5F5F5"
                  boxShadow={"0px 3px 2px 0px rgba(0, 0, 0, 0.36)"}
                  gap={"10px"}
                >
                  <StyledSpaceBetweenBox width={"100%"}>
                    <StyledFlexRowView>
                      <div
                        style={{
                          background: "#FEF0C7",
                          borderRadius: "50%",
                          padding: "12px",
                        }}
                      ></div>
                      <Typography
                        variant="h4"
                        fontWeight={500}
                        color="#667085"
                        fontSize={17}
                      >
                        {"Talk to assistant or @Talairo Bot"}
                      </Typography>
                    </StyledFlexRowView>
  
                    <CommonButton
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      background="#fff"
                      fontWeight="600"
                      onClick={handleAskAIClcik}
                      value={"Ask AI"}
                      disabled={!iconVisible}
                    />
                  </StyledSpaceBetweenBox>
                </CommonGrid2> */}
          </>
        )}
      </CommonGrid2>
    </CommonGrid2>
  </CommonGrid2>
);

export default Result;
