import { URL } from "../../api/Api";
import { catchError, DELETE, GET, POST, PUT } from "../axios/Axios";

export const UserManagementPost = async (payload) => {
  try {
    const response = await POST(`${URL}/customers/permission/`, payload, {});
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const RoleCall = async () => {
  try {
    const response = await GET(`${URL}/customers/role/`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const TeamGetCall = async (condition, id) => {
  try {
    const url = condition
      ? `${URL}/customers/permission/${id}`
      : `${URL}/customers/permission/`;
    const response = await GET(url, {}, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const UserManagementPut = async (id, payload) => {
  try {
    const response = await PUT(
      `${URL}/customers/permission/${id}`,
      payload,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const UserManagementRoleDelete = async (payload) => {
  try {
    const response = await DELETE(`${URL}/customers/role/update`, payload, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const UserManagementRoleManage = async (payload) => {
  try {
    const response = await PUT(`${URL}/customers/role/update`, payload, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
