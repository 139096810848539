import { BrowserRouter } from "react-router-dom";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import MainRoutes from "./routes";
import themes from "./themes";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={themes({ primaryColor: "#7F56D9", secondaryColor: "#6941C6" })}
      >
        <CssBaseline />
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
