import { Autocomplete, Chip, styled, TextField } from "@mui/material";
import React from "react";

export const CustomAutocomplete = styled(Autocomplete)(({ error }) => ({
  "& .MuiAutocomplete-inputRoot": {
    alignItems: "start",
    alignContent: "flex-start",
    padding: "5px",
    border: `1px solid ${error ? "red" : "#D0D5DD"}`,
    borderRadius: "5px",
    alignItems: "center",
    gap: "4px",
  },
  "& .undefined": {
    border: "none !important",
  },
  "& .MuiChip-root": {
    gap: "10px",
    border: "1px solid",
    "& .MuiChip-deleteIcon": {
      width: "20px",
    },
  },
  "& .extracted": {
    color: "red",
    border: "1px solid red",
  },
  "& .MuiAutocomplete-tag": {
    margin: "0px 3px",
    borderRadius: "5px",
    fontSize: "12px",
    padding: "2px 6px",
  },
  "& input.MuiInputBase-input": {
    padding: "6px !important",
    "&::placeholder": {
      color: error && "#d32f2f",
    },
  },
}));

export const AutocompleteText = (props) => {
  const {
    onChange,
    value,
    title,
    key,
    onDelete,
    placeholder,
    isDisabled,
    options,
  } = props.autoData;

  return (
    <CustomAutocomplete
      disabled={isDisabled}
      key={key !== undefined && key}
      title={title !== undefined && title}
      multiple
      onChange={(event, newValue) => {
        onChange && onChange(event, newValue);
      }}
      error={props?.error}
      options={options || []}
      getOptionLabel={(option) => option.content || ""}
      freeSolo
      value={value || []}
      limitTags={2}
      renderTags={(value, getTagProps) =>
        value &&
        value.length !== 0 &&
        value.map((option, index) => (
          <Chip
            key={option.content + index}
            variant={`outlined ${option.name}`}
            border={option.name ? "#D0D5DD" : "none"}
            label={option.content}
            {...getTagProps({ index })}
            onDelete={() => onDelete(option)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          error={props?.error}
          helperText={props?.helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
          }}
          placeholder={placeholder}
        />
      )}
    />
  );
};
