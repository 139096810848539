import {
    Autocomplete,
    Grid2,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import ChevronDownIco from "../../../../assets/icons/ChevronDownIco";
import { StyledFlexColumnView } from "../../../common-components/StyledComponents";

function Engage() {
    const [selectedTaxTerm, setSelectedTaxTerm] = useState("");

    return (
        <Grid2 container spacing={3}>

            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Tax Term"}
                    </Typography>
                    <Autocomplete
                        disablePortal
                        options={["C2C", "1099", "W2 Contract", "W2 Employee"]}
                        value={selectedTaxTerm}
                        onChange={(event, newValue) => setSelectedTaxTerm(newValue || "")}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "5px",
                                border: "1px solid #D0D5DD",
                                alignItems: "center",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                placeholder="Select Tax Term"
                                variant="outlined"
                            />
                        )}
                        popupIcon={<ChevronDownIco />}
                    />
                </StyledFlexColumnView>
            </Grid2>


            {selectedTaxTerm === "C2C" && (
                <>
                    <Grid2 size={4}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Employer Name"}
                            </Typography>
                            <TextField
                                fullWidth
                                value="Zahir"
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #D0D5DD",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "12px",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                            />
                        </StyledFlexColumnView>
                    </Grid2>
                    <Grid2 size={4}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Hourly Bill Rate"}
                            </Typography>
                            <TextField
                                fullWidth
                                value="$100.00"
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #D0D5DD",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "12px",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                            />
                        </StyledFlexColumnView>
                    </Grid2>
                    <Grid2 size={4}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Employer Phone"}
                            </Typography>
                            <TextField
                                fullWidth
                                value="+1000000000"
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #D0D5DD",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "12px",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                            />
                        </StyledFlexColumnView>
                    </Grid2>
                    <Grid2 size={4}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Employer Email"}
                            </Typography>
                            <TextField
                                fullWidth
                                value="tim@apple.com"
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #D0D5DD",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "12px",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                            />
                        </StyledFlexColumnView>
                    </Grid2>
                    <Grid2 size={4}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Employer Contact Name"}
                            </Typography>
                            <TextField
                                fullWidth
                                value="Tim Cook"
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #D0D5DD",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "12px",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                            />
                        </StyledFlexColumnView>
                    </Grid2>
                </>
            )}
            {selectedTaxTerm === "W2 Contract" && (
                <Grid2 size={4}>
                    <StyledFlexColumnView>
                        <Typography
                            sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                        >
                            {"All Inclusive Hourly Pay Rate"}
                        </Typography>
                        <TextField
                            fullWidth
                            value="$100.00"
                            variant="outlined"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    backgroundColor: "#FFFFFF",
                                    border: "1px solid #D0D5DD",
                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                },
                                "& .MuiInputBase-input::placeholder": {
                                    color: "#667085",
                                    opacity: 1,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                },
                            }}
                        />
                    </StyledFlexColumnView>
                </Grid2>
            )}

            {selectedTaxTerm === "W2 Employee" && (
                <Grid2 size={4}>
                    <StyledFlexColumnView>
                        <Typography
                            sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                        >
                            {"All Inclusive Hourly Pay Rate or Annual Salary"}
                        </Typography>
                        <TextField
                            fullWidth
                            value="$100.00"
                            variant="outlined"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    backgroundColor: "#FFFFFF",
                                    border: "1px solid #D0D5DD",
                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                },
                                "& .MuiInputBase-input::placeholder": {
                                    color: "#667085",
                                    opacity: 1,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                },
                            }}
                        />
                    </StyledFlexColumnView>
                </Grid2>
            )}
        </Grid2>
    );
}

export default Engage;
