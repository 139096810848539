import { Box, Divider, Grid2, Typography } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import React, { useEffect } from "react";
import ResumeIntelligenceNote from "../../../../assets/icons/ResumeIntelligenceNote";
import WarningIco from "../../../../assets/icons/WarningIco";
import CommonButton from "../../../../component/Button";
import { HandleSkeletion } from "../../../../utils/constants";
import {
  CommonGrid2,
  CustomTooltip,
} from "../../../common-components/StyledComponents";
import { CustomPaper } from "./Style";

function ResumeScores({
  resumeData,
  data,
  isLoading,
  handleScoreFix,
  fixLoading,
}) {
  return (
    <Box>
      <CustomPaper variant="outlined" padding={"16px"} marginBottom={"16px"}>
        <Grid2 container justifyContent="center" gap={isLoading && "1rem"}>
          {isLoading
            ? Array.from({ length: 5 }).map((_, index) => (
                <CommonGrid2 item key={index}>
                  <HandleSkeletion height={100} width={100} />
                </CommonGrid2>
              ))
            : resumeData.map((item, index) => {
                const color = () => {
                  if (item?.max_score === 100) {
                    const data =
                      item?.score >= 75
                        ? "#47CD89"
                        : item?.score >= 50
                        ? "#FDB022"
                        : "orange";
                    return data;
                  } else if (item?.max_score === 50) {
                    const data =
                      item?.score >= 38
                        ? "#47CD89"
                        : item?.score >= 25
                        ? "#FDB022"
                        : "orange";
                    return data;
                  } else if (item?.max_score === 20) {
                    const data =
                      item?.score >= 15
                        ? "#47CD89"
                        : item?.score >= 10
                        ? "#FDB022"
                        : "orange";
                    return data;
                  } else if (item?.max_score === 10) {
                    const data =
                      item?.score >= 8
                        ? "#47CD89"
                        : item?.score >= 5
                        ? "#FDB022"
                        : "orange";
                    return data;
                  } else if (item?.max_score === 5) {
                    const data =
                      item?.score >= 4
                        ? "#47CD89"
                        : item?.score >= 2
                        ? "#FDB022"
                        : "orange";
                    return data;
                  } else {
                    const data =
                      item?.score >= 4
                        ? "#47CD89"
                        : item?.score >= 2
                        ? "#FDB022"
                        : "orange";
                    return data;
                  }
                };
                if (item?.score === undefined) return null;
                return (
                  <CommonGrid2 item key={index}>
                    <CustomTooltip
                      arrow
                      disabled={item?.justification === ""}
                      title={item?.justification}
                      placement="top"
                      fontWeight="600"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mx={1.5}
                        sx={{
                          cursor:
                            item?.justification === "" ? "auto" : "pointer",
                        }}
                      >
                        <Gauge
                          width={80}
                          height={80}
                          value={item.score}
                          valueMax={item?.max_score}
                          cornerRadius="50%"
                          text={({ value, valueMax }) => `${item?.score}%`}
                          sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                              fontSize: 14,
                              color: "#101828",
                              fontWeight: 600,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                              fill: color,
                            },
                          })}
                        />

                        <Typography
                          fontSize={"12px"}
                          color={"#475467"}
                          fontWeight={500}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    </CustomTooltip>
                  </CommonGrid2>
                );
              })}
        </Grid2>
      </CustomPaper>
      <CustomPaper variant="outlined" marginBottom="1.1rem">
        <CommonGrid2
          alignItems={"center"}
          display={"flex"}
          gap={"0.5rem"}
          padding={"10px"}
        >
          <ResumeIntelligenceNote />
          <Typography variant="h5" color="#101828">
            Candidate Bio
          </Typography>
        </CommonGrid2>
        <Divider />
        <CommonGrid2 padding={`${isLoading ? "0px 1rem" : "0.8rem 1rem"}`}>
          {isLoading ? (
            <HandleSkeletion height={100} width={"100%"} />
          ) : (
            <Typography variant="p" color="#667085" fontSize={"16px"}>
              {data?.personal_details?.candidate_bio}
            </Typography>
          )}
        </CommonGrid2>
      </CustomPaper>
      {data?.["insights"] && data?.["insights"]?.["insights"]?.length !== 0 && (
        <CustomPaper
          variant="outlined"
          marginBottom="1.1rem"
          padding={`${isLoading ? "0px 14px" : "14px"}`}
        >
          {isLoading ? (
            <HandleSkeletion height={60} width={"100%"} />
          ) : (
            <CommonGrid2
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CommonGrid2 display={"flex"} gap={"10px"} alignItems={"center"}>
                <WarningIco />
                <Typography variant="p" color="#667085" fontSize={"16px"}>
                  {data?.["insights"]?.["insights"][0]?.["content"]}
                </Typography>
              </CommonGrid2>
              {data?.["insights"]?.["insights"][0]?.["fix"] && (
                <CommonButton
                  variant="outlined"
                  color="#344054"
                  border="#D0D5DD"
                  fontWeight="600"
                  value="Fix"
                  onClick={handleScoreFix}
                  loading={fixLoading}
                  disabled={fixLoading}
                />
              )}
            </CommonGrid2>
          )}
        </CustomPaper>
      )}
    </Box>
  );
}

export default ResumeScores;
