import { URL } from "../../api/Api";
import { VENDOR_PARTNER } from "../Action";
import { catchError, DELETE, GET, POST, PUT } from "../axios/Axios";

export const VendorAddCall = (payload) => {
  return async (dispatch) => {
    try {
      const response = await POST(`${URL}/customers/partner/`, payload, {});
      dispatch({ type: VENDOR_PARTNER.vendorAddCall, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const VendorUpdateCall = async (id, payload) => {
  try {
    const response = await PUT(`${URL}/customers/partner/${id}`, payload);
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const VendorGetCall = async (id) => {
  try {
    const response = await GET(`${URL}/customers/partner/${id}`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const VendorTableGetCall = async () => {
  try {
    const response = await GET(`${URL}/customers/partner/`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const VendorDelete = async (id) => {
  try {
    const response = await DELETE(`${URL}/customers/partner/${id}`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
