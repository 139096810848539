import {
  Checkbox,
  FormControlLabel,
  Grid2,
  IconButton,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteIco from "../../../../assets/icons/DeleteIco";
import DotsGrid from "../../../../assets/icons/DotsGrid";
import CommonButton from "../../../../component/Button";
import CommonTextField from "../../../../component/Input";
import { HandleSkeletion } from "../../../../utils/constants";
import { StyledFlexRowView } from "../../../common-components/StyledComponents";
import { CustomPaper } from "../../../resume-intelligence/create-candidate-resume/components/Style";

function SeededPreScreen({
  alignment,
  preScreen,
  onDragEnd,
  handleSeededDelete,
  isLoading,
  handleAddSeeded,
  onListChange,
  handleEnableDynamicCheckBox,
}) {
  const { values, errors, setFieldValue } = useFormikContext();

  const debounceTimer = React.useRef(null);
  const [seededData, setSeededData] = useState(values["pre_screen_questions"]);
  useEffect(() => {
    setSeededData(values["pre_screen_questions"]);
  }, [values]);

  const handleListChange = (e, index) => {
    const updatedQuestions = [...values["pre_screen_questions"]];
    updatedQuestions[index] = e.target.value;

    setSeededData(updatedQuestions);

    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      onListChange(updatedQuestions, "pre_screen_questions");
    }, 500);
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 item size={12} marginTop={"1rem"}>
        <Typography fontSize={"16px"} fontWeight={500} color={"#101828"}>
          {"Seeded Pre-Screen Questions"}
        </Typography>
      </Grid2>
      <Grid2 item size={12}>
        {isLoading ? (
          Array.from({ length: 2 }).map((_, index) => (
            <HandleSkeletion height={60} />
          ))
        ) : (
          <DragDropContext
            onDragEnd={(event) => onDragEnd(event, setFieldValue, values)}
          >
            <Droppable droppableId="droppable-questions">
              {(provided) => (
                <Grid2
                  container
                  // spacing={2.5}
                  size={12}
                  {...provided?.droppableProps}
                  ref={provided.innerRef}
                >
                  {(alignment === "Edit" ? seededData : preScreen)?.map(
                    (question, index) => (
                      <Draggable
                        draggableId={`drag-${index}`}
                        index={index}
                        key={`question-${index}`}
                      >
                        {(provided) => (
                          <Grid2
                            item
                            size={12}
                            ref={provided.innerRef}
                            marginBottom={"1.2rem"}
                            {...provided?.draggableProps}
                          >
                            <StyledFlexRowView
                              gap="15px"
                              alignItems={"flex-start"}
                            >
                              {alignment === "View" ? (
                                <CustomPaper
                                  variant="outlined"
                                  border="1px solid #D0D5DD !important"
                                  width="100%"
                                  padding={"10px 12px"}
                                  borderRadius="8px"
                                >
                                  <Typography
                                    fontSize={"16px"}
                                    fontWeight={500}
                                    color={"#101828"}
                                  >
                                    {question}
                                  </Typography>
                                </CustomPaper>
                              ) : (
                                <CommonTextField
                                  placeholder={"Enter the Question"}
                                  padding="10px"
                                  error={Boolean(
                                    errors["pre_screen_questions"]?.[index]
                                  )}
                                  helperText={
                                    errors["pre_screen_questions"]?.[index]
                                  }
                                  value={question}
                                  onChange={(e) => {
                                    handleListChange(e, index);
                                  }}
                                />
                              )}

                              <IconButton
                                size="small"
                                disabled={alignment === "View"}
                                sx={{
                                  border: "1px solid #D0D5DD",
                                  borderRadius: "7px",
                                  padding: "10px",
                                  opacity: alignment === "View" ? 0.5 : 1,
                                }}
                                {...provided?.dragHandleProps}
                              >
                                <DotsGrid />
                              </IconButton>

                              <IconButton
                                size="small"
                                disabled={alignment === "View"}
                                sx={{
                                  border: "1px solid #D0D5DD",
                                  borderRadius: "7px",
                                  padding: "10px",
                                  opacity: alignment === "View" ? 0.5 : 1,
                                }}
                                onClick={() =>
                                  alignment === "View"
                                    ? {}
                                    : handleSeededDelete(
                                        question,
                                        index,
                                        values
                                      )
                                }
                              >
                                <DeleteIco />
                              </IconButton>
                            </StyledFlexRowView>
                          </Grid2>
                        )}
                      </Draggable>
                    )
                  )}

                  {provided?.placeholder}
                </Grid2>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {alignment !== "View" && (
          <Grid2 item size={12} marginBottom={"1rem"}>
            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              value={"Add Questions"}
              disabled={isLoading}
              onClick={() => handleAddSeeded(values)}
              padding="10px 20px"
            />
          </Grid2>
        )}
        <Grid2 size={{ xs: 12 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.enable_dynamic_pre_screen_questions}
                onChange={handleEnableDynamicCheckBox}
              />
            }
            label="Enable Dynamic Pre Screen Questions"
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

export default SeededPreScreen;
