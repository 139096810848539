const NoData = () => {
  return (
    <svg
      width="30"
      height="50"
      viewBox="0 0 434 536"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M269.436 239.047C251.794 221.405 228.244 211.614 203.174 211.614C178.105 211.614 154.554 221.321 136.913 239.047C119.271 256.773 109.479 280.239 109.479 305.309C109.479 330.378 119.187 353.929 136.913 371.57C154.554 389.212 178.105 399.003 203.174 399.003C228.244 399.003 251.794 389.296 269.436 371.57C305.901 335.021 305.901 275.596 269.436 239.047ZM260.488 362.623C245.21 377.901 224.868 386.342 203.174 386.342C181.481 386.342 161.138 377.901 145.86 362.623C130.582 347.345 122.141 327.002 122.141 305.309C122.141 283.615 130.582 263.272 145.86 247.994C161.138 232.716 181.481 224.275 203.174 224.275C224.868 224.275 245.21 232.716 260.488 247.994C292.058 279.564 292.058 330.969 260.488 362.623Z"
        fill="#7F56D9"
      />
      <path
        d="M424.073 479.023L346.079 401.029C338.819 393.77 328.775 390.309 318.645 391.491L307.419 380.264C343.546 330.04 339.157 259.558 294.082 214.399C243.943 164.26 162.403 164.26 112.264 214.399C62.1243 264.538 62.1243 346.078 112.264 396.217C137.333 421.287 170.253 433.78 203.173 433.78C229.509 433.78 255.845 425.592 278.129 409.554L289.355 420.781C288.174 430.91 291.634 440.955 298.894 448.129L376.888 526.208C383.388 532.708 391.913 536 400.523 536C409.048 536 417.658 532.708 424.158 526.208C430.488 519.878 433.949 511.521 433.949 502.574C433.865 493.711 430.319 485.27 424.073 479.023ZM121.127 387.27C75.9675 342.111 75.9675 268.506 121.127 223.346C143.749 200.725 173.376 189.414 203.089 189.414C232.801 189.414 262.429 200.725 285.05 223.346C330.21 268.506 330.21 342.111 285.05 387.27C239.891 432.429 166.37 432.429 121.127 387.27ZM415.126 517.261C407.022 525.364 393.854 525.364 385.836 517.261L307.841 439.266C302.861 434.286 300.751 427.027 302.354 420.021C302.861 417.911 302.185 415.716 300.666 414.197L288.258 401.788C288.342 401.704 288.427 401.535 288.511 401.366C290.368 399.678 292.394 398.074 294.167 396.217C296.024 394.36 297.627 392.419 299.316 390.478C299.4 390.393 299.569 390.393 299.653 390.309L312.061 402.717C313.581 404.236 315.775 404.912 317.886 404.405C324.892 402.801 332.067 404.912 337.131 409.892L415.126 487.971C419.009 491.854 421.203 497.087 421.203 502.658C421.203 508.145 419.009 513.378 415.126 517.261Z"
        fill="#7F56D9"
      />
      <path
        d="M239.896 268.504C237.448 266.056 233.397 266.056 230.949 268.504L203.178 296.275L175.407 268.504C172.959 266.056 168.908 266.056 166.46 268.504C164.012 270.952 164.012 275.004 166.46 277.452L194.231 305.307L166.46 333.078C164.012 335.526 164.012 339.577 166.46 342.025C167.726 343.291 169.33 343.882 170.934 343.882C172.537 343.882 174.141 343.291 175.407 342.025L203.178 314.254L230.949 342.025C232.215 343.291 233.819 343.882 235.423 343.882C237.026 343.882 238.63 343.291 239.896 342.025C242.344 339.577 242.344 335.526 239.896 333.078L212.126 305.307L239.896 277.452C242.344 275.004 242.344 271.037 239.896 268.504Z"
        fill="#7F56D9"
      />
      <path
        d="M140.293 50.2249H300.249C303.71 50.2249 306.58 47.355 306.58 43.8942C306.58 40.4334 303.71 37.5635 300.249 37.5635H140.293C136.832 37.5635 133.962 40.4334 133.962 43.8942C133.962 47.355 136.832 50.2249 140.293 50.2249Z"
        fill="#7F56D9"
      />
      <path
        d="M300.333 71.0713H140.208C136.747 71.0713 133.877 73.9412 133.877 77.402C133.877 80.8628 136.747 83.7327 140.208 83.7327H300.333C303.794 83.7327 306.664 80.8628 306.664 77.402C306.664 73.9412 303.794 71.0713 300.333 71.0713Z"
        fill="#7F56D9"
      />
      <path
        d="M300.332 104.922H216.26C212.8 104.922 209.93 107.792 209.93 111.253C209.93 114.713 212.8 117.583 216.26 117.583H300.332C303.793 117.583 306.663 114.713 306.663 111.253C306.663 107.792 303.793 104.922 300.332 104.922Z"
        fill="#7F56D9"
      />
      <path
        d="M300.332 138.686H216.26C212.8 138.686 209.93 141.556 209.93 145.017C209.93 148.478 212.8 151.347 216.26 151.347H300.332C303.793 151.347 306.663 148.478 306.663 145.017C306.663 141.556 303.793 138.686 300.332 138.686Z"
        fill="#7F56D9"
      />
      <path
        d="M320.419 490.336H46.3409V469.402H286.824C290.285 469.402 293.155 466.532 293.155 463.072C293.155 459.611 290.285 456.741 286.824 456.741H12.6615V99.0126H92.6818C96.1426 99.0126 99.0126 96.1426 99.0126 92.6818V12.6615H341.437V379C341.437 382.46 344.307 385.33 347.768 385.33C351.229 385.33 354.099 382.46 354.099 379V46.2565H375.032V409.471C375.032 412.932 377.902 415.802 381.363 415.802C384.824 415.802 387.694 412.932 387.694 409.471V39.9258C387.694 36.465 384.824 33.5951 381.363 33.5951H354.099V6.33073C354.099 2.86993 351.229 0 347.768 0H92.7663C91.0781 0 89.4743 0.675278 88.2925 1.85701L1.85701 88.2081C0.675276 89.3899 0 90.9936 0 92.6818V462.987C0 466.448 2.86993 469.318 6.33073 469.318H33.5951V496.582C33.5951 500.043 36.465 502.913 39.9258 502.913H320.335C323.796 502.913 326.666 500.043 326.666 496.582C326.666 493.121 323.88 490.336 320.419 490.336ZM21.6089 86.3511L86.3511 21.6089V86.3511H21.6089Z"
        fill="#7F56D9"
      />
    </svg>
  );
};
export default NoData;
