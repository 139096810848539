function BookMarkIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.33301 5.2C3.33301 4.0799 3.33301 3.51984 3.55099 3.09202C3.74274 2.71569 4.0487 2.40973 4.42503 2.21799C4.85285 2 5.4129 2 6.53301 2H9.46634C10.5864 2 11.1465 2 11.5743 2.21799C11.9506 2.40973 12.2566 2.71569 12.4484 3.09202C12.6663 3.51984 12.6663 4.0799 12.6663 5.2V14L7.99967 11.3333L3.33301 14V5.2Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BookMarkIco;
