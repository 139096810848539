import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { Avatar, Checkbox, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import FileAddIco from "../../../assets/icons/FileAddIco";
import ShareIco from "../../../assets/icons/ShareIco";
import CommonButton from "../../../component/Button";
import CustomMenuItem from "../../../component/Menu";
import { DotColor, StatusChange, StyledFlexRowView, TableContainerBorder } from "../../common-components/StyledComponents";
import ManagePermissions from "./ManagePermissions";
import RemoveMember from "./RemoveMember";

export default function Team() {
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [openRemoveMemberDialog, setRemoveMemberDialog] = useState(false);
    const [openManagePermissionsDialog, setManagePermissionsDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClickDot = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChangeRemoveMember = () => {
        setRemoveMemberDialog(true);
    };
    const handleCloseRemoveMember = () => {
        setRemoveMemberDialog(false);
    };
    const handleChangeManagePermission = () => {
        setManagePermissionsDialog(true);
    };
    const handleCloseManagePermission = () => {
        setManagePermissionsDialog(false);
    };

    const menuData = [
        { title: "Share", image: <ShareIco /> },
        { title: "Add to an Active Job", image: <FileAddIco /> },
    ];

    const sortedData = [
        { id: 1, name: "Olivia Rhye", permissions: "Admin", position: "CEO", email: "olivia@mail.com", avatarUrl: "https://mui.com/static/images/avatar/1.jpg" },
        { id: 2, name: "Lana Steiner", permissions: "Manager", position: "Co-Founder", email: "lana@mail.com", avatarUrl: "https://mui.com/static/images/avatar/2.jpg" },
        { id: 3, name: "Kate Morrison", permissions: "Base", position: "Base", email: "kate@mail.com", avatarUrl: "https://mui.com/static/images/avatar/3.jpg" },
    ];

    const headerData = ["User", "Position", "Permissions", "Email Address", ""];

    const handleRowCheckboxChange = (event, rowId) => {
        const newSelectedRows = new Set(selectedRows);
        if (event.target.checked) {
            newSelectedRows.add(rowId);
        } else {
            newSelectedRows.delete(rowId);
        }
        setSelectedRows(newSelectedRows);
    };

    const handleSelectAllCheckboxChange = (event) => {
        if (event.target.checked) {
            const allRowIds = sortedData.map((row) => row.id);
            setSelectedRows(new Set(allRowIds));
        } else {
            setSelectedRows(new Set());
        }
    };

    return (
        <>
            <TableContainerBorder>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={selectedRows.size === sortedData.length}
                                    indeterminate={
                                        selectedRows.size > 0 && selectedRows.size < sortedData.length
                                    }
                                    onChange={handleSelectAllCheckboxChange}
                                />
                            </TableCell>
                            {headerData.map((item, index) => (
                                <TableCell key={index}>{item}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={selectedRows.has(row.id)}
                                        onChange={(event) => handleRowCheckboxChange(event, row.id)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Avatar alt={row.name} src={row.avatarUrl} />
                                        </Grid>
                                        <Grid item>{row.name}</Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>{row.position}</TableCell>
                                <TableCell>
                                    <StatusChange>
                                        <DotColor
                                            color={
                                                row.permissions === "Base"
                                                    ? "#667085"
                                                    : row.permissions === "Manager"
                                                        ? "orange"
                                                        : row.permissions === "Admin"
                                                            ? "green"
                                                            : "#667085"
                                            }
                                        />
                                        {row.permissions}
                                    </StatusChange>
                                </TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>
                                    <MoreVertOutlinedIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={(event) => handleClickDot(event)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainerBorder>

            <StyledFlexRowView style={{ padding: "32px 0px" }}>
                <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    fontWeight="600"
                    value="Remove from Team"
                    padding={"10px 16px"}
                    disabled={selectedRows.size < 1}
                    onClick={handleChangeRemoveMember}

                />
                <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    fontWeight="600"
                    value="Manage Permissions"
                    padding={"10px 16px"}
                    disabled={selectedRows.size < 1}
                    onClick={handleChangeManagePermission}

                />
            </StyledFlexRowView>
            <RemoveMember
                open={openRemoveMemberDialog}
                onClose={handleCloseRemoveMember}
                onApply={() => {
                    handleCloseRemoveMember();
                }}
                selectedUsers={sortedData.filter((row) => selectedRows.has(row.id))}
            />
            <ManagePermissions
                open={openManagePermissionsDialog}
                onClose={handleCloseManagePermission}
                onApply={() => {
                    handleCloseManagePermission();
                }}
                selectedUsers={sortedData.filter((row) => selectedRows.has(row.id))}
            />

            <CustomMenuItem
                open={open}
                anchorEl={anchorEl}
                handleMenuClose={handleMenuClose}
                menuData={menuData}
            />
        </>
    );
}
