import { URL } from "../../api/Api";
import { HARIZON } from "../Action";
import { catchError, GET } from "../axios/Axios";

export const HarizonAnalytics = (params) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/usage/report`, params);
      dispatch({ type: HARIZON.harizonAnalytics, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};
