import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import XCloseIco from "../../assets/icons/XCloseIco";
import DeleteNoteBackgroundImg from "../../assets/images/delete-note-background.png";
import CommonButton from "../../component/Button";
import moment from "moment";
import { StyledDialog } from "./StyledComponents";

function NewDeleteNote({
  deleteNoteDialogOpen,
  handleDeleteNoteDialogClose,
  noteList,
  noteLoading,
  handleDeleteNoteById,
}) {
  return (
    <StyledDialog
      fullWidth
      maxWidth={"xs"}
      open={deleteNoteDialogOpen}
      onClose={handleDeleteNoteDialogClose}
    >
      <img
        src={DeleteNoteBackgroundImg}
        style={{ position: "absolute", width: "max-content", opacity: "0.9" }}
      />
      <DialogActions>
        {/* <Button
          onClick={handleDeleteNoteDialogClose}
          sx={{ marginTop: "10px" }}
        >
          <XCloseIco />
        </Button> */}
        <CommonButton
          onClick={handleDeleteNoteDialogClose}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          value={<XCloseIco />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" marginTop={"12px"}>
          {"Delete Note"}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid2 spacing={2} container>
          <>
            <Grid2 size={12}>
              <Typography
                color="#475467"
                fontSize={16}
                fontFamily={"Inter"}
                fontWeight={500}
                fontStyle={"italic"}
              >
                {noteList.note
                  ? noteList.note
                  : "The candidate is a very hard working, responsibility and has a sense of responsibility, alongside a set of skills"}
              </Typography>
            </Grid2>

            <Grid2 size={12}>
              <Typography
                color="#667085"
                fontSize={16}
                fontFamily={"Inter"}
                fontWeight={500}
              >
                {`Created by `}
                <span style={{ fontWeight: 500, color: "#101828" }}>
                  {"username"}
                </span>
                {` on ${
                  noteList?.created_at &&
                  moment(noteList.created_at).format("DD MMM YYYY")
                }`}
              </Typography>
            </Grid2>

            <Grid2 size={12}>
              <CommonButton
                variant="contained"
                color="white"
                background="#D92D20"
                fontWeight="600"
                padding="10px 15px"
                value="Delete"
                width="100%"
                border={"1px solid #D92D20"}
                onClick={() => handleDeleteNoteById(noteList)}
                disabled={noteLoading}
                loading={noteLoading}
              />
            </Grid2>
          </>

          <Grid2 size={12}>
            <Typography
              color="#667085"
              fontSize={16}
              fontFamily={"Inter"}
              fontWeight={500}
            >
              {"This action cannot be undone."}
            </Typography>
          </Grid2>
        </Grid2>
      </DialogContent>
    </StyledDialog>
  );
}

export default NewDeleteNote;
