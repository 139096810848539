import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  CustomAccordion,
  CustomTable,
  DialogTableList,
  StyledTableTitleTableCell,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";
import { boolean, object } from "yup";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const SummaryInteractionDialog = ({ open, handleClose, interActionData }) => {
  console.log(process.env, "sfksnfklsnfklfj");
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      sx={{ "& .MuiDialog-paperFullWidth": { padding: "0px 25px 20px" } }}
    >
      <DialogActions>
        <Button onClick={handleClose} sx={{ left: "1.5rem" }}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" textAlign={"center"} marginTop={-5}>
          {interActionData.action}
        </Typography>
      </DialogTitle>
      {/* <TableContainerBorder sx={{ marginTop: "0px !important" }}>
        <Table>
          <TableHead>
            <TableRow>
              {headerData.map((item) => (
                <TableCell>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {interActionData?.updated_fields?.map((item) => {
              console.log(
                Object.keys(item?.old_value).length,
                "sflkhsjfkljfsljk"
              );
              return (
                <TableRow>
                  <TableCell>
                    {item?.field}

                    <TableRow>
                      <TableCell>sfkjb</TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell>{"item?.old_value"}</TableCell>
                  <TableCell>{"item?.new_value"}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainerBorder> */}

      {interActionData?.updated_fields?.map((item, index) => {
        return (
          <CustomAccordion borderBottom={"1px solid #EAECF0"}>
            <AccordionSummary
              expandIcon={<KeyboardArrowUpIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h4" margin={"0px"} fontWeight={600}>
                {item.field}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainerBorder
                sx={{
                  marginTop: "0px !important",
                  width: "79%",
                  margin: "auto",
                }}
              >
                <CustomTable>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "800 !important", minWidth: "120px" }}
                      >
                        Value
                      </TableCell>
                      {typeof item?.old_value === "object" &&
                        !Array.isArray(item?.old_value) &&
                        item?.old_value !== null &&
                        Object.keys(item?.old_value).map((key) => {
                          if (key === "id" || key === "job_info") return null;
                          return (
                            <TableCell
                              key={key}
                              sx={{ fontWeight: "800 !important" }}
                            >
                              {key
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </TableCell>
                          );
                        })}
                      {Array.isArray(item?.old_value) &&
                        item?.old_value.map((item, index) =>
                          item?.name === undefined ? (
                            <TableCell
                              key={index}
                              sx={{ fontWeight: "800 !important" }}
                            >
                              {"Field"}
                            </TableCell>
                          ) : (
                            <TableCell
                              key={index}
                              sx={{ fontWeight: "800 !important" }}
                            >
                              {item.name
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </TableCell>
                          )
                        )}
                      {typeof item?.old_value !== "object" &&
                        !Array.isArray(item?.old_value) && (
                          <TableCell sx={{ fontWeight: "800 !important" }}>
                            Fields
                          </TableCell>
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{ fontWeight: "800 !important" }}
                      >
                        Old Value
                      </TableCell>

                      {typeof item?.old_value === "object" &&
                        !Array.isArray(item?.old_value) &&
                        item?.old_value !== null &&
                        Object.keys(item?.old_value).map((key) => {
                          if (key === "id" || key === "job_info") return null;

                          return (
                            <TableCell
                              key={item.old_value[key]}
                              className={item.old_value[key]}
                            >
                              {item.old_value[key] || "-"}
                            </TableCell>
                          );
                        })}
                      {Array.isArray(item?.old_value) &&
                        item?.old_value.map((item, index) => {
                          return (
                            <TableCell key={index}>
                              <DialogTableList
                                gap="10px"
                                display="flex"
                                flexWrap="wrap"
                              >
                                {item?.content === undefined ? (
                                  <DialogTableList
                                    border="1px solid"
                                    padding="0px 3px"
                                    borderRadius="4px"
                                  >
                                    {item}
                                  </DialogTableList>
                                ) : (
                                  item?.content?.map((data) => (
                                    <DialogTableList
                                      border="1px solid"
                                      padding="0px 3px"
                                      borderRadius="4px"
                                    >
                                      {data}
                                    </DialogTableList>
                                  ))
                                )}
                              </DialogTableList>
                            </TableCell>
                          );
                        })}
                      {typeof item?.old_value !== "object" &&
                        !Array.isArray(item?.old_value) && (
                          <TableCell>
                            {typeof item?.old_value === "boolean"
                              ? item?.old_value
                                ? "True"
                                : "False"
                              : item?.old_value}
                          </TableCell>
                        )}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "800 !important" }}>
                        New Value
                      </TableCell>
                      {typeof item?.new_value === "object" &&
                        !Array.isArray(item?.new_value) &&
                        item?.old_value !== null &&
                        Object.keys(item?.new_value).map((key) => {
                          if (key === "id" || key === "job_info") return null;

                          return (
                            <TableCell key={item.new_value[key]}>
                              {item.new_value[key] || "-"}
                            </TableCell>
                          );
                        })}
                      {Array.isArray(item?.new_value) &&
                        item?.old_value.map((item, index) => (
                          <TableCell key={index}>
                            <DialogTableList
                              gap="10px"
                              display="flex"
                              flexWrap="wrap"
                            >
                              {item?.content === undefined ? (
                                <DialogTableList
                                  border="1px solid"
                                  padding="0px 3px"
                                  borderRadius="4px"
                                >
                                  {item}
                                </DialogTableList>
                              ) : (
                                item.content.map((data) => (
                                  <DialogTableList
                                    border="1px solid"
                                    padding="0px 3px"
                                    borderRadius="4px"
                                  >
                                    {data}
                                  </DialogTableList>
                                ))
                              )}
                            </DialogTableList>
                          </TableCell>
                        ))}
                      {typeof item?.old_value !== "object" &&
                        !Array.isArray(item?.old_value) && (
                          <TableCell>
                            {typeof item?.new_value === "boolean"
                              ? item?.new_value
                                ? "True"
                                : "False"
                              : item?.new_value}
                          </TableCell>
                        )}
                    </TableRow>
                  </TableBody>
                </CustomTable>
              </TableContainerBorder>
            </AccordionDetails>
          </CustomAccordion>
        );
      })}
    </Dialog>
  );
};
export default SummaryInteractionDialog;
