import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { CommonGrid2 } from "../../modules/common-components/StyledComponents";

function AuthLayout() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#FFFFFF",
        maxWidth: "550px",
        width: "100%",
        paddingX: {
          xs: "16px",
          sm: "32px",
        },
        borderRadius: "10px",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        margin: "auto",
      }}
    >
      <CommonGrid2
        container
        width={"100%"}
        alignItems="center"
        padding="16px"
        borderRadius="15px"
        boxShadow="0px 4px 20px rgba(0, 0, 0, 0.1)"
      >
        <CommonGrid2 item xs={12} width={"100%"}>
          <Outlet />
        </CommonGrid2>
      </CommonGrid2>
    </Box>
  );
}

export default AuthLayout;
