import { Avatar, Box, Switch, Typography } from "@mui/material";
import React from "react";
import { CustomPaper } from "../../../resume-intelligence/create-candidate-resume/components/Style";
import { HandleSkeletion } from "../../../../utils/constants";
import ExclamatoryIco from "../../../../assets/icons/ExclamatoryIco";
import { CustomDiv } from "../../../chat-bot/style";
import { CustomOrderList } from "../../style";
import QuestionMarkIco from "../../../../assets/icons/QuestionMarkIco";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import RecommendationIco from "../../../../assets/icons/RecommendationIco";

function Recommendations({
  matchedData,
  loading,
  handleQuestionEnable,
  enableQuestion,
}) {
  return loading ? (
    <HandleSkeletion height={100} width={"100%"} />
  ) : (
    <Box marginTop={"0.5rem"}>
      {matchedData?.insights?.feedback?.recommendations?.map((item, index) => (
        <CustomPaper
          variant="outlined"
          width="100%"
          padding={"12px"}
          marginBottom={"1rem"}
          display={"flex"}
          alignItems={"start"}
          key={index}
          gap="1rem"
          background="#F7F8F9"
        >
          <CustomDiv>
            <RecommendationIco />
          </CustomDiv>
          <Typography fontSize={"16px"} fontWeight={500} color={"#00144A"}>
            {item}
          </Typography>
        </CustomPaper>
      ))}

      {matchedData?.insights?.feedback?.pres_screen_questions &&
        matchedData?.insights?.feedback?.pres_screen_questions?.length !==
          0 && (
          <CustomPaper
            variant="outlined"
            width="100%"
            padding={"12px"}
            marginBottom={"1rem"}
            display={"flex"}
            alignItems={"start"}
            gap="1rem"
            background="#F7F8F9"
          >
            <CustomDiv>
              <QuestionMarkIco />
            </CustomDiv>
            <CustomDiv width="100%">
              <Typography variant="h5" fontWeight={600}>
                {"Pre-screen questions:"}
              </Typography>
              <CustomOrderList
                padding="0px 0px 0px 25px"
                margin="10px 0px"
                gap="10px"
                fontSize="15px"
              >
                {matchedData?.insights?.feedback?.pres_screen_questions?.map(
                  (item, index) => (
                    <li> {item}</li>
                  )
                )}
              </CustomOrderList>
            </CustomDiv>
            <CustomDiv>
              <CommonGrid2 display={"flex"} gap={"10px"} alignItems={"center"}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  whiteSpace={"nowrap"}
                  fontSize={"15px"}
                >
                  {"Include in Coversheet"}
                </Typography>
                <Switch
                  checked={enableQuestion.status}
                  onChange={(event) => handleQuestionEnable(event)}
                />
              </CommonGrid2>
            </CustomDiv>
          </CustomPaper>
        )}
    </Box>
  );
}

export default Recommendations;
