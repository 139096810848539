import { Grid } from "@mui/material";
import React from "react";

function Dashboard() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* {"dashboard"} */}
      </Grid>
    </Grid>
  );
}

export default Dashboard;
