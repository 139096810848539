import { Box, Button, Grid2, Typography } from "@mui/material";
import React from "react";

function CommonInputJd({ title, children, buttonName }) {
  return (
    <Box
      sx={{
        width: "600px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid2 container spacing={3}>
        <Grid2 size={12}>
          <Typography
            sx={{ fontWeight: 600, fontSize: "20px", color: "#101828" }}
          >
            {title}
          </Typography>
        </Grid2>

        <Grid2 size={12}>{children}</Grid2>

        <Grid2 size={12}>
          <Button
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              width: "100%",
              padding: "10px 14px",
              borderRadius: "8px",
              color: "#344054",
              border: "1px solid #D0D5DD",
              "&:hover": {
                backgroundColor: "#7F56D9",
              },
              fontSize: "14px",
              fontWeight: "bold",
              "&.Mui-disabled": {
                color: "#98A2B3",
                fontWeight: "bold",
                border: "1px solid #EAECF0",
                backgroundColor: "#F2F4F7",
              },
            }}
            disabled={true}
          >
            {buttonName}
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default CommonInputJd;
