import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import DropDownSelect from "../../../component/Select";
import { HandleSkeletion } from "../../../utils/constants";
import { CustomMarkDown } from "../../chat-bot/style";
import { Field, useFormikContext } from "formik";
import CommonTextField from "../../../component/Input";

export default function JobDescription({
  loading,
  coverSheetData,
  roleData,
  view_resume,
}) {
  const [textField, setTextField] = useState("");
  const { setFieldValue, values } = useFormikContext();
  const debounceTimer = React.useRef(null);

  useEffect(() => {
    setTextField(values["note"] || "");
  }, [values]);
  const handleInputChange = (e, item) => {
    setTextField(e.target.value);
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setFieldValue("note", e.target.value);
    }, 500);
  };

  return (
    <CommonGrid2>
      {(coverSheetData?.availability_status === null ||
        view_resume === "true") && (
        <>
          <Typography
            fontSize={"20px"}
            fontWeight={600}
            color={"#101828"}
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
          >
            Job Description
          </Typography>
          {loading ? (
            <HandleSkeletion height={120} />
          ) : (
            <CustomMarkDown>
              {typeof coverSheetData.jobDescription?.job_text === "string"
                ? coverSheetData.jobDescription?.job_text.trim()
                : JSON.stringify(coverSheetData.jobDescription?.job_text)}
            </CustomMarkDown>
          )}
        </>
      )}
      <CommonGrid2
        display={"flex"}
        flexDirection={"column"}
        gap={"1rem"}
        marginTop={"1rem"}
      >
        <Typography
          fontSize={"20px"}
          fontWeight={600}
          color={"#101828"}
          display={"flex"}
          alignItems={"center"}
          width={"100%"}
        >
          {"Are you interested in evaluating this role further ?"}
        </Typography>
        <CommonGrid2 size={6}>
          {loading ? (
            <HandleSkeletion height={80} />
          ) : (
            <Field name={"role"}>
              {({ field, form }) => {
                return (
                  <DropDownSelect
                    marginTop="0px"
                    title={"Select Role"}
                    removeAnimation={true}
                    data={roleData}
                    error={Boolean(
                      form.touched["availability_status"] &&
                        form.errors["availability_status"]
                    )}
                    disabled={coverSheetData?.availability_status}
                    helperText={
                      form.touched["availability_status"] &&
                      form.errors["availability_status"]
                    }
                    value={form.values["availability_status"] || ""}
                    handleChange={(event) => {
                      form.setFieldValue(
                        "availability_status",
                        event.target.value
                      );
                    }}
                  />
                );
              }}
            </Field>
          )}
        </CommonGrid2>
      </CommonGrid2>
      {coverSheetData?.availability_status ===
        "I am available but not interested" && (
        <CommonGrid2
          display={"flex"}
          flexDirection={"column"}
          gap={"1rem"}
          marginTop={"1rem"}
        >
          <Typography
            fontSize={"20px"}
            fontWeight={600}
            color={"#101828"}
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
          >
            {"What are you looking for ?"}
          </Typography>
          <CommonGrid2 size={6}>
            {loading ? (
              <HandleSkeletion height={80} />
            ) : (
              <Field name={"role"}>
                {({ field, form }) => {
                  return (
                    <CommonTextField
                      {...field}
                      multiline
                      rows={4}
                      placeholder={"Enter Answer"}
                      padding={"0px"}
                      placeholderFontSize="15px"
                      error={Boolean(
                        form.touched["note"] && form.errors["note"]
                      )}
                      helperText={form.touched["note"] && form.errors["note"]}
                      value={textField}
                      onChange={(e) => handleInputChange(e)}
                    />
                  );
                }}
              </Field>
            )}
          </CommonGrid2>
        </CommonGrid2>
      )}
    </CommonGrid2>
  );
}
