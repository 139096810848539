import {
    Autocomplete,
    Chip,
    Grid2,
    IconButton,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import ChevronDownIco from "../../../../assets/icons/ChevronDownIco";
import XCloseIco from "../../../../assets/icons/XCloseIco";
import { CommonGrid2, StyledFlexColumnView } from "../../../common-components/StyledComponents";

function AdditionalInformation() {
    return (
        <Grid2 container spacing={3}>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Highest Level of Education"}
                    </Typography>
                    <Autocomplete
                        disablePortal
                        options={["Bachelor’s Degree"]}
                        defaultValue={'Bachelor’s Degree'}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "5px",
                                border: "1px solid #D0D5DD",
                                alignItems: "center",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                placeholder="Bachelor’s Degree"
                                variant="outlined"
                            />
                        )}
                        popupIcon={
                            <IconButton sx={{ padding: 0 }}>
                                <ChevronDownIco />
                            </IconButton>
                        }
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Certificates"}
                    </Typography>
                    <Autocomplete
                        multiple
                        disablePortal
                        options={["APA-310", "BUGA-666"]}
                        defaultValue={["APA-310", "BUGA-666"]}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "5px",
                                border: "1px solid #D0D5DD",
                                alignItems: "flex-start",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                            },
                            "& .MuiAutocomplete-tag": {
                                marginBottom: "5px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                multiline

                                {...params}
                                fullWidth
                                placeholder=""
                                variant="outlined"
                            />
                        )}
                        popupIcon={
                            <IconButton sx={{ padding: 0, display: "none" }}>
                                <ChevronDownIco />
                            </IconButton>
                        }
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    key={index}
                                    label={option}
                                    //   sx={{ padding: "1px" }}
                                    {...getTagProps({ index })}
                                    //   onDelete={() => handleRemove(option)}
                                    deleteIcon={
                                        <IconButton
                                            sx={{
                                                padding: "0px",
                                                "& > svg": {
                                                    width: "16px",
                                                    height: "16px",
                                                },
                                            }}
                                        >
                                            <XCloseIco />
                                        </IconButton>
                                    }
                                    sx={{
                                        color: "#1E1F21",
                                        gap: "12px",
                                        borderRadius: "6px",
                                        border: "1px solid #D0D5DD",
                                        padding: "2px 2px 2px 6px !important",
                                        backgroundColor: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        "& > svg": {
                                            width: "14px",
                                            height: "14px",
                                        },
                                    }}
                                />
                            ))
                        }
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Industry"}
                    </Typography>
                    <Autocomplete
                        multiple
                        disablePortal
                        options={["Technology"]}
                        defaultValue={["Technology"]}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "5px",
                                border: "1px solid #D0D5DD",
                                alignItems: "flex-start",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                            },
                            "& .MuiAutocomplete-tag": {
                                marginBottom: "5px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                multiline

                                {...params}
                                fullWidth
                                placeholder=""
                                variant="outlined"
                            />
                        )}
                        popupIcon={
                            <IconButton sx={{ padding: 0, display: "none" }}>
                                <ChevronDownIco />
                            </IconButton>
                        }
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    key={index}
                                    label={option}
                                    //   sx={{ padding: "1px" }}
                                    {...getTagProps({ index })}
                                    //   onDelete={() => handleRemove(option)}
                                    deleteIcon={
                                        <IconButton
                                            sx={{
                                                padding: "0px",
                                                "& > svg": {
                                                    width: "16px",
                                                    height: "16px",
                                                },
                                            }}
                                        >
                                            <XCloseIco />
                                        </IconButton>
                                    }
                                    sx={{
                                        color: "#1E1F21",
                                        gap: "12px",
                                        borderRadius: "6px",
                                        border: "1px solid #D0D5DD",
                                        padding: "2px 2px 2px 6px !important",
                                        backgroundColor: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        "& > svg": {
                                            width: "14px",
                                            height: "14px",
                                        },
                                    }}
                                />
                            ))
                        }
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Languages"}
                    </Typography>
                    <Autocomplete
                        multiple
                        disablePortal
                        options={["English", "Spanish"]}
                        defaultValue={["English", "Spanish"]}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "5px",
                                border: "1px solid #D0D5DD",
                                alignItems: "flex-start",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                            },
                            "& .MuiAutocomplete-tag": {
                                marginBottom: "5px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                multiline

                                {...params}
                                fullWidth
                                placeholder=""
                                variant="outlined"
                            />
                        )}
                        popupIcon={
                            <IconButton sx={{ padding: 0, display: "none" }}>
                                <ChevronDownIco />
                            </IconButton>
                        }
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    key={index}
                                    label={option}
                                    //   sx={{ padding: "1px" }}
                                    {...getTagProps({ index })}
                                    //   onDelete={() => handleRemove(option)}
                                    deleteIcon={
                                        <IconButton
                                            sx={{
                                                padding: "0px",
                                                "& > svg": {
                                                    width: "16px",
                                                    height: "16px",
                                                },
                                            }}
                                        >
                                            <XCloseIco />
                                        </IconButton>
                                    }
                                    sx={{
                                        color: "#1E1F21",
                                        gap: "12px",
                                        borderRadius: "6px",
                                        border: "1px solid #D0D5DD",
                                        padding: "2px 2px 2px 6px !important",
                                        backgroundColor: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        "& > svg": {
                                            width: "14px",
                                            height: "14px",
                                        },
                                    }}
                                />
                            ))
                        }
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={8}>
            </Grid2>
            <CommonGrid2 size={4}>
                <CommonGrid2
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        Goverment / Security Classification
                    </Typography>
                    <Switch defaultChecked />
                </CommonGrid2>
            </CommonGrid2>
            <CommonGrid2 size={4}>
                <CommonGrid2
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        DEI
                    </Typography>
                    <Switch defaultChecked />
                </CommonGrid2>
            </CommonGrid2>
        </Grid2>
    );
}

export default AdditionalInformation;
