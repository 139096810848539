export default function UnLockIco() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 13C15.24 13 13 15.24 13 18C13 20.76 15.24 23 18 23C20.76 23 23 20.76 23 18C23 15.24 20.76 13 18 13ZM18 15C18.83 15 19.5 15.67 19.5 16.5C19.5 17.33 18.83 18 18 18C17.17 18 16.5 17.33 16.5 16.5C16.5 15.67 17.17 15 18 15ZM18 21C16.97 21 16.06 20.48 15.52 19.68C16.25 19.26 17.09 19 18 19C18.91 19 19.75 19.26 20.48 19.68C19.94 20.48 19.03 21 18 21Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 20V10H18V11C18.7 11 19.37 11.1 20 11.29V10C20 8.9 19.1 8 18 8H17H15H14H9V6C9 4.34 10.34 3 12 3C12.8589 3 13.6321 3.35872 14.1784 3.93488C14.1838 3.95656 14.1908 3.97828 14.1995 4L14.2179 4.06038C14.3944 4.28292 14.5709 4.56532 14.7475 4.92685C14.9215 5 15.153 5 15.4995 5C16.3279 5 17 5 16.5995 4C16.5904 3.99176 16.5815 3.98358 16.5727 3.97547C15.7951 2.22317 14.0395 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H12.26C11.84 21.4 11.51 20.72 11.29 20H6Z"
        fill="black"
      />
    </svg>
  );
}
