import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import XCloseIco from "../../assets/icons/XCloseIco";
import DeleteNoteBackgroundImg from "../../assets/images/delete-note-background.png";

function DeleteNote({
  deleteNoteDialogOpen,
  handleDeleteNoteDialogClose,
  noteList,
  handleDeleteNoteById,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={deleteNoteDialogOpen}
      onClose={handleDeleteNoteDialogClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "400px",
          maxWidth: "none",
          borderRadius: "12px",
          background: "#FFF",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        },
      }}
    >
      <img
        src={DeleteNoteBackgroundImg}
        style={{ position: "absolute", width: "max-content", opacity: "0.9" }}
      />
      <DialogActions>
        <Button
          onClick={handleDeleteNoteDialogClose}
          sx={{ marginTop: "10px" }}
        >
          <XCloseIco />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {"Delete Note"}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid2 spacing={2} container>
          {noteList?.map((item, index) => (
            <>
              <Grid2 size={12}>
                <Typography
                  color="#475467"
                  fontSize={16}
                  fontFamily={"Inter"}
                  fontWeight={500}
                  sx={{
                    fontStyle: "italic",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                  }}
                >
                  {item.note
                    ? item.note
                    : "The candidate is a very hard working, responsibility and has a sense of responsibility, alongside a set of skills"}
                </Typography>
              </Grid2>

              <Grid2 size={12}>
                <Typography
                  color="#667085"
                  fontSize={16}
                  fontFamily={"Inter"}
                  fontWeight={500}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {`Created by `}
                  <span style={{ fontWeight: 500, color: "#101828" }}>
                    {item.added_by || "Olivia Rhyes"}
                  </span>
                  {` on ${
                    item?.created_at
                      ? new Date(item.created_at).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })
                      : "Oct 12, 2024"
                  }`}
                </Typography>
              </Grid2>

              <Grid2 size={12}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    width: "100%",
                    padding: "10px 14px",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    border: "1px solid #D92D20",
                    backgroundColor: "#D92D20",
                    "&:hover": {
                      backgroundColor: "#D92D20",
                    },
                    boxShadow: "none",
                    fontWeight: "bold",
                  }}
                  onClick={() => handleDeleteNoteById(item)}
                >
                  {"Delete"}
                </Button>
              </Grid2>
            </>
          ))}

          <Grid2 size={12}>
            <Typography
              color="#667085"
              fontSize={16}
              fontFamily={"Inter"}
              fontWeight={500}
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {"This action cannot be undone."}
            </Typography>
          </Grid2>
        </Grid2>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteNote;
