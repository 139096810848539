export default function JobPencilIco() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7021 10C20.7021 15.5228 16.225 20 10.7021 20C5.1793 20 0.702148 15.5228 0.702148 10C0.702147 4.47715 5.1793 1.35705e-06 10.7021 8.74228e-07C16.225 3.91405e-07 20.7021 4.47715 20.7021 10Z"
        fill="#EAECEE"
      />
      <g clip-path="url(#clip0_759_39952)">
        <path
          d="M15.5851 8.27135L13.2294 10.8045L13.7476 11.3626L13.2669 11.8839L9.67027 8.0106L10.1544 7.49295L10.6725 8.05099L13.0248 5.51407C13.364 5.1474 13.825 4.94089 14.3057 4.94043C14.7864 4.93997 15.2475 5.14557 15.5871 5.51178C15.9272 5.87754 16.1181 6.37408 16.1176 6.89173C16.1172 7.40938 15.9254 7.90594 15.585 8.27121L15.5851 8.27135ZM11.2282 11.9206H8.0407L10.1168 9.68843L9.63613 9.1671L6.79977 12.2216C6.35361 12.7031 6.17292 13.4001 6.32249 14.061C6.27306 14.2234 6.21511 14.3827 6.14862 14.5382C5.93385 15.0669 5.74977 15.5221 6.01908 15.8158C6.11112 15.9118 6.23513 15.9636 6.36339 15.959C6.65657 15.9214 6.94124 15.8255 7.20203 15.6763C7.34776 15.6052 7.49691 15.5428 7.64862 15.4891C8.26226 15.652 8.91037 15.457 9.35655 14.9751L12.1929 11.9206L11.7088 11.4029L11.2282 11.9206Z"
          fill="#475E75"
        />
        <path
          d="M9.6152 9.53809L11.9229 11.8458L10.0767 13.6919L7.76904 11.8458L9.6152 9.53809Z"
          fill="#475E75"
          stroke="#475E75"
          stroke-width="0.461538"
        />
      </g>
    </svg>
  );
}
