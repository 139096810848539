import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { React } from "react";
import DropDownSelect from "../../component/Select";
import FilterBackGroundImage from "../../assets/images/FilterBackGround.png";
import CommonButton from "../../component/Button";

const FilterDialog = ({
  handleTitleChange,
  DropDownData,
  titleValue,
  handleClose,
  filterPopup,
}) => {
  return (
    <Dialog fullWidth maxWidth={"xs"} open={filterPopup} onClose={handleClose}>
      <img
        src={FilterBackGroundImage}
        style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
      />
      <DialogActions>
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          Filter
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid gap="20px" display="flex" flexDirection="column">
          <Grid>
            <Typography
              color="#344054"
              fontSize={14}
              fontFamily={"Inter"}
              fontWeight={500}
            >
              Title
            </Typography>
            <DropDownSelect
              title="Select title"
              handleChange={""}
              value={""}
              data={""}
            />
          </Grid>
          <Grid>
            <Typography
              color="#344054"
              fontSize={14}
              fontFamily={"Inter"}
              fontWeight={500}
            >
              Occupational classification
            </Typography>
            <DropDownSelect
              title="Select occupation classification"
              handleChange={""}
              value={""}
              data={""}
            />
          </Grid>
          <Grid>
            <Typography
              color="#344054"
              fontSize={14}
              fontFamily={"Inter"}
              fontWeight={500}
            >
              Location
            </Typography>
            <DropDownSelect
              title="Select location"
              handleChange={""}
              value={""}
              data={""}
            />
          </Grid>
          <Grid>
            <Typography
              color="#344054"
              fontSize={14}
              fontFamily={"Inter"}
              fontWeight={500}
            >
              Team
            </Typography>
            <DropDownSelect
              title="Select team"
              handleChange={""}
              value={""}
              data={""}
            />
          </Grid>
          <CommonButton
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Apply"
            padding="8px"
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FilterDialog;
