import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import ExperienceIcon from "../../../assets/images/experienceIcon.png";
import CommonButton from "../../../component/Button";
import CommonTextField from "../../../component/Input";
import {
  CommonGrid2,
  StyledDialog,
  StyledFlexColumnView,
} from "../../common-components/StyledComponents";
import DateRangePickerComp from "../../jobs/common-card.js/DatePicker";
import { ErrorContent } from "../../jobs/style";
import { CustomDatePicker } from "./style";

const ElcDialog = ({ open, onClose, title, handlELcSubmit, elcPopup }) => {
  const initialValues = {
    school: elcPopup?.name || "",
    Date_range: {
      start_date:
        elcPopup?.start_date === ""
          ? elcPopup?.end_date
          : elcPopup?.start_date || "",
      end_date:
        elcPopup?.end_date === ""
          ? elcPopup?.start_date
          : elcPopup?.end_date || "",
    },
    index: elcPopup?.index,
    date_of_issue: elcPopup?.date_of_issue,
    title: title,
  };

  const validationSchema = Yup.object({
    school: Yup.string().required(
      (title === "Add Licenses"
        ? "Name of licensing authority"
        : title === "Add Certification"
        ? "Name of certifying institution"
        : "School / University") + " is required"
    ),
    Date_range: Yup.object({
      start_date: Yup.date()
        .nullable()
        .typeError("Start date must be a valid date"),
      end_date: Yup.date()
        .nullable()
        .typeError("End date must be a valid date"),
    })
      .test(
        "at-least-one-date",
        "Date is required",
        (value) => !!(value?.start_date || value?.end_date)
      )
      .nullable()
      .when("title", {
        is: (value) =>
          value === "Add Licenses" || value === "Add Certification",
        then: () => Yup.object().nullable(),
        otherwise: (schema) => schema,
      }),

    date_of_issue: Yup.string().when("title", {
      is: (value) => value === "Add Licenses" || value === "Add Certification",
      then: (schema) =>
        schema
          .required("Date of issue is required")
          .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Invalid date format (DD/MM/YYYY)")
          .test("is-past-date", "Future dates are not allowed!", (value) =>
            dayjs(value, "DD/MM/YYYY").isBefore(dayjs().add(1, "day"))
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const handleSubmit = (values) => {
    handlELcSubmit(title, values);
  };

  return (
    <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <img
        src={ExperienceIcon}
        alt="Education"
        style={{ position: "absolute", padding: "16px", width: "90px" }}
      />
      <DialogActions>
        {/* <Button onClick={onClose}>
          <CloseIcon />
        </Button> */}
        <CommonButton
          onClick={onClose}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          color="#7F56D9"
          value={<CloseIcon />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" marginTop={"10px"}>
          {elcPopup.button === "Update"
            ? title.replace("Add", "Update")
            : title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            console.log(errors, "sfonfosfkn");
            return (
              <Form>
                <Grid2 container direction="column" spacing={3}>
                  <Grid2 size={12}>
                    <StyledFlexColumnView>
                      <Typography
                        fontWeight={500}
                        fontSize={"14px"}
                        color={"#344054"}
                      >
                        {title === "Add Licenses"
                          ? "Name of Licensing Authority"
                          : title === "Add Certification"
                          ? "Name of Certifying Institution"
                          : "School / University"}
                      </Typography>
                      <CommonTextField
                        placeholder={
                          title === "Add Licenses"
                            ? "Enter name of licensing authority"
                            : title === "Add Certification"
                            ? "Enter name of certifying institution"
                            : "Enter school / university"
                        }
                        padding="10px"
                        placeholderFontSize={"15px"}
                        error={errors.school && touched.school}
                        value={values.school}
                        onChange={(e) => {
                          setFieldValue("school", e.target.value);
                        }}
                        helperText={
                          Boolean(errors.school && touched.school) &&
                          errors.school
                        }
                      />
                    </StyledFlexColumnView>
                  </Grid2>

                  {title === "Add Licenses" || title === "Add Certification" ? (
                    <Grid2 size={12}>
                      <StyledFlexColumnView>
                        <Typography
                          fontWeight={500}
                          fontSize={"14px"}
                          color={"#344054"}
                        >
                          {"Date of Issue"}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <CustomDatePicker
                            onChange={(newValue) => {
                              const formattedDate = newValue
                                ? dayjs(newValue).format("DD/MM/YYYY")
                                : "";

                              setFieldValue("date_of_issue", formattedDate);
                            }}
                            value={
                              values?.["date_of_issue"]
                                ? dayjs(values["date_of_issue"], "DD/MM/YYYY")
                                : null
                            }
                            format={"DD/MM/YYYY"}
                            error={
                              touched["date_of_issue"] &&
                              errors["date_of_issue"]
                            }
                            maxDate={dayjs()}
                            views={["year", "month", "day"]}
                            slots={{
                              openPickerIcon: () => (
                                <CommonGrid2
                                  display={"flex"}
                                  fontSize={"15px"}
                                  gap={"5px"}
                                  alignItems={"center"}
                                  color={"#000"}
                                  fontWeight={600}
                                >
                                  <CalendarTodayIcon
                                    style={{ height: "20px" }}
                                  />
                                  <Typography
                                    fontWeight={600}
                                    fontSize={"13px"}
                                  >
                                    Select
                                  </Typography>
                                </CommonGrid2>
                              ),
                            }}
                          />
                        </LocalizationProvider>
                        {touched["date_of_issue"] &&
                          errors["date_of_issue"] && (
                            <ErrorContent
                              margin={"-5px 0px 0px"}
                              className="Mui-error"
                            >
                              {errors["date_of_issue"]}
                            </ErrorContent>
                          )}
                      </StyledFlexColumnView>
                    </Grid2>
                  ) : (
                    <Grid2 size={12}>
                      <StyledFlexColumnView>
                        <Typography
                          fontWeight={500}
                          fontSize={"14px"}
                          color={"#344054"}
                        >
                          {"Date"}
                        </Typography>
                        <DateRangePickerComp
                          startDateProp={values.Date_range.start_date}
                          endDateProp={values.Date_range.end_date}
                          handleChange={(start_date, end_date) => {
                            setFieldValue("Date_range", {
                              start_date: start_date,
                              end_date: end_date,
                            });
                          }}
                          error={
                            touched?.Date_range?.start_date &&
                            touched?.Date_range?.end_date &&
                            errors?.Date_range
                          }
                          helperText={
                            Boolean(
                              touched?.Date_range?.start_date &&
                                touched?.Date_range?.end_date
                            ) && errors?.Date_range
                          }
                        />
                      </StyledFlexColumnView>
                    </Grid2>
                  )}

                  <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    fontWeight="600"
                    value={elcPopup.button}
                    padding={"10px"}
                    type="submit"
                    loading={elcPopup.loading}
                    disabled={elcPopup.loading}
                  />
                </Grid2>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default ElcDialog;
