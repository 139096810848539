export default function ExclamatoryIco({ size }) {
  return (
    <svg
      width={size || "25"}
      height={size || "25"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="3.75" fill="#FEEFC6" />
      <path
        d="M12.8383 7.98473L12.0029 12.9981L11.1676 7.98473C11.1475 7.86351 11.1541 7.73938 11.1869 7.62096C11.2196 7.50254 11.2778 7.39266 11.3573 7.29897C11.4367 7.20527 11.5357 7.13 11.6472 7.07837C11.7587 7.02674 11.88 7 12.0029 7C12.1258 7 12.2472 7.02674 12.3587 7.07837C12.4702 7.13 12.5691 7.20527 12.6486 7.29897C12.7281 7.39266 12.7862 7.50254 12.8189 7.62096C12.8517 7.73938 12.8583 7.86351 12.8383 7.98473Z"
        stroke="#DC6803"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0026 17.3333C12.3708 17.3333 12.6693 17.0349 12.6693 16.6667C12.6693 16.2985 12.3708 16 12.0026 16C11.6344 16 11.3359 16.2985 11.3359 16.6667C11.3359 17.0349 11.6344 17.3333 12.0026 17.3333Z"
        fill="#DC6803"
        stroke="#DC6803"
        stroke-width="1.5"
      />
    </svg>
  );
}
