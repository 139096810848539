import { URL } from "../../api/Api";
import { QUICKMATCH } from "../Action";
import { catchError, GET } from "../axios/Axios";

export const UploadJob = async (formData, getName) => {
  try {
    const response = await fetch(
      getName === "NextUpload"
        ? `${URL}/intake/jobdata/`
        : `${URL}/matchengine/match/resume/jd`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const ReviewMatchInformation = (job_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/intake/match-resumes/${job_id}`, {});
      dispatch({ type: QUICKMATCH.reviewMatch, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};
