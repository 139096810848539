import { URL } from "../../api/Api";
import { CLINET_PARTNER } from "../Action";
import { catchError, DELETE, GET, POST, PUT } from "../axios/Axios";

export const CompanyType = async () => {
  try {
    const response = await GET(`${URL}/intake/profiles/list/`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const ClientAddCall = (payload) => {
  return async (dispatch) => {
    try {
      const response = await POST(`${URL}/customers/client/`, payload, {});
      dispatch({ type: CLINET_PARTNER.clientAddCall, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const ClientUpdateCall = async (id, payload) => {
  try {
    const response = await PUT(`${URL}/customers/client/${id}`, payload);
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const ClientGetCall = async (id) => {
  try {
    const response = await GET(`${URL}/customers/client/${id}`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const ClientTableGetCall = async () => {
  try {
    const response = await GET(`${URL}/customers/client/`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const ClientDelete = async (id) => {
  try {
    const response = await DELETE(`${URL}/customers/client/${id}`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
