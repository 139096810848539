import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid2,
    Stack,
    Typography
} from "@mui/material";
import React from "react";
import AvatarIco from "../../../../assets/images/avatar.png";
import {
    StyledFlexRowView
} from "../../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../../portal/quick-match/components/StyledComponent";

function Profile() {

    return (
        <Grid2 container spacing={4}>
            <Grid2 size={12}>
                <Divider />
            </Grid2>

            <Grid2 size={12}>
                <StyledSpaceBetweenBox>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar
                            alt="Zahir Mays"
                            src={AvatarIco}
                            sx={{
                                width: 64,
                                height: 64,
                                backgroundColor: "#F2F4F7",
                                color: "#667085",
                                // border: "1px solid #D0D5DD",
                                borderRadius: "unset",
                                borderTopLeftRadius: "30px",
                                borderTopRightRadius: "30px",
                                borderBottomLeftRadius: "30px"
                            }}
                        />
                        <Box>
                            <StyledFlexRowView>
                                <Typography
                                    sx={{
                                        fontFamily: "inter",
                                        fontSize: "24px",
                                        fontWeight: 600,
                                    }}
                                >
                                    Zahir Mays
                                </Typography>

                            </StyledFlexRowView>

                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#475467",
                                        fontFamily: "Inter",
                                    }}
                                >
                                    Product Designer · Req ID: 100000
                                </Typography>

                            </Stack>
                        </Box>
                    </Stack>

                    <StyledFlexRowView>

                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                padding: "10px 16px",
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor: "#7F56D9",
                                },
                                fontWeight: "bold",
                                fontSize: "14px"
                            }}
                        >
                            {"Submit Changes"}
                        </Button>

                    </StyledFlexRowView>
                </StyledSpaceBetweenBox>
            </Grid2>
            <Grid2 size={12}>
                <Divider />
            </Grid2>
        </Grid2>
    );
}

export default Profile;
