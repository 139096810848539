import { Grid2 } from "@mui/material";
import React from "react";
import CommonAccordion from "../../../common-components/CommonAccordion";
import Experience from "../../../jobs/jobs-candidate/components/Experience";
import Profile from "../../../jobs/jobs-candidate/components/Profile";
import Education from "../../../jobs/jobs-summary/components/Education";
import ExecutiveBio from "./ExecutiveBio";
import ResumeScores from "./ResumeScores";
import Skill from "./Skill";

function ReviewCandidateProfile({
  resumeData,
  isLoading,
  handleScoreFix,
  fixLoading,
}) {
  const resume = [
    ...(resumeData?.elc_table?.certifications || []),
    ...(resumeData?.elc_table?.qualifications || []),
    ...(resumeData?.elc_table?.licenses || []),
    ...(resumeData?.elc_table?.research_paper || []),
  ];
  return (
    <Grid2 container spacing={4}>
      <Grid2 size={12}>
        <Profile
          isReviewCandidateProfile
          matchedData={resumeData?.personal_details}
          filteredResumeTab={[]}
          loading={isLoading}
        />
      </Grid2>

      <Grid2 size={12}>
        {/* <CommonAccordion
          title={"Potential Roles"}
          children={<PotentialRoles />}
        /> */}
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Resume Scores"}
          children={
            <ResumeScores
              resumeData={resumeData?.resume_score || []}
              data={resumeData}
              isLoading={isLoading}
              handleScoreFix={handleScoreFix}
              fixLoading={fixLoading}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion title={"Executive Bio"} children={<ExecutiveBio />} />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={" Skills"}
          children={
            <Skill
              skillData={resumeData?.all_skills || []}
              isLoading={isLoading}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Experience"}
          children={
            <Experience
              matchedData={resumeData?.job_history || []}
              isLoading={isLoading}
            />
          }
        />
      </Grid2>

      <Grid2 size={12}>
        <CommonAccordion
          title={"Education & Certifications"}
          children={<Education educationData={resume} isLoading={isLoading} />}
        />
      </Grid2>
    </Grid2>
  );
}

export default ReviewCandidateProfile;
