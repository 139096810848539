import CloseIcon from "@mui/icons-material/Close";
import {
    Avatar,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    Typography
} from "@mui/material";
import React from "react";
import ManagePermissionsicon from "../../../assets/images/managePermissionsicon.png";
import { DotColor, StatusChange, StyledDialog } from "../../common-components/StyledComponents";

const ManagePermissions = ({
    open,
    onClose,
    onApply,
    selectedUsers
}) => {

    return (
        <>
            <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
                <img
                    src={ManagePermissionsicon}
                    style={{ position: "absolute", padding: "16px", width: "80px" }}
                />
                <DialogActions>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </DialogActions>
                <DialogTitle>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                        {"Manage Permissions"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid2 container direction="column" spacing={3} fontFamily={"Inter"}>
                        <Grid2 item xs={12}>
                            {selectedUsers.length > 0 ? (
                                selectedUsers.map((user, index) => (
                                    <Grid2
                                        key={user.id}
                                        container
                                        alignItems="center"
                                        spacing={2}
                                        sx={{ marginBottom: "16px" }}
                                    >
                                        <Grid2 item>
                                            <Avatar alt={user.name} src={user.avatarUrl} />
                                        </Grid2>
                                        <Grid2 item xs>
                                            <Typography variant="body1">
                                                {user.name}
                                            </Typography>
                                        </Grid2>
                                        <Grid2 item sx={{ marginLeft: 'auto' }}>
                                            <StatusChange>
                                                <DotColor
                                                    color={
                                                        user.permissions === "Base"
                                                            ? "#667085"
                                                            : user.permissions === "Manager"
                                                                ? "orange"
                                                                : user.permissions === "Admin"
                                                                    ? "green"
                                                                    : "#667085"
                                                    }
                                                />
                                                {user.permissions}
                                            </StatusChange>
                                        </Grid2>
                                    </Grid2>
                                ))
                            ) : (
                                <Typography>No user selected.</Typography>
                            )}
                        </Grid2>
                        <Button
                            variant="contained"
                            fontWeight="600"
                            onClick={onApply}
                        >
                            {"Save Changes"}
                        </Button>

                    </Grid2>
                </DialogContent>
            </StyledDialog >
        </>
    );
};

export default ManagePermissions;
