import { Box, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import BoxEditIco from "../../../../assets/icons/BoxEditIco";
import DeleteIco from "../../../../assets/icons/DeleteIco";
import {
    StyledFlexColumnView,
} from "../../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../../portal/quick-match/components/StyledComponent";
import AddEducation from "./AddEducation";

const initialFeedback = [
    {
        id: 1,
        message:
            "Zahir is a great fit for this position and should be highly considered.",
        title: "Adobe Certified Professional in Visual Design",
        subTitle: "Oct 2022",
    },
    {
        id: 2,
        message: "He's a Figma wizard, Dumbledore would be proud!",
        title: "Google UX Design Certificate",
        subTitle: "Oct 2020",
    },
];

function FeedbackCard({ item, handleDeleteNotes, onEdit }) {
    return (
        <Paper
            variant="outlined"
            sx={{
                display: "flex",
                alignItems: "center",
                padding: "12px",
                justifyContent: "space-between",
                mb: 1,
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                borderRadius: "12px",
                border: "1px solid #EAECF0 !important",
            }}
        >
            <StyledSpaceBetweenBox
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <div>
                    <Stack direction="row" alignItems="center" spacing={2}>

                        <StyledFlexColumnView sx={{ gap: "4px" }}>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    color: "#101828",
                                }}
                            >
                                {item?.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    color: "#475467",
                                }}
                            >
                                {item?.subTitle}
                            </Typography>
                        </StyledFlexColumnView>
                    </Stack>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <div
                        style={{
                            cursor: "pointer",
                            border: "1px solid #D0D5DD",
                            borderRadius: "6px",
                            color: "#344054",
                            padding: "11px",
                            display: "flex",
                        }}
                        onClick={() => onEdit(item)}
                    >
                        <BoxEditIco />
                    </div>
                    <div
                        style={{
                            cursor: "pointer",
                            border: "1px solid #D0D5DD",
                            borderRadius: "6px",
                            color: "#344054",
                            padding: "11px",
                            display: "flex",
                        }}
                        onClick={() => handleDeleteNotes(item.id)}
                    >
                        <DeleteIco />
                    </div>
                </div>
            </StyledSpaceBetweenBox>
        </Paper>
    );
}

function Certifications() {
    const [feedback, setFeedback] = useState(initialFeedback);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedExperience, setSelectedExperience] = useState(null);

    const handleEditExperience = (education) => {
        setSelectedExperience(education);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedExperience(null);
    };

    const handleApply = (updatedExperience) => {
        setFeedback((prevFeedback) =>
            prevFeedback.map((item) =>
                item.id === updatedExperience.id ? updatedExperience : item
            )
        );
        handleCloseDialog();
    };

    const handleDeleteNotes = (id) => {
        setFeedback((prevFeedback) =>
            prevFeedback.filter((item) => item.id !== id)
        );
    };

    return (
        <Box>
            {feedback.map((item) => (
                <FeedbackCard
                    key={item.id}
                    icon={item.icon}
                    item={item}
                    handleDeleteNotes={handleDeleteNotes}
                    onEdit={handleEditExperience}
                />
            ))}
            {openDialog && (
                <AddEducation
                    open={openDialog}
                    onClose={handleCloseDialog}
                    onApply={handleApply}
                    experience={selectedExperience}
                    title={"Certifications"}
                />
            )}
        </Box>
    );
}

export default Certifications;
