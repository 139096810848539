import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ClientsPartnersIco from "../../../assets/icons/ClientsPartnersIco";
import HelixIco from "../../../assets/icons/HelixIco";
import HorizonIco from "../../../assets/icons/HorizonIco";
import { default as HyperQuickMatchIco } from "../../../assets/icons/HyperQuickMatchIco";
import IceCandidateHubIco from "../../../assets/icons/IceCandidateHubIco";
import JobEnrichmentIco from "../../../assets/icons/JobEnrichmentIco";
import JunctionSettingsIco from "../../../assets/icons/JunctionSettingsIco";
import TalairoLogo from "../../../assets/icons/TalairoLogo";
import ToolIco from "../../../assets/icons/ToolIco";
import UserManagementIco from "../../../assets/icons/UserManagementIco";
import UsersIco from "../../../assets/icons/UsersIco";
import LogoMark from "../../../assets/images/talairo.png";
import { CommonGrid2 } from "../../../modules/common-components/StyledComponents";
import { CommonTippy, EllipseData } from "../../../modules/jobs/style";
import JudicaIco from "../../../assets/icons/JudicaIco";
import AgencyInformation from "../../../assets/icons/AgencyInformation";

const Sidebar = ({ open, setOpen, handleNavigation }) => {
  const theme = useTheme();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [expandedItems, setExpandedItems] = useState({});
  const location = useLocation();
  const storageData = JSON.parse(localStorage.getItem("credential"));
  const updatedVersion = process.env.REACT_APP_URL_VERSION;
  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const SidebarItems = [
    {
      name: "Horizon",
      path: "/horizon",
      icon: <HorizonIco />,
    },

    ...(!open
      ? [
          {
            name: "JEM - Job Enrichment",
            path: "/create-jobs",
            icon: <JobEnrichmentIco />,
          },
          {
            name: "ICE + Candidate Hub",
            path: "/resume-intelligence/create-candidate-resume",
            icon: <IceCandidateHubIco />,
          },
          {
            name: "Judica - Scoring",
            path: "/jobs",
            icon: <JudicaIco />,
          },
          {
            name: "Candidate Hub",
            path: "/candidates",
            icon: <UsersIco />,
          },
          {
            name: "Hyper Quick Match",
            path: "/tools/quick-match",
            icon: <HyperQuickMatchIco />,
          },
          ...(storageData?.role === "Admin"
            ? [
                {
                  name: "Agency Information",
                  path: "/agency-information",
                  icon: <AgencyInformation />,
                },
                {
                  name: "Clients & Vendors",
                  path: "/client-vendor",
                  icon: <ClientsPartnersIco />,
                },
                {
                  name: "Weights",
                  path: "/weight",
                  icon: <ClientsPartnersIco />,
                },
                {
                  name: "User Management",
                  path: "/user-management",
                  icon: <UserManagementIco />,
                },
              ]
            : []),
        ]
      : [
          {
            name: "Helix",
            icon: <HelixIco />,
            children: [
              {
                name: "JEM - Job Enrichment",
                path: "/create-jobs",
                icon: <JobEnrichmentIco />,
              },
              {
                name: "ICE - Profile Creation",
                path: "/resume-intelligence/create-candidate-resume",
                icon: <IceCandidateHubIco />,
              },
              {
                name: "Judica - Scoring",
                path: "/jobs",
                icon: <JudicaIco />,
              },
              {
                name: "Candidate Hub",
                path: "/candidates",
                icon: <UsersIco />,
              },
            ],
          },
          {
            name: "Tools",
            icon: <ToolIco />,
            children: [
              {
                name: "Hyper Quick Match",
                path: "/tools/quick-match",
                icon: <HyperQuickMatchIco />,
              },
            ],
          },
          ...(storageData?.role === "Admin"
            ? [
                {
                  name: "Junction - Settings",
                  icon: <JunctionSettingsIco />,
                  children: [
                    {
                      name: "Agency Information",
                      path: "/agency-information",
                      icon: <AgencyInformation />,
                    },
                    {
                      name: "Clients & Vendors",
                      path: "/client-vendor",
                      icon: <ClientsPartnersIco />,
                    },
                    {
                      name: "Weights",
                      path: "/weight",
                      icon: <ClientsPartnersIco />,
                    },
                    {
                      name: "User Management",
                      path: "/user-management",
                      icon: <UserManagementIco />,
                    },
                  ],
                },
              ]
            : []),
        ]),
  ];

  const handleExpandToggle = (index) => {
    // setExpandedItems((prev) => ({
    //   ...prev,
    //   [index]: !prev[index],
    // }));
    setExpandedItems((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      return { ...newState, [index]: !prev[index] };
    });
  };

  useEffect(() => {
    const setExpandedFromPath = (items) => {
      const state = {};
      const expand = (items) => {
        for (const [index, item] of items.entries()) {
          if (item.children) {
            const isChildMatch = item.children.some((child) =>
              location.pathname.includes(child.path)
            );
            state[index] = isChildMatch;
            if (isChildMatch) expand(item.children);
          }
        }
      };
      expand(items);
      return state;
    };
    const newExpanded = setExpandedFromPath(SidebarItems);
    setExpandedItems(newExpanded);
  }, [open === true]);

  return (
    <Drawer
      variant={open && !lgUp ? "temporary" : "permanent"}
      open={open}
      anchor="left"
      onClose={toggleDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: open ? 312 : theme.spacing(8),
          transition: theme.transitions.create("width", {
            duration: theme.transitions.duration.standard,
          }),
          overflowX: "hidden",
        },
        position: "relative",
      }}
    >
      <CommonGrid2
        container
        direction="column"
        height={"100vh"}
        flexWrap={"nowrap"}
        padding={"16px"}
        margin={0}
      >
        <CommonGrid2
          item
          marginBottom={"10px"}
          marginTop={"10px"}
          marginLeft={"5px"}
          sx={{ cursor: "pointer" }}
          onClick={() => handleNavigation("/auth/login")}
        >
          {open && <TalairoLogo height="40px" marginLeft="-3.7rem" />}
          {!open && (
            <img src={LogoMark} width={"32px"} height={"32px"} alt="logo" />
          )}
        </CommonGrid2>

        <CommonGrid2
          item
          flexGrow={1}
          overflowY={"auto"}
          width={open ? "100%" : "fit-content"}
          scrollStyle
          marginBottom={"12px"}
        >
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
          >
            {SidebarItems.map((item, index) => (
              <React.Fragment key={item.name}>
                <ListItemButton
                  onClick={() =>
                    item.children
                      ? handleExpandToggle(index)
                      : handleNavigation(item.path)
                  }
                  selected={location.pathname.includes(item.path)}
                  sx={{
                    width: open ? "100%" : "max-content",
                    padding: "8px 10px",
                    background: location.pathname.includes(item.path)
                      ? "rgb(230 230 230)"
                      : "",
                    borderRadius: "10px",
                    boxShadow:
                      location.pathname.includes(item.path) &&
                      "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  }}
                >
                  <CommonTippy
                    content={item.name}
                    placement="right"
                    disabled={open}
                    color="#7F56D9"
                    fontWeight={600}
                    fontSize={"13px"}
                  >
                    <ListItemIcon
                      sx={{
                        justifyContent: "flex-start",
                        minWidth: "40px",
                      }}
                    >
                      {React.cloneElement(item.icon, {
                        color: location.pathname.includes(item.path)
                          ? "#7F56D9"
                          : "#667085",
                      })}
                    </ListItemIcon>
                  </CommonTippy>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        fontWeight={
                          location.pathname.includes(item.path) ? 700 : 600
                        }
                        color={
                          location.pathname.includes(item.path)
                            ? "#7F56D9"
                            : "#344054"
                        }
                        fontSize={"16px"}
                      >
                        {item.name}
                      </Typography>
                    }
                  />
                  {item.children &&
                    (expandedItems[index] ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                {item.children && (
                  <Collapse
                    in={expandedItems[index]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      sx={{ marginLeft: open ? "2rem" : "3rem" }}
                    >
                      {item.children.map((child) => (
                        <ListItemButton
                          key={child.name}
                          onClick={() => handleNavigation(child.path)}
                          sx={{
                            background: location.pathname.includes(child.path)
                              ? "rgb(230 230 230)"
                              : "",
                            borderRadius: "10px",
                            padding: "8px 16px",
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: "40px" }}>
                            {React.cloneElement(child.icon, {
                              color: location.pathname.includes(child.path)
                                ? "#7F56D9"
                                : "#667085",
                            })}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                fontSize={"16px"}
                                variant="body1"
                                fontWeight={
                                  location.pathname.includes(child.path)
                                    ? 700
                                    : 600
                                }
                                color={
                                  location.pathname.includes(child.path)
                                    ? "#7F56D9"
                                    : "#344054"
                                }
                              >
                                {child.name}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </CommonGrid2>

        {/* Footer Section */}
        {open && (
          <Grid item>
            <Box
              sx={{
                backgroundColor: "#F9FAFB",
                border: "1px solid #E5E7EB",
                borderRadius: "8px",
                padding: 2,
                mb: 2,
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body2"
                color="#7F56D9"
                fontWeight={"700"}
                // fontSize={"13px"}
                marginBottom={"-8px"}
              >
                Version {updatedVersion}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                New features available!
              </Typography>
              <Typography
                sx={{ color: "#475467", fontSize: "14px", fontWeight: 400 }}
              >
                Check out the new dashboard view. Pages now load faster!
              </Typography>
              <CommonGrid2 display={"flex"} flexDirection={"row"} gap={"10px"}>
                <Button
                  variant="text"
                  sx={{
                    fontSize: "14px",
                    textTransform: "none",
                    color: "#475467",
                    fontWeight: 600,
                    padding: "0px",
                  }}
                >
                  Dismiss
                </Button>
                <Button
                  variant="text"
                  sx={{
                    fontSize: "14px",
                    textTransform: "none",
                    color: "#6941C6",
                    fontWeight: 600,
                    padding: "0px",
                  }}
                >
                  What’s new?
                </Button>
              </CommonGrid2>
            </Box>
          </Grid>
        )}

        <CommonGrid2 item>
          <Divider />
          <CommonGrid2
            display={"flex"}
            flexDirection={"row"}
            gap={"10px"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            marginTop={"24px"}
            marginBottom={"20px"}
            minWidth={"fit-content"}
          >
            <CommonGrid2
              display={"flex"}
              flexDirection={"row"}
              gap={"10px"}
              alignItems={"center"}
            >
              <Avatar
                alt="Olivia Rhye"
                src={
                  storageData?.username
                    ? storageData?.username?.charAt(0)?.toUpperCase()
                    : "https://mui.com/static/images/avatar/1.jpg"
                }
                sx={{ width: 40, height: 40, fontWeight: "700" }}
              >
                {storageData?.username?.charAt(0)?.toUpperCase()}
              </Avatar>
              <CommonGrid2 item>
                <Typography
                  variant="body1"
                  fontWeight={"bold"}
                  lineHeight={"normal"}
                >
                  <EllipseData width={"170px"}>
                    <Tooltip
                      title={storageData?.username}
                      arrow
                      placement="top"
                    >
                      {storageData?.username}
                    </Tooltip>
                  </EllipseData>
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  lineHeight={"normal"}
                >
                  <EllipseData width={"170px"}>
                    <Tooltip title={storageData?.email} arrow placement="top">
                      {storageData?.email}
                    </Tooltip>
                  </EllipseData>
                </Typography>
              </CommonGrid2>
            </CommonGrid2>

            <IconButton
              onClick={() => handleNavigation("/auth/login", "logout")}
            >
              <LogoutRoundedIcon />
            </IconButton>
          </CommonGrid2>
        </CommonGrid2>
      </CommonGrid2>
    </Drawer>
  );
};

export default Sidebar;
