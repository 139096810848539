import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import FilterBackGroundImage from "../../assets/images/FilterBackGround.png";
import CommonButton from "../../component/Button";
import DateRangePickerComp from "../jobs/common-card.js/DatePicker";
import { StyledDialog, StyledFlexRowView } from "./StyledComponents";
import XCloseIco from "../../assets/icons/XCloseIco";

const FilterDialog = ({
  open,
  onClose,
  title = "Filter",
  filterOptions = [],
  count,
  filterState,
  handleClearForm,
  handleDropdownChange,
  handleDateChange,
  handleApplyFilters,
  selectedCount,
  buttonLoading,
}) => {
  return (
    <StyledDialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <img
        src={FilterBackGroundImage}
        style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
        alt="Filter Background"
      />
      <DialogActions>
        {/* <Button onClick={onClose}>
          <CloseIcon />
        </Button> */}
        <CommonButton
          onClick={onClose}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          value={<XCloseIco />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {title} {selectedCount > 0 && `(${selectedCount})`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid2 container direction="column" gap={2}>
          {filterOptions.map((option, index) => (
            <Grid2 item key={index}>
              <Typography
                color="#344054"
                fontSize={14}
                fontFamily="Inter"
                fontWeight={500}
                sx={{ mb: 1 }}
              >
                {option.label}
                {option.type === "select" &&
                  filterState[option.key] &&
                  `:( ${
                    filterState[option.key]?.label || filterState[option.key]
                  })`}
                {option.type === "multi-select" &&
                  filterState[option.key]?.length > 0 &&
                  `: (${filterState[option.key].length} ${
                    filterState[option.key].length > 1 ? "" : ""
                  })`}
              </Typography>
              {option.type === "select" && (
                <Autocomplete
                  options={option.options || []}
                  getOptionLabel={(option) => option.label || option}
                  value={filterState[option.key] || null}
                  onChange={(_, value) =>
                    handleDropdownChange(option.key, value)
                  }
                  renderInput={(params) => (
                    <TextField {...params} key={option.key} />
                  )}
                  style={{
                    "& label.MuiInputLabel-sizeMedium": {
                      top: "-6px !important",
                    },
                    "& label.MuiInputLabel-shrink": {
                      top: "0px !important",
                    },
                  }}
                />
              )}
              {option.type === "multi-select" && (
                <Autocomplete
                  multiple
                  options={option.options || []}
                  getOptionLabel={(option) => option.key || option}
                  value={filterState[option.key] || null}
                  onChange={(_, value) =>
                    handleDropdownChange(option.key, value)
                  }
                  limitTags={2}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.label || option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={option.label}
                      placeholder="Select options"
                    />
                  )}
                  sx={{
                    "& label.MuiInputLabel-sizeMedium": {
                      top: "-5px !important",
                    },
                    "& label.MuiInputLabel-shrink": {
                      top: "0px !important",
                    },
                    "& .MuiInputBase-formControl": {
                      padding: "5px 39px 5px 9px",
                    },
                    "& fieldset": {
                      border: "1px solid #D0D5DD",
                    },
                    "& .MuiButtonBase-root": {
                      borderRadius: "5px",
                      gap: "5px",
                    },
                  }}
                />
              )}
              {option.type === "date" && (
                <DateRangePickerComp
                  padding={"12px"}
                  startDateProp={filterState[option.key]?.from_date}
                  endDateProp={filterState[option.key]?.to_date}
                  handleChange={(start, end) =>
                    handleDateChange(option.key, start, end)
                  }
                />
              )}
            </Grid2>
          ))}
          <StyledFlexRowView style={{ justifyContent: "flex-end" }}>
            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              value="Clear"
              padding={"10px 16px"}
              onClick={handleClearForm}
              disabled={selectedCount === 0 || buttonLoading.disabled}
            />
            <CommonButton
              variant="contained"
              color="white"
              background="#7F56D9"
              fontWeight="600"
              value={`Apply${count > 0 ? ` (${count})` : ""}`}
              padding="8px"
              onClick={handleApplyFilters}
              disabled={selectedCount === 0 || buttonLoading.disabled}
              loading={buttonLoading.loading}
            />
          </StyledFlexRowView>
        </Grid2>
      </DialogContent>
    </StyledDialog>
  );
};

export default FilterDialog;
