import { MenuItem, Switch, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { AutocompleteText } from "../../../component/AutoComplete";
import CommonTextField from "../../../component/Input";
import DropDownSelect from "../../../component/Select";
import { CustomDiv } from "../../chat-bot/style";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import { ErrorContent } from "../../jobs/style";
import { HandleSkeletion } from "../../../utils/constants";
import CommonPhoneInput from "../../../component/PhoneInput";

const MixedComponent = ({ editData, loading, rowSize = false, marginTop }) => {
  const { values, errors, setFieldValue, handleBlur } = useFormikContext();

  const [localValue, setLocalValue] = React.useState({
    client_name: values.client_name || "",
    address: values.address || "",
    email: values.email || "",
    countries_operating_value: values.countries_operating_value || "",
    hub_cities_value: values.hub_cities_value || "",
    vms: values.vms || "",
    contact_number: values.contact_number || "",
    business_display_name: values.business_display_name || "",
    website: values.website || "",
    sourcing_contact: values.sourcing_contact || "",
    federal_id: values.federal_id || "",
    client_id: values.client_id || "",
    primary_business_unit: values.primary_business_unit || "",

    representative_name: values.representative_name || "",
    job_title: values.job_title || "",
    phone_number: values.phone_number || "",
    email_address: values.email_address || "",

    hiring_manager_name: values.hiring_manager_name || "",
    hiring_job_title: values.hiring_job_title || "",
    hiring_phone_number: values.hiring_phone_number || "",
    hiring_email_address: values.hiring_email_address || "",

    finance_team_name: values.finance_team_name || "",
    account_job_title: values.account_job_title || "",
    account_phone_number: values.account_phone_number || "",
    account_email_address: values.account_email_address || "",

    //vendor
    business_name: values.business_name || "",
    subcontractor_id: values.subcontractor_id || "",
    number_of_employee: values.number_of_employee || "",
  });

  useEffect(() => {
    setLocalValue({
      client_name: values.client_name || "",
      address: values.address || "",
      email: values.email || "",
      countries_operating_value: values.countries_operating_value || "",
      hub_cities_value: values.hub_cities_value || "",
      vms: values.vms || "",
      contact_number: values.contact_number || "",
      business_display_name: values.business_display_name || "",
      website: values.website || "",
      sourcing_contact: values.sourcing_contact || "",
      federal_id: values.federal_id || "",
      client_id: values.client_id || "",
      primary_business_unit: values.primary_business_unit || "",

      representative_name: values.representative_name || "",
      job_title: values.job_title || "",
      phone_number: values.phone_number || "",
      email_address: values.email_address || "",

      hiring_manager_name: values.hiring_manager_name || "",
      hiring_job_title: values.hiring_job_title || "",
      hiring_phone_number: values.hiring_phone_number || "",
      hiring_email_address: values.hiring_email_address || "",

      finance_team_name: values.finance_team_name || "",
      account_job_title: values.account_job_title || "",
      account_phone_number: values.account_phone_number || "",
      account_email_address: values.account_email_address || "",

      //vendor
      business_name: values.business_name || "",
      subcontractor_id: values.subcontractor_id || "",
      number_of_employee: values.number_of_employee || "",
    });
  }, [values]);

  const debounceTimer = React.useRef(null);

  const handleInputChange = (e, item) => {
    const getValue = e.target.value;
    setLocalValue((prev) => ({
      ...prev,
      [item.titleName]: e.target.value,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setFieldValue(item.titleName, getValue);
    }, 300);
  };

  const handleNumberChange = (e, countryData, item) => {
    const getValue = e;
    setLocalValue((prev) => ({
      ...prev,
      [item.titleName]: e,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setFieldValue(item.country, countryData);
      setFieldValue(item.titleName, getValue);
    }, 300);
  };

  const CustomMenuItem = (item, index) => (
    <MenuItem value={item?.id} key={index}>
      {item?.name}
    </MenuItem>
  );
  return (
    <CommonGrid2
      container
      columnSpacing={3}
      rowSpacing={loading ? 0 : 3}
      marginTop={marginTop}
    >
      {editData?.map((item, index) => {
        return (
          <CommonGrid2
            item
            size={{
              xs: 12,
              md: item.type === "additional" || rowSize ? 6 : 4,
              sm: 6,
            }}
            key={index + item.title}
            gap={"5px"}
            display={"flex"}
            flexDirection={item.component === "switch" ? "row" : "column"}
            justifyContent={"flex-start"}
          >
            {loading ? (
              <HandleSkeletion height={100} />
            ) : (
              <>
                <CommonGrid2
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <CommonGrid2
                    display={"flex"}
                    alignItems={"center"}
                    width={"100%"}
                    gap={"10px"}
                  >
                    <Typography variant="p" color="#344054" fontSize="16px">
                      {item.title}
                    </Typography>

                    {item?.iconVisible && item?.content}
                  </CommonGrid2>
                  {item.type === "additional" && (
                    <Switch
                      checked={values[item.toggleName] || false}
                      onChange={(event) => {
                        item.handleChangeSwitch(
                          item.toggleName,
                          event.target.checked
                        );
                      }}
                    />
                  )}
                </CommonGrid2>
                {item.component === "input" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          placeholder={item?.placeholder || item.title}
                          padding="12px"
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={localValue[item.titleName]}
                          onChange={(e) => {
                            handleInputChange(e, item);
                          }}
                          disabled={item?.disabled}
                          placeholderFontSize={item?.placeholderFontSize}
                          startIcon={item.startIcon}
                        />
                      );
                    }}
                  </Field>
                )}

                {item.component === "dropDown" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleDropdownChange = (event) => {
                        form.setFieldValue(item.titleName, event.target.value);
                      };

                      return (
                        <DropDownSelect
                          marginTop="0px"
                          title={item.placeholder || item.title}
                          removeAnimation={true}
                          data={values[item.dropDownData]}
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          id={item.titleName}
                          value={values[item.titleName]}
                          handleChange={handleDropdownChange}
                          disabled={item?.disabled}
                          valueChange={item.valueChange}
                          CustomMenuItem={CustomMenuItem}
                          sizeMedium={"-5px"}
                          padding={"12px"}
                        />
                      );
                    }}
                  </Field>
                )}

                {item.component === "autoComplete" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleAutoCompleteChange = (e, newValue) => {
                        if (e?.key === "Backspace" && e.target.value === "") {
                          const updatedValue = [
                            ...(form.values[item.titleName] || []),
                          ];
                          updatedValue.pop();
                          form.setFieldValue(item.titleName, updatedValue);
                        } else {
                          const formattedValues = newValue.map(
                            (item, index) => {
                              if (typeof item === "string") {
                                return {
                                  content: item,
                                };
                              }
                              return item;
                            }
                          );

                          form.setFieldValue(item.titleName, formattedValues);
                        }
                      };

                      const handleDelete = (option) => {
                        const updatedValue = form.values[item.titleName].filter(
                          (item, i) => item !== option
                        );
                        form.setFieldValue(item.titleName, updatedValue);
                      };

                      const isError =
                        form.touched[item.titleName] &&
                        form.errors[item.titleName];
                      const helperText = isError
                        ? form.errors[item.titleName]
                        : "";

                      const handleKeyDown = (e) => {
                        const checkData = form?.values[item.titleName];
                        const dataValue = e.target.value;
                        // form.setFieldValue(item.inputValue, dataValue);
                        if (e.key === "Tab") {
                          e.preventDefault();
                          setLocalValue((prev) => ({
                            ...prev,
                            [item.inputValue]: "",
                          }));

                          if (dataValue !== "") {
                            const data = {
                              content: dataValue,
                            };

                            const formattedValues = [...checkData, data];
                            form.setFieldValue(item.titleName, formattedValues);
                            form.setFieldValue(item.inputValue, "");
                          }
                        }
                      };

                      return (
                        <CustomDiv>
                          <AutocompleteText
                            autoData={{
                              options: [],
                              key: `autocomplete-${index}`,
                              title: "Select Emails",
                              placeholder: item.placeholder,
                              isDisabled: false,
                              value: values[item.titleName] || "",
                              onDelete: handleDelete,
                              onKeyDown: handleKeyDown,
                              onChange: handleAutoCompleteChange,
                              inputValue: localValue[item.inputValue],
                              onInputChange: (e, newValue) => {
                                setLocalValue((prev) => ({
                                  ...prev,
                                  [item.inputValue]: newValue,
                                }));
                              },
                              isDisabled: item?.disabled,
                              customClass: item.titleName,
                            }}
                            error={isError}
                          />
                          {isError && (
                            <ErrorContent margin="2px 0px 0px">
                              {helperText}
                            </ErrorContent>
                          )}
                        </CustomDiv>
                      );
                    }}
                  </Field>
                )}

                {item.component === "fileUpload" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          readOnly={true}
                          handleFileChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                              setFieldValue(item.titleName, file.name);
                            }
                          }}
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          placeholder={item.placeholder}
                          placeholderFontSize={item?.placeholderFontSize}
                          value={values[item.titleName]}
                          padding="12px"
                          disabled={item?.disabled}
                        />
                      );
                    }}
                  </Field>
                )}

                {item.component === "phone-number" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const isError = Boolean(
                        form.touched[item.titleName] &&
                          form.errors[item.titleName]
                      );
                      return (
                        <>
                          <CommonPhoneInput
                            placeholder={item?.placeholder}
                            padding="14px 0px 14px 60px"
                            country="us"
                            value={localValue[item.titleName]}
                            onChange={(e, countryData) => {
                              handleNumberChange(e, countryData, item);
                            }}
                          />
                          {isError && (
                            <ErrorContent
                              margin="-2px 0px 0px"
                              className="Mui-error"
                            >
                              {form.errors[item.titleName]}
                            </ErrorContent>
                          )}
                        </>
                      );
                    }}
                  </Field>
                )}
              </>
            )}
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
};
export default MixedComponent;
