import CloseIcon from "@mui/icons-material/Close";
import {
    Avatar,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    Typography
} from "@mui/material";
import React from "react";
import RemoveMemberIcon from "../../../assets/images/removeMemberIcon.png";
import { StyledDialog } from "../../common-components/StyledComponents";

const RemoveMember = ({
    open,
    onClose,
    onApply,
    selectedUsers
}) => {

    return (
        <>
            <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
                <img
                    src={RemoveMemberIcon}
                    style={{ position: "absolute", padding: "16px", width: "80px" }}
                />
                <DialogActions>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </DialogActions>
                <DialogTitle>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                        {"Remove Team Member(s)"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid2 container direction="column" spacing={3} fontFamily={"Inter"}>
                        <Grid2 item xs={12}>
                            {selectedUsers.length > 0 ? (
                                selectedUsers.map((user, index) => (
                                    <Grid2
                                        key={user.id}
                                        container
                                        alignItems="center"
                                        spacing={2} // This adds gap between avatar and name
                                        sx={{ marginBottom: "16px" }} // Add margin between each user
                                    >
                                        <Grid2 item>
                                            <Avatar alt={user.name} src={user.avatarUrl} />
                                        </Grid2>
                                        <Grid2 item>
                                            <Typography variant="body1">
                                                {user.name}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                ))
                            ) : (
                                <Typography>No user selected.</Typography>
                            )}
                        </Grid2>
                        <Button
                            variant="contained"
                            fontWeight="600"
                            sx={{
                                backgroundColor: "#D92D20",
                                "&:hover": {
                                    backgroundColor: "#D92D20",
                                }
                            }}
                            onClick={onApply}
                        >
                            {"Remove Team Member(s)"}
                        </Button>
                        <Typography fontSize={"16px"} color="#667085">
                            {"This action cannot be undone."}
                        </Typography>
                    </Grid2>
                </DialogContent>
            </StyledDialog >
        </>
    );
};

export default RemoveMember;
