import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  ButtonGroup,
  debounce,
  Grid,
  List,
  ListItem,
  TableCell,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EyeIco from "../../assets/icons/EyeIco";
import FileAddIco from "../../assets/icons/FileAddIco";
import ShareIco from "../../assets/icons/ShareIco";
import CommonButton from "../../component/Button";
import CommonTextField from "../../component/Input";
import CustomMenuItem from "../../component/Menu";
import SnackBar from "../../component/SnackBar";
import {
  AddToJobPool,
  AddToJobPoolList,
  SearchAndListOfResumes,
} from "../../redux/action/Candidate";
import AddJobPool from "./AddJobPool";
import AddUpdateNote from "../common-components/AddUpdateNote";
import FilterDialog from "../common-components/FilterDialog";
import {
  CheckBoxSelected,
  DotColor,
  ScoreChange,
  StatusChange,
  StyledFlexRowView,
} from "../common-components/StyledComponents";
import CommonTable from "./Table";

export default function Candidates() {
  const [filterPopup, setFilterPopup] = useState(false);
  const [titleValue, setTitleValue] = useState("");
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [openActiveJobDialog, setOpenActiveJobDialog] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [correctedQuery, setCorrectedQuery] = useState("");
  const [currentQuery, setCurrentQuery] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const limit = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [tableRowData, setTableRowData] = useState({});
  const navigate = useNavigate();
  const suggestionsRef = useRef(null);
  const [jobTitles, setJobTitles] = React.useState([]);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [buttonLoading, setButtonLoading] = useState({
    disabled: false,
    loading: false,
  });
  const query = new URLSearchParams(useLocation().search);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [candidateList, setCandidateList] = useState({
    data: [],
    currentPage: 1,
    totalPage: "",
    loading: false,
    count: "",
    direction: "asc",
    columnName: "",
    status: false,
  });
  const [currentResumeData, setCurrentResumeData] = useState({
    currentResume: "",
    percent: 0,
  });

  const [checkSelected, setCheckSelected] = useState({
    count: 0,
    selected: [],
  });

  const [headerData, setHeaderData] = useState([
    { name: "ID", direction: "asc", sort: true, width: "6%", key: "order_no" },
    {
      name: "Name",
      direction: "asc",
      sort: true,
      width: "16%",
      key: "personal_details.occupational_classification.keyword",
    },
    {
      name: "Title",
      direction: "asc",
      sort: true,
      width: "30%",
      key: "personal_details.title.keyword",
    },
    { name: "Status", direction: "asc", sort: false, width: "10%" },
    { name: "RiCE Score", direction: "asc", sort: false, width: "14%" },
    {
      name: "Last Activity",
      direction: "asc",
      sort: true,
      width: "15%",
      key: "updated_at",
    },
    { width: "5%" },
  ]);

  const activeJobData = [
    {
      options: jobTitles, // Assume jobTitles contains your job data
      onChange: (value) => setSelectedJobTitle(value),
      placeholder: "Choose a job",
    },
  ];

  const filterOptions = [
    {
      label: "Location",
      key: "location",
      type: "multi-select",
      options: filterList.location,
    },
    {
      label: "Domain",
      key: "domain",
      type: "multi-select",
      options: filterList.domain,
    },
    {
      label: "Seniority Level",
      key: "seniority",
      type: "multi-select",
      options: filterList.seniority,
    },
    { label: "Date Range", key: "dateRange", type: "date" },
    {
      label: "Education",
      key: "education",
      type: "multi-select",
      options: filterList.education,
    },
  ];

  const handleSortChange = (col, direction) => {
    const sortFieldMapping = {
      Name: "personal_details.candidate_name.keyword",
      Title: "personal_details.title.keyword",
      "Last Activity": "updated_at",
    };

    const field = sortFieldMapping[col] || col;

    const sortPayload = {
      sort: {
        status: true,
        order: direction,
        field: field,
      },
    };

    setCandidateList((prev) => ({
      ...prev,
      sorting: sortPayload.sort,
    }));

    fetchCandidatesList({
      ...candidateList,
      sort: sortPayload.sort,
    });
  };

  const fetchCandidatesList = async ({
    limit = 10,
    offset = (candidateList.currentPage - 1) * limit,
    q = debouncedQuery,
    filterValues = {},
    status_flag = false,
    columnName,
    status = false,
    direction,
  }) => {
    setCandidateList((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const filters = [
        {
          field: "personal_details.location.keyword",
          value: filterValues.location || "",
        },
        {
          field: "personal_details.industry.keyword",
          value: filterValues.domain || "",
        },
        {
          field: "personal_details.seniority_level.keyword",
          value: filterValues.seniority || "",
        },
        {
          field: "updated_at",
          from_date: filterValues.dateRange?.from_date || "",
          to_date: filterValues.dateRange?.to_date || "",
        },
        {
          field: "elc_table.qualifications.name.keyword",
          value: filterValues.education || "",
        },
      ].filter((filter) => Object.values(filter).some((value) => value !== ""));

      let params = {
        limit: limit,
        offset: offset,
      };
      let payload = {
        q,
        filter: filters,
        status_flag: status_flag,
        sort: {
          order: direction,
          status: status,
          field: columnName,
        },
        organization: JSON.parse(
          localStorage.getItem("credential")
        )?.organization?.toLowerCase(),
      };
      const response = await SearchAndListOfResumes(payload, params);

      setCandidateList((prev) => ({
        ...prev,
        data: response?.data?.results || [],
        totalPage: response?.data?.total_pages,
        loading: false,
        count: response?.data?.count || "",
      }));
      setFilterList(response?.data?.filters);
      setSuggestion(response?.data?.suggestions);
      setCorrectedQuery(response?.data?.corrected_query);
      setCurrentQuery(response?.data?.current_query);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setCandidateList((prev) => ({
        ...prev,
        data: [],
        totalPage: 0,
        loading: false,
        count: 0,
      }));

      setFilterList([]);
      setSuggestion([]);
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleSearchChange = useCallback(
    debounce((query) => {
      setDebouncedQuery(query);
      setShowSuggestion(query.length > 0);
    }, 500),
    []
  );

  const handleSearchInput = (e) => {
    const query = e.target.value;
    setTitleValue(query);
    handleSearchChange(query);
    setShowSuggestion(query.length > 0);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setShowSuggestion(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setTitleValue(query.get("occupational_classification"));
    setDebouncedQuery(query.get("occupational_classification") || "");
  }, []);

  useEffect(() => {
    fetchCandidatesList({
      limit,
      offset: (candidateList.currentPage - 1) * limit,
      q: debouncedQuery,
      // filterValues:  || {},
      status_flag: false,
    });
  }, [debouncedQuery, candidateList.currentPage]);

  const fetchJobTitles = async () => {
    try {
      const response = await AddToJobPoolList();
      const jobData = response.data;
      if (Array.isArray(jobData)) {
        const jobOptions = jobData.map((job) => ({
          label: `${job.unique_id} - ${job.title} - ${job.company}`,
          value: job.id,
        }));
        setJobTitles(jobOptions);
      } else {
        setJobTitles([]);
      }
    } catch (error) {
      setJobTitles([]);
    }
  };

  const handleApply = async (setFiles) => {
    setIsRunning(true);
    setFiles((prev) => ({
      ...prev,
      file: prev.file.map((f) => ({
        ...f,
        progress: 0,
        buffer: 0,
        visible: false,
        message: "",
      })),
    }));
    const resumeIds = checkSelected?.selected?.map((item) => item.resume_id);
    // ?.map((item) => {
    //   const candidate = candidateList?.data?.find((c) => c.id === item.id);
    //   return candidate ? candidate?.resume_id : null;
    // })
    // .filter((id) => id !== null);

    try {
      const payload = {
        job: selectedJobTitle.value,
        resumes: resumeIds,
      };

      const response = await AddToJobPool(payload);
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf("}\n")) !== -1) {
          const jsonString = buffer.slice(0, boundaryIndex + 1);
          buffer = buffer.slice(boundaryIndex + 1);
          const jsonResponse = JSON.parse(jsonString);
          try {
            processJsonResponse(jsonResponse, setFiles);
          } catch (e) {
            setFiles((prevFiles) => ({
              ...prevFiles,
              file: prevFiles.file.map((f) =>
                f.resume_id === jsonResponse.resume_id
                  ? {
                      ...f,
                      message: "Error during the request",
                      visible: true,
                    }
                  : { ...f }
              ),
            }));
            setSnackbarState({
              open: true,
              message: "Error parsing JSON",
              severity: "error",
            });
            setIsLoading(false);
          }
        }
      }

      if (buffer) {
        const jsonResponse = JSON.parse(buffer);
        try {
          processJsonResponse(jsonResponse, setFiles);
        } catch (e) {
          setFiles((prevFiles) => ({
            ...prevFiles,
            file: prevFiles.file.map((f) =>
              f.resume_id === jsonResponse.resume_id
                ? {
                    ...f,
                    message: "Error during the request",
                    visible: true,
                  }
                : { ...f }
            ),
          }));
          setSnackbarState({
            open: true,
            message: "Error parsing final JSON chunk",
            severity: "error",
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      setFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          file: {
            ...f.file,
            message: "Error during the request",
            visible: true,
          },
        }))
      );

      setSnackbarState({
        open: true,
        message: "Error during the request",
        severity: "error",
      });
      setIsLoading(false);
    }
    handleCloseActiveJobDialog();
  };

  const processJsonResponse = async (jsonResponse, setFiles) => {
    const { success, data, message, resume_id } = jsonResponse;

    if (Object.keys(data).length === 0) {
      setSnackbarState({
        open: true,
        message: message,
        severity: "error",
      });
    } else if (success && data?.percent !== undefined) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        file: prevFiles.file.map((f) =>
          f.resume_id === resume_id
            ? {
                ...f,
                progress: data.percent,
                buffer: data.percent,
                visible: false,
              }
            : f
        ),
      }));
    } else if (!success) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        file: prevFiles.file.map((f) =>
          f.resume_id === resume_id || data.length === 0
            ? { ...f, visible: true, message: message }
            : { ...f, delete: true }
        ),
      }));
      setIsLoading(false);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setFilterPopup(false);
  };

  const handleAddNote = () => {
    setOpenNoteDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
  };

  const handleOpenActiveJobDialog = () => {
    setOpenActiveJobDialog(true);
    handleMenuClose();
    setFiles((prev) => ({
      ...prev,
      file: checkSelected.selected,
      progress: 0,
      buffer: 0,
      visible: false,
      message: "",
    }));
    fetchJobTitles();
  };

  const handleCloseActiveJobDialog = () => {
    setOpenActiveJobDialog(false);
    setIsRunning(false);
    setFiles([]);
    setCheckSelected({
      selected: [],
      count: 0,
    });
  };

  const handleSuggestionClick = (suggestion) => {
    setTitleValue(suggestion);
    setDebouncedQuery(suggestion);
    setShowSuggestion(false);
  };
  const [noteId, setNoteId] = useState("");
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "Email", added_by: "username" },
  ]);

  const menuData = [
    {
      title: "Share",
      image: <ShareIco />,
    },
    {
      title: "Add to an Active Job",
      image: <FileAddIco />,
      disabled: true,
      onClick: handleOpenActiveJobDialog,
    },
    {
      title: "View",
      image: <EyeIco />,
      onClick: () =>
        navigate(
          `/candidates/candidate-view?view-id=${tableRowData.resume_id}`
        ),
    },
  ];

  const handleIconClick = (event, rowData) => {
    setTableRowData(rowData);
    setAnchorEl(event.currentTarget);
  };

  const handleNoteTextChange = (index, e) => {
    const newNoteList = [...noteList];
    newNoteList[index].note = e.target.value;
    setNoteList(newNoteList);
  };

  const handleClick = () => {
    fetchCandidatesList({
      limit,
      offset: (candidateList.currentPage - 1) * limit,
      q: currentQuery,
      filterValues: filterState || {},
      status_flag: true,
    });
    setShowSuggestion(false);
  };

  const handleSingleSelect = (row) => {
    setCheckSelected((prev) => {
      const isSelected = prev.selected.some((item) => item.id === row.id);
      const updatedSelected = isSelected
        ? prev.selected.filter((item) => item.id !== row.id)
        : [
            ...prev.selected,
            {
              id: row.id,
              name: row.name,
              type: row.resume_file_name,
              resume_id: row.resume_id,
            },
          ];

      const updatedCount = updatedSelected.length;

      return { selected: updatedSelected, count: updatedCount };
    });
  };

  const handleSelectAll = () => {
    const allIds = candidateList.data.map((row) => ({
      id: row.id,
      name: row.name,
      type: row.resume_file_name,
      resume_id: row.resume_id,
    }));

    if (
      candidateList.data.every((row) =>
        checkSelected.selected.some((item) => item.id === row.id)
      )
    ) {
      const deselectedIds = checkSelected.selected.filter(
        (item) => !allIds.some((row) => row.id === item.id)
      );
      setCheckSelected({
        selected: deselectedIds,
        count: deselectedIds.length,
      });
    } else {
      const updatedSelected = [...checkSelected.selected, ...allIds];
      const uniqueSelected = Array.from(
        new Set(updatedSelected.map((item) => item.id))
      ).map((id) => updatedSelected.find((item) => item.id === id));

      setCheckSelected({
        selected: uniqueSelected,
        count: uniqueSelected.length,
      });
    }
  };

  const handleSort = (columnName) => {
    setHeaderData((prevHeaderData) =>
      prevHeaderData.map((col) => {
        if (col.name === columnName.name) {
          return {
            ...col,
            direction: col.direction === "asc" ? "desc" : "asc",
          };
        }
        return col;
      })
    );
    setCandidateList((prev) => {
      return {
        ...prev,
        columnName: columnName.name,
        status: true,
        direction: columnName.direction === "asc" ? "desc" : "asc",
      };
    });

    fetchCandidatesList({
      limit,
      offset: (candidateList.currentPage - 1) * limit,
      q: debouncedQuery,
      // filterValues:  || {},
      status_flag: false,
      columnName: columnName.key,
      status: true,
      direction: columnName.direction === "asc" ? "desc" : "asc",
    });
  };
  const isAddToJobPoolEnabled = checkSelected.count > 0;

  const [filterState, setFilterState] = useState(
    filterOptions.reduce((acc, option) => {
      acc[option.key] =
        option.type === "multi-select" ? [] : option.value || null;
      return acc;
    }, {})
  );

  const handleDropdownChange = (key, value) => {
    const newState = { ...filterState, [key]: value };
    setFilterState(newState);
    fetchCandidatesList({
      limit,
      offset: (candidateList.currentPage - 1) * limit,
      q: currentQuery,
      filterValues: newState,
      status_flag: false,
    });
  };

  const countSelectedFields = () => {
    return Object.values(filterState).filter((value) =>
      Array.isArray(value) ? value.length > 0 : value != null
    ).length;
  };

  const selectedCount = countSelectedFields();

  const handleDateChange = (key, start, end) => {
    const newState = {
      ...filterState,
      [key]: {
        from_date: start,
        to_date: end || start,
      },
    };
    setFilterState(newState);
    fetchCandidatesList({
      limit,
      offset: (candidateList.currentPage - 1) * limit,
      q: currentQuery,
      filterValues: newState,
      status_flag: false,
    });
  };
  const handleApplyFilters = () => {
    fetchCandidatesList({ filterState }).finally(() => {
      handleClose();
    });
  };

  const handleClearForm = () => {
    setFilterState(
      filterOptions.reduce((acc, option) => {
        acc[option.key] = option.type === "multi-select" ? [] : null;
        return acc;
      }, {})
    );
  };

  const handleFocus = () => {
    setShowSuggestion(true);
  };

  const handleTableBodyData = (row) => {
    return (
      <>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.job_title}</TableCell>
        <TableCell>
          <StatusChange>
            <DotColor
              color={
                row.status === "Inactive" || row.status === "Closed"
                  ? "#667085"
                  : row.status === "Under Consideration" ||
                    row.status === "Hold"
                  ? "orange"
                  : row.status === "Available" || row.status === "Active"
                  ? "green"
                  : "#667085"
              }
            />
            {row.status}
          </StatusChange>
        </TableCell>
        <TableCell>
          <ScoreChange>{row.rice_score}%</ScoreChange>
        </TableCell>
        <TableCell>{row.updated_at}</TableCell>
        <TableCell>
          <MoreVertOutlinedIcon
            style={{ cursor: "pointer" }}
            onClick={(e) => handleIconClick(e, row)}
          />
          <CustomMenuItem
            open={open}
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose}
            menuData={menuData}
            boxShadow={
              "0px 0px 0px 0px rgba(16, 24, 40, 0.08),0px 3px 5px -3px rgba(16, 24, 40, 0.03)"
            }
          />
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={5} display="flex" alignItems="center">
          <Typography variant="h2">Candidates</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          alignItems="center"
          sx={{ gap: 2, position: "relative" }}
        >
          <CommonTextField
            placeholder="Search"
            startIcon={<SearchIcon />}
            value={titleValue}
            onChange={handleSearchInput}
            handleFocus={handleFocus}
          />
          {showSuggestion && suggestion.length > 0 && (
            <List
              ref={suggestionsRef}
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                right: 0,
                background: "white",
                border: "1px solid #ccc",
                borderRadius: 1,
                zIndex: 1000,
                maxHeight: 200,
                overflowY: "auto",
                fontFamily: "Inter",
              }}
            >
              {suggestion.map((sug, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => handleSuggestionClick(sug)}
                  sx={{
                    padding: "8px 16px",
                    cursor: "pointer",
                    fontFamily: "Inter",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  {sug}
                </ListItem>
              ))}
            </List>
          )}
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Filters"
            onClick={() => setFilterPopup(true)}
          />
          <CommonButton
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Add New"
            onClick={() =>
              navigate("/resume-intelligence/create-candidate-resume")
            }
          />
        </Grid>
      </Grid>
      <StyledFlexRowView
        style={{ justifyContent: "space-between", padding: "32px 0px 0px 0px" }}
      >
        <CheckBoxSelected>
          Selected{" "}
          <span style={{ color: "#667085" }}> · {checkSelected.count}</span>
        </CheckBoxSelected>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Add to Job Pool"
            padding={"10px 16px"}
            disabled={!isAddToJobPoolEnabled}
            onClick={handleOpenActiveJobDialog}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Re-run RiCE Scoring"
            padding={"10px 16px"}
            disabled={true}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Compare"
            padding={"10px 16px"}
            disabled={true}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Schedule Interview"
            padding={"10px 16px"}
            disabled={true}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Change Status"
            padding={"10px 16px"}
            disabled={true}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Add Notes"
            onClick={handleAddNote}
            padding={"10px 16px"}
            disabled={true}
          />
        </ButtonGroup>
      </StyledFlexRowView>

      {correctedQuery && (
        <CheckBoxSelected style={{ padding: "16px 0px 0px 0px" }}>
          <span style={{ color: "#000" }}>{"These are results for "}</span>
          <span style={{ color: "#7f56d9" }}>{correctedQuery}</span>
        </CheckBoxSelected>
      )}
      {currentQuery && correctedQuery !== null && (
        <CheckBoxSelected
          style={{ padding: "0px 0px 0px 0px", fontSize: "14px" }}
        >
          <span style={{ color: "#000" }}>{"Search instead for "}</span>
          <button
            style={{
              color: "#7f56d9",
              background: "none",
              border: "none",
              cursor: "pointer",
              fontSize: "inherit",
              textDecoration: "underline",
            }}
            onClick={() => handleClick()}
          >
            {currentQuery}
          </button>
        </CheckBoxSelected>
      )}

      <CommonTable
        data={candidateList.data}
        columns={headerData}
        onSortChange={handleSort}
        checkSelected={checkSelected.selected}
        handleTableBodyData={handleTableBodyData}
        paginationProps={{
          count: candidateList.totalPage,
          page: candidateList.currentPage,
          onPageChange: (page) =>
            setCandidateList((prev) => ({
              ...prev,
              currentPage: page,
            })),
        }}
        withPagination={true}
        withCheckbox={true}
        loading={candidateList.loading}
        handleSingleSelect={handleSingleSelect}
        handleSelectAll={handleSelectAll}
        sort={candidateList}
      />
      <FilterDialog
        open={filterPopup}
        onClose={handleClose}
        title="Filter"
        filterOptions={filterOptions}
        backgroundImage="path_to_background_image"
        count={candidateList.count}
        filterState={filterState}
        handleClearForm={handleClearForm}
        handleDropdownChange={handleDropdownChange}
        handleDateChange={handleDateChange}
        handleApplyFilters={handleApplyFilters}
        selectedCount={selectedCount}
        buttonLoading={buttonLoading}
      />

      <AddUpdateNote
        openNoteDialog={openNoteDialog}
        handleCloseNoteDialog={handleCloseNoteDialog}
        id={noteId}
        handleCreateNote={handleCloseNoteDialog}
        noteList={noteList}
        handleNoteTextChange={handleNoteTextChange}
      />
      <AddJobPool
        open={openActiveJobDialog}
        onClose={handleCloseActiveJobDialog}
        activeOptions={activeJobData}
        onApply={() => handleApply(setFiles)}
        placeholder="Search for Job or Company"
        buttonValue="Run Judica"
        disabled={!selectedJobTitle || isLoading || isRunning}
        buttonCloseDisabled={isRunning}
        loading={isRunning}
        files={files}
        isLoading={jobTitles.length == 0}
        currentResumeData={currentResumeData}
        backgroundImage="path_to_background_image"
      />

      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </>
  );
}
