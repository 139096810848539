import { TabContext } from "@mui/lab";
import { Box, Grid2, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CandidateHub from "../../../assets/icons/CandidateHub";
import CommonButton from "../../../component/Button";
import SnackBar from "../../../component/SnackBar";
import { CandidateViewCall } from "../../../redux/action/Candidate";
import {
  ResumeIntelligenceDeleteNote,
  ResumeIntelligenceFix,
  ResumeIntelligenceGETNote,
  ResumeIntelligencePostNote,
  ResumeIntelligencePutNote,
  ResumeIntelligenceSave,
  ResumeIntelligenceUpload,
  ResumeIntelligenceUploadResume,
} from "../../../redux/action/ResumeIntelligence";
import {
  CommonGrid2,
  CreateTabList,
  TabPanelView,
} from "../../common-components/StyledComponents";
import DialogCandidateHub from "../../jobs/jobs-candidate/components/DialogCandidateHub";
import ReviewCandidateProfile from "./components/ReviewCandidateProfile";
import UploadCandidateResume from "./components/UploadCandidateResume";
import { JobResumeDuplicate } from "../../../redux/action/Job";
import { GETDOWNLOAD } from "../../../redux/axios/Axios";
import { DownloadResume } from "../../../api/Api";
import ResumeCreateNote from "./components/CreateNote";
import NewDeleteNote from "../../common-components/NewDeleteNote";

function CreateCandidateResume() {
  const [value, setValue] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const dispatch = useDispatch();
  const allowedTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const location = useLocation();
  const [resumeLoading, setResumeLoading] = useState(false);
  const [viewResume, setViewResume] = useState({
    open: false,
    disabled: false,
    duplicateLoading: false,
    replacedLoading: false,
    leaveLoading: false,
  });
  const [downloadMenuLoader, setDownloadMenuLoader] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const [fixOpen, setFixOpen] = useState(false);
  const [resumeIntelligenceSave, setResumeIntelligenceSave] = useState({});
  const [tabData, setTabData] = useState([
    {
      name: "Upload Candidate Resume",
      disabled: false,
    },
    {
      name: "Review Candidate Profile",
      disabled: true,
    },
  ]);
  const [noteHeaderData, setNoteHeaderData] = useState([
    { name: "Title", direction: "asc", sort: false, width: "50%" },
    { name: "Created By", direction: "asc", sort: false, width: "20%" },
    { name: "Date", direction: "asc", sort: false, width: "15%" },
    { width: "15%" },
  ]);

  const [openNote, setOpenNote] = useState({
    loading: false,
    open: false,
    note: "",
    id: "",
    deleteNote: false,
    created_at: "",
  });
  const [notesTableList, setNotesTableList] = useState([]);
  const resumeIntelligence = useSelector(
    (state) => state.resumeIntelligence.resumeIntelligenceSave
  );
  const [fixLoading, setFixLoading] = useState(false);
  const [notesTableListLoading, setNotesTableListLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const uploadFile = async () => {
    setIsLoading(true);
    setFiles((prevFiles) =>
      prevFiles.map((f) => ({
        ...f,
        delete: false,
        message: "",
        visible: false,
      }))
    );

    const formData = new FormData();

    files.forEach((fileObj, index) => {
      formData.append("files", fileObj.file);
    });

    try {
      const response = await ResumeIntelligenceUpload(formData);
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf("}\n")) !== -1) {
          const jsonString = buffer.slice(0, boundaryIndex + 1);
          buffer = buffer.slice(boundaryIndex + 1);
          const jsonResponse = JSON.parse(jsonString);
          try {
            processJsonResponse(jsonResponse);
          } catch (e) {
            setFiles((prevFiles) =>
              prevFiles.map((f) => ({
                ...f,
                visible: true,
                delete: true,
                message: jsonResponse.message,
              }))
            );

            setSnackbarState({
              open: true,
              message: "Error parsing JSON",
              severity: "error",
            });
            setIsLoading(false);
          }
        }
      }

      if (buffer) {
        const jsonResponse = JSON.parse(buffer);
        try {
          processJsonResponse(jsonResponse);
        } catch (e) {
          setFiles((prevFiles) =>
            prevFiles.map((f) => ({
              ...f,
              visible: true,
              delete: true,
              message: jsonResponse.message,
            }))
          );

          setSnackbarState({
            open: true,
            message: "Error parsing final JSON chunk",
            severity: "error",
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      setFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          visible: true,
          delete: true,
          message: "Error during the request",
        }))
      );

      setSnackbarState({
        open: true,
        message: "Error during the request",
        severity: "error",
      });

      setIsLoading(false);
    }
  };

  const processJsonResponse = (jsonResponse) => {
    const { success, data, message } = jsonResponse;

    if (success && data?.percent !== undefined) {
      const dataFormat = {
        ...jsonResponse,
        files: files
          ? files.map(({ file, ...rest }) => ({
              ...rest,
              ...file,
              progress: data.percent,
              buffer: data.buffer || data.percent,
              visible: false,
              delete: false,
            }))
          : [],
        data: {
          ...jsonResponse.data,
        },
      };
      dispatch(ResumeIntelligenceUploadResume(dataFormat));
      setFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          progress: data.percent,
          buffer: data.buffer || data.percent,
          visible: false,
          delete: false,
        }))
      );
    } else if (data?.id) {
      const dataFormat = {
        jsonResponse,
        files: files
          ? files.map(({ file, ...rest }) => ({
              ...rest,
              ...file,
              progress: data.percent,
              buffer: data.buffer || data.percent,
              visible: false,
              delete: false,
            }))
          : [],
        data: {
          ...jsonResponse.data,
        },
      };
      dispatch(ResumeIntelligenceUploadResume(dataFormat));
      setResumeData(data);
      setSnackbarState({
        open: true,
        message: message,
        severity: "success",
      });

      setTabData((prev) =>
        prev.map((tab, index) => (index < 1 ? { ...tab, disabled: true } : tab))
      );
      navigate("/resume-intelligence/create-candidate-resume?resume=parse");
      setValue(1);
    } else if (!success) {
      const dataFormat = {
        ...jsonResponse,
        files: files
          ? files.map(({ file, ...rest }) => ({
              ...rest,
              ...file,
              progress: data?.percent,
              buffer: data?.buffer || data.percent,
              visible: true,
              delete: true,
              message: message,
            }))
          : [],
        data: {},
      };
      dispatch(ResumeIntelligenceUploadResume(dataFormat));
      setFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          visible: true,
          delete: true,
          message: message,
        }))
      );
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "error",
      });
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const commonFileValidationCheck = (
    commonFiles,
    allowedTypes,
    setCommonFiles,
    existingFiles
  ) => {
    const validFiles = [];
    const invalidFiles = [];

    commonFiles.forEach((file) => {
      if (allowedTypes.includes(file.type)) {
        validFiles.push({
          file,
          name: file.name,
          size: `${(file.size / 1024).toFixed(1)} KB`,
          type: file.type,
          progress: 0,
          buffer: 0,
          visible: false,
          delete: true,
          message: "",
        });
      } else {
        invalidFiles.push(file.name);
      }
    });

    const mergedFiles = [
      ...existingFiles,
      ...validFiles.filter(
        (newFile) =>
          !existingFiles.some(
            (existingFile) => existingFile.name === newFile.name
          )
      ),
    ];
    setCommonFiles(mergedFiles);

    if (invalidFiles.length > 0) {
      setSnackbarState({
        open: true,
        message: `Unsupported file types: ${invalidFiles.join(", ")}`,
        severity: "error",
      });
    }
  };

  const handleFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    commonFileValidationCheck(selectedFiles, allowedTypes, setFiles, files);
  };

  // Handle file deletion
  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleNextUploadClick = async () => {
    if (files.length == 0) {
      setSnackbarState({
        open: true,
        message: "Upload the Files",
        severity: "error",
      });
    } else {
      uploadFile();
    }
  };

  // Drag-and-drop functionality
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const selectedFiles = Array.from(event.dataTransfer.files);
    commonFileValidationCheck(selectedFiles, allowedTypes, setFiles, files);
  };

  const Footer = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Ok"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        onClick={handleFixClose}
      />
    </CommonGrid2>
  );
  const handleFixClose = () => {
    setFixOpen(false);
  };

  const handleScoreFix = async () => {
    if (query.get("resume") === "parse") {
      setFixOpen(true);
    } else {
      setFixLoading(true);
      try {
        const data = await ResumeIntelligenceFix(resumeIntelligence.data.id);
        setFixLoading(false);
        setResumeLoading(true);
        setSnackbarState({
          open: true,
          message: data.message,
          severity: "success",
        });
        const fixData = await dispatch(
          CandidateViewCall(resumeIntelligence.data.id)
        );
        setResumeData(fixData);
        setTimeout(() => {
          setResumeLoading(false);
        }, 1000);
      } catch (error) {
        setFixLoading(false);
        setSnackbarState({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  };

  const resumeIntelligenceUpload = useSelector(
    (state) => state.resumeIntelligence.resumeIntelligenceUpload
  );

  console.log(window.location, "sfjsfkjsfnjsfnj");
  useEffect(() => {
    if (
      resumeIntelligenceUpload &&
      Object.keys(resumeIntelligenceUpload)?.length === 0 &&
      window.location.search.includes("resume=parse")
    ) {
      navigate("/resume-intelligence/create-candidate-resume");
    } else if (window.location.search === "") {
      setValue(0);
    } else if (window.location.search.includes("resume=parse")) {
      setResumeData(resumeIntelligenceUpload?.data);
      setTabData((prev) =>
        prev.map((tab, index) => (index < 1 ? { ...tab, disabled: true } : tab))
      );
      setValue(1);
    }
  }, [location]);

  useEffect(() => {
    if (
      resumeIntelligenceUpload &&
      Object.keys(resumeIntelligenceUpload)?.length !== 0 &&
      resumeIntelligenceUpload?.data?.id &&
      value == 0
    ) {
      setIsLoading(false);
      setFiles([]);
    } else if (
      resumeIntelligenceUpload &&
      Object.keys(resumeIntelligenceUpload)?.length !== 0 &&
      value == 0
    ) {
      setIsLoading(true);
      setFiles(resumeIntelligenceUpload.files);
    }
  }, [resumeIntelligenceUpload]);

  const [saveCandidateOpen, setSaveCandidateOpen] = useState({
    open: false,
    loading: false,
    disabled: false,
    leaveLoading: false,
  });

  const handleSaveLeave = async (action) => {
    let params = {
      action: action,
      resume_id: resumeData.id,
    };
    setSaveCandidateOpen((prev) => ({
      ...prev,
      disabled: true,
      leaveLoading: true,
    }));
    try {
      const data = await JobResumeDuplicate(params);
      setResumeIntelligenceSave(data?.data);
      setSnackbarState({
        open: true,
        severity: "success",
        message: data?.message,
      });
      handleSaveCandidateClose();
      navigate("/resume-intelligence/create-candidate-resume");
      setValue(0);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setSaveCandidateOpen((prev) => ({
        ...prev,
        loading: false,
        leaveLoading: false,
        disabled: false,
      }));
    }
  };

  const SaveCandidateFooter = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Leave"}
        variant="outlined"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        color="#344054"
        border="#D0D5DD"
        disabled={saveCandidateOpen.disabled}
        loading={saveCandidateOpen.leaveLoading}
        onClick={() => handleSaveLeave("leave")}
      />
      <CommonButton
        value={"Save"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={saveCandidateOpen.disabled}
        loading={saveCandidateOpen.loading}
        onClick={handleSave}
      />
    </CommonGrid2>
  );

  const handleSave = async () => {
    setSaveCandidateOpen((prev) => ({
      ...prev,
      disabled: true,
      loading: true,
    }));

    try {
      let params = {
        resume_id: resumeData.id,
      };
      const data = await dispatch(ResumeIntelligenceSave(params));
      setResumeIntelligenceSave(data?.data);
      setSnackbarState({
        open: true,
        severity: "success",
        message: data.message,
      });
      handleSaveCandidateClose();
      navigate(`/candidates/candidate-view?view-id=${data?.data?.id}`);
    } catch (error) {
      setSaveCandidateOpen((prev) => ({
        ...prev,
        loading: false,
      }));
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.data?.message || error.message,
      });
    }
  };
  console.log("sf sfnsfkjjnf-1", saveCandidateOpen);

  const handleSaveCandidateClose = () => {
    setSaveCandidateOpen({
      open: false,
      loading: false,
      disabled: false,
      leaveLoading: false,
    });
  };

  const handleSaveToCandidatehub = () => {
    if (resumeData?.is_duplicate_present) {
      setViewResume((prev) => ({
        ...prev,
        open: true,
      }));
    } else
      setSaveCandidateOpen({
        open: true,
        loading: false,
      });
  };

  const handleDuplicate = async (action) => {
    let params = {
      action: action,
      resume_id: resumeData.id,
    };
    setViewResume((prev) => ({
      ...prev,
      disabled: true,
      duplicateLoading: action === "duplicate" ? true : false,
      replacedLoading: action === "replaced" ? true : false,
      leaveLoading: action === "leave" ? true : false,
    }));
    try {
      const data = await JobResumeDuplicate(params);
      setResumeIntelligenceSave(data?.data);
      setSnackbarState({
        open: true,
        severity: "success",
        message: data?.message,
      });
      handleViewCandidateClose();
      if (action === "leave") {
        navigate("/resume-intelligence/create-candidate-resume");
        setValue(0);
      } else navigate(`/candidates/candidate-view?view-id=${data?.data?.id}`);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setViewResume((prev) => ({
        ...prev,
        disabled: false,
        duplicateLoading: false,
        replacedLoading: false,
        leaveLoading: false,
      }));
    }
  };

  const FooterViewResume = () => (
    <CommonGrid2>
      <CommonGrid2 display={"flex"} gap={"1rem"}>
        <CommonButton
          value={"Duplicate"}
          variant="outlined"
          width={"100%"}
          padding={"10px"}
          marginTop={"2rem"}
          fontWeight={"700"}
          fontSize={"15px"}
          color="#344054"
          border="#D0D5DD"
          disabled={viewResume.disabled}
          loading={viewResume.duplicateLoading}
          onClick={() => handleDuplicate("duplicate")}
        />
        <CommonButton
          value={"Replace Existing"}
          width={"100%"}
          padding={"10px"}
          marginTop={"2rem"}
          fontWeight={"700"}
          fontSize={"15px"}
          disabled={viewResume.disabled}
          loading={viewResume.replacedLoading}
          onClick={() => handleDuplicate("replaced")}
        />
      </CommonGrid2>
      <CommonButton
        value={"Leave"}
        variant="outlined"
        width={"100%"}
        padding={"10px"}
        marginTop={"1rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        color="#344054"
        border="#D0D5DD"
        disabled={viewResume.disabled}
        loading={viewResume.leaveLoading}
        onClick={() => handleDuplicate("leave")}
      />
    </CommonGrid2>
  );

  const handleViewCandidateClose = () => {
    setViewResume({
      open: false,
      disabled: false,
      duplicateLoading: false,
      replacedLoading: false,
      leaveLoading: false,
    });
  };

  const handleViewCandidate = () => {
    setViewResume((prev) => ({
      ...prev,
      open: true,
      disabled: false,
      duplicateLoading: false,
      replacedLoading: false,
      leaveLoading: false,
    }));
  };

  const handleDownloadResume = () => {
    if (resumeData?.id) {
      setDownloadMenuLoader(true);
      GETDOWNLOAD(DownloadResume(resumeIntelligenceSave.id), {
        params: {
          type: "resume",
        },
        responseType: "blob",
      })
        .then((response) => {
          const contentType = response.headers["content-type"];

          const fileName = `${resumeData?.resume_file_name}`;

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setDownloadMenuLoader(false);
        })

        .catch((error) => {
          if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
              const responseText = reader.result;
              try {
                const json = JSON.parse(responseText);
                const errorMessage =
                  json.error || "An unexpected error occurred.";
                setSnackbarState({
                  open: true,
                  severity: "error",
                  message: errorMessage,
                });
              } catch (e) {
                setSnackbarState({
                  open: true,
                  severity: "error",
                  message:
                    "An unexpected error occurred while parsing the error message.",
                });
              }
            };
            reader.readAsText(error.response.data);
          } else {
            setSnackbarState({
              open: true,
              severity: "error",
              message: "An unexpected error occurred.",
            });
          }
          setDownloadMenuLoader(false);
        });
    } else {
      setSnackbarState({
        open: true,
        severity: "error",
        message: "Resume was not parsed successfully. Unable to download.",
      });
      setDownloadMenuLoader(false);
    }
  };

  const handleCloseNoteDialog = () => {
    setOpenNote({
      loading: false,
      open: false,
      note: "",
      id: "",
      created_at: "",
      deleteNote: false,
    });
  };

  const handleClickAddNote = () => {
    setOpenNote((prev) => ({
      ...prev,
      open: true,
    }));
  };

  useEffect(() => {
    if (
      value === 1 &&
      resumeIntelligenceSave &&
      resumeIntelligenceSave?.id !== undefined
    ) {
      handleFetchNote();
    }
  }, [value && resumeIntelligenceSave]);

  const handleFetchNote = async () => {
    try {
      setNotesTableListLoading(true);
      const data = await ResumeIntelligenceGETNote(resumeIntelligenceSave.id);
      setNotesTableList(data.data);
      setNotesTableListLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setNotesTableListLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setOpenNote((prev) => ({
        ...prev,
        loading: true,
      }));
      const data = values.id
        ? await ResumeIntelligencePutNote(values.id, values)
        : await ResumeIntelligencePostNote(resumeIntelligenceSave.id, [values]);
      await handleFetchNote();
      setSnackbarState({
        open: true,
        message: data?.message || data?.data?.message,
        severity: "success",
      });
      handleCloseNoteDialog();
      navigate(`/candidates/candidate-view?view-id=${data?.id}`);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setOpenNote((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleUpdateNoteClick = (row) => {
    setOpenNote({
      open: true,
      note: row.note,
      id: row.id,
    });
  };

  const handleDeleteNoteClick = (row) => {
    setOpenNote((prev) => ({
      ...prev,
      deleteNote: true,
      id: row?.id,
      note: row?.note,
      created_at: row?.created_at,
    }));
  };

  const handleDeleteNoteById = async () => {
    setOpenNote((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const data = await ResumeIntelligenceDeleteNote(openNote.id);
      await handleFetchNote();
      handleCloseNoteDialog();
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setOpenNote((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Typography fontSize={"24px"} fontWeight={600}>
          {"Resume Intelligence Engine Module"}
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Box width={"100%"}>
          <TabContext value={value}>
            <Grid2 alignItems="center" marginTop={3}>
              <CreateTabList onChange={handleChange} aria-label="create-job">
                {tabData.map((item, index) => (
                  <Tab
                    label={item.name}
                    value={index}
                    disabled={item.disabled}
                    fontSize={"20px"}
                  />
                ))}
              </CreateTabList>
            </Grid2>
            <TabPanelView value={0} padding="1.5rem 0px 0px">
              <UploadCandidateResume
                handleDragOver={handleDragOver}
                handleDragLeave={handleDragLeave}
                handleDrop={handleDrop}
                handleFileUpload={handleFileUpload}
                isDragging={isDragging}
                files={files}
                handleDeleteFile={handleDeleteFile}
                handleNextUploadClick={handleNextUploadClick}
                isLoading={isLoading}
              />
            </TabPanelView>
            <TabPanelView value={1} padding="1.5rem 0px 0px">
              <ReviewCandidateProfile
                resumeData={resumeData}
                isLoading={resumeLoading}
                handleScoreFix={handleScoreFix}
                fixLoading={fixLoading}
                handleSaveToCandidatehub={handleSaveToCandidatehub}
                handleViewCandidate={handleViewCandidate}
                handleDownloadResume={handleDownloadResume}
                downloadMenuLoader={downloadMenuLoader}
                handleClickAddNote={handleClickAddNote}
                resumeIntelligenceSave={resumeIntelligenceSave}
                notesTableList={notesTableList}
                notesTableListLoading={notesTableListLoading}
                handleUpdateNoteClick={handleUpdateNoteClick}
                handleDeleteNoteClick={handleDeleteNoteClick}
                noteHeaderData={noteHeaderData}
              />
            </TabPanelView>
          </TabContext>
        </Box>
      </Grid2>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <DialogCandidateHub
        open={fixOpen}
        handleClose={handleFixClose}
        footer={<Footer />}
        icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        title="Save to Candidate Hub?"
        titlepadding={"0px 0px 16px"}
        content={
          <>
            Save the Candidate Hub to access the{" "}
            <strong style={{ fontWeight: 700 }}>Fix</strong> feature for
            correcting grammar mistakes
          </>
        }
      />

      <DialogCandidateHub
        open={saveCandidateOpen.open}
        handleClose={handleSaveCandidateClose}
        icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        footer={<SaveCandidateFooter />}
        title="Save to Candidate Hub?"
        titlepadding={"0px 0px 16px"}
        content={
          <>
            You are about to save a candidate under the name{" "}
            <strong style={{ fontWeight: 700 }}>
              {resumeData?.personal_details?.candidate_name}
            </strong>{" "}
            and the role{" "}
            <strong style={{ fontWeight: 700 }}>
              {resumeData?.personal_details?.inferred_title}
            </strong>{" "}
            in the Candidate Hub.
          </>
        }
      />

      <DialogCandidateHub
        open={viewResume.open}
        handleClose={handleViewCandidateClose}
        icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        footer={<FooterViewResume />}
        title="Candidate Already Exists"
        titlepadding={"0px 0px 16px"}
        content={
          <>
            You are about to save a candidate under the name{" "}
            <strong style={{ fontWeight: 700 }}>
              {resumeData?.personal_details?.candidate_name}
            </strong>{" "}
            and the role{" "}
            <strong style={{ fontWeight: 700 }}>
              {resumeData?.personal_details?.inferred_title}
            </strong>{" "}
            in the Candidate Hub.
          </>
        }
      />

      <ResumeCreateNote
        openNoteDialog={openNote.open}
        handleCloseNoteDialog={handleCloseNoteDialog}
        openNote={openNote}
        handleSubmit={handleSubmit}
      />
      <NewDeleteNote
        deleteNoteDialogOpen={openNote.deleteNote}
        handleDeleteNoteDialogClose={handleCloseNoteDialog}
        noteList={openNote}
        handleDeleteNoteById={handleDeleteNoteById}
        noteLoading={openNote.loading}
      />
    </Grid2>
  );
}

export default CreateCandidateResume;
