import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import CommonButton from "../../component/Button";
import XCloseIco from "../../assets/icons/XCloseIco";
import { CustomDiv } from "../chat-bot/style";
import { Field, Form, Formik } from "formik";
import { CommonGrid2 } from "../common-components/StyledComponents";
import * as Yup from "yup";
import CommonTextField from "../../component/Input";
import CommonPhoneInput from "../../component/PhoneInput";
import { ErrorContent } from "../jobs/style";
import parsePhoneNumber from "libphonenumber-js";

export default function AddMember({
  open,
  handleButttonClose,
  addMemberDialog,
  handleSubmitMember,
  setFieldValue,
  formikValues,
}) {
  const initialValues = {
    name: addMemberDialog.content?.name || "",
    title: addMemberDialog.content?.title || "",
    phone_number: addMemberDialog.content?.phone_number || "",
    email: addMemberDialog.content?.email || "",
    remarks: addMemberDialog.content?.remarks || "",
    phone_number_country: addMemberDialog.content?.phone_number_country || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    title: Yup.string().required("Title is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    phone_number: Yup.string()
      .required("Phone number is required")
      .test(
        "is-valid-phone",
        "Phone number is not valid for the selected country",
        function (value) {
          const { phone_number_country } = this.parent;
          if (!value || !phone_number_country?.countryCode) return false;
          const formattedValue = value.startsWith("+") ? value : `+${value}`;
          const phoneNumber = parsePhoneNumber(
            formattedValue,
            phone_number_country.countryCode
          );
          return phoneNumber?.isValid();
        }
      ),
    phone_number_country: "",
  });

  const primaryViewData = [
    {
      title: "Name",
      component: "input",
      titleName: "name",
      placeholder: "Enter the Name",
    },
    {
      title: "Title",
      component: "input",
      titleName: "title",
      placeholder: "Enter the Title",
    },
    {
      title: "Phone Number",
      component: "phone-number",
      titleName: "phone_number",
      placeholder: "Enter the Phone",
      country: "phone_number_country",
    },
    {
      title: "Email",
      component: "input",
      titleName: "email",
      placeholder: "Enter the email",
    },
    {
      title: "Remarks",
      component: "input",
      titleName: "remarks",
      placeholder: "Enter the remarks",
    },
  ];

  const handleSubmit = (values) => {
    handleSubmitMember(values, setFieldValue, formikValues);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "10px",
        },
      }}
    >
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <CustomDiv></CustomDiv>
        <CommonButton
          onClick={handleButttonClose}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          value={<XCloseIco />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>

      <DialogContent style={{ textAlign: "center", paddingTop: "0px" }}>
        <Typography variant="h3" textAlign={"center"}>
          {addMemberDialog.index === "" ? "Add" : "Update"} Member
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <Grid2 spacing={2} container size={12}>
                  {primaryViewData.map((item) => (
                    <CommonGrid2
                      size={12}
                      item
                      gap={"6px"}
                      display={"flex"}
                      flexDirection={"column"}
                      textAlign={"start"}
                    >
                      <Typography fontSize={"15px"}>{item.title}</Typography>
                      {item.component === "input" && (
                        <Field name={item.titleName}>
                          {({ field, form }) => {
                            return (
                              <CommonTextField
                                placeholder={item.placeholder}
                                placeholderFontSize={"15px"}
                                padding="13px"
                                error={
                                  form.touched[item.titleName] &&
                                  Boolean(errors[item.titleName])
                                }
                                value={values[item.titleName]}
                                onChange={(e) => {
                                  setFieldValue(item.titleName, e.target.value);
                                }}
                                helperText={
                                  form.touched[item.titleName] &&
                                  errors[item.titleName]
                                }
                              />
                            );
                          }}
                        </Field>
                      )}
                      {item.component === "phone-number" && (
                        <Field name={item.titleName}>
                          {({ field, form }) => {
                            const isError = Boolean(
                              form.touched[item.titleName] &&
                                form.errors[item.titleName]
                            );

                            console.log(item.titleName, "snsfnsfjksnfnsjn");
                            return (
                              <>
                                <CommonPhoneInput
                                  placeholder={item?.placeholder}
                                  padding="14px 0px 14px 60px"
                                  country="us"
                                  value={values[item.titleName]}
                                  onChange={(e, countryData) => {
                                    setFieldValue(item.country, countryData);
                                    setFieldValue(item.titleName, e);
                                  }}
                                />
                                {isError && (
                                  <ErrorContent
                                    margin="-2px 0px 0px"
                                    className="Mui-error"
                                  >
                                    {form.errors[item.titleName]}
                                  </ErrorContent>
                                )}
                              </>
                            );
                          }}
                        </Field>
                      )}
                    </CommonGrid2>
                  ))}
                </Grid2>

                <CommonButton
                  value={addMemberDialog.index === "" ? "Add" : "Update"}
                  width={"100%"}
                  padding={"10px"}
                  type="submit"
                  marginTop={"1.5rem"}
                  disabled={addMemberDialog.loading}
                  loading={addMemberDialog.loading}
                />
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
