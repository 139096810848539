import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DotsGrid from "../../../../assets/icons/DotsGrid";
import CustomLinearProgress from "../../../../component/Linearprogress";
import {
  ErrorFound,
  getColor,
  HandleSkeletion,
} from "../../../../utils/constants";
import {
  DotColor,
  StatusChange,
  StyledFlexRowView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  StyledTypoGrey,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";

function Results({
  SummaryJobData,
  tableLoader,
  resultHeaderData,
  lock,
  onDragEnd,
  dragContent,
}) {
  return (
    <TableContainerBorder marginTop={"0px"}>
      <Table>
        <TableHead>
          <TableRow>
            {resultHeaderData.map((item, index) => (
              <StyledTableTitleTableCell>{item}</StyledTableTitleTableCell>
            ))}
          </TableRow>
        </TableHead>
        {tableLoader && (
          <>
            {Array.from({ length: 2 }).map((_, index) => (
              <TableRow>
                {Array.from({ length: resultHeaderData.length }).map(
                  (_, index) => (
                    <TableCell key={index}>
                      <HandleSkeletion height={30} />
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-candidates">
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {dragContent.length !== 0 &&
                  dragContent.map((candidate, index) => (
                    <Draggable
                      key={`candidate-${index}`}
                      draggableId={`candidate-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <TableCell style={{ width: "50vw" }}>
                            {tableLoader ? (
                              <HandleSkeletion height={30} />
                            ) : (
                              <Typography
                                fontSize={"15px"}
                                fontWeight={500}
                                color={"#101828"}
                                fontFamily={"Inter"}
                              >
                                {candidate.candidate_name}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell style={{ width: "20vw" }}>
                            {tableLoader ? (
                              <HandleSkeletion height={30} />
                            ) : (
                              <StatusChange>
                                <DotColor color={getColor(candidate.score)} />
                                {candidate.status}
                              </StatusChange>
                            )}
                          </TableCell>
                          <TableCell style={{ width: "20vw" }}>
                            {tableLoader ? (
                              <HandleSkeletion height={30} />
                            ) : (
                              <StyledFlexRowView sx={{ alignItems: "center" }}>
                                <CustomLinearProgress
                                  variant="determinate"
                                  value={candidate.score}
                                  height={"10px"}
                                  borderRadius={"5px"}
                                  barbackgroundColor={getColor(candidate.score)}
                                />

                                <StyledTypoGrey>{`${candidate.score}%`}</StyledTypoGrey>
                              </StyledFlexRowView>
                            )}
                          </TableCell>
                          <StyledTableBodyTableCell
                            textAlign={"center"}
                            style={{ width: "10vw" }}
                          >
                            {tableLoader ? (
                              <HandleSkeletion height={30} />
                            ) : (
                              <IconButton
                                {...provided.dragHandleProps}
                                disabled={
                                  lock?.content === "UnLock" || lock?.loading
                                    ? true
                                    : false
                                }
                                style={{
                                  padding: "0px",
                                  opacity:
                                    lock?.content === "UnLock" || lock?.loading
                                      ? 0.3
                                      : 1,
                                }}
                              >
                                <DotsGrid />
                              </IconButton>
                            )}
                          </StyledTableBodyTableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
      {SummaryJobData?.results?.candidates?.length === 0 && !tableLoader && (
        <ErrorFound title={"No data Found..."} />
      )}
    </TableContainerBorder>
  );
}

export default Results;
