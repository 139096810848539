import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  MenuItem,
  TableCell,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StatusChangeIco from "../../assets/icons/StatusChangeIco";
import CommonButton from "../../component/Button";
import CommonTextField from "../../component/Input";
import DropDownSelect from "../../component/Select";
import SnackBar from "../../component/SnackBar";
import ToogleButton from "../../component/ToogleButton";
import {
  GetJobList,
  TableStatusChange,
  TableStatusChangeList,
} from "../../redux/action/Job";
import CommonTable from "../candidates/Table";
import { CustomDiv } from "../chat-bot/style";
import FilterDialog from "../common-components/FilterDialog";
import { CommonGrid2, DotColor } from "../common-components/StyledComponents";
import DialogCandidateHub from "./jobs-candidate/components/DialogCandidateHub";
import { CustomizeChip, CustomizeSpan } from "./style";

export default function Jobs() {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const pagination = query.get("pagination");
  const [filterPopup, setFilterPopup] = useState(false);
  const [alignment, setAlignment] = React.useState("");
  const [jobList, setJobList] = useState({
    data: [],
    currentPage: 1,
    totalPage: "",
    loading: true,
    filter: [],
    direction: "asc",
    columnName: "",
    status: false,
  });
  const [searchJob, setSearchJob] = useState({
    search: "",
    suggestion: [],
    showSuggestion: false,
  });
  const [statusDialog, setStatusDialog] = useState({
    open: false,
    disabled: false,
    noLoading: false,
    yesLoading: false,
    value: "",
    id: "",
  });
  const [checkSelected, setCheckSelected] = useState({
    count: 0,
    selected: [],
  });

  const [selectDialog, setSelectDialog] = useState({
    open: false,
    disabled: false,
    changeStatusLoading: false,
    cancelLoading: false,
    changeLoading: false,
    value: "Active",
    id: "",
    condition: true,
  });
  const suggestionsRef = useRef(null);
  const limit = 10;
  const [buttonLoading, setButtonLoading] = useState({
    disabled: false,
    loading: false,
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const ToogleData = [
    { name: "Change Status", value: "Change Status", disabled: false },
    { name: "Share Job", value: "Share Job", disabled: true },
  ];
  const timeoutRef = useRef(null);
  const filterOptions = [
    {
      label: "Work Location Type",
      key: "Work Location Type",
      type: "multi-select",
      options: jobList.filter?.filters?.work_location_type || [],
    },
    {
      label: "Management Level",
      key: "Management Level",
      type: "multi-select",
      options: jobList.filter?.filters?.management_level || [],
    },
    {
      label: "Company Profile",
      key: "Company Profile",
      type: "multi-select",
      options: jobList.filter?.filters?.company_profile || [],
    },
    {
      label: "Status",
      key: "Status",
      type: "multi-select",
      options: jobList.filter?.filters?.status || [],
    },
  ];

  const [filterState, setFilterState] = useState(
    filterOptions.reduce((acc, option) => {
      acc[option.key] =
        option.type === "multi-select" ? [] : option.value || null;
      return acc;
    }, {})
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setSearchJob((prev) => ({
          ...prev,
          showSuggestion: false,
        }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchJobsList = async ({
    limit = 10,
    offset = (jobList.currentPage - 1) * limit,
    q = searchJob.search,
    filterValues = filterState,
    status_flag = false,
    columnName,
    direction,
    status = jobList.status,
  }) => {
    setJobList((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const filters = [
        {
          field: "company_profile.keyword",
          value: filterValues["Company Profile"] || "",
        },
        {
          field: "management_level.keyword",
          value: filterValues["Management Level"] || "",
        },
        {
          field: "work_location_type.keyword",
          value: filterValues["Work Location Type"] || "",
        },
        {
          field: "status.keyword",
          value: filterValues["Status"] || "",
        },
      ].filter((filter) => Object.values(filter).some((value) => value !== ""));
      let params = {
        limit: limit,
        offset: offset,
      };
      let payload = {
        q,
        filter: filters,
        sort: {
          order: direction,
          status: status,
          field: columnName,
        },
        status_flag: status_flag,
        organization: JSON.parse(
          localStorage.getItem("credential")
        )?.organization?.toLowerCase(),
      };

      const response = await GetJobList(payload, params);
      const data = response.data;
      setJobList((prev) => ({
        ...prev,
        data: data?.results || [],
        totalPage: data?.total_pages,
        loading: false,
        filter: data,
      }));
      setSearchJob((prev) => ({
        ...prev,
        suggestion: data.suggestions,
      }));
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setJobList((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  useEffect(() => {
    if (pagination !== "null" && pagination) {
      setJobList((prev) => ({
        ...prev,
        currentPage: parseInt(pagination),
      }));
    }
    fetchJobsList({
      limit,
      offset:
        pagination !== "null" && pagination
          ? (pagination - 1) * limit
          : (jobList.currentPage - 1) * limit,
    });
  }, []);

  const [headerData, setHeaderData] = useState([
    { name: "Unique ID", direction: "asc", sort: false, minWidth: "100px" },
    { name: "Req ID", direction: "asc", sort: true, key: "req_id.keyword" },
    { name: "Title", direction: "asc", sort: true, key: "job_title.keyword" },
    { name: "Status", direction: "asc", sort: false },
    { name: "Hourly Rate", direction: "asc", sort: false, minWidth: "130px" },
    {
      name: "Client",
      direction: "asc",
      sort: true,
      key: "company_name.keyword",
    },
    {
      name: "Created",
      direction: "desc",
      sort: true,
      minWidth: "130px",
      key: "created_at",
    },
    {},
  ]);

  const handleSearchInput = (e) => {
    const newValue = e.target.value;
    setJobList((prev) => ({
      ...prev,
      currentPage: 1,
    }));
    setSearchJob((prev) => {
      if (prev.search !== newValue) {
        handleDelay(newValue, 300);
      }
      return { ...prev, search: newValue };
    });
  };

  const handleSuggestionClick = (value) => {
    setJobList((prev) => ({
      ...prev,
      currentPage: 1,
    }));
    setSearchJob((prev) => ({
      ...prev,
      search: value,
      showSuggestion: false,
    }));

    handleDelay(value, 300);
  };

  const handleDelay = (value, delay) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      fetchJobsList({
        limit,
        offset: 0,
        status_flag: false,
        q: value,
      });
    }, delay);
  };

  const handleFilterClick = () => {
    setFilterPopup(true);
  };

  const handleClose = () => {
    setFilterPopup(false);
  };

  const handleIconClick = (data) => {
    navigate(
      `/jobs/jobs-view?id=${data.id}&title=${data.job_title}&pagination=${jobList.currentPage}`
    );
  };

  const CustomMenuItem = (item, index) => (
    <MenuItem key={index} value={item.id}>
      {item.name}
    </MenuItem>
  );

  console.log(jobList, "sflknsflskn ");

  const statusOptions = [
    { name: "Active", color: "#17B26A", id: "Active" },
    { name: "Hold", color: "orange", id: "Hold" },
    { name: "Filled", color: "#9E77ED", id: "Filled" },
    { name: "Draft", color: "#667085", id: "Draft" },
    { name: "Closed", color: "#F04438", id: "Closed" },
    { name: "Cancelled", color: "#F04438", id: "Cancelled" },
  ];

  const handleStatusOptions = (rowStatus) => {
    const options = [
      { name: "Active", color: "#17B26A", id: "Active" },
      { name: "Hold", color: "orange", id: "Hold" },
      { name: "Filled", color: "#9E77ED", id: "Filled" },
      { name: "Draft", color: "#667085", id: "Draft" },
      { name: "Closed", color: "#F04438", id: "Closed" },
      { name: "Cancelled", color: "#F04438", id: "Cancelled" },
    ];

    return options.filter(
      (option) =>
        !(rowStatus === "Active" && option.id === "Draft") &&
        !(
          rowStatus === "Filled" &&
          (option.id === "Active" ||
            option.id === "Hold" ||
            option.id === "Draft" ||
            option.id === "Cancelled" ||
            option.id === "Closed") &&
          !(
            rowStatus === "Cancelled" &&
            (option.id === "Active" ||
              option.id === "Hold" ||
              option.id === "Draft" ||
              option.id === "Filled" ||
              option.id === "Closed")
          ) &&
          !(
            rowStatus === "Closed" &&
            (option.id === "Active" ||
              option.id === "Hold" ||
              option.id === "Draft" ||
              option.id === "Cancelled" ||
              option.id === "Closed")
          )
        )
    );
  };

  const handleTableBodyData = (row, index) => {
    if (!row) return null;

    return (
      <>
        <TableCell>{row.unique_id || "-"}</TableCell>
        <TableCell>{row.req_id || "-"}</TableCell>
        <TableCell>{row.job_title || "-"}</TableCell>

        <TableCell>
          <DropDownSelect
            marginTop="0px"
            removeAnimation={true}
            data={handleStatusOptions(row.status)}
            valueChange={true}
            fullWidth={false}
            title={"Select Status"}
            CustomMenuItem={CustomMenuItem}
            handleRenderValue={(selected) => {
              const selectedOption = statusOptions.find(
                (option) => option.id === selected
              );
              return (
                <Box display={"flex"} alignItems={"center"}>
                  <DotColor color={selectedOption?.color} marginRight="8px" />
                  <Typography>{selectedOption?.name}</Typography>
                </Box>
              );
            }}
            customStyle={{
              "& .MuiSelect-select": {
                padding: "4px 32px 4px 10px !important",
                borderRadius: "6px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor:
                  row.status === "Active"
                    ? "#17B26A"
                    : row.status === "Hold"
                    ? "orange"
                    : row.status === "Filled"
                    ? "#9E77ED"
                    : row.status === "Draft"
                    ? "#667085"
                    : "#F04438",
                boxShadow: "0px 0px 1px 0px #101828",
                borderWidth: "1.5px",
              },
              "& .MuiSelect-icon": {
                right: "0rem",
              },
            }}
            width={"115px"}
            maxHeight={"215px"}
            value={row.status}
            handleChange={(e) => handleChangeStatus(e, row)}
          />
        </TableCell>
        <TableCell>{row.pay_rate || "-"}</TableCell>
        <TableCell>{row.client || "-"}</TableCell>
        <TableCell>{row.created_at ? row.created_at : "-"}</TableCell>
        <TableCell>
          <VisibilityOutlinedIcon
            onClick={() => handleIconClick(row)}
            style={{ cursor: "pointer" }}
          />
        </TableCell>
      </>
    );
  };

  const handleToogleChange = (event, newAlignment) => {
    if (event.target.value === "Change Status") {
      setSelectDialog((prev) => ({
        ...prev,
        open: true,
        value:
          checkSelected.count === 1
            ? checkSelected.selected[0]?.status
            : "Active",
      }));
      setAlignment(newAlignment);
    }
  };

  const handleChangeStatus = async (e, row) => {
    setStatusDialog((prev) => ({
      ...prev,
      open: true,
      value: e.target.value,
      id: row.id,
    }));
  };

  const handleStatusChange = async (condition) => {
    if (condition === "no") {
      setStatusDialog((prev) => ({
        ...prev,
        noLoading: true,
        disabled: true,
      }));
      setTimeout(() => {
        handleStatusClose();
      }, 1000);
    } else if (condition === "yes") {
      setStatusDialog((prev) => ({
        ...prev,
        yesLoading: true,
        disabled: true,
      }));
      try {
        let params = {
          status: statusDialog.value,
        };
        const data = await TableStatusChange(statusDialog.id, params);
        setSnackbarState({
          open: true,
          severity: "success",
          message: data.message,
        });
        handleStatusClose();
        await fetchJobsList({
          limit,
          offset: (jobList.currentPage - 1) * limit,
        });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error.message,
        });
      }
    }
  };
  const SatusFooter = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"No"}
        variant="outlined"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        color="#344054"
        border="#D0D5DD"
        disabled={statusDialog.disabled}
        loading={statusDialog.noLoading}
        onClick={() => handleStatusChange("no")}
      />
      <CommonButton
        value={"Yes"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={statusDialog.disabled}
        loading={statusDialog.yesLoading}
        onClick={() => handleStatusChange("yes")}
      />
    </CommonGrid2>
  );

  const handleChangeStatusBtn = async (condition) => {
    if (condition === "change-status") {
      setSelectDialog((prev) => ({
        ...prev,
        changeStatusLoading: true,
        disabled: true,
      }));
      setTimeout(() => {
        setSelectDialog((prev) => ({
          ...prev,
          disabled: false,
          condition: false,
        }));
      }, 1000);
    } else if (condition === "no") {
      setSelectDialog((prev) => ({
        ...prev,
        noLoading: true,
        disabled: true,
      }));
      setTimeout(() => {
        handleChangeStatusClose();
      }, 1000);
    } else if (condition === "change") {
      setSelectDialog((prev) => ({
        ...prev,
        changeLoading: true,
        disabled: true,
      }));
      try {
        let payload = {
          jobs: checkSelected.selected.map((item) => item.id),
        };

        const data = await TableStatusChangeList(payload);
        setSnackbarState({
          open: true,
          severity: "success",
          message: data.message,
        });
        handleChangeStatusClose();
        fetchJobsList({ limit, offset: (jobList.currentPage - 1) * limit });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error.message,
        });
      }
    }
  };

  const ChangeStatusFooter = () => (
    <>
      {selectDialog.condition ? (
        <CommonButton
          value={"Change Status"}
          width={"100%"}
          padding={"10px"}
          marginTop={"2rem"}
          fontWeight={"700"}
          fontSize={"15px"}
          disabled={selectDialog.disabled}
          loading={selectDialog.changeStatusLoading}
          onClick={() => handleChangeStatusBtn("change-status")}
        />
      ) : (
        <CommonGrid2 display={"flex"} gap={"1rem"}>
          <CommonButton
            value={"No"}
            variant="outlined"
            width={"100%"}
            padding={"10px"}
            marginTop={"2rem"}
            fontWeight={"700"}
            fontSize={"15px"}
            color="#344054"
            border="#D0D5DD"
            disabled={selectDialog.disabled}
            loading={selectDialog.noLoading}
            onClick={() => handleChangeStatusBtn("no")}
          />
          <CommonButton
            value={"Yes"}
            width={"100%"}
            padding={"10px"}
            marginTop={"2rem"}
            fontWeight={"700"}
            fontSize={"15px"}
            disabled={selectDialog.disabled}
            loading={selectDialog.changeLoading}
            onClick={() => handleChangeStatusBtn("change")}
          />
        </CommonGrid2>
      )}
    </>
  );

  const handleChangeStatusClose = () => {
    setSelectDialog({
      open: false,
      disabled: false,
      changeStatusLoading: false,
      cancelLoading: false,
      changeLoading: false,
      value: "Active",
      id: "",
      condition: true,
    });
  };

  const handleStatusClose = () => {
    setStatusDialog({
      open: false,
      disabled: false,
      noLoading: false,
      yesLoading: false,
      value: "",
      id: "",
    });
  };

  const handleDropdownChange = (key, value) => {
    const newState = { ...filterState, [key]: value };
    setFilterState(newState);
    setJobList((prev) => ({
      ...prev,
      currentPage: 1,
    }));
    fetchJobsList({
      limit,
      offset: 0,
      q: jobList.search,
      filterValues: newState,
    });
  };

  const countSelectedFields = () => {
    return Object.values(filterState).filter((value) =>
      Array.isArray(value) ? value.length > 0 : value != null
    ).length;
  };

  const selectedCount = countSelectedFields();

  const handleDateChange = (key, start, end) => {
    const newState = {
      ...filterState,
      [key]: {
        from_date: start,
        to_date: end || start,
      },
    };
    setJobList((prev) => ({
      ...prev,
      currentPage: 1,
    }));
    setFilterState(newState);
    fetchJobsList({
      limit,
      offset: 0,
      q: jobList.search,
      filterValues: newState,
    });
  };
  const handleApplyFilters = () => {
    setButtonLoading({
      loading: true,
      disabled: true,
    });
    fetchJobsList({ filterValues: filterState }).finally(() => {
      handleClose();
      setButtonLoading({
        loading: false,
        disabled: false,
      });
    });
  };

  const handleClearForm = () => {
    setFilterState(
      filterOptions.reduce((acc, option) => {
        acc[option.key] = option.type === "multi-select" ? [] : null;
        return acc;
      }, {})
    );
    setJobList((prev) => ({
      ...prev,
      currentPage: 1,
    }));
    fetchJobsList({
      limit,
      offset: 0,
      q: jobList.search,
      filterValues: [],
    });
  };

  const handleDeleteChip = (key, item) => {
    const newState = {
      ...filterState,
      [key]: filterState[key].filter((chip) => chip !== item),
    };
    setJobList((prev) => ({
      ...prev,
      currentPage: 1,
    }));

    setFilterState(newState);
    fetchJobsList({
      limit,
      offset: 0,
      q: jobList.search,
      filterValues: newState,
    });
  };

  const handleFocus = (e) => {
    setSearchJob((prev) => ({
      ...prev,
      showSuggestion: true,
    }));
  };

  const handleSearchInstand = () => {
    setJobList((prev) => ({
      ...prev,
      currentPage: 1,
    }));
    fetchJobsList({
      limit,
      offset: 0,
      q: jobList.filter?.current_quer,
      status_flag: true,
      filterValues: filterState || {},
    });
  };

  const handleSort = (columnName) => {
    const filterData = headerData.map((col) => {
      if (col.name === columnName.name) {
        return {
          ...col,
          direction: col.direction === "asc" ? "desc" : "asc",
        };
      }
      return col;
    });
    const isDataChanged = (headerData) => {
      return headerData.some((item) => {
        if (item.name === "Created") {
          return item.direction !== "desc";
        } else if (item.name) {
          return item.direction !== "asc";
        }
        return false;
      });
    };

    setHeaderData(filterData);
    setJobList((prev) => {
      return {
        ...prev,
        columnName: columnName.name,
        status: isDataChanged(filterData),
        direction: columnName.direction === "asc" ? "desc" : "asc",
      };
    });

    fetchJobsList({
      limit,
      offset: (jobList.currentPage - 1) * limit,
      q: jobList.search,
      filterValues: filterState,
      status_flag: false,
      columnName: columnName.key,
      status: isDataChanged(filterData),
      direction: columnName.direction === "asc" ? "desc" : "asc",
    });
  };

  const handleSingleSelect = (row) => {
    setCheckSelected((prev) => {
      const isSelected = prev.selected.some((item) => item.id === row.id);
      const updatedSelected = isSelected
        ? prev.selected.filter((item) => item.id !== row.id)
        : [
            ...prev.selected,
            {
              id: row.id,
              status: row.status,
              job_title: row.job_title,
            },
          ];

      const updatedCount = updatedSelected.length;

      return { selected: updatedSelected, count: updatedCount };
    });
  };

  const handleSelectAll = () => {
    const allIds = jobList.data
      .filter((row) => !["Filled", "Cancelled", "Closed"].includes(row.status))
      .map((row) => ({
        id: row.id,
        status: row.status,
        job_title: row.job_title,
      }));

    if (
      jobList.data
        .filter(
          (row) => !["Filled", "Cancelled", "Closed"].includes(row.status)
        )
        .every((row) =>
          checkSelected.selected.some((item) => item.id === row.id)
        )
    ) {
      const deselectedIds = checkSelected.selected.filter(
        (item) => !allIds.some((row) => row.id === item.id)
      );
      setCheckSelected({
        selected: deselectedIds,
        count: deselectedIds.length,
      });
    } else {
      const updatedSelected = [...checkSelected.selected, ...allIds];

      const uniqueSelected = Array.from(
        new Set(updatedSelected.map((item) => item.id))
      )
        .map((id) =>
          updatedSelected.find(
            (item) =>
              item.id === id &&
              !["Filled", "Cancelled", "Closed"].includes(item.status)
          )
        )
        .filter(Boolean);

      setCheckSelected({
        selected: uniqueSelected,
        count: uniqueSelected.length,
      });
    }
  };

  const handleContent = () => (
    <>
      {selectDialog.condition ? (
        <CommonGrid2
          display={"flex"}
          flexDirection={"column"}
          gap={"0.8rem"}
          textAlign={"left"}
          marginTop={"1rem"}
        >
          <Typography variant="h6" fontWeight={500}>
            Status
          </Typography>
          <DropDownSelect
            marginTop="0px"
            removeAnimation={true}
            data={handleStatusOptions(selectDialog.value)}
            value={selectDialog.value}
            valueChange={true}
            id={"change_status"}
            CustomMenuItem={CustomMenuItem}
            handleChange={(e) =>
              setSelectDialog((prev) => ({
                ...prev,
                value: e.target.value,
              }))
            }
          />
        </CommonGrid2>
      ) : (
        <CommonGrid2
          display={"flex"}
          textAlign={"start"}
          gap={"0.5rem"}
          flexDirection={"column"}
          marginTop={"1rem"}
        >
          <Typography variant="h5">Change Status?</Typography>
          <Typography variant="p">
            {" "}
            Do you want to Change the status{" "}
            {checkSelected.count === 1 && (
              <CustomizeSpan>
                from{" "}
                <CustomizeSpan fontWeight={700}>
                  {checkSelected.selected[0]?.status}
                </CustomizeSpan>
              </CustomizeSpan>
            )}{" "}
            to{" "}
            <CustomizeSpan fontWeight={700}>
              {selectDialog.value} ?
            </CustomizeSpan>
          </Typography>
        </CommonGrid2>
      )}
    </>
  );

  const handleCustomCheckBox = (checkSelected, row) => (
    <Checkbox
      color="primary"
      checked={checkSelected.some((item) => item.id === row.id)}
      onChange={() => {
        handleSingleSelect(row);
      }}
      disabled={["Filled", "Cancelled", "Closed"].includes(row.status)}
    />
  );

  const handleWholeCustomCheckBox = (data, checkSelected) => (
    <Checkbox
      color="primary"
      checked={
        data &&
        data.length !== 0 &&
        data?.every((row) =>
          (checkSelected || [])?.some((item) => item?.id === row?.id)
        )
      }
      disabled={
        data &&
        data.length !== 0 &&
        data?.every((row) =>
          ["Filled", "Cancelled", "Closed"].includes(row.status)
        )
      }
      onChange={handleSelectAll}
    />
  );

  return (
    <>
      <CommonGrid2 container alignItems="center" spacing={2}>
        <CommonGrid2
          item
          size={{ xs: 12, md: 6, sm: 6 }}
          display="flex"
          alignItems="center"
        >
          <Typography variant="h2">Jobs</Typography>
        </CommonGrid2>
        <CommonGrid2
          item
          size={{ xs: 12, md: 6, sm: 6 }}
          display="flex"
          alignItems="center"
          gap={"1rem"}
          position={"relative"}
        >
          <CommonTextField
            placeholder="Search"
            startIcon={<SearchIcon />}
            value={searchJob.search}
            onChange={handleSearchInput}
            handleFocus={handleFocus}

            // disabled={true}
          />
          {searchJob.showSuggestion && searchJob.suggestion.length > 0 && (
            <List
              ref={suggestionsRef}
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                marginTop: "2px",
                right: 0,
                background: "white",
                border: "1px solid #ccc",
                borderRadius: 1,
                zIndex: 1000,
                maxHeight: 200,
                overflowY: "auto",
                fontFamily: "Inter",
              }}
            >
              {searchJob.suggestion.map((sug, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => handleSuggestionClick(sug)}
                  sx={{
                    padding: "8px 16px",
                    cursor: "pointer",
                    backgroundColor:
                      searchJob.search === sug
                        ? "rgb(105 65 198 / 13%)"
                        : "transparent",
                    fontFamily: "Inter",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  {sug}
                </ListItem>
              ))}
            </List>
          )}
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Filters"
            onClick={() => handleFilterClick()}
            disabled={jobList.loading}
          />

          <CommonButton
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Add New"
            onClick={() => navigate("/create-jobs")}
          />
        </CommonGrid2>
      </CommonGrid2>
      {checkSelected.count !== 0 && (
        <CommonGrid2
          marginTop={"1.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontSize={"20px"} fontWeight={600}>
            Selected{" "}
            <CustomizeSpan color={"#667085"}>
              {" "}
              · {checkSelected.count}
            </CustomizeSpan>
          </Typography>

          <ToogleButton
            ToogleData={ToogleData}
            handleChange={handleToogleChange}
            alignment={alignment}
            border={"1px solid #D0D5DD"}
            borderRadius={"8px"}
            activeColor={"#344054"}
            color={"#344054"}
            activeBackgroundColor="#F9FAFB"
            backgroundColor="#FFFF"
            disabled={jobList.loading}
          />
        </CommonGrid2>
      )}
      {selectedCount !== 0 && (
        <CommonGrid2
          size={12}
          display="flex"
          alignItems="center"
          marginTop={"1.5rem"}
          gap={"0.5rem"}
          flexWrap={"wrap"}
        >
          <Typography variant="h3">Filter : </Typography>
          {Object.keys(filterState).map((filterKey) =>
            filterState[filterKey].map((item, index) => (
              <CustomizeChip
                key={index}
                label={`${filterKey} - ${item}`}
                onDelete={() => handleDeleteChip(filterKey, item)}
              />
            ))
          )}
        </CommonGrid2>
      )}

      {jobList.filter?.corrected_query && (
        <CustomDiv
          fontSize={"20px"}
          fontWeight={600}
          padding={"16px 0px 0px 0px"}
        >
          <CustomizeSpan color={"#000"}>
            {"These are results for "}
          </CustomizeSpan>
          <CustomizeSpan color={"#7f56d9"}>
            {jobList.filter?.corrected_query}
          </CustomizeSpan>
        </CustomDiv>
      )}
      {jobList.filter?.current_query &&
        jobList.filter?.corrected_query !== null && (
          <CustomDiv padding={"0px"} fontSize={"14px"}>
            <CustomizeSpan color={"#000"}>
              {"Search instead for "}
            </CustomizeSpan>
            <button
              style={{
                color: "#7f56d9",
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "inherit",
                textDecoration: "underline",
              }}
              onClick={() => handleSearchInstand()}
            >
              {jobList.filter?.current_query}
            </button>
          </CustomDiv>
        )}

      <CommonTable
        // marginTop="1rem"
        data={jobList.data}
        columns={headerData}
        onSortChange={handleSort}
        checkSelected={checkSelected.selected}
        handleTableBodyData={handleTableBodyData}
        handleCustomCheckBox={handleCustomCheckBox}
        handleWholeCustomCheckBox={handleWholeCustomCheckBox}
        withCheckbox={true}
        paginationProps={{
          count: jobList.totalPage,
          page: jobList.currentPage,
          onPageChange: (page) => {
            setJobList((prev) => ({
              ...prev,
              currentPage: page,
            }));
            fetchJobsList({
              limit,
              offset: (page - 1) * limit,
              filterValues: filterState,
            });
          },
        }}
        withPagination={true}
        handleSingleSelect={handleSingleSelect}
        handleSelectAll={handleSelectAll}
        loading={jobList.loading}
      />

      <FilterDialog
        open={filterPopup}
        onClose={handleClose}
        title="Filter"
        filterOptions={filterOptions}
        filterState={filterState}
        handleClearForm={handleClearForm}
        handleDropdownChange={handleDropdownChange}
        handleDateChange={handleDateChange}
        handleApplyFilters={handleApplyFilters}
        selectedCount={selectedCount}
        buttonLoading={buttonLoading}
        count={jobList.filter?.count}
      />

      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />

      <DialogCandidateHub
        open={statusDialog.open}
        handleButttonClose={handleStatusClose}
        // icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        footer={<SatusFooter />}
        title={"Do you want to confirm this"}
        titlepadding={"1rem 0px 0px"}
        // content={"Do you want to confirm this"}
      />
      <DialogCandidateHub
        open={selectDialog.open}
        handleButttonClose={handleChangeStatusClose}
        icon={<StatusChangeIco margin={"10px 0px 0px 10px"} />}
        footer={<ChangeStatusFooter />}
        title={
          checkSelected.count === 1 && checkSelected.selected[0]?.job_title
        }
        titlepadding={"0rem 0px 0px"}
        content={handleContent()}
      />
    </>
  );
}
