function FileAddIco() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3327 6.9987V4.53203C13.3327 3.41193 13.3327 2.85187 13.1147 2.42405C12.9229 2.04773 12.617 1.74176 12.2407 1.55002C11.8128 1.33203 11.2528 1.33203 10.1327 1.33203H5.86602C4.74591 1.33203 4.18586 1.33203 3.75803 1.55002C3.38171 1.74176 3.07575 2.04773 2.884 2.42405C2.66602 2.85187 2.66602 3.41193 2.66602 4.53203V11.4654C2.66602 12.5855 2.66602 13.1455 2.884 13.5733C3.07575 13.9497 3.38171 14.2556 3.75803 14.4474C4.18586 14.6654 4.74591 14.6654 5.86602 14.6654H7.99935M9.33268 7.33203H5.33268M6.66602 9.9987H5.33268M10.666 4.66536H5.33268M11.9993 13.9987V9.9987M9.99935 11.9987H13.9993"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default FileAddIco;
