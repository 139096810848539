export default function BoxEditIco() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#344054"
    >
      <path
        d="M9.16797 3.33417H5.66797C4.26784 3.33417 3.56777 3.33417 3.03299 3.60666C2.56259 3.84634 2.18014 4.22879 1.94045 4.6992C1.66797 5.23398 1.66797 5.93404 1.66797 7.33417V14.3342C1.66797 15.7343 1.66797 16.4344 1.94045 16.9691C2.18014 17.4396 2.56259 17.822 3.03299 18.0617C3.56777 18.3342 4.26784 18.3342 5.66797 18.3342H12.668C14.0681 18.3342 14.7682 18.3342 15.3029 18.0617C15.7734 17.822 16.1558 17.4396 16.3955 16.9691C16.668 16.4344 16.668 15.7343 16.668 14.3342V10.8342M6.66795 13.3342H8.0634C8.47105 13.3342 8.67488 13.3342 8.86669 13.2881C9.03675 13.2473 9.19932 13.18 9.34844 13.0886C9.51664 12.9855 9.66076 12.8414 9.94902 12.5531L17.918 4.58417C18.6083 3.89382 18.6083 2.77453 17.918 2.08417C17.2276 1.39382 16.1083 1.39382 15.418 2.08417L7.449 10.0531C7.16074 10.3414 7.01662 10.4855 6.91355 10.6537C6.82217 10.8028 6.75482 10.9654 6.714 11.1355C6.66795 11.3273 6.66795 11.5311 6.66795 11.9387V13.3342Z"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
