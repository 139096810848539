import React from "react";
import { Button, CircularProgress, styled } from "@mui/material";

const ButtonFilter = styled(Button)(
  ({
    border,
    fontWeight,
    background,
    color,
    padding,
    borderRadius,
    height,
    marginTop,
    boxShadow,
    width,
    variant,
    margin,
    minWidth,
    gap,
    hoverBackground,
    svg,
    customStyle,
    fontSize,
  }) => ({
    padding: padding || "5px 28px",
    fontWeight: fontWeight || 500,
    border: border && `1px solid ${border}`,
    background: background,
    color: color,
    borderRadius: borderRadius || "8px",
    height: height,
    margin: margin,
    marginTop: marginTop,
    boxShadow: boxShadow,
    width: width,
    fontSize: fontSize,
    gap: gap || "10px",
    "&:hover": {
      background: hoverBackground
        ? hoverBackground
        : variant === "outlined"
        ? "rgb(127, 86, 217)"
        : background,
      color: variant === "outlined" && "#fff",
    },
    ...(svg && {
      "&:hover svg path": {
        stroke: "#fff",
      },
    }),
    minWidth: minWidth,
    "&.Mui-disabled": {
      background:
        variant === "contained"
          ? background
          : variant === "outlined"
          ? "#F2F4F7"
          : background,
      color:
        variant === "contained"
          ? color
          : variant === "outlined"
          ? "#98A2B3"
          : color,
      opacity: variant === "outlined" ? 0.7 : 0.3,
    },
    ...customStyle,
  })
);

const CommonButton = ({
  value,
  variant = "contained",
  color = "primary",
  onClick,
  startIcon,
  endIcon,
  fontWeight,
  border,
  background,
  padding,
  marginTop,
  disabled = false,
  boxShadow,
  width,
  margin,
  type = "button",
  loading,
  borderRadius,
  minWidth,
  height,
  gap,
  hoverBackground,
  svg = false,
  customStyle,
  fontSize,
}) => {
  return (
    <ButtonFilter
      variant={variant}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      fontWeight={fontWeight}
      border={border}
      background={background}
      padding={padding}
      marginTop={marginTop}
      disabled={disabled}
      boxShadow={boxShadow}
      width={width}
      type={type}
      margin={margin}
      borderRadius={borderRadius}
      minWidth={minWidth}
      gap={gap}
      height={height}
      hoverBackground={hoverBackground}
      svg={svg}
      customStyle={customStyle}
      fontSize={fontSize}
    >
      {disabled && loading && (
        <CircularProgress size={24} color="inherit" thickness={5} />
      )}
      {value}
    </ButtonFilter>
  );
};

export default CommonButton;
