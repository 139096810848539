import { CANDIDATE } from "../Action";

const intialState = {
  candidateViewData: {},
};
const CandidateReducer = (state = intialState, action) => {
  switch (action.type) {
    case CANDIDATE.candidateView:
      return { ...state, candidateViewData: action.data };
    default:
      return state;
  }
};
export default CandidateReducer;
