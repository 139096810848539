import { Grid2, Typography } from "@mui/material";
import React from "react";
import OwnerManagerDetails from "./OwnerManagerDetails";
import DistributionList from "./DistributionList";
import InterviewSettings from "./InterviewSettings";
import SeededPreScreen from "./SeededPreScreen";

function ConfirmSettings() {
  return (
    <>
      <Grid2 container spacing={5}>
        <Grid2 size={12}>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 600, color: "#101828" }}
          >
            {"Confirm Settings"}
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          <OwnerManagerDetails />
        </Grid2>
        <Grid2 size={12}>
          <DistributionList />
        </Grid2>
        <Grid2 size={12}>
          <InterviewSettings />
        </Grid2>

        <Grid2 size={12}>
          <SeededPreScreen />
        </Grid2>
      </Grid2>
    </>
  );
}

export default ConfirmSettings;
