import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import BoxEditIco from "../../../assets/icons/BoxEditIco";
import DeleteIco from "../../../assets/icons/DeleteIco";
import CommonButton from "../../../component/Button";
import {
  CommonGrid2,
  StyledFlexColumnView,
} from "../../common-components/StyledComponents";
import { CustomPaper } from "../../resume-intelligence/create-candidate-resume/components/Style";
import { HandleSkeletion } from "../../../utils/constants";

function Experience({
  data,
  title,
  loading,
  handleExperienceEdit,
  handleExperienceDelete,
  form,
  view_resume,
}) {
  return loading ? (
    <HandleSkeletion height={80} />
  ) : (
    <Box>
      {data.map((item, index) => {
        return (
          <CustomPaper
            variant="outlined"
            marginBottom="10px"
            display={"flex"}
            alignItems={"center"}
            padding={"12px"}
          >
            <CommonGrid2
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                {/* <CustomImg
                src={item.icon}
                width={"48px"}
                height={"48px"}
                alt="logo"
              /> */}
                <StyledFlexColumnView gap={"4px"}>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"#101828"}
                  >
                    {item?.job_title}
                  </Typography>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={400}
                    color={"#475467"}
                  >
                    {item?.company} &nbsp;&nbsp;&nbsp;
                    {item?.start_date}
                    {item?.start_date && item?.end_date && " - "}
                    {item?.end_date}
                  </Typography>
                </StyledFlexColumnView>
              </Stack>

              <CommonGrid2
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"10px"}
              >
                <CommonButton
                  variant="outlined"
                  color="#344054"
                  borderRadius={"6px"}
                  padding={"11px"}
                  border="#D0D5DD"
                  fontWeight="600"
                  minWidth="auto"
                  svg={true}
                  disabled={
                    item?.accomplishments_summary !== undefined || view_resume
                  }
                  value={<BoxEditIco />}
                  onClick={() => handleExperienceEdit(item, index, form)}
                />

                <CommonButton
                  variant="outlined"
                  color="#344054"
                  borderRadius={"6px"}
                  padding={"11px"}
                  border="#D0D5DD"
                  fontWeight="600"
                  minWidth="auto"
                  value={<DeleteIco />}
                  svg={true}
                  disabled={
                    item?.accomplishments_summary !== undefined || view_resume
                  }
                  onClick={() =>
                    handleExperienceDelete(title, item, index, form)
                  }
                />
              </CommonGrid2>
            </CommonGrid2>
          </CustomPaper>
        );
      })}
    </Box>
  );
}

export default Experience;
