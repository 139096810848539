import { Link, Typography } from "@mui/material";
import React from "react";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import { HandleSkeletion } from "../../../utils/constants";
import { PrimaryDiv } from "../../jobs/style";

const CommonCard = ({
  viewData,
  loading = false,
  rowSize = false,
  viewMarginTop,
}) => {
  return (
    <CommonGrid2
      container
      columnSpacing={2}
      rowSpacing={loading ? 0 : 2}
      marginTop={viewMarginTop}
    >
      {viewData?.map((item, index) => {
        return (
          <CommonGrid2
            item
            size={{
              xs: 12,
              md: rowSize ? 6 : 4,
              sm: 6,
            }}
            key={index}
          >
            {loading ? (
              <HandleSkeletion height={100} />
            ) : (
              <PrimaryDiv>
                <Typography
                  variant="p"
                  color="#475467"
                  fontSize="16px"
                  display={"flex"}
                  gap={"10px"}
                >
                  {item.title}
                  {item.iconVisible && item.content}
                </Typography>

                {item.link && (
                  <Link
                    href={
                      item.link.startsWith("http")
                        ? item.link
                        : `https://${item.link}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      wordWrap: "break-word",
                    }}
                  >
                    {item.link}
                  </Link>
                )}
                {item.name && (
                  <Typography
                    variant="h4"
                    fontWeight={600}
                    fontSize={"18px"}
                    display="flex"
                    alignItems="center"
                    gap="10px"
                    style={{ wordBreak: "break-word" }}
                  >
                    {item.image && <img src={item.image} />}
                    {item.name}
                  </Typography>
                )}
              </PrimaryDiv>
            )}
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
};
export default CommonCard;
