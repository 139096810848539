import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import SkillImage from "../../../../assets/images/SkilImage.png";
import CommonButton from "../../../../component/Button";
import CommonTextField from "../../../../component/Input";
import DropDownSelect from "../../../../component/Select";
import ToogleButton from "../../../../component/ToogleButton";

const AddNewSkillDialog = ({
  open,
  handleClose,
  dialogData,
  handleEditSkillChange,
  handleSkillClick,
  buttonLoading,
  criticalityData,
}) => {
  const validationSchema = Yup.object({
    skill: Yup.string()
      .required("Skill is required")
      .matches(/^[^\s].*$/, "Skill cannot start with spaces")
      .test("no-whitespace", "Skill cannot be just whitespace", (value) => {
        return value && value.trim().length > 0;
      }),
    experience_in_yrs: Yup.number()
      .min(0, "Experience must be a positive number")
      .required("Experience is required")
      .typeError("Experience must be a number"),
  });

  const initialValues = {
    skill: "",
    experience_in_yrs: "",
    classification: "User Added",
    key: dialogData?.key || "",
    category: "nice to have",
    ...(dialogData?.id && { id: dialogData?.id }),
  };

  const handleSubmit = (values, form) => {
    handleSkillClick(values, form);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth={"xs"} onClose={handleClose}>
        <img
          src={SkillImage}
          style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
        />
        <DialogActions>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogTitle>
          <Typography variant="h3" sx={{ mt: 2 }}>
            {dialogData.title}{" "}
            {dialogData.key === "extracted_hard_skills" ? "Hard" : "Soft"} Skill
          </Typography>
        </DialogTitle>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
            console.log(values, "slknfkslnfk");
            return (
              <Form>
                <DialogContent>
                  <Grid2 gap="20px" display="flex" flexDirection="column">
                    {dialogData?.classification &&
                      dialogData.key === "extracted_hard_skills" && (
                        <Grid2>
                          <Typography
                            variant="p"
                            color="#344054"
                            fontSize={14}
                            fontFamily={"Inter"}
                            fontWeight={500}
                            marginBottom={"5px"}
                          >
                            Classification
                          </Typography>
                          <DropDownSelect
                            title="Classification"
                            data={dialogData.classificationData}
                            value={values.classification}
                            handleChange={(e) =>
                              setFieldValue("classification", e.target.value)
                            }
                          />
                          {errors.classification && touched.classification && (
                            <Typography color="error" variant="body2">
                              {errors.classification}
                            </Typography>
                          )}
                        </Grid2>
                      )}

                    <Grid2>
                      <Typography
                        variant="p"
                        color={
                          errors.skill && touched.skill ? "error" : "#344054"
                        }
                        fontSize={14}
                        fontFamily={"Inter"}
                        fontWeight={500}
                        marginBottom={"5px"}
                      >
                        Skill
                      </Typography>
                      <Field
                        name="skill"
                        component={CommonTextField}
                        padding={"10px"}
                        border={
                          errors.skill && touched.skill && "1px solid #d32f2f"
                        }
                        title="Select title"
                        value={values.skill}
                        onChange={(e) => {
                          console.log(e.target.value, "sfkhsklgfghn");
                          setFieldValue("skill", e.target.value);
                        }}
                        sx={{
                          border: "1px solid red",
                        }}
                      />
                      {errors.skill && touched.skill && (
                        <Typography color="error" variant="body2">
                          {errors.skill}
                        </Typography>
                      )}
                    </Grid2>

                    <Grid2 display={"flex"} flexDirection={"column"}>
                      <Typography
                        variant="p"
                        color="#344054"
                        fontSize={14}
                        fontFamily={"Inter"}
                        fontWeight={500}
                        marginBottom={"0.8rem"}
                      >
                        Criticality
                      </Typography>
                      <ToogleButton
                        alignment={values.category}
                        padding={"5px 10px"}
                        ToogleData={criticalityData}
                        handleChange={(event, newAlignment) =>
                          setFieldValue("category", newAlignment)
                        }
                        gap={"10px"}
                        fontSize={"12px"}
                        border={"none !important"}
                        borderRadius={"5px !important"}
                        activeColor={"#FFFF"}
                        color={"#667085"}
                        activeBackgroundColor={"#7F56D9"}
                        backgroundColor={"#EAECF0 !important"}
                      />
                    </Grid2>

                    <Grid2>
                      <Typography
                        variant="p"
                        color={
                          errors.experience_in_yrs && touched.experience_in_yrs
                            ? "error"
                            : "#344054"
                        }
                        fontSize={14}
                        fontFamily={"Inter"}
                        fontWeight={500}
                        marginBottom={"5px"}
                      >
                        Years
                      </Typography>
                      <Field
                        name="experience_in_yrs"
                        component={CommonTextField}
                        padding={"10px"}
                        title="Select title"
                        value={values.experience_in_yrs}
                        border={
                          errors.experience_in_yrs &&
                          touched.experience_in_yrs &&
                          "1px solid #d32f2f"
                        }
                        onChange={(e) =>
                          setFieldValue(
                            "experience_in_yrs",
                            e.target.value.trim()
                          )
                        }
                      />
                      {errors.experience_in_yrs &&
                        touched.experience_in_yrs && (
                          <Typography color="error" variant="body2">
                            {errors.experience_in_yrs}
                          </Typography>
                        )}
                    </Grid2>

                    <CommonButton
                      variant="contained"
                      color="white"
                      background="#7F56D9"
                      fontWeight="600"
                      value={`Add
                       ${
                         dialogData.key === "extracted_hard_skills"
                           ? "Hard"
                           : "Soft"
                       } Skill`}
                      padding="8px"
                      type="submit"
                      disabled={buttonLoading}
                      loading={buttonLoading}
                    />
                  </Grid2>
                </DialogContent>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddNewSkillDialog;
