import React from "react";

const TickIcon = ({ color }) => {
  return (
    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.096 0.389671L3.93601 7.29967L2.03602 5.26967C1.68602 4.93967 1.13602 4.91967 0.736015 5.19967C0.346015 5.48967 0.236015 5.99967 0.476015 6.40967L2.72601 10.0697C2.94601 10.4097 3.32601 10.6197 3.75601 10.6197C4.16601 10.6197 4.55601 10.4097 4.77601 10.0697C5.13601 9.59967 12.006 1.40967 12.006 1.40967C12.906 0.489671 11.816 -0.320329 11.096 0.379671V0.389671Z"
        fill={color}
      />
    </svg>
  );
};

export default TickIcon;
