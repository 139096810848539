import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AccordionDetails,
  AccordionSummary,
  Grid2,
  IconButton,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  addJdNotes,
  deleteJdNote,
  fixedJob,
  getJdNotes,
  getJobDataDetail,
  updateJdNote,
} from "../../../../api/Api";
import CandidateHub from "../../../../assets/icons/CandidateHub";
import JobPencilIco from "../../../../assets/icons/JobPencilIco";
import JobPenIco from "../../../../assets/icons/JobPenIco";
import JobStarIco from "../../../../assets/icons/JobStarIco";
import PopupTickIco from "../../../../assets/icons/PopupTickIco";
import Image from "../../../../assets/images/JobPrimary.png";
import CommonButton from "../../../../component/Button";
import SnackBar from "../../../../component/SnackBar";
import ToogleButton from "../../../../component/ToogleButton";
import {
  JobCreateFormikData,
  JobIndustryScoreSubmit,
  JobJobEdit,
  JobJobReScore,
  JobJobShare,
  JobJobSubmit,
} from "../../../../redux/action/Job";
import { DELETE, GET, POST, PUT } from "../../../../redux/axios/Axios";
import { HandleSkeletion } from "../../../../utils/constants";
import CreateNote from "../../../common-components/CreateNote";
import NewDeleteNote from "../../../common-components/NewDeleteNote";
import {
  CommonGrid2,
  CustomAccordion,
  CustomTooltip,
} from "../../../common-components/StyledComponents";
import ShareQuickMatch from "../../../quick-match/components/ShareQuickMatch";
import AddNewSkillDialog from "../../job-information/Dialog";
import Notes from "../../job-information/notes";
import PrimaryInformation from "../../job-information/primary-information";
import RelevancyTable from "../../job-information/RelevancyTable";
import Result from "../../job-information/result";
import SecondaryInformation from "../../job-information/secondary-information";
import Skills from "../../job-information/skills";
import SkillDeleteDialog from "../../job-information/skills/SkillDeleteDialog";
import DialogCandidateHub from "../../jobs-candidate/components/DialogCandidateHub";
import { CustomizeSpan } from "../../style";
import AddSeededDialog from "./AddSeededDialog";
import InterviewSettings from "./InterviewSettings";
import SeededPreScreen from "./SeededPreScreen";
import { CustomDiv } from "../../../chat-bot/style";
import CopyIco from "../../../../assets/icons/CopyIco";

const ReviewJobPost = ({ jobId }) => {
  const ToogleData = [
    { name: "View", value: "View" },
    { name: "Edit", value: "Edit" },
  ];
  const [alignment, setAlignment] = useState("View");
  const [skillDialog, setSkillDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    skill: "",
    experience_in_yrs: "",
    criticality_score: "",
    title: "Create",
    classificationData: [],
    classification: false,
    classificationDataValue: "",
    key: "",
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const JobJobEditData = useSelector((state) => state.jobs.jobJobEdit);
  const CreateEditData = useSelector((state) => state.jobs.jobCreateEditData);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState({});
  const [jobData, setJobData] = useState({});
  const [noteList, setNoteList] = useState({
    open: false,
    laoding: false,
    noteId: "",
    type: "",
    note: "",
    created_at: "",
    job_history_reason: "",
    accecpting_less_more_candidates: "",
    locals_only: "",
    hybrid_days: "",
    project_scope: "",
    other_details: "",
    deadline_start: "",
    deadline_end: "",
  });
  const typeData = [
    { name: "Standard", id: "Blank" },
    { name: "Supplier Call", id: "Supplier Call" },
  ];
  const [deleteNoteDialogOpen, setDeleteNoteDialogOpen] = useState(false);
  const [notesTableList, setNotesTableList] = useState([]);
  const [notesTableListLoading, setNotesTableListLoading] = useState(false);
  const tableContainerRef = React.useRef(null);
  let dates = moment(new Date());
  let today = dates.format("L");
  const [seededDialog, setSeededDialog] = useState({
    deleteOpen: false,
    loading: false,
    values: "",
    index: "",
    content: "",
    addOpen: false,
  });
  const [iconVisible, setIconVisible] = useState(false);
  const navigate = useNavigate();
  const [fixLoader, setFixLoader] = useState(false);
  const value = (value) => (value ? value : "-");
  const [reScore, setReScore] = useState({
    disabled: false,
    noLoading: false,
    yesLoading: false,
    noteOpen: false,
  });

  const [activate, setActivate] = useState({
    inviteOpen: false,
    shareLoading: false,
    inviteLoading: false,
    activateOpen: false,
    backtoJobsLoading: false,
    disabled: false,
    candidateLoading: false,
  });

  const [saveEditData, setSaveEditData] = React.useState({
    open: false,
    prevData: "",
    leaveLoading: false,
    disabled: false,
    saveLoading: false,
    view: false,
  });

  const [buttonLoader, setButtonLoader] = useState({
    disabled: false,
    rescoreLoading: false,
    activateLoading: false,
    submitLoading: false,
  });

  const [activeButtonDisable, setActivateButtonDisable] = useState(false);

  const statusOptions = [
    {
      name: "Internal Recruiter",
      value: "internal_recruiter",
    },
    { name: "External Recruiter", value: "external_recruiter" },
    { name: "External vendor", value: "external_vendor" },
  ];

  const jobModifyData = {
    job_title: "",
    company_name: "",
    industry: [],
    available_industry: [],
    pay_rate: {
      max: "",
      min: "",
      currency: "USD",
    },
    management_level: "",
    available_management_level: [],
    employment: "",
    available_employment_types: [],
    Date_Range: {
      start_date: "",
      end_date: "",
    },
    criticalityHardData: [
      { name: "Familiar", value: "Familiar" },
      { name: "Hands-on", value: "Hands-on" },
      { name: "Expert", value: "Expert" },
    ],
    criticalitySoftData: [
      { name: "Novice", value: "Novice" },
      { name: "Proficient", value: "Proficient" },
      { name: "Expert", value: "Expert" },
    ],
    priorityData: [
      {
        name: "Medium",
        value: "Additional Qualities",
        key: "medium_priority",
      },
      {
        name: "High",
        value: "Complementary Skills",
        key: "low_priority",
      },
      {
        name: "Highest",
        value: "Core Skills",
        key: "high_priority",
      },
    ],
    skillType: [
      { name: "Hard Skill", value: "extracted_hard_skill" },
      { name: "Soft Skill", value: "extracted_soft_skill" },
    ],
    occupational_classification: "",
    available_occupational_classification: [],
    employeeTarget: "",

    autoCompleteValue: "",
    id: "",
    reports_to: "",
    req_id: "",
    bu_department_team: "",
    company_profile: "",
    available_company_profiles: [],
    education: "",
    specific_degree: "",
    available_education: "",
    dei: false,
    govt_security_clearance: false,
    languages: [],
    certificates: [],
    certificates_value: "",
    employer_targets: [],
    employer_targets_value: "",
    location: "",
    work_location_type: "",
    languages_value: "",
    location_targets: [],
    location_targets_value: "",
    shift: [],
    shift_value: "",
    skillData: {
      low_priority: [],
      high_priority: [],
      recommended: [],
      medium_priority: [],
    },
    score_certificates: false,
    score_education: false,
    score_employer_targets: false,
    score_hard_skills: false,
    score_languages: false,
    score_location_targets: false,
    score_soft_skills: false,
    score_visa: false,
    relevancy_index: [],
    interview_type: [],
    pre_screen_questions: [],
    available_visa_status: [],
    visa_status: [],
    relevancy_index_anomoly: "",
    industry_anomoly: "",
    company_profile_anomoly: "",
    management_level_anomoly: "",
    occupational_classification_anomoly: "",
    pay_anomoly: "",
    enable_dynamic_pre_screen_questions: false,
  };
  const [jobValue, setJobValue] = useState(jobModifyData);
  const [jobValidationCheck, setJobValidationCheck] = useState(jobModifyData);
  const [generateSearch, setGenerateSearch] = useState({
    open: false,
  });

  const [noteHeaderData, setNoteHeaderData] = useState([
    { name: "Title", direction: "asc", sort: false, width: "50%" },
    { name: "Created By", direction: "asc", sort: false, width: "20%" },
    { name: "Date", direction: "asc", sort: false, width: "15%" },
    { width: "15%" },
  ]);

  const handleToogleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      // if (newAlignment === "Edit" && jobData?.unscored) {
      //   setReScore((prev) => ({
      //     ...prev,
      //     rescore: true,
      //     reRunOpen: false,
      //     file: false,
      //   }));
      if (
        JSON.stringify(CreateEditData.formikData) !==
        JSON.stringify(CreateEditData.jobData)
      ) {
        setSaveEditData((prev) => ({
          ...prev,
          open: true,
          prevData: newAlignment,
          view: false,
        }));
      } else setAlignment(newAlignment);
    }
  };

  const noteUpperData = [
    {
      title: "Client",
      component: "input",
      fontSize: "15px",
      value: jobValue?.company_name,
      placeholderFontSize: "15px",
      placeholder: "Enter the name",
    },
    {
      title: "Date",
      component: "input",
      fontSize: "15px",
      value: moment(today).format("DD MMM YYYY"),
      placeholderFontSize: "15px",
      placeholder: "Enter the number",
    },
    {
      title: "Position",
      fontSize: "15px",
      value: jobValue?.job_title,
      placeholderFontSize: "15px",
      placeholder: "Enter H1B Holder Company Name",
    },
    {
      title: "Job ID",
      fontSize: "15px",
      value: jobValue?.req_id,
      placeholderFontSize: "15px",
      placeholder: "Enter H1B Holder Company Name",
    },
  ];

  // useEffect(() => {
  //   setJobValue(jobModifyData);
  //   if (inputJdView === 5) {
  //     fetchJobEdit("");
  //   } else fetchJobEdit(jobId);
  // }, []);

  const fetchJobDataDetail = async (jobId) => {
    try {
      setIsLoading(true);
      const res = await GET(getJobDataDetail(jobId));
      setJobData(res?.data);
      setIsLoading(false);
      handleRescoreClose();
      setExpandedIndex([]);
    } catch (error) {
      setIsLoading(false);
      handleRescoreClose();
      setExpandedIndex([]);
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.message,
      });
    }
  };

  useEffect(() => {
    // setExpandedIndex(accordionDataView.map((_, i) => i));
    if (alignment === "View") {
      // setIconVisible(true);
      fetchJobDataDetail(jobId);
      fetchJobEdit(jobId);
    } else if (alignment === "Edit") {
      setJobValue(jobModifyData);
      fetchJobEdit(jobId);
    }
  }, [alignment]);

  useEffect(() => {
    fetchNotesTableList(jobId);
  }, [jobId]);

  const handleDynmaicList = (data, image) => {
    return data?.flatMap((item) =>
      item?.content?.map((contentItem) => ({
        name: item?.name || "",
        content: contentItem,
        ...(image ? { image: image, name: contentItem } : {}),
      }))
    );
  };

  const handleDynmaicListFormat = (data) => {
    return (
      data?.map((item) => ({
        name: item || "",
      })) || []
    );
  };

  useEffect(() => {
    if (Object.keys(JobJobEditData).length !== 0) {
      const getValue = {
        ...jobValue,
        job_title: JobJobEditData.job_title,
        company_name: JobJobEditData.company_name,
        // industry: JobJobEditData.industry,
        industry: JobJobEditData.available_industry.filter((item) =>
          JobJobEditData.industry.some((check) => check === item.name)
        ),
        available_industry: JobJobEditData.available_industry,
        management_level: JobJobEditData.management_level,
        available_management_level: JobJobEditData.available_management_level,
        employment: JobJobEditData.employment,
        available_employment_types: JobJobEditData.available_employment_types,
        Date_Range: {
          start_date: JobJobEditData.start_date,
          end_date: JobJobEditData.end_date,
        },
        occupational_classification: JobJobEditData.occupational_classification,
        available_occupational_classification:
          JobJobEditData.available_occupational_classification,
        id: JobJobEditData.id,
        reports_to: JobJobEditData.reports_to,
        req_id: JobJobEditData.req_id,
        bu_department_team: JobJobEditData.bu_department_team,
        company_profile: JobJobEditData.company_profile,
        available_company_profiles: JobJobEditData.available_company_profiles,
        education: JobJobEditData.education,
        specific_degree: JobJobEditData.specific_degree,
        available_education: JobJobEditData.available_education,
        dei: JobJobEditData.dei,
        govt_security_clearance: JobJobEditData.govt_security_clearance,
        languages: handleDynmaicList(JobJobEditData.languages),
        certificates: handleDynmaicList(JobJobEditData.certificates),
        employer_targets: handleDynmaicList(JobJobEditData.employer_targets),
        location: JobJobEditData.location,
        work_location_type: JobJobEditData.work_location_type,
        location_targets: handleDynmaicList(JobJobEditData.location_targets),
        // location_targets: JobJobEditData.location_targets.map((item) => {
        //   return { content: item, id: "" };
        // }),
        shift:
          JobJobEditData.shift.length === 0
            ? []
            : JobJobEditData?.shift?.map((item) => {
                return { content: item, id: "" };
              }),
        pay_rate: {
          ...JobJobEditData.pay_rate,
          max: JobJobEditData.pay_rate.max,
          min: JobJobEditData.pay_rate.min,
          currency: JobJobEditData.pay_rate.currency || "USD",
        },
        skillData: {
          low_priority: JobJobEditData.low_priority,
          high_priority: JobJobEditData.high_priority,
          recommended: JobJobEditData.recommended,
          medium_priority: JobJobEditData.medium_priority,
        },
        score_certificates: JobJobEditData.score_certificates,
        score_education: JobJobEditData.score_education,
        score_employer_targets: JobJobEditData.score_employer_targets,
        score_hard_skills: JobJobEditData.score_hard_skills,
        score_languages: JobJobEditData.score_languages,
        score_location_targets: JobJobEditData.score_location_targets,
        score_soft_skills: JobJobEditData.score_soft_skills,
        score_visa: JobJobEditData.score_visa,
        relevancy_index: JobJobEditData.relevancy_index,
        interview_type: JobJobEditData.interview_type,
        pre_screen_questions: JobJobEditData.pre_screen_questions,
        available_visa_status: JobJobEditData.available_visa_status,
        visa_status: JobJobEditData.visa_status,
        relevancy_index_anomoly: JobJobEditData?.relevancy_index_anomoly,
        industry_anomoly: JobJobEditData?.industry_anomoly,
        company_profile_anomoly: JobJobEditData?.company_profile_anomoly,
        management_level_anomoly: JobJobEditData?.management_level_anomoly,
        occupational_classification_anomoly:
          JobJobEditData?.occupational_classification_anomoly,
        pay_anomoly: JobJobEditData?.pay_anomoly,
        enable_dynamic_pre_screen_questions:
          JobJobEditData?.enable_dynamic_pre_screen_questions,
      };
      setJobValue(getValue);
      setJobValidationCheck(getValue);
    }
  }, [JobJobEditData]);

  const handleAddIcon = (viewData, editData, condition) => {
    const data =
      alignment === "Edit"
        ? editData?.anomaly_detected !== "no"
        : viewData?.anomaly_detected !== "no";

    return data ? (
      <Tooltip
        title={
          <CommonGrid2
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
          >
            {alignment === "Edit" ? editData?.anomaly : viewData?.anomaly}
          </CommonGrid2>
        }
        arrow
        placement="top"
      >
        <CustomizeSpan
          cursor="pointer"
          position="relative"
          top={condition.top}
          left={condition.left}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
              fill="#FEF0C7"
            />
            <g clip-path="url(#clip0_1_29287)">
              <path
                d="M11.9997 9.33325V11.9999M11.9997 14.6666H12.0063M18.6663 11.9999C18.6663 15.6818 15.6816 18.6666 11.9997 18.6666C8.31778 18.6666 5.33301 15.6818 5.33301 11.9999C5.33301 8.31802 8.31778 5.33325 11.9997 5.33325C15.6816 5.33325 18.6663 8.31802 18.6663 11.9999Z"
                stroke="#DC6803"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_29287">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(4 4)"
                />
              </clipPath>
            </defs>
          </svg>
        </CustomizeSpan>
      </Tooltip>
    ) : (
      ""
    );
  };

  const validationSchema = Yup.object().shape({
    job_title: Yup.string().required("Title is required"),
    company_name: Yup.string().required("Company is required"),
    industry: Yup.array()
      .min(1, "At least one industry must be selected")
      .required("Industry is required"),
    management_level: Yup.string().required("Seniority level is required"),
    employment: Yup.string().required("Employment type is required"),
    location: Yup.string().required("Location is required"),

    skillData: Yup.object({
      high_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      medium_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      low_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
    }),
    pay_rate: Yup.object().shape({
      min: Yup.number()
        .typeError("Minimum pay rate must be a number")
        .min(0, "Minimum pay rate cannot be negative")
        .nullable(),
      max: Yup.number()
        .typeError("Maximum pay rate must be a number")
        .nullable()
        .when("min", (min, schema) =>
          min !== undefined && min !== null
            ? schema.moreThan(
                min,
                "Maximum pay rate must be greater than the minimum"
              )
            : schema
        ),
    }),

    checkcondition: Yup.boolean().test(
      "checkcondition",
      "At least one field (high, medium, or low priority) must have a value.",
      function (value, context) {
        const { high_priority, medium_priority, low_priority } =
          context.parent.skillData;
        return (
          (high_priority && high_priority.length > 0) ||
          (medium_priority && medium_priority.length > 0) ||
          (low_priority && low_priority.length > 0)
        );
      }
    ),
    Date_Range: Yup.object()
      .nullable()
      .shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
      })
      .test(
        "start-end-date-not-same",
        "Start date and End date cannot be the same",
        function (value) {
          if (!value?.start_date || !value?.end_date) {
            return true;
          }
          return value.start_date !== value.end_date;
        }
      ),

    location_targets: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          content: Yup.string(),
          id: Yup.mixed(),
        })
      )
      .test(
        "unique-content",
        "Location content must be unique",
        function (value) {
          if (!value) return true;
          const contentSet = new Set();
          for (let location of value) {
            if (location.content) {
              const lowercasedContent = location.content.toLowerCase();
              if (contentSet.has(lowercasedContent)) {
                return false;
              }
              contentSet.add(lowercasedContent);
            }
          }
          return true;
        }
      ),
    pre_screen_questions: Yup.array()
      .of(
        Yup.string()
          .test(
            "no-empty-string",
            "Empty strings are not allowed",
            (value) => value !== "" && value !== undefined
          )
          .required("This field is required")
      )
      .nullable()
      .optional(),
    // reports_to: Yup.string().required("Report_To is required"),
    // req_id: Yup.string().required("Req_Id is required"),
    // bu_department_team: Yup.string().required("BU/Dept/Team is required"),
    // company_profile: Yup.string().required("Req_Id is required"),
    // // education: Yup.string().required("Highest Level of Education is required"),
    // shift: Yup.array()
    //   .min(1, "Please select at least one option")
    //   .required("This field is required"),
    // location_targets: Yup.array()
    //   .min(1, "Please select at least one option")
    //   .required("This field is required"),
    // certificates: Yup.array()
    //   .min(1, "Please select at least one option")
    //   .required("This field is required"),
    // employer_targets: Yup.array()
    //   .min(1, "Please select at least one option")
    //   .required("This field is required"),
    // languages: Yup.array()
    //   .min(1, "Please select at least one option")
    //   .required("This field is required"),
    // pay_rate: Yup.object().shape({
    //   min: Yup.number()
    //     .required("Minimum pay rate is required")
    //     .typeError("Minimum pay rate must be a number")
    //     .min(0, "Minimum pay rate cannot be negative"),
    //   max: Yup.number()
    //     .required("Maximum pay rate is required")
    //     .typeError("Maximum pay rate must be a number")
    //     .moreThan(
    //       Yup.ref("min"),
    //       "Maximum pay rate must be greater than the minimum"
    //     ),
    // }),
    // Date_Range: Yup.object()
    //   .shape({
    //     start_date: Yup.string(),
    //     end_date: Yup.string(),
    //   })
    //   .test("Date Range is required", (value) => {
    //     return value?.start_date || value?.end_date;
    //   }),
    // interview_type: Yup.array().of(
    //   Yup.string().required("Interview touch is required")
    // ),
  });

  const validationSchemaScore = Yup.object().shape({
    skillData: Yup.object({
      high_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      medium_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      low_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
    }),
    pay_rate: Yup.object().shape({
      min: Yup.number()
        .typeError("Minimum pay rate must be a number")
        .min(0, "Minimum pay rate cannot be negative")
        .nullable(),
      max: Yup.number()
        .typeError("Maximum pay rate must be a number")
        .nullable()
        .when("min", (min, schema) =>
          min !== undefined && min !== null
            ? schema.moreThan(
                min,
                "Maximum pay rate must be greater than the minimum"
              )
            : schema
        ),
    }),
    Date_Range: Yup.object()
      .nullable()
      .shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
      })
      .test(
        "start-end-date-not-same",
        "Start date and End date cannot be the same",
        function (value) {
          if (!value?.start_date || !value?.end_date) {
            return true;
          }
          return value.start_date !== value.end_date;
        }
      ),

    location_targets: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          content: Yup.string(),
          id: Yup.mixed(),
        })
      )
      .test(
        "unique-content",
        "Location content must be unique",
        function (value) {
          if (!value) return true;
          const contentSet = new Set();
          for (let location of value) {
            if (location.content) {
              const lowercasedContent = location.content.toLowerCase();
              if (contentSet.has(lowercasedContent)) {
                return false;
              }
              contentSet.add(lowercasedContent);
            }
          }
          return true;
        }
      ),
    pre_screen_questions: Yup.array()
      .of(
        Yup.string()
          .test(
            "no-empty-string",
            "Empty strings are not allowed",
            (value) => value !== "" && value !== undefined
          )
          .required("This field is required")
      )
      .nullable()
      .optional(),
  });
  const [selectedValidation, setSelectedValidation] =
    useState(validationSchema);

  const handleInputChange = (e, title) => {
    const getValue = e.target.value;
    setJobValue((prev) => ({
      ...prev,
      [title]: getValue,
    }));
  };

  const handleListChange = (value, title) => {
    setJobValue((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleCurrencyChange = (event, name, currency) => {
    const getValue = event.target.value;
    const updatedPayRate = {
      ...jobValue[name],
      [currency]: getValue,
    };
    setJobValue((prev) => ({
      ...prev,
      pay_rate: {
        ...updatedPayRate,
      },
    }));
  };

  const handleCurrencyDropdownChange = (event, name, currencyValue) => {
    const exchangeRate = 83;
    function convertUsdToInr(usdValue) {
      return usdValue * exchangeRate;
    }

    function convertInrToUsd(inrValue) {
      return inrValue / exchangeRate;
    }

    const updatedPayRate = {
      ...jobValue,
      [currencyValue]: event.target.value,
      min:
        event.target.value === "INR"
          ? convertUsdToInr(jobValue[name]["min"])
          : convertInrToUsd(jobValue[name]["min"]),
      max:
        event.target.value === "INR"
          ? convertUsdToInr(jobValue[name]["max"])
          : convertInrToUsd(jobValue[name]["max"]),
    };
    setJobValue((prev) => ({
      ...prev,
      pay_rate: {
        ...updatedPayRate,
      },
    }));
  };

  const handleChangeSwitch = (name, value) => {
    setJobValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropdownChange = (event, name) => {
    const getValue = event.target.value;
    setJobValue((prev) => ({
      ...prev,
      [name]: getValue,
    }));
  };

  const handleAutoCompleteInputChange = (value, name, setFieldValue) => {
    setFieldValue(name, value);
    setJobValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAutoMultiChange = (e, newValue, name) => {
    setJobValue((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleAutoCompleteKeyDown = (
    e,
    index,
    name,
    inputValue,
    setFieldValue
  ) => {
    const checkData = jobValue[name];
    const dataValue = e.target.value;

    if (e.key === "Tab") {
      e.preventDefault();
      setFieldValue(inputValue, "");
      if (dataValue !== "") {
        const data = {
          ...(name !== "shift" ? { name: "user_added" } : { id: index }),
          content: dataValue,
        };

        const formattedValues = [...checkData, data];
        setJobValue((prev) => ({
          ...prev,
          [inputValue]: "",
          [name]: formattedValues,
        }));
      }
    }
  };

  const handleAutoCompleteDelete = (option, name) => {
    const updatedValue = jobValue[name].filter((item, i) => item !== option);
    setJobValue((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));
  };

  const handleAutoCompleteChange = (e, newValue, name) => {
    const getValue = e.target.value;
    if (e?.key === "Backspace" && getValue === "") {
      const updatedValue = [...(jobValue[name] || [])];
      updatedValue.pop();
      setJobValue((prev) => ({
        ...prev,
        [name]: updatedValue,
      }));
    } else {
      const formattedValues = newValue.map((item, index) => {
        if (typeof item === "string") {
          return {
            ...(name !== "shift" ? { name: "user_added" } : { id: index }),
            content: item,
          };
        }
        return item;
      });
      setJobValue((prev) => ({
        ...prev,
        [name]: formattedValues,
      }));
    }
  };

  const handleDatePicker = (name, start_date, end_date) => {
    setJobValue((prev) => ({
      ...prev,
      [name]: {
        start_date: start_date,
        end_date: end_date,
      },
    }));
  };

  const primaryViewData = [
    {
      title: "Title",
      name: value(jobData?.job_details?.job_title),
      component: "input",
      titleName: "job_title",
      onChange: handleInputChange,
    },
    {
      title: "Company",
      name: value(jobData?.job_details?.company_name),
      component: "input",
      titleName: "company_name",
      onChange: handleInputChange,
    },
    {
      title: "Industry",
      additional: handleDynmaicListFormat(jobData?.job_details?.industry),
      component: "multi-complete",
      titleName: "industry",
      options: "available_industry",
      content: handleAddIcon(
        jobData?.job_details?.industry_anomoly,
        jobValue?.industry_anomoly,
        {
          top: "0px",
          left: "0px",
        }
      ),
      placeholder: "Select Industry",
      iconVisible: true,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoMultiChange,
    },
    {
      title:
        alignment === "View"
          ? "Hourly Target Rate"
          : "Minimal Hourly Target Rate",
      name: value(jobData?.job_details?.pay_rate),
      component: "input-select",
      titleName: "pay_rate",
      currency: "min",
      currencyValue: "currency",
      content: handleAddIcon(
        jobData?.job_details?.pay_anomoly,
        jobValue?.pay_anomoly,
        {
          top: "0px",
          left: "0px",
        }
      ),
      iconVisible: true,
      handleCurrencyChange: handleCurrencyChange,
      handleCurrencyDropdownChange: handleCurrencyDropdownChange,
    },
    ...(alignment === "Edit"
      ? [
          {
            title: "Maximal Hourly Target Rate",
            component: "input-select",
            name: value(jobData?.job_details?.pay_rate),
            titleName: "pay_rate",
            currency: "max",
            currencyValue: "currency",
            content: handleAddIcon(
              jobData?.job_details?.pay_anomoly,
              jobValue?.pay_anomoly,
              {
                top: "0px",
                left: "0px",
              }
            ),
            iconVisible: true,
            handleCurrencyChange: handleCurrencyChange,
            handleCurrencyDropdownChange: handleCurrencyDropdownChange,
          },
        ]
      : []),
    {
      title: "Seniority Level",
      name: value(jobData?.job_details?.management_level),
      component: "dropDown",
      titleName: "management_level",
      dropDownData: "available_management_level",
      content: handleAddIcon(
        jobData?.job_details?.management_level_anomoly,
        jobValue?.management_level_anomoly,
        {
          top: "0px",
          left: "0px",
        }
      ),
      iconVisible: true,
      handleDropdownChange: handleDropdownChange,
    },
    {
      title: "Employment Type",
      name: value(jobData?.job_details?.employment),
      component: "dropDown",
      value: jobValue.employment,
      data: jobValue.available_employment_types,
      titleName: "employment",
      dropDownData: "available_employment_types",
      handleDropdownChange: handleDropdownChange,
    },
    {
      component: "split-part",
      data: [
        {
          title: "Location",
          // image: jobData?.job_details?.location ? Image : null,
          name: value(jobData?.job_details?.location),
          component: "input",
          titleName: "location",
          editValue: jobValue,
          onChange: handleInputChange,
        },
        {
          title: "Work Location Type",
          name: value(jobData?.job_details?.work_location_type),
          component: "input",
          titleName: "work_location_type",
          editValue: jobValue,
          onChange: handleInputChange,
        },
      ],
    },

    ...(alignment === "View"
      ? [
          {
            title: "Start Date",
            name: value(jobData?.job_details?.start_date),
          },
          {
            title: "End Date",
            name: value(jobData?.job_details?.end_date),
          },
        ]
      : [
          {
            title: "Date Range",
            name: "Oct 30 2024",
            component: "calender",
            titleName: "Date_Range",
            handleDatePicker: handleDatePicker,
          },
        ]),
  ];

  const SecondaryViewData = [
    {
      title: "Occupational Classification",
      name: value(jobData?.job_details?.occupational_classification),
      component: "dropDown",
      titleName: "occupational_classification",
      dropDownData: "available_occupational_classification",
      content: handleAddIcon(
        jobData?.job_details?.occupational_classification_anomoly,
        jobValue?.occupational_classification_anomoly,
        { top: "0px", left: "0px" }
      ),
      iconVisible: true,
      handleDropdownChange: handleDropdownChange,
    },
    {
      title: "Req ID",
      component: "input",
      titleName: "req_id",
      name: value(jobData?.job_details?.req_id),
      onChange: handleInputChange,
    },
    {
      title: "Shift",
      shift: jobData?.job_details?.shift || [],
      component: "autoComplete",
      titleName: "shift",
      inputValue: "shift_value",
      placeholder: "Enter Shift",
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleAutoCompleteKeyDown: handleAutoCompleteKeyDown,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoCompleteChange,
    },
    {
      title: "Reports To",
      name: value(jobData?.job_details?.reports_to),
      component: "input",
      titleName: "reports_to",
      onChange: handleInputChange,
    },
    {
      title: "BU/Dept/Team",
      name: value(jobData?.job_details?.bu_department_team),
      component: "input",
      titleName: "bu_department_team",
      onChange: handleInputChange,
    },
    {
      title: "Company Profile",
      name: jobData?.job_details?.company_profile,
      component: "dropDown",
      titleName: "company_profile",
      dropDownData: "available_company_profiles",
      content: handleAddIcon(
        jobData?.job_details?.company_profile_anomoly,
        jobValue?.company_profile_anomoly,
        {
          top: "0px",
          left: "0px",
        }
      ),
      iconVisible: true,
      handleDropdownChange: handleDropdownChange,
    },
  ];

  const additionalData = [
    ...(alignment === "View"
      ? [
          {
            title: "Highest Level of Education",
            name: jobData?.job_details?.education,
            component: "dropDown",
            titleName: "education",
            dropDownData: "available_education",
            check: jobValue.score_education,
            toggleName: "score_education",
            type: "additional",
            handleChangeSwitch: handleChangeSwitch,
            handleDropdownChange: handleDropdownChange,
          },
          {
            title: "Specialization",
            name: jobData?.job_details?.specific_degree,
            type: "additional",
          },
        ]
      : [
          {
            component: "split-part",
            // handleChangeSwitch: handleChangeSwitch,
            // check: jobValue.score_education,
            // toggleName: "score_education",
            type: "additional",
            data: [
              {
                title: "Highest Level of Education",
                name: jobData?.job_details?.education,
                component: "dropDown",
                titleName: "education",
                dropDownData: "available_education",
                handleDropdownChange: handleDropdownChange,
              },
              {
                title: "Specialization",
                component: "input",
                titleName: "specific_degree",
                onChange: handleInputChange,
                handleChangeSwitch: handleChangeSwitch,
                check: jobValue.score_education,
                toggleName: "score_education",
                type: "additional",
              },
            ],
          },
        ]),
    {
      title: "Location Targets",
      additional: handleDynmaicList(
        jobData?.job_details?.location_targets,
        Image
      ),
      component: "autoComplete",
      check: false,
      titleName: "location_targets",
      placeholder: "Enter Location Targets",
      inputValue: "location_targets_value",
      check: jobValue.score_location_targets,
      toggleName: "score_location_targets",
      type: "additional",
      content: (
        <CommonGrid2 gap={"10px"} display={"flex"}>
          <JobPencilIco />
          <JobStarIco />
          <JobPenIco />
        </CommonGrid2>
      ),
      iconVisible: iconVisible,
      handleChangeSwitch: handleChangeSwitch,
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleAutoCompleteKeyDown: handleAutoCompleteKeyDown,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoCompleteChange,
    },
    {
      title: "Certificates",
      component: "autoComplete",
      check: false,
      multi_additional: handleDynmaicList(jobData?.job_details?.certificates),
      titleName: "certificates",
      inputValue: "certificates_value",
      placeholder: "Enter Certificates",
      check: jobValue.score_certificates,
      toggleName: "score_certificates",
      type: "additional",
      content: (
        <CommonGrid2 gap={"10px"} display={"flex"}>
          <JobPencilIco />
          <JobStarIco />
          <JobPenIco />
        </CommonGrid2>
      ),
      iconVisible: iconVisible,
      handleChangeSwitch: handleChangeSwitch,
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleAutoCompleteKeyDown: handleAutoCompleteKeyDown,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoCompleteChange,
    },
    {
      title: "Employer Targets",
      component: "autoComplete",
      check: false,
      multi_additional: handleDynmaicList(
        jobData?.job_details?.employer_targets
      ),
      titleName: "employer_targets",
      inputValue: "employer_targets_value",
      placeholder: "Enter Employer Targets",
      check: jobValue.score_employer_targets,
      toggleName: "score_employer_targets",
      type: "additional",
      content: (
        <CommonGrid2 gap={"10px"} display={"flex"}>
          <JobPencilIco />
          <JobStarIco />
          <JobPenIco />
        </CommonGrid2>
      ),
      iconVisible: iconVisible,
      handleChangeSwitch: handleChangeSwitch,
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleAutoCompleteKeyDown: handleAutoCompleteKeyDown,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoCompleteChange,
    },
    {
      title: "Languages",
      component: "autoComplete",
      titleName: "languages",
      inputValue: "languages_value",
      multi_additional: handleDynmaicList(jobData?.job_details?.languages),
      placeholder: "Enter Languages",
      check: jobValue.score_languages,
      toggleName: "score_languages",
      type: "additional",
      content: (
        <CommonGrid2 gap={"10px"} display={"flex"}>
          <JobPencilIco />
          <JobStarIco />
          <JobPenIco />
        </CommonGrid2>
      ),
      iconVisible: iconVisible,
      handleChangeSwitch: handleChangeSwitch,
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleAutoCompleteKeyDown: handleAutoCompleteKeyDown,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoCompleteChange,
    },
    {
      title: "Visa Status",
      name: jobData?.job_details?.visa_employment_needs?.content,
      component: "multi-complete",
      titleName: "visa_status",
      options: "available_visa_status",
      check: jobValue.score_visa,
      toggleName: "score_visa",
      type: "additional",
      placeholder: "Select Visa Status",
      handleChangeSwitch: handleChangeSwitch,
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoMultiChange,
    },
    {
      title: "Goverment / Security Classification",
      check: jobValue.govt_security_clearance,
      name: jobData?.job_details?.govt_security_clearance ? "Yes" : "NO",
      toggleName: "govt_security_clearance",
      type: "additional",
      handleChangeSwitch: handleChangeSwitch,
    },
    {
      title: "DEI",
      name: jobData?.job_details?.dei ? "Yes" : "NO",
      check: jobValue.dei,
      toggleName: "dei",
      type: "additional",
      handleChangeSwitch: handleChangeSwitch,
    },
  ];

  const headerData = [
    { name: "Skill" },
    { name: "Criticality" },
    { name: "Years" },
    ...(alignment === "Edit" ? [{ name: "" }] : []),
  ];

  const toggleRow = (index) => {
    setOpen((prev) => {
      const newState = { ...prev, [index]: !prev[index] };

      return newState;
    });
    setTimeout(() => {
      if (tableContainerRef.current) {
        const targetRow = tableContainerRef.current.querySelector(
          `.primary_${index}`
        );
        if (targetRow) {
          const targetCellTop = targetRow.getBoundingClientRect().top;
          const containerTop =
            tableContainerRef.current.getBoundingClientRect().top;
          const cellOffset = targetCellTop - containerTop;
          tableContainerRef.current.scrollTo({
            top: tableContainerRef.current.scrollTop + cellOffset - 60,
            behavior: "smooth",
          });
        }
      }
    }, 0);
  };

  const menuItemData = [
    {
      name: "USD",
    },
    { name: "INR" },
  ];

  const handleDeleteSkillRow = (data, values, type) => {
    setDialogData({
      key: type,
      data: data,
      values: values,
    });
    setDeleteDialog(true);
  };

  const handleClose = () => {
    setSkillDialog(false);
  };

  const handleAddNewSkill = (values) => {
    setSkillDialog(true);
    setDialogData({
      title: "Create",
      classification: true,
      experience_in_yrs: "",
      criticality_score: 0,
      skill: "",
      values: values,
    });
  };

  const handleSkillClick = async (values, form) => {
    setButtonLoading(true);
    setTimeout(() => {
      const skillDataKey = values.key;
      const currentSkillData = dialogData.values["skillData"][skillDataKey];
      const { classification, skill, ...rest } = values;

      let updatedSkillData;
      updatedSkillData = [...currentSkillData, values];

      const formData = {
        ...dialogData.values["skillData"],
        [skillDataKey]: updatedSkillData,
      };

      setJobValue((prev) => ({
        ...prev,
        skillData: {
          ...formData,
        },
      }));

      setSnackbarState({
        open: true,
        severity: "success",
        message: "updated Successfully",
      });
      setButtonLoading(false);
      setIsLoading(true);
      handleClose();
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteSkill = () => {
    setButtonLoading(true);
    let filterData;

    setTimeout(() => {
      filterData = dialogData.values["skillData"][dialogData.key].filter(
        (item) => item !== dialogData.data
      );

      const formData = {
        ...dialogData.values["skillData"],
        [dialogData.key]: filterData,
      };

      setJobValue((prev) => ({
        ...prev,
        skillData: {
          ...formData,
        },
      }));

      setSnackbarState({
        open: true,
        severity: "success",
        message: `${
          dialogData?.data?.classification
            ? dialogData?.data?.classification
            : dialogData.data.skill
        } Removed Successfully`,
      });
      setButtonLoading(false);
      handleDeleteDialogClose();
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const fetchJobEdit = async (jobId) => {
    setIsLoading(true);

    try {
      const data = await dispatch(JobJobEdit(jobId));
      setIsLoading(false);
      setExpandedIndex([]);
    } catch (error) {
      setIsLoading(false);
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setExpandedIndex([]);
    }
  };

  const handleSkillToogleChange = (
    event,
    newAlignment,
    rowIndex,
    values,
    type
  ) => {
    if (newAlignment !== null) {
      const dataKey = type;

      const updatedSkillData = values?.["skillData"]?.[dataKey]?.map((row) =>
        Array.isArray(row?.skill)
          ? { ...row, skill: row.skill.map((skill) => ({ ...skill })) }
          : { ...row }
      );

      const rowToUpdate = updatedSkillData?.[rowIndex];
      if (rowToUpdate) {
        rowToUpdate["expertise_level"] = newAlignment;
      }

      setJobValue((prev) => ({
        ...prev,
        skillData: {
          ...values?.["skillData"],
          [dataKey]: updatedSkillData,
        },
      }));
    }
  };

  const handleSkillTextChange = (event, rowIndex, values, type, fieldName) => {
    const { value } = event.target;
    const getData = event.target.value;
    const dataKey = type;
    const updatedSkillData = values?.["skillData"]?.[dataKey]?.map((row) =>
      Array.isArray(row?.skill)
        ? { ...row, skill: row.skill.map((skill) => ({ ...skill })) }
        : { ...row }
    );

    const rowToUpdate = updatedSkillData?.[rowIndex];
    if (rowToUpdate) {
      rowToUpdate[fieldName] = getData;
    }

    setJobValue((prev) => ({
      ...prev,
      skillData: {
        ...values?.["skillData"],
        [dataKey]: updatedSkillData,
      },
    }));
  };

  const onSeededDragEnd = (result, setFieldValue, values) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;
    const update = [...values["pre_screen_questions"]];
    const [removed] = update.splice(source.index, 1);
    update.splice(destination.index, 0, removed);

    setFieldValue("pre_screen_questions", update);
    setJobValue((prev) => ({
      ...prev,
      pre_screen_questions: update,
    }));
  };

  const handleSeededDelete = (question, index, values) => {
    setSeededDialog({
      content: question,
      index: index,
      values: values,
      deleteOpen: true,
      loading: false,
      addOpen: false,
    });
  };

  const handleDeleteSeeded = () => {
    setSeededDialog((prev) => ({
      ...prev,
      loading: true,
    }));

    setTimeout(() => {
      const originalQuestions = [
        ...seededDialog?.values["pre_screen_questions"],
      ];
      const updatedQuestions = originalQuestions.filter(
        (_, i) => i !== seededDialog?.index
      );

      setJobValue((prev) => ({
        ...prev,
        pre_screen_questions: updatedQuestions,
      }));

      setSnackbarState({
        open: true,
        severity: "success",
        message: "Seeded Remvoved Successfully",
      });
      setIsLoading(true);
      handleSeededClose();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleSeededClose = () => {
    setSeededDialog({
      content: "",
      index: "",
      values: "",
      deleteOpen: false,
      loading: false,
      addOpen: false,
    });
  };
  const ordinalNumbers = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "sixth",
    "Seventh",
    "Eigth",
    "Ninth",
    "Tenth",
  ];
  const interviewTypes = jobValue.interview_type || [];
  const InterviewSettingData = interviewTypes.map((type, index) => ({
    title: `${ordinalNumbers[index] || `${index + 1}th`} Touch`,
    name: value(type),
    component: "input-list",
    titleName: "interview_type",
    onListChange: (value) => handleListChange(value, "interview_type"),
  }));

  const handleAddSeeded = (values) => {
    setSeededDialog((prev) => ({
      ...prev,
      addOpen: true,
      values: values,
    }));
  };

  const handleAddSubmit = (values) => {
    setSeededDialog((prev) => ({
      ...prev,
      loading: true,
    }));

    setTimeout(() => {
      const update = [...seededDialog.values["pre_screen_questions"]];
      const updatedQuestions = [...update, values.question];

      setJobValue((prev) => ({
        ...prev,
        ["pre_screen_questions"]: updatedQuestions,
      }));
      setSnackbarState({
        open: true,
        severity: "success",
        message: "Seeded Added Successfully",
      });
      setIsLoading(true);
      handleSeededClose();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleClickAddNote = () => {
    setNoteList((prev) => ({
      ...prev,
      open: true,
      loading: false,
      noteId: "",
      type: "",
      note: "",
      created_at: "",
      job_history_reason: "",
      accecpting_less_more_candidates: "",
      locals_only: "",
      hybrid_days: "",
      project_scope: "",
      other_details: "",
      deadline_start: "",
      deadline_end: "",
    }));
  };

  const handleCloseNoteDialog = () => {
    setNoteList((prev) => ({
      ...prev,
      open: false,
      loading: false,
      noteId: "",
      type: "",
      note: "",
      created_at: "",
      job_history_reason: "",
      accecpting_less_more_candidates: "",
      locals_only: "",
      hybrid_days: "",
      project_scope: "",
      other_details: "",
      deadline_start: "",
      deadline_end: "",
    }));
  };

  const handleDeleteNoteClick = (row, index) => {
    setDeleteNoteDialogOpen(true);
    setNoteList((prev) => ({
      ...prev,
      open: false,
      loading: false,
      noteId: row?.id,
      type: row?.type,
      note: row?.note,
      created_at: row?.created_at,
      job_history_reason: "",
      accecpting_less_more_candidates: "",
      locals_only: "",
      hybrid_days: "",
      project_scope: "",
      other_details: "",
      deadline_start: "",
      deadline_end: "",
    }));
  };

  const handleDeleteNoteDialogClose = () => {
    setDeleteNoteDialogOpen(false);
    handleCloseNoteDialog();
  };

  const handleUpdateNoteClick = (row) => {
    setNoteList((prev) => ({
      ...prev,
      open: true,
      loading: false,
      noteId: row?.id,
      type: row?.type,
      note: row?.note,
      created_at: row?.created_at,
      job_history_reason: row?.job_history_reason,
      accecpting_less_more_candidates: row?.accecpting_less_more_candidates,
      locals_only: row?.locals_only,
      hybrid_days: row?.hybrid_days,
      project_scope: row?.project_scope,
      other_details: row?.note,
      deadline_start: row?.deadline_start,
      deadline_end: row?.deadline_end,
    }));
  };

  const fetchNotesTableList = async (jobId) => {
    setNotesTableListLoading(true);
    const res = await GET(getJdNotes(jobId));
    if (res) {
      setNotesTableList(res?.data);
      setNotesTableListLoading(false);
    }
  };

  const handleCreateNote = async (values) => {
    setNoteList((prev) => ({ ...prev, loading: true }));
    let payload;
    if (values.type === "Blank") {
      payload = {
        note: values.note,
        interaction: "Email",
        added_by: "username",
        type: values.type,
      };
    } else {
      payload = {
        note: values.other_details,
        type: values.type,
        added_by: "username",
        hybrid_days: values.hybrid_days,
        deadline_start: values["Date_range"]?.["deadline_start"],
        deadline_end: values["Date_range"]?.["deadline_end"],
        job_history_type: values.job_history_type,
        job_history_reason: values.job_history_reason,
        accecpting_less_more_candidates: values.accecpting_less_more_candidates,
        locals_only: values.locals_only,
        project_scope: values.project_scope,
      };
    }

    if (noteList.noteId) {
      try {
        const res = await PUT(updateJdNote(noteList.noteId), payload);
        setSnackbarState({
          open: true,
          severity: "success",
          message: res?.message,
        });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error?.message || "Something went wrong",
        });
      }
    } else {
      try {
        const res = await POST(addJdNotes(jobId), [payload]);
        setSnackbarState({
          open: true,
          severity: "success",
          message: res?.data?.message,
        });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error?.message || "Something went wrong",
        });
      }
    }
    handleCloseNoteDialog();
    setReScore((prev) => ({
      ...prev,
      noteOpen: true,
    }));

    fetchNotesTableList(jobId);
  };

  const handleDeleteNoteById = async () => {
    setNoteList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await DELETE(deleteJdNote(noteList.noteId));
      setSnackbarState({
        open: true,
        severity: "success",
        message: res?.message,
      });
      fetchNotesTableList(jobId);
      handleDeleteNoteDialogClose();
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.message,
      });
      handleCloseNoteDialog();
    }
  };

  const handleEnableDynamicCheckBox = (event) => {
    setJobValue((prev) => ({
      ...prev,
      enable_dynamic_pre_screen_questions: event.target.checked,
    }));
  };

  const accordionDataView = [
    {
      title: "Primary Information",
      component: (
        <PrimaryInformation
          primaryViewData={primaryViewData}
          alignment={alignment}
          isLoading={isLoading}
          menuItemData={menuItemData}
        />
      ),
    },
    {
      title: "Secondary Information",
      component: (
        <>
          <SecondaryInformation
            SecondaryViewData={SecondaryViewData}
            alignment={alignment}
            isLoading={isLoading}
          />
          <SecondaryInformation
            SecondaryViewData={additionalData}
            alignment={alignment}
            isLoading={isLoading}
            marginTop={"3rem"}
            viewMarginTop={"1rem"}
          />
        </>
      ),
    },
    {
      title: "Skills",
      component: (
        <CommonGrid2 spacing={5} container>
          <CommonGrid2 size={12}>
            <Skills
              highPriority={
                alignment === "Edit"
                  ? jobValue?.skillData?.high_priority
                  : jobData?.job_details?.high_priority
              }
              lowPriority={
                alignment === "Edit"
                  ? jobValue?.skillData?.low_priority
                  : jobData?.job_details?.low_priority
              }
              mediumPriority={
                alignment === "Edit"
                  ? jobValue?.skillData?.medium_priority
                  : jobData?.job_details?.medium_priority
              }
              recommended={
                alignment === "Edit"
                  ? jobValue?.skillData?.recommended
                  : jobData?.job_details?.recommended
              }
              headerData={headerData}
              alignment={alignment}
              handleDeleteSkillRow={handleDeleteSkillRow}
              handleAddNewSkill={handleAddNewSkill}
              open={open}
              jobModifyData={jobModifyData}
              handleSkillToogleChange={handleSkillToogleChange}
              tableContainerRef={tableContainerRef}
              handleSkillTextChange={handleSkillTextChange}
              isLoading={isLoading}
              iconVisible={iconVisible}
            />
          </CommonGrid2>
          {jobValue &&
            jobValue?.interview_type &&
            jobValue?.interview_type?.length !== 0 && (
              <CommonGrid2 size={12}>
                <InterviewSettings
                  InterviewSettingData={InterviewSettingData}
                  alignment={alignment}
                  isLoading={isLoading}
                  rowSize={true}
                />
              </CommonGrid2>
            )}

          {(alignment === "Edit"
            ? true
            : jobData &&
              jobData?.job_details &&
              jobData?.job_details?.pre_screen_questions?.length !== 0) && (
            <CommonGrid2 size={12}>
              <SeededPreScreen
                alignment={alignment}
                onDragEnd={onSeededDragEnd}
                preScreen={jobData?.job_details?.pre_screen_questions}
                handleSeededDelete={handleSeededDelete}
                isLoading={isLoading}
                handleAddSeeded={handleAddSeeded}
                onListChange={handleListChange}
                handleEnableDynamicCheckBox={handleEnableDynamicCheckBox}
              />
            </CommonGrid2>
          )}
        </CommonGrid2>
      ),
    },
    ...(!JobJobEditData.is_scored
      ? []
      : [
          {
            title: "Notes",
            component: (
              <Notes
                isShown={true}
                handleClickAddNote={handleClickAddNote}
                handleDeleteNoteClick={handleDeleteNoteClick}
                handleUpdateNoteClick={handleUpdateNoteClick}
                notesTableList={notesTableList}
                notesTableListLoading={notesTableListLoading || isLoading}
                isLoading={isLoading}
                headerData={noteHeaderData}
              />
            ),
          },
        ]),
  ];
  const [expandedIndex, setExpandedIndex] = useState(
    accordionDataView.map((_, i) => i)
  );

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleSubmitJob = async (values, condition) => {
    setIsLoading(true);
    setButtonLoader((prev) => ({
      ...prev,
      disabled: true,
      ...(condition === "submit"
        ? { submitLoading: true }
        : condition === true
        ? { rescoreLoading: true }
        : { activateLoading: true }),
    }));
    const result = (valueData) => {
      if (!valueData) return [];

      const processedData = valueData.reduce((acc, { name, content }) => {
        const existing = acc.find((item) => item.name === name);
        if (existing) {
          existing.content.push(content);
        } else {
          acc.push({ name, content: [content] });
        }
        return acc;
      }, []);

      const extracted = processedData.find((item) => item.name === "extracted");
      const inferred = processedData.find((item) => item.name === "inferred");
      const userAdded = processedData.find(
        (item) => item.name === "user_added"
      );

      const resultData = [];
      if (!extracted) {
        resultData.push({ name: "extracted", content: [] });
      } else {
        resultData.push(extracted);
      }
      if (!inferred) {
        resultData.push({ name: "inferred", content: [] });
      } else {
        resultData.push(inferred);
      }
      const otherData = processedData.filter(
        (item) =>
          item.name !== "extracted" &&
          item.name !== "inferred" &&
          item.name !== "user_added"
      );
      resultData.push(...otherData);
      if (!userAdded) {
        resultData.push({ name: "user_added", content: [] });
      } else {
        resultData.push(userAdded);
      }
      return resultData;
    };

    const data = {
      ...JobJobEditData,
      ...values,
      start_date: values.Date_Range.start_date,
      end_date: values.Date_Range.end_date,
      employer_targets: result(values.employer_targets),
      certificates: result(values.certificates),
      languages: result(values.languages),
      shift: values.shift.map((item) => item.content),
      location_targets: result(values.location_targets),
      high_priority: values.skillData.high_priority,
      low_priority: values.skillData.low_priority,
      medium_priority: values.skillData.medium_priority,
      recommended: values.skillData.recommended,
      industry: values.industry.map((item) => item.name),
    };

    try {
      let jobDataDetails;
      if (condition === "submit") {
        await JobJobSubmit(jobValue.id, data);
        setAlignment("View");
      } else if (
        !jobData?.job_details?.is_scored ||
        // !JobJobEditData.is_scored ||
        condition === true
      ) {
        let param = {
          id: jobId,
        };
        // await JobJobSubmit(jobValue.id, data);
        jobDataDetails = await JobIndustryScoreSubmit(param);
        await fetchJobDataDetail(jobId);
      } else {
        let params = {
          jem_status: "Active",
        };
        jobDataDetails = await JobJobSubmit(jobValue.id, data, params);
        setSnackbarState({
          open: true,
          severity: "success",
          message: jobDataDetails.message,
        });
        setActivate((prev) => ({
          ...prev,
          activateOpen: true,
          data: jobDataDetails?.data,
        }));
        setAlignment("View");
        setActivateButtonDisable(true);
      }
      setIsLoading(false);
      setButtonLoader((prev) => ({
        ...prev,
        disabled: false,
        activateLoading: false,
        rescoreLoading: false,
        submitLoading: false,
      }));
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.data?.message || error.message,
      });
      setIsLoading(false);
      setButtonLoader((prev) => ({
        ...prev,
        disabled: false,
        activateLoading: false,
        rescoreLoading: false,
        submitLoading: false,
      }));
    }
  };

  const handleScrollToError = (data) => {
    if (alignment === "View" && Object.keys(data).length !== 0) {
      setSnackbarState({
        open: true,
        message: "Some data is missing. Switch to edit mode to fill it in.",
        severity: "error",
      });
    } else {
      const newExpandedIndex =
        data.Date_Range ||
        data.pay_rate ||
        data.company_name ||
        data.job_title ||
        data.industry ||
        data.management_level ||
        data.location ||
        data.employment
          ? 0
          : data.location_targets
          ? 1
          : data.pre_screen_questions || data.skillData
          ? 2
          : expandedIndex;

      setExpandedIndex([newExpandedIndex]);
      setTimeout(() => {
        const errorElement = document.querySelector(".Mui-error");
        if (errorElement) {
          errorElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 300);
    }
  };

  const resultHeaderData = [
    {
      name: "Relevancy Index",
      icon: handleAddIcon(
        jobData?.job_details?.relevancy_index_anomoly,
        jobValue.relevancy_index_anomoly,
        {
          top: "4px",
          left: "10px",
        }
      ),
    },

    {},
    { name: "Relevancy Score" },
    {},
  ];

  const onRelevancyDragEnd = (result, values, setFieldValue) => {
    const { source, destination } = result;
    if (!destination || destination.index === source.index) return;
    const reorderedCandidates = [...values["relevancy_index"]];
    const [removed] = reorderedCandidates.splice(source.index, 1);
    reorderedCandidates.splice(destination.index, 0, removed);
    setFieldValue("relevancy_index", reorderedCandidates);
    setJobValue((prev) => ({
      ...prev,
      relevancy_index: reorderedCandidates,
    }));
  };

  const fetchJobsList = async (job_id) => {
    setFixLoader(true);
    setIsLoading(true);
    try {
      const response = await GET(fixedJob(job_id));
      setSnackbarState({
        open: true,
        message: response?.data?.message || "Fix applied successfully",
        severity: "success",
      });
      if (alignment === "Edit") {
        setJobValue(jobModifyData);
        await fetchJobEdit(jobId);
      } else if (alignment === "View") {
        await fetchJobDataDetail(jobId);
      }

      setIsLoading(false);
      setFixLoader(false);
    } catch (err) {
      setSnackbarState({
        open: true,
        message: "An error occurred while applying the fix.",
        severity: "error",
      });
      setIsLoading(false);
      setFixLoader(false);
    }
  };

  const handleRescoreClose = () => {
    setIsLoading(false);
    setReScore({
      noLoading: false,
      disabled: false,
      yesLoading: false,
      noteOpen: false,
    });
  };

  const Footer = () => {
    return (
      <CommonGrid2 display={"flex"} gap={"1rem"}>
        <CommonButton
          value={"No"}
          variant="outlined"
          color="#344054"
          width={"100%"}
          padding={"10px"}
          marginTop={"2rem"}
          fontWeight={"700"}
          fontSize={"15px"}
          border={"#D0D5DD"}
          onClick={() => handleClickScore("no", false)}
          disabled={reScore.disabled}
          loading={reScore.noLoading}
        />
        <CommonButton
          value={"Yes"}
          width={"100%"}
          padding={"10px"}
          marginTop={"2rem"}
          fontWeight={"700"}
          fontSize={"15px"}
          disabled={reScore.disabled}
          loading={reScore.yesLoading}
          onClick={() => handleClickScore("yes", false)}
        />
      </CommonGrid2>
    );
  };

  const handleClickScore = async (condition, check) => {
    setReScore((prev) => ({
      ...prev,
      yesLoading: condition === "yes" ? true : false,
      noLoading: condition === "no" ? true : false,
      disabled: true,
    }));
    setIsLoading(true);

    let payload = {
      score: condition === "yes" ? true : false,
    };

    try {
      const response = await JobJobReScore(jobId, payload);
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf("}\n")) !== -1) {
          const jsonString = buffer.slice(0, boundaryIndex + 1);
          buffer = buffer.slice(boundaryIndex + 1);
          const jsonResponse = JSON.parse(jsonString);
          try {
            processJsonResponse(jsonResponse, check, condition);
          } catch (e) {
            setSnackbarState({
              open: true,
              message: "Error parsing JSON",
              severity: "error",
            });
            setIsLoading(false);
            setReScore({
              noLoading: false,
              disabled: false,
              yesLoading: false,
              noteOpen: true,
            });
          }
        }
      }

      if (buffer) {
        const jsonResponse = JSON.parse(buffer);
        try {
          processJsonResponse(jsonResponse, check, condition);
        } catch (e) {
          setSnackbarState({
            open: true,
            message: "Error parsing final JSON chunk",
            severity: "error",
          });
          setIsLoading(false);
          setReScore({
            noLoading: false,
            disabled: false,
            yesLoading: false,
            noteOpen: true,
          });
        }
      }
    } catch (error) {
      setSnackbarState({
        open: true,
        message: "Error during the request",
        severity: "error",
      });
      setIsLoading(false);
      setReScore({
        noLoading: false,
        disabled: false,
        yesLoading: false,
        noteOpen: true,
      });
    }
  };

  const processJsonResponse = async (jsonResponse, check, condition) => {
    const { success, data, message } = jsonResponse;

    if (success && data?.percent !== undefined) {
    } else if (data?.response) {
      handleRescoreClose();
      setSnackbarState({
        open: true,
        severity: "success",
        message: message,
        // duration: 500,
      });
    } else if (!success) {
      setIsLoading(false);
      setReScore({
        noLoading: false,
        disabled: false,
        yesLoading: false,
        noteOpen: true,
      });
    }
  };

  const handleActivatePopup = (action) => {
    if (action === "share") {
      setActivate((prev) => ({
        ...prev,
        disabled: true,
        shareLoading: true,
      }));
      setTimeout(() => {
        setActivate((prev) => ({
          ...prev,
          activateOpen: false,
          disabled: false,
          inviteOpen: true,
          shareLoading: false,
        }));
      }, 1000);
    } else if (action === "back-to-jobs") {
      setActivate((prev) => ({
        ...prev,
        disabled: true,
        backtoJobsLoading: true,
      }));
      setTimeout(() => {
        setActivate((prev) => ({
          ...prev,
          disabled: false,
          backtoJobsLoading: false,
        }));
        navigate("/jobs");
      }, 1000);
    } else if (action === "add-candidate") {
      setActivate((prev) => ({
        ...prev,
        disabled: true,
        candidateLoading: true,
      }));
      setTimeout(() => {
        setActivate((prev) => ({
          ...prev,
          disabled: false,
          candidateLoading: false,
        }));
        navigate(
          `/candidates?occupational_classification=${activate.data?.occupational_classification}`
        );
      }, 1000);
    }
  };
  const ActivateFooter = () => {
    return (
      <CommonGrid2>
        <CommonGrid2 display={"flex"} gap={"1rem"}>
          <CommonButton
            value={"Share"}
            variant="outlined"
            color="#344054"
            border={"#D0D5DD"}
            width={"100%"}
            padding={"10px"}
            marginTop={"2rem"}
            fontWeight={"700"}
            fontSize={"15px"}
            disabled={activate.disabled}
            loading={activate.shareLoading}
            onClick={() => handleActivatePopup("share")}
          />
          <CommonButton
            value={"Back to Jobs"}
            variant="outlined"
            color="#344054"
            width={"100%"}
            marginTop={"2rem"}
            padding={"10px"}
            fontWeight={"700"}
            fontSize={"15px"}
            border={"#D0D5DD"}
            onClick={() => handleActivatePopup("back-to-jobs")}
            disabled={activate.disabled}
            loading={activate.backtoJobsLoading}
          />
        </CommonGrid2>
        <CommonButton
          value={"Add Candidates to Job"}
          variant="outlined"
          color="#344054"
          border={"#D0D5DD"}
          width={"100%"}
          padding={"10px"}
          marginTop={"1rem"}
          fontWeight={"700"}
          fontSize={"15px"}
          disabled={activate.disabled}
          loading={activate.candidateLoading}
          onClick={() => handleActivatePopup("add-candidate")}
        />
      </CommonGrid2>
    );
  };

  const handlePopupClose = () => {
    setActivate({
      inviteOpen: false,
      shareLoading: false,
      inviteLoading: false,
      activateOpen: false,
      backtoJobsLoading: false,
      disabled: false,
      candidateLoading: false,
    });
  };

  const handleShareInvite = async (shareData) => {
    setActivate((prev) => ({
      ...prev,
      inviteLoading: true,
    }));
    try {
      let url = process.env.REACT_APP_URL;
      let payload = {
        url: `${url}/jobs/jobs-view?id=${encodeURIComponent(
          jobId
        )}&title=${encodeURIComponent(jobValue.job_title)}&value=1`,
        to_mail: shareData,
        cc_mail: [],
      };
      const data = await JobJobShare(payload, "Job", jobId);
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      handlePopupClose();
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setActivate((prev) => ({
        ...prev,
        inviteLoading: false,
      }));
    }
  };

  const CustomMenuItem = (item, index) => {
    return (
      <MenuItem value={item?.value} key={index}>
        {item?.name}
      </MenuItem>
    );
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      let getValues = {
        ...jobValidationCheck,
        shift_value: "",
        location_targets_value: "",
        certificates_value: "",
        employer_targets_value: "",
        languages_value: "",
      };
      dispatch(JobCreateFormikData(jobValue, getValues));
    }, 10);

    return () => {
      clearTimeout(handler);
    };
  }, [jobValue]);

  const handleLeave = async () => {
    setSaveEditData((prev) => ({
      ...prev,
      disabled: true,
      leaveLoading: true,
    }));
    await dispatch(JobCreateFormikData(jobValue, jobValue));
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setSaveEditData((prev) => ({
        ...prev,
        disabled: false,
        leaveLoading: false,
        open: false,
        view: false,
      }));
      setAlignment(saveEditData.prevData);
      setJobValue(jobValidationCheck);
    } catch (error) {}
  };

  const handleSave = async () => {
    setSaveEditData((prev) => ({
      ...prev,
      saveLoading: true,
      disabled: true,
    }));

    const result = (valueData) => {
      if (!valueData) return [];

      const processedData = valueData.reduce((acc, { name, content }) => {
        const existing = acc.find((item) => item.name === name);
        if (existing) {
          existing.content.push(content);
        } else {
          acc.push({ name, content: [content] });
        }
        return acc;
      }, []);

      const extracted = processedData.find((item) => item.name === "extracted");
      const inferred = processedData.find((item) => item.name === "inferred");
      const userAdded = processedData.find(
        (item) => item.name === "user_added"
      );

      const resultData = [];
      if (!extracted) {
        resultData.push({ name: "extracted", content: [] });
      } else {
        resultData.push(extracted);
      }
      if (!inferred) {
        resultData.push({ name: "inferred", content: [] });
      } else {
        resultData.push(inferred);
      }
      const otherData = processedData.filter(
        (item) =>
          item.name !== "extracted" &&
          item.name !== "inferred" &&
          item.name !== "user_added"
      );
      resultData.push(...otherData);
      if (!userAdded) {
        resultData.push({ name: "user_added", content: [] });
      } else {
        resultData.push(userAdded);
      }
      return resultData;
    };

    const data = {
      ...JobJobEditData,
      ...jobValue,
      start_date: jobValue.Date_Range.start_date,
      end_date: jobValue.Date_Range.end_date,
      employer_targets: result(jobValue.employer_targets),
      certificates: result(jobValue.certificates),
      languages: result(jobValue.languages),
      shift: jobValue.shift.map((item) => item.content),
      location_targets: result(jobValue.location_targets),
      high_priority: jobValue.skillData.high_priority,
      low_priority: jobValue.skillData.low_priority,
      medium_priority: jobValue.skillData.medium_priority,
      recommended: jobValue.skillData.recommended,
    };

    try {
      await dispatch(JobCreateFormikData(jobValue, jobValue));
      const jobData = await JobJobSubmit(jobValue.id, data);
      setSnackbarState({
        open: true,
        severity: "success",
        message: jobData.message,
      });
      handleSaveCandidate();
      setJobValue(jobValidationCheck);
      setAlignment(saveEditData.prevData);
    } catch (error) {
      let getValues = {
        ...jobValidationCheck,
        shift_value: "",
        location_targets_value: "",
        certificates_value: "",
        employer_targets_value: "",
        languages_value: "",
      };
      dispatch(JobCreateFormikData(jobValue, getValues));
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setSaveEditData((prev) => ({
        ...prev,
        saveLoading: false,
        disabled: false,
      }));
    }
  };

  const FooterSave = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Leave"}
        variant="outlined"
        color="#344054"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        border={"#D0D5DD"}
        onClick={handleLeave}
        disabled={saveEditData.disabled}
        loading={saveEditData.leaveLoading}
      />
      <CommonButton
        value={"Save"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={saveEditData.disabled}
        loading={saveEditData.saveLoading}
        onClick={handleSave}
      />
    </CommonGrid2>
  );

  const handleSaveCandidate = () => {
    setSaveEditData({
      open: false,
      prevData: "",
      leaveLoading: false,
      disabled: false,
      saveLoading: false,
      view: false,
    });
  };

  const handleSearchStringClose = () => {
    setGenerateSearch({
      open: false,
      copied: false,
    });
  };

  const handleSearchStringClick = () => {
    setGenerateSearch({
      open: true,
    });
  };

  const handleCopyMouseLeave = () => {
    setTimeout(
      () =>
        setGenerateSearch((prev) => ({
          ...prev,
          copied: false,
        })),
      200
    );
  };

  const handleCopyClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setGenerateSearch((prev) => ({
        ...prev,
        copied: true,
      }));
    } catch (error) {}
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={jobValue}
        validationSchema={
          alignment === "Edit"
            ? validationSchema
            : alignment === "View" && !jobData?.job_details?.is_scored
            ? validationSchemaScore
            : selectedValidation
        }
        // validateOnMount={true}
        // validateOnChange={false}
        onSubmit={async (values, actions) => {
          handleSubmitJob(values);
        }}
      >
        {({ values, validateForm }) => {
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Grid2 container spacing={2}>
                <CommonGrid2
                  size={{ xs: 12, md: 4, sm: 12 }}
                  item
                  alignItems={"center"}
                  display={"flex"}
                >
                  {isLoading ? (
                    <HandleSkeletion height={50} width={"100%"} />
                  ) : alignment === "Edit" ? (
                    <Typography variant="h3">
                      {JobJobEditData?.job_title}
                      {JobJobEditData?.company_name &&
                        " - " + JobJobEditData?.company_name}
                      {JobJobEditData?.req_id && " - " + JobJobEditData?.req_id}
                    </Typography>
                  ) : (
                    <Typography variant="h3">
                      {jobData?.job_details?.job_title}
                      {jobData?.job_details?.company_name &&
                        " - " + jobData?.job_details?.company_name}
                      {jobData?.job_details?.req_id &&
                        " - " + jobData?.job_details?.req_id}
                    </Typography>
                  )}
                </CommonGrid2>

                <CommonGrid2
                  item
                  size={{ xs: 12, md: 8, sm: 12 }}
                  gap="10px"
                  display="flex"
                  justifyContent={"end"}
                  alignItems={"end"}
                  flexDirection={"column"}
                  height={"fit-content"}
                >
                  <CommonGrid2
                    display={"flex"}
                    flexDirection={"row"}
                    size="12"
                    gap={"1rem"}
                    flexWrap={"wrap"}
                    width={"100%"}
                    justifyContent="end"
                  >
                    {jobData?.job_details?.is_scored && (
                      <CommonButton
                        variant="outlined"
                        color="#344054"
                        border="#D0D5DD"
                        fontWeight="600"
                        value="Generate Search Strings"
                        padding={"10px 20px"}
                        onClick={handleSearchStringClick}
                      />
                    )}

                    <CommonButton
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      fontWeight="600"
                      padding={"10px 20px"}
                      value="Share"
                      disabled={true}
                    />
                    {alignment === "View" &&
                      (jobData?.job_details?.is_scored
                        ? jobData?.show_rescore
                        : jobData?.job_details?.is_scored) && (
                        <CommonButton
                          variant="contained"
                          color="white"
                          background="#7F56D9"
                          fontWeight="600"
                          padding={"10px 20px"}
                          value={"Re-Score"}
                          // type="submit"
                          disabled={
                            isLoading ||
                            activeButtonDisable ||
                            buttonLoader.disabled
                          }
                          loading={buttonLoader.rescoreLoading}
                          onClick={() => {
                            setSelectedValidation(validationSchemaScore);
                            setTimeout(async () => {
                              let data = await validateForm();
                              handleScrollToError(data);
                              if (Object.keys(data).length === 0) {
                                handleSubmitJob(values, true);
                              }
                            }, 0);
                          }}
                        />
                      )}

                    {alignment === "View" && (
                      <CommonButton
                        variant="contained"
                        color="white"
                        background="#7F56D9"
                        fontWeight="600"
                        padding={"10px 20px"}
                        value={
                          !jobData?.job_details?.is_scored
                            ? "Score"
                            : "Activate"
                        }
                        type="submit"
                        disabled={
                          buttonLoader.disabled ||
                          isLoading ||
                          activeButtonDisable
                        }
                        loading={buttonLoader.activateLoading}
                        onClick={async () => {
                          setSelectedValidation(
                            !jobData?.job_details?.is_scored
                              ? validationSchemaScore
                              : validationSchema
                          );
                          let data = await validateForm();

                          setTimeout(() => {
                            handleScrollToError(data);
                          }, 0);
                        }}
                      />
                    )}
                    {alignment === "Edit" && (
                      <CommonButton
                        value="Submit"
                        padding="5px 2rem"
                        fontWeight="600"
                        disabled={
                          buttonLoader.disabled ||
                          isLoading ||
                          activeButtonDisable
                        }
                        loading={buttonLoader.submitLoading}
                        onClick={() => {
                          setSelectedValidation(validationSchema);
                          setTimeout(async () => {
                            let data = await validateForm();
                            handleScrollToError(data);
                            if (Object.keys(data).length === 0) {
                              handleSubmitJob(values, "submit");
                            }
                          }, 0);
                        }}
                      />
                    )}

                    <ToogleButton
                      ToogleData={ToogleData}
                      handleChange={handleToogleChange}
                      alignment={alignment}
                      border={"1px solid #D0D5DD"}
                      borderRadius={"8px"}
                      activeColor={"#344054"}
                      color={"#344054"}
                      activeBackgroundColor="#F9FAFB"
                      backgroundColor="#FFFF"
                      disabled={isLoading || activeButtonDisable}
                    />
                  </CommonGrid2>
                  <CommonGrid2
                    display={"flex"}
                    gap={"0.5rem"}
                    alignItems={"center"}
                  >
                    <Typography variant="h6">Vision Assist</Typography>
                    <Switch
                      checked={iconVisible}
                      onChange={(event) => setIconVisible(event.target.checked)}
                    />
                  </CommonGrid2>
                </CommonGrid2>
              </Grid2>
              {((alignment === "View" &&
                jobData?.job_details?.insights &&
                Object.keys(jobData?.job_details?.insights).length !== 0) ||
                (alignment === "Edit" && JobJobEditData.is_scored)) && (
                <Result
                  jobData={
                    alignment === "View" ? jobData?.job_details : JobJobEditData
                  }
                  isLoading={fixLoader ? false : isLoading}
                  fetchJobsList={fetchJobsList}
                  job_id={jobId}
                  fixLoader={fixLoader}
                  toolTipOpen={
                    fixLoader ? false : alignment === "View" ? true : false
                  }
                />
              )}

              {(alignment === "View"
                ? jobData?.job_details?.relevancy_index !== null
                : values["relevancy_index"] !== null) && (
                <Grid2 marginTop={"3rem"}>
                  <RelevancyTable
                    alignment={alignment}
                    isLoading={isLoading}
                    resultHeaderData={resultHeaderData}
                    onDragEnd={onRelevancyDragEnd}
                    dragContent={
                      alignment === "View"
                        ? jobData?.job_details?.relevancy_index
                        : values["relevancy_index"]
                    }
                    iconVisible={iconVisible}
                  />
                </Grid2>
              )}
              {accordionDataView.map((item, index) => (
                <CustomAccordion
                  expanded={expandedIndex.includes(index)}
                  onChange={() =>
                    setExpandedIndex((prev) =>
                      prev.includes(index)
                        ? prev.filter((i) => i !== index)
                        : [...prev, index]
                    )
                  }
                  // borderBottom={"1px solid #EAECF0"}
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography
                      variant="h4"
                      margin={"15px 0px"}
                      fontWeight={600}
                    >
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>{item.component}</AccordionDetails>
                </CustomAccordion>
              ))}

              <AddNewSkillDialog
                open={skillDialog}
                handleClose={handleClose}
                dialogData={dialogData}
                jobModifyData={jobModifyData}
                handleSkillClick={handleSkillClick}
                buttonLoading={buttonLoading}
              />
              <SkillDeleteDialog
                deleteDialog={deleteDialog}
                handleDeleteDialogClose={handleDeleteDialogClose}
                handleDeleteSkill={handleDeleteSkill}
                buttonLoading={buttonLoading}
                title="Delete Skill"
                content={dialogData?.data?.skill}
              />

              <SkillDeleteDialog
                deleteDialog={seededDialog.deleteOpen}
                handleDeleteDialogClose={handleSeededClose}
                handleDeleteSkill={handleDeleteSeeded}
                buttonLoading={seededDialog.loading}
                title="Delete Seeded Pre-Screen"
                content={seededDialog.content}
              />
              <AddSeededDialog
                open={seededDialog.addOpen}
                handleDialogClose={handleSeededClose}
                handleSubmit={handleAddSubmit}
                loading={seededDialog.loading}
              />
            </Form>
          );
        }}
      </Formik>
      <NewDeleteNote
        deleteNoteDialogOpen={deleteNoteDialogOpen}
        handleDeleteNoteDialogClose={handleDeleteNoteDialogClose}
        noteList={noteList}
        handleDeleteNoteById={handleDeleteNoteById}
        noteLoading={noteList.loading}
      />
      <DialogCandidateHub
        open={reScore.noteOpen}
        disabled={reScore.disabled}
        closeIcon={true}
        handleButttonClose={handleRescoreClose}
        footer={<Footer />}
        icon={""}
        title="Re-Score"
        titlepadding={"0px 0px 16px"}
        content={"Do you want to rescore?"}
      />

      <CreateNote
        openNoteDialog={noteList.open}
        handleCloseNoteDialog={handleCloseNoteDialog}
        id={noteList.noteId}
        //
        noteUpperData={noteUpperData}
        typeData={typeData}
        noteList={noteList}
        handleNoteSubmit={handleCreateNote}
        loading={noteList.loading}
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />

      <DialogCandidateHub
        open={activate.activateOpen}
        handleButttonClose={handlePopupClose}
        icon={<PopupTickIco margin={"10px 0px 0px 10px"} />}
        footer={<ActivateFooter />}
        titlepadding={"0px 0px 10px"}
        title={`The job ${activate?.data?.order_no} has been activated`}
        content={"Do you want to share the job?"}
      />

      <DialogCandidateHub
        open={saveEditData.open}
        icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        title="Save to JEM"
        handleButttonClose={handleSaveCandidate}
        footer={<FooterSave />}
        titlepadding={"0px 0px 16px"}
        content={
          "You have unsaved changes. If you leave this page, your changes will be lost. Are you sure you want to proceed?"
        }
      />

      <DialogCandidateHub
        open={generateSearch.open}
        handleButttonClose={handleSearchStringClose}
        content={
          <CustomDiv>
            <Typography
              variant="h6"
              textAlign={"left"}
              fontWeight={600}
              marginBottom={"8px"}
            >
              Generate Search String{" "}
              <CustomTooltip
                arrow
                placement="top"
                title={generateSearch.copied ? "Copied!" : "Copy to Clipboard"}
                fontSize="12px"
                padding="5px 10px"
                margin="0px 0px 10px !important"
              >
                <IconButton
                  onClick={() =>
                    handleCopyClick(
                      jobData?.job_details?.insights?.boolean_search_strings
                    )
                  }
                  style={{ padding: "8px" }}
                  padding="0px"
                  onMouseLeave={handleCopyMouseLeave}
                >
                  <CopyIco size={22} />
                </IconButton>
              </CustomTooltip>
            </Typography>
            {jobData?.job_details?.insights?.boolean_search_strings}
          </CustomDiv>
        }
      />

      <ShareQuickMatch
        title="Share Job Post"
        shareDialogOpen={activate.inviteOpen}
        handleShareDialogClose={handlePopupClose}
        handleShareInvite={handleShareInvite}
        isLoading={activate.inviteLoading}
        condition={true}
        statusOptions={statusOptions}
        CustomMenuItem={CustomMenuItem}
      />
    </div>
  );
};
export default ReviewJobPost;
