import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DotsVerticalIco from "../../../../assets/icons/DotsVerticalIco";
import {
  StyledFlexRowView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  StyledTypo,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";

const data = [
  {
    type: "Current Title",
    location: "Product Designer",
  },
  {
    type: "Years at Company",
    location: "5 Years",
  },
  {
    type: "Key Achievements",
    location: "Achievement 1",
  },
  {
    type: "",
    location: "Achievement 1",
  },
  {
    type: "Professional Affiliations",
    location: "Affiliation 1",
  },
  {
    type: "",
    location: "Affiliation 2",
  },
];

function ExecutiveBio() {
  return (
    <TableContainerBorder sx={{ marginTop: "0px !important" }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableTitleTableCell sx={{ minWidth: "130px" }}>
              {"Education & Certifications"}
            </StyledTableTitleTableCell>

            <StyledTableTitleTableCell>{"Location"}</StyledTableTitleTableCell>
            <StyledTableTitleTableCell>{""}</StyledTableTitleTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <StyledTableBodyTableCell>{row.type}</StyledTableBodyTableCell>
              <TableCell>
                <StyledFlexRowView>
                  <StyledTypo
                    sx={{
                      display: index >= 2 && index <= 5 ? "list-item" : "block",
                      listStyleType: index >= 2 && index <= 5 ? "disc" : "none",
                      marginLeft: index >= 2 && index <= 5 ? "40px" : "0",
                    }}
                  >
                    {row.location}
                  </StyledTypo>
                </StyledFlexRowView>
              </TableCell>
              <TableCell>
                <DotsVerticalIco />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainerBorder>
  );
}

export default ExecutiveBio;
