import { ExpandMore } from "@mui/icons-material";
import {
  Avatar,
  Box,
  FormControl,
  Grid2,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DotsVerticalIco from "../../../../assets/icons/DotsVerticalIco";
import ShareIco from "../../../../assets/icons/ShareIco";
import UsIco from "../../../../assets/icons/UsIco";
import {
  CommonGrid2,
  StyledFlexColumnView,
  StyledFlexRowView,
  SubTitleTypo,
  TitleTypo,
} from "../../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../../quick-match/components/StyledComponent";
import { CommonTippy } from "../../style";
import CandidateFitIco from "../../../../assets/icons/CandidateFitIco";
import { CustomDiv } from "../../../chat-bot/style";
import StrongFitIco from "../../../../assets/icons/StrongFitIco";

function CompareProfile({
  matchedData,
  getlistResumeData,
  listResumeData,
  handleReplaceSecondaryId,
}) {
  const [expandMoreAnchor, setExpandMoreAnchor] = useState(null);
  const [dotsAnchor, setDotsAnchor] = useState(null);
  const isExpandMoreMenuOpen = Boolean(expandMoreAnchor);
  const isDotsMenuOpen = Boolean(dotsAnchor);
  const navigate = useNavigate();
  const handleExpandMoreClick = (event) => {
    setExpandMoreAnchor(event.currentTarget);
  };

  const handleDotsClick = (event) => {
    setDotsAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setExpandMoreAnchor(null);
    setDotsAnchor(null);
  };

  const handleViewResume = () => {
    navigate(`/candidates/candidate-view?view-id=${matchedData.resume_id}`);
  };

  const statusOptions = [
    { label: "Hired", color: "#17B26A" },
    { label: "In Progress", color: "#9E77ED" },
    { label: "Rejected", color: "#F04438" },
  ];

  const dotsMenuItems = [
    // {
    //   label: "View Resume",
    //   icon: <ClipBoardCheck />,
    //   onClick: handleViewResume,
    // },
    {
      label: "Share",
      icon: <ShareIco />,
    },
  ];

  const candidateGridData = [
    {
      title: "Inferred Title",
      contentKey: matchedData?.resume_info?.inferred_title || "-",
    },
    {
      title: matchedData?.resume_info?.pay_rate?.label,
      contentKey: matchedData?.resume_info?.pay_rate?.value || "-",
    },
    {
      title: matchedData?.resume_info?.location?.label,
      contentKey: matchedData?.resume_info?.location?.value || "-",
      icon: <UsIco />,
    },
    {
      title: "Highest Degree",
      contentKey: matchedData?.resume_info?.highest_degree || "-",
      isLink: true,
    },
  ];

  return (
    <div
      style={{
        marginTop: "16px",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        borderRadius: "12px",
        border: "1px solid #D0D5DD ",
      }}
    >
      <StyledSpaceBetweenBox
        sx={{ borderBottom: "1px solid #D0D5DD", padding: "12px" }}
      >
        <StyledFlexRowView>
          <Avatar
            alt={matchedData?.resume_info?.candidate_name || "Avatar"}
            src={matchedData?.avatar_url}
            sx={{
              width: 64,
              height: 64,
              backgroundColor: "#F2F4F7",
              color: "#667085",
              border: "1px solid #D0D5DD",
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "24px",
            }}
          >
            {!matchedData?.avatar_url &&
              matchedData?.resume_info?.candidate_name?.[0]?.toUpperCase()}
          </Avatar>
          <CommonGrid2 display={"flex"} flexDirection={"column"}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#101828",
                display: "flex",
                alignItems: "center",
              }}
            >
              {matchedData?.resume_info?.candidate_name}
            </Typography>
            {matchedData?.resume_info?.candidate_fit && (
              <CommonGrid2
                display={"flex"}
                alignItems={"center"}
                gap={"5px"}
                marginTop={"4px"}
              >
                <CommonGrid2
                  background={
                    matchedData?.resume_info?.candidate_fit?.fit === "Weak Fit"
                      ? "#ff070026"
                      : matchedData?.resume_info?.candidate_fit?.fit ===
                        "Strong Fit"
                      ? "#ECFDF3"
                      : "#ffa6022e"
                  }
                  borderRadius="5px"
                  width="max-content"
                  padding="5px 10px"
                  display={"flex"}
                  alignItems={"center"}
                  gap={"5px"}
                >
                  {matchedData?.resume_info?.candidate_fit?.fit ===
                    "Strong Fit" && <StrongFitIco />}
                  <Typography
                    variant="p"
                    fontWeight={700}
                    color={
                      matchedData?.resume_info?.candidate_fit?.fit ===
                      "Weak Fit"
                        ? "#dd0600"
                        : matchedData?.resume_info?.candidate_fit?.fit ===
                          "Strong Fit"
                        ? "#067647"
                        : "#ffa500"
                    }
                  >
                    {matchedData?.resume_info?.candidate_fit?.fit}
                  </Typography>
                </CommonGrid2>
                <CommonTippy
                  content={matchedData?.resume_info?.candidate_fit?.explanation}
                  placement="top"
                  color={"#7F56D9"}
                  fontSize="13px"
                  appendTo="parent"
                  maxWidth={
                    window.innerWidth >= 1024
                      ? "800px"
                      : window.innerWidth >= 600
                      ? 600
                      : 300
                  }
                  lineHeight={1.5}
                >
                  <CommonGrid2
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                  >
                    <CandidateFitIco />
                  </CommonGrid2>
                </CommonTippy>
              </CommonGrid2>
            )}
          </CommonGrid2>

          {/* <FormControl>
            <Select
              value={"In Progress"}
              displayEmpty
              renderValue={(selected) => {
                const selectedOption = statusOptions.find(
                  (option) => option.label === selected
                );
                return (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        backgroundColor: selectedOption.color,
                        marginRight: "8px",
                      }}
                    />
                    <Typography>{selectedOption.label}</Typography>
                  </Box>
                );
              }}
              IconComponent={ExpandMore}
              sx={{
                "& .MuiSelect-select": {
                  paddingLeft: "10px !important",
                  paddingTop: "1px !important",
                  paddingBottom: "1px !important",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "6px",
                  paddingRight: "25px !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D0D5DD",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                },
                "& .MuiSelect-icon": {
                  fontSize: "14px",
                },
              }}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.label} value={option.label}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {option.label}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </StyledFlexRowView>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <IconButton
            size="small"
            sx={{
              border: "1px solid #D0D5DD",
              borderRadius: "8px",
              padding: "8px",
            }}
            onClick={handleExpandMoreClick}
          >
            <ExpandMore />
          </IconButton>
          <IconButton
            size="small"
            sx={{
              border: "1px solid #D0D5DD",
              borderRadius: "8px",
              padding: "10px",
            }}
            onClick={handleDotsClick}
          >
            <DotsVerticalIco />
          </IconButton>
        </Box>
      </StyledSpaceBetweenBox>
      <Menu anchorEl={dotsAnchor} open={isDotsMenuOpen} onClose={handleClose}>
        {dotsMenuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick?.();
              handleClose();
            }}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              {item.icon}
              <Typography>{item.label}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={expandMoreAnchor}
        open={isExpandMoreMenuOpen}
        onClose={handleClose}
      >
        {listResumeData.length > 0 ? (
          listResumeData.map((resume, index) => (
            <React.Fragment key={index}>
              {console.log(resume)}
              <MenuItem
                key={index}
                onClick={() => handleReplaceSecondaryId(resume.id)}
              >
                <Avatar
                  alt={resume?.name || "Avatar"}
                  src={
                    resume?.avatar_url ||
                    "https://mui.com/static/images/avatar/1.jpg"
                  }
                  sx={{ width: 24, height: 24, marginRight: 1 }}
                />
                <Typography>{resume?.name || "Candidate"}</Typography>
              </MenuItem>
            </React.Fragment>
          ))
        ) : (
          <MenuItem disabled>No data available</MenuItem>
        )}
      </Menu>

      <Grid2 container spacing={2} padding="12px 20px">
        {candidateGridData.map((item) => (
          <Grid2
            size={6}
            key={item.id}
            // backgroundColor="#F2F4F7"
            // borderRadius={"10px"}
            // padding={"10px"}
          >
            <StyledFlexColumnView gap={"6px"}>
              <TitleTypo fontSize="15px" fontWeight={600} color="#5B738B">
                {item.title}
              </TitleTypo>
              <SubTitleTypo fontSize="15px">{item.contentKey}</SubTitleTypo>
            </StyledFlexColumnView>
          </Grid2>
        ))}
      </Grid2>
    </div>
  );
}

export default CompareProfile;
