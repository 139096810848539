import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import Featuredicon from "../../../assets/images/featuredicon.png";
import { StyledDialog, StyledFlexColumnView } from "../../common-components/StyledComponents";

const ChangePassword = ({
    open,
    onClose,
    onApply,
    onReset
}) => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const toggleShowOldPassword = () => setShowOldPassword((prev) => !prev);
    const toggleShowNewPassword = () => setShowNewPassword((prev) => !prev);

    const isButtonDisabled = !oldPassword.trim() || !newPassword.trim() || !repeatPassword.trim();

    return (
        <>
            <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose} >
                <img
                    src={Featuredicon}
                    style={{ position: "absolute", padding: "8px", width: "80px" }}
                />
                <DialogActions>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </DialogActions>
                <DialogTitle>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                        {"Change Password"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid2 container direction="column" spacing={3}>
                        <Grid2 size={12}>
                            <StyledFlexColumnView>
                                <Typography
                                    sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                                >
                                    {"Old Password"}
                                </Typography>
                                <TextField
                                    fullWidth
                                    type={showOldPassword ? "text" : "password"} // Toggle password visibility
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    placeholder="Enter your old password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={toggleShowOldPassword} edge="end">
                                                    {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            backgroundColor: "#FFFFFF",
                                            border: "1px solid #D0D5DD",
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "12px",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            color: "#667085",
                                            opacity: 1,
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        },
                                    }}
                                />
                            </StyledFlexColumnView>
                        </Grid2>
                        <Grid2 size={12}>
                            <StyledFlexColumnView>
                                <Typography
                                    sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                                >
                                    {"New Password"}
                                </Typography>
                                <TextField
                                    fullWidth
                                    type={showNewPassword ? "text" : "password"}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Enter your new password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={toggleShowNewPassword} edge="end">
                                                    {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            backgroundColor: "#FFFFFF",
                                            border: "1px solid #D0D5DD",
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "12px",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            color: "#667085",
                                            opacity: 1,
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        },
                                    }}
                                />
                            </StyledFlexColumnView>
                        </Grid2>
                        <Grid2 size={12}>
                            <StyledFlexColumnView>
                                <Typography
                                    sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                                >
                                    {"Repeat New Password"}
                                </Typography>
                                <TextField
                                    fullWidth
                                    type={showOldPassword ? "text" : "password"}
                                    value={repeatPassword}
                                    onChange={(e) => setRepeatPassword(e.target.value)}
                                    placeholder="Repeat your new password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={toggleShowOldPassword} edge="end">
                                                    {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            backgroundColor: "#FFFFFF",
                                            border: "1px solid #D0D5DD",
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "12px",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            color: "#667085",
                                            opacity: 1,
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        },
                                    }}
                                />
                            </StyledFlexColumnView>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Forgot your Password? "}<span style={{ color: "#6941C6", fontWeight: 600 }} onClick={onReset}>{"Reset Password"}</span>
                            </Typography>
                        </Grid2>
                        <Button
                            variant="outlined"
                            color="white"
                            fontWeight="600"
                            value="Change Password"
                            padding="8px"
                            onClick={onApply}
                            disabled={isButtonDisabled}
                        >{"Change Password"}</Button>
                    </Grid2>
                </DialogContent>
            </StyledDialog>

        </>
    );
};

export default ChangePassword;
