import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import ManagePermissionsicon from "../../../assets/images/managePermissionsicon.png";
import CommonButton from "../../../component/Button";
import DropDownSelect from "../../../component/Select";
import {
  DotColor,
  StyledDialog,
} from "../../common-components/StyledComponents";

const ManagePermission = ({
  open,
  onClose,
  onApply,
  selectedUser,
  options,
  handleChangeStatus,
  dialog,
}) => {
  const CustomMenuItem = (item, index) => (
    <MenuItem value={item?.id} key={index} name={item?.name}>
      {item?.name}
    </MenuItem>
  );

  return (
    <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <img
        src={ManagePermissionsicon}
        style={{ position: "absolute", padding: "16px", width: "80px" }}
      />
      <DialogActions>
        {/* <Button onClick={onClose}>
          <CloseIcon />
        </Button> */}
        <CommonButton
          onClick={onClose}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          color="#7F56D9"
          value={<CloseIcon />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" marginTop={"1rem"}>
          {"Manage Permissions"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid2 container direction="column" spacing={3} fontFamily={"Inter"}>
          <Grid2 container direction="column" spacing={2} fontFamily={"Inter"}>
            {selectedUser.length !== 0 &&
              selectedUser.map((user, index) => (
                <Grid2 key={user.id} container alignItems="center" spacing={2}>
                  <Avatar alt={user.username} src={user.username} />
                  <Typography variant="body1">{user.username}</Typography>
                  <Grid2 item sx={{ marginLeft: "auto" }}>
                    <DropDownSelect
                      marginTop="0px"
                      removeAnimation={true}
                      data={options}
                      valueChange={true}
                      fullWidth={false}
                      title={"Select Status"}
                      CustomMenuItem={CustomMenuItem}
                      handleRenderValue={(selected) => {
                        const selectedOption = options.find(
                          (option) => option.id === selected
                        );
                        console.log(selected, options, selectedOption);
                        return (
                          <Box display={"flex"} alignItems={"center"}>
                            <DotColor
                              color={
                                selectedOption.name === "Recruiter"
                                  ? "#667085"
                                  : selectedOption.name === "Manager"
                                  ? "orange"
                                  : selectedOption.name === "Admin"
                                  ? "green"
                                  : "#667085"
                              }
                              marginRight="8px"
                            />
                            <Typography>{selectedOption?.name}</Typography>
                          </Box>
                        );
                      }}
                      customStyle={{
                        "& .MuiSelect-select": {
                          padding: "4px 32px 4px 10px !important",
                          borderRadius: "6px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            user.role_name === "Admin"
                              ? "#17B26A"
                              : user.role_name === "Recruiter"
                              ? "#667085"
                              : user.role_name === "Filled"
                              ? "#9E77ED"
                              : user.role_name === "Draft"
                              ? "Orange"
                              : "#F04438",
                          boxShadow: "0px 0px 1px 0px #101828",
                          borderWidth: "1.5px",
                        },
                        "& .MuiSelect-icon": {
                          right: "0rem",
                        },
                      }}
                      width={"115px"}
                      maxHeight={"215px"}
                      value={user.role_id}
                      handleChange={(e) => handleChangeStatus(e, index)}
                    />
                  </Grid2>
                </Grid2>
              ))}
          </Grid2>

          <CommonButton
            variant="contained"
            value={"Save Changes"}
            onClick={onApply}
            fontWeight="600"
            width={"100%"}
            padding={"10px"}
            disabled={dialog.disabled}
            loading={dialog.manageLoading}
          />
        </Grid2>
      </DialogContent>
    </StyledDialog>
  );
};

export default ManagePermission;
