import CloseIcon from "@mui/icons-material/Close";
import { CheckBox } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ActiveJobBackground from "../../assets/images/activeJobBackGround.png";
import CommonButton from "../../component/Button";
import {
  CommonGrid2,
  StyledDialog,
} from "../common-components/StyledComponents";
import { CommonTippy } from "../jobs/style";
import {
  FileContainer,
  StyledLinearContainer,
  StyledSpaceBetweenBox,
} from "../quick-match/components/StyledComponent";
import CommonLinearProgress from "../../component/Linearprogress";
import { getFileIcon, HandleSkeletion } from "../../utils/constants";
import { CustomDiv } from "../chat-bot/style";
import XCloseIco from "../../assets/icons/XCloseIco";

const AddJobPool = ({
  open,
  onClose,
  title = "Add to Job Pool",
  activeOptions = [],
  onApply,
  buttonValue = "Add",
  placeholder = "Add to Job Pool",
  disabled,
  buttonCloseDisabled,
  loading,
  files,
  isLoading,
}) => {
  return (
    <StyledDialog fullWidth open={open}>
      <img
        src={ActiveJobBackground}
        style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
        alt="Background"
      />
      <DialogActions>
        <CommonTippy
          content={"Button is disabled"}
          placement="top"
          disabled={!buttonCloseDisabled}
          color="#7F56D9"
          fontWeight={600}
          fontSize={"13px"}
        >
          <CustomDiv>
            {/* <Button onClick={onClose} disabled={buttonCloseDisabled}>
              <CloseIcon />
            </Button> */}
            <CommonButton
              onClick={onClose}
              disabled={buttonCloseDisabled}
              padding={"10px"}
              background={"rgba(127, 86, 217, 0.04)"}
              border="rgba(127, 86, 217, 0.5)"
              height={"100%"}
              value={<XCloseIco />}
              boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
            />
          </CustomDiv>
        </CommonTippy>
      </DialogActions>
      <DialogTitle>
        <Typography
          sx={{ mt: 2 }}
          color="#101828"
          fontSize={"18px"}
          fontWeight={600}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CommonGrid2 container direction="column" gap={2}>
          {activeOptions.map((option, index) => (
            <CommonGrid2 item key={index}>
              {option.label && (
                <Typography
                  color="#344054"
                  fontSize={14}
                  fontFamily="Inter"
                  fontWeight={500}
                >
                  {option.label}
                </Typography>
              )}
              {isLoading ? (
                <HandleSkeletion height={80} width={"100%"} />
              ) : (
                <CommonGrid2 container alignItems="center" spacing={2}>
                  <CommonGrid2 size={loading ? 8.5 : 9.5}>
                    <Autocomplete
                      options={option.options || []}
                      getOptionLabel={(opt) => opt.label || opt}
                      onChange={(event, value) => option.onChange(value)}
                      renderInput={(params) => (
                        <TextField {...params} placeholder={placeholder} />
                      )}
                      sx={{
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "5px",
                          border: "1px solid #D0D5DD",
                          alignItems: "center",
                          borderRadius: "8px",
                          backgroundColor: "#FFFFFF",
                        },
                        "& .MuiInputBase-input::placeholder": {
                          color: "#667085",
                          opacity: 1,
                          fontSize: "16px",
                          fontWeight: 400,
                        },
                      }}
                    />
                  </CommonGrid2>
                  <CommonGrid2 size={loading ? 3.5 : 2.5}>
                    <CommonButton
                      variant="contained"
                      color="white"
                      background="#7F56D9"
                      fontWeight="600"
                      value={buttonValue}
                      padding="10px"
                      width={"100%"}
                      loading={loading}
                      onClick={onApply}
                      disabled={disabled}
                    />
                  </CommonGrid2>
                </CommonGrid2>
              )}
            </CommonGrid2>
          ))}
        </CommonGrid2>
        <CommonGrid2 marginTop={"1rem"}>
          {files &&
            loading &&
            files.length !== 0 &&
            files.file.map((item, index) => (
              <FileContainer
                key={index}
                elevation={1}
                gap={"1rem"}
                padding={"6px 10px 3px"}
                margin="16px 0px 0px"
                borderRadius="7px"
              >
                <Box display={"flex"} alignItems={"flex-start"} width={"20px"}>
                  {getFileIcon(item)}
                </Box>

                <Box width={"100%"}>
                  <StyledSpaceBetweenBox>
                    <Typography
                      color="#344054"
                      fontSize={"14px"}
                      fontWeight={700}
                      textAlign={"start"}
                    >
                      {item.name}
                    </Typography>
                    <IconButton
                      sx={{
                        padding: "0px",
                        cursor: "auto",
                      }}
                    >
                      {item.progress === 100 && (
                        <CheckBox sx={{ color: "#7F56D9" }} />
                      )}
                    </IconButton>
                  </StyledSpaceBetweenBox>
                  <Typography
                    color="#475467"
                    fontSize={"14px"}
                    fontWeight={500}
                  >
                    {item.size}
                  </Typography>
                  {item.progress >= 0 && (
                    <CommonTippy
                      content={item.message}
                      visible={item.visible}
                      interactive={true}
                      trigger="click"
                      hideOnClick={false}
                      zIndex={2}
                      color="red"
                    >
                      <StyledLinearContainer>
                        <CommonLinearProgress
                          variant={
                            item.progress && !item.visible
                              ? "buffer"
                              : "determinate"
                          }
                          value={item.progress}
                          valueBuffer={item.buffer}
                          height={"6px"}
                          borderRadius={"2px"}
                          backgroundColor={"#E0E0E0"}
                          barbackgroundColor={item.visible ? "red" : "#7F56D9"}
                          dashedmarginTop={"1px"}
                          dashedbackgroundImage={
                            item.visible
                              ? "radial-gradient(rgb(255 46 0) 0%, rgb(255 0 0) 16%, #00000000 42%)"
                              : "radial-gradient(rgb(82 0 255) 0%, rgb(82 0 255) 16%, #00000000 42%)"
                          }
                        />

                        <Typography
                          color="#344054"
                          fontSize={"14px"}
                          fontWeight={600}
                        >
                          {item.progress}%
                        </Typography>
                      </StyledLinearContainer>
                    </CommonTippy>
                  )}
                </Box>
              </FileContainer>
            ))}
        </CommonGrid2>
      </DialogContent>
    </StyledDialog>
  );
};

export default AddJobPool;
