import CloseIcon from "@mui/icons-material/Close";
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import ChevronDownIco from "../../../../assets/icons/ChevronDownIco";
import NoteBackgroundImg from "../../../../assets/images/note-background.png";
import { StyledDialog, StyledFlexColumnView } from "../../../common-components/StyledComponents";

const ScheduleInterview = ({
    open,
    onClose,
    onApply,
    selectedUsers
}) => {

    return (
        <>
            <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
                <img
                    src={NoteBackgroundImg}
                    style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
                />
                <DialogActions>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </DialogActions>
                <DialogTitle>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                        {"Schedule Interview"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid2 container direction="column" spacing={3} fontFamily={"Inter"}>
                        <Grid2 item xs={12}>
                            <Grid2
                                container
                                alignItems="center"
                                spacing={2}

                            >
                                <Grid2 size={12}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                                        >
                                            {"Interviewer"}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "8px",
                                                padding: "12px",
                                                borderRadius: "8px",
                                                backgroundColor: "#FFFFFF",
                                                border: "1px solid #1018280D",
                                                boxShadow: "0px 1px 2px 0px #1018280D",
                                            }}
                                        >
                                            <Avatar
                                                alt="Olivia Rhye"
                                                src="https://mui.com/static/images/avatar/1.jpg"

                                                sx={{ width: 32, height: 32 }}
                                            />
                                            <Typography
                                                sx={{
                                                    color: "#101828",
                                                    fontWeight: 400,
                                                    fontSize: "16px",
                                                }}
                                            >
                                                {"Olivia Rhye (you)"}
                                            </Typography>
                                        </Box>
                                    </StyledFlexColumnView>
                                </Grid2>
                                <Grid2 size={12}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            sx={{ fontWeight: 500, fontSize: "16px", color: "#101828" }}
                                        >
                                            {"Time"}
                                        </Typography>
                                        <Autocomplete
                                            disablePortal
                                            options={["Mon, Nov 25, 1:00PM"]}
                                            defaultValue={["Mon, Nov 25, 1:00PM"]}
                                            sx={{
                                                "& .MuiAutocomplete-inputRoot": {
                                                    padding: "5px",
                                                    border: "1px solid #1018280D",
                                                    alignItems: "center",
                                                    borderRadius: "8px",
                                                    color: "#101828",
                                                    backgroundColor: "#FFFFFF",
                                                    boxShadow: "0px 1px 2px 0px #1018280D"
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#101828",
                                                    opacity: 1,
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            )}
                                            popupIcon={
                                                <IconButton sx={{ padding: 0 }}>
                                                    <ChevronDownIco />
                                                </IconButton>
                                            }
                                        />
                                    </StyledFlexColumnView>
                                </Grid2>

                            </Grid2>

                        </Grid2>
                        <Button
                            variant="contained"
                            fontWeight="600"
                            onClick={onApply}
                        >
                            {"Schedule"}
                        </Button>

                    </Grid2>
                </DialogContent>
            </StyledDialog >
        </>
    );
};

export default ScheduleInterview;
