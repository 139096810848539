import { Switch, Typography } from "@mui/material";
import React from "react";
import "tippy.js/dist/tippy.css";
import CommonButton from "../../../../component/Button";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import SkillData from "./SkillTable";
import { Field } from "formik";

const Skills = ({
  primaryData,
  secondaryData,
  headerData,
  alignment,
  handleDeleteSkillRow,
  handleAddNewSkill,
  tableLoading,
  toggleRow,
  open,
  criticalityData,
  handleSkillToogleChange,
  tableContainerRef,
  handleSkillTextChange,
  isLoading,
}) => {
  return (
    <CommonGrid2 container spacing={2} flexDirection={"row"}>
      <CommonGrid2 size={6}>
        <CommonGrid2
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h5" fontWeight={500}>
            Primary Attributes
          </Typography>
          <Field name={"score_hard_skills"}>
            {({ field, form }) => {
              return (
                alignment === "Edit" && (
                  <Switch
                    defaultChecked={form.values["score_hard_skills"]}
                    onChange={(event) => {
                      form.setFieldValue(
                        "score_hard_skills",
                        event.target.checked
                      );
                    }}
                  />
                )
              );
            }}
          </Field>
        </CommonGrid2>

        <SkillData
          rowData={primaryData}
          name={"primary"}
          tableLoading={tableLoading}
          toggleRow={toggleRow}
          open={open}
          criticalityData={criticalityData}
          handleSkillToogleChange={handleSkillToogleChange}
          tableContainerRef={tableContainerRef}
          handleSkillTextChange={handleSkillTextChange}
          headerData={headerData}
          handleDeleteSkillRow={handleDeleteSkillRow}
          alignment={alignment}
          isLoading={isLoading}
        />
        {alignment === "Edit" && (
          <Field name={"skillData"}>
            {({ field, form }) => {
              return (
                <CommonButton
                  variant="outlined"
                  color="#344054"
                  border="#D0D5DD"
                  fontWeight="600"
                  value="Add New"
                  marginTop="1rem"
                  onClick={() =>
                    handleAddNewSkill("extracted_hard_skills", form)
                  }
                />
              );
            }}
          </Field>
        )}
      </CommonGrid2>

      <CommonGrid2 size={6}>
        <CommonGrid2
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h5" fontWeight={500}>
            Secondary Attributes
          </Typography>
          <Field name={"score_soft_skills"}>
            {({ field, form }) => {
              return (
                alignment === "Edit" && (
                  <Switch
                    defaultChecked={form.values["score_soft_skills"]}
                    onChange={(event) => {
                      form.setFieldValue(
                        "score_soft_skills",
                        event.target.checked
                      );
                    }}
                  />
                )
              );
            }}
          </Field>
        </CommonGrid2>
        <SkillData
          rowData={secondaryData}
          name={"secondary"}
          tableLoading={tableLoading}
          toggleRow={toggleRow}
          open={open}
          criticalityData={criticalityData}
          handleSkillToogleChange={handleSkillToogleChange}
          tableContainerRef={tableContainerRef}
          headerData={headerData}
          handleDeleteSkillRow={handleDeleteSkillRow}
          handleSkillTextChange={handleSkillTextChange}
          alignment={alignment}
          isLoading={isLoading}
        />
        {alignment === "Edit" && (
          <Field name={"skillData"}>
            {({ field, form }) => {
              return (
                <CommonButton
                  variant="outlined"
                  color="#344054"
                  border="#D0D5DD"
                  fontWeight="600"
                  value="Add New"
                  marginTop="1rem"
                  onClick={() =>
                    handleAddNewSkill("extracted_soft_skills", form)
                  }
                />
              );
            }}
          </Field>
        )}
      </CommonGrid2>
    </CommonGrid2>
  );
};
export default Skills;
