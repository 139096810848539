import { Switch, Tooltip, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import "tippy.js/dist/tippy.css";
import CommonButton from "../../../../component/Button";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import SkillTable from "./SkillTable";

const Skills = ({
  alignment,
  handleAddNewSkill,
  jobModifyData,
  handleSkillToogleChange,
  handleSkillTextChange,
  isLoading,
  highPriority,
  lowPriority,
  mediumPriority,
  recommended,
  headerData,
  handleDeleteSkillRow,
  iconVisible,
}) => {
  const skillSections = [
    {
      title: "Highest Priority (Core)",
      fieldName: "high_priority",
      rowData: highPriority,
      scoreFieldName: "score_hard_skills",
    },
    {
      title: "High Priority (Complementary)",
      fieldName: "low_priority",
      rowData: lowPriority,
      scoreFieldName: "score_soft_skills",
    },
    {
      title: "Recommended based on Historical/Empirical data",
      fieldName: "recommended",
      rowData: recommended,
      scoreFieldName: "score_soft_skills",
    },
    {
      title: "Medium Priority",
      fieldName: "medium_priority",
      rowData: mediumPriority,
      scoreFieldName: "score_soft_skills",
    },
  ];

  const { values, errors, setFieldValue } = useFormikContext();

  const debounceTimer = React.useRef(null);
  const [skillData, setSkillData] = useState({
    high_priority: values?.["skillData"]?.["high_priority"],
    low_priority: values?.["skillData"]?.["low_priority"],
    medium_priority: values?.["skillData"]?.["medium_priority"],
    recommended: values?.["skillData"]?.["recommended"],
  });
  useEffect(() => {
    setSkillData({
      high_priority: values?.["skillData"]?.["high_priority"],
      low_priority: values?.["skillData"]?.["low_priority"],
      medium_priority: values?.["skillData"]?.["medium_priority"],
      recommended: values?.["skillData"]?.["recommended"],
    });
  }, [values]);

  const handleSkillText = (event, index, name, fieldName) => {
    const getData = event.target.value;
    const dataKey = name;
    setSkillData((prev) => {
      const updatedSkillData = prev?.[dataKey]?.map((row, i) => {
        if (i === index) {
          return { ...row, [fieldName]: getData };
        }
        return row;
      });

      return {
        ...prev,
        [dataKey]: updatedSkillData,
      };
    });

    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      handleSkillTextChange(event, index, values, name, fieldName);
    }, 500);
  };

  return (
    <>
      {alignment === "Edit" && (
        <Tooltip
          className={errors?.["checkcondition"] && "Mui-error"}
          open={errors?.["checkcondition"] ? true : false}
          title={errors?.["checkcondition"]}
          arrow
          zIndex={9}
          placement="right"
          PopperProps={{
            sx: {
              zIndex: 1300,
            },
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "red",
                "& .MuiTooltip-arrow": {
                  "&::before": {
                    backgroundColor: "red",
                  },
                },
              },
            },
          }}
        >
          <CommonGrid2 width={"max-content"}>
            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              padding="9px 20px"
              fontWeight="600"
              value="Add New"
              onClick={() => handleAddNewSkill(values)}
            />
          </CommonGrid2>
        </Tooltip>
      )}

      <CommonGrid2
        container
        flexDirection="row"
        columnSpacing={2}
        rowSpacing={4}
        marginTop={alignment === "Edit" ? "2rem" : "0px"}
      >
        {skillSections.map(({ title, fieldName, rowData, scoreFieldName }) => {
          return (
            rowData?.length !== 0 && (
              <CommonGrid2 size={6}>
                <CommonGrid2
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5" fontWeight={500}>
                    {title}
                  </Typography>
                  {alignment === "Edit" && (
                    <Switch
                      checked={values[scoreFieldName]}
                      onChange={(event) =>
                        setFieldValue(scoreFieldName, event.target.checked)
                      }
                    />
                  )}
                </CommonGrid2>
                <SkillTable
                  rowData={
                    alignment === "Edit" ? skillData[fieldName] : rowData
                  }
                  name={fieldName}
                  jobModifyData={jobModifyData}
                  handleSkillToogleChange={handleSkillToogleChange}
                  headerData={headerData}
                  handleDeleteSkillRow={handleDeleteSkillRow}
                  handleSkillText={handleSkillText}
                  alignment={alignment}
                  isLoading={isLoading}
                  iconVisible={iconVisible}
                />
              </CommonGrid2>
            )
          );
        })}
      </CommonGrid2>
    </>
  );
};

export default Skills;
