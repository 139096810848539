import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import RecordingIco from "../../../../assets/icons/RecordingIco";
import CommonButton from "../../../../component/Button";
import {
  StyledDialog,
  StyledFlexColumnView,
} from "../../../common-components/StyledComponents";
import XCloseIco from "../../../../assets/icons/XCloseIco";
import NoteBackgroundImg from "../../../../assets/images/note-background.png";
import CommonTextField from "../../../../component/Input";
import { Form, Formik } from "formik";
import * as Yup from "yup";

function ResumeCreateNote({
  openNoteDialog,
  handleCloseNoteDialog,
  openNote,
  handleSubmit,
}) {
  const initialValues = {
    note: openNote?.note || "",
    id: openNote?.id || "",
  };

  const validationSchema = Yup.object({
    note: Yup.string().required("Note is required"),
  });

  return (
    <StyledDialog
      fullWidth
      maxWidth={"xs"}
      open={openNoteDialog}
      onClose={handleCloseNoteDialog}
    >
      <img
        src={NoteBackgroundImg}
        style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
      />
      <DialogActions>
        {/* <Button onClick={handleCloseNoteDialog} sx={{ marginTop: "10px" }}>
          <XCloseIco />
        </Button> */}
        <CommonButton
          onClick={handleCloseNoteDialog}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          value={<XCloseIco />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {openNote.id ? "Update Note" : "Create Note"}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            handleSubmit(values);
          }}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <Grid2 spacing={2} container>
                  <Grid2 size={12}>
                    <StyledFlexColumnView>
                      <Typography
                        color="#344054"
                        fontSize={14}
                        fontFamily={"Inter"}
                        fontWeight={500}
                      >
                        Note
                      </Typography>

                      <CommonTextField
                        padding={"0px"}
                        placeholder="Enter your note"
                        multiline
                        rows={4}
                        value={values.note}
                        error={errors.note && touched.note}
                        onChange={(e) => {
                          setFieldValue("note", e.target.value);
                        }}
                        fullWidth
                        placeholderFontSize="15px"
                        fontSize={"14px"}
                      />
                      {errors.note && touched.note && (
                        <Typography
                          color="error"
                          marginTop={"-5px"}
                          fontWeight={600}
                          fontSize={"13px"}
                        >
                          {errors.note}
                        </Typography>
                      )}
                    </StyledFlexColumnView>
                  </Grid2>

                  <Grid2 size={12}>
                    <CommonButton
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      fontWeight="600"
                      width={"100%"}
                      value={openNote.id ? "Start Recording" : "Record"}
                      padding="10px 15px"
                      startIcon={<RecordingIco />}
                      disabled={true}
                    />
                  </Grid2>

                  <Grid2 size={12}>
                    <CommonButton
                      width="100%"
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      fontWeight="600"
                      value={openNote.id ? "Update" : "Create Note"}
                      padding="10px 20px"
                      disabled={openNote.loading}
                      loading={openNote.loading}
                      type="submit"
                    />
                  </Grid2>
                </Grid2>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
}

export default ResumeCreateNote;
