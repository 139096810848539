import { Divider, Grid2 } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addDameNotes,
  deleteDameNote,
  getCompareCandidates,
  getDameNotes,
  getMatchedJdlistResumeData,
  updateDameNote,
} from "../../../api/Api";
import Breadcrumbs from "../../../component/Breadcrumbs";
import {
  CompareChatBot,
  CompareChatBotGetCall,
} from "../../../redux/action/ChatBot";
import { DELETE, GET, POST, PUT } from "../../../redux/axios/Axios";
import { HandleSkeletion } from "../../../utils/constants";
import ChatBot from "../../chat-bot";
import AddUpdateNote from "../../common-components/AddUpdateNote";
import CommonAccordion from "../../common-components/CommonAccordion";
import DeleteNote from "../../common-components/DeleteNote";
import Notes from "../job-information/notes";
import Experience from "../jobs-candidate/components/Experience";
import MatchingSummary from "../jobs-candidate/components/MatchingSummary";
import StrengthWeak from "../jobs-candidate/components/StrengthWeak";
import { ChatBotRelative } from "../style";
import Actions from "./components/Actions";
import CompareProfile from "./components/CompareProfile";

function JobsCandidateCompare() {
  const navigate = useNavigate();
  const param = useParams();
  const firstResumeId = param.firstResumeId;
  const secondResumeId = param.secondResumeId;
  const [pageLoader, setPageLoader] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [listResumeData, setListResumeData] = useState([]);
  const query = new URLSearchParams(useLocation().search);
  const jobId = query.get("id");
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "Email", added_by: "username" },
  ]);
  const dispatch = useDispatch();
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [deleteNoteDialogOpen, setDeleteNoteDialogOpen] = useState(false);
  const [noteId, setNoteId] = useState("");
  const [notesTableList, setNotesTableList] = useState([]);
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [notesTableListLoading, setNotesTableListLoading] = useState(false);
  const [currentResumeId, setCurrentResumeId] = useState(null);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [iconVisible, setIconVisible] = React.useState(true);
  const [chatBotData, setChatBotData] = React.useState({
    message: [
      { sender: "bot", text: "Hi, I am Hailey. How can I assist you today?" },
    ],
    value: "",
    loader: false,
    pageLoader: false,
    arrow: true,
    bubble: [],
    bubbleCondition: true,
  });
  const [isBottomVisible, setIsBottomVisible] = React.useState(true);
  const [noteHeaderData, setNoteHeaderData] = useState([
    { name: "Title", direction: "asc", sort: false, width: "50%" },
    { name: "Created By", direction: "asc", sort: false, width: "20%" },
    { name: "Date", direction: "asc", sort: false, width: "15%" },
    { width: "15%" },
  ]);

  const fetchCandidateCompareList = async () => {
    try {
      const res = await GET(
        getCompareCandidates(firstResumeId, secondResumeId)
      );
      console.log(res?.data, "ress");
      setTableList(res?.data);
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };
  const getlistResumeData = async () => {
    try {
      const res = await GET(
        getMatchedJdlistResumeData(firstResumeId, secondResumeId)
      );
      setListResumeData(res?.data || []);
      fetchCandidateCompareList();
    } catch (error) {
      console.error("Error fetching resume data:", error);
    }
  };

  useEffect(() => {
    setPageLoader(true);
    getlistResumeData();
    fetchCandidateCompareList();
  }, [firstResumeId, secondResumeId]);

  const handleReplaceSecondaryId = (id) => {
    const currentUrl = window.location.pathname;
    const title = query.get("title");
    const value = query.get("value");
    const newUrl = `${currentUrl.replace(param.secondResumeId, id)}${
      title
        ? `?title=${encodeURIComponent(title)}&value=${encodeURIComponent(
            value
          )}&id=${query.get("id")}&pagination=${query.get("pagination")}`
        : ""
    }`;

    navigate(newUrl);
  };

  const handleClickAddNote = (resumeId) => {
    setCurrentResumeId(resumeId);
    setOpenNoteDialog(true);
    setNoteId("");
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleCloseNoteDialog = () => {
    setNoteId("");
    setOpenNoteDialog(false);
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleDeleteNote = (row) => {
    setDeleteNoteDialogOpen(true);
    setNoteId(row?.id);
    setNoteList([
      {
        note: row?.note,
        interaction: row?.interaction,
        added_by: row?.added_by,
        created_at: row?.created_at,
      },
    ]);
  };

  const handleDeleteNoteDialogClose = () => {
    setDeleteNoteDialogOpen(false);
    setNoteId("");
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleUpdateNote = (row) => {
    if (row?.id) {
      setNoteId(row?.id);
      setOpenNoteDialog(true);
      setNoteList([
        {
          note: row?.note,
          interaction: row?.interaction,
          added_by: row?.added_by,
        },
      ]);
    }
  };

  const handleCreateNote = async (resumeId) => {
    setAddNoteLoading(true);
    if (resumeId) {
      const payload = noteList;
      if (noteId) {
        const res = await PUT(updateDameNote(noteId), noteList[0]);
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      } else {
        const res = await POST(addDameNotes(resumeId), payload);
        console.log(res?.data, "note");
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      }
      setAddNoteLoading(false);
      await fetchNotesTableList(firstResumeId, secondResumeId);
      setOpenNoteDialog(false);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
    }
  };

  const handleDeleteNoteById = async (row) => {
    const res = await DELETE(deleteDameNote(noteId));
    console.log(res?.data, "note");
    if (res?.success) {
      console.log(res?.data?.success, "tr");
      setSnackbarState({
        open: true,
        severity: "success",
        message: res?.message,
      });
      // setAddNoteLoading(false);
      await fetchNotesTableList(firstResumeId, secondResumeId);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
      setDeleteNoteDialogOpen(false);
      setNoteId("");
    } else {
    }
  };

  const handleNoteTextChange = (index, e) => {
    const newNoteList = [...noteList];
    newNoteList[index].note = e.target.value;
    setNoteList(newNoteList);
  };

  const fetchNotesTableList = async (firstResumeId, secondResumeId) => {
    setNotesTableListLoading(true);
    try {
      const [firstNotes, secondNotes] = await Promise.all([
        GET(getDameNotes(firstResumeId)),
        GET(getDameNotes(secondResumeId)),
      ]);

      setNotesTableList([
        { resumeId: firstResumeId, notes: firstNotes?.data },
        { resumeId: secondResumeId, notes: secondNotes?.data },
      ]);
    } catch (error) {
      console.error("Error fetching notes:", error);
    } finally {
      setNotesTableListLoading(false);
    }
  };

  useEffect(() => {
    fetchNotesTableList(firstResumeId, secondResumeId);
  }, [firstResumeId, secondResumeId]);

  const breadCrumbData = [
    {
      title: "Jobs",
      navigate: `/jobs?pagination=${query.get("pagination")}`,
    },
    {
      title: query.get("title"),
      navigate: `/jobs/jobs-view?id=${query.get("id")}&title=${query.get(
        "title"
      )}&value=${query.get("value")}&pagination=${query.get("pagination")}`,
    },
    {
      title: "Compare",
      active: true,
    },
  ];

  const handleChatBotClick = (value) => {
    setIconVisible(value);
  };

  const handleChatBotFetchData = async () => {
    setChatBotData((prev) => ({
      ...prev,
      pageLoader: true,
    }));
    try {
      let param = {
        first_match: firstResumeId,
        second_match: secondResumeId,
      };
      const data = await CompareChatBotGetCall(jobId, param);
      const messages = [];
      data?.data?.history.forEach((entry) => {
        if (entry.user) {
          const userMessage = {
            sender: "user",
            text: entry.user,
          };
          messages.push(userMessage);
        }
        if (entry.assistant) {
          const botMessage = {
            sender: "bot",
            text: entry.assistant,
          };
          messages.push(botMessage);
        }
      });

      setChatBotData((prev) => ({
        ...prev,
        message: [...prev.message, ...messages],
        bubble: data?.data?.bubble_questions,
        pageLoader: false,
      }));
      setIsBottomVisible(false);
    } catch (error) {
      setChatBotData((prev) => ({
        ...prev,
        pageLoader: false,
      }));
    }
  };

  const handleSendMessage = async () => {
    if (!chatBotData.value.trim()) return;
    let payload = {
      query: chatBotData.value,
      filter_criteria: {
        type: "match_data",
        job_data_id: query.get("id"),
      },
      match_ids: [firstResumeId, secondResumeId],
    };

    setChatBotData((prev) => ({
      ...prev,
      message: [...prev.message, { sender: "user", text: chatBotData.value }],

      loader: true,
      value: "",
    }));

    try {
      const data = await dispatch(CompareChatBot(payload));
      const botResponse = {
        sender: "bot",
        text: data?.data?.response,
      };
      setChatBotData((prev) => ({
        ...prev,
        message: [...prev.message, botResponse],

        loader: false,
      }));
    } catch (error) {
      const botResponse = {
        sender: "error",
        text: error?.data?.message,
      };
      setChatBotData((prev) => ({
        ...prev,
        message: [...prev.message, botResponse],

        loader: false,
        value: "",
      }));
    }
  };

  const handleChatBotChange = (event) => {
    setChatBotData((prev) => ({
      ...prev,
      value: event.target.value,
    }));
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleUpArrow = (condition) => {
    console.log(condition, "sflknsfklm");
    setChatBotData((prev) => ({
      ...prev,
      arrow: condition === "up" ? false : true,
    }));
  };

  const handleButtonClickByLabel = async (item) => {
    const senderResponse = {
      sender: "user",
      text: item.question,
    };
    setChatBotData((prev) => ({
      ...prev,
      message: [...prev.message, senderResponse],
      loader: true,
    }));
    setChatBotData((prev) => ({
      ...prev,
      arrow: !prev.arrow,
    }));

    let payload = {
      query: item.prompt,
      filter_criteria: {
        type: "match_data",
        job_data_id: query.get("id"),
      },
      match_ids: [firstResumeId, secondResumeId],
    };

    try {
      const data = await dispatch(CompareChatBot(payload));
      const botResponse = {
        sender: "bot",
        text: data?.data?.response,
      };
      setChatBotData((prev) => ({
        ...prev,
        message: [...prev.message, botResponse],

        loader: false,
      }));
    } catch (error) {
      const botResponse = {
        sender: "error",
        text: error?.data?.message,
      };
      setChatBotData((prev) => ({
        ...prev,
        message: [...prev.message, botResponse],

        loader: false,
        value: "",
      }));
    }
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Breadcrumbs breadCrumbData={breadCrumbData} />
      </Grid2>
      {pageLoader ? (
        Array.from(new Array(20)).map((el, ind) => (
          <Grid2 item size={4} key={`key-${ind}`}>
            <HandleSkeletion
              key={`key-${ind.toString()}`}
              width={"100%"}
              height={100}
            />
          </Grid2>
        ))
      ) : (
        <>
          {tableList?.candidates?.map((item, index) => (
            <Grid2 size={6}>
              <CompareProfile
                matchedData={item}
                getlistResumeData={getlistResumeData}
                listResumeData={listResumeData}
                handleReplaceSecondaryId={(id) => handleReplaceSecondaryId(id)}
              />
            </Grid2>
          ))}

          <Grid2 size={12}>
            <CommonAccordion
              title={"Overall"}
              children={
                <Grid2 container spacing={3}>
                  {tableList?.candidates?.map((item, index) => (
                    <Grid2 size={6}>
                      <MatchingSummary
                        matchedData={item}
                        handleScoreChange={""}
                      />
                    </Grid2>
                  ))}
                </Grid2>
              }
            />
          </Grid2>

          <Grid2 size={12}>
            <CommonAccordion
              title={"Strengths"}
              children={
                <Grid2 container spacing={3}>
                  {tableList?.candidates?.map((item, index) => (
                    <Grid2 size={6}>
                      <StrengthWeak size={12} matchedData={item} />
                    </Grid2>
                  ))}
                </Grid2>
              }
            />
          </Grid2>

          <Grid2 size={12}>
            <CommonAccordion
              title={"Experience"}
              children={
                <Grid2 container spacing={3}>
                  {tableList?.candidates?.map((item, index) => (
                    <Grid2 size={6}>
                      <Experience matchedData={item?.experience} />
                    </Grid2>
                  ))}
                </Grid2>
              }
            />
          </Grid2>

          <Grid2 size={12}>
            <CommonAccordion
              title={"Notes"}
              children={
                <Grid2 container spacing={3}>
                  {notesTableList.map((item, index) => (
                    <Grid2 key={item.resumeId} size={6}>
                      <Notes
                        handleClickAddNote={() =>
                          handleClickAddNote(item.resumeId)
                        }
                        handleDeleteNoteClick={handleDeleteNote}
                        handleDeleteNoteDialogClose={
                          handleDeleteNoteDialogClose
                        }
                        handleUpdateNoteClick={handleUpdateNote}
                        notesTableList={item.notes}
                        notesTableListLoading={notesTableListLoading}
                        headerData={noteHeaderData}
                      />
                    </Grid2>
                  ))}
                </Grid2>
              }
            />
          </Grid2>

          <Grid2 size={12}>
            <Divider />
          </Grid2>

          <Grid2 size={12}>
            <Grid2 container spacing={3}>
              {tableList?.candidates?.map((item, index) => (
                <Grid2 size={6}>
                  <Actions matchedData={item} />
                </Grid2>
              ))}
            </Grid2>
          </Grid2>
        </>
      )}
      <DeleteNote
        openNoteDialog={openNoteDialog}
        deleteNoteDialogOpen={deleteNoteDialogOpen}
        handleDeleteNoteDialogClose={handleDeleteNoteDialogClose}
        noteList={noteList}
        handleDeleteNoteById={handleDeleteNoteById}
      />
      <AddUpdateNote
        openNoteDialog={openNoteDialog}
        handleCloseNoteDialog={handleCloseNoteDialog}
        id={noteId}
        handleCreateNote={() => handleCreateNote(currentResumeId)}
        noteList={noteList}
        handleNoteTextChange={handleNoteTextChange}
        addNoteLoading={addNoteLoading}
      />
      <ChatBotRelative bottom={iconVisible ? "-6rem" : "2rem"}>
        <ChatBot
          iconVisible={iconVisible}
          handleClick={handleChatBotClick}
          chatBotData={chatBotData}
          handleChatBotFetchData={handleChatBotFetchData}
          handleSendMessage={handleSendMessage}
          handleChatBotChange={handleChatBotChange}
          isBottomVisible={isBottomVisible}
          setIsBottomVisible={setIsBottomVisible}
          handleUpArrow={handleUpArrow}
          handleButtonClickByLabel={handleButtonClickByLabel}
        />
      </ChatBotRelative>
    </Grid2>
  );
}

export default JobsCandidateCompare;
