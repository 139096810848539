import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Checkbox,
  Grid,
  Grid2,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import CandidatePool from "../../assets/icons/CandidatePool";
import EyeIco from "../../assets/icons/EyeIco";
import JobPool from "../../assets/icons/JobPool";
import ScoringSummary from "../../assets/icons/ScoringSummary";
import Breadcrumbs from "../../component/Breadcrumbs";
import CommonButton from "../../component/Button";
import CommonTextField from "../../component/Input";
import CustomMenuItem from "../../component/Menu";
import RunScoringTest from "../common-components/RunScoringTest";
import {
  CommonGrid2,
  TableContainerBorder
} from "../common-components/StyledComponents";

const ResumeIntelligence = () => {
  const breadCrumbData = [
    {
      title: "RICE",
      navigate: "/jobs",
    },
    {
      title: "Matches",
      active: true,
    },
  ];

  const headerData = ["Job", "Job Title", "Score", "Owner", ""];
  const sortedData = [
    {
      id: "1000",
      title: "Product Manager @ Company Name",
      status: "92%",
      Hours: "Olivia Rhye",
      client: "Talario",
      createdOn: "Oct 10,2024",
      avatarUrl: "https://mui.com/static/images/avatar/1.jpg"
    },
    {
      id: "1001",
      title: "HR Manager @ Company Name",
      status: "62%",
      Hours: "Koray Okums",
      client: "Curious Club",
      createdOn: "Oct 12,2024",
      avatarUrl: "https://mui.com/static/images/avatar/1.jpg"
    },
    {
      id: "1002",
      title: "UI Designer @ Company Name",
      status: "22%",
      Hours: "Koray Okums",
      client: "Talario",
      createdOn: "Oct 15,2024",
      avatarUrl: "https://mui.com/static/images/avatar/1.jpg"
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set());

  const handleClickDot = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor to the clicked icon
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleRowCheckboxChange = (event, rowId) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(rowId);
    } else {
      newSelectedRows.delete(rowId);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAllCheckboxChange = (event) => {
    if (event.target.checked) {
      const allRowIds = sortedData.map(row => row.id);
      setSelectedRows(new Set(allRowIds));
    } else {
      setSelectedRows(new Set());
    }
  };
  const [statusValues, setStatusValues] = useState(
    sortedData.map((row) => row.status)
  );

  const handleSliderChange = (index, newValue) => {
    const updatedValues = [...statusValues];
    updatedValues[index] = `${newValue}%`;
    setStatusValues(updatedValues);
  };


  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
  };
  const handleAddNote = () => {
    setOpenNoteDialog(true);
  };

  const menuData = [
    {
      title: "Add to Job Pool",
      image: <JobPool />,
    },
    { title: "View Scoring Summary", image: <ScoringSummary /> },
    { title: "View Job Information", image: <EyeIco size={"16"} /> },
    { title: "View Candidate Pool", image: <CandidatePool /> },
  ];

  return (
    <>
      <Breadcrumbs breadCrumbData={breadCrumbData} />

      <Grid2 container alignItems="center" marginTop={3}>
        <CommonGrid2 item size={6} display="flex" alignItems="center">
          <Typography variant="h2" color="#101828">
            Lead Product Design: Zahir Mays
          </Typography>
        </CommonGrid2>

        <CommonGrid2
          item
          size={6}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={"1rem"}
        >
          <CommonTextField placeholder="Search" startIcon={<SearchIcon />} />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Filters"
          />

          <CommonButton
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Add New"
          />
        </CommonGrid2>
      </Grid2>

      <TableContainerBorder>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selectedRows.size === sortedData.length}
                  onChange={handleSelectAllCheckboxChange}
                />
              </TableCell>
              {headerData.map((item, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={selectedRows.has(row.id)}
                    onChange={(event) => handleRowCheckboxChange(event, row.id)}
                  />
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell width={"40%"}>{row.title}</TableCell>
                <TableCell width={"20%"} >
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <Slider
                      value={parseInt(statusValues[index]) || 0}
                      min={0}
                      max={100}
                      onChange={(event, newValue) => handleSliderChange(index, newValue)}
                      sx={{
                        width: "100%",
                        color: "#17B26A",
                        "& .MuiSlider-thumb": {
                          backgroundColor: "white",
                          border: "2px solid #17B26A",
                        },
                        "& .MuiSlider-rail": {
                          backgroundColor: "#969696",
                        },
                      }}
                    />
                    <Typography variant="body2" style={{ color: "#101828" }}>
                      {statusValues[index]}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Avatar alt={row.name} src={row.avatarUrl} />
                    </Grid>
                    <Grid item>
                      {row.Hours}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell width={"2%"} aria-haspopup="true">
                  <MoreVertOutlinedIcon
                    style={{ cursor: "pointer" }}
                    onClick={(event) => handleClickDot(event)}
                  />
                </TableCell>
                <CustomMenuItem
                  open={open}
                  anchorEl={anchorEl}
                  handleMenuClose={handleMenuClose}
                  menuData={menuData}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerBorder>
      <div style={{ padding: "24px 0px" }}>
        <CommonButton variant="outlined" color="#344054" border="#D0D5DD" fontWeight="600" value="Run Scoring Test" padding={"10px 16px"} onClick={handleAddNote} />
      </div>

      <RunScoringTest openNoteDialog={openNoteDialog}
        handleCloseNoteDialog={handleCloseNoteDialog} />
    </>
  );
};

export default ResumeIntelligence;
