import { Padding } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import React from "react";

const FormControlField = styled(FormControl)(
  ({ marginTop, InputPadding, borderRadius, sizeMedium }) => ({
    marginTop: marginTop || "8px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D0D5DD",
      borderRadius: borderRadius || "8px",
    },
    "& .MuiInputLabel-formControl": {
      fontFamily: "Inter",
    },
    "& .MuiSelect-nativeInput": {
      color: "#667085",
      fontFamily: "Inter",
    },
    "& #demo-simple-select": {
      padding: InputPadding || "12px",
    },
    "& label.MuiInputLabel-sizeMedium": {
      top: sizeMedium || "-6px",
    },
    "& label.MuiInputLabel-shrink": {
      top: "0px",
    },
  })
);
const DropDownSelect = ({
  data,
  title,
  value,
  handleChange,
  marginTop,
  InputPadding,
  borderRadius,
  sizeMedium,
  error,
  helperText,
}) => {
  return (
    <FormControlField
      fullWidth
      marginTop={marginTop}
      InputPadding={InputPadding}
      borderRadius={borderRadius}
      sizeMedium={sizeMedium}
      helperText={helperText}
      error={error}
    >
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={title}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 210,
            },
          },
        }}
      >
        {data &&
          data.map((item, index) => (
            <MenuItem value={item.name}>{item.name}</MenuItem>
          ))}
      </Select>
    </FormControlField>
  );
};

export default DropDownSelect;
