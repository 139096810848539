import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AccordionDetails,
  AccordionSummary,
  Grid2,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../component/Breadcrumbs";
import CommonButton from "../../../component/Button";
import SnackBar from "../../../component/SnackBar";
import { CustomDiv } from "../../chat-bot/style";
import {
  CommonGrid2,
  CustomAccordion,
} from "../../common-components/StyledComponents";
import SkillDeleteDialog from "../../jobs/job-information/skills/SkillDeleteDialog";
import MixedComponent from "../mixed-component";
import AddInformation from "./AddInformation";
import ContactInformation from "./ContactInformation";
import * as Yup from "yup";
import { CommonTippy } from "../../jobs/style";
import {
  VendorAddCall,
  VendorGetCall,
  VendorUpdateCall,
} from "../../../redux/action/VendorPartner";
import { useDispatch } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";

export default function AddVendor() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [addInformationDialog, setAddInformationDialog] = useState({
    open: false,
    loading: false,
  });
  const [buttonLoading, setButtonLoading] = useState({
    disabled: false,
    cancelButton: false,
    addButton: false,
  });

  const [informationDelete, setInformationDelete] = useState({
    open: false,
    loading: false,
    content: "",
    index: "",
    form: "",
  });
  const [vendorData, setVendorData] = useState({
    business_name: "",
    business_display_name: "",
    subcontractor_id: "",
    address: "",
    email: "",
    contact_number: "",
    federal_id: "",
    bussiness_classification: "",
    bussiness_classification_data: [
      { name: "Individual" },
      { name: "Partnership" },
      { name: "C OR S CORP AND LLC (under LLC-Partnership, C or S)" },
    ],
    number_of_employee: "",
    website: "",
    primary_business_unit: "",
    record_status: "",
    record_status_data: [{ name: "Active" }, { name: "Inactive" }],

    currency_status: "",
    currency_status_data: [
      { name: "USD-United States", id: "USD-United States" },
      { name: "INR", id: "INR" },
    ],

    diversity_status: "",
    diversity_status_data: [
      { name: "MINORITY" },
      { name: "WOMAN OWNED" },
      { name: "VETERAN" },
      { name: "SMALL BUSINESS" },
    ],
    invoicing: "",
    invoice_data: [
      { name: "Monthly", id: "Monthly" },
      { name: "Weekly", id: "Weekly" },
      { name: "Fortnightly", id: "Fortnightly" },
    ],
    net_terms: "",
    net_terms_data: [
      { name: "30 days", id: "30 days" },
      { name: "45 days", id: "45 days" },
    ],
    job_termination_notice: "",
    // job_termination_notice_data: [{ name: "2 weeks", id: "2 weeks" }],
    contact_information: [],
    country: "",
  });

  const validationSchema = Yup.object({
    business_name: Yup.string().required("Business Name is required"),
    // business_display_name: Yup.string().required(
    //   "Business Display Name is required"
    // ),
    // subcontractor_id: Yup.string().required("Subcontractor Id is required"),
    address: Yup.string().required("Address is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    // contact_number: Yup.string()
    //   .required("Phone number is required")
    //   .test("is-valid-length", "Invalid phone number length", function (value) {
    //     const { country } = this.parent;

    //     if (!value || !country || !country.format) return false;

    //     const expectedDigitCount = (country.format.match(/\./g) || []).length;
    //     if (value.length >= 15) {
    //       return true;
    //     }
    //     return value.length === expectedDigitCount;
    //   }),

    contact_number: Yup.string()
      .required("Phone number is required")
      .test(
        "is-valid-phone",
        "Phone number is not valid for the selected country",
        function (value) {
          const { country } = this.parent;
          if (!value || !country?.countryCode) return false;
          const formattedValue = value.startsWith("+") ? value : `+${value}`;
          const phoneNumber = parsePhoneNumber(
            formattedValue,
            country.countryCode
          );
          return phoneNumber?.isValid();
        }
      ),

    bussiness_classification: Yup.string().required(
      "Bussiness Classification is required"
    ),
    number_of_employee: Yup.number()
      .typeError("Only numbers are allowed")
      .positive("Number must be positive")
      .integer("Only whole numbers are allowed"),

    website: Yup.string()
      .required("Website is required")
      .test(
        "is-valid-website",
        "Enter a valid website (e.g., www.example.com or https://example.com)",
        (value) => {
          if (!value) return false;
          const wwwPattern =
            /^www\.[a-zA-Z0-9-]{1,256}\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?$/;
          const httpsPattern =
            /^(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

          return wwwPattern.test(value) || httpsPattern.test(value);
        }
      ),
    // primary_business_unit: Yup.string().required(
    //   "Primary Business Unit is required"
    // ),
    record_status: Yup.string().required("Record Status Id is required"),

    diversity_status: Yup.string().required("Diversity Status is required"),
    currency_status: Yup.string().required("Currency is required"),

    invoicing: Yup.string().required("Invoice Id is required"),
    net_terms: Yup.string().required("Net Terms is required"),

    job_termination_notice: Yup.number()
      .typeError("Only numbers are allowed")
      .positive("Number must be positive")
      .integer("Only whole numbers are allowed")
      .required("Notice is required"),
    contact_information: Yup.array()
      .of(
        Yup.object().shape({
          data: Yup.array().of(
            Yup.object().shape({
              titleName: Yup.string().required("Field is required"),
              value: Yup.string().required("This field is required"),
            })
          ),
        })
      )
      .min(1, "At least one contact information is required"),
  });
  const query = new URLSearchParams(useLocation().search);
  const getId = query.get("id");

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const breadCrumbData = [
    // {
    //   title: "Partners",
    //   navigate: "/client-partner?type=Vendors",
    // },
    {
      title: "Vendors",
      navigate: "/client-vendor?type=Vendors",
    },
    {
      title: "Add Vendor",
      active: true,
    },
  ];

  const handleFunctionCall = async () => {
    setIsLoading(true);
    setButtonLoading((prev) => ({
      ...prev,
      disabled: true,
    }));
    try {
      let data;
      if (getId) data = await VendorGetCall(getId);

      setVendorData((prev) => ({
        ...prev,
        ...(getId
          ? {
              business_name: data?.data?.business_name,
              business_display_name: data?.data?.business_display_name,
              subcontractor_id: data?.data?.subcontractor_id,
              address: data?.data?.address,
              email: data?.data?.email,
              contact_number: data?.data?.contact_number,
              federal_id: data?.data?.federal_id,
              bussiness_classification: data?.data?.bussiness_classification,
              number_of_employee: data?.data?.number_of_employee,
              website: data?.data?.website,
              record_status: data?.data?.record_status,
              primary_business_unit: data?.data?.primary_business_unit,
              diversity_status: data?.data?.diversity_status,
              contact_information: data?.data?.contact_information,
              currency_status: data?.data?.["currency_details"]?.currency,
              invoicing: data?.data?.["payment_terms"]?.invoicing,
              net_terms: data?.data?.["payment_terms"]?.net_terms,
              job_termination_notice:
                data?.data?.["payment_terms"]?.job_termination_notice,
              country: data?.data?.country,
            }
          : {}),
      }));
      setExpandedIndex([0]);
      setIsLoading(false);
      handleButtonCall();
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setIsLoading(false);
      handleButtonCall();
    }
  };

  useEffect(() => {
    handleFunctionCall();
  }, []);

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const businessInformationData = [
    {
      title: "Business Name",
      component: "input",
      titleName: "business_name",
      placeholder: "Enter Name",
      placeholderFontSize: "15px",
    },
    {
      title: "Business Display Name",
      component: "input",
      titleName: "business_display_name",
      placeholder: "Enter Business Display Name",
      placeholderFontSize: "15px",
    },
    {
      title: "Subcontractor ID",
      component: "input",
      titleName: "subcontractor_id",
      placeholder: "Enter Subcontractor ID",
      placeholderFontSize: "15px",
    },
    {
      title: "Address",
      component: "input",
      titleName: "address",
      placeholder: "Enter Address",
      placeholderFontSize: "15px",
    },
    {
      title: "Email Address",
      component: "input",
      titleName: "email",
      placeholder: "Enter Email Address",
      placeholderFontSize: "15px",
    },
    {
      title: "Contact Number",
      component: "phone-number",
      titleName: "contact_number",
      placeholder: "Enter Contact Number",
      placeholderFontSize: "15px",
      country: "country",
      startIcon: "+91",
    },
    {
      title: "Federal ID",
      component: "input",
      titleName: "federal_id",
      placeholder: "Enter Federal ID",
      placeholderFontSize: "15px",
    },

    {
      title: "Business Classification",
      component: "dropDown",
      titleName: "bussiness_classification",
      dropDownData: "bussiness_classification_data",
      placeholder: "Select Classification",
      // valueChange: true,
    },
    {
      title: "Number of employees",
      component: "input",
      titleName: "number_of_employee",
      placeholder: "Enter number of employees",
      placeholderFontSize: "15px",
    },
    {
      title: "Website",
      component: "input",
      titleName: "website",
      placeholder: "Enter Website",
      placeholderFontSize: "15px",
    },

    {
      title: "Record Status",
      component: "dropDown",
      titleName: "record_status",
      placeholder: "Select Record Status",
      dropDownData: "record_status_data",
    },
    {
      title: "Primary Business Unit",
      component: "input",
      titleName: "primary_business_unit",
      placeholder: "Enter Primary Business Unit",
      placeholderFontSize: "15px",
    },
  ];

  const diversityStatus = [
    {
      title: "Diversity Status",
      component: "dropDown",
      titleName: "diversity_status",
      placeholder: "Select Diversity Status",
      dropDownData: "diversity_status_data",
      // valueChange: true,
    },
  ];

  const currencyDetails = [
    {
      title: "Currency Status",
      component: "dropDown",
      titleName: "currency_status",
      placeholder: "Select Currency",
      dropDownData: "currency_status_data",
      valueChange: true,
    },
  ];

  const paymentTerm = [
    {
      title: "Invoicing",
      component: "dropDown",
      titleName: "invoicing",
      placeholder: "Select Invoice",
      dropDownData: "invoice_data",
      valueChange: true,
    },
    {
      title: "Net Terms",
      component: "dropDown",
      titleName: "net_terms",
      placeholder: "Select Net Term",
      dropDownData: "net_terms_data",
      valueChange: true,
    },
    {
      title: "Job Termination Notice (In weeks)",
      component: "input",
      titleName: "job_termination_notice",
      placeholder: "Enter Job Termination Notice",
      placeholderFontSize: "15px",
      // dropDownData: "job_termination_notice_data",
      // valueChange: true,
    },
  ];

  const handleInformation = (data, setFieldValue, values) => {
    setAddInformationDialog((prev) => ({
      ...prev,
      loading: true,
    }));
    setTimeout(() => {
      setFieldValue("contact_information", [
        ...values.contact_information,
        ...data,
      ]);
      setAddInformationDialog({
        open: false,
        loading: false,
      });
    }, 1000);
  };

  const handleDeleteInformation = (data, index, form) => {
    setInformationDelete((prev) => ({
      ...prev,
      open: true,
      index: index,
      content: data,
      form: form,
    }));
  };

  const accordionDataView = [
    {
      title: "Information",
      component: (
        <MixedComponent
          editData={businessInformationData}
          rowSize
          loading={isLoading}
        />
      ),
    },
    {
      title: "Diversity Status",
      component: (
        <MixedComponent
          editData={diversityStatus}
          rowSize
          loading={isLoading}
        />
      ),
    },
    {
      title: "Contact Information",
      accordingButton: "Add Information",
      buttonEnable: true,
      component: (
        <ContactInformation
          loading={false}
          rowSize
          handleDeleteInformation={handleDeleteInformation}
        />
      ),
    },
    {
      title: "Currency Details",
      component: (
        <MixedComponent
          editData={currencyDetails}
          rowSize
          loading={isLoading}
        />
      ),
    },
    {
      title: "Payment Terms",
      component: (
        <MixedComponent editData={paymentTerm} rowSize loading={isLoading} />
      ),
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(
    accordionDataView.map((_, i) => i)
  );

  const handleAddInformation = (e) => {
    e.stopPropagation();
    setAddInformationDialog((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const handleButttonClose = () => {
    setAddInformationDialog({
      loading: false,
      open: false,
    });
  };

  const handleInformationDeleteButton = () => {
    setInformationDelete((prev) => ({
      ...prev,
      loading: true,
    }));
    setTimeout(() => {
      handleInformationDeleteClose();
      informationDelete.form?.setFieldValue(
        "contact_information",
        informationDelete.form?.values?.contact_information.filter(
          (_, getIndex) => getIndex !== informationDelete.index
        )
      );
      // setFieldValue((prev) =>
      //   prev.filter((_, getIndex) => getIndex !== informationDelete.index)
      // );
    }, 1000);
  };

  const handleInformationDeleteClose = () => {
    setInformationDelete({
      open: false,
      loading: false,
      content: "",
      index: "",
      form: "",
    });
  };

  const handleScrollToError = (data) => {
    setExpandedIndex((prev) => {
      const newExpandedIndex =
        data.business_name ||
        data.address ||
        data.email ||
        data.subcontractor_id ||
        data.bussiness_classification ||
        data.number_of_employee ||
        data.record_status ||
        data.contact_number ||
        data.business_display_name ||
        data.website ||
        data.federal_id ||
        data.primary_business_unit
          ? 0
          : data?.diversity_status
          ? 1
          : data?.contact_information
          ? 2
          : data?.currency_status
          ? 3
          : 4;

      return newExpandedIndex !== null && !prev.includes(newExpandedIndex)
        ? [...prev, newExpandedIndex]
        : prev;
    });

    setTimeout(() => {
      const errorElement = document.querySelector(".Mui-error");
      const informationElement = document.querySelector(".add-information");
      const elementToScroll = errorElement || informationElement;
      if (elementToScroll) {
        elementToScroll.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 300);
  };

  const handleButtonCall = () => {
    setButtonLoading({
      addButton: false,
      disabled: false,
      cancelButton: false,
    });
  };

  const handleCancelButton = () => {
    setButtonLoading((prev) => ({
      ...prev,
      disabled: true,
      cancelButton: true,
    }));
    setTimeout(() => {
      handleButtonCall();
      navigate("/client-vendor?type=Vendors");
    }, 1000);
  };

  const handleSubmitVendor = async (values) => {
    try {
      setButtonLoading((prev) => ({
        ...prev,
        disabled: true,
        addButton: true,
      }));
      let payload = {
        business_name: values.business_name,
        business_display_name: values.business_display_name,
        subcontractor_id: values.subcontractor_id,
        address: values.address,
        email: values.email,
        contact_number: values.contact_number,
        federal_id: values.federal_id,
        bussiness_classification: values.bussiness_classification,
        number_of_employee: values.number_of_employee,
        website: values.website,
        record_status: values.record_status,
        primary_business_unit: values.primary_business_unit,
        diversity_status: values.diversity_status,
        contact_information: values.contact_information,
        currency_details: {
          currency: values.currency_status,
        },
        country: values.country,
        payment_terms: {
          invoicing: values.invoicing,
          net_terms: values.net_terms,
          job_termination_notice: values.job_termination_notice,
        },
      };
      const data = getId
        ? await VendorUpdateCall(getId, payload)
        : await dispatch(VendorAddCall(payload));
      setSnackbarState({
        open: true,
        severity: "success",
        message: data?.message,
      });
      setTimeout(() => {
        handleButtonCall();
        navigate("/client-vendor?type=Vendors");
      }, 500);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      handleButtonCall();
    }
  };

  return (
    <CustomDiv>
      <Formik
        enableReinitialize={true}
        initialValues={vendorData}
        validationSchema={validationSchema}
        onSubmit={handleSubmitVendor}
      >
        {({ validateForm, values, errors, setFieldValue }) => {
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Grid2>
                <Breadcrumbs breadCrumbData={breadCrumbData} />
                <Grid2
                  container
                  spacing={2}
                  marginTop={"2rem"}
                  marginBottom={"2rem"}
                >
                  <CommonGrid2
                    size={{ xs: 12, md: 4, sm: 12 }}
                    item
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <Typography variant="h3">
                      {getId ? "Edit Vendor" : "Add Vendor"}
                    </Typography>
                  </CommonGrid2>
                  <CommonGrid2
                    item
                    size={{ xs: 12, md: 8, sm: 12 }}
                    gap="10px"
                    display="flex"
                    justifyContent={"end"}
                  >
                    <CommonButton
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      fontWeight="600"
                      value="Cancel"
                      padding="8px 20px"
                      disabled={buttonLoading.disabled}
                      loading={buttonLoading.cancelButton}
                      onClick={handleCancelButton}
                    />
                    <CommonButton
                      variant="contained"
                      color="white"
                      background="#7F56D9"
                      fontWeight="600"
                      value={getId ? "Update" : "Add"}
                      padding="8px 20px"
                      type="submit"
                      disabled={buttonLoading.disabled}
                      loading={buttonLoading.addButton}
                      onClick={() => {
                        setTimeout(async () => {
                          let data = await validateForm();
                          handleScrollToError(data);
                        }, 0);
                      }}
                    />
                  </CommonGrid2>
                  <CommonGrid2 size={12}>
                    {accordionDataView.map((item, index) => {
                      return (
                        <CustomAccordion
                          key={index}
                          padding="0px"
                          expanded={expandedIndex.includes(index)}
                          onChange={() =>
                            setExpandedIndex((prev) =>
                              prev.includes(index)
                                ? prev.filter((i) => i !== index)
                                : [...prev, index]
                            )
                          }
                        >
                          <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                          >
                            <CommonGrid2
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              size={12}
                            >
                              <Typography
                                variant="h4"
                                margin={"15px 0px"}
                                fontWeight={600}
                              >
                                {item.title}
                              </Typography>
                              {item.buttonEnable && (
                                <CommonTippy
                                  color="red"
                                  interactive={true}
                                  // trigger="click"
                                  // hideOnClick={false}
                                  zIndex={9}
                                  className={
                                    values.contact_information?.length === 0
                                      ? "add-information"
                                      : ""
                                  }
                                  content="Atleast one information is required"
                                  visible={
                                    values.contact_information?.length === 0
                                  }
                                  fontSize={"12px"}
                                >
                                  <CommonGrid2
                                    display={"flex"}
                                    alignItems={"end"}
                                    flexDirection={"column"}
                                    marginRight={"15px"}
                                  >
                                    <CommonButton
                                      variant="outlined"
                                      padding={"10px"}
                                      color="#344054"
                                      border="#D0D5DD"
                                      fontWeight="600"
                                      height="max-content"
                                      onClick={handleAddInformation}
                                      value={item.accordingButton}
                                      borderRadius={"7px"}
                                      customStyle={{
                                        pointerEvents: "all",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </CommonGrid2>
                                </CommonTippy>
                              )}
                            </CommonGrid2>
                          </AccordionSummary>
                          <AccordionDetails>{item.component}</AccordionDetails>
                        </CustomAccordion>
                      );
                    })}
                  </CommonGrid2>
                </Grid2>
              </Grid2>
              <AddInformation
                open={addInformationDialog.open}
                handleButttonClose={handleButttonClose}
                setFieldValue={setFieldValue}
                handleInformation={handleInformation}
                addInformationDialog={addInformationDialog}
                informationValues={values}
              />
              <SkillDeleteDialog
                deleteDialog={informationDelete.open}
                handleDeleteDialogClose={handleInformationDeleteClose}
                handleDeleteSkill={handleInformationDeleteButton}
                buttonLoading={informationDelete.loading}
                title={"Delete"}
                content={informationDelete.content?.type}
              />
              {/* <DialogCandidateHub
                open={addInformationDialog.open}
                // icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
                title="Add Information"
                titlepadding={"0px 0px 16px"}
                handleButttonClose={handleButttonClose}
                // footer={<BackGroundFooter />}
                // content={handleContent}
              /> */}
            </Form>
          );
        }}
      </Formik>

      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </CustomDiv>
  );
}
