import React from "react";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import Attributes from "./Attributes";

function Skills({ attributeHeaderData, SummaryJobData, tableLoader }) {
  return (
    <CommonGrid2 container spacing={2} flexDirection={"column"}>
      <Attributes
        title={"Primary Attributes"}
        data={SummaryJobData.skill.primary_attributes}
        attributeHeaderData={attributeHeaderData}
        tableLoader={tableLoader}
      />
      <Attributes
        title={"Secondary Attributes"}
        data={SummaryJobData.skill.secondary_attributes}
        attributeHeaderData={attributeHeaderData}
        tableLoader={tableLoader}
      />
    </CommonGrid2>
  );
}

export default Skills;
