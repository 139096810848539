import { Box, Grid2, IconButton, TableCell, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import html2pdf from "html2pdf.js";
import parsePhoneNumber from "libphonenumber-js";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import DeleteIco from "../../assets/icons/DeleteIco";
import PopupTickIco from "../../assets/icons/PopupTickIco";
import TalairoLogo from "../../assets/images/TalairoLogo.png";
import CommonButton from "../../component/Button";
import SnackBar from "../../component/SnackBar";
import {
  CoverSheetGetCall,
  CoverSheetJobDescription,
  CoverSheetJobDescriptionPUTCall,
  CoverSheetPutCall,
  VisaStatusDetails,
} from "../../redux/action/CoverSheet";
import { CustomDiv, CustomMarkDown } from "../chat-bot/style";
import CommonAccordion from "../common-components/CommonAccordion";
import { CommonGrid2 } from "../common-components/StyledComponents";
import SkillDeleteDialog from "../jobs/job-information/skills/SkillDeleteDialog";
import AttachmentDialog from "../jobs/jobs-candidate/components/AttachementDialog";
import Attachment from "../jobs/jobs-candidate/components/Attachment";
import DialogCandidateHub from "../jobs/jobs-candidate/components/DialogCandidateHub";
import { CustomizeTableCell } from "../jobs/style";
import AddExperience from "./components/AddExperience";
import ELCTable from "./components/ELCTable";
import ElcDialog from "./components/ElcDialog";
import Experience from "./components/Experience";
import JobDescription from "./components/JobDescription";
import MixedComponent from "./components/MixedComponent";
import PreScreenQuestion from "./components/PreScreenQuestion";
import Profile from "./components/Profile";
import References from "./components/References";

function CandidatePerspective() {
  const query = new URLSearchParams(useLocation().search);
  const coverId = query.get("id");
  const view_resume = query.get("view_resume");
  const dispatch = useDispatch();
  const coverSheet = useSelector((state) => state.coverSheet.coverSheetGet);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [expandedPanel, setExpandedPanel] = useState(["Primary Information"]);
  const [isLoading, setIsLoading] = useState(true);
  const [elcPopup, setElcPopup] = useState({
    open: false,
    loading: false,
    title: "",
    button: "",
    start_date: "",
    end_date: "",
    name: "",
    index: "",
    form: "",
  });
  const [elcExperience, setElcExperience] = useState({
    open: false,
    loading: false,
    position: "",
    company: "",
    button: "Add",
    index: "",
    form: "",
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [descriptionLoading, setDescriptionLoading] = useState({
    loading: false,
    disabled: false,
    open: false,
  });
  const [deleteElc, setDeleteElc] = useState({
    open: false,
    loading: false,
    content: "",
    title: "",
    index: "",
    form: "",
  });
  const [attachmentDialog, setAttachmentDialog] = useState({
    loading: false,
    disabled: false,
    open: false,
  });
  const [attachementHeaderData, setAttachementHeaderData] = useState([
    { name: "Name", width: "25%" },
    { name: "Type", width: "25%" },
    { name: "FileName", width: "25%" },
    {},
  ]);
  const coverSheetModifyData = {
    first_name: "",
    middle_name: "",
    last_name: "",
    title: "",
    seniority_level: "",
    available_industry: [
      {
        name: "Mid Level",
      },
      {
        name: "Senior",
      },
      {
        name: "Fresher",
      },
      {
        name: "Junior",
      },
      {
        name: "Executive",
      },
    ],
    // rate: {
    //   max: "",
    //   min: "",
    //   period: "Hourly",
    //   currency: "",
    // },
    location: "",
    dob: "",
    dob_month: "",
    portfolio: "",
    email: "",
    phone_number: "",
    notice_period: "",
    job_location: "",
    linkedin_url: "",
    willing_to_relocate: false,
    willing_to_be_onsite: false,
    education: "",
    availability_status: null,
    available_education: [
      {
        name: "PHD Degree",
      },
      {
        name: "Master's Degree",
      },
      {
        name: "Bachelor's Degree",
      },
    ],
    certificates: [],
    certificates_value: "",
    languages: [],
    languages_value: "",
    industry: [],
    industry_value: "",
    govt_security_clearance: false,
    dei: false,
    porche: null,
    porche_data: [{ name: "Yes" }, { name: "No" }],
    manager_name: "",
    department: "",
    employment_period: {
      start_date: "",
      end_date: "",
    },

    employment_type: "",
    employment_data: [{ name: "Permanent" }, { name: "Contract" }],
    tax_term: "",
    tax_term_data: [
      { name: "C2C" },
      { name: "1099" },
      { name: "W2 Contract" },
      { name: "W2 Employee" },
    ],
    emplopyer_name: "",
    emplopyer_phone: "",
    emplopyer_email: "",
    emplopyer_contact_name: "",
    hour_rate: "",
    contract_hour_rate: "",
    employee_hour_rate: "",
    visa_type: "",
    visa_type_data: [
      // { name: "Citizen" },
      // { name: "Legal Resident" },
      // { name: "Work Permit H1B" },
      // { name: "Work Permit TN" },
      // { name: "EAD - OPT" },
      // { name: "EAD – H4" },
      // { name: "EAD – L2" },
      // { name: "EAD – Legal Resident" },
    ],
    H1B_holder_company_name: "",
    visa_stamped: "",
    visa_stamped_data: [{ name: "Yes" }, { name: "No" }],
    visa_valid_from: "",
    visa_valid_to: "",
    qualifications: [],
    certifications: [],
    licenses: [],
    job_history: [],
    reference_name: "",
    reference_phone: "",
    interview_details: "",
    client_name: "",
    jobDescription: "",
    note: "",
    submitted: false,
    pre_screen_question_answers: [],
    attachment: [],
    phone_number_country: "",
    emplopyer_phone_country: "",
    extension_phone: "",
  };

  const roleData = [
    {
      name: "I am available and interested",
    },
    {
      name: "I am available but not interested",
    },
    {
      name: "I am not available",
    },
    { name: "I am unavailable but lets keep in touch" },
  ];

  const [coverSheetData, setCoverSheetData] = useState(coverSheetModifyData);

  const jobDescriptionSchema = Yup.object({
    availability_status: Yup.string().required("Role is required"),
    note: Yup.string().required("Note is required"),
  });

  const jobDescriptionSchemaRole = Yup.object({
    availability_status: Yup.string().required("Role is required"),
  });

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    // middle_name: Yup.string().required("Middle Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    title: Yup.string().required("Title is required"),
    seniority_level: Yup.string().required("Seniority Level is required"),
    // rate: Yup.object().shape({
    //   min: Yup.number()
    //     .required("Minimum pay rate is required")
    //     .typeError("Minimum pay rate must be a number")
    //     .min(0, "Minimum pay rate cannot be negative"),
    //   max: Yup.number()
    //     .required("Maximum pay rate is required")
    //     .typeError("Maximum pay rate must be a number")
    //     .moreThan(
    //       Yup.ref("min"),
    //       "Maximum pay rate must be greater than the minimum"
    //     ),
    // }),
    location: Yup.string().required("Location is required"),
    dob: Yup.string()
      .matches(/^\d{2} [A-Za-z]{3}$/, "Invalid date format")
      .required("Date of Birth is required"),
    job_location: Yup.string().required("Job Location is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    phone_number: Yup.string()
      .required("Phone number is required")
      .test(
        "is-valid-phone",
        "Phone number is not valid for the selected country",
        function (value) {
          const { phone_number_country } = this.parent;
          if (!value || !phone_number_country?.countryCode) return false;
          const formattedValue = value.startsWith("+") ? value : `+${value}`;
          const phoneNumber = parsePhoneNumber(
            formattedValue,
            phone_number_country.countryCode
          );
          return phoneNumber?.isValid();
        }
      ),
    notice_period: Yup.number()
      .typeError("Notice Period must be a number")
      .required("Notice Period is required")
      .min(0, "Notice Period cannot be negative"),
    // .matches(
    //   /^\d{10}$|^\d{3}-\d{3}-\d{4}$/,
    //   "Phone must be exactly 10 digits or in the format 123-456-7890"
    // ),
    // linkedin_url: Yup.string()
    //   // .matches(
    //   //   /^https?:\/\/(www\.)?linkedin\.com\/.*$/,
    //   //   "LinkedIn URL must be valid (e.g., https://www.linkedin.com/...)"
    //   // )
    //   .required("LinkedIn URL is required"),
    education: Yup.string().required("Education is required"),

    industry: Yup.array()
      .min(1, "Please enter at least one option")
      .required("This field is required"),
    languages: Yup.array()
      .min(1, "Please enter at least one option")
      .required("This field is required"),

    porche: Yup.string()
      .oneOf(["Yes", "No"], "Porche must be Yes or No")
      .test("required", function (value) {
        const { client_name } = this.parent;
        if (!value) {
          return this.createError({
            message: `Previous Experience is required`,
          });
        }
        return true;
      }),
    manager_name: Yup.string().when("porche", {
      is: (value) => value === "Yes",
      then: (schema) => schema.required("Manager Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    department: Yup.string().when("porche", {
      is: (value) => value === "Yes",
      then: (schema) => schema.required("Department is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    employment_type: Yup.string().when("porche", {
      is: (value) => value === "Yes",
      then: (schema) => schema.required("Employment Type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    tax_term: Yup.string()
      .required("Tax Term is required")
      .oneOf(
        ["C2C", "1099", "W2 Contract", "W2 Employee"],
        "Tax Term must be C2C or 1099 or W2 Contract or W2 Employee"
      ),

    emplopyer_name: Yup.string().when("tax_term", {
      is: (value) => value === "C2C" || value === "1099",
      then: (schema) => schema.required("Employer Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    emplopyer_phone: Yup.string().when("tax_term", {
      is: (value) => value === "C2C" || value === "1099",
      then: (schema) =>
        schema
          .required("Phone number is required")
          .test(
            "is-valid-phone",
            "Phone number is not valid for the selected country",
            function (value) {
              const { emplopyer_phone_country } = this.parent;
              if (!value || !emplopyer_phone_country?.countryCode) return false;
              const formattedValue = value.startsWith("+")
                ? value
                : `+${value}`;
              const phoneNumber = parsePhoneNumber(
                formattedValue,
                emplopyer_phone_country.countryCode
              );
              return phoneNumber?.isValid();
            }
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
    extension_phone: Yup.string().when("tax_term", {
      is: (value) => value === "C2C" || value === "1099",
      then: (schema) =>
        schema
          .required("Extension is required")
          .matches(/^\d+$/, "Extension must contain only numbers"),
      otherwise: (schema) => schema.notRequired(),
    }),
    emplopyer_email: Yup.string().when("tax_term", {
      is: (value) => value === "C2C" || value === "1099",
      then: (schema) =>
        schema
          .required("Employer Email is required")
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Invalid Employer email address"
          ),

      otherwise: (schema) => schema.notRequired(),
    }),
    emplopyer_contact_name: Yup.string().when("tax_term", {
      is: (value) => value === "C2C" || value === "1099",
      then: (schema) => schema.required("Employer Contact Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    hour_rate: Yup.number().when("tax_term", {
      is: (value) => value === "C2C" || value === "1099",
      then: (schema) =>
        schema
          .typeError("Hour Rate must be a number")
          .required("Hour Rate is required")
          .min(0, "Hour Rate cannot be negative"),
      otherwise: (schema) => schema.notRequired(),
    }),
    contract_hour_rate: Yup.number().when("tax_term", {
      is: (value) => value === "W2 Contract",
      then: (schema) =>
        schema
          .typeError("Hour Rate must be a number")
          .required("Hour Rate is required")
          .min(0, "Hour Rate cannot be negative"),
      otherwise: (schema) => schema.notRequired(),
    }),
    employee_hour_rate: Yup.number().when("tax_term", {
      is: (value) => value === "W2 Employee",
      then: (schema) =>
        schema
          .typeError("Hourly rate or Annual Salary must be a number")
          .required("Hourly rate or Annual Salary is required")
          .min(0, "Hourly rate or Annual Salary cannot be negative"),
      otherwise: (schema) => schema.notRequired(),
    }),

    visa_type: Yup.string()
      .required("Visa Type is required")
      .oneOf(
        [
          "EAD-OPT",
          "EAD-H4",
          "EAD-L2",
          "H1B Transfer",
          "Work Permit H1B",
          "Work Permit TN",
          "EAD-Legal Resident",
          "Citizen",
          "Legal Resident",
        ],
        "Visa Type must be EAD-OPT or EAD-H4 or EAD-L2 or Work Permit H1B or H1B Transfer or Work Permit TN or EAD-Legal Resident"
      ),

    visa_valid_from: Yup.string().when("visa_type", {
      is: (value) =>
        value === "EAD-OPT" ||
        value === "EAD-H4" ||
        value === "EAD-L2" ||
        value === "Work Permit H1B" ||
        value === "H1B Transfer" ||
        value === "Work Permit TN" ||
        value === "EAD-Legal Resident",
      then: (schema) => schema.required("Date is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    visa_valid_to: Yup.string().when("visa_type", {
      is: (value) =>
        value === "EAD-OPT" ||
        value === "EAD-H4" ||
        value === "EAD-L2" ||
        value === "Work Permit H1B" ||
        value === "H1B Transfer" ||
        value === "Work Permit TN" ||
        value === "EAD-Legal Resident",
      then: (schema) =>
        schema
          .required("To date is required")
          .test(
            "is-greater-than-from",
            "To date must be greater than From date",
            function (value) {
              const { visa_valid_from } = this.parent;
              if (!visa_valid_from || !value) return true;
              return new Date(value) > new Date(visa_valid_from);
            }
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
    H1B_holder_company_name: Yup.string().when("visa_type", {
      is: (value) =>
        value === "H1B" ||
        value === "Work Permit H1B" ||
        value === "H1B Transfer" ||
        value === "Work Permit TN" ||
        value === "EAD-Legal Resident",
      then: (schema) => schema.required("Company Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    visa_stamped: Yup.string().when("visa_type", {
      is: (value) =>
        value === "H1B" ||
        value === "Work Permit H1B" ||
        value === "H1B Transfer" ||
        value === "Work Permit TN" ||
        value === "EAD-Legal Resident",
      then: (schema) => schema.required("Visa Stamp is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    employment_period: Yup.mixed().when("porche", {
      is: (value) => value === "Yes",
      then: (schema) =>
        Yup.object()
          .nullable()
          .test("validate-employment-period", function (value) {
            if (!value?.start_date || !value?.end_date) {
              return this.createError({
                message: "Start Date and End Date are required",
              });
            }

            if (value.start_date === value.end_date) {
              return this.createError({
                message: "Start date and End date cannot be the same",
              });
            }

            return true;
          }),
      otherwise: (schema) => schema.notRequired(),
    }),

    pre_screen_question_answers: Yup.array().of(
      Yup.object().shape({
        answer: Yup.string().required("This field is required"),
      })
    ),
  });

  const [checkValidation, setCheckValidation] = useState(
    jobDescriptionSchemaRole
  );

  const fetchCoverSheet = async () => {
    setIsLoading(true);
    let params = {
      id: coverId,
    };
    let visa_params = {
      match_id: coverId,
    };
    try {
      let getData = null;
      let data = await dispatch(CoverSheetGetCall(params));
      let visaStatus = await VisaStatusDetails(visa_params);
      setCheckValidation(
        data?.availability_status === null
          ? jobDescriptionSchemaRole
          : data?.availability_status === "I am available and interested"
          ? validationSchema
          : jobDescriptionSchema
      );

      if (data?.availability_status === null) {
        getData = await CoverSheetJobDescription(coverId);
      }
      setCoverSheetData((prev) => ({
        ...prev,
        ...(getData ? { jobDescription: getData?.data } : {}),
        visa_type_data: visaStatus,
      }));
      setIsLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCoverSheet();
  }, []);

  useEffect(() => {
    if (Object.keys(coverSheet).length !== 0) {
      setCoverSheetData((prev) => ({
        ...prev,
        first_name: coverSheet.personal_details.first_name,
        middle_name: coverSheet.personal_details.middle_name,
        last_name: coverSheet.personal_details.last_name,
        title: coverSheet.personal_details.title,
        seniority_level: coverSheet.personal_details.seniority_level,
        // rate: {
        //   max: coverSheet.personal_details.rate.max,
        //   min: coverSheet.personal_details.rate.min,
        //   period: "Hourly",
        //   currency: "",
        // },
        availability_status: coverSheet.availability_status,
        location: coverSheet.personal_details.location,
        dob: coverSheet.personal_details.dob,
        dob_month: coverSheet.personal_details.dob,
        portfolio: coverSheet.personal_details.portfolio,
        email: coverSheet.personal_details.email,
        phone_number: coverSheet.personal_details.phone_number,
        phone_number_country: coverSheet.personal_details.phone_number_country,
        notice_period: coverSheet.personal_details?.notice_period,
        job_location: coverSheet.personal_details.job_location,
        linkedin_url: coverSheet.personal_details.linkedin_url,
        willing_to_relocate: coverSheet.personal_details.willing_to_relocate,
        willing_to_be_onsite: coverSheet.personal_details?.willing_to_be_onsite,
        education: coverSheet.additional_information.education,
        certificates: coverSheet?.additional_information?.certificates?.map(
          (item) => {
            return { content: item, id: "" };
          }
        ),
        languages: coverSheet?.additional_information?.languages.map((item) => {
          return { content: item, id: "" };
        }),
        industry: coverSheet?.additional_information?.industry.map((item) => {
          return { content: item, id: "" };
        }),
        govt_security_clearance:
          coverSheet.additional_information.govt_security_clearance,
        dei: coverSheet.additional_information.dei,
        porche: coverSheet.previous_experience?.porche,

        manager_name: coverSheet.previous_experience?.manager_name,
        department: coverSheet.previous_experience?.department,
        employment_type: coverSheet.previous_experience?.employment_type,
        tax_term: coverSheet.tax_term?.tax_term,
        emplopyer_name: coverSheet.tax_term?.emplopyer_name,
        emplopyer_phone: coverSheet.tax_term?.emplopyer_phone,
        emplopyer_email: coverSheet.tax_term?.emplopyer_email,
        emplopyer_contact_name: coverSheet.tax_term?.emplopyer_contact_name,
        hour_rate: coverSheet.tax_term?.hour_rate,
        contract_hour_rate: coverSheet.tax_term?.contract_hour_rate,
        employee_hour_rate: coverSheet.tax_term?.employee_hour_rate,
        visa_type: coverSheet.visa_information?.visa_type,
        visa_stamped: coverSheet.visa_information?.visa_stamped,
        H1B_holder_company_name:
          coverSheet.visa_information?.H1B_holder_company_name,
        visa_valid_from: coverSheet.visa_information?.visa_valid_from,
        visa_valid_to: coverSheet.visa_information?.visa_valid_to,

        qualifications: coverSheet?.elc_table?.qualifications,
        certifications: coverSheet?.elc_table?.certifications,
        licenses: coverSheet?.elc_table?.licenses,
        job_history: coverSheet?.job_history,
        reference_name: coverSheet?.references?.name,
        reference_phone: coverSheet?.references?.phone,
        interview_details: coverSheet?.references?.interview_details,
        client_name: coverSheet?.client_name,
        employment_period: {
          start_date:
            coverSheet.previous_experience?.employment_period?.start_date || "",
          end_date:
            coverSheet.previous_experience?.employment_period?.end_date || "",
        },
        note: coverSheet?.interest_notes,
        submitted: coverSheet?.submitted,
        pre_screen_question_answers: coverSheet?.pre_screen_question_answers,
        emplopyer_phone_country: coverSheet.tax_term?.emplopyer_phone_country,
        extension_phone: coverSheet.tax_term?.extension_phone,
      }));
    }
  }, [coverSheet]);

  const handleAddExperience = (form) => {
    setElcExperience((prev) => ({
      ...prev,
      open: true,
      loading: false,
      position: "",
      company: "",
      button: "Add",
      index: "",
      form: form,
    }));
  };
  const handleCloseAddExperience = () => {
    setElcExperience((prev) => ({
      ...prev,
      open: false,
      loading: false,
      position: "",
      company: "",
      button: "",
      index: "",
      form: "",
    }));
  };

  const primaryData = [
    {
      title: "First Name",
      component: "input",
      disabled:
        coverSheet?.personal_details?.first_name?.length !== 0 || view_resume,
      fontSize: "15px",
      titleName: "first_name",
      placeholderFontSize: "15px",
      placeholder: "Enter your First Name",
    },
    {
      title: "Middle Name",
      component: "input",
      disabled:
        coverSheet?.personal_details?.middle_name?.length !== 0 || view_resume,
      fontSize: "15px",
      titleName: "middle_name",
      placeholderFontSize: "15px",
      placeholder: "Enter your Middle Name",
    },
    {
      title: "Last Name",
      component: "input",
      fontSize: "15px",
      disabled:
        coverSheet?.personal_details?.last_name?.length !== 0 || view_resume,
      titleName: "last_name",
      placeholderFontSize: "15px",
      placeholder: "Enter your Last Name",
    },
    // {
    //   title: "Avatar",
    //   component: "fileUpload",
    //   titleName: "company_name",
    // },
    {
      title: "Title",
      component: "input",
      disabled:
        coverSheet?.personal_details?.title?.length !== 0 || view_resume,
      fontSize: "15px",
      titleName: "title",
    },
    {
      title: "Seniority Level",
      component: "dropDown",
      titleName: "seniority_level",
      disabled:
        coverSheet?.personal_details?.seniority_level?.length !== 0 ||
        view_resume,
      dropDownData: "available_industry",
    },
    // {
    //   component: "input-select",
    //   data: [
    //     {
    //       title: "Minimal Hour Rate",
    //       component: "input-select",
    //       fontSize: "15px",
    //       titleName: "rate",
    //       currency: "min",
    //       disabled:
    //         coverSheet?.personal_details?.rate?.min !== "" || view_resume,
    //     },
    //     {
    //       title: "Maximal Hour Rate",
    //       component: "input-select",
    //       fontSize: "15px",
    //       titleName: "rate",
    //       currency: "max",
    //       disabled:
    //         coverSheet?.personal_details?.rate?.max !== "" || view_resume,
    //     },
    //   ],
    // },
    {
      title: "Primary Location",
      component: "input",
      titleName: "location",
      fontSize: "15px",
      disabled:
        coverSheet?.personal_details?.location?.length !== 0 || view_resume,
      placeholderFontSize: "15px",
      placeholder: "Enter your location",
    },
    {
      title: " Day and Month of Birth",
      name: "Oct 30",
      component: "dob_month",
      titleName: "dob",
      disabled: coverSheet?.personal_details?.dob !== "" || view_resume,
    },
    {
      title: "Client / Job Location",
      component: "input",
      titleName: "job_location",
      fontSize: "15px",
      disabled:
        coverSheet?.personal_details?.job_location?.length !== 0 || view_resume,
      placeholderFontSize: "15px",
      placeholder: "Enter your location",
    },
    {
      title: "Primary Email Address",
      component: "input",
      fontSize: "15px",
      titleName: "email",
      disabled:
        coverSheet?.personal_details?.email?.length !== 0 || view_resume,
      placeholderFontSize: "15px",
      placeholder: "Enter your primary email address",
    },
    {
      title: "Primary Mobile Phone",
      component: "phone-number",
      fontSize: "15px",
      titleName: "phone_number",
      country: "phone_number_country",
      disabled:
        coverSheet?.personal_details?.phone_number?.length !== 0 || view_resume,
      placeholderFontSize: "15px",
      placeholder: "Enter your primary mobile phone number",
    },
    {
      title: "Notice Period (in days)",
      component: "input",
      fontSize: "15px",
      titleName: "notice_period",
      disabled:
        (coverSheet?.personal_details?.notice_period?.length !== 0 &&
          coverSheet?.personal_details?.notice_period !== undefined) ||
        view_resume,
      placeholderFontSize: "15px",
      placeholder: "Enter your Notice Period (in days)",
    },

    {
      title: "Portfolio",
      size: true,
      component: "input-link",
      titleName: "portfolio",
      disabled:
        coverSheet?.personal_details?.portfolio?.length !== 0 || view_resume,
      placeholderFontSize: "15px",
      placeholder: "Enter your portfolio URL",
    },
    {
      title: "LinkedIn",
      size: true,
      component: "input-link-linkedin",
      titleName: "linkedin_url",
      disabled:
        coverSheet?.personal_details?.linkedin_url?.length !== 0 || view_resume,
      placeholderFontSize: "15px",
      placeholder: "Enter your linkedin URL",
    },
    {
      title: "Are you willing to relocate?",
      size: true,
      // disabled:
      //   coverSheet?.personal_details?.willing_to_relocate !== "" || view_resume,
      toggleName: "willing_to_relocate",
      type: "additional",
    },
    {
      title: "Are you willing to be onsite?",
      size: true,
      toggleName: "willing_to_be_onsite",
      type: "additional",
    },
  ];

  const additionalInforamtion = [
    {
      title: "Highest Level of Education",
      disabled:
        coverSheet?.additional_information?.education?.length !== 0 ||
        view_resume,
      component: "dropDown",
      titleName: "education",
      dropDownData: "available_education",
    },
    {
      title: "Certificates",
      disabled:
        coverSheet?.additional_information?.certificates?.length !== 0 ||
        view_resume,
      component: "autoComplete",
      titleName: "certificates",
      placeholder: "Enter Certificates",
      inputValue: "certificates_value",
    },
    {
      title: "Industry",
      component: "autoComplete",
      disabled:
        coverSheet?.additional_information?.industry?.length !== 0 ||
        view_resume,
      titleName: "industry",
      placeholder: "Enter Industry",
      inputValue: "industry_value",
    },
    {
      title: "Languages",
      component: "autoComplete",
      disabled:
        coverSheet?.additional_information?.languages?.length !== 0 ||
        view_resume,
      titleName: "languages",
      placeholder: "Enter Languages",
      inputValue: "languages_value",
    },

    {
      title: "Goverment / Security Classification",
      toggleName: "govt_security_clearance",
      type: "additional",
      // disabled:
      //   coverSheet?.additional_information?.govt_security_clearance !== "" ||
      //   view_resume,
    },
    // {
    //   title: "DEI",
    //   toggleName: "dei",
    //   type: "additional",
    //   disabled: coverSheet?.additional_information?.dei !== "" || view_resume,
    // },
  ];

  const previousExperience = [
    {
      title: `Have you worked in the past with ${coverSheetData?.client_name}?`,
      component: "dropDown",
      titleName: "porche",
      dropDownData: "porche_data",
      customSize: 8,
      disabled: coverSheet?.previous_experience !== null || view_resume,
    },
  ];

  const conditionalExperience = [
    {
      title: "Employment Period",
      titleName: "employment_period",
      component: "calender",
      placeholder: "Select period",
      disabled: coverSheet?.previous_experience !== null || view_resume,
    },
    {
      title: "Department",
      component: "input",
      fontSize: "15px",
      titleName: "department",
      placeholderFontSize: "15px",
      placeholder: "Enter department",
      disabled: coverSheet?.previous_experience !== null || view_resume,
    },

    {
      title: "Type of Employment",
      component: "dropDown",
      titleName: "employment_type",
      dropDownData: "employment_data",
      placeholder: "Select Answer",
      disabled: coverSheet?.previous_experience !== null || view_resume,
    },
    {
      title: "Manager Name",
      component: "input",
      fontSize: "15px",
      titleName: "manager_name",
      placeholderFontSize: "15px",
      placeholder: "Enter manager name",
      disabled: coverSheet?.previous_experience !== null || view_resume,
    },
  ];

  const tax_term = [
    {
      title: "Tax Term",
      component: "dropDown",
      titleName: "tax_term",
      dropDownData: "tax_term_data",
      placeholder: "Select tax term",
      disabled: coverSheet?.tax_term !== null || view_resume,
    },
  ];

  const conditional_C2C = [
    {
      title: "Employer Name",
      component: "input",
      fontSize: "15px",
      titleName: "emplopyer_name",
      placeholderFontSize: "15px",
      placeholder: "Enter Employer Name",
      disabled: coverSheet?.tax_term !== null || view_resume,
    },
    {
      title: "Hourly Bill Rate",
      component: "hour",
      fontSize: "15px",
      titleName: "hour_rate",
      placeholder: "Enter Hourly rate",
      disabled: coverSheet?.tax_term !== null || view_resume,
    },
    {
      component: "split-screen",
      data: [
        {
          size: 8,
          title: "Employer Phone",
          component: "phone-number",
          fontSize: "15px",
          titleName: "emplopyer_phone",
          placeholderFontSize: "15px",
          country: "emplopyer_phone_country",
          placeholder: "Enter Employer Phone",
          disabled: coverSheet?.tax_term !== null || view_resume,
        },
        {
          size: 4,
          title: "Extension(Opt).",
          component: "input",
          fontSize: "15px",
          titleName: "extension_phone",
          placeholderFontSize: "15px",
          placeholder: "Enter extension",
          disabled: coverSheet?.tax_term !== null || view_resume,
        },
      ],
    },
    {
      title: "Employer Email",
      component: "input",
      fontSize: "15px",
      titleName: "emplopyer_email",
      placeholderFontSize: "15px",
      placeholder: "Enter Email",
      disabled: coverSheet?.tax_term !== null || view_resume,
    },
    {
      title: "Employer Contact Name",
      component: "input",
      fontSize: "15px",
      titleName: "emplopyer_contact_name",
      placeholderFontSize: "15px",
      placeholder: "Enter Contact Name",
      disabled: coverSheet?.tax_term !== null || view_resume,
    },
  ];

  const conditional_w2contract = [
    {
      title: "All Inclusive Hourly Pay Rate",
      component: "hour",
      fontSize: "15px",
      placeholderFontSize: "15px",
      titleName: "contract_hour_rate",
      placeholder: "Enter Hourly rate",
      disabled: coverSheet?.tax_term !== null || view_resume,
    },
  ];

  const conditional_w2employee = [
    {
      title: "All Inclusive Hourly Pay Rate or Annual Salary",
      component: "hour",
      fontSize: "15px",
      placeholderFontSize: "15px",
      titleName: "employee_hour_rate",
      placeholder: "Enter Hourly rate or Annual Salary",
      disabled: coverSheet?.tax_term !== null || view_resume,
    },
  ];

  const visa_status = [
    {
      title: "Visa Type",
      component: "dropDown",
      titleName: "visa_type",
      dropDownData: "visa_type_data",
      placeholder: "Select visa type",
      disabled: coverSheet?.visa_information !== null || view_resume,
    },
  ];

  const conditional_H1B = [
    {
      title: "Current Visa Holder Company name",
      component: "input",
      fontSize: "15px",
      titleName: "H1B_holder_company_name",
      placeholderFontSize: "15px",
      placeholder: "Enter Visa Holder Company Name",
      disabled: coverSheet?.visa_information !== null || view_resume,
    },
    {
      title: "Visa Stamped in Passport",
      component: "dropDown",
      titleName: "visa_stamped",
      dropDownData: "visa_stamped_data",
      placeholder: "Select Visa Stamp",
      disabled: coverSheet?.visa_information !== null || view_resume,
    },
  ];

  const conditional_opt_ead = [
    {
      title: "Visa Valid From",
      titleName: "visa_valid_from",
      component: "single-calender",
      placeholder: "Select date",
      disabled: coverSheet?.visa_information !== null || view_resume,
    },
    {
      title: "Visa Valid To",
      titleName: "visa_valid_to",
      component: "single-calender",
      placeholder: "Select date",
      disabled: coverSheet?.visa_information !== null || view_resume,
    },
  ];

  const references = [
    {
      title: "Name",
      component: "input",
      fontSize: "15px",
      titleName: "reference_name",
      placeholderFontSize: "15px",
      placeholder: "Enter the name",
      disabled: coverSheet?.references?.name !== "" || view_resume,
    },
    {
      title: "Phone",
      component: "input",
      fontSize: "15px",
      titleName: "reference_phone",
      placeholderFontSize: "15px",
      placeholder: "Enter the number",
      disabled: coverSheet?.references?.phone !== "" || view_resume,
    },
    {
      title: "Interview Details",
      fontSize: "15px",
      titleName: "interview_details",
      placeholderFontSize: "15px",
      placeholder: "Enter interview details",
      disabled: coverSheet?.references?.interview_details !== "" || view_resume,
    },
  ];

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleCloseElc = () => {
    setElcPopup((prev) => ({
      ...prev,
      open: false,
      loading: false,
      title: "",
      button: "",
      start_date: "",
      end_date: "",
      name: "",
      index: "",
      form: "",
    }));
  };

  const handleElcDialog = (label, form) => {
    setElcPopup((prev) => ({
      ...prev,
      open: true,
      loading: false,
      title: label,
      button: "Add",
      start_date: "",
      end_date: "",
      name: "",
      index: "",
      form: form,
    }));
  };

  const handleElcEdit = (title, data, index, form) => {
    setElcPopup((prev) => ({
      ...prev,
      open: true,
      title: title,
      button: "Update",
      start_date: data?.start_date,
      end_date: data?.end_date,
      name: data?.name,
      index: index,
      form: form,
    }));
  };

  const handlELcSubmit = (title, values) => {
    setElcPopup((prev) => ({
      ...prev,
      loading: true,
    }));
    setIsLoading(true);
    let payload = {
      name: values.school,
      ...(values?.date_of_issue
        ? { start_date: values.date_of_issue }
        : {
            start_date: values.Date_range.start_date,
            end_date: values.Date_range.end_date,
          }),
    };

    if (title === "Add Education") {
      const previous_update = elcPopup.form.values;
      const updatedJobHistory = [...previous_update.qualifications];

      if (
        values.index !== undefined &&
        values.index !== null &&
        values.index !== ""
      ) {
        updatedJobHistory[values.index] = {
          ...updatedJobHistory[values.index],
          ...payload,
        };
      } else {
        updatedJobHistory.push(payload);
      }
      elcPopup.form.setFieldValue("qualifications", updatedJobHistory);
    } else if (title === "Add Licenses") {
      const previous_update = elcPopup.form.values;
      const updatedJobHistory = [...previous_update.licenses];

      if (
        values.index !== undefined &&
        values.index !== null &&
        values.index !== ""
      ) {
        updatedJobHistory[values.index] = {
          ...updatedJobHistory[values.index],
          ...payload,
        };
      } else {
        updatedJobHistory.push(payload);
      }
      elcPopup.form.setFieldValue("licenses", updatedJobHistory);
    } else if (title === "Add Certification") {
      const previous_update = elcPopup.form.values;
      const updatedJobHistory = [...previous_update.certifications];
      if (
        values.index !== undefined &&
        values.index !== null &&
        values.index !== ""
      ) {
        updatedJobHistory[values.index] = {
          ...updatedJobHistory[values.index],
          ...payload,
        };
      } else {
        updatedJobHistory.push(payload);
      }
      elcPopup.form.setFieldValue("certifications", updatedJobHistory);
    }
    setTimeout(() => {
      setSnackbarState({
        open: true,
        message: "updated Successfully",
        severity: "success",
      });
      handleCloseElc();
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handlExperienceSubmit = (title, values) => {
    setElcExperience((prev) => ({
      ...prev,
      loading: true,
    }));
    setIsLoading(true);
    let payload = {
      job_title: values.position,
      company: values.company,
      start_date: values.Date_range.start_date,
      end_date: values.Date_range.end_date,
    };
    const previous_elcExperience = elcExperience.form.values;
    const updatedJobHistory = [...previous_elcExperience.job_history];
    if (
      values.index !== undefined &&
      values.index !== null &&
      values.index !== ""
    ) {
      updatedJobHistory[values.index] = {
        ...updatedJobHistory[values.index],
        ...payload,
      };
    } else {
      updatedJobHistory.push(payload);
    }
    elcExperience.form.setFieldValue("job_history", updatedJobHistory);

    setTimeout(() => {
      setSnackbarState({
        open: true,
        message: "updated Successfully",
        severity: "success",
      });
      handleCloseAddExperience();
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };
  const handleDeleteElcClose = () => {
    setDeleteElc((prev) => ({
      ...prev,
      open: false,
      loading: false,
      content: "",
      title: "",
      index: "",
      form: "",
    }));
  };

  const handleDeleteELC = (title, data, index, form) => {
    setDeleteElc((prev) => ({
      ...prev,
      open: true,
      loading: false,
      index: index,
      title: title,
      content: data.name,
      form: form,
    }));
  };
  const handleDeleteElc = () => {
    setDeleteElc((prev) => ({
      ...prev,
      loading: true,
    }));
    setIsLoading(true);
    const previous_elcdelete = deleteElc.form.values;

    if (deleteElc.title === "Add Education") {
      const updatedDelete = [...previous_elcdelete.qualifications];
      const updatedQualifications = updatedDelete.filter(
        (_, index) => index !== deleteElc.index
      );
      deleteElc.form.setFieldValue("qualifications", updatedQualifications);
    } else if (deleteElc.title === "Add Licenses") {
      const updatedDelete = [...previous_elcdelete.licenses];
      const updatedQualifications = updatedDelete.filter(
        (_, index) => index !== deleteElc.index
      );
      deleteElc.form.setFieldValue("licenses", updatedQualifications);
    } else if (deleteElc.title === "Add Certification") {
      const updatedDelete = [...previous_elcdelete.certifications];
      const updatedQualifications = updatedDelete.filter(
        (_, index) => index !== deleteElc.index
      );
      deleteElc.form.setFieldValue("certifications", updatedQualifications);
    } else if (deleteElc.title === "Add Experience") {
      const updatedDelete = [...previous_elcdelete.job_history];
      const updatedQualifications = updatedDelete.filter(
        (_, index) => index !== deleteElc.index
      );
      deleteElc.form.setFieldValue("job_history", updatedQualifications);
    } else if (deleteElc.title === "Attachment") {
      const updatedDelete = [...previous_elcdelete.attachment];
      const updatedQualifications = updatedDelete.filter(
        (_, index) => index !== deleteElc.index
      );
      deleteElc.form.setFieldValue("attachment", updatedQualifications);
    }
    setTimeout(() => {
      setSnackbarState({
        open: true,
        message: "Delete Successfully",
        severity: "success",
      });
      handleDeleteElcClose();
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleExperienceEdit = (data, index, form) => {
    setElcExperience((prev) => ({
      ...prev,
      open: true,
      button: "Update",
      start_date: data?.start_date,
      end_date: data?.end_date,
      position: data?.job_title,
      company: data?.company,
      index: index,
      form: form,
    }));
  };
  const handleExperienceDelete = (title, data, index, form) => {
    setDeleteElc((prev) => ({
      ...prev,
      open: true,
      loading: false,
      index: index,
      title: title,
      content: data.job_title,
      form: form,
    }));
  };

  const handleScrollToError = (error) => {
    setExpandedPanel((prev) => {
      const newPanels = new Set(prev);

      if (
        error.first_name ||
        error.last_name ||
        error.title ||
        error.seniority_level ||
        // error.rate?.min ||
        // error.rate?.max ||
        error.dob ||
        error.location ||
        error.job_location ||
        error.email ||
        error.phone_number ||
        error.notice_period ||
        error.linkedin_url
      ) {
        newPanels.add("Primary Information");
      }
      if (error.education || error.industry || error.languages) {
        newPanels.add("Additional Information");
      }
      if (
        error.porche ||
        error.manager_name ||
        error.department ||
        error.employment_period ||
        error.employment_type
      ) {
        newPanels.add("Previous Experience With The Client");
      }
      if (
        error.tax_term ||
        error.emplopyer_name ||
        error.emplopyer_phone ||
        error.extension_phone ||
        error.emplopyer_email ||
        error.emplopyer_contact_name ||
        error.hour_rate ||
        error.contract_hour_rate ||
        error.employee_hour_rate
      ) {
        newPanels.add("Engage");
      }
      if (
        error.visa_type ||
        error.visa_valid_from ||
        error.visa_valid_to ||
        error.H1B_holder_company_name ||
        error.visa_stamped
      ) {
        newPanels.add("Visa Status");
      }
      if (error.pre_screen_question_answers) {
        newPanels.add("Pre-Screen Questions");
      }

      return Array.from(newPanels);
    });

    setTimeout(() => {
      const errorElement = document.querySelector(".Mui-error");
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);
  };

  const handleSubmit = async (values) => {
    setSubmitLoading(true);
    setIsLoading(true);
    let payload = {
      ...coverSheet,
      personal_details: {
        ...coverSheet.personal_details,
        // rate: values.rate,
        visa: "NA",
        email: values.email,
        title: values.title,
        location: values.location,
        other_info: {
          interests: "",
          activities: "",
          volunteer_work: "",
        },
        linkedin_url: values.linkedin_url,
        phone_number: values.phone_number,
        phone_number_country: values.phone_number_country,
        notice_period: values.notice_period,
        seniority_level: values.seniority_level,
        willing_to_relocate: values.willing_to_relocate,
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        portfolio: values.portfolio,
        dob: values.dob_month,
        job_location: values.job_location,
      },
      additional_information: {
        industry: values.industry?.map((item) => item?.content),
        certificates: values.certificates?.map((item) => item?.content),
        languages: values.languages?.map((item) => item?.content),
        education: values.education,
        govt_security_clearance: values.govt_security_clearance,
        dei: values.dei,
      },
      elc_table: {
        licenses: values.licenses,
        certifications: values.certifications,
        qualifications: values.qualifications,
        research_paper: [],
      },
      job_history: values.job_history,
      references: {
        name: values.reference_name,
        interview_details: values.interview_details,
        phone: values.reference_phone,
      },
      previous_experience: {
        porche: values.porche,
        ...(values.porche === "Yes"
          ? {
              // employment_period: values.employment_period,
              employment_period: {
                start_date: values.employment_period.start_date,
                end_date: values.employment_period.end_date,
              },
              department: values.department,
              employment_type: values.employment_type,
              manager_name: values.manager_name,
            }
          : {}),
      },
      tax_term: {
        tax_term: values.tax_term,
        ...(values.tax_term === "W2 Contract"
          ? { contract_hour_rate: values.contract_hour_rate }
          : values.tax_term === "W2 Employee"
          ? { employee_hour_rate: values.employee_hour_rate }
          : {
              emplopyer_name: values.emplopyer_name,
              emplopyer_phone: values.emplopyer_phone,
              emplopyer_phone_country: values.emplopyer_phone_country,
              extension_phone: values.extension_phone,
              emplopyer_email: values.emplopyer_email,
              hour_rate: values.hour_rate,
              emplopyer_contact_name: values.emplopyer_contact_name,
            }),
      },
      visa_information: {
        visa_type: values.visa_type,
        ...(values.visa_type === "Work Permit H1B" ||
        values.visa_type === "H1B Transfer" ||
        values.visa_type === "EAD-OPT" ||
        values.visa_type === "EAD-H4" ||
        values.visa_type === "Work Permit TN" ||
        values.visa_type === "EAD-L2" ||
        values.visa_type === "EAD-Legal Resident"
          ? {
              visa_valid_from: values.visa_valid_from,
              visa_valid_to: values.visa_valid_to,
            }
          : {}),
        ...(values.visa_type === "Work Permit H1B" ||
        values.visa_type === "H1B Transfer" ||
        values.visa_type === "Work Permit TN" ||
        values.visa_type === "EAD-Legal Resident"
          ? {
              visa_stamped: values.visa_stamped,
              H1B_holder_company_name: values.H1B_holder_company_name,
            }
          : {}),
      },
      attachments: values.attachment,
      pre_screen_question_answers: values.pre_screen_question_answers,
    };

    const formData = new FormData();
    values.attachment.forEach((fileObj) => {
      formData.append("files", fileObj.file[0]?.file);
    });
    formData.append("data", JSON.stringify(payload));

    let params = {
      status: "Completed",
    };
    try {
      let noteParams = {
        note: values.note,
      };
      let interestParams = {
        status: values.availability_status,
      };
      const data =
        coverSheetData.availability_status === null
          ? await CoverSheetJobDescriptionPUTCall(coverId, interestParams)
          : coverSheetData.availability_status ===
            "I am available but not interested"
          ? await CoverSheetJobDescriptionPUTCall(coverId, noteParams)
          : await CoverSheetPutCall(params, formData);

      await fetchCoverSheet();

      setSnackbarState({
        open: true,
        message: data?.message,
        severity: "success",
      });
      setSubmitLoading(false);
      setIsLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setSubmitLoading(false);
      setIsLoading(false);
    }
  };

  const printableRef = useRef();

  const handleDownloadPDF = async () => {
    setExpandedPanel([
      "Primary Information",
      "Additional Information",
      "Previous Experience With The Client",
      "Education",
      "Certifications",
      "Licences",
      "References",
      "Visa Status",
      "Engage",
      "Pre-Screen Questions",
    ]);
    setSubmitLoading(true);

    setTimeout(async () => {
      const element = printableRef.current;
      const width = element.offsetWidth;
      const height = element.offsetHeight;
      const options = {
        margin: [20, 20, 20, 20],
        filename: "Coversheet.pdf",
        image: { type: "jpeg", quality: 2 },
        html2canvas: { scale: 3 },
        jsPDF: {
          unit: "px",
          format: [width + 250, height + 100],
          orientation: "portrait",
        },
      };

      try {
        await html2pdf().set(options).from(element).save();
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        setSubmitLoading(false);
      }
    }, 1000);
  };

  const handleChangeAccor = (panel) => (_, isExpanded) => {
    setExpandedPanel((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
    );
  };

  const handleViewDescription = async () => {
    try {
      setDescriptionLoading((prev) => ({
        ...prev,
        loading: true,
        disabled: true,
      }));
      const getData = await CoverSheetJobDescription(coverId);

      setCoverSheetData((prev) => ({
        ...prev,
        jobDescription: getData.data,
      }));

      setDescriptionLoading((prev) => ({
        ...prev,
        loading: false,
        disabled: false,
        open: true,
      }));
    } catch (error) {
      setDescriptionLoading((prev) => ({
        ...prev,
        loading: false,
        disabled: false,
      }));
    }
  };

  const handleDescriptionClose = () => {
    setDescriptionLoading((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleAttachment = () => {
    setAttachmentDialog((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const handleAttachmentClose = () => {
    setAttachmentDialog({
      loading: false,
      disabled: false,
      open: false,
    });
  };

  const handleAttachmentSubmit = async (
    values,
    setFieldValue,
    formikValues
  ) => {
    const updatedFiles = values.file.map((file) => ({
      ...file,
      delete: false,
    }));
    setFieldValue("file", updatedFiles);
    setAttachmentDialog((prev) => ({
      ...prev,
      loading: true,
      disabled: true,
    }));
    setIsLoading(true);
    const formData = new FormData();
    values.file.forEach((fileObj) => {
      formData.append("file", fileObj.file);
    });
    formData.append("name", values.name);

    let dataChange = {
      ...values,
      filename: values.file[0]?.name,
      type: values.file[0]?.type,
    };
    setTimeout(() => {
      handleAttachmentClose();
      formikValues.setFieldValue("attachment", [
        ...formikValues.values?.attachment,
        dataChange,
      ]);
      setSnackbarState({
        open: true,
        message: "Attachment Created Successfully",
        severity: "success",
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleAttachmentDeleteClick = (row, index, form) => {
    setDeleteElc((prev) => ({
      ...prev,
      open: true,
      loading: false,
      index: index,
      title: "Attachment",
      content: row.name,
      form: form,
    }));
  };

  const handleAttachmentTableBodyData = (row, index, form) => (
    <>
      <TableCell>{row.name || "-"}</TableCell>
      <TableCell>
        {row.type?.includes("pdf")
          ? "PDF"
          : row.type?.includes("wordprocessingml.document") ||
            row.type.includes(".docx")
          ? "DOCX"
          : row.type?.includes("msword") || row.type.includes(".doc")
          ? "DOC"
          : "-"}
      </TableCell>
      <TableCell>{row.filename || "-"}</TableCell>
      <CustomizeTableCell textAlign={"center"}>
        <IconButton
          onClick={() => handleAttachmentDeleteClick(row, index, form)}
          disabled={view_resume}
          sx={{ opacity: view_resume ? 0.4 : 1 }}
        >
          <DeleteIco />
        </IconButton>
      </CustomizeTableCell>
    </>
  );

  return (
    <CustomDiv
      position="relative"
      borderRadius="10px"
      boxShadow="1px 1px 5px 3px rgb(16 24 40 / 13%)"
      padding="0rem 2rem 2rem"
    >
      {view_resume && (
        <CommonButton
          variant="contained"
          color="white"
          background="#7F56D9"
          fontWeight="600"
          padding={"10px 15px"}
          value="Download"
          width={"max-content"}
          disabled={submitLoading}
          loading={submitLoading}
          onClick={handleDownloadPDF}
          customStyle={{ position: "absolute", right: "2rem", top: "20px" }}
        />
      )}

      <div ref={printableRef}>
        <Formik
          enableReinitialize={true}
          initialValues={coverSheetData}
          // validateOnMount={true}
          validationSchema={checkValidation}
          onSubmit={async (values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            form,
            validateForm,
          }) => {
            const previous_experience_with_client = [...previousExperience];
            const previous_tax_term = [...tax_term];
            const previous_conditional_w2contract = [...conditional_w2contract];
            const previous_conditional_w2employee = [...conditional_w2employee];
            const previous_visa_status = [...visa_status];
            const previous_conditional_H1B = [...conditional_H1B];
            const previous_conditional_opt_ead = [...conditional_opt_ead];

            if (values.porche === "Yes") {
              previous_experience_with_client.push(...conditionalExperience);
            }
            if (values.tax_term === "C2C" || values.tax_term === "1099") {
              previous_tax_term.push(...conditional_C2C);
            }
            if (values.tax_term === "W2 Contract") {
              previous_tax_term.push(...previous_conditional_w2contract);
            }
            if (values.tax_term === "W2 Employee") {
              previous_tax_term.push(...previous_conditional_w2employee);
            }
            if (
              values.visa_type === "Work Permit H1B" ||
              values.visa_type === "H1B Transfer" ||
              values.visa_type === "Work Permit TN" ||
              values.visa_type === "EAD-Legal Resident"
            ) {
              previous_visa_status.push(...previous_conditional_H1B);
            }
            if (
              values.visa_type === "EAD-OPT" ||
              values.visa_type === "EAD-H4" ||
              values.visa_type === "EAD-L2" ||
              values.visa_type === "Work Permit H1B" ||
              values.visa_type === "H1B Transfer" ||
              values.visa_type === "Work Permit TN" ||
              values.visa_type === "EAD-Legal Resident"
            ) {
              previous_visa_status.push(...previous_conditional_opt_ead);
            }
            let formikValues = {
              values: values,
              setFieldValue: setFieldValue,
            };

            return (
              <Form
                onKeyDown={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
              >
                <Field>
                  {({ field, form }) => (
                    <Grid2 container spacing={4}>
                      <CommonGrid2
                        display={"flex"}
                        flexDirection={"row"}
                        gap={"10px"}
                        size={12}
                        marginBottom={"-2rem"}
                      >
                        <img
                          src={TalairoLogo}
                          style={{
                            width: "215px",
                            height: "100%",
                          }}
                          alt="logo"
                        />
                        {/* <Typography
                            fontWeight={600}
                            fontSize={"24px"}
                            color={"#101828"}
                          >
                            Talairo
                          </Typography> */}
                      </CommonGrid2>

                      <Grid2 size={12}>
                        <Profile
                          handleScrollToError={handleScrollToError}
                          errors={errors}
                          coverSheet={coverSheet}
                          loading={isLoading}
                          submitLoading={submitLoading}
                          view_resume={view_resume}
                          handleDownloadPDF={handleDownloadPDF}
                          coverSheetData={coverSheetData}
                          handleViewDescription={handleViewDescription}
                          descriptionLoading={descriptionLoading}
                        />
                      </Grid2>

                      {(!coverSheetData.submitted ||
                        view_resume === "true") && (
                        <Grid2 size={12}>
                          <JobDescription
                            loading={isLoading}
                            coverSheetData={coverSheetData}
                            roleData={roleData}
                          />
                        </Grid2>
                      )}

                      {((coverSheetData?.availability_status !== null &&
                        coverSheetData?.availability_status ===
                          "I am available and interested" &&
                        !coverSheetData.submitted) ||
                        (coverSheetData?.availability_status ===
                          "I am available and interested" &&
                          view_resume === "true")) && (
                        <>
                          <Grid2 size={12} fontFamily={"Inter"}>
                            <CommonAccordion
                              // defaultOpen={true}
                              open={expandedPanel.includes(
                                "Primary Information"
                              )}
                              onChange={handleChangeAccor(
                                "Primary Information"
                              )}
                              title={"Primary Information"}
                              children={
                                <MixedComponent
                                  editData={primaryData}
                                  loading={isLoading}
                                />
                              }
                            />
                          </Grid2>

                          <Grid2 size={12} fontFamily={"Inter"}>
                            <CommonAccordion
                              title={"Additional Information"}
                              open={expandedPanel.includes(
                                "Additional Information"
                              )}
                              onChange={handleChangeAccor(
                                "Additional Information"
                              )}
                              children={
                                <MixedComponent
                                  editData={additionalInforamtion}
                                  loading={isLoading}
                                />
                              }
                            />
                          </Grid2>
                          <Grid2 size={12} fontFamily={"Inter"}>
                            <CommonAccordion
                              title={"Previous Experience With The Client"}
                              open={expandedPanel.includes(
                                "Previous Experience With The Client"
                              )}
                              onChange={handleChangeAccor(
                                "Previous Experience With The Client"
                              )}
                              children={
                                <MixedComponent
                                  loading={isLoading}
                                  editData={previous_experience_with_client}
                                />
                              }
                            />
                          </Grid2>
                          <Grid2 size={12} fontFamily={"Inter"}>
                            <CommonAccordion
                              title={"How We Engage"}
                              open={expandedPanel.includes("Engage")}
                              onChange={handleChangeAccor("Engage")}
                              children={
                                <MixedComponent
                                  editData={previous_tax_term}
                                  loading={isLoading}
                                />
                              }
                            />
                          </Grid2>
                          <Grid2 size={12} fontFamily={"Inter"}>
                            <CommonAccordion
                              title={"Visa Status"}
                              open={expandedPanel.includes("Visa Status")}
                              onChange={handleChangeAccor("Visa Status")}
                              children={
                                <MixedComponent
                                  editData={previous_visa_status}
                                  loading={isLoading}
                                />
                              }
                            />
                          </Grid2>
                          <Grid2 size={12} fontFamily={"Inter"}>
                            <CommonAccordion
                              title={"Experience"}
                              // disabled={isLoading || view_resume}
                              open={expandedPanel.includes("Experience")}
                              onChange={handleChangeAccor("Experience")}
                              children={
                                <Experience
                                  loading={isLoading}
                                  data={values.job_history}
                                  form={form}
                                  title={"Add Experience"}
                                  handleExperienceEdit={handleExperienceEdit}
                                  handleExperienceDelete={
                                    handleExperienceDelete
                                  }
                                  view_resume={view_resume}
                                />
                              }
                              accordionButton
                              disabled
                              buttonTitle={"Add Experience"}
                              onButtonClick={() => handleAddExperience(form)}
                            />
                          </Grid2>
                          <Grid2 size={12} fontFamily={"Inter"}>
                            <CommonAccordion
                              disabled
                              open={expandedPanel.includes("Education")}
                              onChange={handleChangeAccor("Education")}
                              title={"Education"}
                              children={
                                <ELCTable
                                  loading={isLoading}
                                  data={values.qualifications}
                                  form={form}
                                  handleElcEdit={handleElcEdit}
                                  title={"Add Education"}
                                  handleDeleteELC={handleDeleteELC}
                                  view_resume={view_resume}
                                />
                              }
                              accordionButton
                              buttonTitle={"Add Education"}
                              onButtonClick={() =>
                                handleElcDialog("Add Education", form)
                              }
                            />
                          </Grid2>
                          <Grid2 size={12} fontFamily={"Inter"}>
                            <CommonAccordion
                              disabled={isLoading || view_resume}
                              title={"Licences"}
                              open={expandedPanel.includes("Licences")}
                              onChange={handleChangeAccor("Licences")}
                              children={
                                <ELCTable
                                  loading={isLoading}
                                  data={values.licenses}
                                  form={form}
                                  handleElcEdit={handleElcEdit}
                                  title={"Add Licenses"}
                                  handleDeleteELC={handleDeleteELC}
                                  view_resume={view_resume}
                                />
                              }
                              accordionButton
                              buttonTitle={"Add Licenses"}
                              onButtonClick={() =>
                                handleElcDialog("Add Licenses", form)
                              }
                            />
                          </Grid2>
                          <Grid2 size={12} fontFamily={"Inter"}>
                            <CommonAccordion
                              disabled={isLoading || view_resume}
                              title={"Certifications"}
                              open={expandedPanel.includes("Certifications")}
                              onChange={handleChangeAccor("Certifications")}
                              children={
                                <ELCTable
                                  loading={isLoading}
                                  form={form}
                                  data={values.certifications}
                                  handleElcEdit={handleElcEdit}
                                  title={"Add Certification"}
                                  handleDeleteELC={handleDeleteELC}
                                  view_resume={view_resume}
                                />
                              }
                              accordionButton
                              buttonTitle={"Add Certification"}
                              onButtonClick={() =>
                                handleElcDialog("Add Certification", form)
                              }
                            />
                          </Grid2>
                          <Grid2 size={12} fontFamily={"Inter"}>
                            <CommonAccordion
                              title={"References"}
                              open={expandedPanel.includes("References")}
                              onChange={handleChangeAccor("References")}
                              children={
                                <References
                                  data={references}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  loading={isLoading}
                                />
                              }
                            />
                          </Grid2>
                          <Grid2 size={12}>
                            <Attachment
                              tableData={values.attachment}
                              attachementHeaderData={attachementHeaderData}
                              handleAttachment={handleAttachment}
                              handleAttachmentTableBodyData={(row, index) =>
                                handleAttachmentTableBodyData(
                                  row,
                                  index,
                                  formikValues
                                )
                              }
                              isLoading={isLoading}
                              disabled={view_resume}
                            />
                          </Grid2>
                          {values.pre_screen_question_answers?.length !== 0 && (
                            <Grid2 size={12}>
                              <CommonAccordion
                                title={"Pre-Screen Questions"}
                                open={expandedPanel.includes(
                                  "Pre-Screen Questions"
                                )}
                                onChange={handleChangeAccor(
                                  "Pre-Screen Questions"
                                )}
                                children={
                                  <PreScreenQuestion
                                    data={values.pre_screen_question_answers}
                                    // values={values}
                                    // setFieldValue={setFieldValue}
                                    loading={isLoading}
                                  />
                                }
                              />
                            </Grid2>
                          )}
                          <AddExperience
                            open={elcExperience.open}
                            onClose={handleCloseAddExperience}
                            title={"Add Experience"}
                            handlExperienceSubmit={handlExperienceSubmit}
                            elcExperience={elcExperience}
                          />

                          <ElcDialog
                            open={elcPopup.open}
                            onClose={handleCloseElc}
                            title={elcPopup.title}
                            handlELcSubmit={handlELcSubmit}
                            elcPopup={elcPopup}
                          />
                          <SkillDeleteDialog
                            deleteDialog={deleteElc.open}
                            handleDeleteDialogClose={handleDeleteElcClose}
                            handleDeleteSkill={handleDeleteElc}
                            buttonLoading={deleteElc.loading}
                            title={deleteElc.title.replace("Add", "Delete")}
                            content={deleteElc.content}
                          />

                          <AttachmentDialog
                            open={attachmentDialog.open}
                            handleClose={handleAttachmentClose}
                            handleSubmit={(values, { setFieldValue }) =>
                              handleAttachmentSubmit(
                                values,
                                setFieldValue,
                                formikValues
                              )
                            }
                            attachmentDialog={attachmentDialog}
                          />
                        </>
                      )}
                    </Grid2>
                  )}
                </Field>
              </Form>
            );
          }}
        </Formik>
        {coverSheetData?.submitted && view_resume !== "true" && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="start"
            height="100%"
            gap="1rem"
            marginTop={"1rem"}
            padding={"1rem"}
            border={"1px solid #E5E7EB"}
            borderRadius={"10px"}
          >
            <PopupTickIco />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
              alignItems={"start"}
              gap={"0.5rem"}
            >
              <Typography variant="h4" fontWeight={600}>
                Submission Successful !
              </Typography>
              <Typography variant="p" fontWeight={600}>
                {coverSheetData.availability_status ===
                "I am available and interested"
                  ? "Thank you for submission."
                  : coverSheetData.availability_status ===
                    "I am available but not interested"
                  ? "We will find a role that you are interested in and let you know."
                  : coverSheetData.availability_status === "I am not available"
                  ? "We will not be contacting you regarding this role any further."
                  : "Thanks, We will keep your profile on our database."}
              </Typography>
            </Box>
          </Box>
        )}

        <DialogCandidateHub
          open={descriptionLoading.open}
          handleButttonClose={handleDescriptionClose}
          title={"Job Description"}
          titlepadding={"0px"}
          maxWidth={"md"}
          content={
            <CustomDiv textAlign={"start"}>
              <CustomMarkDown>
                {typeof coverSheetData.jobDescription?.job_text === "string"
                  ? coverSheetData.jobDescription?.job_text.trim()
                  : JSON.stringify(coverSheetData.jobDescription?.job_text)}
              </CustomMarkDown>
            </CustomDiv>
          }
        />
        <SnackBar
          snackbarState={snackbarState}
          handleCloseValidation={handleCloseValidation}
        />
      </div>
    </CustomDiv>
  );
}

export default CandidatePerspective;
