import React from "react";
import { StyledSpaceBetweenBox } from "../../../portal/quick-match/components/StyledComponent";
import {
  StyledFlexColumnView,
  StyledFlexRowView,
} from "../../../common-components/StyledComponents";
import { Button, Grid2, Typography } from "@mui/material";
import CalenderIco from "../../../../assets/icons/CalenderIco";
import ClipBoardCheck from "../../../../assets/icons/ClipBoardCheckIco";

function Actions({ matchedData }) {
  return (
    <div
      style={{
        marginTop: "16px",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        borderRadius: "12px",
        border: "1px solid #D0D5DD ",
      }}
    >
      <StyledSpaceBetweenBox
        sx={{ borderBottom: "1px solid #D0D5DD", padding: "12px" }}
      >
        <StyledFlexRowView>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: "#101828",
              display: "flex",
              alignItems: "center",
            }}
          >
            Actions for{" "}
            <span style={{ fontWeight: "bold", marginLeft: "4px" }}>
              {matchedData?.resume_info?.candidate_name}
            </span>
          </Typography>
        </StyledFlexRowView>
      </StyledSpaceBetweenBox>

      <div>
        <Grid2 container spacing={2} sx={{ padding: "12px" }}>
          <Grid2>
            <Button
              variant="outlined"
              size="small"
              startIcon={<CalenderIco />}
              sx={{
                padding: "10px 14px",
                borderRadius: "8px",
                marginTop: "24px",
                color: "#344054",
                fontSize: "14px",
                border: "1px solid #D0D5DD",
                // "&:hover": {
                //   backgroundColor: "#7F56D9",
                // },
                fontWeight: "bold",
              }}
              //   onClick={handleClickAddNote}
            >
              {"Schedule Interview"}
            </Button>
          </Grid2>

          <Grid2>
            <Button
              variant="outlined"
              size="small"
              startIcon={<ClipBoardCheck />}
              sx={{
                padding: "10px 14px",
                borderRadius: "8px",
                marginTop: "24px",
                color: "#344054",
                fontSize: "14px",
                border: "1px solid #D0D5DD",
                // "&:hover": {
                //   backgroundColor: "#7F56D9",
                //   "& > svg": {
                //     fill: "white",
                //     stroke: "white",
                //   },
                // },
                fontWeight: "bold",
              }}
            >
              {"Conduct Test"}
            </Button>
          </Grid2>
        </Grid2>
      </div>
    </div>
  );
}

export default Actions;
