import {
  Autocomplete,
  Checkbox,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { AutocompleteText } from "../../../component/AutoComplete";
import CommonTextField from "../../../component/Input";
import DropDownSelect from "../../../component/Select";
import { CustomDiv } from "../../chat-bot/style";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import { ErrorContent } from "../style";
import DateRangePickerComp from "./DatePicker";
import { HandleSkeletion } from "../../../utils/constants";

const EditIndex = ({
  editData,
  menuItemData,
  type,
  loading,
  rowSize = false,
  marginTop,
}) => {
  const { values, errors, setFieldValue, handleBlur, touched } =
    useFormikContext();

  const [localValue, setLocalValue] = React.useState({
    job_title: values.job_title || "",
    company_name: values.company_name || "",
    location: values.location || "",
    work_location_type: values.work_location_type || "",
    req_id: values.req_id || "",
    shift: values.shift || "",
    bu_department_team: values.bu_department_team || "",
    interview_type: values.interview_type || "",
    shift_value: values.shift_value || "",
    reports_to: values.reports_to || "",
    location_targets_value: values.location_targets_value || "",
    certificates_value: values.certificates_value || "",
    employer_targets_value: values.employer_targets_value || "",
    languages_value: values.languages_value || "",
    min: values["pay_rate"]?.["min"] || "",
    max: values["pay_rate"]?.["max"] || "",
    specific_degree: values.specific_degree || "",
  });

  useEffect(() => {
    setLocalValue({
      job_title: values.job_title || "",
      company_name: values.company_name || "",
      location: values.location || "",
      work_location_type: values.work_location_type || "",
      req_id: values.req_id || "",
      shift: values.shift || "",
      bu_department_team: values.bu_department_team || "",
      interview_type: values.interview_type || "",
      shift_value: values.shift_value || "",
      reports_to: values.reports_to || "",
      location_targets_value: values.location_targets_value || "",
      certificates_value: values.certificates_value || "",
      employer_targets_value: values.employer_targets_value || "",
      languages_value: values.languages_value || "",
      min: values["pay_rate"]?.["min"] || "",
      max: values["pay_rate"]?.["max"] || "",
      specific_degree: values.specific_degree || "",
    });
  }, [values]);

  const debounceTimer = React.useRef(null);

  const handleInputChange = (e, item) => {
    setLocalValue((prev) => ({
      ...prev,
      [item.titleName]: e.target.value,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      item.onChange(e, item.titleName);
    }, 500);
  };

  const handleListChange = (e, item, index) => {
    const updatedValues = [...values[item.titleName]];
    updatedValues[index] = e.target.value;

    setLocalValue((prev) => ({
      ...prev,
      [item.titleName]: updatedValues,
    }));

    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      item.onListChange(updatedValues);
    }, 500);
  };

  const handleCurrencyChange = (e, item) => {
    setLocalValue((prev) => ({
      ...prev,
      [item.currency]: e.target.value,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      item.handleCurrencyChange(e, item.titleName, item.currency);
    }, 500);
  };
  return (
    <CommonGrid2
      container
      columnSpacing={3}
      rowSpacing={loading ? 0 : 3}
      marginTop={marginTop}
    >
      {editData?.map((item, index) => {
        return (
          <CommonGrid2
            item
            size={{
              xs: 12,
              md: item.type === "additional" || rowSize ? 6 : 4,
              sm: 6,
            }}
            key={index + item.title}
            gap={"5px"}
            display={"flex"}
            flexDirection={item.component === "switch" ? "row" : "column"}
            // justifyContent={"flex-start"}
          >
            {loading ? (
              <HandleSkeletion height={100} />
            ) : (
              <>
                {item.component !== "split-part" && (
                  <CommonGrid2
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <CommonGrid2
                      display={"flex"}
                      alignItems={"center"}
                      width={"100%"}
                      gap={"10px"}
                    >
                      <Typography variant="p" color="#344054" fontSize="16px">
                        {item.title}
                      </Typography>

                      {item?.iconVisible && item?.content}
                    </CommonGrid2>
                    {item.type === "additional" && (
                      <Switch
                        checked={values[item.toggleName] || false}
                        onChange={(event) => {
                          item.handleChangeSwitch(
                            item.toggleName,
                            event.target.checked
                          );
                        }}
                      />
                    )}
                  </CommonGrid2>
                )}
                {item.component === "input" && (
                  <CommonTextField
                    placeholder={item.title}
                    padding="10px"
                    error={Boolean(errors[item.titleName])}
                    value={localValue[item.titleName]}
                    onChange={(e) => {
                      handleInputChange(e, item);
                    }}
                    onBlur={handleBlur}
                  />
                )}
                {item.component === "split-part" && (
                  <CommonGrid2 container spacing={2}>
                    {item.data.map((innerItem) => (
                      <CommonGrid2
                        size={6}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"5px"}
                      >
                        <CommonGrid2
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          width={"100%"}
                        >
                          <CommonGrid2
                            display={"flex"}
                            alignItems={"center"}
                            width={"100%"}
                            gap={"10px"}
                          >
                            <Tooltip
                              arrow
                              title={innerItem.title}
                              placement="top"
                            >
                              <Typography
                                variant="p"
                                color="#344054"
                                fontSize="16px"
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                whiteSpace={"nowrap"}
                              >
                                {innerItem.title}
                                {innerItem?.iconVisible && innerItem?.content}
                              </Typography>
                            </Tooltip>
                          </CommonGrid2>
                          {innerItem.type === "additional" && (
                            <Switch
                              checked={values[innerItem.toggleName] || false}
                              onChange={(event) => {
                                innerItem.handleChangeSwitch(
                                  innerItem.toggleName,
                                  event.target.checked
                                );
                              }}
                            />
                          )}
                        </CommonGrid2>

                        {innerItem.component === "input" && (
                          <Field name={item.titleName}>
                            {({ field, form }) => {
                              return (
                                <CommonTextField
                                  placeholder={innerItem.title}
                                  padding="10px"
                                  error={Boolean(errors[innerItem.titleName])}
                                  value={localValue[innerItem.titleName]}
                                  onChange={(e) => {
                                    handleInputChange(e, innerItem);
                                  }}
                                  helperText={
                                    form.touched[item.titleName] &&
                                    errors[innerItem.titleName]
                                  }
                                  onBlur={handleBlur}
                                />
                              );
                            }}
                          </Field>
                        )}

                        {innerItem.component === "dropDown" && (
                          <DropDownSelect
                            marginTop="0px"
                            title={innerItem.title}
                            removeAnimation={true}
                            data={values[innerItem.dropDownData]}
                            error={Boolean(errors[innerItem.titleName])}
                            helperText={errors[innerItem.titleName]}
                            id={innerItem.titleName}
                            value={values[innerItem.titleName]}
                            handleChange={(e) =>
                              innerItem.handleDropdownChange(
                                e,
                                innerItem.titleName
                              )
                            }
                          />
                        )}
                      </CommonGrid2>
                    ))}
                  </CommonGrid2>
                )}
                {item.component === "input-select" && (
                  <CommonTextField
                    showSelect={true}
                    placeholder={"Enter the rate"}
                    menuItemData={menuItemData}
                    onChange={(e) => {
                      handleCurrencyChange(e, item);
                    }}
                    currency={values[item.titleName]?.[item.currencyValue]}
                    padding="10px"
                    handleCurrencyChange={(e) =>
                      item.handleCurrencyDropdownChange(
                        e,
                        item.titleName,
                        item.currencyValue
                      )
                    }
                    error={errors[item.titleName]?.[item.currency]}
                    helperText={errors[item.titleName]?.[item.currency]}
                    value={localValue?.[item.currency]}
                  />
                )}
                {item.component === "dropDown" && (
                  <DropDownSelect
                    marginTop="0px"
                    title={item.title}
                    removeAnimation={true}
                    data={values[item.dropDownData]}
                    error={Boolean(errors[item.titleName])}
                    helperText={errors[item.titleName]}
                    id={item.titleName}
                    value={values[item.titleName]}
                    handleChange={(e) =>
                      item.handleDropdownChange(e, item.titleName)
                    }
                  />
                )}

                {item.component === "calender" && (
                  <CustomDiv>
                    <DateRangePickerComp
                      startDateProp={values.Date_Range?.start_date}
                      endDateProp={values.Date_Range?.end_date}
                      handleChange={(start_date, end_date) => {
                        item.handleDatePicker(
                          item.titleName,
                          start_date,
                          end_date
                        );
                      }}
                      error={Boolean(errors["Date_Range"])}
                    />
                    {Boolean(errors["Date_Range"]) && (
                      <ErrorContent>{errors["Date_Range"]}</ErrorContent>
                    )}
                  </CustomDiv>
                )}

                {item.component === "autoComplete" && (
                  <CustomDiv>
                    <AutocompleteText
                      autoData={{
                        options: [],
                        key: `autocomplete-${index}`,
                        title: item.placeholder,
                        placeholder: item.placeholder,
                        isDisabled: false,
                        value: values[item.titleName] || "",

                        onDelete: (option) =>
                          item.handleDelete(option, item.titleName),
                        onKeyDown: (e) => {
                          item.handleAutoCompleteKeyDown(
                            e,
                            index,
                            item.titleName,
                            item.inputValue,
                            setFieldValue
                          );
                          if (e.key === "Tab") {
                            setLocalValue((prev) => ({
                              ...prev,
                              [item.inputValue]: "",
                            }));
                          }
                        },

                        onChange: (e, newValue) => {
                          item.handleAutoCompleteChange(
                            e,
                            newValue,
                            item.titleName
                          );
                        },
                        inputValue: localValue[item.inputValue],
                        onInputChange: (e, newValue) => {
                          setLocalValue((prev) => ({
                            ...prev,
                            [item.inputValue]: newValue,
                          }));
                        },
                        customClass: item.titleName,
                      }}
                      error={errors[item.titleName]}
                    />
                    {errors[item.titleName] && (
                      <ErrorContent>{errors[item.titleName]}</ErrorContent>
                    )}
                  </CustomDiv>
                )}
                {item.component === "multi-complete" && (
                  <CustomDiv>
                    <Autocomplete
                      multiple
                      options={values[item.options] || []}
                      getOptionLabel={(option) => option.name || option}
                      value={values[item.titleName]?.map(
                        (selected) =>
                          values[item.options]?.find(
                            (option) => option.id === selected.id
                          ) || []
                      )}
                      onChange={(e, newValue) => {
                        item.handleAutoCompleteChange(
                          e,
                          newValue,
                          item.titleName
                        );
                      }}
                      limitTags={2}
                      renderOption={(props, option, { selected }) => {
                        const isChecked = values[item.titleName]?.some(
                          (val) => val.name === option.name || selected
                        );

                        return (
                          <li {...props} style={{ padding: "3px 10px" }}>
                            <Checkbox checked={isChecked} />
                            {option.name || option}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={item.placeholder}
                          error={errors[item.titleName]}
                        />
                      )}
                      ListboxProps={{
                        id: "custom-autocomplete-list",
                      }}
                      disablePortal
                      disableCloseOnSelect
                      sx={{
                        "& label.MuiInputLabel-sizeMedium": {
                          top: "-5px !important",
                        },
                        "& label.MuiInputLabel-shrink": {
                          top: "0px !important",
                        },
                        "& .MuiInputBase-formControl": {
                          padding: "5px 39px 5px 9px",
                          gap: "4px",
                        },
                        "& fieldset": {
                          border: "1px solid #D0D5DD",
                        },
                        "& .MuiAutocomplete-tag": {
                          gap: "5px",
                          margin: "0px 3px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          border: "1px solid",
                          padding: "2px 6px",
                          background: "rgba(0, 0, 0, 0.08)",
                        },
                      }}
                    />
                    {errors[item.titleName] && (
                      <ErrorContent>{errors[item.titleName]}</ErrorContent>
                    )}
                  </CustomDiv>
                )}

                {item.component === "input-list" && (
                  <CommonTextField
                    placeholder={item.title}
                    padding="10px"
                    onBlur={handleBlur}
                    error={Boolean(errors[item.titleName]?.[index])}
                    helperText={errors[item.titleName]?.[index]}
                    value={localValue[item.titleName]?.[index] || ""}
                    onChange={(e) => {
                      handleListChange(e, item, index);
                    }}
                  />
                )}
              </>
            )}
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
};
export default EditIndex;
