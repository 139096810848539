const ResumeIntelligenceNote = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2056_50731)">
        <path
          d="M2.5 7C2.5 3.96243 4.96243 1.5 8 1.5H28C31.0376 1.5 33.5 3.96243 33.5 7V27C33.5 30.0376 31.0376 32.5 28 32.5H8C4.96243 32.5 2.5 30.0376 2.5 27V7Z"
          stroke="#EAECF0"
          shape-rendering="crispEdges"
        />
        <path
          d="M20.666 11.6673C21.286 11.6673 21.596 11.6673 21.8503 11.7355C22.5405 11.9204 23.0796 12.4595 23.2645 13.1497C23.3327 13.404 23.3327 13.714 23.3327 14.334V20.4673C23.3327 21.5874 23.3327 22.1475 23.1147 22.5753C22.9229 22.9516 22.617 23.2576 22.2407 23.4493C21.8128 23.6673 21.2528 23.6673 20.1327 23.6673H15.866C14.7459 23.6673 14.1859 23.6673 13.758 23.4493C13.3817 23.2576 13.0757 22.9516 12.884 22.5753C12.666 22.1475 12.666 21.5874 12.666 20.4673V14.334C12.666 13.714 12.666 13.404 12.7342 13.1497C12.9191 12.4595 13.4582 11.9204 14.1484 11.7355C14.4027 11.6673 14.7127 11.6673 15.3327 11.6673M16.3993 13.0007H19.5993C19.9727 13.0007 20.1594 13.0007 20.302 12.928C20.4275 12.8641 20.5294 12.7621 20.5934 12.6366C20.666 12.494 20.666 12.3074 20.666 11.934V11.4007C20.666 11.0273 20.666 10.8406 20.5934 10.698C20.5294 10.5725 20.4275 10.4706 20.302 10.4066C20.1594 10.334 19.9727 10.334 19.5993 10.334H16.3993C16.026 10.334 15.8393 10.334 15.6967 10.4066C15.5712 10.4706 15.4693 10.5725 15.4053 10.698C15.3327 10.8406 15.3327 11.0273 15.3327 11.4007V11.934C15.3327 12.3074 15.3327 12.494 15.4053 12.6366C15.4693 12.7621 15.5712 12.8641 15.6967 12.928C15.8393 13.0007 16.026 13.0007 16.3993 13.0007Z"
          stroke="#344054"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2056_50731"
          x="0"
          y="0"
          width="36"
          height="36"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2056_50731"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2056_50731"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ResumeIntelligenceNote;
