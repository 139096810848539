import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import React from "react";
import ActiveJobBackground from "../../assets/images/activeJobBackGround.png";
import CommonButton from "../../component/Button";
import DropDownSelect from "../../component/Select";
import { StyledDialog } from "./StyledComponents";


const AddUpdateActiveJob = ({
  open,
  onClose,
  title = "Filter",
  activeOptions = [],
  onApply,
  backgroundImage,
  id
}) => {
  return (
    <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose} >
      <img
        src={ActiveJobBackground}
        style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
      />
      <DialogActions>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {id ? "Update To Active Job" : "Add To Active Job"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" gap={2}>
          {activeOptions.map((option, index) => (
            <Grid item key={index}>
              <Typography
                color="#344054"
                fontSize={14}
                fontFamily="Inter"
                fontWeight={500}
              >
                {option.label}
              </Typography>
              <DropDownSelect
                title={option.placeholder}
                data={option.data}
                handleChange={option.onChange}
                value={option.value}
              />
            </Grid>
          ))}
          <CommonButton
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Add"
            padding="8px"
            onClick={onApply}
          />
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default AddUpdateActiveJob;
