import { styled } from "@mui/material";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const StyledTextField = styled(PhoneInput)(({ customStyle, padding }) => ({
  "input.form-control": {
    paddingLeft: "60px",
    height: "100%",
    width: "100%",
    borderRadius: "7px",
    fontFamily: "Inter",
    fontWeight: 500,
    padding: padding,
  },
  "& .country-name": {
    fontFamily: "Inter",
  },
  ".flag-dropdown": {
    borderRadius: "7px 0px 0px 7px !important",
  },
  ".flag-dropdown .selected-flag": {
    width: "50px",
    "&:focus": {
      backgroundColor: "transparent !important",
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  ".selected-flag .arrow": {
    left: "25px !important",
    borderLeft: "4px solid transparent !important",
    borderRight: " 4px solid transparent !important",
    borderTop: "5px solid #555 !important",
  },

  ...customStyle,
}));

const CommonPhoneInput = ({
  onChange,
  value,
  country = "us",
  placeholder,
  customStyle,
  padding,
}) => {
  return (
    <StyledTextField
      country={country}
      placeholder={placeholder}
      value={value}
      customStyle={customStyle}
      onChange={onChange}
      padding={padding}
      inputProps={{
        name: "phone",
        // required: true,
        autoFocus: true,
      }}
    />
  );
};

export default CommonPhoneInput;
