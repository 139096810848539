import { Box, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import BoxEditIco from "../../../../assets/icons/BoxEditIco";
import DeleteIco from "../../../../assets/icons/DeleteIco";
import LightboxIco from "../../../../assets/images/lightbox.png";
import PolymathIco from "../../../../assets/images/polymath.png";
import SpheruleIco from "../../../../assets/images/spherule.png";
import {
  StyledFlexColumnView,
} from "../../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../../portal/quick-match/components/StyledComponent";
import AddExperience from "./AddExperience";

const initialFeedback = [
  {
    id: 1,
    icon: SpheruleIco,
    message:
      "Zahir is a great fit for this position and should be highly considered.",
    title: "Lead Product Designer",
    subTitle: "Polymath · Jan 2024 ⏤ Now",
  },
  {
    id: 2,
    icon: PolymathIco,
    message: "He's a Figma wizard, Dumbledore would be proud!",
    title: "Product Designer",
    subTitle: "Spherule · Jan 2022 ⏤ Jan 2024",
  },
  {
    id: 3,
    icon: LightboxIco,
    message:
      "Unfortunately, he’s a bit weak with soft skills, especially communication.",
    title: "Product Designer",
    subTitle: "Lightbox · Apr 2022 ⏤ Jan 2022",
  },
];

function FeedbackCard({ icon, item, handleDeleteNotes, onEdit }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "12px",
        justifyContent: "space-between",
        mb: 1,
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        borderRadius: "12px",
        border: "1px solid #EAECF0 !important",
      }}
    >
      <StyledSpaceBetweenBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <Stack direction="row" alignItems="center" spacing={2}>
            <img
              src={icon}
              style={{
                width: "48px",
                height: "48px",
              }}
              alt="logo"
            />
            <StyledFlexColumnView sx={{ gap: "4px" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#101828",
                }}
              >
                {item?.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#475467",
                }}
              >
                {item?.subTitle}
              </Typography>
            </StyledFlexColumnView>
          </Stack>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              border: "1px solid #D0D5DD",
              borderRadius: "6px",
              color: "#344054",
              padding: "11px",
              display: "flex",
            }}
            onClick={() => onEdit(item)}
          >
            <BoxEditIco />
          </div>
          <div
            style={{
              cursor: "pointer",
              border: "1px solid #D0D5DD",
              borderRadius: "6px",
              color: "#344054",
              padding: "11px",
              display: "flex",
            }}
            onClick={() => handleDeleteNotes(item.id)}
          >
            <DeleteIco />
          </div>
        </div>
      </StyledSpaceBetweenBox>
    </Paper>
  );
}

function Experience() {
  const [feedback, setFeedback] = useState(initialFeedback);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);

  const handleEditExperience = (experience) => {
    setSelectedExperience(experience);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedExperience(null);
  };

  const handleApply = (updatedExperience) => {
    setFeedback((prevFeedback) =>
      prevFeedback.map((item) =>
        item.id === updatedExperience.id ? updatedExperience : item
      )
    );
    handleCloseDialog();
  };

  const handleDeleteNotes = (id) => {
    setFeedback((prevFeedback) =>
      prevFeedback.filter((item) => item.id !== id)
    );
  };

  return (
    <Box>
      {feedback.map((item) => (
        <FeedbackCard
          key={item.id}
          icon={item.icon}
          item={item}
          handleDeleteNotes={handleDeleteNotes}
          onEdit={handleEditExperience}
        />
      ))}
      {openDialog && (
        <AddExperience
          open={openDialog}
          onClose={handleCloseDialog}
          onApply={handleApply}
          experience={selectedExperience}
        />
      )}
    </Box>
  );
}

export default Experience;
