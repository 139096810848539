import { URL } from "../../api/Api";
import { JOBS } from "../Action";
import { catchError, DELETE, GET, getToken, POST, PUT } from "../axios/Axios";

export const GetJobList = async (payload, params) => {
  try {
    const response = await POST(`${URL}/intake/search/filter`, payload, params);

    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobSummary = (job_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/intake/job-summary/${job_id}`, {});
      dispatch({ type: JOBS.jobSummary, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const JobJobEdit = (job_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/intake/jobdata/edit/${job_id}`, {});
      dispatch({ type: JOBS.jobJobEdit, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const JobJobSkillEdit = async (id, key, classification, payload) => {
  try {
    const getClassification = classification
      ? `&classification=${classification}`
      : "";
    const response = await POST(
      `${URL}/intake/skill/update?id=${id}&key=${key}${getClassification}`,
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    console.log(error, "sjfnbsjkfsbfn");
    throw catchError(error);
  }
};

export const JobJobShare = async (payload, type, id) => {
  try {
    const response = await POST(
      type === "Job"
        ? `${URL}/intake/share/job?id=${id}`
        : type === "summary"
        ? `${URL}/intake/share/job`
        : `${URL}/matchengine/share/candidateprofile`,
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobSubmit = async (id, payload, params) => {
  try {
    const response = await PUT(
      `${URL}/intake/jobdata/edit/${id}`,
      payload,
      params ? params : {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobDelete = async (id) => {
  try {
    const response = await DELETE(
      `${URL}/matchengine/match/resume/jd/${id}`,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobLock = async (payload) => {
  try {
    const response = await PUT(
      `${URL}/matchengine/matchresume/order`,
      payload,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobReScore = async (id, payload) => {
  try {
    const token = await getToken();
    const response = await fetch(
      `${URL}/intake/job/rescore/${id}?score=${payload.score}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: "",
      }
    );

    if (response.status === 401 || response.status === 403) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
      return;
    } else if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (error.message.includes("401") || error.message.includes("403")) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
    }
    throw catchError(error);
  }
};

export const JobJobFormikData = (jobValue, values) => {
  return {
    type: JOBS.jobJobEditData,
    jobData: jobValue,
    formikData: values,
  };
};

export const JobCreateFormikData = (jobValue, values) => {
  return {
    type: JOBS.jobCreateEditData,
    jobData: jobValue,
    formikData: values,
  };
};

export const JobResumeDuplicate = async (params) => {
  try {
    const response = await POST(`${URL}/resume/save/`, {}, params);
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

// Create-Job
export const JobIndustryList = async () => {
  try {
    const response = await GET(`${URL}/intake/industries/list/`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobIndustrySubmit = async (payload) => {
  try {
    const response = await POST(`${URL}/intake/jobdata/title`, payload, {});
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobIndustryScoreSubmit = async (param) => {
  try {
    const response = await POST(`${URL}/intake/jobdata/title/score`, {}, param);
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobClassificationList = async (param) => {
  try {
    const response = await GET(`${URL}/intake/classification/list/`, param);
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJDSubmit = async (payload) => {
  try {
    const token = await getToken();
    const response = await fetch(`${URL}/intake/jobdata/jd`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 401 || response.status === 403) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
      return;
    } else if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // const data = await response.json();
    return response;
  } catch (error) {
    if (error.message.includes("401") || error.message.includes("403")) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
    }
    throw error;
  }
};

export const checkBackGroundProcess = async (job_id) => {
  try {
    const response = await POST(`${URL}/intake/jobdata/check/${job_id}`);
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const CreateJobDelete = async (id) => {
  try {
    const response = await PUT(`${URL}/intake/remove/${id}`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const StatusChange = async (id, params) => {
  try {
    const response = await PUT(
      `${URL}/matchengine/change/status/${id}`,
      {},
      params
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const TableStatusChange = async (id, params) => {
  try {
    const response = await PUT(
      `${URL}/intake/jobdata/change-status/${id}`,
      {},
      params
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const TableStatusChangeList = async (payload) => {
  try {
    const response = await PUT(
      `${URL}/intake/jobdata/change-status-list`,
      payload,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const AttachementCreateCall = async (job_id, payload) => {
  try {
    const response = await POST(
      `${URL}/matchengine/match/attachment/${job_id}`,
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const AttachementTableGetCall = async (job_id) => {
  try {
    const response = await GET(
      `${URL}/matchengine/match/attachment/${job_id}`,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const AttachementDownload = async (job_id) => {
  try {
    const token = await getToken();
    const response = await fetch(
      `${URL}/matchengine/match/attachment/download/${job_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 401 || response.status === 403) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
      return;
    } else if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (error.message.includes("401") || error.message.includes("403")) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
    }
    throw catchError(error);
  }
};

export const AttachementDelete = async (id) => {
  try {
    const response = await DELETE(
      `${URL}/matchengine/match/attachment/${id}`,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const AttachementViewGetCall = async (job_id) => {
  try {
    const response = await GET(
      `${URL}/matchengine/match/attachment/view/${job_id}`,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobCandidateQueationEnable = async (payload) => {
  try {
    const response = await PUT(
      `${URL}/matchengine/match/prescreen/status`,
      payload,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
