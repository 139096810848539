import { ExpandMore } from "@mui/icons-material";
import {
  Avatar,
  Box,
  FormControl,
  Grid2,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowUpRightIco from "../../../../assets/icons/ArrowUpRightIco";
import ClipBoardCheck from "../../../../assets/icons/ClipBoardCheckIco";
import DotsVerticalIco from "../../../../assets/icons/DotsVerticalIco";
import ShareIco from "../../../../assets/icons/ShareIco";
import UsIco from "../../../../assets/icons/UsIco";
import {
  StyledFlexColumnView,
  StyledFlexRowView,
  SubTitleTypo,
  TitleTypo,
} from "../../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../../portal/quick-match/components/StyledComponent";

function CompareProfile({
  matchedData,
  getlistResumeData,
  listResumeData,
  handleReplaceSecondaryId,
}) {
  const [expandMoreAnchor, setExpandMoreAnchor] = useState(null);
  const [dotsAnchor, setDotsAnchor] = useState(null);
  const isExpandMoreMenuOpen = Boolean(expandMoreAnchor);
  const isDotsMenuOpen = Boolean(dotsAnchor);
  const navigate = useNavigate();
  const handleExpandMoreClick = (event) => {
    setExpandMoreAnchor(event.currentTarget);
  };

  const handleDotsClick = (event) => {
    setDotsAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setExpandMoreAnchor(null);
    setDotsAnchor(null);
  };

  const handleViewResume = () => {
    navigate(`/candidates/candidate-view?view-id=${matchedData.resume_id}`);
  };

  const statusOptions = [
    { label: "Hired", color: "#17B26A" },
    { label: "In Progress", color: "#9E77ED" },
    { label: "Rejected", color: "#F04438" },
  ];

  const dotsMenuItems = [
    {
      label: "View Resume",
      icon: <ClipBoardCheck />,
      onClick: handleViewResume,
    },
    {
      label: "Share",
      icon: <ShareIco />,
    },
  ];

  return (
    <div
      style={{
        marginTop: "16px",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        borderRadius: "12px",
        border: "1px solid #D0D5DD ",
      }}
    >
      <StyledSpaceBetweenBox
        sx={{ borderBottom: "1px solid #D0D5DD", padding: "12px" }}
      >
        <StyledFlexRowView>
          <Avatar
            alt={matchedData?.resume_info?.candidate_name || "Avatar"}
            src={matchedData?.avatar_url}
            sx={{
              width: 64,
              height: 64,
              backgroundColor: "#F2F4F7",
              color: "#667085",
              border: "1px solid #D0D5DD",
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "24px",
            }}
          >
            {!matchedData?.avatar_url &&
              matchedData?.resume_info?.candidate_name?.[0]?.toUpperCase()}
          </Avatar>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: "#101828",
              display: "flex",
              alignItems: "center",
            }}
          >
            {matchedData?.resume_info?.candidate_name}
          </Typography>

          <FormControl>
            <Select
              value={"In Progress"}
              displayEmpty
              renderValue={(selected) => {
                const selectedOption = statusOptions.find(
                  (option) => option.label === selected
                );
                return (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        backgroundColor: selectedOption.color,
                        marginRight: "8px",
                      }}
                    />
                    <Typography>{selectedOption.label}</Typography>
                  </Box>
                );
              }}
              IconComponent={ExpandMore}
              sx={{
                "& .MuiSelect-select": {
                  paddingLeft: "10px !important",
                  paddingTop: "1px !important",
                  paddingBottom: "1px !important",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "6px",
                  paddingRight: "25px !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D0D5DD",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                },
                "& .MuiSelect-icon": {
                  fontSize: "14px",
                },
              }}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.label} value={option.label}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {option.label}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledFlexRowView>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <IconButton
            size="small"
            sx={{
              border: "1px solid #D0D5DD",
              borderRadius: "8px",
              padding: "8px",
            }}
            onClick={handleExpandMoreClick}
          >
            <ExpandMore />
          </IconButton>
          <IconButton
            size="small"
            sx={{
              border: "1px solid #D0D5DD",
              borderRadius: "8px",
              padding: "10px",
            }}
            onClick={handleDotsClick}
          >
            <DotsVerticalIco />
          </IconButton>
        </Box>
      </StyledSpaceBetweenBox>
      <Menu anchorEl={dotsAnchor} open={isDotsMenuOpen} onClose={handleClose}>
        {dotsMenuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick?.();
              handleClose();
            }}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              {item.icon}
              <Typography>{item.label}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={expandMoreAnchor}
        open={isExpandMoreMenuOpen}
        onClose={handleClose}
      >
        {listResumeData.length > 0 ? (
          listResumeData.map((resume, index) => (
            <React.Fragment key={index}>
              {console.log(resume)}
              <MenuItem
                key={index}
                onClick={() => handleReplaceSecondaryId(resume.id)}
              >
                <Avatar
                  alt={resume?.name || "Avatar"}
                  src={
                    resume?.avatar_url ||
                    "https://mui.com/static/images/avatar/1.jpg"
                  }
                  sx={{ width: 24, height: 24, marginRight: 1 }}
                />
                <Typography>{resume?.name || "Candidate"}</Typography>
              </MenuItem>
            </React.Fragment>
          ))
        ) : (
          <MenuItem disabled>No data available</MenuItem>
        )}
      </Menu>

      <div>
        <Grid2 container spacing={2} sx={{ padding: "12px" }}>
          <Grid2 size={6}>
            <StyledFlexColumnView sx={{ gap: "6px !important" }}>
              <TitleTypo>{"Title"}</TitleTypo>
              <SubTitleTypo>
                {matchedData?.resume_info?.inferred_title
                  ? matchedData?.resume_info?.inferred_title
                  : "-"}
              </SubTitleTypo>
            </StyledFlexColumnView>
          </Grid2>
          <Grid2 size={6}>
            <StyledFlexColumnView sx={{ gap: "6px !important" }}>
              <TitleTypo>{"Rate"}</TitleTypo>
              <SubTitleTypo>
                {matchedData?.resume_info?.pay_rate
                  ? matchedData?.resume_info?.pay_rate
                  : "-"}
              </SubTitleTypo>
            </StyledFlexColumnView>
          </Grid2>

          <Grid2 size={6}>
            <StyledFlexColumnView sx={{ gap: "6px !important" }}>
              <TitleTypo>{"Location"}</TitleTypo>
              {matchedData?.resume_info?.location ? (
                <StyledFlexRowView>
                  <UsIco />
                  <SubTitleTypo>
                    {matchedData?.resume_info?.location}
                  </SubTitleTypo>
                </StyledFlexRowView>
              ) : (
                "-"
              )}
            </StyledFlexColumnView>
          </Grid2>

          <Grid2 size={6}>
            <StyledFlexColumnView sx={{ gap: "6px !important" }}>
              <TitleTypo>{"Website"}</TitleTypo>
              {matchedData?.resume_info?.website ? (
                <StyledFlexRowView>
                  <SubTitleTypo sx={{ color: "#6941C6 !important" }}>
                    {matchedData?.resume_info?.website}
                  </SubTitleTypo>
                  <ArrowUpRightIco />
                </StyledFlexRowView>
              ) : (
                "-"
              )}
            </StyledFlexColumnView>
          </Grid2>
        </Grid2>
      </div>
    </div>
  );
}

export default CompareProfile;
