import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CommonGrid2,
  DotColor,
  StyledFlexRowView,
} from "../../../common-components/StyledComponents";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
  Legend,
} from "recharts";
import { TooltipParagraph } from "../../style";

function Comparison({ matchedData }) {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, candidate_score, highest_score, average_score } =
        payload[0].payload;
      return (
        <Box
          backgroundColor={"rgb(249 247 255)"}
          padding={"10px"}
          border={"1px solid rgb(127, 86, 217)"}
          borderRadius={"10px"}
        >
          <TooltipParagraph
            textAlign={"center"}
            fontWeight={700}
            fontSize={"15px"}
            color="#000"
          >
            {name}
          </TooltipParagraph>
          <CommonGrid2
            gap={"4px"}
            marginTop={"10px"}
            display={"flex"}
            flexDirection={"column"}
          >
            <TooltipParagraph
              color={"#6941C6"}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
              gap="10px"
            >
              Candidate Score:{" "}
              <TooltipParagraph fontWeight={700}>
                {candidate_score}
              </TooltipParagraph>
            </TooltipParagraph>
            <TooltipParagraph
              color={"#667085a1"}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
              gap="10px"
            >
              Highest Score:{" "}
              <TooltipParagraph fontWeight={700}>
                {highest_score}
              </TooltipParagraph>
            </TooltipParagraph>
            <TooltipParagraph
              color={"#F79009"}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
              gap="10px"
            >
              Average Score:{" "}
              <TooltipParagraph fontWeight={700}>
                {average_score}
              </TooltipParagraph>
            </TooltipParagraph>
          </CommonGrid2>
        </Box>
      );
    }
    return null;
  };

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <CommonGrid2 justifyContent="center" display={"flex"}>
        {payload.map((entry, index) => (
          <CommonGrid2
            key={`item-${index}`}
            alignItems={"center"}
            margin={"0 10px"}
            flexDirection={"row"}
            display={"flex"}
          >
            <DotColor
              color={entry.color}
              marginRight={"5px"}
              width={"8px"}
              height={"8px"}
            ></DotColor>
            <Typography fontSize={"14px"} fontWeight={600} color={"#475467"}>
              {entry.value}
            </Typography>
          </CommonGrid2>
        ))}
      </CommonGrid2>
    );
  };

  const CustomPolarRadiusAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <text
        x={x}
        y={y}
        dy={16}
        textAnchor="middle"
        fill="#666"
        fontWeight="bold"
      >
        {payload.value}
      </text>
    );
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          "& .MuiAccordionSummary-expandIconWrapper": {
            border: "1px solid #D0D5DD",
            borderRadius: "8px",
            padding: "4px",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px !important",
            minHeight: "55px",
          },
          padding: "0px !important",
        }}
      >
        <StyledFlexRowView>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: "#101828",
              display: "flex",
              alignItems: "center",
            }}
          >
            Comparison
          </Typography>
        </StyledFlexRowView>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px" }}>
        <Box
          sx={{
            width: "100%",
            mx: "auto",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            borderRadius: "12px",
            border: "1px solid #EAECF0 !important",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 1,
          }}
        >
          <RadarChart
            cx="50%"
            cy="50%"
            outerRadius="90%"
            width={600}
            height={400}
            // data={data}
            data={matchedData?.spider_graph_results}
          >
            <PolarGrid />

            <PolarAngleAxis
              dataKey="name"
              tick={{ fontWeight: 600, fill: "#475467" }}
            />
            <PolarRadiusAxis
              angle={30}
              domain={[0, 100]}
              tick={CustomPolarRadiusAxisTick}
              axisLine={false}
            />
            <Radar
              name="Candidate Score"
              dataKey="candidate_score"
              stroke="#6941C6"
              fill="transparent"
              fillOpacity={0.3}
              strokeWidth={2}
            />

            <Radar
              name="Highest Score"
              dataKey="highest_score"
              stroke="#667085a1"
              fill="transparent"
              fillOpacity={0.3}
              strokeWidth={2}
            />

            <Radar
              name="Average Score"
              dataKey="average_score"
              stroke="#F79009"
              fill="transparent"
              fillOpacity={0.1}
              strokeWidth={2}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend content={renderLegend} />
          </RadarChart>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default Comparison;
