import { Grid2, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  CreateTabList,
  TabPanelView,
} from "../../common-components/StyledComponents";
import { TabContext } from "@mui/lab";
import InputJobData from "./components/InputJobData";
import SelectCreationModel from "./select-creation-model";
import { useNavigate } from "react-router-dom";
import JobInformation from "../job-information";
import ConfirmSettings from "./components/ConfirmSettings";

const CreateJob = () => {
  const navigate = useNavigate();
  const [inputJdView, setInputJdView] = useState("");

  const [tabData, setTabData] = useState([
    {
      name: "Select Creation Model",
      disabled: false,
    },
    {
      name: "Input Job Data",
      disabled: true,
    },
    {
      name: "Review Job Post",
      disabled: true,
    },
    {
      name: "Confirm Settings",
      disabled: true,
    },
  ]);
  const [value, setValue] = React.useState("0");

  const handleChange = (event, newValue) => {
    console.log(newValue, "slkfjslkfglks");
    setValue(newValue);
  };

  const selectCreateData = [
    {
      name: "Enrich Job Post with Job Code/VMS ID",
      description: "Description",
      key: 1,
    },
    {
      name: "Create a Job Post with Talairo Helix",
      description: "Description",
      key: 2,
    },
    {
      name: "Auto-Generate a Job Post",
      description: "Description",
      key: 3,
    },
    {
      name: "Create Job Post using Resume",
      description: "Description",
      key: 4,
    },
    {
      name: "Create Job Post Manually",
      description: "Description",
      key: 5,
    },
  ];
  const headerData = [
    "Job",
    "Job Title",
    "Team",
    "Priority",
    "Ready",
    "Owner",
    "",
  ];
  const handleContinue = (data) => {
    setValue(data.key === 5 ? "2" : "1");
    setTabData((prev) =>
      prev.map((tab, index) =>
        index === 1 || index === 2 || index === 3
          ? { ...tab, disabled: false }
          : tab
      )
    );
    tabData[1].disabled = false;
    navigate(`/jobs/create-jobs?key=${data.key}`);
    setInputJdView(data.key);
  };

  return (
    <Grid2 alignItems="center">
      <Typography variant="h2">Create Job</Typography>

      <TabContext value={value}>
        <Grid2 alignItems="center" marginTop={3}>
          <CreateTabList onChange={handleChange} aria-label="create-job">
            {tabData.map((item, index) => (
              <Tab
                label={item.name}
                value={index.toString()}
                disabled={item.disabled}
              />
            ))}
          </CreateTabList>
        </Grid2>
        <TabPanelView value="0" padding="1.5rem 0px 0px">
          <SelectCreationModel
            selectCreateData={selectCreateData}
            headerData={headerData}
            handleContinue={handleContinue}
          />
        </TabPanelView>
        <TabPanelView value="1" padding="1.5rem 0px 0px">
          <InputJobData inputJdView={inputJdView} />
        </TabPanelView>
        <TabPanelView value="2" padding="1.5rem 0px 0px">
          <JobInformation alignItems={"Edit"} />
        </TabPanelView>
        <TabPanelView value="3" padding="1.5rem 0px 0px">
          <ConfirmSettings />
        </TabPanelView>
      </TabContext>
    </Grid2>
  );
};
export default CreateJob;
