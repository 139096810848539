export default function RecommendationIco() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="3.75" fill="#DBFAE6" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8254 7.79176L10.4554 12.9743L9.03043 11.4518C8.76793 11.2043 8.35543 11.1893 8.05543 11.3993C7.76293 11.6168 7.68043 11.9993 7.86043 12.3068L9.54793 15.0518C9.71293 15.3068 9.99793 15.4643 10.3204 15.4643C10.6279 15.4643 10.9204 15.3068 11.0854 15.0518C11.3554 14.6993 16.5079 8.55677 16.5079 8.55677C17.1829 7.86676 16.3654 7.25927 15.8254 7.78427V7.79176Z"
        fill="#079455"
        stroke="#079455"
        stroke-width="0.375"
      />
    </svg>
  );
}
