import {
    Grid2,
    TextField
} from "@mui/material";
import React from "react";

function References() {
    return (
        <Grid2 container spacing={2} display={"flex"} justifyContent={"space-between"} >
            <>
                <Grid2 item xs={12} width={"49%"} >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            fullWidth
                            value={"Name"}
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                flex: 0.3,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px 0 0 8px",

                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "#667085",
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            value={"Olivia Rhye"}
                            variant="outlined"
                            sx={{
                                flex: 1,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "0 8px 8px 0",
                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                },
                            }}
                        />
                    </div>

                </Grid2>
                <Grid2 item xs={12} width={"49%"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            fullWidth
                            value={"Phone"}
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                flex: 0.3,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px 0 0 8px",

                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "#667085",
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            value={"+381 60 0000 000"}
                            variant="outlined"
                            sx={{
                                flex: 1,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "0 8px 8px 0",
                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                },
                            }}
                        />
                    </div>

                </Grid2>
                <Grid2 item xs={12} width={"100%"} >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            fullWidth
                            value={"Interview Details"}
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                flex: 0.2,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px 0 0 8px",

                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "#667085",
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            value={"You’ll be contacted by {reference.name} via email regarding the technical interview at {client.name}, {client.location}."}
                            variant="outlined"
                            sx={{
                                flex: 1,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "0 8px 8px 0",
                                },
                                "& .MuiInputBase-input": {
                                    padding: "12px",
                                },
                            }}
                        />
                    </div>

                </Grid2>
            </>
        </Grid2>
    );
}

export default References;
