function CopyIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_151_10599)">
        <path
          d="M6.66602 6.66602V4.33268C6.66602 3.39926 6.66602 2.93255 6.84767 2.57603C7.00746 2.26243 7.26243 2.00746 7.57603 1.84767C7.93255 1.66602 8.39926 1.66602 9.33268 1.66602H15.666C16.5994 1.66602 17.0661 1.66602 17.4227 1.84767C17.7363 2.00746 17.9912 2.26243 18.151 2.57603C18.3327 2.93255 18.3327 3.39926 18.3327 4.33268V10.666C18.3327 11.5994 18.3327 12.0661 18.151 12.4227C17.9912 12.7363 17.7363 12.9912 17.4227 13.151C17.0661 13.3327 16.5994 13.3327 15.666 13.3327H13.3327M4.33268 18.3327H10.666C11.5994 18.3327 12.0661 18.3327 12.4227 18.151C12.7363 17.9912 12.9912 17.7363 13.151 17.4227C13.3327 17.0661 13.3327 16.5994 13.3327 15.666V9.33268C13.3327 8.39926 13.3327 7.93255 13.151 7.57603C12.9912 7.26243 12.7363 7.00746 12.4227 6.84767C12.0661 6.66602 11.5994 6.66602 10.666 6.66602H4.33268C3.39926 6.66602 2.93255 6.66602 2.57603 6.84767C2.26243 7.00746 2.00746 7.26243 1.84767 7.57603C1.66602 7.93255 1.66602 8.39926 1.66602 9.33268V15.666C1.66602 16.5994 1.66602 17.0661 1.84767 17.4227C2.00746 17.7363 2.26243 17.9912 2.57603 18.151C2.93255 18.3327 3.39926 18.3327 4.33268 18.3327Z"
          stroke="#7F56D9"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_151_10599">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CopyIco;
