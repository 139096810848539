import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  DialogContent,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import validator from "validator"; // Importing email validator
import * as Yup from "yup";
import UsersIco from "../../../../assets/icons/UsersIco";
import XCloseIco from "../../../../assets/icons/XCloseIco";
import CommonButton from "../../../../component/Button";
import {
  CommonGrid2,
  StyledFlexRowView,
} from "../../../common-components/StyledComponents";
import { CommonDialogStyle } from "./StyledComponent";

function ShareQuickMatch({
  shareDialogOpen,
  handleShareDialogClose,
  handleShareInvite,
  isLoading,
}) {
  const handleShareChange = (e, newValue, setFieldValue, values) => {
    console.log(e, newValue, values, "sfkjhsbfkj;hskj");
    const inputValue = e.target.value;
    if (e.key === "Backspace" && inputValue === "") {
      const updatedShareData = [...values.shareData];
      updatedShareData.pop();
      setFieldValue("shareData", updatedShareData);
    } else if (validator.isEmail(inputValue)) {
      setFieldValue(
        "shareData",
        newValue.map((item) =>
          typeof item === "string" ? { email: item } : item
        )
      );
    }
  };

  const handleShareDelete = (values, setFieldValue, option) => {
    const updatedValue = values.shareData.filter((item, i) => item !== option);
    setFieldValue("shareData", updatedValue);
  };

  const validationSchema = Yup.object({
    shareData: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        })
      )
      .test(
        "at-least-one-email",
        "At least one email is required",
        (value) => Array.isArray(value) && value.length > 0
      ),
  });

  return (
    <CommonDialogStyle
      maxWidth="sm"
      fullWidth
      open={shareDialogOpen}
      onClose={handleShareDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={{ shareData: [] }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values, "sfjbsjkfbsfgjkkgsjnsgknj");
          handleShareInvite(values.shareData);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          console.log(values, "sflskjflksfnlksjflk");
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid2 container spacing={2}>
                  <Grid2 size={12}>
                    <CommonGrid2
                      display={"flex"}
                      justifyContent={"space-between"}
                      flexDirection={"row"}
                      gap={"10px"}
                      alignItems={"center"}
                    >
                      <IconButton>
                        <UsersIco />
                      </IconButton>
                      <IconButton onClick={handleShareDialogClose}>
                        <XCloseIco />
                      </IconButton>
                    </CommonGrid2>
                  </Grid2>

                  <Grid2 size={12}>
                    <Typography
                      fontSize={"18px"}
                      color={"#101828"}
                      fontWeight={600}
                    >
                      {"Share Quick Match"}
                    </Typography>
                  </Grid2>
                  <Grid2 size={12}>
                    <StyledFlexRowView alignItems={"flex-start"}>
                      <div style={{ width: "100%" }}>
                        <Autocomplete
                          multiple
                          freeSolo
                          options={[]}
                          limitTags={2}
                          value={values.shareData}
                          onChange={(event, newValue) => {
                            handleShareChange(
                              event,
                              newValue,
                              setFieldValue,
                              values
                            );
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.email}
                                {...getTagProps({ index })}
                                onDelete={() => {
                                  handleShareDelete(
                                    values,
                                    setFieldValue,
                                    option
                                  );
                                }}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: null,
                              }}
                              placeholder="Enter Email id"
                              error={
                                touched.shareData && Boolean(errors.shareData)
                              }
                              helperText={
                                touched.shareData && errors.shareData
                                  ? errors.shareData
                                  : ""
                              }
                            />
                          )}
                        />
                        <span style={{ fontSize: "10px" }}>
                          (Please type the next email ID and press the 'Enter'
                          button)
                        </span>
                      </div>
                      <CommonButton
                        variant="outlined"
                        color="#98A2B3"
                        border="#D0D5DD"
                        fontWeight="600"
                        value="Share"
                        padding={"6.5px"}
                        width={"150px"}
                        type="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      />
                    </StyledFlexRowView>
                  </Grid2>
                  <Grid2 size={12}>
                    {values.shareData.map((user, index) => (
                      <CommonGrid2
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                        gap={"10px"}
                        alignItems={"center"}
                        marginTop={"16px"}
                        key={index}
                      >
                        <Chip
                          avatar={
                            <Avatar
                              sx={{
                                width: "40px !important",
                                fontSize: "20px !important",
                                height: "40px !important",
                              }}
                            >
                              {user.email.charAt(0)}
                            </Avatar>
                          }
                          label={user.email}
                        />
                        <Button
                          onClick={() =>
                            handleShareDelete(values, setFieldValue, user)
                          }
                          sx={{
                            color: "#B42318",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          Remove
                        </Button>
                      </CommonGrid2>
                    ))}
                  </Grid2>
                </Grid2>
              </DialogContent>
            </Form>
          );
        }}
      </Formik>
    </CommonDialogStyle>
  );
}

export default ShareQuickMatch;
