import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import XCloseIco from "../../../../assets/icons/XCloseIco";
import {
  StyledFlexColumnView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";
import NoteBackgroundImg from "../../../../assets/images/note-background.png";
import { Download } from "@mui/icons-material";

const data = [
  {
    id: 1,
    fileName: "file1.txt",
  },
  {
    id: 2,
    fileName: "file2.txt",
  },
];
function DownloadJD({
  openDownloadJdDialog,
  handleCloseDownloadJdDialog,
  jdFileList,
  handleDownloadJd,
  jdFileListLoader,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={openDownloadJdDialog}
      onClose={handleCloseDownloadJdDialog}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "700px",
          borderRadius: "12px",
          background: "#FFF",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        },
      }}
    >
      <img
        src={NoteBackgroundImg}
        style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
      />
      <DialogActions>
        <Button
          onClick={handleCloseDownloadJdDialog}
          sx={{ marginTop: "10px" }}
        >
          <XCloseIco />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {"Download Job"}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <TableContainerBorder sx={{ marginTop: "0px !important" }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableTitleTableCell>
                  {"File Name"}
                </StyledTableTitleTableCell>
                <StyledTableTitleTableCell align="right">
                  {"Download"}
                </StyledTableTitleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jdFileListLoader
                ? Array.from(new Array(3)).map((el, ind) => (
                    <TableCell sx={{ width: "100%" }}>
                      <Skeleton
                        key={`key-${ind.toString()}`}
                        width={"100%"}
                        height={100}
                      />
                    </TableCell>
                  ))
                : jdFileList.map((row) => (
                    <TableRow key={row.id}>
                      <StyledTableBodyTableCell>
                        {row.file_name}
                      </StyledTableBodyTableCell>

                      <TableCell align="right">
                        <IconButton onClick={() => handleDownloadJd(row)}>
                          <Download />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainerBorder>
      </DialogContent>
    </Dialog>
  );
}

export default DownloadJD;
