import AddIcon from "@mui/icons-material/Add";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DownloadJd,
  getJdFileList,
  getJobDataDetail,
  getMatchedJdResumeData
} from "../../../api/Api";
import FileExtensionIco from "../../../assets/icons/FileExtensionIco";
import Breadcrumbs from "../../../component/Breadcrumbs";
import CommonButton from "../../../component/Button";
import SnackBar from "../../../component/SnackBar";
import { UploadJob } from "../../../redux/action/QuickMatch";
import { GET, GETDOWNLOAD } from "../../../redux/axios/Axios";
import {
  CommonGrid2,
  TabPanelView,
  TabViewSection,
} from "../../common-components/StyledComponents";
import AddMoreCandidate from "../../portal/quick-match/components/AddMoreCandidate";
import DownloadJD from "../job-information/download-jd";
import JobInformation from "../job-information/index";
import JobsCandidate from "../jobs-candidate";
import JobsSummary from "../jobs-summary";

export default function JobsView() {
  const query = new URLSearchParams(useLocation().search);
  const jobId = query.get("id");
  const [value, setValue] = React.useState("0");
  const [jobData, setJobData] = React.useState({});
  const [openDownloadJdDialog, setOpenDownloadJdDialog] = React.useState(false);
  const [jdFileList, setJdFileList] = React.useState([]);
  const [jdFileListLoader, setJdFileListLoader] = React.useState(false);
  const [oneTimeCall, setOneTimeCall] = React.useState(false);

  const resumeTabIndex = value - 2;
  const [resumeTab, setResumeTab] = React.useState([]);
  const matchedData = resumeTab[resumeTabIndex]?.details;
  const resumeId = resumeTab[value - 2]?.id;
  const filteredResumeTab = resumeTab.filter((item) => item.id !== resumeId);

  const [resumeLoader, setResumeLoader] = React.useState(false);
  const valueLocation = useLocation().search;
  const navigate = useNavigate();

  const [files, setFiles] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [wrongClose, setWrongClose] = React.useState(false);

  const [addMoreCandidateDialogOpen, setAddMoreCandidateDialogOpen] =
    React.useState(false);
  const [isAddMoreCandidateDragging, setIsAddMoreCandidateDragging] =
    React.useState(false);
  const [addMoreCandidateFiles, setAddMoreCandidateFiles] = React.useState([]);
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: "",
    severity: "error",
  });

  const handleChange = (event, newValue) => {
    console.log(newValue, "slkfjslkfglks");
    setValue(newValue);
    const index = newValue - 2;
    const isMatchedData = resumeTab[newValue - 2]?.details;
    const resumeId = resumeTab[newValue - 2]?.id;
    if (!isMatchedData && newValue && newValue >= 2) {
      fetchResumeData(resumeId, index);
      // getCandidateNotesDetatil(resumeId);
    }
    const urlParams = new URLSearchParams(valueLocation);
    urlParams.delete("value");
    urlParams.append("value", newValue);
    const newUrl = `/jobs/jobs-view?${urlParams.toString()}`;
    navigate(newUrl);
  };

  const tabData = ["Summary", "Job"];

  const breadCrumbData = [
    {
      title: "Jobs",
      navigate: "/jobs",
    },
    {
      title: query.get("title"),
      active: true,
    },
  ];

  const fetchJobDataDetail = async () => {
    if (jobId) {
      try {
        const res = await GET(getJobDataDetail(jobId));
        setJobData(res?.data);
        setResumeTab(res?.data?.resumes || []);
        setOneTimeCall(true);
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    } else {
      console.error("Job ID is not provided in the URL.");
    }
  };

  React.useEffect(() => {
    const queryValue = query?.get("value");

    if (queryValue >= "2" && oneTimeCall && resumeTab.length !== 0) {
      const data = resumeTab[queryValue - 2]?.id;
      const index = queryValue - 2;
      setValue(queryValue);
      fetchResumeData(data, index);
      setOneTimeCall(false);
    }
  }, [resumeTab, oneTimeCall]);

  const fetchResumeData = async (resumeId, index, resumeObj) => {
    setResumeLoader(true);
    try {
      const data = await GET(getMatchedJdResumeData(resumeId));

      let arr = [...resumeTab];
      console.log(data?.data, "data");
      let detailObj = {
        candidate_name: data?.data?.resume_info?.candidate_name,
        pay_rate: data?.data?.resume_info?.pay_rate,
        location: data?.data?.resume_info?.location,
        inferred_title: data?.data?.resume_info?.inferred_title,
        visa_info: data?.data?.resume_info?.visa_info,
        website: data?.data?.resume_info?.website,
        email: data?.data?.resume_info?.email,
        overall_score: data?.data?.quick_match_score?.overall_score,
        results: data?.data?.results || [],
        skill_calculation: data?.data?.skill_calculation,
        error_message: data?.data?.error_message,
        resume_id: data?.data?.resume_id,
        // file_name: data?.resume_file_name,
        spider_graph_results: data?.data?.spider_graph_results,
        file_name: data?.data?.resume_file_name,
        insights: data?.data?.insights,
        feedback: data?.data?.feedback,
        match_summary: data?.data?.match_summary,
        experience: data?.data?.experience,
      };
      if (arr[index]) {
        arr[index] = {
          ...arr[index],
          details: detailObj,
        };
      } else if (resumeObj) {
        arr.push({
          ...resumeObj,
          details: detailObj,
        });
      }
      setResumeTab(arr);
      console.log(data, "matchdata");
      // setMatchedData({
      //   candidate_name: data?.resume_info?.candidate_name,
      //   pay_rate: data?.resume_info?.pay_rate,
      //   location: data?.resume_info?.location,
      //   inferred_title: data?.resume_info?.inferred_title,
      //   visa_info: data?.resume_info?.visa_info,
      //   overall_score: data?.quick_match_score?.overall_score,
      //   results: data?.results || [],
      // });
      setResumeLoader(false);
    } catch (error) {
      // setSnack({
      //   open: true,
      //   color: "error",
      //   message: error?.response?.data?.message,
      // });
      setResumeLoader(false);
    }
  };

  React.useEffect(() => {
    fetchJobDataDetail();
  }, [jobId]);

  //dame notes

  const handleCloseDownloadJdDialog = () => {
    setOpenDownloadJdDialog(false);
  };

  const handleDownloadJd = (row) => {
    if (row.id) {
      GETDOWNLOAD(DownloadJd(row.id), {
        responseType: "blob",
      })
        .then((response) => {
          const contentType = response.headers["content-type"];
          const extension =
            contentType === "application/pdf"
              ? "pdf"
              : contentType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ? "docx"
                : "doc";

          const fileName = `${row?.file_name}.${extension}`;

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
              const responseText = reader.result;
              try {
                const json = JSON.parse(responseText);
                const errorMessage =
                  json.error || "An unexpected error occurred.";
                setSnackbarState({
                  open: true,
                  color: "error",
                  message: errorMessage,
                });
              } catch (e) {
                setSnackbarState({
                  open: true,
                  color: "error",
                  message:
                    "An unexpected error occurred while parsing the error message.",
                });
              }
            };
            reader.readAsText(error.response.data);
          } else {
            setSnackbarState({
              open: true,
              color: "error",
              message: "An unexpected error occurred.",
            });
          }
        });
    } else {
      setSnackbarState({
        open: true,
        color: "error",
        message: "JD was not parsed successfully. Unable to download.",
      });
    }
  };

  const fetchJdFileList = async () => {
    setJdFileListLoader(true);
    if (jobId) {
      try {
        const res = await GET(getJdFileList(jobId));
        if (res?.success) {
          setJdFileList(res?.data);
          setJdFileListLoader(false);
        }
      } catch (error) {
        setJdFileListLoader(false);
      }
    }
  };

  const handleDownloadJdOpen = () => {
    setOpenDownloadJdDialog(true);
    fetchJdFileList();
  };
  //add more candidate functionality

  const allowedTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const commonFileValidationCheck = (
    commonFiles,
    allowedTypes,
    setCommonFiles,
    existingFiles
  ) => {
    const validFiles = [];
    const invalidFiles = [];

    commonFiles.forEach((file) => {
      if (allowedTypes.includes(file.type)) {
        validFiles.push({
          file,
          name: file.name,
          size: `${(file.size / 1024).toFixed(1)} KB`,
          type: file.type,
          progress: 0,
          buffer: 0,
          visible: false,
          delete: true,
          message: "",
        });
      } else {
        invalidFiles.push(file.name);
      }
    });

    const mergedFiles = [
      ...existingFiles,
      ...validFiles.filter(
        (newFile) =>
          !existingFiles.some(
            (existingFile) => existingFile.name === newFile.name
          )
      ),
    ];
    setCommonFiles(mergedFiles);

    if (invalidFiles.length > 0) {
      setSnackbarState({
        open: true,
        message: `Unsupported file types: ${invalidFiles.join(", ")}`,
        severity: "error",
      });
    }
  };

  const getFileIcon = (file) => {
    const { name, type } = file;

    // Determine the file type based on type or name
    let extension = "";

    if (type?.includes("pdf")) {
      extension = "PDF";
      return (
        <FileExtensionIco extension={extension} extensionColor={"#D92D20"} />
      );
    } else if (
      type?.includes("wordprocessingml.document") ||
      name?.endsWith(".docx")
    ) {
      extension = "DOCX";
      return (
        <FileExtensionIco extension={extension} extensionColor={"#155EEF"} />
      );
    } else if (type?.includes("msword") || name?.endsWith(".doc")) {
      extension = "DOC";
      return (
        <FileExtensionIco extension={extension} extensionColor={"#155EEF"} />
      );
    }

    return <FileExtensionIco extension={""} extensionColor={""} />;
  };

  const handleAddMoreCandidateDialogClose = () => {
    setAddMoreCandidateDialogOpen(false);
    setAddMoreCandidateFiles([]);
  };

  const handleAddMoreCandidateOpen = () => {
    setAddMoreCandidateDialogOpen(true);
  };

  const handleAddMoreCandidateFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    commonFileValidationCheck(
      selectedFiles,
      allowedTypes,
      setAddMoreCandidateFiles,
      addMoreCandidateFiles
    );
  };

  const handleAddMoreCandidateDeleteFile = (index) => {
    setAddMoreCandidateFiles((prevFiles) =>
      prevFiles.filter((_, i) => i !== index)
    );
  };

  const handleAddMoreCandidateDragOver = (event) => {
    event.preventDefault();
    setIsAddMoreCandidateDragging(true);
  };

  const handleAddMoreCandidateDragLeave = () => {
    setIsAddMoreCandidateDragging(false);
  };

  const handleAddMoreCandidateDrop = (event) => {
    event.preventDefault();
    setIsLoading(false);
    setIsAddMoreCandidateDragging(false);

    const droppedFiles = Array.from(event.dataTransfer.files);
    commonFileValidationCheck(
      droppedFiles,
      allowedTypes,
      setAddMoreCandidateFiles,
      addMoreCandidateFiles
    );
  };

  const processJsonResponse = (
    jsonResponse,
    getName,
    setCandidateFiles,
    getAddMore
  ) => {
    const { success, data, message } = jsonResponse;
    if (success && data?.percent !== undefined) {
      setCandidateFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.name === data.current_resume
            ? {
              ...f,
              progress: data.percent,
              buffer: data.buffer || data.percent,
              visible: false,
              delete: false,
            }
            : f
        )
      );
    } else if (getAddMore === "Add-more-Candidate" && data?.length !== 0) {
      setAddMoreCandidateDialogOpen(false);
      setAddMoreCandidateFiles([]);
      if (Array.isArray(data) && data.length > 0 && data[0]?.jd_info) {
        const jdInfo = data[0]?.jd_info;
        let responseId = data[0]?.id;
        let arr = [...resumeTab];
        console.log(responseId, "responseId");
        let resumeObj = {
          id: responseId,
          name: data[0]?.resume_info?.candidate_name
            ? data[0]?.resume_info?.candidate_name
            : "",
          score: data[0]?.quick_match_score?.overall_score
            ? data[0]?.quick_match_score?.overall_score
            : data[0]?.score,
          file_name: data[0]?.resume_file_name,
        };
        arr.push(resumeObj);
        let currentResumeIndex = arr.length + 1;
        console.log(currentResumeIndex, "currentResumeIndex");
        setResumeTab(arr);
        fetchResumeData(responseId, currentResumeIndex, resumeObj);
        setValue(currentResumeIndex?.toString());
        const urlParams = new URLSearchParams(valueLocation);
        urlParams.delete("value");
        urlParams.append("value", currentResumeIndex);

        const newUrl = `/jobs/jobs-view?${urlParams.toString()}`;
        navigate(newUrl);
      }
    } else if (!success) {
      setWrongClose(true);
      setCandidateFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.name === data.current_resume || data.length == 0
            ? {
              ...f,
              visible: true,
              delete: true,
              message: message,
            }
            : { ...f, delete: true }
        )
      );
    }
  };

  const uploadFile = async (getName, setCandidateFiles, getAddMore) => {
    setIsLoading(true);

    setCandidateFiles((prevFiles) =>
      prevFiles.map((f) => ({
        ...f,
        delete: false,
      }))
    );

    const formData = new FormData();
    if (getAddMore === "Add-more-Candidate") {
      addMoreCandidateFiles.forEach((fileObj, index) => {
        formData.append("resume_file", fileObj.file);
      });
      formData.append("id", jobId);

      try {
        const response = await UploadJob(formData, getName);
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let buffer = "";

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });
          buffer += chunk;
          let boundaryIndex;
          while ((boundaryIndex = buffer.indexOf("}\n")) !== -1) {
            const jsonString = buffer.slice(0, boundaryIndex + 1);
            buffer = buffer.slice(boundaryIndex + 1);
            const jsonResponse = JSON.parse(jsonString);
            try {
              processJsonResponse(
                jsonResponse,
                getName,
                setCandidateFiles,
                getAddMore
              );
            } catch (e) {
              if (getName === "NextUpload") {
                setFiles((prevFiles) =>
                  prevFiles.map((f) => ({
                    ...f,
                    visible: true,
                    delete: true,
                    message: jsonResponse.message,
                  }))
                );
                setWrongClose(true);
              } else {
                setSnackbarState({
                  open: true,
                  message: "Error parsing JSON",
                  severity: "error",
                });
                setCandidateFiles((prevFiles) =>
                  prevFiles.map((f) =>
                    f.name === jsonResponse.data.current_resume
                      ? {
                        ...f,
                        visible: true,
                        delete: true,
                      }
                      : { ...f, delete: true }
                  )
                );
              }
            }
          }
        }

        if (buffer) {
          const jsonResponse = JSON.parse(buffer);
          try {
            processJsonResponse(
              jsonResponse,
              getName,
              setCandidateFiles,
              getAddMore
            );
          } catch (e) {
            setSnackbarState({
              open: true,
              message: "Error parsing final JSON chunk",
              severity: "error",
            });
            setCandidateFiles((prevFiles) =>
              prevFiles.map((f) =>
                f.name === jsonResponse.data.current_resume
                  ? {
                    ...f,
                    visible: true,
                    delete: true,
                  }
                  : { ...f, delete: true }
              )
            );
          }
        }
        setIsLoading(false);
      } catch (error) {
        setSnackbarState({
          open: true,
          message: "Error during the request",
          severity: "error",
        });
        setCandidateFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            visible: true,
            delete: true,
            message: "Error during the request",
          }))
        );

        setIsLoading(false);
      }
    }
  };

  const handleAddMoreCandidateClick = async () => {
    if (addMoreCandidateFiles.length == 0) {
      setSnackbarState({
        open: true,
        message: "Upload the Files",
        severity: "error",
      });
    } else {
      uploadFile(
        "Upload-Candidate",
        setAddMoreCandidateFiles,
        "Add-more-Candidate"
      );
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <>
      <Breadcrumbs breadCrumbData={breadCrumbData} />

      <TabContext value={value}>
        <CommonGrid2 container spacing={2} alignItems="center" marginTop={2.5}>
          <CommonGrid2 item size={{ xs: 12, md: 10, sm: 9 }}>
            <TabViewSection
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {tabData.map((item, index) => (
                <Tab label={item} value={index.toString()} />
              ))}
              {resumeTab?.map((elt, index) => (
                <Tab
                  label={`#${index + 1} ${elt?.name ? elt?.name : elt?.file_name
                    } - ${elt?.score}%`}
                  value={(index + tabData.length).toString()} // Ensure unique values
                  key={index + tabData.length}
                  sx={{
                    textTransform: "none",
                    "&.Mui-selected": {
                      color: "black",
                      fontWeight: "bold",
                    },
                  }}
                />
              ))}
            </TabViewSection>
          </CommonGrid2>

          <CommonGrid2
            item
            size={{ xs: 12, md: 2, sm: 3 }}
            gap="1rem"
            justifyContent="end"
            display="flex"
          >
            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              value={<VisibilityOutlinedIcon />}
              padding="8px 0px"
            />

            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              value={<AddIcon />}
              padding="8px 0px"
              onClick={handleAddMoreCandidateOpen}
            />
          </CommonGrid2>
        </CommonGrid2>

        <TabPanelView value="0" padding="2rem 0px">
          <JobsSummary />
        </TabPanelView>

        <TabPanelView value="1" padding="3rem 0px">
          <JobInformation
            jobData={jobData}
            jobId={jobId}
            handleDownloadJdOpen={handleDownloadJdOpen}
            fetchData={fetchJobDataDetail}
          />
        </TabPanelView>

        {resumeTab.map((elt, index) => (
          <TabPanelView
            value={(index + tabData.length).toString()}
            padding="3rem 0px"
            key={index + tabData.length}
          >
            <JobsCandidate
              matchedData={matchedData}
              resumeId={resumeId}
              resumeLoader={resumeLoader}
              filteredResumeTab={filteredResumeTab}
            />
          </TabPanelView>
        ))}
      </TabContext>

      {/* Download JD Dailog */}
      <DownloadJD
        openDownloadJdDialog={openDownloadJdDialog}
        handleCloseDownloadJdDialog={handleCloseDownloadJdDialog}
        jdFileList={jdFileList}
        handleDownloadJd={handleDownloadJd}
        jdFileListLoader={jdFileListLoader}
      />

      {/* Add Candidate Resume */}
      <AddMoreCandidate
        addMoreCandidateDialogOpen={addMoreCandidateDialogOpen}
        handleAddMoreCandidateDialogClose={handleAddMoreCandidateDialogClose}
        handleAddMoreCandidateDragOver={handleAddMoreCandidateDragOver}
        handleAddMoreCandidateDragLeave={handleAddMoreCandidateDragLeave}
        handleAddMoreCandidateDrop={handleAddMoreCandidateDrop}
        handleAddMoreCandidateFileUpload={handleAddMoreCandidateFileUpload}
        addMoreCandidateFiles={addMoreCandidateFiles}
        getFileIcon={getFileIcon}
        isAddMoreCandidateDragging={isAddMoreCandidateDragging}
        handleAddMoreCandidateDeleteFile={handleAddMoreCandidateDeleteFile}
        handleAddMoreCandidateClick={handleAddMoreCandidateClick}
        isLoading={isLoading}
        isJem={true}
        disabled={addMoreCandidateFiles?.length >= 1}
        buttonDisabled={isLoading}
      />

      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </>
  );
}