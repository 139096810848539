export const QUICKMATCH = {
  reviewMatch: "reviewMatch",
};

export const JOBS = {
  jobs: "jobs",
  jobSummary: "jobSummary",
  jobJobEdit: "jobJobEdit",
  jobJobEditData: "jobJobEditData",
  jobCreateEditData: "jobCreateEditData",
};

export const RESUMEINTELLIGENCE = {
  resumeIntelligenceUpload: "resumeIntelligenceUpload",
  resumeIntelligenceSave: "resumeIntelligenceSave",
};

export const CANDIDATE = {
  candidateView: "candidateView",
};

export const CHATBOT = {
  chatBot: "chatBot",
  candidatechatBot: "candidatechatBot",
  comparechatBot: "comparechatBot",
};

export const COVER = {
  coverGet: "coverGet",
};

export const HARIZON = {
  harizonAnalytics: "harizonAnalytics",
};

export const CLINET_PARTNER = {
  clientAddCall: "clientAddCall",
};

export const VENDOR_PARTNER = {
  vendorAddCall: "vendorAddCall",
};
