import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import TalairoLogo from "../../../../assets/icons/TalairoLogo";
import CommonButton from "../../../../component/Button";
import CommonTextField from "../../../../component/Input";
import SnackBar from "../../../../component/SnackBar";
import { ResetPasswordCall } from "../../../../redux/action/Login";
import { CustomDiv } from "../../../chat-bot/style";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import PopupTickIco from "../../../../assets/icons/PopupTickIco";

export default function ResetPassword() {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [showPassword, setShowPassword] = useState({ old: false, new: false });
  const query = new URLSearchParams(useLocation().search);
  const getId = query.get("id");
  const theme = useTheme();
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState({
    disabled: false,
    loading: false,
    condition: false,
  });
  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const validationSchema = Yup.object({
    new_password: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
      ),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: async (values, form) => {
      try {
        setButtonLoading({ disabled: true, loading: true });
        let payload = {
          password: values.confirm_password,
        };
        const data = await ResetPasswordCall(getId, payload);
        setSnackbarState({
          open: true,
          severity: "success",
          message: data.message,
        });

        setButtonLoading({ disabled: false, loading: false, condition: true });
      } catch (error) {
        setButtonLoading({ disabled: false, loading: false });
        setSnackbarState({
          open: true,
          severity: "error",
          message:
            error?.data?.error || error?.message || "Something went wrong",
        });
      }
    },
  });

  const handleShowPasswordToggle = (name) =>
    setShowPassword((prev) => ({ ...prev, [name]: !prev[name] }));

  return (
    <CustomDiv width="100%">
      {!buttonLoading.condition ? (
        <form onSubmit={formik.handleSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
            gap="1.2rem"
            sx={{
              [theme.breakpoints.down("sm")]: {
                padding: "15px",
              },
              [theme.breakpoints.up("sm")]: {
                padding: "40px 40px 20px 40px",
              },
            }}
          >
            <TalairoLogo height="40px" />

            <Typography
              textAlign="center"
              fontWeight={700}
              fontSize="30px"
              color="#223548"
            >
              Reset Your Password
            </Typography>
            <Typography textAlign="center" color="#5B738B">
              Strong passwords include numbers, letters, and punctuation marks.
            </Typography>

            <CommonGrid2
              display={"flex"}
              flexDirection={"column"}
              gap={"0.4rem"}
              marginTop={"1rem"}
            >
              <Typography
                color={
                  formik.touched["new_password"] &&
                  formik.errors["new_password"]
                    ? "#d32f2f"
                    : "#5B738B"
                }
                fontWeight={600}
              >
                Enter Password
              </Typography>
              <CommonTextField
                placeholder={"Enter password"}
                padding={"10px"}
                backgroundColor={"#EBF1F4"}
                placeholderFontSize={"14px"}
                border={!Boolean(formik.errors["new_password"]) && "none"}
                borderRadius={"5px"}
                error={
                  formik.touched["new_password"] &&
                  Boolean(formik.errors["new_password"])
                }
                value={formik.values["new_password"]}
                helperText={
                  formik.touched["new_password"] &&
                  formik.errors["new_password"]
                }
                onChange={(e) =>
                  formik.setFieldValue("new_password", e.target.value)
                }
                color={"#8c8d8e"}
                type={showPassword.old ? "text" : "password"}
                CustomIcon={
                  <IconButton
                    onClick={() => handleShowPasswordToggle("old")}
                    edge="end"
                  >
                    {showPassword.old ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                }
              />
            </CommonGrid2>
            <CommonGrid2
              display={"flex"}
              flexDirection={"column"}
              gap={"0.4rem"}
              marginTop={"0rem"}
            >
              <Typography
                color={
                  formik.touched["confirm_password"] &&
                  formik.errors["confirm_password"]
                    ? "#d32f2f"
                    : "#5B738B"
                }
                fontWeight={600}
              >
                Confirm Password
              </Typography>
              <CommonTextField
                placeholder={"Confirm password"}
                padding={"10px"}
                backgroundColor={"#EBF1F4"}
                placeholderFontSize={"14px"}
                border={!Boolean(formik.errors["confirm_password"]) && "none"}
                borderRadius={"5px"}
                error={
                  formik.touched["confirm_password"] &&
                  Boolean(formik.errors["confirm_password"])
                }
                value={formik.values["confirm_password"]}
                helperText={
                  formik.touched["confirm_password"] &&
                  formik.errors["confirm_password"]
                }
                onChange={(e) =>
                  formik.setFieldValue("confirm_password", e.target.value)
                }
                color={"#8c8d8e"}
                type={showPassword.new ? "text" : "password"}
                CustomIcon={
                  <IconButton
                    onClick={() => handleShowPasswordToggle("new")}
                    edge="end"
                  >
                    {showPassword.new ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                }
              />
            </CommonGrid2>
            <CommonButton
              marginTop={"0.5rem"}
              type="submit"
              value="Continue"
              padding="10px 16px"
              borderRadius="6px"
              loading={buttonLoading.loading}
              disabled={buttonLoading.disabled}
            />
          </Box>
        </form>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          padding="40px"
          gap="2rem"
        >
          <TalairoLogo height="40px" />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
            alignItems={"center"}
            gap={"1rem"}
          >
            <PopupTickIco />
            <Typography variant="h4" fontWeight={600}>
              Submission Successful !
            </Typography>
          </Box>
        </Box>
      )}
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </CustomDiv>
  );
}
