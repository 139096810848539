import { Grid2 } from "@mui/material";
import React, { useEffect } from "react";
import CommonTextField from "../../../component/Input";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import { useFormikContext } from "formik";
import { HandleSkeletion } from "../../../utils/constants";

function References({ data, loading }) {
  const { values, errors, setFieldValue, handleBlur } = useFormikContext();

  const [localValue, setLocalValue] = React.useState({
    reference_name: values.reference_name || "",
    reference_phone: values.reference_phone || "",
    interview_details: values.interview_details || "",
  });

  useEffect(() => {
    setLocalValue({
      reference_name: values.reference_name || "",
      reference_phone: values.reference_phone || "",
      interview_details: values.interview_details || "",
    });
  }, [values]);

  const debounceTimer = React.useRef(null);

  const handleInputChange = (e, item) => {
    setLocalValue((prev) => ({
      ...prev,
      [item.titleName]: e.target.value,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setFieldValue(item.titleName, e.target.value);
    }, 500);
  };

  return (
    <CommonGrid2
      container
      spacing={2}
      gap={loading ? "0px" : "1rem"}
      display={"flex"}
      justifyContent={"space-between"}
    >
      {data.map((item, index) => {
        return loading ? (
          <Grid2 size={data.length - 1 === index ? 12 : 6}>
            <HandleSkeletion height={100} />
          </Grid2>
        ) : (
          <CommonGrid2
            width={data.length - 1 === index ? "100%" : "auto"}
            xs={12}
            flex={data.length - 1 !== index && 1}
          >
            <Grid2
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              width={data.length - 1 === index ? "100%" : "auto"}
              border={"0.1px solid #D0D5DD"}
              borderRadius={"8px"}
            >
              <CommonTextField
                flex={0.3}
                disabled={true}
                border={"none"}
                value={item.title}
                padding={"12px"}
                borderRadius={"8px 0 0 8px"}
                customStyle={{
                  "& fieldset": {
                    border: "none",
                  },
                  borderRight: "1px solid #D0D5DD",
                }}
              />

              <CommonTextField
                flex={1}
                value={localValue[item.titleName]}
                onChange={(e) => handleInputChange(e, item)}
                border={"none"}
                // border={"0.1px solid #D0D5DD"}
                disabled={item.disabled}
                placeholder={item.placeholder}
                placeholderFontSize={item.placeholderFontSize}
                padding={"12px"}
                borderRadius={"0 8px 8px 0"}
                fontSize={"15px"}
              />
            </Grid2>
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
}

export default References;
