function CalenderIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 6.66732H2M10.6667 1.33398V4.00065M5.33333 1.33398V4.00065M5.2 14.6673H10.8C11.9201 14.6673 12.4802 14.6673 12.908 14.4493C13.2843 14.2576 13.5903 13.9516 13.782 13.5753C14 13.1475 14 12.5874 14 11.4673V5.86732C14 4.74721 14 4.18716 13.782 3.75934C13.5903 3.38301 13.2843 3.07705 12.908 2.8853C12.4802 2.66732 11.9201 2.66732 10.8 2.66732H5.2C4.0799 2.66732 3.51984 2.66732 3.09202 2.8853C2.71569 3.07705 2.40973 3.38301 2.21799 3.75934C2 4.18716 2 4.74721 2 5.86732V11.4673C2 12.5874 2 13.1475 2.21799 13.5753C2.40973 13.9516 2.71569 14.2576 3.09202 14.4493C3.51984 14.6673 4.0799 14.6673 5.2 14.6673Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CalenderIco;
