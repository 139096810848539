import {
  AccordionDetails,
  AccordionSummary,
  Grid2,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumbs from "../../../component/Breadcrumbs";
import CommonButton from "../../../component/Button";
import SnackBar from "../../../component/SnackBar";
import {
  ClientAddCall,
  ClientGetCall,
  ClientUpdateCall,
  CompanyType,
} from "../../../redux/action/ClientPartner";
import { JobIndustryList } from "../../../redux/action/Job";
import { CustomDiv } from "../../chat-bot/style";
import {
  CommonGrid2,
  CustomAccordion,
} from "../../common-components/StyledComponents";
import MixedComponent from "../mixed-component";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import parsePhoneNumber from "libphonenumber-js";

export default function AddClient() {
  const [buttonLoading, setButtonLoading] = useState({
    disabled: false,
    cancelButton: false,
    addButton: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const getId = query.get("id");
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [clientData, setClientData] = useState({
    client_name: "",
    address: "",
    email: "",
    industry: "",
    industry_data: [],
    company_type: "",
    type_data: [],
    countries_operating: [],
    hub_cities: [],
    primary_time_zone: "",
    vms: "",
    countries_operating_value: "",
    hub_cities_value: "",
    time_zone_data: [
      { name: "Eastern Standard Time(EST)" },
      { name: "Central Standard Time(CST)" },
      { name: "Mountain Standard Time(MST)" },
      { name: "Pacific Standard Time(PST)" },
      { name: "Indian Standard Time(IST)" },
      { name: "Gulf Standard Time(GST)" },
      { name: "Greenwich Standard Time(GST)" },
    ],

    contact_number: "",
    contact_number_country: "",
    business_display_name: "",
    website: "",
    sourcing_contact: "",
    federal_id: "",
    client_id: "",
    primary_business_unit: "",
    record_status: "",
    record_status_data: [{ name: "Active" }, { name: "Inactive" }],
    currency_status: "",
    currency_status_data: [
      { name: "USD - United States", id: "USD - United States" },
      { name: "INR", id: "INR" },
    ],

    net_terms: "",
    net_terms_data: [{ name: "30 days", id: "30 days" }],
    job_termination_notice: "",
    job_termination_notice_data: [{ name: "2 weeks", id: "2 weeks" }],
    client_category: "",
    client_category_data: [
      { name: "Direct", id: "Direct" },
      { name: "Indirect", id: "Indirect" },
    ],

    permanent_net_terms: "",
    permanent_net_terms_data: [{ name: "45 days", id: "45 days" }],
    commission_type: "",
    commission_type_data: [
      { name: "Fixed Percentage", id: "Fixed Percentage" },
    ],

    representative_name: "",
    job_title: "",
    phone_number: "",
    phone_number_country: "",
    email_address: "",

    hiring_manager_name: "",
    hiring_job_title: "",
    hiring_phone_number: "",
    hiring_phone_number_country: "",
    hiring_email_address: "",

    finance_team_name: "",
    account_job_title: "",
    account_phone_number: "",
    account_phone_number_country: "",
    account_email_address: "",

    entry_duration: "",
    entry_duration_data: [{ name: "Hourly", id: "Hourly" }],
    time_entry_system: "",
    time_entry_system_data: [{ name: "Online Portal", id: "Online Portal" }],

    discount_percentage: "",
    discount_percentage_data: [{ name: "10", id: "10.00" }],

    weights_group: "Group A",
    weights_group_data: [{ name: "Group A", id: "Group A" }],
  });

  const handleFunctionCall = async () => {
    setIsLoading(true);
    setButtonLoading((prev) => ({
      ...prev,
      disabled: true,
    }));
    try {
      let data;
      const industryData = await JobIndustryList();
      const companyData = await CompanyType();
      if (getId) {
        data = await ClientGetCall(getId);
      }

      setClientData((prev) => ({
        ...prev,
        ...(getId
          ? {
              client_name: data?.data?.client_name,
              address: data?.data?.address,
              email: data?.data?.email,
              industry: data?.data?.industry,
              company_type: data?.data?.company_type,
              countries_operating:
                data?.data?.countries_operating?.length === 0
                  ? []
                  : data?.data?.countries_operating?.map((item) => ({
                      content: item,
                    })),
              hub_cities:
                data?.data?.hub_cities.length === 0
                  ? []
                  : data?.data?.hub_cities?.map((item) => ({
                      content: item,
                    })),
              primary_time_zone: data?.data?.primary_time_zone,
              vms: data?.data?.vms,

              contact_number: data?.data?.contact_number,
              business_display_name: data?.data?.business_display_name,
              website: data?.data?.website,
              sourcing_contact: data?.data?.sourcing_contact,
              federal_id: data?.data?.federal_id,
              client_id: data?.data?.client_id,
              primary_business_unit: data?.data?.primary_business_unit,
              record_status: data?.data?.record_status,
              currency_status: data?.data?.currency_status,
              net_terms: data?.data?.["temporary_placement_details"]?.net_terms,
              job_termination_notice:
                data?.data?.["temporary_placement_details"]
                  ?.job_termination_notice,
              client_category:
                data.data?.["temporary_placement_details"]?.client_category,

              permanent_net_terms:
                data.data?.["permanent_placement_details"]?.net_terms,

              commission_type:
                data.data?.["permanent_placement_details"]?.commission_type,

              representative_name:
                data.data?.["contact_details"]?.["representative_details"]
                  ?.representative_name,
              job_title:
                data.data?.["contact_details"]?.["representative_details"]
                  ?.job_title,
              phone_number:
                data.data?.["contact_details"]?.["representative_details"]
                  ?.phone_number,
              email_address:
                data.data?.["contact_details"]?.["representative_details"]
                  ?.email_address,

              hiring_manager_name:
                data.data?.["contact_details"]?.["hiring_manager_details"]
                  ?.hiring_manager_name,
              hiring_job_title:
                data.data?.["contact_details"]?.["hiring_manager_details"]
                  ?.job_title,
              hiring_phone_number:
                data.data?.["contact_details"]?.["hiring_manager_details"]
                  ?.phone_number,
              hiring_email_address:
                data.data?.["contact_details"]?.["hiring_manager_details"]
                  ?.email_address,

              finance_team_name:
                data.data?.["contact_details"]?.["account_team_details"]
                  ?.finance_team_name,
              account_job_title:
                data.data?.["contact_details"]?.["account_team_details"]
                  ?.job_title,
              account_phone_number:
                data.data?.["contact_details"]?.["account_team_details"]
                  ?.phone_number,
              account_email_address:
                data.data?.["contact_details"]?.["account_team_details"]
                  ?.email_address,

              entry_duration:
                data?.data?.["time_sheet_settings"]?.entry_duration,

              time_entry_system:
                data?.data?.["time_sheet_settings"]?.time_entry_system,

              discount_percentage: data?.data?.discount_percentage?.toString(),

              weights_group: data?.data?.weights_group,
              contact_number_country: data?.data?.contact_number_country,
              phone_number_country: data?.data?.phone_number_country,
              hiring_phone_number_country:
                data?.data?.hiring_phone_number_country,
              account_phone_number_country:
                data?.data?.account_phone_number_country,
            }
          : {}),
        industry_data: industryData,
        type_data: companyData,
      }));
      setExpandedIndex([0]);
      setIsLoading(false);
      handleButtonCall();
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setIsLoading(false);
      handleButtonCall();
    }
  };
  useEffect(() => {
    handleFunctionCall();
  }, []);

  const businessInformationData = [
    {
      title: "Business Name",
      component: "input",
      titleName: "client_name",
      placeholder: "Enter Name",
      placeholderFontSize: "15px",
    },
    {
      title: "Logo",
      component: "fileUpload",
      titleName: "company_name",
      placeholder: "Upload Logo",
      placeholderFontSize: "15px",
      disabled: true,
    },
    {
      title: "Corporate Address",
      component: "input",
      titleName: "address",
      placeholder: "Enter Corporate Address",
      placeholderFontSize: "15px",
    },
    {
      title: "Industry",
      component: "dropDown",
      titleName: "industry",
      placeholder: "Select Industry",
      dropDownData: "industry_data",
      valueChange: true,
    },

    {
      title: "Company Type",
      component: "dropDown",
      titleName: "company_type",
      dropDownData: "type_data",
      placeholder: "Select Company Type",
      valueChange: true,
    },

    {
      title: "Countries Operating In",
      component: "autoComplete",
      titleName: "countries_operating",
      placeholder: "Enter Countries",
      inputValue: "countries_operating_value",
    },
    {
      title: "Hub Cities",
      component: "autoComplete",
      titleName: "hub_cities",
      placeholder: "Enter Cities",
      inputValue: "hub_cities_value",
    },
    {
      title: "Primary Time Zone",
      component: "dropDown",
      titleName: "primary_time_zone",
      dropDownData: "time_zone_data",
      placeholder: "Select Time Zone",
    },
    {
      title: "Email Address",
      component: "input",
      titleName: "email",
      placeholder: "Enter Email Address",
      placeholderFontSize: "15px",
    },
    {
      title: "Contact Number",
      component: "phone-number",
      titleName: "contact_number",
      country: "contact_number_country",
      placeholder: "Enter Contact Number",
      placeholderFontSize: "15px",
      // startIcon: "+91",
    },
    {
      title: "Business Display Name",
      component: "input",
      titleName: "business_display_name",
      placeholder: "Enter Business Display Name",
      placeholderFontSize: "15px",
    },
    {
      title: "Website",
      component: "input",
      titleName: "website",
      placeholder: "Enter Website",
      placeholderFontSize: "15px",
    },
    {
      title: "VMS",
      component: "input",
      titleName: "vms",
      placeholder: "Enter VMS",
      placeholderFontSize: "15px",
    },
    {
      title: "Sourcing Contact",
      component: "input",
      titleName: "sourcing_contact",
      placeholder: "Enter Sourcing Contact",
      placeholderFontSize: "15px",
    },

    {
      title: "Federal ID",
      component: "input",
      titleName: "federal_id",
      placeholder: "Enter Federal ID",
      placeholderFontSize: "15px",
    },
    {
      title: "Client ID",
      component: "input",
      titleName: "client_id",
      placeholder: "Enter Client ID",
      placeholderFontSize: "15px",
    },
    {
      title: "Record Status",
      component: "dropDown",
      titleName: "record_status",
      placeholder: "Select Record Status",
      dropDownData: "record_status_data",
    },
    {
      title: "Primary Business Unit",
      component: "input",
      titleName: "primary_business_unit",
      placeholder: "Enter Primary Business Unit",
      placeholderFontSize: "15px",
    },
  ];

  const currencyDetails = [
    {
      title: "Currency Status",
      component: "dropDown",
      titleName: "currency_status",
      placeholder: "Select Currency",
      dropDownData: "currency_status_data",
      valueChange: true,
    },
  ];

  const temporaryPlacementDetails = [
    {
      title: "Net Terms",
      component: "dropDown",
      titleName: "net_terms",
      placeholder: "Select Net Term",
      dropDownData: "net_terms_data",
      valueChange: true,
    },
    {
      title: "Job Termination Notice",
      component: "dropDown",
      titleName: "job_termination_notice",
      placeholder: "Select Job Termination Notice",
      dropDownData: "job_termination_notice_data",
      valueChange: true,
    },
    {
      title: "Client Category",
      component: "dropDown",
      titleName: "client_category",
      placeholder: "Select Client Category",
      dropDownData: "client_category_data",
      valueChange: true,
    },
  ];

  const permanentPlacementDetails = [
    {
      title: "Net Terms",
      component: "dropDown",
      titleName: "permanent_net_terms",
      placeholder: "Select Net Term",
      dropDownData: "net_terms_data",
      valueChange: true,
    },
    {
      title: "Commission Type",
      component: "input",
      titleName: "commission_type",
      placeholder: "Enter Commission Type",
      placeholderFontSize: "15px",
      // dropDownData: "commission_type_data",
      // valueChange: true,
    },
  ];

  const contactDetails = [
    {
      title: "Back Office Coordinator’s Name",
      component: "input",
      titleName: "representative_name",
      placeholder: "Enter Name",
      placeholderFontSize: "15px",
    },
    {
      title: "Job Title",
      component: "input",
      titleName: "job_title",
      placeholder: "Enter Job Title",
      placeholderFontSize: "15px",
    },
    {
      title: "Phone Number",
      component: "phone-number",
      titleName: "phone_number",
      placeholder: "Enter Phone Number",
      country: "phone_number_country",
      placeholderFontSize: "15px",
      startIcon: "+91",
    },
    {
      title: "Email Address",
      component: "input",
      titleName: "email_address",
      placeholder: "Enter Email Address",
      placeholderFontSize: "15px",
    },
  ];

  const hiringManagerDetails = [
    {
      title: "Hiring Manager Name",
      component: "input",
      titleName: "hiring_manager_name",
      placeholder: "Enter Name",
      placeholderFontSize: "15px",
    },
    {
      title: "Job Title",
      component: "input",
      titleName: "hiring_job_title",
      placeholder: "Enter Job Title",
      placeholderFontSize: "15px",
    },
    {
      title: "Phone Number",
      component: "phone-number",
      titleName: "hiring_phone_number",
      placeholder: "Enter Phone Number",
      placeholderFontSize: "15px",
      country: "hiring_phone_number_country",
      startIcon: "+91",
    },
    {
      title: "Email Address",
      component: "input",
      titleName: "hiring_email_address",
      placeholder: "Enter Email Address",
      placeholderFontSize: "15px",
    },
  ];

  const accountTeamDetails = [
    {
      title: "Invoicing/Time Sheet Coordinator’s name",
      component: "input",
      titleName: "finance_team_name",
      placeholder: "Enter Name",
      placeholderFontSize: "15px",
    },
    {
      title: "Job Title",
      component: "input",
      titleName: "account_job_title",
      placeholder: "Enter Job Title",
      placeholderFontSize: "15px",
    },
    {
      title: "Phone Number",
      component: "phone-number",
      titleName: "account_phone_number",
      placeholder: "Enter Phone Number",
      placeholderFontSize: "15px",
      country: "account_phone_number_country",
      startIcon: "+91",
    },
    {
      title: "Email Address",
      component: "input",
      titleName: "account_email_address",
      placeholder: "Enter Email Address",
      placeholderFontSize: "15px",
    },
  ];

  const timeSheetSetting = [
    {
      title: "Entry Duration",
      component: "dropDown",
      titleName: "entry_duration",
      placeholder: "Select Duration",
      dropDownData: "entry_duration_data",
      valueChange: true,
    },
    {
      title: "Time Entry System",
      component: "dropDown",
      titleName: "time_entry_system",
      placeholder: "Select Time Entry",
      dropDownData: "time_entry_system_data",
      valueChange: true,
    },
  ];

  const discount = [
    {
      title: "Discount Percentage",
      component: "dropDown",
      titleName: "discount_percentage",
      placeholder: "Select Discount",
      dropDownData: "discount_percentage_data",
      valueChange: true,
    },
  ];

  const weightage = [
    {
      title: "Weight Group",
      component: "dropDown",
      titleName: "weights_group",
      placeholder: "Select Weight",
      dropDownData: "weights_group_data",
      valueChange: true,
      disabled: true,
    },
  ];
  const breadCrumbData = [
    // {
    //   title: "Partners",
    //   navigate: "/client-partner?type=Clients",
    // },
    {
      title: "Clients",
      navigate: "/client-vendor?type=Clients",
    },
    {
      title: "Add Client",
      active: true,
    },
  ];

  const handleSubmitClient = async (values, actions) => {
    // let payload = {
    //   ...values,
    //   countries_operating: countries_operating?.countries?.map(
    //     (item) => item.content
    //   ),
    //   hub_cities: values?.hub_cities?.map((item) => item.content),
    //   temporary_placement_details: {
    //     net_terms: values.net_terms,
    //     job_termination_notice: values.job_termination_notice,
    //     client_category: values.client_category,
    //   },
    //   permanent_placement_details: {
    //     net_terms: values.permanent_net_terms,
    //     commission_type: values.commission_type,
    //   },
    //   contact_details: {
    //     representative_details: {
    //       representative_name: values.representative_name,
    //       job_title: values.job_title,
    //       phone_number: values.phone_number,
    //       email_address: values.email_address,
    //     },
    //     hiring_manager_details: {
    //       hiring_manager_name: values.hiring_manager_name,
    //       job_title: values.hiring_job_title,
    //       phone_number: values.hiring_phone_number,
    //       email_address: values.hiring_email_address,
    //     },
    //     account_team_details: {
    //       finance_team_name: values.finance_team_name,
    //       job_title: values.account_job_title,
    //       phone_number: values.account_phone_number,
    //       email_address: values.account_email_address,
    //     },
    //   },
    //   time_sheet_settings: {
    //     entry_duration: values.entry_duration,
    //     time_entry_system: values.time_entry_system,
    //   },
    // };
    try {
      setButtonLoading((prev) => ({
        ...prev,
        disabled: true,
        addButton: true,
      }));
      let payload = {
        ...values,
        countries_operating: values?.countries_operating?.map(
          (item) => item.content
        ),
        hub_cities: values?.hub_cities?.map((item) => item.content),
        temporary_placement_details: {
          net_terms: values.net_terms,
          job_termination_notice: values.job_termination_notice,
          client_category: values.client_category,
        },
        permanent_placement_details: {
          net_terms: values.permanent_net_terms,
          commission_type: values.commission_type,
        },
        contact_details: {
          representative_details: {
            representative_name: values.representative_name,
            job_title: values.job_title,
            phone_number: values.phone_number,
            email_address: values.email_address,
          },
          hiring_manager_details: {
            hiring_manager_name: values.hiring_manager_name,
            job_title: values.hiring_job_title,
            phone_number: values.hiring_phone_number,
            email_address: values.hiring_email_address,
          },
          account_team_details: {
            finance_team_name: values.finance_team_name,
            job_title: values.account_job_title,
            phone_number: values.account_phone_number,
            email_address: values.account_email_address,
          },
        },
        time_sheet_settings: {
          entry_duration: values.entry_duration,
          time_entry_system: values.time_entry_system,
        },
      };
      const data = getId
        ? await ClientUpdateCall(getId, payload)
        : await dispatch(ClientAddCall(payload));
      setSnackbarState({
        open: true,
        severity: "success",
        message: data?.message,
      });
      setTimeout(() => {
        handleButtonCall();
        navigate("/client-vendor?type=Clients");
      }, 500);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      handleButtonCall();
    }
  };

  const validationSchema = Yup.object({
    client_name: Yup.string().required("Client Name is required"),
    address: Yup.string().required("Address is required"),
    // email: Yup.string()
    //   .required("Email is required")
    //   .matches(
    //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    //     "Invalid email address"
    //   ),
    industry: Yup.string().required("Industry level is required"),
    company_type: Yup.string().required("Type is required"),
    primary_time_zone: Yup.string().required("TimeZone is required"),
    // vms: Yup.string().required("VMS is required"),
    // contact_number: Yup.string()
    //   .matches(/^(?!0+$)\d{10}$/, "Invalid contact number")
    //   .required("Contact is required"),

    countries_operating: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          content: Yup.string().required("Country name is required"),
        })
      )
      .min(1, "At least one country is required")
      .test(
        "unique-content",
        "Countries List must be unique",
        function (value) {
          if (!value || value.length === 0) return false;
          const contentSet = new Set();
          for (let location of value) {
            if (location.content) {
              const lowercasedContent = location.content.toLowerCase();
              if (contentSet.has(lowercasedContent)) {
                return false;
              }
              contentSet.add(lowercasedContent);
            }
          }
          return true;
        }
      ),

    hub_cities: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          content: Yup.string().required("Cities name is required"),
        })
      )
      .min(1, "At least one cities is required")
      .test("unique-content", "Cities List must be unique", function (value) {
        if (!value || value.length === 0) return false;
        const contentSet = new Set();
        for (let location of value) {
          if (location.content) {
            const lowercasedContent = location.content.toLowerCase();
            if (contentSet.has(lowercasedContent)) {
              return false;
            }
            contentSet.add(lowercasedContent);
          }
        }
        return true;
      }),

    // business_display_name: Yup.string().required("Business Name is required"),
    // website: Yup.string()
    //   .required("Website is required")
    //   .matches(
    //     /^(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    //     "Enter a valid URL (e.g., https://example.com)"
    //   ),
    website: Yup.string()
      .required("Website is required")
      .test(
        "is-valid-website",
        "Enter a valid website (e.g., www.example.com or https://example.com)",
        (value) => {
          if (!value) return false;
          const wwwPattern =
            /^www\.[a-zA-Z0-9-]{1,256}\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?$/;
          const httpsPattern =
            /^(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

          return wwwPattern.test(value) || httpsPattern.test(value);
        }
      ),
    sourcing_contact: Yup.string().required("Contact is required"),

    // client_id: Yup.string().required("Client Id is required"),
    primary_business_unit: Yup.string().required("Business Unit is required"),
    record_status: Yup.string().required("Status is required"),

    currency_status: Yup.string().required("Currency is required"),

    net_terms: Yup.string().required("Net Terms is required"),
    job_termination_notice: Yup.string().required("Notice is required"),
    client_category: Yup.string().required("Client Category is required"),

    permanent_net_terms: Yup.string().required("Net Terms is required"),
    // commission_type: Yup.string().required("Type is required"),

    representative_name: Yup.string().required("Name is required"),
    job_title: Yup.string().required("Title is required"),
    // phone_number: Yup.string()
    //   .matches(/^(?!0+$)\d{10}$/, "Invalid Phone number")
    //   .required("Phone Number is required"),

    phone_number: Yup.string()
      .required("Phone number is required")
      .test(
        "is-valid-phone",
        "Phone number is not valid for the selected country",
        function (value) {
          const { phone_number_country } = this.parent;
          if (!value || !phone_number_country?.countryCode) return false;
          const formattedValue = value.startsWith("+") ? value : `+${value}`;
          const phoneNumber = parsePhoneNumber(
            formattedValue,
            phone_number_country.countryCode
          );
          return phoneNumber?.isValid();
        }
      ),
    email_address: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),

    hiring_manager_name: Yup.string().required("Name is required"),
    hiring_job_title: Yup.string().required("Title is required"),

    hiring_phone_number: Yup.string()
      .required("Phone number is required")
      .test(
        "is-valid-phone",
        "Phone number is not valid for the selected country",
        function (value) {
          const { hiring_phone_number_country } = this.parent;
          if (!value || !hiring_phone_number_country?.countryCode) return false;
          const formattedValue = value.startsWith("+") ? value : `+${value}`;
          const phoneNumber = parsePhoneNumber(
            formattedValue,
            hiring_phone_number_country.countryCode
          );
          return phoneNumber?.isValid();
        }
      ),
    hiring_email_address: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),

    finance_team_name: Yup.string().required("Name is required"),
    account_job_title: Yup.string().required("Title is required"),

    account_phone_number: Yup.string()
      .required("Phone number is required")
      .test(
        "is-valid-phone",
        "Phone number is not valid for the selected country",
        function (value) {
          const { account_phone_number_country } = this.parent;
          if (!value || !account_phone_number_country?.countryCode)
            return false;
          const formattedValue = value.startsWith("+") ? value : `+${value}`;
          const phoneNumber = parsePhoneNumber(
            formattedValue,
            account_phone_number_country.countryCode
          );
          return phoneNumber?.isValid();
        }
      ),
    account_email_address: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),

    entry_duration: Yup.string().required("Duration is required"),
    time_entry_system: Yup.string().required("Time is required"),

    discount_percentage: Yup.string().required("Discount is required"),

    weights_group: Yup.string().required("Weight is required"),
  });

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleScrollToError = (data) => {
    setExpandedIndex((prev) => {
      const newExpandedIndex =
        data.client_name ||
        data.address ||
        data.email ||
        data.industry ||
        data.company_type ||
        data.primary_time_zone ||
        data.vms ||
        data.countries_operating ||
        data.hub_cities ||
        data.record_status ||
        data.contact_number ||
        data.business_display_name ||
        data.website ||
        data.sourcing_contact ||
        data.federal_id ||
        data.client_id ||
        data.primary_business_unit
          ? 0
          : data?.currency_status
          ? 1
          : data?.net_terms ||
            data?.job_termination_notice ||
            data?.client_category
          ? 2
          : data?.permanent_net_terms || data?.commission_type
          ? 3
          : data?.entry_duration || data?.time_entry_system
          ? 5
          : data?.discount_percentage
          ? 6
          : data?.weights_group
          ? 7
          : 4;

      return newExpandedIndex !== null && !prev.includes(newExpandedIndex)
        ? [...prev, newExpandedIndex]
        : prev;
    });

    setTimeout(() => {
      const errorElement = document.querySelector(".Mui-error");
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 300);
  };

  const handleButtonCall = () => {
    setButtonLoading({
      addButton: false,
      disabled: false,
      cancelButton: false,
    });
  };

  const handleCancelButton = () => {
    setButtonLoading((prev) => ({
      ...prev,
      disabled: true,
      cancelButton: true,
    }));
    setTimeout(() => {
      handleButtonCall();
      navigate("/client-vendor?type=Clients");
    }, 1000);
  };

  const accordionDataView = [
    {
      title: "Business Information",
      component: (
        <MixedComponent
          editData={businessInformationData}
          rowSize
          loading={isLoading}
        />
      ),
    },
    {
      title: "Currency Details",
      component: (
        <MixedComponent
          editData={currencyDetails}
          rowSize
          loading={isLoading}
        />
      ),
    },
    {
      title: "Temporary Placement Details",
      component: (
        <MixedComponent
          editData={temporaryPlacementDetails}
          rowSize
          loading={isLoading}
        />
      ),
    },
    {
      title: "Permanent Placement Details",
      component: (
        <MixedComponent
          editData={permanentPlacementDetails}
          rowSize
          loading={isLoading}
        />
      ),
    },
    {
      title: "Contact Details",
      component: (
        <CustomDiv>
          <Typography variant="h5" fontWeight={600} margin={"-1rem 0px 1rem"}>
            Representative Details
          </Typography>
          <MixedComponent
            editData={contactDetails}
            rowSize
            loading={isLoading}
          />
          <Typography variant="h5" fontWeight={600} margin={"1.5rem 0px 1rem"}>
            Hiring Manager Details
          </Typography>
          <MixedComponent
            editData={hiringManagerDetails}
            rowSize
            loading={isLoading}
          />
          <Typography variant="h5" fontWeight={600} margin={"1.5rem 0px 1rem"}>
            Accounts team Details
          </Typography>
          <MixedComponent
            editData={accountTeamDetails}
            rowSize
            loading={isLoading}
          />
        </CustomDiv>
      ),
    },
    {
      title: "Timesheet Settings",
      component: (
        <MixedComponent
          editData={timeSheetSetting}
          rowSize
          loading={isLoading}
        />
      ),
    },
    {
      title: "Discounts",
      component: (
        <MixedComponent editData={discount} rowSize loading={isLoading} />
      ),
    },
    {
      title: "Weightage",
      component: (
        <MixedComponent editData={weightage} rowSize loading={isLoading} />
      ),
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(
    accordionDataView.map((_, i) => i)
  );

  return (
    <CustomDiv>
      <Formik
        enableReinitialize={true}
        initialValues={clientData}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          handleSubmitClient(values, actions);
        }}
      >
        {({ validateForm, values, errors }) => {
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Grid2>
                <Breadcrumbs breadCrumbData={breadCrumbData} />
                <Grid2
                  container
                  spacing={2}
                  marginTop={"2rem"}
                  marginBottom={"2rem"}
                >
                  <CommonGrid2
                    size={{ xs: 12, md: 4, sm: 12 }}
                    item
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <Typography variant="h3">
                      {getId ? "Edit Client" : "Add Client"}
                    </Typography>
                  </CommonGrid2>
                  <CommonGrid2
                    item
                    size={{ xs: 12, md: 8, sm: 12 }}
                    gap="10px"
                    display="flex"
                    justifyContent={"end"}
                  >
                    <CommonButton
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      fontWeight="600"
                      value="Cancel"
                      padding="8px 20px"
                      disabled={buttonLoading.disabled}
                      loading={buttonLoading.cancelButton}
                      onClick={() => handleCancelButton()}
                    />
                    <CommonButton
                      variant="contained"
                      color="white"
                      background="#7F56D9"
                      fontWeight="600"
                      value={getId ? "Update" : "Add"}
                      padding="8px 20px"
                      type="submit"
                      disabled={buttonLoading.disabled}
                      loading={buttonLoading.addButton}
                      onClick={() => {
                        setTimeout(async () => {
                          let data = await validateForm();
                          handleScrollToError(data);
                        }, 0);
                      }}
                    />
                  </CommonGrid2>
                  <CommonGrid2 size={12}>
                    {accordionDataView.map((item, index) => {
                      return (
                        <CustomAccordion
                          key={index}
                          padding="0px"
                          expanded={expandedIndex.includes(index)}
                          onChange={() =>
                            setExpandedIndex((prev) =>
                              prev.includes(index)
                                ? prev.filter((i) => i !== index)
                                : [...prev, index]
                            )
                          }
                        >
                          <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                          >
                            <Typography
                              variant="h4"
                              margin={"15px 0px"}
                              fontWeight={600}
                            >
                              {item.title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>{item.component}</AccordionDetails>
                        </CustomAccordion>
                      );
                    })}
                  </CommonGrid2>
                </Grid2>
              </Grid2>
            </Form>
          );
        }}
      </Formik>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </CustomDiv>
  );
}
