import CloseIcon from "@mui/icons-material/Close";
import {
    Autocomplete,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    TextField,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ChevronDownIco from "../../../../assets/icons/ChevronDownIco";
import ExperienceIcon from "../../../../assets/images/experienceIcon.png";
import CommonTextField from "../../../../component/Input";
import { StyledDialog, StyledFlexColumnView } from "../../../common-components/StyledComponents";
import DateRangePickerComp from "../../../jobs/common-card.js/DatePicker";

const AddExperience = ({
    open,
    onClose,
    onApply,
    experience
}) => {
    const [username, setUsername] = useState("");
    const [position, setPosition] = useState("");
    const [permissions, setPermissions] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [fileName, setFileName] = useState("");

    // Populate fields if editing
    useEffect(() => {
        if (experience) {
            setUsername(experience.username || "");
            setPosition(experience.position || "");
            setPermissions(experience.permissions || null);
            setStartDate(experience.startDate || null);
            setEndDate(experience.endDate || null);
            setFileName(experience.fileName || "");
        } else {
            // Reset fields for adding
            setUsername("");
            setPosition("");
            setPermissions(null);
            setStartDate(null);
            setEndDate(null);
            setFileName("");
        }
    }, [experience]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) setFileName(file.name);
    };

    const isFormValid = () => {
        return username.trim() !== "" && position.trim() !== "" && permissions !== null;
    };

    const handleSave = () => {
        const payload = {
            username,
            position,
            permissions,
            startDate,
            endDate,
            fileName,
            id: experience?.id || null, // Include ID for editing
        };
        onApply(payload); // Call parent handler
    };

    return (
        <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
            <img
                src={ExperienceIcon}
                alt="Experience"
                style={{ position: "absolute", padding: "16px", width: "90px" }}
            />
            <DialogActions>
                <Button onClick={onClose}>
                    <CloseIcon />
                </Button>
            </DialogActions>
            <DialogTitle>
                <Typography variant="h3" sx={{ mt: 2 }}>
                    {experience ? "Edit Experience" : "Add Experience"}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid2 container direction="column" spacing={3}>
                    <Grid2 size={12}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "#344054",
                                    marginBottom: "8px",
                                }}
                            >
                                {"Position Title"}
                            </Typography>
                            <Autocomplete
                                onChange={(e, value) => setPermissions(value)}
                                disablePortal
                                options={[]}
                                value={permissions}
                                sx={{
                                    "& .MuiAutocomplete-inputRoot": {
                                        padding: "5px",
                                        border: "1px solid #D0D5DD",
                                        alignItems: "center",
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        placeholder="Select Title"
                                        variant="outlined"
                                    />

                                )}
                                popupIcon={<ChevronDownIco />}
                            />
                        </div>
                    </Grid2>
                    <Grid2 size={12}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Company Name"}
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={position}
                                placeholder="Enter Company Name"
                                onChange={(e) => setPosition(e.target.value)}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #D0D5DD",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "12px",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#667085",
                                        opacity: 1,
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    },
                                }}
                            />
                        </StyledFlexColumnView>
                    </Grid2>
                    <Grid2 size={12}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Company Avatar"}
                            </Typography>
                            <CommonTextField
                                readOnly={true}
                                handleFileChange={handleFileChange}
                                value={fileName}
                                padding="10px"
                            />
                        </StyledFlexColumnView>
                    </Grid2>
                    <Grid2 size={12}>
                        <StyledFlexColumnView>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                            >
                                {"Date"}
                            </Typography>
                            <DateRangePickerComp
                                startDateProp={startDate}
                                endDateProp={endDate}
                                handleChange={(start, end) => {
                                    setStartDate(start);
                                    setEndDate(end);
                                }}
                            />
                        </StyledFlexColumnView>
                    </Grid2>

                    <Button
                        variant={isFormValid() ? "contained" : "outlined"}
                        color="primary"
                        fontWeight="600"
                        disabled={!isFormValid()}
                        onClick={handleSave}
                        sx={{
                            padding: "8px",
                            ...(isFormValid() && {
                                backgroundColor: "rgb(127, 86, 217)",
                                color: "#fff",
                            }),
                            "&:hover": isFormValid()
                                ? {
                                    backgroundColor: "rgb(127, 86, 217)",
                                    color: "#fff",

                                }
                                : {},
                        }}
                    >
                        {experience ? "Save Changes" : "Add Experience"}
                    </Button>

                </Grid2>
            </DialogContent >
        </StyledDialog >
    );
};

export default AddExperience;
