import { RESUMEINTELLIGENCE } from "../Action";

const intialState = {
  resumeIntelligenceUpload: {},
  resumeIntelligenceSave: {},
};
const ResumeIntelligenceReducer = (state = intialState, action) => {
  switch (action.type) {
    case RESUMEINTELLIGENCE.resumeIntelligenceUpload:
      return { ...state, resumeIntelligenceUpload: action.data };
    case RESUMEINTELLIGENCE.resumeIntelligenceSave:
      return { ...state, resumeIntelligenceSave: action.data };
    default:
      return state;
  }
};
export default ResumeIntelligenceReducer;
