import CloseIcon from "@mui/icons-material/Close";
import {
    Autocomplete,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import ChevronDownIco from "../../../assets/icons/ChevronDownIco";
import Featuredicon from "../../../assets/images/featuredicon.png";
import { StyledDialog, StyledFlexColumnView } from "../../common-components/StyledComponents";

const AddMember = ({
    open,
    onClose,
    onApply
}) => {
    const [username, setUsername] = useState("");
    const [position, setPosition] = useState("");
    const [permissions, setPermissions] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePositionChange = (event) => {
        setPosition(event.target.value);
    };

    const handlePermissionsChange = (event, value) => {
        setPermissions(value);
    };

    const isFormValid = () => {
        return username.trim() !== "" && position.trim() !== "" && permissions !== null;
    };

    const handleAddClick = () => {
        if (isFormValid()) {
            setShowConfirmation(true);
        }
    };

    const handleConfirmAdd = () => {
        onApply(username, position, permissions);
        setShowConfirmation(false);
    };
    const handleGoBack = () => {
        setShowConfirmation(false);
    };

    return (
        <>
            <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose} >
                <img
                    src={Featuredicon}
                    style={{ position: "absolute", padding: "8px", width: "80px" }}
                />
                <DialogActions>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </DialogActions>
                <DialogTitle>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                        {"Add Team Member"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid2 container direction="column" spacing={3}>
                        <Grid2 size={12}>
                            <StyledFlexColumnView>
                                <Typography
                                    sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                                >
                                    {"Email Address"}
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={username}
                                    placeholder="Enter Email Address"
                                    onChange={handleUsernameChange}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            backgroundColor: "#FFFFFF",
                                            border: "1px solid #D0D5DD",
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "12px",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            color: "#667085",
                                            opacity: 1,
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        },
                                    }}
                                />
                            </StyledFlexColumnView>
                        </Grid2>
                        <Grid2 size={12}>
                            <StyledFlexColumnView>
                                <Typography
                                    sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                                >
                                    {"Position"}
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={position}
                                    placeholder="Enter Position Title"
                                    onChange={handlePositionChange}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            backgroundColor: "#FFFFFF",
                                            border: "1px solid #D0D5DD",
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "12px",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            color: "#667085",
                                            opacity: 1,
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        },
                                    }}
                                />
                            </StyledFlexColumnView>
                        </Grid2>
                        <Grid2 size={12}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "#344054",
                                        marginBottom: "8px",
                                    }}
                                >
                                    {"Permissions"}
                                </Typography>
                                <Autocomplete
                                    disablePortal
                                    options={["Admin"]}
                                    value={permissions}
                                    onChange={handlePermissionsChange}
                                    sx={{
                                        "& .MuiAutocomplete-inputRoot": {
                                            padding: "5px",
                                            border: "1px solid #D0D5DD",
                                            alignItems: "center",
                                            borderRadius: "8px",
                                            backgroundColor: "#FFFFFF",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            color: "#667085",
                                            opacity: 1,
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        },
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Select Permission"
                                            variant="outlined"
                                        />
                                    )}
                                    popupIcon={<ChevronDownIco />}
                                />
                            </div>
                        </Grid2>
                        <Button
                            variant={isFormValid() ? "contained" : "outlined"}
                            color="primary"
                            fontWeight="600"
                            onClick={handleAddClick}
                            disabled={!isFormValid()}
                            sx={{
                                padding: "8px",
                                ...(isFormValid() && {
                                    backgroundColor: "rgb(127, 86, 217)",
                                    color: "#fff",
                                }),
                                "&:hover": isFormValid()
                                    ? {
                                        backgroundColor: "rgb(127, 86, 217)",
                                        color: "#fff",

                                    }
                                    : {},
                            }}
                        >
                            {"Add Team Member"}
                        </Button>

                    </Grid2>
                </DialogContent>
            </StyledDialog >
            <StyledDialog fullWidth maxWidth="xs" open={showConfirmation} onClose={handleGoBack} >
                <img
                    src={Featuredicon}
                    style={{ position: "absolute", padding: "8px", width: "80px" }}
                />
                <DialogActions>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </DialogActions>
                <DialogTitle>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                        {"Add Team Member"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                    >
                        You are about to add a team member with the email <strong>{username}</strong> at the <strong>{position}</strong> position as <strong>{permissions}</strong>.
                    </Typography>


                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"

                        fontWeight="600"
                        padding="8px"
                        onClick={handleGoBack}

                    >{"Go Back"}</Button>
                    <Button
                        variant="contained"

                        fontWeight="600"
                        padding="8px"
                        onClick={handleConfirmAdd}

                    >{"Confirm"}</Button>

                </DialogActions>
            </StyledDialog>
        </>
    );
};

export default AddMember;
