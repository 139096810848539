import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DotsGrid from "../../../assets/icons/DotsGrid";
import CustomLinearProgress from "../../../component/Linearprogress";
import {
  ErrorFound,
  getColor,
  HandleSkeletion,
} from "../../../utils/constants";
import {
  DotColor,
  StatusChange,
  StyledFlexRowView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  StyledTypoGrey,
  TableContainerBorder,
} from "../../common-components/StyledComponents";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function RelevancyTable({
  isLoading,
  resultHeaderData,

  onDragEnd,
  dragContent,
  alignment,
}) {
  console.log(dragContent, "dragContentdragContent");
  return (
    <TableContainerBorder
      marginTop={"0px"}
      scrollStyle={true}
      height={dragContent?.length}
    >
      <Table>
        <TableHead>
          <TableRow>
            {resultHeaderData.map((item, index) => (
              <StyledTableTitleTableCell>{item}</StyledTableTitleTableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <>
            {Array.from({ length: 2 }).map((_, index) => (
              <TableRow>
                {Array.from({ length: 4 }).map((_, index) => (
                  <TableCell key={index}>
                    <HandleSkeletion height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-candidates">
              {(provided) => (
                <>
                  {dragContent && dragContent?.length !== 0 && (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {dragContent.map((candidate, index) => (
                        <Draggable
                          key={`candidate-${index}`}
                          draggableId={`candidate-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <TableCell style={{ width: "50vw" }}>
                                <Typography
                                  fontSize={"15px"}
                                  fontWeight={500}
                                  color={"#101828"}
                                  fontFamily={"Inter"}
                                >
                                  {candidate.skill}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ width: "20vw" }}>
                                <StatusChange>
                                  <DotColor
                                    color={getColor(candidate.relevancy_score)}
                                  />
                                  {candidate.status}
                                </StatusChange>
                              </TableCell>
                              <TableCell style={{ width: "20vw" }}>
                                <StyledFlexRowView
                                  sx={{ alignItems: "center" }}
                                >
                                  <CustomLinearProgress
                                    variant="determinate"
                                    value={candidate.relevancy_score}
                                    height={"10px"}
                                    borderRadius={"5px"}
                                    barbackgroundColor={getColor(
                                      candidate.relevancy_score
                                    )}
                                  />

                                  <StyledTypoGrey>{`${candidate.relevancy_score}%`}</StyledTypoGrey>
                                </StyledFlexRowView>
                              </TableCell>
                              <StyledTableBodyTableCell
                                textAlign={"center"}
                                style={{ width: "10vw" }}
                              >
                                <IconButton
                                  {...provided.dragHandleProps}
                                  disabled={alignment === "View" ? true : false}
                                  style={{
                                    padding: "0px",
                                    opacity: alignment === "View" ? 0.3 : 1,
                                  }}
                                >
                                  <DotsGrid />
                                </IconButton>
                              </StyledTableBodyTableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Table>
      {dragContent?.length === 0 && !isLoading && (
        <ErrorFound title={"No data Found..."} />
      )}
    </TableContainerBorder>
  );
}

export default RelevancyTable;
