import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import XCloseIco from "../../../../assets/icons/XCloseIco";
import CommonButton from "../../../../component/Button";
import DropDownSelect from "../../../../component/Select";
import { CustomDiv } from "../../../chat-bot/style";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import FileUpload from "../../../quick-match/components/FileUpload";

export default function AttachmentDialog({
  open,
  handleClose,
  handleSubmit,
  attachmentDialog,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const initialValues = {
    name: "",
    name_data: [
      {
        name: "Visa Copy",
      },
      { name: "EAD" },
      { name: "RTR" },
      { name: "Current Resume" },
    ],
    file: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    file: Yup.array()
      .min(1, "At least one file is required")
      .test("fileType", "Only PDF, DOC, and DOCX are allowed", (files) => {
        if (!files || files.length === 0) return false;
        const allowedTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        return files.every((file) => allowedTypes.includes(file.type));
      }),
  });

  const commonFileValidationCheck = (
    commonFiles,
    setCommonFiles,
    existingFiles
  ) => {
    const validFiles = commonFiles.map((file) => ({
      file,
      name: file.name,
      size: `${(file.size / 1024).toFixed(1)} KB`,
      type: file.type,
      visible: false,
      delete: true,
      message: "",
    }));

    const mergedFiles = [...validFiles];
    setCommonFiles("file", mergedFiles);
  };

  const handleFileUpload = (event, setFieldValue, values) => {
    const selectedFiles = Array.from(event.target.files);
    commonFileValidationCheck(selectedFiles, setFieldValue, values);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event, setFieldValue, values) => {
    event.preventDefault();
    setIsDragging(false);

    const droppedFiles = Array.from(event.dataTransfer.files);
    commonFileValidationCheck(droppedFiles, setFieldValue, values);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "10px",
        },
      }}
    >
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <CustomDiv></CustomDiv>
        <CommonButton
          onClick={handleClose}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          value={<XCloseIco />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>

      <DialogContent style={{ textAlign: "center", paddingTop: "0px" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <Form>
                <Grid2 spacing={2} container>
                  <Typography variant="h3" textAlign={"center"} width={"100%"}>
                    Attachment
                  </Typography>

                  <CommonGrid2
                    size={12}
                    textAlign={"left"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"7px"}
                  >
                    <Typography variant="p" color="#344054" fontSize="16px">
                      Name of Attachment
                    </Typography>

                    <DropDownSelect
                      marginTop="0px"
                      title={"Select the attachment"}
                      removeAnimation={true}
                      data={values["name_data"]}
                      error={Boolean(errors.name && touched.name)}
                      helperText={
                        Boolean(errors.name && touched.name) && errors.name
                      }
                      sizeMedium="-5px"
                      value={values.name}
                      handleChange={(e) => {
                        setFieldValue("name", e.target.value);
                      }}
                    />
                  </CommonGrid2>

                  <CommonGrid2 size={12} textAlign={"left"} marginTop={"1rem"}>
                    <FileUpload
                      marginBottom={"15px"}
                      isJem={true}
                      border="1px solid #D0D5DD"
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={(event) =>
                        handleDrop(event, setFieldValue, values.file)
                      }
                      onChange={(event) =>
                        handleFileUpload(event, setFieldValue, values.file)
                      }
                      files={values.file}
                      disabled={attachmentDialog.loading}
                      isDragging={isDragging}
                      handleDeleteFile={(index) => {
                        const updatedFiles = values.file.filter(
                          (_, i) => i !== index
                        );
                        setFieldValue("file", updatedFiles);
                      }}
                    />
                    {errors.file && touched.file && (
                      <Typography
                        color="error"
                        variant="body2"
                        fontSize={"12px"}
                        marginTop={"-8px"}
                      >
                        {errors.file}
                      </Typography>
                    )}
                  </CommonGrid2>

                  <Grid2 size={12} marginTop={"0.5rem"}>
                    <CommonButton
                      variant="contained"
                      color="white"
                      background="#7F56D9"
                      fontWeight="600"
                      width={"100%"}
                      padding={"10px 14px"}
                      value="Add"
                      type="submit"
                      disabled={attachmentDialog.loading}
                      loading={attachmentDialog.loading}
                    />
                  </Grid2>
                </Grid2>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
