import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import DotsVerticalIco from "../../../../assets/icons/DotsVerticalIco";
import {
  StyledFlexRowView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  StyledTypo,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";
import { HandleSkeletion } from "../../../../utils/constants";

const headerData = [
  "Education & Certifications",
  "Date Obtained",
  "Location",
  "",
];
function Education({ educationData, isLoading }) {
  return (
    <TableContainerBorder marginTop={"0px"}>
      <Table>
        <TableHead>
          <TableRow>
            {headerData.map((item) => (
              <StyledTableTitleTableCell>{item}</StyledTableTitleTableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <TableRow>
                {Array.from({ length: 4 }).map((_, index) => (
                  <TableCell key={index}>
                    <HandleSkeletion height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </>
        ) : (
          <TableBody>
            {educationData.map((row) => (
              <TableRow key={row.id}>
                <StyledTableBodyTableCell>{row.name}</StyledTableBodyTableCell>

                <StyledTableBodyTableCell>
                  {row.achieved}
                </StyledTableBodyTableCell>
                <TableCell>
                  <StyledTypo> {row.school}</StyledTypo>
                </TableCell>
                <TableCell>
                  <DotsVerticalIco />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainerBorder>
  );
}

export default Education;
