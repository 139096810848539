import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CommonButton from "../../../../component/Button";
import { HandleSkeletion } from "../../../../utils/constants";
import {
  CommonGrid2,
  CustomAccordion,
  StyledFlexColumnView,
} from "../../../common-components/StyledComponents";
import { CustomAvatar } from "../../style";

import { StyledSpaceBetweenBox } from "../../../quick-match/components/StyledComponent";

function Experience({ matchedData, isLoading }) {
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const handleAccordionToggle = (index) => {
    setExpandedAccordion(expandedAccordion === index ? null : index);
  };
  return (
    <Box>
      {isLoading ? (
        <HandleSkeletion height={80} />
      ) : (
        matchedData?.map((item, index) => (
          <CommonGrid2
            sx={{
              padding: `${isLoading ? "0px 14px" : "10px"}`,
              mb: "1rem",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              borderRadius: "12px",
              backgroundColor:
                item?.job_title === "Gap" ? "rgb(243, 233, 255)" : "white",
              border:
                expandedAccordion === index
                  ? "2px solid #7F56D9 !important"
                  : "1px solid #EAECF0 !important",
            }}
          >
            <CommonGrid2 size={12}>
              <StyledSpaceBetweenBox width={"100%"}>
                <div>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    {item?.job_title !== "Gap" && item?.flag && (
                      <CustomAvatar
                        src={item.flag}
                        width={"45px"}
                        height="42px"
                        borderRadius="50%"
                      />
                    )}
                    <StyledFlexColumnView gap={"4px"}>
                      <Typography fontSize={"16px"} fontWeight={600}>
                        {item?.job_title}
                      </Typography>
                      <Typography
                        fontSize={"14px"}
                        fontWeight={400}
                        color={"#475467"}
                      >
                        {item?.job_title === "Gap"
                          ? `${item?.start_date ? item?.start_date : ""}${
                              item?.end_date ? ` - ${item?.end_date}` : ""
                            }`
                          : `${item?.company ? item?.company : ""}${
                              item?.company && item?.start_date ? " · " : ""
                            }${item?.start_date ? item?.start_date : ""}${
                              item?.end_date ? ` - ${item?.end_date}` : ""
                            }`}
                        {/* {`${item?.company} · ${item?.start_date} - ${item?.end_date}`} */}
                      </Typography>
                    </StyledFlexColumnView>
                  </Stack>
                </div>
                {item?.job_title !== "Gap" && (
                  <CommonButton
                    value={
                      expandedAccordion === index
                        ? "Less Experience"
                        : "View Experience"
                    }
                    endIcon={
                      expandedAccordion === index ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                    variant={
                      expandedAccordion === index ? "contained" : "outlined"
                    }
                    padding={"10px 14px"}
                    background={expandedAccordion === index && "#7F56D9"}
                    color={expandedAccordion === index ? "#fff" : "#344054"}
                    border={expandedAccordion !== index && "#D0D5DD"}
                    fontWeight={600}
                    fontSize="13px"
                    gap={"0px"}
                    onClick={() => handleAccordionToggle(index)}
                    minWidth={"auto"}
                  />
                )}
              </StyledSpaceBetweenBox>
            </CommonGrid2>
            <Collapse
              in={expandedAccordion === index}
              timeout="auto"
              unmountOnExit
            >
              <CommonGrid2 size={12} marginTop={"1.5rem"}>
                <Typography variant="p">
                  {item.accomplishments_summary}
                </Typography>
                <CustomAccordion ContentMargin={"0px"}>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls={`panel-content`}
                    id={`panel-header`}
                  >
                    <Typography variant="h4" margin={"0px"} fontWeight={600}>
                      {"Accomplishments"}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails padding="0px">
                    <ul style={{ margin: "-0.5rem 0px 0px" }}>
                      {item?.accomplishments?.map((data) => (
                        <li>{data}</li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </CustomAccordion>
              </CommonGrid2>
            </Collapse>
          </CommonGrid2>
        ))
      )}
    </Box>
  );
}

export default Experience;
