import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Checkbox,
  Grid2,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CommonButton from "../../../component/Button";
import { TableContainerBorder } from "../../common-components/StyledComponents";

const CommonTable = ({
  data = [],
  columns = [],
  sortedColumns = [],
  onSortChange,
  selectedRows = new Set(),
  onRowCheckboxChange,
  paginationProps = { count: 0, page: 1, onPageChange: () => {} },
  withPagination = false,
  withCheckbox = false,
  handleTableBodyData,
}) => {
  const { count, page, onPageChange } = paginationProps;
  const [selectedRowsState, setSelectedRowsState] = useState(selectedRows);

  const handleRowCheckboxChange = (rowId) => {
    setSelectedRowsState((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(rowId)) {
        newSelectedRows.delete(rowId);
      } else {
        newSelectedRows.add(rowId);
      }
      onRowCheckboxChange(newSelectedRows);
      return newSelectedRows;
    });
  };

  const allSelected = selectedRowsState.size === data.length;

  return (
    <TableContainerBorder>
      <Table>
        <TableHead>
          <TableRow>
            {withCheckbox && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={allSelected}
                  onChange={() => {
                    if (allSelected) {
                      setSelectedRowsState(new Set());
                    } else {
                      setSelectedRowsState(new Set(data.map((row) => row.id)));
                    }
                  }}
                />
              </TableCell>
            )}
            {columns.map((col, index) => (
              <TableCell
                key={index}
                style={{ color: "#475467", padding: "16px" }}
              >
                {sortedColumns.includes(col) && onSortChange ? (
                  <TableSortLabel
                    active={sortedColumns.includes(col)}
                    direction="asc"
                    onClick={() => onSortChange(col)}
                  >
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color="#475467"
                    >
                      {col}
                    </Typography>
                  </TableSortLabel>
                ) : (
                  <Typography variant="body2" fontWeight="600" color="#475467">
                    {col}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {withCheckbox && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedRowsState.has(row.id)}
                      onChange={() => handleRowCheckboxChange(row.id)}
                    />
                  </TableCell>
                )}
                {handleTableBodyData(row)}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                <Typography>No data available</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {withPagination && (
        <Grid2 display="flex" justifyContent="space-between" sx={{ p: 2 }}>
          <CommonButton
            variant="outlined"
            border="#D0D5DD"
            color="#344054"
            fontWeight="600"
            value="Previous"
            padding="3px 10px"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              if (page > 1) {
                onPageChange(page - 1);
              }
            }}
            disabled={page === 1}
          />
          <Pagination
            count={count}
            page={page}
            onChange={(_, newPage) => onPageChange(newPage)}
            shape="rounded"
            color="primary"
            hideNextButton
            hidePrevButton
          />
          <CommonButton
            variant="outlined"
            border="#D0D5DD"
            color="#344054"
            fontWeight="600"
            value="Next"
            padding="3px 10px"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              if (page < count) {
                onPageChange(page + 1);
              }
            }}
            disabled={page === count}
          />
        </Grid2>
      )}
    </TableContainerBorder>
  );
};

export default CommonTable;
