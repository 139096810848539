import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, IconButton, Slide, Snackbar, Typography } from "@mui/material";
import React from "react";
import CloseIco from "../../assets/icons/CloseIco";
import ReportIcon from "@mui/icons-material/Report";

const SlideTransition = (props) => {
  return <Slide {...props} direction="down" />;
};

const SnackBar = ({ snackbarState, handleCloseValidation }) => {
  return (
    <Snackbar
      open={snackbarState.open}
      autoHideDuration={snackbarState.duration || 4000}
      onClose={handleCloseValidation}
      TransitionComponent={SlideTransition}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{ background: "#fff", borderRadius: "8px" }}
    >
      {/* <Alert
        sx={{ fontWeight: 600 }}
        onClose={handleCloseValidation}
        severity={snackbarState.severity}
        variant="filled"
      >
        {snackbarState.message}
      </Alert> */}

      <Box
        display={"flex"}
        alignItems={"center"}
        backgroundColor={
          snackbarState.severity === "success" ? "#F1FDF7" : "#ff00000a"
        }
        borderRadius={"8px"}
        boxShadow={"0px 0px 10px rgba(83, 60, 190, 0.4)"}
        padding={"10px 14px"}
        gap={"4rem"}
        // minWidth={"300px"}
        // maxWidth={"400px"}
      >
        <Box display={"flex"} alignItems={"center"} gap={"10px"}>
          {snackbarState.severity === "success" ? (
            <CheckCircleIcon sx={{ color: "#2FB666" }} />
          ) : (
            <ReportIcon sx={{ color: "red" }} />
          )}
          <Box>
            <Typography fontWeight={"700"} color={"#222"} fontSize={"15px"}>
              {snackbarState.severity === "success" ? "Success !" : "Alert !"}
            </Typography>
            <Typography fontWeight={"500"} color={"#666"}>
              {snackbarState.message}
            </Typography>
          </Box>
        </Box>
        <IconButton
          onClick={handleCloseValidation}
          sx={{ color: "#666", padding: "4px" }}
        >
          <CloseIco />
        </IconButton>
      </Box>
    </Snackbar>
  );
};
export default SnackBar;
