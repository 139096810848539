import React from "react";
import {
  CommonGrid2,
  DotColor,
  StatusChange,
} from "../../common-components/StyledComponents";
import { Skeleton, Typography } from "@mui/material";
import { PrimaryDiv, SecondaryShift } from "../style";

const CommonCard = ({ viewData, loading = false, rowSize = false }) => {
  return (
    <CommonGrid2 container columnSpacing={2} rowSpacing={loading ? 0 : 2}>
      {viewData?.map((item, index) => {
        return (
          <CommonGrid2
            item
            size={{
              xs: 12,
              md: rowSize ? 6 : 4,
              sm: 6,
            }}
            key={index}
          >
            {loading ? (
              <Skeleton height={100} />
            ) : (
              <PrimaryDiv>
                <Typography variant="p" color="#475467" fontSize="16px">
                  {item.title}
                </Typography>
                {item.name && (
                  <Typography
                    variant="h4"
                    fontWeight={600}
                    fontSize={"18px"}
                    display="flex"
                    alignItems="center"
                    gap="10px"
                  >
                    {item.image && <img src={item.image} />}
                    {item.name}
                  </Typography>
                )}
                {item.shift && (
                  <SecondaryShift>
                    {item.shift.map((item, index) => (
                      <StatusChange key={index}>
                        <DotColor
                          color={item === "Morning" ? "green" : "#101828"}
                        ></DotColor>
                        {item}
                      </StatusChange>
                    ))}
                  </SecondaryShift>
                )}
                {item.additional && (
                  <SecondaryShift>
                    {item.additional.map((subItem, index) => {
                      return subItem.image ? (
                        <StatusChange key={index}>
                          <img src={subItem.image} width={20} />
                          {subItem.name}
                        </StatusChange>
                      ) : (
                        <StatusChange key={index}>{subItem}</StatusChange>
                      );
                    })}
                  </SecondaryShift>
                )}
              </PrimaryDiv>
            )}
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
};
export default CommonCard;
