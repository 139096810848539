export default function CandidateFitIco() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99967 2.50016C5.85754 2.50016 2.49967 5.85803 2.49967 10.0002C2.49967 14.1423 5.85754 17.5002 9.99967 17.5002C14.1418 17.5002 17.4997 14.1423 17.4997 10.0002C17.4997 5.85803 14.1418 2.50016 9.99967 2.50016ZM0.833008 10.0002C0.833008 4.93755 4.93707 0.833496 9.99967 0.833496C15.0623 0.833496 19.1663 4.93755 19.1663 10.0002C19.1663 15.0627 15.0623 19.1668 9.99967 19.1668C4.93707 19.1668 0.833008 15.0627 0.833008 10.0002ZM11.0413 13.9585C11.0413 14.5338 10.575 15.0002 9.99967 15.0002C9.42434 15.0002 8.95801 14.5338 8.95801 13.9585C8.95801 13.3832 9.42434 12.9168 9.99967 12.9168C10.575 12.9168 11.0413 13.3832 11.0413 13.9585ZM8.44576 7.47045C8.66626 7.0498 9.23559 6.66683 9.99967 6.66683C11.0688 6.66683 11.6663 7.36228 11.6663 7.91683C11.6663 8.47141 11.0688 9.16683 9.99967 9.16683C9.53942 9.16683 9.16634 9.53991 9.16634 10.0002V10.8335C9.16634 11.2937 9.53942 11.6668 9.99967 11.6668C10.4599 11.6668 10.833 11.2937 10.833 10.8335V10.739C12.1927 10.4281 13.333 9.37275 13.333 7.91683C13.333 6.17019 11.6919 5.00016 9.99967 5.00016C8.71351 5.00016 7.52067 5.6456 6.96966 6.69654C6.75595 7.10415 6.91313 7.60783 7.32074 7.82154C7.72836 8.03525 8.23203 7.87806 8.44576 7.47045Z"
        fill="#7F56D9"
      />
    </svg>
  );
}
