import { Download } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid2,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BookMarkIco from "../../../../assets/icons/BookMarkIco";
import BriefCaseIco from "../../../../assets/icons/BriefCaseIco";
import CalenderIco from "../../../../assets/icons/CalenderIco";
import ClipBoardCheck from "../../../../assets/icons/ClipBoardCheckIco";
import CopyIco from "../../../../assets/icons/CopyIco";
import DotsVerticalIco from "../../../../assets/icons/DotsVerticalIco";
import ScalesIco from "../../../../assets/icons/ScalesIco";
import ShareIco from "../../../../assets/icons/ShareIco";
import UsIco from "../../../../assets/icons/UsIco";
import XCircleCloseIco from "../../../../assets/icons/XCircleCloseIco";
import {
  CommonGrid2,
  CustomTooltip,
  DotColor,
  StyledFlexColumnView,
  StyledFlexRowView,
  SubTitleTypo,
  TitleTypo,
} from "../../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../../quick-match/components/StyledComponent";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useSelector } from "react-redux";
import CandidateFitIco from "../../../../assets/icons/CandidateFitIco";
import EyeIco from "../../../../assets/icons/EyeIco";
import CommonButton from "../../../../component/Button";
import DropDownSelect from "../../../../component/Select";
import { HandleSkeletion } from "../../../../utils/constants";
import { CustomDiv } from "../../../chat-bot/style";
import { CommonTippy, CustomAvatar, CustomizeMenu } from "../../style";
import ScheduleInterview from "./ScheduleInterview";
import StrongFitIco from "../../../../assets/icons/StrongFitIco";

function Profile({
  isReviewCandidateProfile,
  matchedData,
  filteredResumeTab,
  resumeId,
  handleDownloadResume,
  handleClickAddNote,
  isCandidatePerspectiveOpen,
  loading,
  handleSaveToCandidatehub,
  handleViewCandidate,
  handleView,
  menuViewLoader,
  downloadMenuLoader,
  handleShareClick,
  isRequestLoading,
  handleRequestInfo,
  candidateProfileValue,
  handleChangeStatus,
  candidateProfileData,
  handleCopyClick,
  handleCopyMouseLeave,
  copied,
  handleQuestionMarkClick,
  iconVisible,
  chatBotData,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElCompare, setAnchorElCompare] = React.useState(null);
  const [openScheduleInterview, setScheduleInterview] = useState(false);
  const open = Boolean(anchorEl);
  const openCompare = Boolean(anchorElCompare);

  const resumeIntelligence = useSelector(
    (state) => state.resumeIntelligence.resumeIntelligenceUpload
  );
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const dataLoading = loading;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeScheduleInterview = () => {
    setScheduleInterview(true);
  };
  const handleCloseScheduleInterview = () => {
    setScheduleInterview(false);
  };

  const handleCloseCompare = () => {
    setAnchorElCompare(null);
  };
  const handleClickCompare = (event) => {
    setAnchorElCompare(event.currentTarget);
  };
  const statusOptions = [
    { label: "Hired", color: "#17B26A", key: "Selected" },
    { label: "In Progress", color: "#9E77ED", key: "In Progress" },
    { label: "Rejected", color: "#F04438", key: "Rejected" },
  ];

  const handleCompareNavigate = (item) => {
    navigate(
      `/jobs/candidate/compare/${resumeId}/${item?.id}?title=${query.get(
        "title"
      )}&value=${query.get("value")}&id=${query.get(
        "id"
      )}&pagination=${query.get("pagination")}`
    );
  };

  const menuItems = [
    {
      label: "Share",
      icon: <ShareIco />,
      onClick: () => {
        handleShareClick();
        handleClose();
      },
    },
    {
      label: "Compare",
      icon: <CalenderIco />,
      onClick: handleClickCompare,
      ariaControls: open ? "basic-menu" : undefined,
      ariaHasPopup: "true",
      ariaExpanded: open ? "true" : undefined,
    },
    {
      label: "Add Note",
      icon: <ClipBoardCheck />,
      disabled: !matchedData?.rice_created,
      onClick: () => {
        handleClickAddNote();
        handleClose();
      },
    },
    {
      label: "Download",
      disabled: downloadMenuLoader,
      icon: downloadMenuLoader ? (
        <CircularProgress
          size={20}
          thickness={5}
          style={{ color: "#667085" }}
        />
      ) : (
        <Download sx={{ fontSize: "16px" }} />
      ),
      onClick: handleDownloadResume,
    },
    ...(matchedData?.temp_rice_created
      ? [
          {
            label: matchedData?.rice_created
              ? "View Profile"
              : "Save to Candidate Hub",
            icon: menuViewLoader ? (
              <CircularProgress
                size={20}
                thickness={5}
                style={{ color: "#667085" }}
              />
            ) : (
              <EyeIco size={"16px"} />
            ),
            disabled: menuViewLoader,
            onClick: async () => {
              if (matchedData?.rice_created) {
                navigate(
                  `/candidates/candidate-view?view-id=${matchedData.resume_id}`
                );
              } else if (matchedData?.duplication_present) {
                handleClose();
                handleViewCandidate();
              } else {
                await handleView();
                handleClose();
              }
            },
          },
        ]
      : []),
    ...(matchedData?.coversheet_added === true
      ? [
          {
            label: "Cover Sheet",
            icon: <ClipBoardCheck />,
            onClick: () =>
              navigate(`/coversheet?id=${resumeId}&view_resume=true`),
          },
        ]
      : []),
  ];

  const reviewCandidateProfileMenuItems = [
    {
      label: "Save to Candidate Hub",
      disabled: query.get("resume") === "parse" ? false : true,
      icon: <BookMarkIco />,
      onClick: () => {
        handleClose();
        handleSaveToCandidatehub();
      },
    },
    {
      label: "Match to Active Jobs",
      icon: <BriefCaseIco />,
      disabled: true,
      onClick: handleClose,
    },

    {
      label: "Schedule Interview",
      disabled: true,
      icon: <ClipBoardCheck />,
      onClick: handleClose,
    },
    {
      label: "Compare",
      disabled: true,
      icon: <ScalesIco />,
      onClick: handleClose,
    },
    {
      label: "Download",
      disabled:
        window.location.search.includes("resume=parse") || downloadMenuLoader,
      icon: downloadMenuLoader ? (
        <CircularProgress
          size={20}
          thickness={5}
          style={{ color: "#667085" }}
        />
      ) : (
        <Download sx={{ fontSize: "16px" }} />
      ),
      onClick: handleDownloadResume,
    },
    {
      label: "Close",
      icon: <XCircleCloseIco />,
      onClick: handleClose,
    },
  ];

  const candidateGridData = [
    { title: "Inferred Title", contentKey: matchedData?.inferred_title || "-" },
    {
      title: matchedData?.pay_rate?.label,
      contentKey: matchedData?.pay_rate?.value || "-",
    },
    {
      title: matchedData?.location?.label,
      contentKey: matchedData?.location?.value || "-",
      icon: matchedData?.location?.flag,
    },
    {
      title: "Highest Degree",
      contentKey: matchedData?.resume_info?.highest_degree || "-",
      isLink: true,
    },
  ];

  const candidateReviewGridData = [
    {
      title: "Industries",
      contentKey: matchedData?.resume_info?.industry_info || [],
    },
    {
      title: "Occupational Classification",
      contentKey:
        matchedData?.resume_info?.occupational_classification_info || [],
    },
    {
      title: "Countries",
      contentKey: matchedData?.resume_info?.country_info || [],
    },
    { title: "Website", isLink: "-" },
  ];

  const gridData = [
    { title: "Title", contentKey: matchedData?.inferred_title || "-" },
    {
      title: "Rate",
      contentKey: matchedData?.pay_rate || matchedData?.rate || "-",
    },
    {
      title: "Location",
      contentKey: matchedData?.location || "-",
      icon: <UsIco />,
    },
    {
      title: "Website",
      website: matchedData?.website || "-",
      isLink: true,
    },
  ];

  const reviewGridData = [
    { title: "Visa Status", contentKey: matchedData?.visa || "-" },
    { title: "Seniority", contentKey: matchedData?.seniority_level || "-" },
    {
      title: "Occupational Classification",
      contentKey: matchedData?.occupational_classification || "-",
    },
    { title: "Industry Experience", contentKey: matchedData?.industry || "-" },
  ];

  const CustomMenuItem = (item, index) => (
    <MenuItem key={index} value={item.key}>
      {item.label}
    </MenuItem>
  );

  return (
    <>
      <Grid2 container columnSpacing={4} rowSpacing={dataLoading ? 2 : 4}>
        {!query.get("view-id") && (
          <Grid2 size={12}>
            <Divider />
          </Grid2>
        )}
        {!isCandidatePerspectiveOpen && (
          <Grid2 size={12}>
            <StyledSpaceBetweenBox>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar
                  alt={matchedData?.candidate_name || "Avatar"}
                  src={matchedData?.avatar_url}
                  sx={{
                    width: 64,
                    height: 64,
                    backgroundColor: "#F2F4F7",
                    color: "#667085",
                    border: "1px solid #D0D5DD",
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: "24px",
                  }}
                >
                  {dataLoading ? (
                    <HandleSkeletion height={35} width={30} />
                  ) : (
                    !matchedData?.avatar_url &&
                    matchedData?.candidate_name?.[0]?.toUpperCase()
                  )}
                </Avatar>
                <Box>
                  <StyledFlexRowView>
                    <Typography fontSize={"24px"} fontWeight={600}>
                      {dataLoading ? (
                        <HandleSkeletion height={35} width={300} />
                      ) : (
                        matchedData?.candidate_name
                      )}

                      {isReviewCandidateProfile ? null : dataLoading ? (
                        <HandleSkeletion height={35} width={80} />
                      ) : (
                        <DropDownSelect
                          marginTop="0px"
                          removeAnimation={true}
                          renderValue={false}
                          data={statusOptions}
                          valueChange={true}
                          fullWidth={false}
                          CustomMenuItem={CustomMenuItem}
                          disabled={matchedData?.status == "Rejected"}
                          handleRenderValue={(selected) => {
                            const selectedOption = statusOptions.find(
                              (option) => option.key === selected
                            );
                            return (
                              <Box display={"flex"} alignItems={"center"}>
                                <DotColor
                                  color={selectedOption.color}
                                  marginRight="8px"
                                />
                                <Typography>{selectedOption.label}</Typography>
                              </Box>
                            );
                          }}
                          customStyle={{
                            "& .MuiSelect-select": {
                              padding: "4px 32px 4px 10px !important",
                              borderRadius: "6px",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#D0D5DD",
                              boxShadow:
                                "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            },
                            "& .MuiSelect-icon": {
                              right: "0rem",
                            },
                            margin: "0px 10px",
                          }}
                          value={matchedData?.status || candidateProfileValue}
                          handleChange={(e) => handleChangeStatus(e)}
                        />
                      )}
                    </Typography>
                  </StyledFlexRowView>

                  {dataLoading ? (
                    <HandleSkeletion height={35} width={200} />
                  ) : (
                    <>
                      {matchedData?.email ? (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Link
                            href={`mailto:${matchedData?.email}`}
                            underline="none"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: "#7F56D9",
                              fontSize: "16px",
                              fontFamily: "Inter",
                            }}
                          >
                            {matchedData?.email}
                          </Link>
                          <CustomTooltip
                            arrow
                            placement="top"
                            title={copied ? "Copied!" : "Copy to Clipboard"}
                            fontSize="12px"
                            padding="5px 10px"
                            margin="0px 0px 10px !important"
                          >
                            <IconButton
                              onClick={() =>
                                handleCopyClick(matchedData?.email)
                              }
                              style={{ padding: "5px" }}
                              padding="0px"
                              onMouseLeave={handleCopyMouseLeave}
                            >
                              <CopyIco />
                            </IconButton>
                          </CustomTooltip>
                        </Stack>
                      ) : null}
                    </>
                  )}

                  {dataLoading ? (
                    <HandleSkeletion height={35} width={200} />
                  ) : matchedData?.resume_info?.candidate_fit ? (
                    <CommonGrid2
                      display={"flex"}
                      alignItems={"center"}
                      gap={"5px"}
                      marginTop={"4px"}
                    >
                      <CommonGrid2
                        background={
                          matchedData?.resume_info?.candidate_fit?.fit ===
                          "Weak Fit"
                            ? "#ff070026"
                            : matchedData?.resume_info?.candidate_fit?.fit ===
                              "Strong Fit"
                            ? "#ECFDF3"
                            : "#ffa6022e"
                        }
                        borderRadius="5px"
                        width="max-content"
                        padding="5px 10px"
                        display={"flex"}
                        alignItems={"center"}
                        gap={"5px"}
                      >
                        {matchedData?.resume_info?.candidate_fit?.fit ===
                          "Strong Fit" && <StrongFitIco />}
                        <Typography
                          variant="p"
                          fontWeight={700}
                          color={
                            matchedData?.resume_info?.candidate_fit?.fit ===
                            "Weak Fit"
                              ? "#dd0600"
                              : matchedData?.resume_info?.candidate_fit?.fit ===
                                "Strong Fit"
                              ? "#067647"
                              : "#ffa500"
                          }
                        >
                          {matchedData?.resume_info?.candidate_fit?.fit}
                        </Typography>
                      </CommonGrid2>

                      <IconButton
                        onClick={() =>
                          handleQuestionMarkClick(
                            chatBotData.bubble.filter(
                              (item) => item.question === "Candidate fit"
                            )[0]
                          )
                        }
                        style={{ opacity: !iconVisible ? 0.5 : 1 }}
                        disabled={!iconVisible}
                      >
                        <CandidateFitIco />
                      </IconButton>
                    </CommonGrid2>
                  ) : null}
                </Box>
              </Stack>

              {dataLoading ? (
                <HandleSkeletion height={100} width={200} />
              ) : (
                <StyledFlexRowView gap={"1.5rem"}>
                  {isReviewCandidateProfile ? null : (
                    <CommonButton
                      value={"Schedule Interview"}
                      padding={"10px 14px"}
                      variant="outlined"
                      size="small"
                      color="rgb(127, 86, 217)"
                      borderRadius="8px"
                      fontWeight={600}
                      border="rgb(127, 86, 217)"
                      onClick={handleChangeScheduleInterview}
                      disabled={
                        !matchedData?.rice_created ||
                        matchedData.status == "Rejected"
                      }
                    />
                  )}
                  <CommonButton
                    value={"Request Info"}
                    padding={"10px 14px"}
                    variant="outlined"
                    color={"#344054"}
                    border={"#D0D5DD"}
                    fontWeight={"600"}
                    onClick={handleRequestInfo}
                    loading={isRequestLoading}
                    disabled={
                      !location.pathname.includes("jobs-view") ||
                      isRequestLoading ||
                      !matchedData?.rice_created ||
                      matchedData.status == "Rejected"
                    }
                  />

                  <div>
                    <CommonButton
                      value={<DotsVerticalIco />}
                      padding={"10px 14px"}
                      variant="outlined"
                      color={"#344054"}
                      border={"#D0D5DD"}
                      fontWeight={"600"}
                      svg={true}
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      minWidth={"20px !important"}
                    />

                    <CustomizeMenu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {(isReviewCandidateProfile
                        ? reviewCandidateProfileMenuItems
                        : menuItems
                      ).map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={item.disabled ? () => {} : item.onClick}
                          aria-controls={item.ariaControls}
                          aria-haspopup={item.ariaHasPopup}
                          aria-expanded={item.ariaExpanded}
                          style={{ opacity: item.disabled ? 0.5 : 1 }}
                          disabled={item?.disabled}
                        >
                          <StyledFlexRowView>
                            {item.icon}
                            <Typography>{item.label}</Typography>
                          </StyledFlexRowView>
                        </MenuItem>
                      ))}
                    </CustomizeMenu>

                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElCompare}
                      open={openCompare}
                      onClose={handleCloseCompare}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {filteredResumeTab?.map((item, imdex) => (
                        <MenuItem
                          sx={{ marginTop: "10px" }}
                          onClick={() => handleCompareNavigate(item)}
                        >
                          <>
                            <ListItemIcon>
                              <Avatar
                                alt={"a"}
                                src="https://mui.com/static/images/avatar/1.jpg"
                                sx={{ width: 24, height: 24 }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              <Typography>{item?.name}</Typography>
                            </ListItemText>
                            <Chip
                              label={`${item?.score}%`}
                              variant="outlined"
                              size="small"
                              sx={{
                                paddingX: "5px",
                                paddingY: "1px",
                                marginLeft: "30px",
                              }}
                            />
                          </>
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </StyledFlexRowView>
              )}
            </StyledSpaceBetweenBox>
          </Grid2>
        )}

        <ScheduleInterview
          open={openScheduleInterview}
          onClose={handleCloseScheduleInterview}
          onApply={() => {
            handleCloseScheduleInterview();
          }}
        />
      </Grid2>
      <CommonGrid2
        display={"flex"}
        gap={"1rem"}
        justifyContent={"space-between"}
        margin={"2rem 0px 0.5rem"}
      >
        {candidateProfileData
          ? candidateGridData.map((item) => (
              <Grid2
                size={3}
                key={item.id}
                // backgroundColor="#F2F4F7"
                // borderRadius={"10px"}
                padding={"10px"}
                width={"100%"}
              >
                {dataLoading ? (
                  <HandleSkeletion height={50} />
                ) : (
                  <StyledFlexColumnView gap={"6px"}>
                    <CommonTippy
                      content={item.title}
                      placement="top"
                      color={"#7F56D9"}
                      fontSize="12px"
                    >
                      <TitleTypo
                        fontWeight={600}
                        color="#5B738B"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {item.title}
                      </TitleTypo>
                    </CommonTippy>
                    <CommonGrid2 display={"flex"} gap={"8px"}>
                      {item?.icon && (
                        <CustomAvatar
                          src={item.icon}
                          width={"22px"}
                          height="21px"
                          borderRadius="50%"
                          margin="0px 0px 4px 0px"
                        />
                      )}
                      <SubTitleTypo>{item.contentKey}</SubTitleTypo>
                    </CommonGrid2>
                  </StyledFlexColumnView>
                )}
              </Grid2>
            ))
          : gridData.map((item) => (
              <Grid2
                size={3}
                key={item.id}
                // backgroundColor="#F2F4F7"
                // borderRadius={"10px"}
                padding={"10px"}
                width={"100%"}
              >
                {dataLoading ? (
                  <HandleSkeletion height={50} />
                ) : (
                  <StyledFlexColumnView gap={"6px"}>
                    <CommonTippy
                      content={item.title}
                      placement="top"
                      color={"#7F56D9"}
                      fontSize="12px"
                    >
                      <TitleTypo
                        fontWeight={600}
                        color="#5B738B"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {item.title}
                      </TitleTypo>
                    </CommonTippy>
                    {item.contentKey && (
                      <SubTitleTypo>{item.contentKey}</SubTitleTypo>
                    )}
                    {item.isLink && (
                      <SubTitleTypo fontSize={"15px"}>
                        {item.website}
                      </SubTitleTypo>
                    )}
                  </StyledFlexColumnView>
                )}
              </Grid2>
            ))}
      </CommonGrid2>
      <CommonGrid2
        display={"flex"}
        justifyContent={"space-between"}
        margin={"0rem 0px 2rem"}
        gap={"1rem"}
      >
        {candidateProfileData
          ? candidateReviewGridData.map((item) => (
              <CommonGrid2
                size={3}
                key={item.id}
                // backgroundColor="#F2F4F7"
                // borderRadius={"10px"}
                padding={"10px"}
                width={"100%"}
                // scrollStyle={true}
                // height={"130px"}
                // overflowY={item.contentKey?.length >= 3 ? "scroll" : "none"}
                // scrollBar="5px"
              >
                {dataLoading ? (
                  <HandleSkeletion height={50} />
                ) : (
                  <StyledFlexColumnView gap={"5px"}>
                    <CommonTippy
                      content={item.title}
                      placement="top"
                      color={"#7F56D9"}
                      fontSize="12px"
                    >
                      <TitleTypo
                        fontWeight={600}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        color="#5B738B"
                      >
                        {item.title}
                      </TitleTypo>
                    </CommonTippy>
                    {item.contentKey &&
                      item.contentKey.map((year) => (
                        <CommonGrid2
                          display={"flex"}
                          justifyContent={"space-between"}
                          gap={"4px"}
                        >
                          <CommonTippy
                            content={
                              year.industry ||
                              year.occupational_classification ||
                              year.country
                            }
                            placement="top"
                            color={"#7F56D9"}
                            fontSize="11px"
                          >
                            <CommonGrid2
                              display={"flex"}
                              justifyContent={"space-between"}
                              gap={"6px"}
                            >
                              {year?.flag && (
                                <CustomAvatar
                                  src={year.flag}
                                  width={"22px"}
                                  height="21px"
                                  borderRadius="50%"
                                  margin="0px 0px 4px 0px"
                                />
                              )}
                              <SubTitleTypo
                                fontSize="15px"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {year.industry ||
                                  year.occupational_classification ||
                                  year.country}
                              </SubTitleTypo>
                            </CommonGrid2>
                          </CommonTippy>
                          <SubTitleTypo
                            fontSize="15px"
                            color="#8396A8"
                            whiteSpace="pre"
                          >
                            {year.duration_in_years} yrs
                          </SubTitleTypo>
                        </CommonGrid2>
                      ))}
                    {item.isLink && (
                      <SubTitleTypo fontSize={"15px"}>
                        {item.isLink}
                      </SubTitleTypo>
                    )}
                  </StyledFlexColumnView>
                )}
              </CommonGrid2>
            ))
          : isReviewCandidateProfile &&
            reviewGridData.map((item) => (
              <Grid2
                size={3}
                key={item.id}
                // backgroundColor="#F2F4F7"
                // borderRadius={"10px"}
                padding={"10px"}
                width={"100%"}
              >
                {dataLoading ? (
                  <HandleSkeletion height={50} />
                ) : (
                  <StyledFlexColumnView gap={"6px"}>
                    <CommonTippy
                      content={item.title}
                      placement="top"
                      color={"#7F56D9"}
                      fontSize="12px"
                    >
                      <TitleTypo
                        fontWeight={600}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        color="#5B738B"
                      >
                        {item.title}
                      </TitleTypo>
                    </CommonTippy>
                    <SubTitleTypo>{item.contentKey}</SubTitleTypo>
                  </StyledFlexColumnView>
                )}
              </Grid2>
            ))}
      </CommonGrid2>
      {!isCandidatePerspectiveOpen && (
        <Grid2 size={12}>
          <Divider />
        </Grid2>
      )}
    </>
  );
}

export default Profile;
