import CommonButton from "../../../component/Button";
import CommonTable from "../../candidates/Table";
import { StyledFlexRowView } from "../../common-components/StyledComponents";

export default function Team({
  teamList,
  handleTeamTableBodyData,
  headerData,
  checkSelected,
  handleSingleSelect,
  handleSelectAll,
  handleOpenDialog,
}) {
  return (
    <>
      <CommonTable
        data={teamList.data}
        columns={headerData}
        onSortChange={(col) => console.log("Sort by", col)}
        checkSelected={checkSelected.selected}
        handleTableBodyData={handleTeamTableBodyData}
        withCheckbox={true}
        withPagination={false}
        loading={teamList.loading}
        handleSingleSelect={handleSingleSelect}
        handleSelectAll={handleSelectAll}
      />

      <StyledFlexRowView style={{ padding: "32px 0px" }}>
        <CommonButton
          variant="outlined"
          color="#344054"
          border="#D0D5DD"
          fontWeight="600"
          value="Remove from Team"
          padding={"10px 16px"}
          disabled={checkSelected.count < 1}
          onClick={() => handleOpenDialog("remove")}
        />
        <CommonButton
          variant="outlined"
          color="#344054"
          border="#D0D5DD"
          fontWeight="600"
          value="Manage Permissions"
          padding={"10px 16px"}
          disabled={checkSelected.count < 1}
          onClick={() => handleOpenDialog("manage")}
        />
      </StyledFlexRowView>
    </>
  );
}
