import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CustomAccordion,
  CustomTable,
  DialogTableList,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";
import {
  CustomizeTableCell,
  CustomizeTableRow,
  EllipseData,
} from "../../style";

const InteractionDialog = ({ open, handleClose, interActionData }) => {
  console.log(interActionData.updated_fields," data")
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      sx={{ "& .MuiDialog-paperFullWidth": { padding: "0px 25px 20px" } }}
    >
      <DialogActions>
        <Button onClick={handleClose} sx={{ left: "1.5rem" }}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" textAlign={"center"} marginTop={-5}>
          {interActionData.action}
        </Typography>
      </DialogTitle>
      {interActionData?.updated_fields && (
  <CustomTable>
    <TableHead>
      <TableRow>
        <CustomizeTableCell className="font-bold">Field</CustomizeTableCell>
        <CustomizeTableCell className="font-bold">Old Value</CustomizeTableCell>
        <CustomizeTableCell className="font-bold">New Value</CustomizeTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <CustomizeTableRow>
        <CustomizeTableCell>{interActionData.updated_fields.key || ""}</CustomizeTableCell>
        <CustomizeTableCell>{interActionData.updated_fields.old_value || ""}</CustomizeTableCell>
        <CustomizeTableCell>{interActionData.updated_fields.new_value || ""}</CustomizeTableCell>
      </CustomizeTableRow>
    </TableBody>
  </CustomTable>
)}
    </Dialog>
  );    
};
export default InteractionDialog;
