import { CLINET_PARTNER } from "../Action";

const intialState = {
  clientAddCall: {},
};
const ClientPartnerReducer = (state = intialState, action) => {
  switch (action.type) {
    case CLINET_PARTNER.clientAddCall:
      return { ...state, clientAddCall: action.data };
    default:
      return state;
  }
};
export default ClientPartnerReducer;
