import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography
} from "@mui/material";
import React from "react";
import XCloseIco from "../../assets/icons/XCloseIco";
import NoteBackgroundImg from "../../assets/images/note-background.png";

function RunScoringTest({
  openNoteDialog,
  handleCloseNoteDialog,
  id,
  handleCreateNote,
  noteList,
  handleNoteTextChange,
  addNoteLoading,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={openNoteDialog}
      onClose={handleCloseNoteDialog}
      sx={{
        "& .MuiDialog-paper": {
          width: "400px",
          maxWidth: "none",
          borderRadius: "12px",
          background: "#FFF",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        },
      }}
    >
      <img
        src={NoteBackgroundImg}
        style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
      />
      <DialogActions>
        <Button onClick={handleCloseNoteDialog} sx={{ marginTop: "10px" }}>
          <XCloseIco />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {"Run Scoring Test?"}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid2
          spacing={2}
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "150px",
            width: "100%",
          }}
        >
          <Typography variant="h6" sx={{ mt: 2 }}>
            <span style={{ color: "#475467" }}>
              {"Run scoring tests for following jobs:"}
            </span>
            {"Product Manager at Porsche, Product Designer at Apple?"}
          </Typography>
          <Button
            variant="contained"

            fullWidth
            sx={{ width: "100%" }}
          >{"Run Scoring Test"}</Button>
        </Grid2>
      </DialogContent>
    </Dialog>
  );
}

export default RunScoringTest;
