import { Alert, Slide, Snackbar } from "@mui/material";
import React from "react";

const SlideTransition = (props) => {
  return <Slide {...props} direction="down" />;
};

const SnackBar = ({ snackbarState, handleCloseValidation }) => {
  return (
    <Snackbar
      open={snackbarState.open}
      autoHideDuration={snackbarState.duration || 4000}
      onClose={handleCloseValidation}
      TransitionComponent={SlideTransition}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert
        sx={{ fontWeight: 600 }}
        onClose={handleCloseValidation}
        severity={snackbarState.severity}
        variant="filled"
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
};
export default SnackBar;
