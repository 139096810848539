export default function StrongFitIco() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0396 11.6519L11.8536 7.10159L8.10746 10.8477L10.766 14.6444C10.8868 14.8172 11.1 14.9003 11.3061 14.8545C11.3138 14.8528 11.321 14.8511 11.3283 14.8492C11.5235 14.7968 11.6717 14.636 11.707 14.4358L12.1289 12.0431L14.5218 12.465C14.729 12.5019 14.9384 12.4097 15.0525 12.2315C15.1658 12.0536 15.1608 11.8248 15.0396 11.6519Z"
        fill="#7F56D9"
      />
      <path
        d="M4.12335 7.13631L0.964478 11.6479C0.843257 11.8207 0.83818 12.0495 0.951598 12.2274C1.06526 12.4056 1.27543 12.498 1.48229 12.4609L3.87514 12.039L4.29706 14.4316C4.33233 14.632 4.48057 14.7927 4.67581 14.845C4.68303 14.847 4.69032 14.8487 4.69792 14.8504C4.90402 14.8961 5.11723 14.8131 5.23811 14.6403L7.8695 10.8824L4.12335 7.13631Z"
        fill="#7F56D9"
      />
      <path
        d="M7.99693 12.1423C4.83253 12.1423 2.25781 9.56773 2.25781 6.40318C2.25781 3.23862 4.83249 0.664062 7.99693 0.664062C11.1614 0.664062 13.736 3.23862 13.736 6.40318C13.736 9.56773 11.1613 12.1423 7.99693 12.1423Z"
        fill="#FF9F19"
      />
      <path
        d="M8.00033 11.0944C5.41103 11.0944 3.30469 8.98792 3.30469 6.39877C3.30469 3.80962 5.411 1.70312 8.00033 1.70312C10.5897 1.70312 12.696 3.80962 12.696 6.39877C12.696 8.98792 10.5896 11.0944 8.00033 11.0944Z"
        fill="#FFE14D"
      />
      <path
        d="M9.04621 7.97077H8.52447V4.31862C8.52447 4.03041 8.29075 3.79688 8.00272 3.79688H6.95925C6.67122 3.79688 6.4375 4.03041 6.4375 4.31862C6.4375 4.60684 6.67122 4.84037 6.95925 4.84037H7.481V7.9708H6.95925C6.67122 7.9708 6.4375 8.20433 6.4375 8.49255C6.4375 8.78077 6.67122 9.0143 6.95925 9.0143H9.04621C9.33424 9.0143 9.56796 8.78077 9.56796 8.49255C9.56796 8.20433 9.33424 7.97077 9.04621 7.97077Z"
        fill="#F28618"
      />
    </svg>
  );
}
