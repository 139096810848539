export default function AccomplishmentIco() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="3.75" fill="#DBFAE6" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9997 14.6663C14.577 14.6663 16.6663 12.577 16.6663 9.99967C16.6663 7.42235 14.577 5.33301 11.9997 5.33301C9.42235 5.33301 7.33301 7.42235 7.33301 9.99967C7.33301 12.577 9.42235 14.6663 11.9997 14.6663ZM11.9997 7.99967C11.8103 7.99967 11.6836 8.2269 11.4303 8.68135L11.3647 8.79892C11.2928 8.92806 11.2568 8.99263 11.2007 9.03523C11.1445 9.07784 11.0747 9.09365 10.9349 9.12528L10.8076 9.15408C10.3156 9.26539 10.0697 9.32104 10.0112 9.50922C9.95264 9.6974 10.1203 9.89349 10.4557 10.2857L10.5425 10.3871C10.6378 10.4986 10.6854 10.5543 10.7069 10.6232C10.7283 10.6921 10.7211 10.7665 10.7067 10.9152L10.6935 11.0505C10.6428 11.5738 10.6175 11.8354 10.7707 11.9517C10.9239 12.068 11.1542 11.962 11.6148 11.7499L11.7339 11.6951C11.8649 11.6348 11.9303 11.6047 11.9997 11.6047C12.0691 11.6047 12.1345 11.6348 12.2654 11.6951L12.3845 11.7499C12.8451 11.962 13.0754 12.068 13.2287 11.9517C13.3819 11.8354 13.3565 11.5738 13.3058 11.0505L13.2927 10.9152C13.2783 10.7665 13.2711 10.6921 13.2925 10.6232C13.3139 10.5543 13.3616 10.4986 13.4569 10.3871L13.5437 10.2857C13.879 9.89349 14.0467 9.6974 13.9882 9.50922C13.9297 9.32104 13.6837 9.26539 13.1917 9.15408L13.0645 9.12528C12.9247 9.09365 12.8548 9.07784 12.7987 9.03523C12.7425 8.99263 12.7065 8.92806 12.6346 8.79892L12.5691 8.68135C12.3157 8.2269 12.1891 7.99967 11.9997 7.99967Z"
        fill="#079455"
      />
      <path
        d="M8.72867 14.6279L8.47616 15.549C8.05727 17.0769 7.84782 17.8408 8.12731 18.2591C8.22527 18.4056 8.35667 18.5233 8.50915 18.6009C8.94423 18.8224 9.616 18.4724 10.9595 17.7723C11.4066 17.5394 11.6301 17.4229 11.8676 17.3976C11.9557 17.3882 12.0443 17.3882 12.1324 17.3976C12.3699 17.4229 12.5934 17.5394 13.0405 17.7723C14.384 18.4724 15.0558 18.8224 15.4909 18.6009C15.6433 18.5233 15.7747 18.4056 15.8727 18.2591C16.1522 17.8408 15.9427 17.0769 15.5239 15.549L15.2713 14.6279C14.3472 15.2824 13.2185 15.667 12 15.667C10.7815 15.667 9.65277 15.2824 8.72867 14.6279Z"
        fill="#079455"
      />
    </svg>
  );
}
