import { Grid2, IconButton, TableCell, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIco from "../../assets/icons/DeleteIco";
import EyeIco from "../../assets/icons/EyeIco";
import CommonButton from "../../component/Button";
import SnackBar from "../../component/SnackBar";
import ToogleButton from "../../component/ToogleButton";
import {
  ClientDelete,
  ClientTableGetCall,
} from "../../redux/action/ClientPartner";
import {
  CommonGrid2,
  DotColor,
  StatusChange,
} from "../common-components/StyledComponents";
import SkillDeleteDialog from "../jobs/job-information/skills/SkillDeleteDialog";
import Client from "./client";
import Vendor from "./vendor";
import {
  VendorDelete,
  VendorTableGetCall,
} from "../../redux/action/VendorPartner";
import moment from "moment";

export default function ClientPartner() {
  const [activePage, setActivePage] = useState("Vendors");
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const paramsType = query.get("type");
  const [clientList, setClientList] = useState({
    data: [],
    currentPage: 1,
    totalPage: "",
    loading: true,
  });
  const [vendorList, setVendorList] = useState({
    data: [],
    currentPage: 1,
    totalPage: "",
    loading: true,
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    id: "",
  });
  const [deleteButton, setDeleteButton] = useState({
    loading: false,
    open: false,
    content: "",
    id: "",
  });
  const [vendorDeleteButton, setVendorDeleteButton] = useState({
    loading: false,
    open: false,
    content: "",
    id: "",
  });

  const [headerData, setHeaderData] = useState([
    { name: "ID", direction: "asc", sort: false, width: "10%" },
    { name: "Name", direction: "asc", sort: false, width: "40%" },
    { name: "Type", direction: "asc", sort: false, width: "20%" },
    { name: "Onboarded", direction: "asc", sort: false, width: "20%" },
    {},
  ]);

  const [vendorHeaderData, setVendorHeaderData] = useState([
    { name: "ID", direction: "asc", sort: false, width: "10%" },
    { name: "Name", direction: "asc", sort: false, width: "40%" },
    { name: "Type", direction: "asc", sort: false, width: "20%" },
    { name: "Onboarded", direction: "asc", sort: false, width: "20%" },
    {},
  ]);

  const handleAddClientClick = () => {
    navigate(`/client-vendor/client/add`);
  };

  const handleAddVendorClick = () => {
    navigate(`/client-vendor/vendor/add`);
  };

  const handleToogleChange = (event, newAlignment) => {
    if (newAlignment !== null) setActivePage(newAlignment);
  };

  const ToogleData = [
    {
      name: "Vendors",
      value: "Vendors",
      // disabled: true,
    },
    { name: "Clients", value: "Clients" },
  ];

  const handleFetchClientCall = async () => {
    try {
      setClientList((prev) => ({
        ...prev,
        loading: true,
      }));
      const data = await ClientTableGetCall();
      setClientList((prev) => ({
        ...prev,
        data: data?.data,
        loading: false,
      }));
    } catch (error) {
      setClientList((prev) => ({
        ...prev,
        loading: false,
      }));
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleFetchVendorCall = async () => {
    try {
      setVendorList((prev) => ({
        ...prev,
        loading: true,
      }));
      const data = await VendorTableGetCall();
      setVendorList((prev) => ({
        ...prev,
        data: data?.data,
        loading: false,
      }));
    } catch (error) {
      setVendorList((prev) => ({
        ...prev,
        loading: false,
      }));
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (paramsType) {
      setActivePage(paramsType);
    }
  }, []);

  useEffect(() => {
    if (activePage === "Clients") {
      handleFetchClientCall();
    } else if (activePage === "Vendors") {
      handleFetchVendorCall();
    }
  }, [activePage]);

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleViewClick = (list) => {
    navigate(`/client-vendor/client/add?id=${list.id}`);
  };

  const handleDeleteClick = (list) => {
    setDeleteButton((prev) => ({
      ...prev,
      open: true,
      content: list.client_name,
      id: list.id,
    }));
  };

  const handelDialogClose = () => {
    setDeleteButton({
      open: false,
      loading: false,
      content: "",
      id: "",
    });
  };

  const handleClientDelete = async () => {
    setDeleteButton((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const data = await ClientDelete(deleteButton.id);
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      handleFetchClientCall();
      handelDialogClose();
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setDeleteButton((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleClientTableBodyData = (row, index) => {
    return (
      <>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{row.client_name || "-"}</TableCell>
        <TableCell>
          <StatusChange>
            <DotColor
              color={
                row.status === "Active"
                  ? "green"
                  : row.status === "Hold"
                  ? "orange"
                  : "#667085"
              }
            />
            {row.type_name || "-"}
          </StatusChange>
        </TableCell>
        <TableCell>
          {row.created_at ? moment(row.created_at).format("DD MMM YYYY") : "-"}
        </TableCell>
        <TableCell>
          <CommonGrid2 display={"flex"} gap={"1.5rem"} justifyContent={"end"}>
            <IconButton onClick={() => handleViewClick(row)}>
              <EyeIco />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(row)}>
              <DeleteIco />
            </IconButton>
          </CommonGrid2>
        </TableCell>
      </>
    );
  };

  const handleVendorDeleteClick = (list) => {
    setVendorDeleteButton((prev) => ({
      ...prev,
      open: true,
      content: list.business_name,
      id: list.id,
    }));
  };

  const handevendorlDialogClose = () => {
    setVendorDeleteButton({
      open: false,
      loading: false,
      content: "",
      id: "",
    });
  };

  const handleVendorDelete = async () => {
    setVendorDeleteButton((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const data = await VendorDelete(vendorDeleteButton.id);
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      handleFetchVendorCall();
      handevendorlDialogClose();
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setVendorDeleteButton((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleVendorTableBodyData = (row, index) => {
    return (
      <>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{row.business_name || "-"}</TableCell>
        <TableCell>
          <StatusChange>
            <DotColor
              color={
                row.bussiness_classification === "Partnership"
                  ? "green"
                  : row.bussiness_classification === "Individual"
                  ? "orange"
                  : "#667085"
              }
            />
            {row.bussiness_classification || "-"}
          </StatusChange>
        </TableCell>
        <TableCell>
          {row.created_at ? moment(row.created_at).format("DD MMM YYYY") : "-"}
        </TableCell>
        <TableCell>
          <CommonGrid2 display={"flex"} gap={"1.5rem"} justifyContent={"end"}>
            <IconButton
              onClick={() => navigate(`/client-vendor/vendor/add?id=${row.id}`)}
            >
              <EyeIco />
            </IconButton>
            <IconButton onClick={() => handleVendorDeleteClick(row)}>
              <DeleteIco />
            </IconButton>
          </CommonGrid2>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Grid2>
        <Grid2 container spacing={2} marginBottom={"2rem"}>
          <CommonGrid2
            size={{ xs: 12, md: 4, sm: 12 }}
            item
            alignItems={"center"}
            display={"flex"}
          >
            <Typography fontSize={"24px"} fontWeight={600}>
              {activePage}
            </Typography>
          </CommonGrid2>

          <CommonGrid2
            item
            size={{ xs: 12, md: 8, sm: 12 }}
            gap="10px"
            display="flex"
            justifyContent={"end"}
          >
            <ToogleButton
              ToogleData={ToogleData}
              alignment={activePage}
              handleChange={(event, selectedValue) =>
                handleToogleChange(event, selectedValue)
              }
              border={"1px solid #D0D5DD"}
              borderRadius={"8px"}
              activeColor={"#344054"}
              color={"#344054"}
              activeBackgroundColor="#F9FAFB"
              backgroundColor="#FFFF"
            />
          </CommonGrid2>
        </Grid2>
      </Grid2>
      {activePage === "Vendors" && (
        <Vendor
          vendorHeaderData={vendorHeaderData}
          handleAddVendorClick={handleAddVendorClick}
          vendorList={vendorList}
          handleVendorTableBodyData={handleVendorTableBodyData}
        />
      )}
      {activePage === "Clients" && (
        <Client
          headerData={headerData}
          handleAddClientClick={handleAddClientClick}
          clientList={clientList}
          handleClientTableBodyData={handleClientTableBodyData}
        />
      )}

      <SkillDeleteDialog
        deleteDialog={deleteButton.open}
        handleDeleteDialogClose={handelDialogClose}
        handleDeleteSkill={handleClientDelete}
        buttonLoading={deleteButton.loading}
        title="Clients"
        content={deleteButton.content}
      />

      <SkillDeleteDialog
        deleteDialog={vendorDeleteButton.open}
        handleDeleteDialogClose={handevendorlDialogClose}
        handleDeleteSkill={handleVendorDelete}
        buttonLoading={vendorDeleteButton.loading}
        title="Vendors"
        content={vendorDeleteButton.content}
      />

      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </>
  );
}
