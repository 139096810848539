import { Grid2, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackBar from "../../component/SnackBar";
import { HarizonAnalytics } from "../../redux/action/Harizon";
import { StyledSpaceBetweenBox } from "../quick-match/components/StyledComponent";
import AnalyticsCard from "./components/AnalyticsCard";
import { SelectCreateCard } from "./style";
import { CommonGrid2 } from "../common-components/StyledComponents";
import DropDownSelect from "../../component/Select";

const Dashboard = () => {
  const harizonAnalytics = useSelector(
    (state) => state.harizon.harizonAnalytics
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriodValue, setTimePeriodValue] = useState("");
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: "",
    severity: "error",
  });
  const analytics = [
    {
      name: "JEM",
    },
    {
      name: "ICE",
    },
    {
      name: "DAME",
    },
    {
      name: "QM",
    },
    {
      name: "Time Saved",
    },
    {
      name: "ROI",
    },
  ];

  useEffect(() => {
    fetchAnalytics({});
  }, []);

  const fetchAnalytics = async ({ timePeriod = timePeriodValue }) => {
    try {
      let params = {
        filter_type: timePeriod,
      };
      setIsLoading(true);
      const data = await dispatch(HarizonAnalytics(params));
      console.log(data, "sfjksfnj");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  const mergedAnalyticsData = analytics.map((item) => {
    const found =
      Object.keys(harizonAnalytics).length !== 0 &&
      harizonAnalytics.find(
        (data) => data.name.toLowerCase() === item.name.toLowerCase()
      );
    return {
      ...item,
      value: found ? found.value : 0,
    };
  });

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const dropDownOption = [
    {
      name: "Last Month",
      id: "last_month",
    },
    { name: "This Month", id: "this_month" },
    { name: "Last Week", id: "last_week" },
    { name: "This Week", id: "this_week" },
  ];

  const CustomMenuItem = (item, index) => (
    <MenuItem value={item?.id} key={index}>
      {item?.name}
    </MenuItem>
  );

  return (
    <Grid2 container spacing={3}>
      <Grid2 item size={{ xs: 12 }} paddingBottom={3}>
        <StyledSpaceBetweenBox>
          <Typography variant="h2">Horizon Dashboard</Typography>
        </StyledSpaceBetweenBox>
      </Grid2>
      <Grid2 item size={{ xs: 12 }}>
        <SelectCreateCard minHeight="100%" padding="20px" condition={false}>
          <CommonGrid2
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h5" color="#475E75">
              Helix + Halo Usage
            </Typography>
            <DropDownSelect
              marginTop="0px"
              title={"Select Time Period"}
              InputPadding={"10px"}
              valueChange={true}
              data={dropDownOption || []}
              value={timePeriodValue}
              handleChange={(e) => {
                setTimePeriodValue(e.target.value);
                fetchAnalytics({ timePeriod: e.target.value });
              }}
              disabled={isLoading}
              customStyle={{
                width: "180px",
              }}
              CustomMenuItem={CustomMenuItem}
            />
          </CommonGrid2>
          <AnalyticsCard
            harizonAnalytics={mergedAnalyticsData}
            isLoading={isLoading}
          />
        </SelectCreateCard>
      </Grid2>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </Grid2>
  );
};
export default Dashboard;
