function DotsVerticalIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0003 10.834C10.4606 10.834 10.8337 10.4609 10.8337 10.0007C10.8337 9.54041 10.4606 9.16732 10.0003 9.16732C9.54009 9.16732 9.16699 9.54041 9.16699 10.0007C9.16699 10.4609 9.54009 10.834 10.0003 10.834Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0003 5.00065C10.4606 5.00065 10.8337 4.62755 10.8337 4.16732C10.8337 3.70708 10.4606 3.33398 10.0003 3.33398C9.54009 3.33398 9.16699 3.70708 9.16699 4.16732C9.16699 4.62755 9.54009 5.00065 10.0003 5.00065Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0003 16.6673C10.4606 16.6673 10.8337 16.2942 10.8337 15.834C10.8337 15.3737 10.4606 15.0007 10.0003 15.0007C9.54009 15.0007 9.16699 15.3737 9.16699 15.834C9.16699 16.2942 9.54009 16.6673 10.0003 16.6673Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DotsVerticalIco;
