import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

export default function FullLayout() {
  return (
    <Box padding="2rem">
      <Outlet />
    </Box>
  );
}
