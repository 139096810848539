import { Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from "../layout/AuthLayout";
import FullLayout from "../layout/FullLayout";
import PortalLayout from "../layout/PortalLayout";
import AuthLogin from "../modules/auth/login";
import ForgotPassword from "../modules/auth/login/forgot-password";
import ResetPassword from "../modules/auth/login/reset-password";
import Candidates from "../modules/candidates";
import CandidateView from "../modules/candidates/candidate-view";
import ClientPartner from "../modules/client-partner";
import AddClient from "../modules/client-partner/client/AddClient";
import AddVendor from "../modules/client-partner/vendor/AddVendor";
import Coversheet from "../modules/coversheet";
import Horizon from "../modules/horizon";
import Jobs from "../modules/jobs";
import CreateJob from "../modules/jobs/create-job";
import JobsBoard from "../modules/jobs/jobs-board";
import JobsCandidate from "../modules/jobs/jobs-candidate";
import JobsCandidateCompare from "../modules/jobs/jobs-candidate-compare";
import JobsSummary from "../modules/jobs/jobs-summary";
import JobsView from "../modules/jobs/jobs-view";
import QuickMatch from "../modules/quick-match";
import ResumeIntelligence from "../modules/resume-intelligence";
import CreateCandidateResume from "../modules/resume-intelligence/create-candidate-resume";
import AddUser from "../modules/user-management/add-user";
import UserManagement from "../modules/user-management/index";
import Weight from "../modules/weight";
import AgencyInformation from "../modules/agency-information";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PortalLayout />}>
        <Route index element={<Navigate to="/auth/login" />} />
        <Route path="/horizon" element={<Horizon />} />
        <Route path="/resume-intelligence" element={<ResumeIntelligence />} />
        <Route
          path="/resume-intelligence/create-candidate-resume"
          element={<CreateCandidateResume />}
        />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/jobs/jobs-view" element={<JobsView />} />
        <Route path="/create-jobs" element={<CreateJob />} />
        <Route path="/jobs/jobs-summary" element={<JobsSummary />} />
        <Route path="/jobs/jobs-candidate" element={<JobsCandidate />} />
        <Route
          path="/jobs/candidate/compare/:firstResumeId/:secondResumeId"
          element={<JobsCandidateCompare />}
        />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/user-management/user-add" element={<AddUser />} />
        <Route path="/candidates" element={<Candidates />} />

        <Route path="/jobs/jobs-board" element={<JobsBoard />} />
        <Route path="/candidates/candidate-view" element={<CandidateView />} />
        <Route path="/tools/quick-match" element={<QuickMatch />} />
        <Route path="/weight" element={<Weight />} />
        <Route path="/client-vendor" element={<ClientPartner />} />
        <Route path="/client-vendor/client/add" element={<AddClient />} />
        <Route path="/client-vendor/vendor/add" element={<AddVendor />} />
        <Route path="/agency-information" element={<AgencyInformation />} />

        <Route path="*" element={<> Not Found</>} />
      </Route>

      <Route path="/auth" element={<AuthLayout />}>
        <Route path="/auth/login" element={<AuthLogin />} />
        <Route path="login" element={<AuthLogin />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>

      <Route path="/" element={<FullLayout />}>
        <Route path="/coversheet" element={<Coversheet />} />
      </Route>
    </Routes>
  );
};

export default MainRoutes;
