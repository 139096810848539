export default function AgencyInformation() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 11.062V17.2797C2.5 18.582 2.5 19.2335 2.75886 19.731C2.98655 20.1685 3.34962 20.5239 3.79651 20.7468C4.30405 21 4.9688 21 6.29633 21H17.7037C19.0312 21 19.695 21 20.2025 20.7468C20.6494 20.5239 21.0137 20.1685 21.2414 19.731C21.5 19.234 21.5 18.5835 21.5 17.2838V11.062C21.5 10.4408 21.4994 10.1301 21.4223 9.84093C21.3539 9.5848 21.2417 9.34236 21.0898 9.12329C20.9184 8.87608 20.6802 8.67109 20.2025 8.26201L14.5025 3.379C13.6159 2.61947 13.1727 2.2399 12.6738 2.09545C12.2342 1.96818 11.7656 1.96818 11.326 2.09545C10.8275 2.23979 10.3848 2.61901 9.49955 3.37738L3.79766 8.26201C3.32014 8.67109 3.08192 8.87608 2.91052 9.12329C2.75863 9.34236 2.64553 9.5848 2.57715 9.84093C2.5 10.1301 2.5 10.4408 2.5 11.062Z"
        stroke="#667085"
        stroke-width="2.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6183 9.04198L11.9135 13.2716L11.2088 9.04198C11.1919 8.93971 11.1974 8.83498 11.2251 8.73507C11.2527 8.63516 11.3018 8.54246 11.3688 8.46341C11.4359 8.38436 11.5193 8.32086 11.6134 8.2773C11.7075 8.23374 11.8099 8.21118 11.9135 8.21118C12.0172 8.21118 12.1196 8.23374 12.2137 8.2773C12.3077 8.32086 12.3912 8.38436 12.4583 8.46341C12.5253 8.54246 12.5744 8.63516 12.602 8.73507C12.6296 8.83498 12.6352 8.93971 12.6183 9.04198Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9062 16.9271C12.2168 16.9271 12.4687 16.6753 12.4687 16.3647C12.4687 16.0541 12.2168 15.8022 11.9062 15.8022C11.5956 15.8022 11.3438 16.0541 11.3438 16.3647C11.3438 16.6753 11.5956 16.9271 11.9062 16.9271Z"
        fill="#667085"
        stroke="#667085"
        stroke-width="1.5"
      />
    </svg>
  );
}
