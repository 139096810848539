import { QUICKMATCH } from "../Action";

const intialState = {
  reviewMatch: [
    {
      candidate_name: "",
      score: "",
    },
  ],
};
const QuickMatchReducer = (state = intialState, action) => {
  switch (action.type) {
    case QUICKMATCH.reviewMatch:
      return { ...state, reviewMatch: action.data };
    default:
      return state;
  }
};
export default QuickMatchReducer;
