function BriefCaseIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.6663 4.66667C10.6663 4.04669 10.6663 3.7367 10.5982 3.48236C10.4133 2.79218 9.87416 2.25308 9.18398 2.06815C8.92965 2 8.61966 2 7.99967 2C7.37969 2 7.0697 2 6.81537 2.06815C6.12519 2.25308 5.58609 2.79218 5.40116 3.48236C5.33301 3.7367 5.33301 4.04669 5.33301 4.66667M3.46634 14H12.533C13.2797 14 13.6531 14 13.9383 13.8547C14.1892 13.7268 14.3932 13.5229 14.521 13.272C14.6663 12.9868 14.6663 12.6134 14.6663 11.8667V6.8C14.6663 6.05326 14.6663 5.6799 14.521 5.39468C14.3932 5.1438 14.1892 4.93982 13.9383 4.81199C13.6531 4.66667 13.2797 4.66667 12.533 4.66667H3.46634C2.7196 4.66667 2.34624 4.66667 2.06102 4.81199C1.81014 4.93982 1.60616 5.1438 1.47833 5.39468C1.33301 5.6799 1.33301 6.05326 1.33301 6.8V11.8667C1.33301 12.6134 1.33301 12.9868 1.47833 13.272C1.60616 13.5229 1.81014 13.7268 2.06102 13.8547C2.34624 14 2.7196 14 3.46634 14Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BriefCaseIco;
