import { LinearProgress, styled } from "@mui/material";
import React from "react";

const CustomLinearProgress = styled(LinearProgress)(
  ({
    height,
    borderRadius,
    backgroundColor,
    barbackgroundColor,
    dashedmarginTop,
    dashedbackgroundImage,
  }) => ({
    flex: 1,
    height: height || "8px",
    borderRadius: borderRadius || "4px",
    backgroundColor: backgroundColor || "#E0E0E0",
    "& .MuiLinearProgress-bar": {
      backgroundColor: barbackgroundColor || "#7F56D9",
    },

    "& .MuiLinearProgress-dashed": {
      marginTop: dashedmarginTop || "2px",
      backgroundImage:
        dashedbackgroundImage ||
        "radial-gradient(rgb(82 0 255) 0%, rgb(82 0 255) 16%, #00000000 42%)",
    },
  })
);

const CommonLinearProgress = ({
  variant,
  value,
  valueBuffer,
  height,
  borderRadius,
  backgroundColor,
  barColor,
  dashedmarginTop,
  dashedbackgroundImage,
  barbackgroundColor,
}) => {
  return (
    <CustomLinearProgress
      variant={variant}
      value={value}
      valueBuffer={valueBuffer}
      height={height}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      barColor={barColor}
      dashedmarginTop={dashedmarginTop}
      dashedbackgroundImage={dashedbackgroundImage}
      barbackgroundColor={barbackgroundColor}
    />
  );
};

export default CommonLinearProgress;
