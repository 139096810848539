import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import CommonButton from "../../component/Button";
import { StyledFlexRowView } from "./StyledComponents";

function CommonAccordion({
  title,
  children,
  onButtonClick,
  accordionButton = false,
  buttonTitle,
  loading,
  disabled,
  open,
  onChange,
  defaultOpen,
}) {
  return (
    <Accordion
      key={title}
      defaultExpanded={defaultOpen}
      expanded={open}
      onChange={onChange}
      sx={{
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id={`${title}`}
        sx={{
          "& .MuiAccordionSummary-expandIconWrapper": {
            border: "1px solid #D0D5DD",
            borderRadius: "8px",
            padding: "10px",
            pointerEvents: "all",
            cursor: "pointer",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px 20px 0px 0px !important",
            justifyContent: "space-between",
          },
          "& .MuiAccordionSummary-content": {
            margin: "0px 20px 0px 0px !important",
            justifyContent: "space-between",
          },
          padding: "0px !important",
          pointerEvents: "none",
        }}
        onClick={(e) => {
          if (!e.target.closest(".expand-icon")) {
            e.stopPropagation();
          }
        }}
      >
        <StyledFlexRowView style={{ width: "100%" }}>
          <Typography
            fontSize={"20px"}
            fontWeight={600}
            color={"#101828"}
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
          >
            {title}
          </Typography>
        </StyledFlexRowView>
        {accordionButton && (
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value={buttonTitle}
            padding="10px 16px"
            fullWidth
            onClick={(e) => {
              e.stopPropagation();
              onButtonClick && onButtonClick();
            }}
            customStyle={{
              pointerEvents: "all",
              cursor: "pointer",
            }}
            loading={loading}
            disabled={disabled}
          />
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px" }}>{children}</AccordionDetails>
    </Accordion>
  );
}

export default CommonAccordion;
