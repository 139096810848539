import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import RemoveMemberIcon from "../../../assets/images/removeMemberIcon.png";
import { StyledDialog } from "../../common-components/StyledComponents";
import CommonButton from "../../../component/Button";

const RemoveMember = ({ open, onClose, onApply, selectedUser, dialog }) => {
  return (
    <StyledDialog fullWidth maxWidth="xs" open={open}>
      <img
        src={RemoveMemberIcon}
        style={{ position: "absolute", padding: "16px", width: "80px" }}
      />
      <DialogActions>
        {/* <Button onClick={onClose}>
          <CloseIcon />
        </Button> */}
        <CommonButton
          onClick={onClose}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          color="#7F56D9"
          value={<CloseIcon />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {"Remove Team Member(s)"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid2 container direction="column" spacing={3} fontFamily={"Inter"}>
          {selectedUser &&
            selectedUser.length !== 0 &&
            selectedUser.map((user, index) => (
              <Grid2 key={user.id} container alignItems="center" spacing={2}>
                <Avatar alt={user.username} src={user.username} />
                <Typography variant="body1">{user.username}</Typography>
              </Grid2>
            ))}

          <CommonButton
            value={"Remove Team Member(s)"}
            onClick={onApply}
            fontWeight="600"
            background={"#D92D20"}
            width={"100%"}
            padding={"10px"}
            disabled={dialog.disabled}
            loading={dialog.removeLoading}
          />
          <Typography fontSize={"16px"} color="#667085">
            {"This action cannot be undone."}
          </Typography>
        </Grid2>
      </DialogContent>
    </StyledDialog>
  );
};

export default RemoveMember;
