import React from "react";
import CommonCard from "../../common-card.js/index.js";
import EditIndex from "../../common-card.js/Edit-Index.js";

const SecondaryInformation = ({
  SecondaryViewData,
  isLoading,
  alignment,
  autoCompleteData,
}) => {
  return (
    <>
      {alignment === "View" ? (
        <CommonCard viewData={SecondaryViewData} loading={isLoading} />
      ) : (
        <EditIndex
          editData={SecondaryViewData}
          loading={isLoading}
          autoCompleteData={autoCompleteData}
        />
      )}
    </>
  );
};
export default SecondaryInformation;
