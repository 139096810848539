import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import QuickMatchReducer from "../redux/reducer/QuickMatchReducer";
import JobReducer from "../redux/reducer/JobReducer";
import ResumeIntelligenceReducer from "../redux/reducer/ResumeIntelligence";
import CandidateReducer from "../redux/reducer/CandidateReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  quickMatchState: QuickMatchReducer,
  jobs: JobReducer,
  resumeIntelligence: ResumeIntelligenceReducer,
  candidate: CandidateReducer,
});

export default reducer;
