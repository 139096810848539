import { MenuItem, Switch, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import CommonTextField from "../../../component/Input";
import DropDownSelect from "../../../component/Select";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import { HandleSkeletion } from "../../../utils/constants";
import CommonPhoneInput from "../../../component/PhoneInput";
import { ErrorContent } from "../../jobs/style";

const MixedComponent = ({ editData, loading, rowSize = false, marginTop }) => {
  const { values, errors, setFieldValue, handleBlur } = useFormikContext();

  const [localValue, setLocalValue] = React.useState({
    organization_name: values.organization_name || "",
    display_name: values.display_name || "",
    vagency_idms: values.agency_id || "",
    primary_country: values.primary_country || "",
    primary_address: values.primary_address || "",
    email_address: values.email_address || "",
    contact_number: values.contact_number || "",
    website: values.website || "",
  });

  useEffect(() => {
    setLocalValue({
      organization_name: values.organization_name || "",
      display_name: values.display_name || "",
      vagency_idms: values.agency_id || "",
      primary_country: values.primary_country || "",
      primary_address: values.primary_address || "",
      email_address: values.email_address || "",
      contact_number: values.contact_number || "",
      website: values.website || "",
    });
  }, [values]);

  const debounceTimer = React.useRef(null);

  const handleInputChange = (e, item) => {
    const getValue = e.target.value;
    setLocalValue((prev) => ({
      ...prev,
      [item.titleName]: e.target.value,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setFieldValue(item.titleName, getValue);
    }, 300);
  };

  const CustomMenuItem = (item, index) => (
    <MenuItem value={item?.id} key={index}>
      {item?.name}
    </MenuItem>
  );

  const handleNumberChange = (e, countryData, item) => {
    const getValue = e;
    setLocalValue((prev) => ({
      ...prev,
      [item.titleName]: e,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setFieldValue(item.country, countryData);
      setFieldValue(item.titleName, getValue);
    }, 300);
  };
  return (
    <CommonGrid2
      container
      columnSpacing={3}
      rowSpacing={loading ? 0 : 3}
      marginTop={marginTop}
    >
      {editData?.map((item, index) => {
        return (
          <CommonGrid2
            item
            size={{
              xs: 12,
              md: item.type === "additional" || rowSize ? 6 : 4,
              sm: 6,
            }}
            key={index + item.title}
            gap={"5px"}
            display={"flex"}
            flexDirection={item.component === "switch" ? "row" : "column"}
            justifyContent={"flex-start"}
          >
            {loading ? (
              <HandleSkeletion height={100} />
            ) : (
              <>
                <CommonGrid2
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <CommonGrid2
                    display={"flex"}
                    alignItems={"center"}
                    width={"100%"}
                    gap={"10px"}
                  >
                    <Typography variant="p" color="#344054" fontSize="16px">
                      {item.title}
                    </Typography>

                    {item?.iconVisible && item?.content}
                  </CommonGrid2>
                  {item.type === "additional" && (
                    <Switch
                      checked={values[item.toggleName] || false}
                      onChange={(event) => {
                        item.handleChangeSwitch(
                          item.toggleName,
                          event.target.checked
                        );
                      }}
                    />
                  )}
                </CommonGrid2>
                {item.component === "input" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          placeholder={item?.placeholder || item.title}
                          padding="12px"
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={localValue[item.titleName]}
                          onChange={(e) => {
                            handleInputChange(e, item);
                          }}
                          disabled={item?.disabled}
                          placeholderFontSize={item?.placeholderFontSize}
                          startIcon={item.startIcon}
                        />
                      );
                    }}
                  </Field>
                )}

                {item.component === "dropDown" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleDropdownChange = (event) => {
                        form.setFieldValue(item.titleName, event.target.value);
                      };

                      return (
                        <DropDownSelect
                          marginTop="0px"
                          title={item.placeholder || item.title}
                          removeAnimation={true}
                          data={values[item.dropDownData]}
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          id={item.titleName}
                          value={values[item.titleName]}
                          handleChange={handleDropdownChange}
                          disabled={item?.disabled}
                          valueChange={item.valueChange}
                          CustomMenuItem={CustomMenuItem}
                          sizeMedium={"-5px"}
                          padding={"12px"}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "phone-number" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const isError = Boolean(
                        form.touched[item.titleName] &&
                          form.errors[item.titleName]
                      );
                      return (
                        <>
                          <CommonPhoneInput
                            placeholder={item?.placeholder}
                            padding="14px 0px 14px 60px"
                            country="us"
                            value={localValue[item.titleName]}
                            onChange={(e, countryData) => {
                              handleNumberChange(e, countryData, item);
                            }}
                          />
                          {isError && (
                            <ErrorContent
                              margin="-2px 0px 0px"
                              className="Mui-error"
                            >
                              {form.errors[item.titleName]}
                            </ErrorContent>
                          )}
                        </>
                      );
                    }}
                  </Field>
                )}
              </>
            )}
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
};
export default MixedComponent;
