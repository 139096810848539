export default function AnalysisIco() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="3.75" fill="#D1EFFF" />
      <path
        d="M11.3383 7.24034C11.6207 6.71104 12.3793 6.71104 12.6617 7.24034L13.98 9.71132C14.05 9.84255 14.1574 9.95 14.2887 10.02L16.7597 11.3383C17.289 11.6207 17.289 12.3793 16.7597 12.6617L14.2887 13.98C14.1574 14.05 14.05 14.1574 13.98 14.2887L12.6617 16.7597C12.3793 17.289 11.6207 17.289 11.3383 16.7597L10.02 14.2887C9.95 14.1574 9.84255 14.05 9.71132 13.98L7.24034 12.6617C6.71104 12.3793 6.71104 11.6207 7.24034 11.3383L9.71132 10.02C9.84255 9.95 9.95 9.84255 10.02 9.71132L11.3383 7.24034Z"
        fill="#0070F2"
      />
    </svg>
  );
}
