import { Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonButton from "../../../../component/Button";
import CommonTextField from "../../../../component/Input";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import { Field, useFormikContext } from "formik";
import { HandleSkeletion } from "../../../../utils/constants";

function General({ loading, getShow }) {
  const [general, setGeneral] = useState({
    username: "",
    password: "",
  });
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setGeneral({
      username: values.username || "",
      password: values.password || "",
    });
  }, [values]);

  const debounceTimer = React.useRef(null);

  const handleInputChange = (e, title) => {
    const getValue = e.target.value;
    setGeneral((prev) => ({
      ...prev,
      [title]: e.target.value,
    }));
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setFieldValue(title, getValue);
    }, 300);
  };

  return (
    <>
      <Grid2 container columnSpacing={3} rowSpacing={loading ? 0 : 3}>
        <Grid2 size={{ xs: 12, md: 6, sm: 12 }}>
          {loading ? (
            <HandleSkeletion height={100} />
          ) : (
            <CommonGrid2 container spacing={1} flexDirection={"column"}>
              <Typography variant="p" fontSize={"16px"} color={"#344054"}>
                {"Username"}
              </Typography>

              <CommonGrid2 container spacing={2}>
                <CommonGrid2 item size={{ xs: 12, md: 8, sm: 8 }}>
                  <Field name={"username"}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          fullWidth
                          padding="12px"
                          error={Boolean(
                            form.touched["username"] && form.errors["username"]
                          )}
                          helperText={
                            form.touched["username"] && form.errors["username"]
                          }
                          disabled={getShow === "view"}
                          placeholder="Enter Username"
                          placeholderFontSize="15px"
                          value={general.username}
                          borderRadius={"8px"}
                          backgroundColor={"#FFFFFF"}
                          border={"1px solid #D0D5DD"}
                          onChange={(e) => handleInputChange(e, "username")}
                        />
                      );
                    }}
                  </Field>
                </CommonGrid2>
                <CommonGrid2 item size={{ xs: 12, md: 4, sm: 4 }}>
                  <CommonButton
                    fontWeight={"600"}
                    width={"100%"}
                    variant="outlined"
                    color={"#344054"}
                    padding={"10px"}
                    value={"Change Username"}
                    border={"#D0D5DD"}
                    disabled={true}
                  />
                </CommonGrid2>
              </CommonGrid2>
            </CommonGrid2>
          )}
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6, sm: 12 }}>
          {loading ? (
            <HandleSkeletion height={100} />
          ) : (
            <CommonGrid2 container spacing={1} flexDirection={"column"}>
              <Typography variant="p" fontSize={"16px"} color={"#344054"}>
                {"Password"}
              </Typography>

              <CommonGrid2 container spacing={2}>
                <CommonGrid2 item size={{ xs: 12, md: 8, sm: 8 }}>
                  <Field name={"password"}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          fullWidth
                          disabled={getShow === "view"}
                          placeholder="Enter Password"
                          padding="12px"
                          error={Boolean(
                            form.touched["password"] && form.errors["password"]
                          )}
                          helperText={
                            form.touched["password"] && form.errors["password"]
                          }
                          placeholderFontSize="15px"
                          value={general.password}
                          borderRadius={"8px"}
                          backgroundColor={"#FFFFFF"}
                          border={"1px solid #D0D5DD"}
                          onChange={(e) => handleInputChange(e, "password")}
                        />
                      );
                    }}
                  </Field>
                </CommonGrid2>
                <CommonGrid2 item size={{ xs: 12, md: 4, sm: 4 }}>
                  <CommonButton
                    fontWeight={"600"}
                    width={"100%"}
                    variant="outlined"
                    color={"#344054"}
                    padding={"10px"}
                    value={"Change Password"}
                    border={"#D0D5DD"}
                    disabled={true}
                  />
                </CommonGrid2>
              </CommonGrid2>
            </CommonGrid2>
          )}
        </Grid2>
      </Grid2>
    </>
  );
}

export default General;
