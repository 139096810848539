import { Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReviewCandidateProfile from "../../resume-intelligence/create-candidate-resume/components/ReviewCandidateProfile";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CandidateViewCall } from "../../../redux/action/Candidate";
import SnackBar from "../../../component/SnackBar";
import { ResumeIntelligenceFix } from "../../../redux/action/ResumeIntelligence";

const CandidateView = () => {
  const [fixLoading, setFixLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const query = new URLSearchParams(useLocation().search);
  const viewId = query.get("view-id");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [resumeData, setResumeData] = useState({});

  const handleCandidateViewCall = async () => {
    setIsLoading(true);
    try {
      const data = await dispatch(CandidateViewCall(viewId));
      setResumeData(data);
      setIsLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.data?.message || error.message,
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleCandidateViewCall();
  }, []);

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleScoreFix = async () => {
    setFixLoading(true);
    try {
      const data = await ResumeIntelligenceFix(viewId);
      setFixLoading(false);
      setIsLoading(true);
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      const fixData = await dispatch(CandidateViewCall(viewId));
      setResumeData(fixData);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      setFixLoading(false);
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Typography fontSize={"24px"} fontWeight={600} marginBottom={"2rem"}>
          {"Resume Intelligence Engine Module"}
        </Typography>
      </Grid2>
      <ReviewCandidateProfile
        resumeData={resumeData}
        isLoading={isLoading}
        fixLoading={fixLoading}
        handleScoreFix={handleScoreFix}
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </Grid2>
  );
};
export default CandidateView;
