import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const CustomPaper = styled(Paper)(
  ({ marginBottom, padding, width, borderRadius }) => ({
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    borderRadius: borderRadius || "12px",
    border: "1px solid #EAECF0 !important",
    marginBottom: marginBottom,
    padding: padding,
    width: width,
  })
);
