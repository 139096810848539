import React from "react";
import CommonCard from "../../common-card.js";
import EditIndex from "../../common-card.js/Edit-Index.js";
const interviewStages = [
  { label: "First Touch", value: "Interview with Hiring Manager" },
  { label: "Second Touch", value: "Panel Video Interview" },
  { label: "Third Touch", value: "N/A" },
  { label: "Fourth Touch", value: "N/A" },
];
function InterviewSettings({
  InterviewSettingData,
  isLoading,
  alignment,
  rowSize,
}) {
  return (
    <>
      {alignment === "View" ? (
        <CommonCard
          viewData={InterviewSettingData}
          loading={isLoading}
          rowSize={rowSize}
        />
      ) : (
        <EditIndex editData={InterviewSettingData} loading={isLoading} />
      )}
    </>
  );
}

export default InterviewSettings;
