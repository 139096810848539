import { Download } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid2,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  addJdNotes,
  deleteJdNote,
  fixedJob,
  getJdNotes,
  getJobDataDetail,
  updateJdNote,
} from "../../../api/Api";
import ClipBoardCheck from "../../../assets/icons/ClipBoardCheckIco";
import DotsVerticalIco from "../../../assets/icons/DotsVerticalIco";
import JobPencilIco from "../../../assets/icons/JobPencilIco";
import JobPenIco from "../../../assets/icons/JobPenIco";
import JobStarIco from "../../../assets/icons/JobStarIco";
import ShareIco from "../../../assets/icons/ShareIco";
import Image from "../../../assets/images/JobPrimary.png";
import CommonButton from "../../../component/Button";
import CommonLinearProgress from "../../../component/Linearprogress";
import SnackBar from "../../../component/SnackBar";
import ToogleButton from "../../../component/ToogleButton";
import {
  checkBackGroundProcess,
  JobJobEdit,
  JobJobFormikData,
  JobJobReScore,
  JobJobShare,
  JobJobSubmit,
} from "../../../redux/action/Job";
import { DELETE, GET, POST, PUT } from "../../../redux/axios/Axios";
import { getFileIcon, HandleSkeletion } from "../../../utils/constants";
import CreateNote from "../../common-components/CreateNote";
import NewDeleteNote from "../../common-components/NewDeleteNote";
import {
  CommonGrid2,
  CustomAccordion,
  StyledFlexRowView,
} from "../../common-components/StyledComponents";
import ShareQuickMatch from "../../quick-match/components/ShareQuickMatch";
import {
  FileContainer,
  StyledLinearContainer,
} from "../../quick-match/components/StyledComponent";
import AddSeededDialog from "../create-job/components/AddSeededDialog";
import InterviewSettings from "../create-job/components/InterviewSettings";
import SeededPreScreen from "../create-job/components/SeededPreScreen";
import DialogCandidateHub from "../jobs-candidate/components/DialogCandidateHub";
import Notes from "./notes";
import { CommonTippy, CustomizeMenu, CustomizeSpan } from "../style";
import AddNewSkillDialog from "./Dialog";
import PrimaryInformation from "./primary-information";
import RelevancyTable from "./RelevancyTable";
import Result from "./result";
import SecondaryInformation from "./secondary-information";
import Skills from "./skills";
import SkillDeleteDialog from "./skills/SkillDeleteDialog";

const JobInformation = ({
  // jobData,
  jobId,
  handleDownloadJdOpen,
  fetchData,
  handleClick,
  saveEditData,
  resumeTab,
}) => {
  const ToogleData = [
    { name: "View", value: "View" },
    { name: "Edit", value: "Edit" },
  ];
  const [alignment, setAlignment] = React.useState("View");
  const [skillDialog, setSkillDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    skill: "",
    experience_in_yrs: "",
    criticality_score: "",
    title: "Create",
    classificationData: [],
    classification: false,
    classificationDataValue: "",
    key: "",
  });
  const [noteList, setNoteList] = useState({
    open: false,
    laoding: false,
    noteId: "",
    type: "",
    note: "",
    created_at: "",
    job_history_reason: "",
    accecpting_less_more_candidates: "",
    locals_only: "",
    hybrid_days: "",
    project_scope: "",
    other_details: "",
    deadline_start: "",
    deadline_end: "",
  });

  const [deleteNoteDialogOpen, setDeleteNoteDialogOpen] = useState(false);
  const [notesTableList, setNotesTableList] = useState([]);
  const [notesTableListLoading, setNotesTableListLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const JobJobEditData = useSelector((state) => state.jobs.jobJobEdit);
  const JobEditData = useSelector((state) => state.jobs.jobJobEditData);
  const [jobData, setJobData] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  let dates = moment(new Date());
  let today = dates.format("L");
  const typeData = [
    { name: "Standard", id: "Blank" },
    { name: "Supplier Call", id: "Supplier Call" },
  ];
  const [shareDialogOpen, setShareDialogOpen] = useState({
    open: false,
    loading: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const [files, setFiles] = useState([]);
  const [reScore, setReScore] = useState({
    disabled: false,
    noLoading: false,
    yesLoading: false,
    rescore: false,
    reRunOpen: false,
    noteOpen: false,
    file: false,
  });

  const [seededDialog, setSeededDialog] = useState({
    deleteOpen: false,
    loading: false,
    values: "",
    index: "",
    content: "",
    addOpen: false,
  });
  const [fixLoader, setFixLoader] = useState(false);
  const [iconVisible, setIconVisible] = React.useState(false);
  const [backGroundprocess, setBackGroundprocess] = useState({
    open: false,
    openLoading: false,
    buttonLoading: false,
  });

  const handleToogleChange = (event, newAlignment) => {
    if (
      JSON.stringify(JobEditData.formikData) !==
      JSON.stringify(JobEditData.jobData)
    ) {
      handleClick();
    } else if (newAlignment !== null) {
      if (newAlignment === "Edit" && jobData?.unscored) {
        setReScore((prev) => ({
          ...prev,
          rescore: true,
          reRunOpen: false,
          file: false,
        }));
      } else setAlignment(newAlignment);
    }
  };

  const value = (value) => (value ? value : "-");

  const jobModifyData = {
    job_title: "",
    company_name: "",
    industry: [],
    available_industry: [],
    pay_rate: {
      max: "",
      min: "",
      currency: "USD",
    },
    management_level: "",
    available_management_level: [],
    employment: "",
    available_employment_types: [],
    Date_Range: {
      start_date: "",
      end_date: "",
    },
    criticalityHardData: [
      { name: "Familiar", value: "Familiar" },
      { name: "Hands-on", value: "Hands-on" },
      { name: "Expert", value: "Expert" },
    ],
    criticalitySoftData: [
      { name: "Novice", value: "Novice" },
      { name: "Proficient", value: "Proficient" },
      { name: "Expert", value: "Expert" },
    ],
    priorityData: [
      {
        name: "Medium",
        value: "Additional Qualities",
        key: "medium_priority",
      },
      {
        name: "High",
        value: "Complementary Skills",
        key: "low_priority",
      },
      {
        name: "Highest",
        value: "Core Skills",
        key: "high_priority",
      },
    ],
    skillType: [
      { name: "Hard Skill", value: "extracted_hard_skill" },
      { name: "Soft Skill", value: "extracted_soft_skill" },
    ],
    occupational_classification: "",
    available_occupational_classification: [],
    employeeTarget: "",

    autoCompleteValue: "",
    id: "",
    reports_to: "",
    req_id: "",
    bu_department_team: "",
    company_profile: "",
    available_company_profiles: [],
    education: "",
    specific_degree: "",
    available_education: "",
    dei: false,
    govt_security_clearance: false,
    languages: [],
    certificates: [],
    certificates_value: "",
    employer_targets: [],
    employer_targets_value: "",
    location: "",
    work_location_type: "",
    languages_value: "",
    location_targets: [],
    location_targets_value: "",
    shift: [],
    shift_value: "",
    skillData: {
      low_priority: [],
      high_priority: [],
      recommended: [],
      medium_priority: [],
    },
    score_certificates: false,
    score_education: false,
    score_employer_targets: false,
    score_hard_skills: false,
    score_languages: false,
    score_location_targets: false,
    score_soft_skills: false,
    score_visa: false,
    relevancy_index: [],
    interview_type: [],
    pre_screen_questions: [],
    available_visa_status: [],
    visa_status: [],
    relevancy_index_anomoly: "",
    industry_anomoly: "",
    company_profile_anomoly: "",
    management_level_anomoly: "",
    occupational_classification_anomoly: "",
    pay_anomoly: "",
    enable_dynamic_pre_screen_questions: false,
  };
  const [jobValue, setJobValue] = useState(jobModifyData);
  const [jobValidationCheck, setJobValidationCheck] = useState(jobModifyData);

  const statusOptions = [
    {
      name: "Internal Recruiter",
      value: "internal_recruiter",
    },
    { name: "External Recruiter", value: "external_recruiter" },
    { name: "External vendor", value: "external_vendor" },
  ];

  useEffect(() => {
    setExpandedIndex(accordionDataView.map((_, i) => i));
    if (alignment === "View") {
      // setIconVisible(true);
      fetchJobDataDetail(jobId);
    } else if (alignment === "Edit") {
      setJobValue(jobModifyData);
      fetchJobEdit(jobId);
    }
  }, [alignment]);

  useEffect(() => {
    fetchNotesTableList(jobId);
  }, [jobId]);

  useEffect(() => {
    if (saveEditData?.view) setAlignment("View");
  }, [saveEditData]);

  const handleDynmaicList = (data, image) => {
    return data?.flatMap((item) =>
      item?.content?.map((contentItem) => ({
        name: item?.name || "",
        content: contentItem,
        ...(image ? { image: image } : {}),
      }))
    );
  };

  const handleDynmaicListFormat = (data) => {
    return (
      data?.map((item) => ({
        name: item || "",
      })) || []
    );
  };

  useEffect(() => {
    if (Object.keys(JobJobEditData).length !== 0) {
      const getValue = {
        ...jobValue,
        job_title: JobJobEditData.job_title,
        company_name: JobJobEditData.company_name,
        industry: JobJobEditData.available_industry.filter((item) =>
          JobJobEditData.industry.some((check) => check === item.name)
        ),
        available_industry: JobJobEditData.available_industry,
        management_level: JobJobEditData.management_level,
        available_management_level: JobJobEditData.available_management_level,
        employment: JobJobEditData.employment,
        available_employment_types: JobJobEditData.available_employment_types,
        Date_Range: {
          start_date: JobJobEditData.start_date,
          end_date: JobJobEditData.end_date,
        },
        occupational_classification: JobJobEditData.occupational_classification,
        available_occupational_classification:
          JobJobEditData.available_occupational_classification,
        id: JobJobEditData.id,
        reports_to: JobJobEditData.reports_to,
        req_id: JobJobEditData.req_id,
        bu_department_team: JobJobEditData.bu_department_team,
        company_profile: JobJobEditData.company_profile,
        available_company_profiles: JobJobEditData.available_company_profiles,
        education: JobJobEditData.education,
        specific_degree: JobJobEditData.specific_degree,
        available_education: JobJobEditData.available_education,
        dei: JobJobEditData.dei,
        govt_security_clearance: JobJobEditData.govt_security_clearance,
        languages: handleDynmaicList(JobJobEditData.languages),
        certificates: handleDynmaicList(JobJobEditData.certificates),
        employer_targets: handleDynmaicList(JobJobEditData.employer_targets),
        location: JobJobEditData.location,
        work_location_type: JobJobEditData.work_location_type,
        location_targets: handleDynmaicList(JobJobEditData.location_targets),
        shift:
          JobJobEditData.shift.length === 0
            ? []
            : JobJobEditData?.shift?.map((item) => {
                return { content: item, id: "" };
              }),
        pay_rate: {
          ...JobJobEditData.pay_rate,
          max: JobJobEditData.pay_rate.max,
          min: JobJobEditData.pay_rate.min,
          currency: JobJobEditData.pay_rate.currency || "USD",
        },
        skillData: {
          low_priority: JobJobEditData.low_priority,
          high_priority: JobJobEditData.high_priority,
          recommended: JobJobEditData.recommended,
          medium_priority: JobJobEditData.medium_priority,
        },
        score_certificates: JobJobEditData.score_certificates,
        score_education: JobJobEditData.score_education,
        score_employer_targets: JobJobEditData.score_employer_targets,
        score_hard_skills: JobJobEditData.score_hard_skills,
        score_languages: JobJobEditData.score_languages,
        score_location_targets: JobJobEditData.score_location_targets,
        score_soft_skills: JobJobEditData.score_soft_skills,
        score_visa: JobJobEditData.score_visa,
        relevancy_index: JobJobEditData.relevancy_index,
        interview_type: JobJobEditData.interview_type,
        pre_screen_questions: JobJobEditData.pre_screen_questions,
        available_visa_status: JobJobEditData.available_visa_status,
        visa_status: JobJobEditData.visa_status,
        relevancy_index_anomoly: JobJobEditData?.relevancy_index_anomoly,
        industry_anomoly: JobJobEditData?.industry_anomoly,
        company_profile_anomoly: JobJobEditData?.company_profile_anomoly,
        management_level_anomoly: JobJobEditData?.management_level_anomoly,
        occupational_classification_anomoly:
          JobJobEditData?.occupational_classification_anomoly,
        pay_anomoly: JobJobEditData?.pay_anomoly,
        enable_dynamic_pre_screen_questions:
          JobJobEditData?.enable_dynamic_pre_screen_questions,
      };
      setJobValue(getValue);
      setJobValidationCheck(getValue);
    }
  }, [JobJobEditData]);

  const handleAddIcon = (viewData, editData, condition) => {
    const data =
      alignment === "Edit"
        ? editData?.anomaly_detected !== "no"
        : viewData?.anomaly_detected !== "no";

    return data ? (
      <Tooltip
        title={
          <CommonGrid2
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
          >
            {alignment === "Edit" ? editData?.anomaly : viewData?.anomaly}
          </CommonGrid2>
        }
        arrow
        placement="top"
      >
        <CustomizeSpan
          cursor="pointer"
          position="relative"
          top={condition.top}
          left={condition.left}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
              fill="#FEF0C7"
            />
            <g clip-path="url(#clip0_1_29287)">
              <path
                d="M11.9997 9.33325V11.9999M11.9997 14.6666H12.0063M18.6663 11.9999C18.6663 15.6818 15.6816 18.6666 11.9997 18.6666C8.31778 18.6666 5.33301 15.6818 5.33301 11.9999C5.33301 8.31802 8.31778 5.33325 11.9997 5.33325C15.6816 5.33325 18.6663 8.31802 18.6663 11.9999Z"
                stroke="#DC6803"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_29287">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(4 4)"
                />
              </clipPath>
            </defs>
          </svg>
        </CustomizeSpan>
      </Tooltip>
    ) : (
      ""
    );
  };

  const handleInputChange = (e, title) => {
    const getValue = e.target.value;
    setJobValue((prev) => ({
      ...prev,
      [title]: getValue,
    }));
  };

  const handleListChange = (value, title) => {
    setJobValue((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleCurrencyChange = (event, name, currency) => {
    const getValue = event.target.value;
    const updatedPayRate = {
      ...jobValue[name],
      [currency]: getValue,
    };
    setJobValue((prev) => ({
      ...prev,
      pay_rate: {
        ...updatedPayRate,
      },
    }));
  };

  const handleCurrencyDropdownChange = (event, name, currencyValue) => {
    const exchangeRate = 83;
    function convertUsdToInr(usdValue) {
      return usdValue * exchangeRate;
    }

    function convertInrToUsd(inrValue) {
      return inrValue / exchangeRate;
    }

    const updatedPayRate = {
      ...jobValue,
      [currencyValue]: event.target.value,
      min:
        event.target.value === "INR"
          ? convertUsdToInr(jobValue[name]["min"])
          : convertInrToUsd(jobValue[name]["min"]),
      max:
        event.target.value === "INR"
          ? convertUsdToInr(jobValue[name]["max"])
          : convertInrToUsd(jobValue[name]["max"]),
    };
    setJobValue((prev) => ({
      ...prev,
      pay_rate: {
        ...updatedPayRate,
      },
    }));
  };

  const handleChangeSwitch = (name, value) => {
    setJobValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropdownChange = (event, name) => {
    const getValue = event.target.value;
    setJobValue((prev) => ({
      ...prev,
      [name]: getValue,
    }));
  };

  const handleAutoCompleteInputChange = (value, name, setFieldValue) => {
    setFieldValue(name, value);
    setJobValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAutoCompleteKeyDown = (
    e,
    index,
    name,
    inputValue,
    setFieldValue
  ) => {
    const checkData = jobValue[name];
    const dataValue = e.target.value;

    if (e.key === "Tab") {
      e.preventDefault();
      setFieldValue(inputValue, "");
      if (dataValue !== "") {
        const data = {
          ...(name !== "shift" ? { name: "user_added" } : { id: index }),
          content: dataValue,
        };

        const formattedValues = [...checkData, data];
        setJobValue((prev) => ({
          ...prev,
          [inputValue]: "",
          [name]: formattedValues,
        }));
      }
    }
  };

  const handleAutoCompleteDelete = (option, name) => {
    const updatedValue = jobValue[name].filter((item, i) => item !== option);
    setJobValue((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));
  };

  const handleAutoCompleteChange = (e, newValue, name) => {
    const getValue = e.target.value;
    if (e?.key === "Backspace" && getValue === "") {
      const updatedValue = [...(jobValue[name] || [])];
      updatedValue.pop();
      setJobValue((prev) => ({
        ...prev,
        [name]: updatedValue,
      }));
    } else {
      const formattedValues = newValue.map((item, index) => {
        if (typeof item === "string") {
          return {
            ...(name !== "shift" ? { name: "user_added" } : { id: index }),
            content: item,
          };
        }
        return item;
      });
      setJobValue((prev) => ({
        ...prev,
        [name]: formattedValues,
      }));
    }
  };

  const handleAutoMultiChange = (e, newValue, name) => {
    setJobValue((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleDatePicker = (name, start_date, end_date) => {
    setJobValue((prev) => ({
      ...prev,
      [name]: {
        start_date: start_date,
        end_date: end_date,
      },
    }));
  };

  const primaryViewData = [
    {
      title: "Title",
      name: value(jobData?.job_details?.job_title),
      component: "input",
      titleName: "job_title",
      editValue: jobValue,
      onChange: handleInputChange,
    },
    {
      title: "Company",
      name: value(jobData?.job_details?.company_name),
      component: "input",
      titleName: "company_name",
      editValue: jobValue,
      onChange: handleInputChange,
    },
    {
      title: "Industry",
      additional: handleDynmaicListFormat(jobData?.job_details?.industry),
      component: "multi-complete",
      titleName: "industry",
      options: "available_industry",
      content: handleAddIcon(
        jobData?.job_details?.industry_anomoly,
        jobValue?.industry_anomoly,
        { top: "0px", left: "0px" }
      ),
      placeholder: "Select Industry",
      iconVisible: true,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoMultiChange,
    },
    {
      title:
        alignment === "View"
          ? "Hourly Target Rate"
          : "Minimal Hourly Target Rate",
      name: value(jobData?.job_details?.pay_rate),
      component: "input-select",
      titleName: "pay_rate",
      currency: "min",
      currencyValue: "currency",
      content: handleAddIcon(
        jobData?.job_details?.pay_anomoly,
        jobValue?.pay_anomoly,
        { top: "0px", left: "0px" }
      ),
      handleCurrencyChange: handleCurrencyChange,
      handleCurrencyDropdownChange: handleCurrencyDropdownChange,
      iconVisible: true,
    },
    ...(alignment === "Edit"
      ? [
          {
            title: "Maximal Hourly Target Rate",
            name: value(jobData?.job_details?.pay_rate),
            component: "input-select",
            titleName: "pay_rate",
            currency: "max",
            currencyValue: "currency",
            content: handleAddIcon(
              jobData?.job_details?.pay_anomoly,
              jobValue?.pay_anomoly,
              { top: "0px", left: "0px" }
            ),
            iconVisible: true,
            handleCurrencyChange: handleCurrencyChange,
            handleCurrencyDropdownChange: handleCurrencyDropdownChange,
          },
        ]
      : []),
    {
      title: "Seniority Level",
      name: value(jobData?.job_details?.management_level),
      component: "dropDown",
      titleName: "management_level",
      dropDownData: "available_management_level",
      content: handleAddIcon(
        jobData?.job_details?.management_level_anomoly,
        jobValue?.management_level_anomoly,
        { top: "0px", left: "0px" }
      ),
      iconVisible: true,
      handleDropdownChange: handleDropdownChange,
    },
    {
      title: "Employment Type",
      name: value(jobData?.job_details?.employment),
      component: "dropDown",
      value: jobValue.employment,
      data: jobValue.available_employment_types,
      titleName: "employment",
      dropDownData: "available_employment_types",
      handleDropdownChange: handleDropdownChange,
    },

    {
      component: "split-part",
      data: [
        {
          title: "Location",
          // image: jobData?.job_details?.location ? Image : null,
          name: value(jobData?.job_details?.location),
          component: "input",
          titleName: "location",
          editValue: jobValue,
          onChange: handleInputChange,
        },
        {
          title: "Work Location Type",
          name: value(jobData?.job_details?.work_location_type),
          component: "input",
          titleName: "work_location_type",
          editValue: jobValue,
          onChange: handleInputChange,
        },
      ],
    },

    ...(alignment === "View"
      ? [
          {
            title: "Start Date",
            name: value(jobData?.job_details?.start_date),
          },
          {
            title: "End Date",
            name: value(jobData?.job_details?.end_date),
          },
        ]
      : [
          {
            title: "Date Range",
            name: "Oct 30 2024",
            component: "calender",
            titleName: "Date_Range",
            handleDatePicker: handleDatePicker,
          },
        ]),
  ];

  const SecondaryViewData = [
    {
      title: "Occupational Classification",
      name: value(jobData?.job_details?.occupational_classification),
      component: "dropDown",
      titleName: "occupational_classification",
      dropDownData: "available_occupational_classification",
      content: handleAddIcon(
        jobData?.job_details?.occupational_classification_anomoly,
        jobValue?.occupational_classification_anomoly,
        { top: "0px", left: "0px" }
      ),
      iconVisible: true,
      handleDropdownChange: handleDropdownChange,
    },
    {
      title: "Req ID",
      name: value(jobData?.job_details?.req_id),
      component: "input",
      titleName: "req_id",
      onChange: handleInputChange,
    },
    {
      title: "Shift",
      shift: jobData?.job_details?.shift || [],
      component: "autoComplete",
      titleName: "shift",
      inputValue: "shift_value",
      placeholder: "Enter Shift",
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleAutoCompleteKeyDown: handleAutoCompleteKeyDown,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoCompleteChange,
    },
    {
      title: "Reports To",
      name: value(jobData?.job_details?.reports_to),
      component: "input",
      titleName: "reports_to",
      onChange: handleInputChange,
    },
    {
      title: "BU/Dept/Team",
      name: value(jobData?.job_details?.bu_department_team),
      component: "input",
      titleName: "bu_department_team",
      onChange: handleInputChange,
    },
    {
      title: "Company Profile",
      name: jobData?.job_details?.company_profile,
      component: "dropDown",
      titleName: "company_profile",
      dropDownData: "available_company_profiles",
      content: handleAddIcon(
        jobData?.job_details?.company_profile_anomoly,
        jobValue?.company_profile_anomoly,
        { top: "0px", left: "0px" }
      ),
      iconVisible: true,
      handleDropdownChange: handleDropdownChange,
    },
  ];
  const additionalData = [
    ...(alignment === "View"
      ? [
          {
            title: "Highest Level of Education",
            name: jobData?.job_details?.education,
            component: "dropDown",
            titleName: "education",
            dropDownData: "available_education",
            check: jobValue.score_education,
            toggleName: "score_education",
            type: "additional",
            handleChangeSwitch: handleChangeSwitch,
            handleDropdownChange: handleDropdownChange,
          },
          {
            title: "Specialization",
            name: jobData?.job_details?.specific_degree,
            type: "additional",
          },
        ]
      : [
          {
            component: "split-part",
            // handleChangeSwitch: handleChangeSwitch,
            // check: jobValue.score_education,
            // toggleName: "score_education",
            type: "additional",
            data: [
              {
                title: "Highest Level of Education",
                name: jobData?.job_details?.education,
                component: "dropDown",
                titleName: "education",
                dropDownData: "available_education",
                handleDropdownChange: handleDropdownChange,
              },
              {
                title: "Specialization",
                component: "input",
                titleName: "specific_degree",
                onChange: handleInputChange,
                handleChangeSwitch: handleChangeSwitch,
                check: jobValue.score_education,
                toggleName: "score_education",
                type: "additional",
              },
            ],
          },
        ]),
    {
      title: "Location Targets",
      multi_additional: handleDynmaicList(
        jobData?.job_details?.location_targets,
        Image
      ),
      component: "autoComplete",
      check: false,
      titleName: "location_targets",
      inputValue: "location_targets_value",
      placeholder: "Enter Location Targets",
      check: jobValue.score_location_targets,
      toggleName: "score_location_targets",
      type: "additional",
      handleChangeSwitch: handleChangeSwitch,
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleAutoCompleteKeyDown: handleAutoCompleteKeyDown,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoCompleteChange,
      content: (
        <CommonGrid2 gap={"10px"} display={"flex"}>
          <JobPencilIco />
          <JobStarIco />
          <JobPenIco />
        </CommonGrid2>
      ),
      iconVisible: iconVisible,
    },
    {
      title: "Certificates",
      multi_additional: handleDynmaicList(jobData?.job_details?.certificates),
      component: "autoComplete",
      check: false,
      titleName: "certificates",
      inputValue: "certificates_value",
      placeholder: "Enter Certificates",
      check: jobValue.score_certificates,
      toggleName: "score_certificates",
      type: "additional",
      handleChangeSwitch: handleChangeSwitch,
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleAutoCompleteKeyDown: handleAutoCompleteKeyDown,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoCompleteChange,
      content: (
        <CommonGrid2 gap={"10px"} display={"flex"}>
          <JobPencilIco />
          <JobStarIco />
          <JobPenIco />
        </CommonGrid2>
      ),
      iconVisible: iconVisible,
    },
    {
      title: "Employer Targets",
      component: "autoComplete",
      check: false,
      titleName: "employer_targets",
      inputValue: "employer_targets_value",
      placeholder: "Enter Employer Targets",
      multi_additional: handleDynmaicList(
        jobData?.job_details?.employer_targets
      ),
      check: jobValue.score_employer_targets,
      toggleName: "score_employer_targets",
      type: "additional",
      handleChangeSwitch: handleChangeSwitch,
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleAutoCompleteKeyDown: handleAutoCompleteKeyDown,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoCompleteChange,
      content: (
        <CommonGrid2 gap={"10px"} display={"flex"}>
          <JobPencilIco />
          <JobStarIco />
          <JobPenIco />
        </CommonGrid2>
      ),
      iconVisible: iconVisible,
    },
    {
      title: "Languages",
      multi_additional: handleDynmaicList(jobData?.job_details?.languages),
      component: "autoComplete",
      titleName: "languages",
      inputValue: "languages_value",
      placeholder: "Enter Languages",
      check: jobValue.score_languages,
      toggleName: "score_languages",
      type: "additional",
      handleChangeSwitch: handleChangeSwitch,
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleAutoCompleteKeyDown: handleAutoCompleteKeyDown,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoCompleteChange,
      content: (
        <CommonGrid2 gap={"10px"} display={"flex"}>
          <JobPencilIco />
          <JobStarIco />
          <JobPenIco />
        </CommonGrid2>
      ),
      iconVisible: iconVisible,
    },
    {
      title: "Visa Status",
      name: jobData?.job_details?.visa_employment_needs?.content,
      component: "multi-complete",
      titleName: "visa_status",
      options: "available_visa_status",
      check: jobValue.score_visa,
      toggleName: "score_visa",
      type: "additional",
      placeholder: "Select Visa Status",
      handleChangeSwitch: handleChangeSwitch,
      handleAutoCompleteInputChange: handleAutoCompleteInputChange,
      handleDelete: handleAutoCompleteDelete,
      handleAutoCompleteChange: handleAutoMultiChange,
    },
    {
      title: "Goverment / Security Classification",
      name: jobData?.job_details?.govt_security_clearance ? "Yes" : "NO",
      check: jobValue.govt_security_clearance,
      toggleName: "govt_security_clearance",
      type: "additional",
      handleChangeSwitch: handleChangeSwitch,
      size: true,
    },
    {
      title: "DEI",
      name: jobData?.job_details?.dei ? "Yes" : "NO",
      check: jobValue.dei,
      toggleName: "dei",
      size: true,
      type: "additional",
      handleChangeSwitch: handleChangeSwitch,
    },
  ];

  const headerData = [
    { name: "Skill" },
    { name: "Criticality" },
    { name: "Years" },
    ...(alignment === "Edit" ? [{ name: "" }] : []),
  ];

  const menuItemData = [
    {
      name: "USD",
    },
    { name: "INR" },
  ];

  const handleDeleteSkillRow = (data, values, type) => {
    setDialogData({
      key: type,
      data: data,
      values: values,
    });
    setDeleteDialog(true);
  };

  const handleClose = () => {
    setSkillDialog(false);
  };

  const handleAddNewSkill = (values) => {
    setSkillDialog(true);
    setDialogData({
      title: "Create",
      classification: true,
      // bucketData: jobValue.bucketData,
      experience_in_yrs: "",
      criticality_score: 0,
      skill: "",
      values: values,
    });
  };

  const handleSkillClick = async (values, form) => {
    setButtonLoading(true);
    setTimeout(() => {
      const skillDataKey = values.key;
      const currentSkillData = dialogData.values["skillData"][skillDataKey];
      const { classification, skill, ...rest } = values;

      const customizeType = {
        ...values,
        type: "user_added_hard_skill",
      };
      let updatedSkillData;
      updatedSkillData = [...currentSkillData, customizeType];

      const formData = {
        ...dialogData.values["skillData"],
        [skillDataKey]: updatedSkillData,
      };

      setJobValue((prev) => ({
        ...prev,
        skillData: {
          ...formData,
        },
      }));

      setSnackbarState({
        open: true,
        severity: "success",
        message: "updated Successfully",
      });
      setButtonLoading(false);
      setIsLoading(true);
      handleClose();
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteSkill = () => {
    setButtonLoading(true);
    let filterData;

    setTimeout(() => {
      filterData = dialogData.values["skillData"][dialogData.key].filter(
        (item) => item !== dialogData.data
      );

      const formData = {
        ...dialogData.values["skillData"],
        [dialogData.key]: filterData,
      };

      setJobValue((prev) => ({
        ...prev,
        skillData: {
          ...formData,
        },
      }));

      setSnackbarState({
        open: true,
        severity: "success",
        message: `${
          dialogData?.data?.classification
            ? dialogData?.data?.classification
            : dialogData.data.skill
        } Removed Successfully`,
      });
      setButtonLoading(false);
      handleDeleteDialogClose();
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const fetchJobDataDetail = async (jobId) => {
    try {
      setIsLoading(true);
      const res = await GET(getJobDataDetail(jobId));
      setJobData(res?.data);
      setIsLoading(false);
      handleRescoreClose();
      setExpandedIndex([]);
    } catch (error) {
      setIsLoading(false);
      handleRescoreClose();
      setExpandedIndex([]);
    }
  };

  const fetchJobsList = async (job_id) => {
    setFixLoader(true);
    setIsLoading(true);
    try {
      const response = await GET(fixedJob(job_id));
      setSnackbarState({
        open: true,
        message: response?.data?.message || "Fix applied successfully",
        severity: "success",
      });
      if (alignment === "Edit") {
        setJobValue(jobModifyData);
        await fetchJobEdit(jobId);
      } else {
        await fetchJobDataDetail(jobId);
      }
      setIsLoading(false);
      setFixLoader(false);
    } catch (err) {
      setSnackbarState({
        open: true,
        message: "An error occurred while applying the fix.",
        severity: "error",
      });

      setIsLoading(false);
      setFixLoader(false);
    }
  };

  //notes
  const handleClickAddNote = () => {
    setNoteList((prev) => ({
      ...prev,
      open: true,
      loading: false,
      noteId: "",
      type: "",
      note: "",
      created_at: "",
      job_history_reason: "",
      accecpting_less_more_candidates: "",
      locals_only: "",
      hybrid_days: "",
      project_scope: "",
      other_details: "",
      deadline_start: "",
      deadline_end: "",
    }));
  };

  const handleCloseNoteDialog = () => {
    setNoteList((prev) => ({
      ...prev,
      open: false,
      loading: false,
      noteId: "",
      type: "",
      note: "",
      created_at: "",
      job_history_reason: "",
      accecpting_less_more_candidates: "",
      locals_only: "",
      hybrid_days: "",
      project_scope: "",
      other_details: "",
      deadline_start: "",
      deadline_end: "",
    }));
  };

  const handleDeleteNoteClick = (row, index) => {
    setDeleteNoteDialogOpen(true);
    setNoteList((prev) => ({
      ...prev,
      open: false,
      loading: false,
      noteId: row?.id,
      type: row?.type,
      note: row?.note,
      created_at: row?.created_at,
      job_history_reason: "",
      accecpting_less_more_candidates: "",
      locals_only: "",
      hybrid_days: "",
      project_scope: "",
      other_details: "",
      deadline_start: "",
      deadline_end: "",
    }));
  };

  const handleDeleteNoteDialogClose = () => {
    setDeleteNoteDialogOpen(false);
    handleCloseNoteDialog();
  };

  const handleUpdateNoteClick = (row) => {
    setNoteList((prev) => ({
      ...prev,
      open: true,
      loading: false,
      noteId: row?.id,
      type: row?.type,
      note: row?.note,
      created_at: row?.created_at,
      job_history_reason: row?.job_history_reason,
      accecpting_less_more_candidates: row?.accecpting_less_more_candidates,
      locals_only: row?.locals_only,
      hybrid_days: row?.hybrid_days,
      project_scope: row?.project_scope,
      other_details: row?.note,
      deadline_start: row?.deadline_start,
      deadline_end: row?.deadline_end,
    }));
  };

  const fetchNotesTableList = async (jobId) => {
    setNotesTableListLoading(true);
    const res = await GET(getJdNotes(jobId));
    if (res) {
      setNotesTableList(res?.data);
      setNotesTableListLoading(false);
    }
  };

  const handleCreateNote = async (values) => {
    setNoteList((prev) => ({ ...prev, loading: true }));
    let payload;
    if (values.type === "Blank") {
      payload = {
        note: values.note,
        interaction: "Email",
        added_by: "username",
        type: values.type,
      };
    } else {
      payload = {
        note: values.other_details,
        type: values.type,
        added_by: "username",
        hybrid_days: values.hybrid_days,
        deadline_start: values["Date_range"]?.["deadline_start"],
        deadline_end: values["Date_range"]?.["deadline_end"],
        job_history_type: values.job_history_type,
        job_history_reason: values.job_history_reason,
        accecpting_less_more_candidates: values.accecpting_less_more_candidates,
        locals_only: values.locals_only,
        project_scope: values.project_scope,
      };
    }

    if (noteList.noteId) {
      try {
        const res = await PUT(updateJdNote(noteList.noteId), payload);
        setSnackbarState({
          open: true,
          severity: "success",
          message: res?.message,
        });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error?.message || "Something went wrong",
        });
      }
    } else {
      try {
        const res = await POST(addJdNotes(jobId), [payload]);
        setSnackbarState({
          open: true,
          severity: "success",
          message: res?.data?.message,
        });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error?.message || "Something went wrong",
        });
      }
    }
    handleCloseNoteDialog();
    setReScore((prev) => ({
      ...prev,
      rescore: true,
      noteOpen: true,
      file: false,
      reRunOpen: false,
    }));

    fetchNotesTableList(jobId);
  };

  const handleDeleteNoteById = async () => {
    setNoteList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await DELETE(deleteJdNote(noteList.noteId));
      setSnackbarState({
        open: true,
        severity: "success",
        message: res?.message,
      });
      fetchNotesTableList(jobId);
      handleDeleteNoteDialogClose();
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.message,
      });
      handleCloseNoteDialog();
    }
  };

  const fetchJobEdit = async (jobId) => {
    try {
      setIsLoading(true);
      const data = await dispatch(JobJobEdit(jobId));
      setIsLoading(false);
      setExpandedIndex([]);
    } catch (error) {
      setIsLoading(false);
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setExpandedIndex([]);
    }
  };

  const handleSkillToogleChange = (
    event,
    newAlignment,
    rowIndex,
    values,
    type
  ) => {
    if (newAlignment !== null) {
      const dataKey = type;

      const updatedSkillData = values?.["skillData"]?.[dataKey]?.map((row) =>
        Array.isArray(row?.skill)
          ? { ...row, skill: row.skill.map((skill) => ({ ...skill })) }
          : { ...row }
      );

      const rowToUpdate = updatedSkillData?.[rowIndex];
      if (rowToUpdate) {
        rowToUpdate["expertise_level"] = newAlignment;
      }

      setJobValue((prev) => ({
        ...prev,
        skillData: {
          ...values?.["skillData"],
          [dataKey]: updatedSkillData,
        },
      }));
      // setJobValue("skillData", {
      //   ...values?.["skillData"],
      //   [dataKey]: updatedSkillData,
      // });
    }
  };

  const handleSkillTextChange = (event, rowIndex, values, type, fieldName) => {
    const { value } = event.target;
    const getData = event.target.value;
    const dataKey = type;
    const updatedSkillData = values?.["skillData"]?.[dataKey]?.map((row) =>
      Array.isArray(row?.skill)
        ? { ...row, skill: row.skill.map((skill) => ({ ...skill })) }
        : { ...row }
    );

    const rowToUpdate = updatedSkillData?.[rowIndex];
    if (rowToUpdate) {
      rowToUpdate[fieldName] = getData;
    }

    setJobValue((prev) => ({
      ...prev,
      skillData: {
        ...values?.["skillData"],
        [dataKey]: updatedSkillData,
      },
    }));
  };

  const onSeededDragEnd = (result, setFieldValue, values) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;
    const update = [...values["pre_screen_questions"]];
    const [removed] = update.splice(source.index, 1);
    update.splice(destination.index, 0, removed);

    setFieldValue("pre_screen_questions", update);
    setJobValue((prev) => ({
      ...prev,
      pre_screen_questions: update,
    }));
  };

  const handleSeededDelete = (question, index, values) => {
    setSeededDialog({
      content: question,
      index: index,
      values: values,
      deleteOpen: true,
      loading: false,
      addOpen: false,
    });
  };

  const handleDeleteSeeded = () => {
    setSeededDialog((prev) => ({
      ...prev,
      loading: true,
    }));

    setTimeout(() => {
      const originalQuestions = [
        ...seededDialog?.values["pre_screen_questions"],
      ];
      const updatedQuestions = originalQuestions.filter(
        (_, i) => i !== seededDialog?.index
      );

      setJobValue((prev) => ({
        ...prev,
        pre_screen_questions: updatedQuestions,
      }));

      setSnackbarState({
        open: true,
        severity: "success",
        message: "Seeded Remvoved Successfully",
      });
      setIsLoading(true);
      handleSeededClose();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleSeededClose = () => {
    setSeededDialog({
      content: "",
      index: "",
      values: "",
      deleteOpen: false,
      loading: false,
      addOpen: false,
    });
  };
  const ordinalNumbers = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "sixth",
    "Seventh",
    "Eigth",
    "Ninth",
    "Tenth",
  ];
  const interviewTypes =
    alignment === "Edit"
      ? jobValue.interview_type
      : jobData?.job_details?.interview_type || [];
  const InterviewSettingData = interviewTypes.map((type, index) => ({
    title: `${ordinalNumbers[index] || `${index + 1}th`} Touch`,
    name: value(type),
    component: "input-list",
    titleName: "interview_type",
    onListChange: (value) => handleListChange(value, "interview_type"),
  }));

  const handleAddSeeded = (values) => {
    setSeededDialog((prev) => ({
      ...prev,
      addOpen: true,
      values: values,
    }));
  };

  const handleAddSubmit = (values) => {
    setSeededDialog((prev) => ({
      ...prev,
      loading: true,
    }));

    setTimeout(() => {
      const update = [...seededDialog.values["pre_screen_questions"]];
      const updatedQuestions = [...update, values.question];

      setJobValue((prev) => ({
        ...prev,
        ["pre_screen_questions"]: updatedQuestions,
      }));
      setSnackbarState({
        open: true,
        severity: "success",
        message: "Seeded Added Successfully",
      });
      setIsLoading(true);
      handleSeededClose();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const [noteHeaderData, setNoteHeaderData] = useState([
    { name: "Title", direction: "asc", sort: false, width: "50%" },
    { name: "Created By", direction: "asc", sort: false, width: "20%" },
    { name: "Date", direction: "asc", sort: false, width: "15%" },
    { width: "15%" },
  ]);

  const handleEnableDynamicCheckBox = (event) => {
    setJobValue((prev) => ({
      ...prev,
      enable_dynamic_pre_screen_questions: event.target.checked,
    }));
  };

  const accordionDataView = [
    {
      title: "Primary Information",
      component: (
        <PrimaryInformation
          primaryViewData={primaryViewData}
          alignment={alignment}
          isLoading={isLoading}
          menuItemData={menuItemData}
        />
      ),
    },
    {
      title: "Secondary Information",
      component: (
        <>
          <SecondaryInformation
            SecondaryViewData={SecondaryViewData}
            alignment={alignment}
            isLoading={isLoading}
          />
          <SecondaryInformation
            SecondaryViewData={additionalData}
            alignment={alignment}
            isLoading={isLoading}
            marginTop={"3rem"}
            viewMarginTop={"1rem"}
          />
        </>
      ),
    },
    {
      title: "Skills",
      component: (
        <CommonGrid2 spacing={5} container>
          <CommonGrid2 size={12}>
            <Skills
              highPriority={
                alignment === "Edit"
                  ? jobValue?.skillData?.high_priority
                  : jobData?.job_details?.high_priority
              }
              lowPriority={
                alignment === "Edit"
                  ? jobValue?.skillData?.low_priority
                  : jobData?.job_details?.low_priority
              }
              mediumPriority={
                alignment === "Edit"
                  ? jobValue?.skillData?.medium_priority
                  : jobData?.job_details?.medium_priority
              }
              recommended={
                alignment === "Edit"
                  ? jobValue?.skillData?.recommended
                  : jobData?.job_details?.recommended
              }
              headerData={headerData}
              alignment={alignment}
              handleDeleteSkillRow={handleDeleteSkillRow}
              handleAddNewSkill={handleAddNewSkill}
              jobModifyData={jobModifyData}
              handleSkillToogleChange={handleSkillToogleChange}
              handleSkillTextChange={handleSkillTextChange}
              isLoading={isLoading}
              iconVisible={iconVisible}
            />
          </CommonGrid2>
          {jobData &&
            jobData?.job_details &&
            jobData?.job_details?.interview_type?.length !== 0 && (
              <CommonGrid2 size={12}>
                <InterviewSettings
                  InterviewSettingData={InterviewSettingData}
                  alignment={alignment}
                  isLoading={isLoading}
                  rowSize={true}
                />
              </CommonGrid2>
            )}
          {(alignment === "Edit"
            ? true
            : jobData &&
              jobData?.job_details &&
              jobData?.job_details?.pre_screen_questions?.length !== 0) && (
            <CommonGrid2 size={12}>
              <SeededPreScreen
                alignment={alignment}
                preScreen={jobData?.job_details?.pre_screen_questions}
                onDragEnd={onSeededDragEnd}
                handleSeededDelete={handleSeededDelete}
                isLoading={isLoading}
                onListChange={handleListChange}
                handleAddSeeded={handleAddSeeded}
                handleEnableDynamicCheckBox={handleEnableDynamicCheckBox}
              />
            </CommonGrid2>
          )}
        </CommonGrid2>
      ),
    },
    {
      title: "Notes",
      component: (
        <Notes
          handleClickAddNote={handleClickAddNote}
          handleDeleteNoteClick={handleDeleteNoteClick}
          handleUpdateNoteClick={handleUpdateNoteClick}
          notesTableList={notesTableList}
          notesTableListLoading={notesTableListLoading || isLoading}
          isLoading={isLoading}
          headerData={noteHeaderData}
        />
      ),
    },
  ];

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleSubmitJob = async (values, actions) => {
    setIsLoading(true);
    setEditButtonLoading(true);

    const result = (valueData) => {
      if (!valueData) return [];

      const processedData = valueData.reduce((acc, { name, content }) => {
        const existing = acc.find((item) => item.name === name);
        if (existing) {
          existing.content.push(content);
        } else {
          acc.push({ name, content: [content] });
        }
        return acc;
      }, []);

      const extracted = processedData.find((item) => item.name === "extracted");
      const inferred = processedData.find((item) => item.name === "inferred");
      const userAdded = processedData.find(
        (item) => item.name === "user_added"
      );

      const resultData = [];
      if (!extracted) {
        resultData.push({ name: "extracted", content: [] });
      } else {
        resultData.push(extracted);
      }
      if (!inferred) {
        resultData.push({ name: "inferred", content: [] });
      } else {
        resultData.push(inferred);
      }
      const otherData = processedData.filter(
        (item) =>
          item.name !== "extracted" &&
          item.name !== "inferred" &&
          item.name !== "user_added"
      );
      resultData.push(...otherData);
      if (!userAdded) {
        resultData.push({ name: "user_added", content: [] });
      } else {
        resultData.push(userAdded);
      }
      return resultData;
    };

    const data = {
      ...JobJobEditData,
      ...values,
      start_date: values.Date_Range.start_date,
      end_date: values.Date_Range.end_date,
      employer_targets: result(values.employer_targets),
      certificates: result(values.certificates),
      languages: result(values.languages),
      shift: values.shift.map((item) => item.content),
      location_targets: result(values.location_targets),
      high_priority: values.skillData.high_priority,
      low_priority: values.skillData.low_priority,
      medium_priority: values.skillData.medium_priority,
      recommended: values.skillData.recommended,
      industry: values.industry.map((item) => item.name),
    };

    try {
      dispatch(JobJobFormikData(values, values));
      const jobEdit = await JobJobSubmit(jobValue.id, data);
      setSnackbarState({
        open: true,
        severity: "success",
        message: jobEdit.message,
      });
      setAlignment("View");
      // setIsLoading(false);
      setEditButtonLoading(false);
    } catch (error) {
      dispatch(JobJobFormikData(jobValue, values));
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setIsLoading(false);
      setEditButtonLoading(false);
    }
  };

  const [expandedIndex, setExpandedIndex] = useState(
    accordionDataView.map((_, i) => i)
  );

  const handleScrollToError = (data) => {
    setExpandedIndex((prev) => {
      const newExpandedIndex =
        data.Date_Range || data.pay_rate
          ? 0
          : data.location_targets
          ? 1
          : data.pre_screen_questions || data.skillData
          ? 2
          : null;

      return newExpandedIndex !== null && !prev.includes(newExpandedIndex)
        ? [...prev, newExpandedIndex]
        : prev;
    });

    setTimeout(() => {
      const errorElement = document.querySelector(".Mui-error");
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 300);
  };

  const Footer = () => {
    return (
      <CommonGrid2 display={"flex"} gap={"1rem"}>
        {reScore.reRunOpen ? (
          <CommonButton
            value={"Yes"}
            width={"100%"}
            padding={"10px"}
            marginTop={reScore.noteOpen ? "2rem" : "1rem"}
            fontWeight={"700"}
            fontSize={"15px"}
            disabled={reScore.disabled}
            loading={reScore.yesLoading}
            onClick={() => handleClickScore("re-run", false)}
          />
        ) : (
          <>
            <CommonButton
              value={"No"}
              variant="outlined"
              color="#344054"
              width={"100%"}
              padding={"10px"}
              marginTop={"2rem"}
              fontWeight={"700"}
              fontSize={"15px"}
              border={"#D0D5DD"}
              onClick={() =>
                handleClickScore("no", reScore.noteOpen ? false : true)
              }
              disabled={reScore.disabled}
              loading={reScore.noLoading}
            />

            <CommonButton
              value={"Yes"}
              width={"100%"}
              padding={"10px"}
              marginTop={"2rem"}
              fontWeight={"700"}
              fontSize={"15px"}
              disabled={reScore.disabled}
              loading={reScore.yesLoading}
              onClick={() =>
                handleClickScore("yes", reScore.noteOpen ? false : true)
              }
            />
          </>
        )}
      </CommonGrid2>
    );
  };

  const handleClickScore = async (condition, check) => {
    setReScore((prev) => ({
      ...prev,
      yesLoading: condition === "yes" || condition === "re-run" ? true : false,
      noLoading: condition === "no" ? true : false,
      disabled: true,
      file: condition == "yes" || condition === "re-run" ? true : false,
    }));
    setIsLoading(true);
    setFiles(
      resumeTab.map((item) => ({
        title: item.name,
        progress: 0,
        buffer: 0,
        type: item.file_name,
        name: item.file_name,
        visible: false,
      }))
    );

    let payload = {
      score: condition === "re-run" || condition === "yes" ? true : false,
    };

    try {
      const response = await JobJobReScore(jobId, payload);
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf("}\n")) !== -1) {
          const jsonString = buffer.slice(0, boundaryIndex + 1);
          buffer = buffer.slice(boundaryIndex + 1);
          const jsonResponse = JSON.parse(jsonString);
          try {
            processJsonResponse(jsonResponse, check, condition);
          } catch (e) {
            setFiles((prevFiles) =>
              prevFiles.map((f) =>
                f.title === jsonResponse.data.current_resume
                  ? {
                      ...f,
                      visible: true,
                    }
                  : { ...f }
              )
            );
            setSnackbarState({
              open: true,
              message: "Error parsing JSON",
              severity: "error",
            });
            setIsLoading(false);
            setReScore({
              noLoading: false,
              disabled: false,
              yesLoading: false,
              rescore: true,
              reRunOpen: condition === "re-run" ? true : false,
              noteOpen: condition === "re-run" ? false : true,
              file: condition == "yes" || condition === "re-run" ? true : false,
            });
          }
        }
      }

      if (buffer) {
        const jsonResponse = JSON.parse(buffer);
        try {
          processJsonResponse(jsonResponse, check, condition);
        } catch (e) {
          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.title === jsonResponse.data.current_resume
                ? {
                    ...f,
                    visible: true,
                    delete: true,
                  }
                : { ...f, delete: true }
            )
          );
          setSnackbarState({
            open: true,
            message: "Error parsing final JSON chunk",
            severity: "error",
          });
          setIsLoading(false);
          setReScore({
            noLoading: false,
            disabled: false,
            yesLoading: false,
            rescore: true,
            reRunOpen: condition === "re-run" ? true : false,
            noteOpen: condition === "re-run" ? false : true,
            file: condition == "yes" || condition === "re-run" ? true : false,
          });
        }
      }
    } catch (error) {
      setFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          visible: true,
          message: "Error during the request",
        }))
      );
      setSnackbarState({
        open: true,
        message: "Error during the request",
        severity: "error",
      });
      setIsLoading(false);
      setReScore({
        noLoading: false,
        disabled: false,
        yesLoading: false,
        rescore: true,
        reRunOpen: condition === "re-run" ? true : false,
        noteOpen: condition === "re-run" ? false : true,
        file: condition == "yes" || condition === "re-run" ? true : false,
      });
    }
  };

  const processJsonResponse = async (jsonResponse, check, condition) => {
    const { success, data, message } = jsonResponse;

    if (success && data?.percent !== undefined) {
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.title === data.current_resume
            ? {
                ...f,
                progress: data.percent,
                buffer: data.percent,
                visible: false,
              }
            : f
        )
      );
    } else if (data?.response) {
      if (check) {
        setAlignment("Edit");
      } else {
        await fetchData();
        await fetchJobDataDetail(jobId);
      }
      handleRescoreClose();
      setSnackbarState({
        open: true,
        severity: "success",
        message: message,
        // duration: 500,
      });
    } else if (!success) {
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.title === data.current_resume || data.length == 0
            ? {
                ...f,
                visible: true,
                message: message,
              }
            : { ...f, delete: true }
        )
      );
      setIsLoading(false);
      setReScore({
        noLoading: false,
        disabled: false,
        yesLoading: false,
        rescore: true,
        reRunOpen: condition === "re-run" ? true : false,
        noteOpen: condition === "re-run" ? false : true,
        file: condition == "yes" || condition === "re-run" ? true : false,
      });
    }
  };

  const handleRescoreClose = () => {
    setIsLoading(false);
    setReScore({
      noLoading: false,
      disabled: false,
      yesLoading: false,
      rescore: false,
      reRunOpen: false,
      noteOpen: false,
      file: false,
    });
  };

  const handleClickRerun = async () => {
    setBackGroundprocess((prev) => ({
      ...prev,
      openLoading: true,
    }));
    try {
      const data = await checkBackGroundProcess(jobId);
      if (!data?.background_process_status) {
        setReScore({
          noLoading: false,
          disabled: false,
          yesLoading: false,
          rescore: true,
          reRunOpen: true,
          noteOpen: false,
          file: false,
        });
      }
      setBackGroundprocess((prev) => ({
        ...prev,
        open: data?.background_process_status,
        openLoading: false,
      }));
    } catch (error) {
      setBackGroundprocess((prev) => ({
        ...prev,
        openLoading: false,
      }));
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };
  const resultHeaderData = [
    {
      name: "Relevancy Index",
      icon: handleAddIcon(
        jobData?.job_details?.relevancy_index_anomoly,
        jobValue.relevancy_index_anomoly,
        { top: "4px", left: "10px" }
      ),
    },
    {},
    { name: "Relevancy Score" },
    {},
  ];

  const onRelevancyDragEnd = (result, values, setFieldValue) => {
    const { source, destination } = result;
    if (!destination || destination.index === source.index) return;
    const reorderedCandidates = [...values["relevancy_index"]];
    const [removed] = reorderedCandidates.splice(source.index, 1);
    reorderedCandidates.splice(destination.index, 0, removed);
    setFieldValue("relevancy_index", reorderedCandidates);
    setJobValue((prev) => ({
      ...prev,
      relevancy_index: reorderedCandidates,
    }));
  };

  const noteUpperData = [
    {
      title: "Client",
      component: "input",
      fontSize: "15px",
      value: jobData?.job_details?.company_name,
      placeholderFontSize: "15px",
      placeholder: "Enter the name",
    },
    {
      title: "Date",
      component: "input",
      fontSize: "15px",
      value: moment(today).format("DD MMM YYYY"),
      placeholderFontSize: "15px",
      placeholder: "Enter the number",
    },
    {
      title: "Position",
      fontSize: "15px",
      value: jobData?.job_details?.job_title,
      placeholderFontSize: "15px",
      placeholder: "Enter H1B Holder Company Name",
    },
    {
      title: "Job ID",
      fontSize: "15px",
      value: jobData?.job_details?.req_id,
      placeholderFontSize: "15px",
      placeholder: "Enter H1B Holder Company Name",
    },
  ];
  const validationSchema = Yup.object().shape({
    pay_rate: Yup.object().shape({
      min: Yup.number()
        .typeError("Minimum pay rate must be a number")
        .min(0, "Minimum pay rate cannot be negative")
        .nullable(),
      max: Yup.number()
        .typeError("Maximum pay rate must be a number")
        .nullable()
        .when("min", (min, schema) =>
          min !== undefined && min !== null
            ? schema.moreThan(
                min,
                "Maximum pay rate must be greater than the minimum"
              )
            : schema
        ),
    }),
    // req_id: Yup.number().typeError("Req Id must be a number").nullable(),

    Date_Range: Yup.object()
      .nullable()
      .shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
      })
      .test(
        "start-end-date-not-same",
        "Start date and End date cannot be the same",
        function (value) {
          if (!value?.start_date || !value?.end_date) {
            return true;
          }
          return value.start_date !== value.end_date;
        }
      ),
    location_targets: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          content: Yup.string(),
          id: Yup.mixed(),
        })
      )
      .test(
        "unique-content",
        "Location content must be unique",
        function (value) {
          if (!value) return true;
          const contentSet = new Set();
          for (let location of value) {
            if (location.content) {
              const lowercasedContent = location.content.toLowerCase();
              if (contentSet.has(lowercasedContent)) {
                return false;
              }
              contentSet.add(lowercasedContent);
            }
          }
          return true;
        }
      ),
    pre_screen_questions: Yup.array()
      .of(
        Yup.string()
          .test(
            "no-empty-string",
            "Empty strings are not allowed",
            (value) => value !== "" && value !== undefined
          )
          .required("This field is required")
      )
      .nullable()
      .optional(),
    skillData: Yup.object({
      high_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      medium_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      low_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
    }),
  });

  useEffect(() => {
    setFiles(
      resumeTab.map((item) => ({
        title: item.name,
        progress: 0,
        buffer: 0,
        type: item.file_name,
        name: item.file_name,
        visible: false,
      }))
    );
  }, []);

  const WholeProgressView = () => {
    return (
      <CommonGrid2 marginTop={"1rem"}>
        {files?.map((file, index) => (
          <FileContainer
            key={index}
            elevation={1}
            gap={"1rem"}
            padding={"6px 10px 3px"}
            margin="16px 0px 0px"
            borderRadius="7px"
          >
            <Box display={"flex"} alignItems={"flex-start"} width={"20px"}>
              {getFileIcon(file)}
            </Box>

            <Box width={"100%"}>
              <Typography
                color="#344054"
                fontSize={"14px"}
                fontWeight={700}
                textAlign={"start"}
              >
                {file.title}
              </Typography>

              <Typography color="#475467" fontSize={"14px"} fontWeight={500}>
                {file.size}
              </Typography>
              {file.progress >= 0 && (
                <CommonTippy
                  content={file.message}
                  visible={file.visible}
                  interactive={true}
                  trigger="click"
                  hideOnClick={false}
                  zIndex={2}
                  color="red"
                >
                  <StyledLinearContainer>
                    <CommonLinearProgress
                      variant={
                        file.progress && !file.visible
                          ? "buffer"
                          : "determinate"
                      }
                      value={file.progress}
                      valueBuffer={file.buffer}
                      height={"6px"}
                      left="0px"
                      borderRadius={"2px"}
                      backgroundColor={"#E0E0E0"}
                      barbackgroundColor={file.visible ? "red" : "#7F56D9"}
                      dashedmarginTop={"1px"}
                      dashedbackgroundImage={
                        file.visible
                          ? "radial-gradient(rgb(255 46 0) 0%, rgb(255 0 0) 16%, #00000000 42%)"
                          : "radial-gradient(rgb(82 0 255) 0%, rgb(82 0 255) 16%, #00000000 42%)"
                      }
                    />

                    <Typography
                      color="#344054"
                      fontSize={"14px"}
                      fontWeight={500}
                    >
                      {file.progress}%
                    </Typography>
                  </StyledLinearContainer>
                </CommonTippy>
              )}
            </Box>
          </FileContainer>
        ))}
      </CommonGrid2>
    );
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: "Share",
      icon: <ShareIco />,
      disabled: isLoading,
      onClick: () => {
        handleShareClick();
        handleMenuClose();
      },
    },

    {
      label: "Add Note",
      disabled: isLoading,
      icon: <ClipBoardCheck />,
      onClick: () => {
        handleClickAddNote();
        handleMenuClose();
      },
    },
    {
      label: "Download",
      icon: <Download sx={{ fontSize: "16px" }} />,
      disabled: isLoading,
      onClick: () => {
        handleDownloadJdOpen();
        handleMenuClose();
      },
    },
  ];

  const handleShareClick = () => {
    setShareDialogOpen({ open: true, loading: false });
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen({ open: false, loading: false });
  };

  const handleShareInvite = async (shareData) => {
    setShareDialogOpen((prev) => ({ ...prev, loading: true }));
    try {
      let payload = {
        url: window.location.href,
        to_mail: shareData,
        cc_mail: [],
      };
      const data = await JobJobShare(payload, "Job", jobId);
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      handleShareDialogClose();
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setShareDialogOpen((prev) => ({ ...prev, loading: false }));
    }
  };

  const handlebackGroundWarningClose = () => {
    setBackGroundprocess({
      open: false,
      openLoading: false,
      buttonLoading: false,
    });
  };

  const handlebackGroundWarning = () => {
    setBackGroundprocess((prev) => ({
      ...prev,
      buttonLoading: true,
    }));
    setTimeout(() => {
      handlebackGroundWarningClose();
    }, 1000);
  };

  const BackGroundFooter = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"OK"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        onClick={handlebackGroundWarning}
        disabled={backGroundprocess.buttonLoading}
        loading={backGroundprocess.buttonLoading}
      />
    </CommonGrid2>
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (alignment === "Edit") {
        let getValues = {
          ...jobValidationCheck,
          shift_value: "",
          location_targets_value: "",
          certificates_value: "",
          employer_targets_value: "",
          languages_value: "",
        };
        dispatch(JobJobFormikData(jobValue, getValues));
      }
    }, 10);

    return () => {
      clearTimeout(handler);
    };
  }, [jobValue]);

  const CustomMenuItem = (item, index) => {
    return (
      <MenuItem value={item?.value} key={index}>
        {item?.name}
      </MenuItem>
    );
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={jobValue}
        validationSchema={validationSchema}
        // validateOnChange={false}
        // validateOnBlur={false}
        // validateOnMount={false}
        // validate={() => {}}
        onSubmit={async (values, actions) => {
          handleSubmitJob(values, actions);
        }}
      >
        {({ values, errors, validateForm }) => {
          console.log(values, "sfkjsnfjnsfjksfjnjnsf");
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Grid2 container spacing={2}>
                <CommonGrid2
                  size={{ xs: 12, md: 5, sm: 12 }}
                  item
                  alignItems={"center"}
                  display={"flex"}
                >
                  {isLoading ? (
                    <HandleSkeletion height={50} width={"60%"} />
                  ) : (
                    <Typography variant="h3">
                      {jobData?.job_details?.job_title}
                      {jobData?.job_details?.company_name &&
                        " - " + jobData?.job_details?.company_name}
                      {jobData?.job_details?.req_id &&
                        " - " + jobData?.job_details?.req_id}
                    </Typography>
                  )}
                </CommonGrid2>
                <CommonGrid2
                  item
                  size={{ xs: 12, md: 7, sm: 12 }}
                  gap="10px"
                  display="flex"
                  justifyContent={"end"}
                  height={"fit-content"}
                >
                  <CommonGrid2
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                    marginRight={"10px"}
                  >
                    <Typography variant="h6">Vision Assist</Typography>
                    <Switch
                      checked={iconVisible}
                      onChange={(event) => setIconVisible(event.target.checked)}
                    />
                  </CommonGrid2>
                  {alignment === "Edit" && (
                    <CommonButton
                      value="Submit"
                      padding="5px 2rem"
                      fontWeight="600"
                      type="submit"
                      disabled={
                        editButtonLoading ||
                        isLoading ||
                        JSON.stringify(JobEditData.formikData) ===
                          JSON.stringify(JobEditData.jobData)
                      }
                      loading={editButtonLoading}
                      onClick={async () => {
                        let data = await validateForm();
                        setTimeout(() => {
                          handleScrollToError(data);
                        }, 0);
                      }}
                    />
                  )}

                  {(alignment === "View"
                    ? jobData?.show_rescore
                    : alignment !== "Edit") && (
                    <CommonButton
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      fontWeight="600"
                      value="Rerun Scoring"
                      padding="0px 20px"
                      disabled={isLoading || backGroundprocess.openLoading}
                      loading={backGroundprocess.openLoading}
                      onClick={() => handleClickRerun()}
                    />
                  )}

                  <ToogleButton
                    ToogleData={ToogleData}
                    handleChange={handleToogleChange}
                    alignment={alignment}
                    border={"1px solid #D0D5DD"}
                    borderRadius={"8px"}
                    activeColor={"#344054"}
                    color={"#344054"}
                    activeBackgroundColor="#F9FAFB"
                    backgroundColor="#FFFF"
                    disabled={isLoading}
                  />
                  <div>
                    <CommonButton
                      value={<DotsVerticalIco />}
                      padding={"13px 14px"}
                      variant="outlined"
                      color={"#344054"}
                      border={"#D0D5DD"}
                      fontWeight={"600"}
                      svg={true}
                      aria-controls={menuOpen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={menuOpen ? "true" : undefined}
                      onClick={handleMenuClick}
                      minWidth={"20px !important"}
                    />

                    <CustomizeMenu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={handleMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {menuItems.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={item.disabled ? () => {} : item.onClick}
                          aria-controls={item.ariaControls}
                          aria-haspopup={item.ariaHasPopup}
                          aria-expanded={item.ariaExpanded}
                          style={{ opacity: item.disabled ? 0.5 : 1 }}
                          disabled={item?.disabled}
                        >
                          <StyledFlexRowView>
                            {item.icon}
                            <Typography>{item.label}</Typography>
                          </StyledFlexRowView>
                        </MenuItem>
                      ))}
                    </CustomizeMenu>
                  </div>
                </CommonGrid2>
              </Grid2>
              {(alignment === "View" ||
                (alignment === "Edit" && JobJobEditData.is_scored)) && (
                <Result
                  jobData={
                    alignment === "View" ? jobData?.job_details : JobJobEditData
                  }
                  isLoading={fixLoader ? false : isLoading}
                  fixLoader={fixLoader}
                  fetchJobsList={fetchJobsList}
                  job_id={jobId}
                  toolTipOpen={
                    fixLoader ? false : alignment === "View" ? true : false
                  }
                />
              )}
              {(alignment === "View"
                ? jobData?.job_details?.relevancy_index !== null
                : values["relevancy_index"] !== null) && (
                <Grid2 marginTop={"3rem"}>
                  <RelevancyTable
                    alignment={alignment}
                    isLoading={isLoading}
                    resultHeaderData={resultHeaderData}
                    onDragEnd={onRelevancyDragEnd}
                    dragContent={
                      alignment === "View"
                        ? jobData?.job_details?.relevancy_index
                        : values["relevancy_index"]
                    }
                    iconVisible={iconVisible}
                  />
                </Grid2>
              )}
              {accordionDataView.map((item, index) => {
                return (
                  <CustomAccordion
                    key={index}
                    expanded={expandedIndex.includes(index)}
                    onChange={() =>
                      setExpandedIndex((prev) =>
                        prev.includes(index)
                          ? prev.filter((i) => i !== index)
                          : [...prev, index]
                      )
                    }
                    // borderBottom={"1px solid #EAECF0"}
                  >
                    <AccordionSummary
                      expandIcon={<KeyboardArrowDownIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography
                        variant="h4"
                        margin={"15px 0px"}
                        fontWeight={600}
                      >
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.component}</AccordionDetails>
                  </CustomAccordion>
                );
              })}

              <AddNewSkillDialog
                open={skillDialog}
                handleClose={handleClose}
                jobModifyData={jobModifyData}
                handleSkillClick={handleSkillClick}
                buttonLoading={buttonLoading}
              />
              <SkillDeleteDialog
                deleteDialog={deleteDialog}
                handleDeleteDialogClose={handleDeleteDialogClose}
                handleDeleteSkill={handleDeleteSkill}
                buttonLoading={buttonLoading}
                title="Delete Skill"
                content={dialogData?.data?.skill}
              />

              <SkillDeleteDialog
                deleteDialog={seededDialog.deleteOpen}
                handleDeleteDialogClose={handleSeededClose}
                handleDeleteSkill={handleDeleteSeeded}
                buttonLoading={seededDialog.loading}
                title="Delete Seeded Pre-Screen"
                content={seededDialog.content}
              />
              <AddSeededDialog
                open={seededDialog.addOpen}
                handleDialogClose={handleSeededClose}
                handleSubmit={handleAddSubmit}
                loading={seededDialog.loading}
              />
            </Form>
          );
        }}
      </Formik>

      <NewDeleteNote
        deleteNoteDialogOpen={deleteNoteDialogOpen}
        handleDeleteNoteDialogClose={handleDeleteNoteDialogClose}
        noteList={noteList}
        handleDeleteNoteById={handleDeleteNoteById}
        noteLoading={noteList.loading}
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <DialogCandidateHub
        open={reScore.rescore}
        // disabled={reScore.disabled}
        closeIcon={true}
        handleButttonClose={handleRescoreClose}
        footer={<Footer />}
        icon={""}
        titlepadding={"0px 0px 16px"}
        title="Re-Score"
        content={
          <>
            {reScore.noteOpen ? "Do you want to rescore?" : ""}
            {reScore?.file && WholeProgressView()}
          </>
        }
      />
      <CreateNote
        openNoteDialog={noteList.open}
        handleCloseNoteDialog={handleCloseNoteDialog}
        id={noteList.noteId}
        //
        noteUpperData={noteUpperData}
        typeData={typeData}
        noteList={noteList}
        handleNoteSubmit={handleCreateNote}
        loading={noteList.loading}
      />
      <ShareQuickMatch
        title="Share Job Description"
        shareDialogOpen={shareDialogOpen.open}
        handleShareDialogClose={handleShareDialogClose}
        handleShareInvite={handleShareInvite}
        isLoading={shareDialogOpen.loading}
        condition={true}
        statusOptions={statusOptions}
        CustomMenuItem={CustomMenuItem}
      />
      <DialogCandidateHub
        open={backGroundprocess.open}
        // icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        title="Processing"
        titlepadding={"0px 0px 16px"}
        handleClose={handlebackGroundWarningClose}
        footer={<BackGroundFooter />}
        content={
          "Some background Process is Still Running for this Job,Please Wait for Some time"
        }
      />
    </div>
  );
};
export default JobInformation;
