import {
  Autocomplete,
  Chip,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledFlexColumnView } from "../../../common-components/StyledComponents";
import ChevronDownIco from "../../../../assets/icons/ChevronDownIco";
import XCloseIco from "../../../../assets/icons/XCloseIco";

function OwnerManagerDetails() {
  return (
    <Grid2 container spacing={3}>
      <Grid2 size={6}>
        <StyledFlexColumnView>
          <Typography
            sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
          >
            {"Job Owner"}
          </Typography>
          <Autocomplete
            disablePortal
            options={[]}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                padding: "5px",
                border: "1px solid #D0D5DD",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#667085",
                opacity: 1,
                fontSize: "16px",
                fontWeight: 400,
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Job Owner"
                variant="outlined"
              />
            )}
            popupIcon={
              <IconButton sx={{ padding: 0 }}>
                <ChevronDownIco />
              </IconButton>
            }
          />
        </StyledFlexColumnView>
      </Grid2>

      <Grid2 size={6}>
        <StyledFlexColumnView>
          <Typography
            sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
          >
            {"Hiring Manager"}
          </Typography>
          <Autocomplete
            disablePortal
            options={[]}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                padding: "5px",
                border: "1px solid #D0D5DD",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#667085",
                opacity: 1,
                fontSize: "16px",
                fontWeight: 400,
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Hiring Manager"
                variant="outlined"
              />
            )}
            popupIcon={
              <IconButton sx={{ padding: 0 }}>
                <ChevronDownIco />
              </IconButton>
            }
          />
        </StyledFlexColumnView>
      </Grid2>

      <Grid2 size={6}>
        <StyledFlexColumnView>
          <Typography
            sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
          >
            {"Delegates"}
          </Typography>
          <Autocomplete
            multiple
            disablePortal
            options={[]}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                padding: "5px",
                border: "1px solid #D0D5DD",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#667085",
                opacity: 1,
                fontSize: "16px",
                fontWeight: 400,
              },
            }}
            renderInput={(params) => (
              <TextField
                multiline
                rows={5}
                {...params}
                fullWidth
                placeholder="Delegates"
                variant="outlined"
              />
            )}
            popupIcon={
              <IconButton sx={{ padding: 0, display: "none" }}>
                <ChevronDownIco />
              </IconButton>
            }
          />
        </StyledFlexColumnView>
      </Grid2>

      <Grid2 size={6}>
        <StyledFlexColumnView>
          <Typography
            sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
          >
            {"Hiring Manager Details"}
          </Typography>
          <Autocomplete
            multiple
            disablePortal
            options={["john@mail.com", "david@mail.com"]}
            defaultValue={["john@mail.com", "david@mail.com"]}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                padding: "5px",
                border: "1px solid #D0D5DD",
                alignItems: "flex-start",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiAutocomplete-tag": {
                marginBottom: "5px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#667085",
                opacity: 1,
                fontSize: "16px",
                fontWeight: 400,
              },
            }}
            renderInput={(params) => (
              <TextField
                multiline
                rows={5}
                {...params}
                fullWidth
                placeholder=""
                variant="outlined"
              />
            )}
            popupIcon={
              <IconButton sx={{ padding: 0, display: "none" }}>
                <ChevronDownIco />
              </IconButton>
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={option}
                  //   sx={{ padding: "1px" }}
                  {...getTagProps({ index })}
                  //   onDelete={() => handleRemove(option)}
                  deleteIcon={
                    <IconButton
                      sx={{
                        padding: "0px",
                        "& > svg": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                    >
                      <XCloseIco />
                    </IconButton>
                  }
                  sx={{
                    color: "#1E1F21",
                    gap: "12px",
                    borderRadius: "6px",
                    border: "1px solid #D0D5DD",
                    padding: "2px 2px 2px 6px !important",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    "& > svg": {
                      width: "14px",
                      height: "14px",
                    },
                  }}
                />
              ))
            }
          />
        </StyledFlexColumnView>
      </Grid2>
    </Grid2>
  );
}

export default OwnerManagerDetails;
