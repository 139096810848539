import { URL } from "../../api/Api";
import { catchError, POST, PUT } from "../axios/Axios";

export const LoginCall = async (payload) => {
  try {
    const response = await fetch(`${URL}/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorResponse = await response.text();
      let errorMessage = "An error occurred";
      try {
        const parsedError = JSON.parse(errorResponse);
        errorMessage = parsedError.detail || errorMessage;
      } catch (parseError) {}
      throw new Error(errorMessage);
    }

    return response.json();
  } catch (error) {
    throw catchError(error);
  }
};

//Forgot-password
export const ForgotPasswordCall = async (payload, params) => {
  try {
    const response = await POST(`${URL}/customers/password/`, payload, params);
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

//Reset-password
export const ResetPasswordCall = async (id, payload) => {
  try {
    const response = await PUT(`${URL}/customers/password/${id}`, payload, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
