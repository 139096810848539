import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React from "react";
import CommonButton from "../../../component/Button";
import { TableContainerBorder } from "../../common-components/StyledComponents";

const JobTable = ({
  sortedData,
  headerData,
  handleTableBodyData,
  sortItem,
  currentPage,
  handlePrevious,
  handleNext,
  setCurrentPage,
  totalPage
}) => {
  const startIndex = (currentPage - 1) * totalPage;
  const endIndex = startIndex + totalPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);
  return (
    <TableContainerBorder>
      <Table>
        <TableHead>
          <TableRow>
            {headerData.map((item, index) => {
              return (
                <TableCell key={index}>
                  {sortItem.includes(item) ? (
                    <TableSortLabel
                      active={sortItem.includes(item)}
                      direction={"asc"}
                    >
                      {item}
                    </TableSortLabel>
                  ) : (
                    item
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((row, index) => handleTableBodyData(row, index))}
        </TableBody>
      </Table>
      <Grid display={"flex"} justifyContent={"space-between"} sx={{ p: 2 }}>

        <CommonButton
          variant="outlined"
          border="#D0D5DD"
          color="#344054"
          fontWeight="600"
          value="Previous"
          padding="3px 10px"
          onClick={handlePrevious}
          disabled={currentPage === 1}
          startIcon={<ArrowBackIcon />}
        />
        <Pagination
          count={totalPage}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
          hidePrevButton
          hideNextButton
          shape="rounded"
        />
        <CommonButton
          variant="outlined"
          border="#D0D5DD"
          color="#344054"
          fontWeight="600"
          value="Next"
          padding="3px 10px"
          onClick={handleNext}
          endIcon={<ArrowForwardIcon />}
          disabled={currentPage === Math.ceil(sortedData.length / totalPage)}
        />
      </Grid>
    </TableContainerBorder>
  );
};

export default JobTable;
