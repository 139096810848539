import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import XCloseIco from "../../../../assets/icons/XCloseIco";
import DeleteNoteBackgroundImg from "../../../../assets/images/delete-note-background.png";
import CommonButton from "../../../../component/Button";

const SkillDeleteDialog = ({
  deleteDialog,
  handleDeleteDialogClose,
  handleDeleteSkill,
  buttonLoading,
  title,
  content,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={deleteDialog}
      onClose={handleDeleteDialogClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "400px",
          maxWidth: "none",
          borderRadius: "12px",
          background: "#FFF",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        },
      }}
    >
      <img
        src={DeleteNoteBackgroundImg}
        style={{ position: "absolute", width: "max-content", opacity: "0.9" }}
      />
      <DialogActions>
        <Button onClick={handleDeleteDialogClose} sx={{ marginTop: "10px" }}>
          <XCloseIco />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" marginTop={2}>
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid2 spacing={2} container>
          <Grid2 size={12}>
            <Typography
              color="#475467"
              fontSize={16}
              fontFamily={"Inter"}
              fontWeight={500}
              fontStyle={"italic"}
            >
              {content}
            </Typography>
          </Grid2>

          <Grid2 size={12}>
            <CommonButton
              value={"Delete"}
              width={"100%"}
              padding={"10px 14px"}
              variant="contained"
              background="#D92D20"
              fontWeight={"bold"}
              onClick={() => handleDeleteSkill()}
              disabled={buttonLoading}
              loading={buttonLoading}
            />
          </Grid2>
        </Grid2>
      </DialogContent>
    </Dialog>
  );
};

export default SkillDeleteDialog;
