import { Switch, Typography } from "@mui/material";
import React from "react";
import "tippy.js/dist/tippy.css";
import { Field } from "formik";
import CommonButton from "../../../../component/Button";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import CreateSkillData from "./CreateSkill";

const Skills = ({
  primaryData,
  secondaryData,
  headerData,
  alignment,
  handleDeleteSkillRow,
  handleAddNewSkill,
  tableLoading,
  toggleRow,
  open,
  criticalityData,
  handleSkillToogleChange,
  tableContainerRef,
  handleSkillTextChange,
  isLoading,
  highPriority,
  lowPriority,
  mediumPriority,
  recommended,
}) => {
  console.log(highPriority, "sfbsjkgbkjsbgjdjfgn");
  return (
    <>
      {alignment === "Edit" && (
        <Field name={"skillData"}>
          {({ field, form }) => {
            return (
              <CommonButton
                variant="outlined"
                color="#344054"
                border="#D0D5DD"
                padding="9px 20px"
                fontWeight="600"
                value="Add New"
                onClick={() => handleAddNewSkill("extracted_soft_skills", form)}
              />
            );
          }}
        </Field>
      )}

      <CommonGrid2
        container
        flexDirection={"row"}
        columnSpacing={2}
        rowSpacing={4}
        marginTop={alignment === "Edit" ? "2rem" : "0px"}
      >
        {highPriority?.length !== 0 && (
          <CommonGrid2 size={6}>
            <CommonGrid2
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5" fontWeight={500}>
                Highest Priority (Core)
              </Typography>
              <Field name={"score_hard_skills"}>
                {({ field, form }) => {
                  return (
                    alignment === "Edit" && (
                      <Switch
                        defaultChecked={form.values["score_hard_skills"]}
                        onChange={(event) => {
                          form.setFieldValue(
                            "score_hard_skills",
                            event.target.checked
                          );
                        }}
                      />
                    )
                  );
                }}
              </Field>
            </CommonGrid2>

            <CreateSkillData
              rowData={highPriority}
              name={"secondary"}
              tableLoading={tableLoading}
              toggleRow={toggleRow}
              open={open}
              criticalityData={criticalityData}
              handleSkillToogleChange={handleSkillToogleChange}
              tableContainerRef={tableContainerRef}
              headerData={headerData}
              handleDeleteSkillRow={handleDeleteSkillRow}
              handleSkillTextChange={handleSkillTextChange}
              alignment={alignment}
              isLoading={isLoading}
            />
          </CommonGrid2>
        )}
        {lowPriority?.length !== 0 && (
          <CommonGrid2 size={6}>
            <CommonGrid2
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5" fontWeight={500}>
                High Priority (Complementary)
              </Typography>
              <Field name={"score_soft_skills"}>
                {({ field, form }) => {
                  return (
                    alignment === "Edit" && (
                      <Switch
                        defaultChecked={form.values["score_soft_skills"]}
                        onChange={(event) => {
                          form.setFieldValue(
                            "score_soft_skills",
                            event.target.checked
                          );
                        }}
                      />
                    )
                  );
                }}
              </Field>
            </CommonGrid2>
            <CreateSkillData
              rowData={lowPriority}
              name={"secondary"}
              tableLoading={tableLoading}
              toggleRow={toggleRow}
              open={open}
              criticalityData={criticalityData}
              handleSkillToogleChange={handleSkillToogleChange}
              tableContainerRef={tableContainerRef}
              headerData={headerData}
              handleDeleteSkillRow={handleDeleteSkillRow}
              handleSkillTextChange={handleSkillTextChange}
              alignment={alignment}
              isLoading={isLoading}
            />
          </CommonGrid2>
        )}
        {recommended?.length !== 0 && (
          <CommonGrid2 size={6}>
            <CommonGrid2
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5" fontWeight={500}>
                Recommended based on Historical/Empirical data
              </Typography>
              <Field name={"score_soft_skills"}>
                {({ field, form }) => {
                  return (
                    alignment === "Edit" && (
                      <Switch
                        defaultChecked={form.values["score_soft_skills"]}
                        onChange={(event) => {
                          form.setFieldValue(
                            "score_soft_skills",
                            event.target.checked
                          );
                        }}
                      />
                    )
                  );
                }}
              </Field>
            </CommonGrid2>
            <CreateSkillData
              rowData={recommended}
              name={"secondary"}
              tableLoading={tableLoading}
              toggleRow={toggleRow}
              open={open}
              criticalityData={criticalityData}
              handleSkillToogleChange={handleSkillToogleChange}
              tableContainerRef={tableContainerRef}
              headerData={headerData}
              handleDeleteSkillRow={handleDeleteSkillRow}
              handleSkillTextChange={handleSkillTextChange}
              alignment={alignment}
              isLoading={isLoading}
            />
          </CommonGrid2>
        )}
        {mediumPriority?.length !== 0 && (
          <CommonGrid2 size={6}>
            <CommonGrid2
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5" fontWeight={500}>
                Medium Priority
              </Typography>
              <Field name={"score_soft_skills"}>
                {({ field, form }) => {
                  return (
                    alignment === "Edit" && (
                      <Switch
                        defaultChecked={form.values["score_soft_skills"]}
                        onChange={(event) => {
                          form.setFieldValue(
                            "score_soft_skills",
                            event.target.checked
                          );
                        }}
                      />
                    )
                  );
                }}
              </Field>
            </CommonGrid2>
            <CreateSkillData
              rowData={mediumPriority}
              name={"secondary"}
              tableLoading={tableLoading}
              toggleRow={toggleRow}
              open={open}
              criticalityData={criticalityData}
              handleSkillToogleChange={handleSkillToogleChange}
              tableContainerRef={tableContainerRef}
              headerData={headerData}
              handleDeleteSkillRow={handleDeleteSkillRow}
              handleSkillTextChange={handleSkillTextChange}
              alignment={alignment}
              isLoading={isLoading}
            />
          </CommonGrid2>
        )}
      </CommonGrid2>
    </>
  );
};
export default Skills;
