export default function QuestionMarkIco() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="3.75" fill="#EBE1F9" />
      <path
        d="M15.6338 7.63081C15.2922 7.00534 14.7575 6.4842 14.095 6.10617C13.4329 5.72982 12.6336 5.50002 11.7661 5.50002C10.6968 5.49747 9.80835 5.76587 9.13782 6.13758C8.46462 6.50795 8.17436 6.93919 8.17436 6.93919C8.06122 7.03377 7.99741 7.17137 8.00008 7.31531C8.00314 7.45946 8.07204 7.59476 8.18867 7.68464L9.12042 8.40362C9.31034 8.55011 9.58214 8.54461 9.76544 8.39068C9.76544 8.39068 9.87989 8.19137 10.2386 7.99397C10.5993 7.79786 11.0669 7.6399 11.7661 7.63778C12.3759 7.6365 12.9076 7.85574 13.2705 8.15532C13.4507 8.30374 13.5854 8.46994 13.6679 8.62198C13.7511 8.77527 13.7815 8.90927 13.781 9.01105C13.7793 9.35494 13.7099 9.57988 13.6098 9.77154C13.5335 9.91466 13.4338 10.0417 13.3055 10.1649C13.114 10.3493 12.8543 10.5198 12.563 10.6765C12.2714 10.835 11.9571 10.9745 11.6395 11.1431C11.2771 11.3365 10.8935 11.6142 10.6101 12.0311C10.4688 12.2371 10.3583 12.4729 10.2881 12.7216C10.217 12.9705 10.1849 13.2315 10.1849 13.4978C10.1849 13.7819 10.1849 14.0151 10.1849 14.0151C10.1849 14.2829 10.4103 14.5 10.6882 14.5H11.9008C12.1787 14.5 12.4041 14.2829 12.4041 14.0151C12.4041 14.0151 12.4041 13.7819 12.4041 13.4978C12.4041 13.3952 12.4162 13.329 12.4279 13.287C12.4479 13.2243 12.4591 13.2086 12.4919 13.1704C12.5254 13.1342 12.5929 13.0786 12.7175 13.0123C12.8995 12.9137 13.1919 12.7805 13.5231 12.6082C14.0189 12.347 14.6217 11.9925 15.1301 11.4075C15.3829 11.1156 15.6065 10.764 15.7608 10.3591C15.9164 9.9541 16.0004 9.49891 16 9.01105C15.9995 8.51681 15.8604 8.0474 15.6338 7.63081Z"
        fill="#7F56D9"
      />
      <path
        d="M11.5004 15.5C10.6718 15.5 10 16.172 10 17.0002C10 17.8283 10.6718 18.5 11.5004 18.5C12.3285 18.5 13 17.8283 13 17.0002C13 16.172 12.3285 15.5 11.5004 15.5Z"
        fill="#7F56D9"
      />
    </svg>
  );
}
