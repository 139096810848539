import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Checkbox,
  Grid2,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CommonButton from "../../../component/Button";
import { HandleSkeletion } from "../../../utils/constants";
import {
  DataFound,
  TableContainerBorder,
} from "../../common-components/StyledComponents";
import { CustomDiv } from "../../chat-bot/style";
import { CustomizeTableCell } from "../../jobs/style";

const CommonTable = ({
  data = [],
  columns = [],
  onSortChange,
  paginationProps = { count: 0, page: 1, onPageChange: () => {} },
  withPagination = false,
  withCheckbox = false,
  handleTableBodyData,
  loading,
  handleSingleSelect,
  checkSelected,
  handleSelectAll,
  marginTop,
  handleCustomCheckBox,
  handleWholeCustomCheckBox,
  loadingRowLength,
}) => {
  const { count, page, onPageChange } = paginationProps;
  const loadingLength = withCheckbox ? 1 : 0;

  return (
    <TableContainerBorder marginTop={marginTop}>
      <Table>
        <TableHead>
          <TableRow>
            {withCheckbox && (
              <TableCell padding="checkbox" width={"4%"}>
                {handleWholeCustomCheckBox ? (
                  handleWholeCustomCheckBox(data, checkSelected)
                ) : (
                  <Checkbox
                    color="primary"
                    checked={
                      data &&
                      data.length !== 0 &&
                      data.every((row) =>
                        (checkSelected || []).some((item) => item.id === row.id)
                      )
                    }
                    onChange={handleSelectAll}
                  />
                )}
              </TableCell>
            )}
            {columns.map((col, index) => (
              <CustomizeTableCell
                textAlign={col.textAlign}
                width={col.width}
                key={index}
                style={{
                  color: "#475467",
                  padding: "16px",
                  minWidth: col.minWidth,
                }}
              >
                {col?.sort ? (
                  <TableSortLabel
                    active={col?.sort}
                    direction={col.direction}
                    onClick={() => onSortChange(col)}
                  >
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color="#475467"
                    >
                      {col.name}
                    </Typography>
                  </TableSortLabel>
                ) : (
                  <Typography variant="body2" fontWeight="600" color="#475467">
                    {col.name}
                  </Typography>
                )}
              </CustomizeTableCell>
            ))}
          </TableRow>
        </TableHead>
        {loading ? (
          <>
            {Array.from({ length: loadingRowLength || 10 }).map((_, index) => (
              <TableRow>
                {Array.from({
                  length: columns.length + loadingLength,
                }).map((_, index) => (
                  <TableCell key={index}>
                    <HandleSkeletion height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </>
        ) : (
          <TableBody>
            {data.length > 0 ? (
              data.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  className={
                    withCheckbox &&
                    checkSelected.some((item) => item.id === row.id) &&
                    "selected-row-color"
                  }
                >
                  {withCheckbox && (
                    <TableCell padding="checkbox">
                      {handleCustomCheckBox ? (
                        handleCustomCheckBox(checkSelected, row)
                      ) : (
                        <Checkbox
                          color="primary"
                          checked={checkSelected.some(
                            (item) => item.id === row.id
                          )}
                          onChange={() => {
                            handleSingleSelect(row);
                          }}
                        />
                      )}
                    </TableCell>
                  )}
                  {handleTableBodyData(row, rowIndex)}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <DataFound padding="10px" margin="auto">
                    <CustomDiv fontSize="20px">👋</CustomDiv>
                    {"Hey  No data available"}
                  </DataFound>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>

      {withPagination && (
        <Grid2 display="flex" justifyContent="space-between" sx={{ p: 2 }}>
          <CommonButton
            variant="outlined"
            border="#D0D5DD"
            color="#344054"
            fontWeight="600"
            value="Previous"
            padding="3px 10px"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              if (page > 1) {
                onPageChange(page - 1);
              }
            }}
            disabled={page === 1 || loading}
          />
          <Pagination
            count={count}
            page={page}
            onChange={(_, newPage) => onPageChange(newPage)}
            shape="rounded"
            color="primary"
            hideNextButton
            hidePrevButton
            disabled={loading}
          />
          <CommonButton
            variant="outlined"
            border="#D0D5DD"
            color="#344054"
            fontWeight="600"
            value="Next"
            padding="3px 10px"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              if (page < count) {
                onPageChange(page + 1);
              }
            }}
            disabled={page === count || loading}
          />
        </Grid2>
      )}
    </TableContainerBorder>
  );
};

export default CommonTable;
