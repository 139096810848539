import { CheckBox } from "@mui/icons-material";
import { Box, IconButton, styled, Tooltip, Typography } from "@mui/material";
import Tippy from "@tippyjs/react";
import { React } from "react";
import "tippy.js/dist/tippy.css";
import DeleteIco from "../../../../assets/icons/DeleteIco";
import UploadCloudIco from "../../../../assets/icons/UploadCloudIco";
import CommonLinearProgress from "../../../../component/Linearprogress";
import {
  FileContainer,
  FileSpan,
  StyledLinearContainer,
  StyledSpaceBetweenBox,
  UploadDiv,
} from "./StyledComponent";
import { CommonTippy } from "../../../jobs/style";

const FileUpload = ({
  onDragOver,
  onDragLeave,
  onDrop,
  isDragging,
  onChange,
  files,
  getFileIcon,
  handleDeleteFile,
  disabled = true,
  isJem,
}) => {
  return (
    <>
      <Box
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        border={"1px solid #EAECF0"}
        borderRadius={"12px"}
        padding={"20px"}
        textAlign={"center"}
        marginBottom={3}
        backgroundColor={isDragging ? "#F0EAFD" : "#F9F9F9"}
        transition={"background-color 0.3s"}
      >
        <input
          type="file"
          multiple={isJem ? false : true}
          onChange={(event) => {
            onChange(event);
            event.target.value = "";
          }}
          disabled={disabled}
          style={{ display: "none" }}
          id="file-input"
        />
        <label
          htmlFor="file-input"
          style={{
            cursor: disabled ? "auto" : "pointer",
            opacity: disabled ? "0.5" : 2,
          }}
        >
          <UploadDiv>
            <UploadCloudIco />
          </UploadDiv>
          <Typography fontSize={"14px"} marginTop={1}>
            <FileSpan color="#6941C6" fontWeight={600}>
              Click to upload{" "}
            </FileSpan>
            <FileSpan color="#475467" fontWeight={400}>
              or drag and drop
            </FileSpan>
          </Typography>
          <Typography
            sx={{ color: "#475467", fontWeight: 400, fontSize: "12px" }}
          >
            PDF, DOCX, or DOC
          </Typography>
        </label>
      </Box>

      {files?.map((file, index) => (
        <FileContainer key={index} elevation={1} gap={"1rem"}>
          <Box display={"flex"} alignItems={"flex-start"} className={"kathir"}>
            {getFileIcon(file)}
          </Box>

          <Box width={"100%"}>
            <StyledSpaceBetweenBox>
              <Typography color="#344054" fontSize={"14px"} fontWeight={700}>
                {file.name}
              </Typography>

              <IconButton
                onClick={() => (file.delete ? handleDeleteFile(index) : "")}
                sx={{
                  padding: "0px",
                  cursor: file.delete ? "pointer" : "auto",
                }}
              >
                {file.progress === 100 && !file.delete && (
                  <CheckBox sx={{ color: "#7F56D9" }} />
                )}
                {file.delete && <DeleteIco />}
              </IconButton>
            </StyledSpaceBetweenBox>
            <Typography color="#475467" fontSize={"14px"} fontWeight={500}>
              {file.size}
            </Typography>
            {file.progress >= 0 && (
              <Tooltip
                persistOnClick
                active
                content="This order has shipping labels."
              >
                <CommonTippy
                  content={file.message}
                  visible={file.visible}
                  interactive={true}
                  trigger="click"
                  hideOnClick={false}
                  zIndex={2}
                  color="red"
                >
                  <StyledLinearContainer>
                    <CommonLinearProgress
                      variant={file.progress > 1 ? "buffer" : "determinate"}
                      value={file.progress}
                      valueBuffer={file.buffer}
                      height={"8px"}
                      borderRadius={"4px"}
                      backgroundColor={"#E0E0E0"}
                      barbackgroundColor={file.visible ? "red" : "#7F56D9"}
                      dashedmarginTop={"2px"}
                      dashedbackgroundImage={
                        file.visible
                          ? "radial-gradient(rgb(255 46 0) 0%, rgb(255 0 0) 16%, #00000000 42%)"
                          : "radial-gradient(rgb(82 0 255) 0%, rgb(82 0 255) 16%, #00000000 42%)"
                      }
                    />

                    <Typography
                      color="#344054"
                      fontSize={"14px"}
                      fontWeight={500}
                    >
                      {file.progress}%
                    </Typography>
                  </StyledLinearContainer>
                </CommonTippy>
              </Tooltip>
            )}
          </Box>
        </FileContainer>
      ))}
    </>
  );
};
export default FileUpload;
