import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import React from "react";

const FormControlField = styled(FormControl)(
  ({
    marginTop,
    InputPadding,
    borderRadius,
    sizeMedium,
    removeAnimation,
    customStyle,
    value,
    padding,
  }) => ({
    marginTop: marginTop || "8px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D0D5DD",
      borderRadius: borderRadius || "6px",
    },
    "& .MuiSelect-select": {
      padding: padding || "11.5px 32px 11.5px 12px",
    },
    "& .MuiInputLabel-formControl": {
      fontFamily: "Inter",
    },
    "& .MuiSelect-nativeInput": {
      color: "#667085",
      fontFamily: "Inter",
    },
    "& #demo-simple-select": {
      padding: InputPadding || "12px",
    },
    "& label.MuiInputLabel-sizeMedium": {
      top: sizeMedium || "-6px",
    },
    "& label.MuiInputLabel-shrink": {
      top: "0px",
    },
    "& .MuiFormHelperText-root": {
      fontWeight: 600,
      margin: "2px 0px 0px",
    },

    ...(removeAnimation && {
      "& .MuiFormLabel-filled": {
        display: "none",
      },

      ...(value !== "" &&
        value !== undefined && {
          "& label": {
            display: "none",
          },
        }),
    }),
    ...customStyle,
  })
);
const DropDownSelect = ({
  data,
  title,
  value,
  handleChange,
  marginTop,
  InputPadding,
  borderRadius,
  sizeMedium,
  error,
  helperText,
  valueChange,
  CustomMenuItem,
  removeAnimation,
  customStyle,
  disabled,
  id,
  handleRenderValue,
  padding,
  fullWidth = true,
  width = "auto",
  maxHeight,
}) => {
  return (
    <FormControlField
      fullWidth={fullWidth}
      marginTop={marginTop}
      InputPadding={InputPadding}
      borderRadius={borderRadius}
      sizeMedium={sizeMedium}
      error={error}
      removeAnimation={removeAnimation}
      customStyle={customStyle}
      value={value}
      padding={padding}
    >
      <InputLabel id={id || "demo-simple-select-label"}>{title}</InputLabel>
      <Select
        disabled={disabled}
        labelId={id || "demo-simple-select-label"}
        id={id || "demo-simple-select"}
        value={value}
        label={removeAnimation && value ? "" : title}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: maxHeight || 210,
              width: width,
            },
          },
        }}
        renderValue={(selected) =>
          handleRenderValue
            ? handleRenderValue(selected)
            : valueChange
            ? data && data.filter((item) => item.id === selected)[0]?.name
            : selected
        }
      >
        {data &&
          data.map((item, index) =>
            valueChange ? (
              CustomMenuItem(item, index)
            ) : (
              <MenuItem value={item?.name} key={index}>
                {item?.name}
              </MenuItem>
            )
          )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControlField>
  );
};

export default DropDownSelect;
