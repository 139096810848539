import { Grid2, Typography } from "@mui/material";
import { clientTableData } from "../../../utils/constants";

import React from "react";
import CommonButton from "../../../component/Button";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import CommonTable from "../../candidates/Table";

export default function Vendor({
  vendorHeaderData,
  handleAddVendorClick,
  vendorList,
  handleVendorTableBodyData,
}) {
  return (
    <Grid2>
      <Grid2 container spacing={2} marginTop={"2rem"} marginBottom={"2rem"}>
        <CommonGrid2
          size={{ xs: 12, md: 4, sm: 12 }}
          item
          alignItems={"center"}
          display={"flex"}
        >
          <Typography variant="h3">Vendors</Typography>
        </CommonGrid2>
        <CommonGrid2
          item
          size={{ xs: 12, md: 8, sm: 12 }}
          gap="10px"
          display="flex"
          justifyContent={"end"}
        >
          <CommonButton
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Add Vendors"
            padding="8px 20px"
            onClick={handleAddVendorClick}
          />
        </CommonGrid2>
      </Grid2>
      <CommonTable
        data={vendorList.data}
        columns={vendorHeaderData}
        loadingRowLength={5}
        onSortChange={(col) => console.log("Sort by", col)}
        handleTableBodyData={handleVendorTableBodyData}
        // paginationProps={{
        //   count: jobList.totalPage,
        //   page: jobList.currentPage,
        //   onPageChange: (page) =>
        //     setJobList((prev) => ({
        //       ...prev,
        //       currentPage: page,
        //     })),
        // }}
        withPagination={false}
        loading={vendorList.loading}
      />
    </Grid2>
  );
}
