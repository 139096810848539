const CandidatePool = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6673 14V12.6667C14.6673 11.4241 13.8175 10.38 12.6673 10.084M10.334 2.19384C11.3113 2.58943 12.0007 3.54754 12.0007 4.66667C12.0007 5.78579 11.3113 6.7439 10.334 7.13949M11.334 14C11.334 12.7575 11.334 12.1362 11.131 11.6462C10.8603 10.9928 10.3412 10.4736 9.68781 10.203C9.19775 10 8.5765 10 7.33398 10H5.33398C4.09147 10 3.47022 10 2.98016 10.203C2.32675 10.4736 1.80762 10.9928 1.53697 11.6462C1.33398 12.1362 1.33398 12.7575 1.33398 14M9.00065 4.66667C9.00065 6.13943 7.80674 7.33333 6.33398 7.33333C4.86123 7.33333 3.66732 6.13943 3.66732 4.66667C3.66732 3.19391 4.86123 2 6.33398 2C7.80674 2 9.00065 3.19391 9.00065 4.66667Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default CandidatePool;
