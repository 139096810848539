import {
  Avatar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import BoxEditIco from "../../../../assets/icons/BoxEditIco";
import DeleteIco from "../../../../assets/icons/DeleteIco";
import CommonButton from "../../../../component/Button";
import { HandleSkeletion } from "../../../../utils/constants";
import {
  CommonGrid2,
  StyledFlexRowView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  StyledTypo,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";

function Notes({
  handleClickAddNote,
  handleDeleteNoteClick,
  handleUpdateNoteClick,
  notesTableList,
  notesTableListLoading,
  isLoading,
  disabled,
  headerData,
}) {
  return (
    <>
      <TableContainerBorder marginTop={"0px"}>
        <Table>
          <TableHead>
            <TableRow>
              {headerData.map((item) => (
                <StyledTableTitleTableCell width={item.width}>
                  {item.name}
                </StyledTableTitleTableCell>
              ))}
            </TableRow>
          </TableHead>
          {notesTableListLoading ? (
            <>
              {Array.from({ length: 2 }).map((_, index) => (
                <TableRow>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <TableCell key={index}>
                      <HandleSkeletion height={30} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ) : (
            <TableBody>
              {notesTableList?.map((row, index) => (
                <TableRow key={row.id}>
                  <StyledTableBodyTableCell>
                    {row.title}
                  </StyledTableBodyTableCell>

                  <TableCell>
                    <StyledFlexRowView>
                      <Avatar
                        alt={row.madeBy}
                        src="https://mui.com/static/images/avatar/1.jpg"
                        sx={{ width: 24, height: 24 }}
                      />

                      <StyledTypo> {row.added_by}</StyledTypo>
                    </StyledFlexRowView>
                  </TableCell>
                  <StyledTableBodyTableCell>
                    {new Date(row.created_at).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </StyledTableBodyTableCell>

                  <TableCell>
                    <CommonGrid2
                      gap={"1rem"}
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"center"}
                    >
                      <IconButton onClick={() => handleUpdateNoteClick(row)}>
                        <BoxEditIco />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteNoteClick(row, index)}
                      >
                        <DeleteIco />
                      </IconButton>
                    </CommonGrid2>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainerBorder>
      <CommonButton
        variant="outlined"
        color="#344054"
        border="#D0D5DD"
        fontWeight="700"
        value="Add Note"
        padding="8px 15px"
        marginTop="2rem"
        disabled={isLoading || disabled}
        onClick={handleClickAddNote}
        boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
      />
    </>
  );
}

export default Notes;
