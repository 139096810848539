import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterBackGroundImage from "../../assets/images/FilterBackGround.png";
import CommonButton from "../../component/Button";
import DateRangePickerComp from "../jobs/common-card.js/DatePicker";
import { StyledDialog, StyledFlexRowView } from "./StyledComponents";

const FilterDialog = ({ open, onClose, title = "Filter", filterOptions = [], onApply, count, fetchCandidatesList, limit, currentPage, debouncedQuery, currentQuery, currentQueryStatus }) => {
  const [filterState, setFilterState] = useState(
    filterOptions.reduce((acc, option) => {
      acc[option.key] = option.type === "multi-select" ? [] : option.value || null;
      return acc;
    }, {})
  );

  useEffect(() => {
    if (!open) {
      setFilterState(
        filterOptions.reduce((acc, option) => {
          acc[option.key] = option.type === "multi-select" ? [] : option.value || null;
          return acc;
        }, {})
      );
    }
  }, [open, filterOptions]);

  const handleDropdownChange = (key, value) => {
    console.log(key, value)
    console.log(filterState)
    const newState = { ...filterState, [key]: value };
    console.log(newState)
    setFilterState(newState);
    fetchCandidatesList({
      limit,
      offset: (currentPage - 1) * limit,
      q: currentQuery,
      filterValues: newState,
      status_flag: currentQueryStatus,
    });
    return newState;
  };

  const countSelectedFields = () => {
    return Object.values(filterState).filter((value) =>
      Array.isArray(value) ? value.length > 0 : value != null
    ).length;
  };

  const selectedCount = countSelectedFields();

  const handleDateChange = (key, start, end) => {
    setFilterState((prevState) => {
      const newState = {
        ...prevState,
        [key]: {
          from_date: start,
          to_date: end || start,
        },
      };
      return newState;
    });
  };
  const handleApplyFilters = () => {
    onApply(filterState);
  };

  const handleClearForm = () => {
    setFilterState(
      filterOptions.reduce((acc, option) => {
        acc[option.key] = option.type === "multi-select" ? [] : option.value || null;
        return acc;
      }, {})
    );
  };

  return (
    <StyledDialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <img
        src={FilterBackGroundImage}
        style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
        alt="Filter Background"
      />
      <DialogActions>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {title} {selectedCount > 0 && `(${selectedCount})`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid2 container direction="column" gap={2}>
          {filterOptions.map((option, index) => (
            <Grid2 item key={index}>
              <Typography
                color="#344054"
                fontSize={14}
                fontFamily="Inter"
                fontWeight={500}
                sx={{ mb: 1 }}
              >
                {option.label}
                {option.type === "select" && filterState[option.key] && (
                  `:( ${filterState[option.key]?.label || filterState[option.key]})`
                )}
                {option.type === "multi-select" && filterState[option.key]?.length > 0 && (
                  `: (${filterState[option.key].length} ${filterState[option.key].length > 1 ? '' : ''})`
                )}
              </Typography>
              {option.type === "select" && (
                <>
                  <Autocomplete
                    options={option.options}
                    getOptionLabel={(option) => option.label || option}
                    value={filterState[option.key] || null}
                    onChange={(_, value) => handleDropdownChange(option.key, value)}

                    renderInput={(params) => <TextField {...params} key={option.key} />}
                  />
                </>
              )}
              {option.type === "multi-select" && (
                <>
                  <Autocomplete
                    multiple
                    options={option.options}
                    getOptionLabel={(option) => option.key || option}
                    value={filterState[option.key] || []}
                    onChange={(_, value) => handleDropdownChange(option.key, value)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox checked={selected} />
                        {option.label || option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label={option.label} placeholder="Select options" />
                    )}
                  />
                </>
              )}
              {option.type === "date" && (
                <>
                  <DateRangePickerComp
                    startDateProp={filterState[option.key]?.from_date}
                    endDateProp={filterState[option.key]?.to_date}
                    handleChange={(start, end) => handleDateChange(option.key, start, end)}
                  />
                </>
              )}
            </Grid2>
          ))}
          <StyledFlexRowView style={{ justifyContent: "flex-end" }}>
            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              value="Clear"
              padding={"10px 16px"}
              setFilterState={setFilterState}
              onClick={handleClearForm}
            />
            <CommonButton
              variant="contained"
              color="white"
              background="#7F56D9"
              fontWeight="600"
              value={`Apply${count > 0 ? ` (${count})` : ""}`}
              padding="8px"
              onClick={handleApplyFilters}
            // onClick={() => onApply(filterState)}
            />
          </StyledFlexRowView>
        </Grid2>

      </DialogContent>
    </StyledDialog>
  );
};

export default FilterDialog;
