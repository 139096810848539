function ScalesIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.33301 11.3333V11.2332C1.33301 11.0222 1.33301 10.9167 1.34915 10.8139C1.36347 10.7227 1.38725 10.6332 1.42008 10.5468C1.45707 10.4496 1.50941 10.358 1.61409 10.1748L3.99967 6M1.33301 11.3333C1.33301 12.8061 2.52692 14 3.99967 14C5.47243 14 6.66634 12.8061 6.66634 11.3333M1.33301 11.3333V11.2C1.33301 11.0133 1.33301 10.92 1.36934 10.8487C1.4013 10.7859 1.45229 10.735 1.51501 10.703C1.58631 10.6667 1.67966 10.6667 1.86634 10.6667H6.13301C6.31969 10.6667 6.41303 10.6667 6.48434 10.703C6.54706 10.735 6.59805 10.7859 6.63001 10.8487C6.66634 10.92 6.66634 11.0133 6.66634 11.2V11.3333M3.99967 6L6.38526 10.1748C6.48994 10.358 6.54228 10.4496 6.57927 10.5468C6.6121 10.6332 6.63587 10.7227 6.6502 10.8139C6.66634 10.9167 6.66634 11.0222 6.66634 11.2332V11.3333M3.99967 6L11.9997 4.66667M9.33301 10V9.89987C9.33301 9.68888 9.33301 9.58338 9.34915 9.4806C9.36347 9.38934 9.38725 9.29982 9.42008 9.21348C9.45707 9.11623 9.50941 9.02463 9.61409 8.84144L11.9997 4.66667M9.33301 10C9.33301 11.4728 10.5269 12.6667 11.9997 12.6667C13.4724 12.6667 14.6663 11.4728 14.6663 10M9.33301 10V9.86667C9.33301 9.67998 9.33301 9.58664 9.36934 9.51534C9.4013 9.45262 9.45229 9.40162 9.51501 9.36966C9.58632 9.33333 9.67966 9.33333 9.86634 9.33333H14.133C14.3197 9.33333 14.413 9.33333 14.4843 9.36966C14.5471 9.40162 14.5981 9.45262 14.63 9.51534C14.6663 9.58664 14.6663 9.67998 14.6663 9.86667V10M11.9997 4.66667L14.3853 8.84144C14.4899 9.02463 14.5423 9.11623 14.5793 9.21348C14.6121 9.29982 14.6359 9.38934 14.6502 9.4806C14.6663 9.58338 14.6663 9.68888 14.6663 9.89987V10M7.99967 2V5.33333"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ScalesIco;
