function ClipBoardCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.666 2.66732C11.286 2.66732 11.596 2.66732 11.8503 2.73547C12.5405 2.9204 13.0796 3.4595 13.2645 4.14968C13.3327 4.40401 13.3327 4.714 13.3327 5.33398V11.4673C13.3327 12.5874 13.3327 13.1475 13.1147 13.5753C12.9229 13.9516 12.617 14.2576 12.2407 14.4493C11.8128 14.6673 11.2528 14.6673 10.1327 14.6673H5.86602C4.74591 14.6673 4.18586 14.6673 3.75803 14.4493C3.38171 14.2576 3.07575 13.9516 2.884 13.5753C2.66602 13.1475 2.66602 12.5874 2.66602 11.4673V5.33398C2.66602 4.714 2.66602 4.40401 2.73416 4.14968C2.9191 3.4595 3.45819 2.9204 4.14838 2.73547C4.40271 2.66732 4.7127 2.66732 5.33268 2.66732M5.99935 10.0007L7.33268 11.334L10.3327 8.33398M6.39935 4.00065H9.59935C9.97272 4.00065 10.1594 4.00065 10.302 3.92799C10.4275 3.86407 10.5294 3.76209 10.5934 3.63664C10.666 3.49404 10.666 3.30735 10.666 2.93398V2.40065C10.666 2.02728 10.666 1.8406 10.5934 1.69799C10.5294 1.57255 10.4275 1.47056 10.302 1.40665C10.1594 1.33398 9.97272 1.33398 9.59935 1.33398H6.39935C6.02598 1.33398 5.8393 1.33398 5.69669 1.40665C5.57125 1.47056 5.46926 1.57255 5.40534 1.69799C5.33268 1.8406 5.33268 2.02728 5.33268 2.40065V2.93398C5.33268 3.30735 5.33268 3.49404 5.40534 3.63664C5.46926 3.76209 5.57125 3.86407 5.69669 3.92799C5.8393 4.00065 6.02598 4.00065 6.39935 4.00065Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ClipBoardCheck;
