import { Divider, Grid2, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonAccordion from "../common-components/CommonAccordion";
import { StyledFlexColumnView } from "../common-components/StyledComponents";
import AddMember from "./components/AddMember";
import General from "./components/General";
import Groups from "./components/Groups";
import Information from "./components/Information";
import Team from "./components/Team";

function Settings() {
    const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
    const [openAddGroupDialog, setOpenAddGroupDialog] = useState(false);

    const navigate = useNavigate();
    const handleAddMemberClick = () => {
        setOpenAddMemberDialog(true);
    };
    const handleCloseAddMemberDialog = () => {
        setOpenAddMemberDialog(false);
    };
    const handleAddGroupClick = () => {
        navigate("/add-group");
    };

    const handleCloseAddGroup = () => {
        setOpenAddGroupDialog(false);
    };

    return (
        <Grid2 container spacing={4}>
            <Grid2 size={12}>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "24px",
                        color: "#101828",
                    }}
                >
                    Settings
                </Typography>
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"General"} children={<General />} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"Information"} children={<Information />} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion
                    title={"Team"}
                    accordionButton
                    buttonTitle={"Add Member"}
                    children={<Team />}
                    onButtonClick={handleAddMemberClick}
                />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion
                    title={"Groups"}
                    accordionButton
                    buttonTitle={"Add Group"}
                    children={<Groups />}
                    onButtonClick={handleAddGroupClick}
                />
            </Grid2>
            <Grid2 size={12}>
                <Divider />
            </Grid2>
            <Grid2 size={4}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Session Duration"}
                    </Typography>
                    <TextField
                        fullWidth
                        value={"60"}
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Log out after given time in minutes."}
                    </Typography>
                </StyledFlexColumnView>
            </Grid2>
            <AddMember
                open={openAddMemberDialog}
                onClose={handleCloseAddMemberDialog}
                onApply={handleCloseAddMemberDialog}
            />

        </Grid2 >
    );
}

export default Settings;
