import { Box, Typography } from "@mui/material";
import React from "react";
import TickIcon from "../../../../assets/icons/TickIcon";
import { HandleSkeletion } from "../../../../utils/constants";
import { CustomDiv } from "../../../chat-bot/style";
import { CustomPaper } from "../../../resume-intelligence/create-candidate-resume/components/Style";
import AccomplishmentIco from "../../../../assets/icons/AccomplishmentIco";

function Accomplishment({ matchedData, loading }) {
  return loading ? (
    <HandleSkeletion height={100} width={"100%"} />
  ) : (
    <Box marginTop={"0.5rem"}>
      {matchedData?.insights?.feedback?.accomplishments?.map((item, index) => (
        <CustomPaper
          variant="outlined"
          width="100%"
          padding={"12px"}
          marginBottom={"1rem"}
          display={"flex"}
          alignItems={"start"}
          key={index}
          gap="1rem"
          background="#EBFFF2"
          border="none !important"
        >
          <CustomDiv>
            <AccomplishmentIco />
          </CustomDiv>
          <Typography fontSize={"16px"} fontWeight={500} color={"#00144A"}>
            {item.accomplishment}
          </Typography>
        </CustomPaper>
      ))}
    </Box>
  );
}

export default Accomplishment;
