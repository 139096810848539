import { Grid2, Typography } from "@mui/material";
import React, { useState } from "react";
import LogoMark from "../../../assets/images/Logomark.png";
import CommonAccordion from "../../common-components/CommonAccordion";
import AddEducation from "./components/AddEducation";
import AddExperience from "./components/AddExperience";
import AdditionalInformation from "./components/AdditionalInformation";
import Certifications from "./components/Certifications";
import Education from "./components/Education";
import Engage from "./components/Engage";
import Experience from "./components/Experience";
import Licences from "./components/Licences";
import PreviousExperience from "./components/PreviousExperience";
import PrimaryInformation from "./components/PrimaryInformation";
import Profile from "./components/Profile";
import References from "./components/References";
import VisaStatus from "./components/VisaStatus";


function CandidatePerspective() {
    const [openAddExperience, setAddExperience] = useState(false);
    const [openAddEducation, setAddEducation] = useState(false);
    const [openAddLicences, setAddLicences] = useState(false);
    const [openAddCertification, setAddCertification] = useState(false);

    const handleAddExperience = () => {
        setAddExperience(true);
    };
    const handleCloseAddExperience = () => {
        setAddExperience(false);
    };
    const handleAddEducation = () => {
        setAddEducation(true);
    };
    const handleCloseAddEducation = () => {
        setAddEducation(false);
    };
    const handleAddLicences = () => {
        setAddLicences(true);
    };
    const handleCloseAddLicences = () => {
        setAddLicences(false);
    };
    const handleAddCertification = () => {
        setAddCertification(true);
    };
    const handleCloseAddCertification = () => {
        setAddCertification(false);
    };
    return (
        <Grid2 container spacing={4}>
            <Grid2 size={12}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        paddingBottom: "16px"
                    }}
                >
                    <img
                        src={LogoMark}
                        style={{
                            width: "32px",
                            height: "32px",
                        }}
                        alt="logo"
                    />
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "24px",
                            color: "#101828",
                        }}
                    >
                        Talairo
                    </Typography>
                </div>
            </Grid2>
            <Grid2 size={12}>
                <Profile />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"Primary Information"} children={<PrimaryInformation />} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"Additional Information"} children={<AdditionalInformation />} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"Previous Experience With The Client"} children={<PreviousExperience />} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"How We Engage"} children={<Engage />} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"Visa Status"} children={<VisaStatus />} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"Experience"} children={<Experience />} accordionButton buttonTitle={"Add Experience"} onButtonClick={handleAddExperience} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"Education"} children={<Education />} accordionButton buttonTitle={"Add Education"} onButtonClick={handleAddEducation} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"Licences"} children={<Licences />} accordionButton buttonTitle={"Add Licence"} onButtonClick={handleAddLicences} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"Certifications"} children={<Certifications />} accordionButton buttonTitle={"Add Certification"} onButtonClick={handleAddCertification} />
            </Grid2>
            <Grid2 size={12}>
                <CommonAccordion title={"References"} children={<References />} />
            </Grid2>
            <AddExperience
                open={openAddExperience}
                onClose={handleCloseAddExperience}
                onApply={handleCloseAddExperience}
            />
            <AddEducation
                open={openAddEducation}
                onClose={handleCloseAddEducation}
                onApply={handleCloseAddEducation}
                title={"Education"}
            />

            <AddEducation
                open={openAddLicences}
                onClose={handleCloseAddLicences}
                onApply={handleCloseAddLicences}
                title={"Licences"}
            />
            <AddEducation
                open={openAddCertification}
                onClose={handleCloseAddCertification}
                onApply={handleCloseAddCertification}
                title={"Certification"}
            />

        </Grid2 >
    );
}

export default CandidatePerspective;
