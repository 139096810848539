import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import Featuredicon from "../../../assets/images/featuredicon.png";
import { StyledDialog, StyledFlexColumnView } from "../../common-components/StyledComponents";

const ResetPassword = ({ open, onClose, onApply }) => {
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState(""); // State for new password
    const [oldPassword, setOldPassword] = useState(""); // State for old password
    const [showPasswordField, setShowPasswordField] = useState(false); // Manage New Password field visibility
    const [showOldPassword, setShowOldPassword] = useState(false); // Toggle visibility for old password
    const [showNewPassword, setShowNewPassword] = useState(false); // Toggle visibility for new password

    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const toggleShowOldPassword = () => setShowOldPassword((prev) => !prev);
    const toggleShowNewPassword = () => setShowNewPassword((prev) => !prev);

    const handleResetClick = () => {
        setShowPasswordField(true); // Show New Password field and hide email
    };

    return (
        <>
            <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
                <img
                    src={Featuredicon}
                    style={{ position: "absolute", padding: "8px", width: "80px" }}
                />
                <DialogActions>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </DialogActions>
                <DialogTitle>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                        {"Reset Password"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={3}>
                        {/* Email Field - Rendered only if showPasswordField is false */}
                        {!showPasswordField && (
                            <Grid item xs={12}>
                                <StyledFlexColumnView>
                                    <Typography
                                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                                    >
                                        {"Email Address"}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter your email"
                                        error={!isValidEmail && email.length > 0}
                                        helperText={
                                            !isValidEmail && email.length > 0
                                                ? "Please enter a valid email address"
                                                : ""
                                        }
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "8px",
                                                backgroundColor: "#FFFFFF",
                                                border: "1px solid #D0D5DD",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: "12px",
                                            },
                                            "& .MuiInputBase-input::placeholder": {
                                                color: "#667085",
                                                opacity: 1,
                                                fontSize: "16px",
                                                fontWeight: 400,
                                            },
                                        }}
                                    />
                                </StyledFlexColumnView>
                            </Grid>
                        )}
                        {showPasswordField && (
                            <>
                                <Grid item xs={12}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "#344054",
                                            }}
                                        >
                                            {"New Password"}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            placeholder="Enter your new password"
                                            type={showNewPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={toggleShowNewPassword} edge="end">
                                                            {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    borderRadius: "8px",
                                                    backgroundColor: "#FFFFFF",
                                                    border: "1px solid #D0D5DD",
                                                },
                                                "& .MuiInputBase-input": {
                                                    padding: "12px",
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#667085",
                                                    opacity: 1,
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                },
                                            }}
                                        />
                                    </StyledFlexColumnView>
                                </Grid>
                                <Grid item xs={12}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                                        >
                                            {"Old Password"}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            type={showOldPassword ? "text" : "password"} // Toggle password visibility
                                            value={oldPassword}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                            placeholder="Enter your old password"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={toggleShowOldPassword} edge="end">
                                                            {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    borderRadius: "8px",
                                                    backgroundColor: "#FFFFFF",
                                                    border: "1px solid #D0D5DD",
                                                },
                                                "& .MuiInputBase-input": {
                                                    padding: "12px",
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#667085",
                                                    opacity: 1,
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                },
                                            }}
                                        />
                                    </StyledFlexColumnView>
                                </Grid>
                            </>
                        )}

                        {/* Reset Password Button */}
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleResetClick}
                                disabled={!isValidEmail} // Button is enabled only for valid email
                                sx={{
                                    backgroundColor: isValidEmail ? "#7F56D9" : "#D0D5DD",
                                    color: "white",
                                    fontWeight: 600,
                                    padding: "12px",
                                    "&:disabled": {
                                        color: "#ffffff",
                                        backgroundColor: "#D0D5DD",
                                    },
                                    "&:hover": {
                                        backgroundColor: "rgb(127, 86, 217)",
                                        color: "#fff",
                                    },
                                }}
                            >
                                {"Reset Password"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </StyledDialog>
        </>
    );
};

export default ResetPassword;
