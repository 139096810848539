import { URL } from "../../api/Api";
import { catchError, GET, POST, PUT } from "../axios/Axios";

export const PartnerCriticalScore = async () => {
  try {
    const response = await GET(`${URL}/matchengine/match/weights/list`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const PartnerCriticalScoreUpdate = async (payload) => {
  try {
    const response = await PUT(
      `${URL}/matchengine/match/weights/list/`,
      payload,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
