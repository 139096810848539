import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import RecordingIco from "../../assets/icons/RecordingIco";
import XCloseIco from "../../assets/icons/XCloseIco";
import NoteBackgroundImg from "../../assets/images/note-background.png";
import CommonButton from "../../component/Button";
import { StyledFlexColumnView } from "./StyledComponents";

function AddUpdateNote({
  openNoteDialog,
  handleCloseNoteDialog,
  id,
  handleCreateNote,
  noteList,
  handleNoteTextChange,
  addNoteLoading,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={openNoteDialog}
      onClose={handleCloseNoteDialog}
      sx={{
        "& .MuiDialog-paper": {
          width: "400px",
          maxWidth: "none",
          borderRadius: "12px",
          background: "#FFF",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        },
      }}
    >
      <img
        src={NoteBackgroundImg}
        style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
      />
      <DialogActions>
        {/* <Button onClick={handleCloseNoteDialog} sx={{ marginTop: "10px" }}>
          <XCloseIco />
        </Button> */}

        <CommonButton
          onClick={handleCloseNoteDialog}
          padding={"10px"}
          background={"rgba(127, 86, 217, 0.04)"}
          border="rgba(127, 86, 217, 0.5)"
          height={"100%"}
          value={<XCloseIco />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
        />
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {id ? "Update Note" : "Create Note"}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid2 spacing={2} container>
          {noteList?.map((note, index) => (
            <>
              <Grid2 size={12}>
                <StyledFlexColumnView>
                  <Typography
                    color="#344054"
                    fontSize={14}
                    fontFamily={"Inter"}
                    fontWeight={500}
                  >
                    Note
                  </Typography>

                  <TextField
                    id="outlined-multiline-static"
                    // label="Multiline"
                    placeholder="placeholder"
                    multiline
                    rows={4}
                    // defaultValue={
                    //   id
                    //     ? "Lorem ipsum dolor sit amet consectetur. Blandit massa velit et euismod sed faucibus tortor nisl pellentesque."
                    //     : null
                    // }
                    value={note.note}
                    onChange={(e) => handleNoteTextChange(index, e)}
                    fullWidth
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "inter",
                    }}
                  />
                </StyledFlexColumnView>
              </Grid2>

              <Grid2 size={12}>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<RecordingIco />}
                  sx={{
                    width: "100%",
                    padding: "10px 14px",
                    borderRadius: "8px",
                    color: "#344054",
                    border: "1px solid #D0D5DD",
                    "&:hover": {
                      backgroundColor: "#7F56D9",
                    },
                    fontWeight: "bold",
                  }}
                >
                  {id ? "Start Recording" : "Record"}
                </Button>
              </Grid2>

              <Grid2 size={12}>
                {/* {id ? (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      width: "100%",
                      padding: "10px 14px",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                      border: "1px solid #D0D5DD",
                      fontWeight: "bold",
                      boxShadow: "none",
                    }}
                  >
                    {"Update"}
                  </Button>
                ) : ( */}
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    padding: "10px 14px",
                    borderRadius: "8px",
                    color: "#344054",
                    border: "1px solid #D0D5DD",
                    "&:hover": {
                      backgroundColor: "#7F56D9",
                    },
                    fontWeight: "bold",
                    "&.Mui-disabled": {
                      color: "#98A2B3",
                      fontWeight: "bold",
                      border: "1px solid #EAECF0",
                      backgroundColor: "#F2F4F7",
                    },
                  }}
                  disabled={addNoteLoading || note?.note === "" ? true : false}
                  onClick={handleCreateNote}
                  startIcon={
                    addNoteLoading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null
                  }
                >
                  {id ? "Update" : "Create Note"}
                </Button>
                {/* )} */}
              </Grid2>
            </>
          ))}
        </Grid2>
      </DialogContent>
    </Dialog>
  );
}

export default AddUpdateNote;
