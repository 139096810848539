import {
  Slider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Field } from "formik";
import React from "react";
import { HandleSkeletion } from "../../utils/constants";
import {
  CommonGrid2,
  TableContainerBorder,
} from "../common-components/StyledComponents";
import { CustomizeTableCell, CustomTooltipField } from "../jobs/style";

const CriticalityScore = ({ handleSliderChange, clientData }) => {
  const visibleCondition =
    clientData.primaryTotalCount + clientData.secondaryTotalCount;

  return (
    <div style={{ position: "relative" }}>
      {visibleCondition !== 100 && visibleCondition !== "" && (
        <CustomTooltipField
          className="error-occur"
          fontSize="14px"
          padding="5px 10px"
          size={7}
        >
          {visibleCondition >= 100
            ? "Total criticality score should not exceed 100."
            : "Total criticality score should not be less than 100."}
        </CustomTooltipField>
      )}
      <CommonGrid2 container spacing={2} flexDirection={"row"}>
        {clientData["inputField"].map((inputItem) => (
          <CommonGrid2 size={6}>
            <CommonGrid2
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5" fontWeight={500}>
                {inputItem}
              </Typography>
            </CommonGrid2>

            <Field name={clientData["data"]}>
              {({ field, form }) => {
                console.log(field, form);
                return (
                  <TableContainerBorder
                    scrollStyle={true}
                    marginTop="1rem"
                    // height={data.length}
                  >
                    <Table aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {clientData["headerData"].map((item, index) => {
                            return (
                              <CustomizeTableCell key={index}>
                                {item}
                              </CustomizeTableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      {clientData["loader"] ? (
                        <>
                          {Array.from({ length: 5 }).map((_, index) => (
                            <TableRow>
                              {Array.from({ length: 2 }).map((_, index) => (
                                <TableCell key={index}>
                                  <HandleSkeletion height={30} />
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </>
                      ) : (
                        <TableBody>
                          {form["values"]["data"]?.map((item, index) => {
                            if (item.attribute === inputItem)
                              return (
                                <TableRow key={index}>
                                  <CustomizeTableCell
                                    fontWeight={500}
                                    sx={{ width: "40%" }}
                                  >
                                    <Typography
                                      variant="body1"
                                      fontWeight="bold"
                                    >
                                      {item.name}
                                    </Typography>
                                  </CustomizeTableCell>
                                  <TableCell sx={{ width: "40%" }}>
                                    <Slider
                                      defaultValue={item.weightage}
                                      valueLabelDisplay="auto"
                                      onChange={(_, newValue) =>
                                        handleSliderChange(item.id, newValue)
                                      }
                                      sx={{
                                        "& .MuiSlider-thumb": {
                                          width: 12,
                                          height: 12,
                                        },
                                        "& .MuiSlider-valueLabel": {
                                          backgroundColor: "#6941C6",
                                          fontSize: "13px",
                                          color: "white",
                                        },
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainerBorder>
                );
              }}
            </Field>
            <Typography
              variant="p"
              fontSize={"16px"}
              display={"flex"}
              justifyContent={"end"}
              marginTop={"1rem"}
            >
              Total :{" "}
              <b>
                {inputItem === "primary"
                  ? clientData.primaryTotalCount
                  : clientData.secondaryTotalCount}
              </b>
            </Typography>
          </CommonGrid2>
        ))}
      </CommonGrid2>
    </div>
  );
};
export default CriticalityScore;
