import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { Grid2, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import CommonButton from "../../../../component/Button";
import {
  CommonGrid2,
  DotColor,
  StatusChange,
} from "../../../common-components/StyledComponents";
import { SelectCreateCard } from "../../style";
import JobTable from "../../Table";

const SelectCreationModel = ({
  selectCreateData,
  headerData,
  handleContinue,
}) => {
  const sortedData = [
    {
      id: "1000",
      title: "Product Manager",
      priority: "Low",
      team: "Team 1",
      ready: "90%",
      owner: "Olivia Rhye",
    },
    {
      id: "1000",
      title: "HR Manager",
      priority: "Medium",
      team: "Team 2",
      ready: "80%",
      owner: "Lana Steiner",
    },
    {
      id: "1000",
      title: "UI Designer",
      priority: "High",
      team: "Team 3",
      ready: "70%",
      owner: "Orlando Diggs",
    },
  ];

  const sortItem = ["Priority", "Ready"];
  const handleTableBodyData = (row, index) => {
    return (
      <TableRow key={row.id + index}>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.title}</TableCell>
        <TableCell>
          <StatusChange>{row.team}</StatusChange>
        </TableCell>
        <TableCell>
          <StatusChange>
            <DotColor
              color={
                row.priority === "Low"
                  ? "green"
                  : row.priority === "Medium"
                  ? "orange"
                  : "#667085"
              }
            ></DotColor>
            {row.priority}
          </StatusChange>
        </TableCell>
        <TableCell>{row.ready}</TableCell>
        <TableCell>{row.owner}</TableCell>
        <TableCell>
          <MoreVertOutlinedIcon style={{ cursor: "pointer" }} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Grid2 container spacing={3}>
      {selectCreateData.map((item) => (
        <CommonGrid2
          item
          size={{ xs: 6, md: 6, sm: 12 }}
          flexDirection={"column"}
          display={"flex"}
          gap={"1rem"}
        >
          <SelectCreateCard>
            <Typography variant="h5">{item.name}</Typography>
            <Typography variant="p" fontSize={"15px"} fontWeight={500}>
              {item.description}
            </Typography>
            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              value="Continue"
              width="max-content"
              onClick={() => handleContinue(item)}
            />
          </SelectCreateCard>
        </CommonGrid2>
      ))}
      <JobTable
        headerData={headerData}
        sortedData={sortedData}
        handleTableBodyData={handleTableBodyData}
        sortItem={sortItem}
      />
    </Grid2>
  );
};
export default SelectCreationModel;
