import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import AlertCircleIco from "../../../../assets/icons/AlertCircleIco";
import TickIcon from "../../../../assets/icons/TickIcon";
import CommonButton from "../../../../component/Button";
import SnackBar from "../../../../component/SnackBar";
import { HandleSkeletion, hexToRgba } from "../../../../utils/constants";
import {
  CommonGrid2,
  StyledFlexRowView,
} from "../../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../../portal/quick-match/components/StyledComponent";

const Result = ({
  jobData,
  isLoading,
  fetchJobsList,
  snackbarState,
  handleCloseSnackbar,
}) => {
  const job_id = jobData?.job_details?.job_data;

  return (
    <CommonGrid2
      container
      marginTop={"2rem"}
      spacing={3}
      flexDirection={"column"}
    >
      <Typography variant="h4" margin={"15px 0px"} fontWeight={600}>
        {"Results"}
      </Typography>

      <CommonGrid2
        spacing={3}
        container
        flexWrap={"nowrap"}
        alignItems={"center"}
      >
        <CommonGrid2 width={"200px"}>
          <Box
            position="relative"
            display="inline-flex"
            borderRadius="50%"
            padding="0.9rem"
            border="1px solid #EAECF0"
          >
            <CircularProgress
              variant="determinate"
              value={100}
              size={160}
              thickness={5}
              sx={{
                color: "#EAECF0",
              }}
            />

            <CircularProgress
              variant="determinate"
              value={jobData?.job_details?.score}
              size={160}
              thickness={5}
              sx={{
                color: "#66bb6a",
                position: "absolute",
                "& .MuiCircularProgress-circle": {
                  strokeLinecap: "round",
                },
              }}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="div" component="div" color="#475467">
                {jobData?.job_details?.score_label}
              </Typography>
              <Typography
                variant="h2"
                component="div"
                fontWeight="bold"
                color="#101828"
              >
                {`${Math.round(jobData?.job_details?.score)}%`}
              </Typography>
            </Box>
          </Box>
        </CommonGrid2>

        <CommonGrid2
          gap={isLoading ? "0px" : "1rem"}
          display={"flex"}
          flexDirection={"column"}
          width={"calc(100% - 245px)"}
        >
          {isLoading ? (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <HandleSkeletion height={80} key={index} />
              ))}
            </>
          ) : (
            <>
              {jobData?.job_details?.insights?.jd_recruiter_cheat_sheet && (
                <CommonGrid2
                  container
                  border={"1px solid #EAECF0"}
                  borderRadius={"10px"}
                  padding={"12px"}
                  boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
                  gap={"10px"}
                >
                  <StyledSpaceBetweenBox width={"100%"}>
                    <StyledFlexRowView>
                      <div
                        style={{
                          background: hexToRgba("#DCFAE6", 0.6),
                          borderRadius: "50%",
                          padding: "5px 10px",
                        }}
                      >
                        <TickIcon color={"green"} />
                      </div>

                      <ul>
                        {jobData?.job_details?.insights?.jd_recruiter_cheat_sheet.map(
                          (listItem) => (
                            <li style={{ fontSize: "17px", color: "#667085" }}>
                              {listItem}
                            </li>
                          )
                        )}
                      </ul>
                    </StyledFlexRowView>
                  </StyledSpaceBetweenBox>
                </CommonGrid2>
              )}
              {jobData?.job_details?.insights?.insights?.map((item, index) => (
                <CommonGrid2
                  key={index}
                  container
                  border={"1px solid #EAECF0"}
                  borderRadius={"10px"}
                  padding={"12px"}
                  boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
                  gap={"10px"}
                >
                  <StyledSpaceBetweenBox
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <StyledFlexRowView>
                      <div
                        style={{
                          background: item.fix
                            ? "unset"
                            : hexToRgba("#DCFAE6", 0.6),
                          borderRadius: "50%",
                          padding: "5px 10px",
                          ...(item.fix && {
                            display: "flex",
                            alignItems: "center",
                          }),
                        }}
                      >
                        {item.fix ? (
                          <AlertCircleIco />
                        ) : (
                          <TickIcon color={"green"} />
                        )}
                      </div>

                      <Typography
                        variant="h4"
                        fontWeight={500}
                        color="#667085"
                        fontSize={17}
                      >
                        {item.content}
                      </Typography>
                    </StyledFlexRowView>
                    {item.fix && (
                      <CommonButton
                        variant="outlined"
                        color="#344054"
                        border="#D0D5DD"
                        fontWeight="600"
                        onClick={() => fetchJobsList(job_id)}
                        value={item?.value ? item?.value : "Fix"}
                        style={{
                          marginLeft: "auto",
                        }}
                      />
                    )}
                  </StyledSpaceBetweenBox>
                </CommonGrid2>
              ))}
            </>
          )}
        </CommonGrid2>
      </CommonGrid2>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseSnackbar}
      />
    </CommonGrid2>
  );
};

export default Result;
