import { Grid2 } from "@mui/material";
import React from "react";
import { StyledFlexColumnView } from "../../../common-components/StyledComponents";
import Attributes from "./Attributes";

function Skills({ skillData, isLoading }) {
  return (
    <StyledFlexColumnView>
      <Grid2 container spacing={4}>
        <Grid2 size={6}>
          <Attributes
            title={"Hard Skills"}
            data={skillData?.hard_skills || []}
            isLoading={isLoading}
          />
        </Grid2>
        <Grid2 size={6}>
          <Attributes
            title={"Soft Skills"}
            data={skillData?.soft_skills || []}
            isLoading={isLoading}
          />
        </Grid2>
        <Grid2 size={6}>
          <Attributes
            title={"Tools"}
            data={skillData?.tools || []}
            isLoading={isLoading}
          />
        </Grid2>
      </Grid2>
    </StyledFlexColumnView>
  );
}

export default Skills;
