const ScoringSummary = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33398 11.3333V11.2332C1.33398 11.0222 1.33398 10.9167 1.35012 10.8139C1.36445 10.7227 1.38823 10.6332 1.42106 10.5468C1.45804 10.4496 1.51038 10.358 1.61507 10.1748L4.00065 6M1.33398 11.3333C1.33398 12.8061 2.52789 14 4.00065 14C5.47341 14 6.66732 12.8061 6.66732 11.3333M1.33398 11.3333V11.2C1.33398 11.0133 1.33398 10.92 1.37032 10.8487C1.40227 10.7859 1.45327 10.735 1.51599 10.703C1.58729 10.6667 1.68063 10.6667 1.86732 10.6667H6.13398C6.32067 10.6667 6.41401 10.6667 6.48531 10.703C6.54804 10.735 6.59903 10.7859 6.63099 10.8487C6.66732 10.92 6.66732 11.0133 6.66732 11.2V11.3333M4.00065 6L6.38624 10.1748C6.49092 10.358 6.54326 10.4496 6.58024 10.5468C6.61308 10.6332 6.63685 10.7227 6.65118 10.8139C6.66732 10.9167 6.66732 11.0222 6.66732 11.2332V11.3333M4.00065 6L12.0007 4.66667M9.33398 10V9.89987C9.33398 9.68888 9.33398 9.58338 9.35012 9.4806C9.36445 9.38934 9.38823 9.29982 9.42106 9.21348C9.45804 9.11623 9.51038 9.02463 9.61507 8.84144L12.0007 4.66667M9.33398 10C9.33398 11.4728 10.5279 12.6667 12.0007 12.6667C13.4734 12.6667 14.6673 11.4728 14.6673 10M9.33398 10V9.86667C9.33398 9.67998 9.33398 9.58664 9.37032 9.51534C9.40227 9.45262 9.45327 9.40162 9.51599 9.36966C9.58729 9.33333 9.68063 9.33333 9.86732 9.33333H14.134C14.3207 9.33333 14.414 9.33333 14.4853 9.36966C14.548 9.40162 14.599 9.45262 14.631 9.51534C14.6673 9.58664 14.6673 9.67998 14.6673 9.86667V10M12.0007 4.66667L14.3862 8.84144C14.4909 9.02463 14.5433 9.11623 14.5802 9.21348C14.6131 9.29982 14.6369 9.38934 14.6512 9.4806C14.6673 9.58338 14.6673 9.68888 14.6673 9.89987V10M8.00065 2V5.33333"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default ScoringSummary;
