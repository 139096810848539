import {
    Autocomplete,
    Grid2,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import ChevronDownIco from "../../../assets/icons/ChevronDownIco";
import CommonTextField from "../../../component/Input";
import { StyledFlexColumnView } from "../../common-components/StyledComponents";

function Information() {

    const primaryLocation = [
        { label: "San Francisco, CA", flag: "🇺🇸" },
        { label: "New York, NY", flag: "🇺🇸" },
        { label: "Toronto, ON", flag: "🇨🇦" },
    ];


    return (
        <Grid2 container spacing={3}>
            <Grid2 size={6}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Full Name"}
                    </Typography>
                    <TextField
                        fullWidth
                        // defaultValue={"Zahir"}
                        value={"Zahir"}
                        // placeholder="Enter Email"
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={6}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Avatar"}
                    </Typography>
                    <CommonTextField
                        readOnly={true}
                        padding="10px"
                        // placeholder={"Attach Resume"}
                        sx={{
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={6}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Company Name"}
                    </Typography>
                    <TextField
                        fullWidth
                        value={"Product Designer"}
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={6}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Title"}
                    </Typography>
                    <TextField
                        fullWidth
                        value={"Product Designer"}
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
            <Grid2 size={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#344054",
                            marginBottom: "8px",
                        }}
                    >
                        {"Timezone"}
                    </Typography>
                    <Autocomplete
                        disablePortal
                        options={primaryLocation}
                        getOptionLabel={(option) => option.label}
                        defaultValue={primaryLocation[0]}
                        renderOption={(props, option) => (
                            <li {...props} style={{ display: "flex", alignItems: "center" }}>
                                <span
                                    style={{
                                        marginRight: "8px",
                                        fontSize: "18px",
                                        borderRadius: "50%",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "24px",
                                        height: "24px",
                                        backgroundColor: "#f5f5f5",
                                    }}
                                >
                                    {option.flag}
                                </span>
                                {option.label}
                            </li>
                        )}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "5px",
                                border: "1px solid #D0D5DD",
                                alignItems: "center",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            <span
                                                style={{
                                                    marginRight: "8px",
                                                    fontSize: "18px",
                                                    borderRadius: "50%",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "24px",
                                                    height: "24px",
                                                    backgroundColor: "#f5f5f5", // Optional background
                                                }}
                                            >
                                                {primaryLocation[0].flag}
                                            </span>
                                            {params.InputProps.startAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        popupIcon={
                            <IconButton sx={{ padding: 0 }}>
                                <ChevronDownIco />
                            </IconButton>
                        }
                    />
                </div>
            </Grid2>
            <Grid2 size={6}>
                <StyledFlexColumnView>
                    <Typography
                        sx={{ fontWeight: 500, fontSize: "14px", color: "#344054" }}
                    >
                        {"Company Address"}
                    </Typography>
                    <TextField
                        fullWidth
                        value="hello@zahirmays.fyi"
                        variant="outlined"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D0D5DD",
                            },
                            "& .MuiInputBase-input": {
                                padding: "12px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "#667085",
                                opacity: 1,
                                fontSize: "16px",
                                fontWeight: 400,
                            },
                        }}
                    />
                </StyledFlexColumnView>
            </Grid2>
        </Grid2>
    );
}

export default Information;
