function AlertCircleIco() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="#FEF0C7"
      />
      <g clip-path="url(#clip0_189_26726)">
        <path
          d="M12.0007 9.33398V12.0007M12.0007 14.6673H12.0073M18.6673 12.0007C18.6673 15.6826 15.6826 18.6673 12.0007 18.6673C8.31875 18.6673 5.33398 15.6826 5.33398 12.0007C5.33398 8.31875 8.31875 5.33398 12.0007 5.33398C15.6826 5.33398 18.6673 8.31875 18.6673 12.0007Z"
          stroke="#DC6803"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_189_26726">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AlertCircleIco;
