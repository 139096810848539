import React from "react";
import CommonCard from "../../common-card.js/index.js";
import EditIndex from "../../common-card.js/Edit-Index.js";

const AddtionalInfomation = ({
  SecondaryViewData,
  alignment,
  autoCompleteData,
  isLoading,
}) => {
  return (
    <>
      {alignment === "View" ? (
        <CommonCard viewData={SecondaryViewData.data} loading={isLoading} />
      ) : (
        <EditIndex
          editData={SecondaryViewData.data}
          autoCompleteData={autoCompleteData}
          type={SecondaryViewData.type}
          loading={isLoading}
        />
      )}
    </>
  );
};

export default AddtionalInfomation;
