import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid2,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DotsGrid from "../../../../assets/icons/DotsGrid";
import DeleteIco from "../../../../assets/icons/DeleteIco";
import { StyledFlexRowView } from "../../../common-components/StyledComponents";
import CommonButton from "../../../../component/Button";
import { CustomPaper } from "../../../resume-intelligence/create-candidate-resume/components/Style";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function SeededPreScreen({ alignment, preScreen }) {
  const [questions, setQuestions] = useState(preScreen);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }

    const reorderedQuestions = Array.from(questions);
    const [removed] = reorderedQuestions.splice(source.index, 1);
    reorderedQuestions.splice(destination.index, 0, removed);

    setQuestions(reorderedQuestions);
  };
  return (
    <Grid2 container spacing={3}>
      <Grid2 item size={12}>
        <Typography fontSize={"16px"} fontWeight={500} color={"#101828"}>
          {"Seeded Pre-Screen Questions"}
        </Typography>
      </Grid2>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-questions">
          {(provided) => (
            <Grid2
              container
              spacing={2.5}
              size={12}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {questions.map((question, index) => (
                <Draggable
                  draggableId={`drag-${question.id}`}
                  index={index}
                  key={question.id}
                >
                  {(provided) => (
                    <Grid2
                      item
                      size={12}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <StyledFlexRowView gap="15px">
                        <CustomPaper
                          variant="outlined"
                          width="100%"
                          padding={"10px 12px"}
                          borderRadius="8px"
                        >
                          <Typography
                            fontSize={"16px"}
                            fontWeight={500}
                            color={"#667085"}
                          >
                            {question?.text}
                          </Typography>
                        </CustomPaper>

                        <IconButton
                          size="small"
                          disabled={alignment === "View"}
                          sx={{
                            border: "1px solid #D0D5DD",
                            borderRadius: "7px",
                            padding: "10px",
                            opacity: alignment === "View" ? 0.5 : 1,
                          }}
                          {...provided.dragHandleProps}
                        >
                          <DotsGrid />
                        </IconButton>

                        <IconButton
                          size="small"
                          disabled={alignment === "View"}
                          sx={{
                            border: "1px solid #D0D5DD",
                            borderRadius: "7px",
                            padding: "10px",
                            opacity: alignment === "View" ? 0.5 : 1,
                          }}
                        >
                          <DeleteIco />
                        </IconButton>
                      </StyledFlexRowView>
                    </Grid2>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
            </Grid2>
          )}
        </Droppable>
      </DragDropContext>
      {alignment !== "View" && (
        <Grid2 item size={12}>
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value={"Add Questions"}
            padding="10px 20px"
          />
        </Grid2>
      )}
    </Grid2>
  );
}

export default SeededPreScreen;
