import {
  Avatar,
  Grid2,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonAccordion from "../common-components/CommonAccordion";
import {
  CommonGrid2,
  DotColor,
  StatusChange,
} from "../common-components/StyledComponents";
import BoxEditIco from "../../assets/icons/BoxEditIco";
import EyeIco from "../../assets/icons/EyeIco";
import SnackBar from "../../component/SnackBar";
import {
  RoleCall,
  TeamGetCall,
  UserManagementRoleDelete,
  UserManagementRoleManage,
} from "../../redux/action/UserManagement";
import ManagePermission from "./components/ManagePermission";
import RemoveMember from "./components/RemoveMember";
import Team from "./components/Team";

export default function UserManagement() {
  const navigate = useNavigate();
  const [teamList, setTeamList] = useState({
    data: [],
    loading: true,
  });
  const [checkSelected, setCheckSelected] = useState({
    count: 0,
    selected: [],
    managePermission: [],
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [dialog, setDialog] = useState({
    removeOpen: false,
    manageOpen: false,
    disabled: false,
    removeLoading: false,
    manageLoading: false,
  });

  const handleOpenDialog = (condition) => {
    setDialog((prev) => ({
      ...prev,
      removeOpen: condition === "remove" ? true : false,
      manageOpen: condition === "manage" ? true : false,
    }));
  };
  const handleCloseRemoveMember = () => {
    setDialog({
      removeOpen: false,
      manageOpen: false,
      disabled: false,
      removeLoading: false,
      manageLoading: false,
    });
  };

  const handleAddMemberClick = () => {
    navigate("/user-management/user-add");
  };

  const handleFetchCall = async () => {
    setTeamList((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const data = await TeamGetCall();
      const dataRole = await RoleCall();
      setTeamList((prev) => ({
        ...prev,
        data: data?.data,
        loading: false,
        role: dataRole?.roles,
      }));
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setTeamList((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    handleFetchCall();
  }, []);

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleIconClick = (condition, row) => {
    if (condition === "view") {
      navigate(`/user-management/user-add?id=${row.id}&show=view`);
    }
    if (condition === "edit") {
      navigate(`/user-management/user-add?id=${row.id}`);
    }
  };

  const handleTeamTableBodyData = (row) => (
    <>
      <TableCell>
        <CommonGrid2 container alignItems="center" spacing={2}>
          <Avatar
            alt={row.full_name}
            src={row.full_name}
            sx={{ fontWeight: "700" }}
          />
          {row.full_name}
        </CommonGrid2>
      </TableCell>
      <TableCell>{row.title || "-"}</TableCell>
      <TableCell>
        <StatusChange>
          <DotColor
            color={
              row.role_name === "Base"
                ? "#667085"
                : row.role_name === "Manager"
                ? "orange"
                : row.role_name === "Admin"
                ? "green"
                : "#667085"
            }
          />
          {row.role_name}
        </StatusChange>
      </TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>
        <CommonGrid2
          display={"flex"}
          gap={"1rem"}
          justifyContent={"space-around"}
        >
          <Tooltip arrow title="View" placement="top">
            <IconButton
              onClick={() => handleIconClick("view", row)}
              style={{ cursor: "pointer" }}
            >
              <EyeIco />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Edit" placement="top">
            <IconButton
              onClick={() => handleIconClick("edit", row)}
              style={{ cursor: "pointer" }}
            >
              <BoxEditIco />
            </IconButton>
          </Tooltip>
        </CommonGrid2>
      </TableCell>
    </>
  );

  const [headerData, setHeaderData] = useState([
    { name: "User", width: "22%" },
    { name: "Position", width: "22%" },
    { name: "Permissions", width: "22%" },
    { name: "Email Address", width: "22%" },
    { width: "10%" },
  ]);

  const handleSingleSelect = (row) => {
    setCheckSelected((prev) => {
      const isSelected = prev.selected.some((item) => item.id === row.id);
      const updatedSelected = isSelected
        ? prev.selected.filter((item) => item.id !== row.id)
        : [...prev.selected, { ...row, id: row.id }];

      const updatedCount = updatedSelected.length;

      return {
        selected: updatedSelected,
        count: updatedCount,
        managePermission: updatedSelected,
      };
    });
  };

  const handleSelectAll = () => {
    const allIds = teamList.data.map((row) => ({
      ...row,
      id: row.id,
    }));

    if (
      teamList.data.every((row) =>
        checkSelected.selected.some((item) => item.id === row.id)
      )
    ) {
      const deselectedIds = checkSelected.selected.filter(
        (item) => !allIds.some((row) => row.id === item.id)
      );
      setCheckSelected({
        selected: deselectedIds,
        count: deselectedIds.length,
        managePermission: deselectedIds,
      });
    } else {
      const updatedSelected = [...checkSelected.selected, ...allIds];
      const uniqueSelected = Array.from(
        new Set(updatedSelected.map((item) => item.id))
      ).map((id) => updatedSelected.find((item) => item.id === id));

      setCheckSelected({
        selected: uniqueSelected,
        count: uniqueSelected.length,
        managePermission: uniqueSelected,
      });
    }
  };

  const handleChangeStatus = (e, index) => {
    setCheckSelected((prev) => ({
      ...prev,
      managePermission: prev.managePermission.map((item, i) =>
        i === index
          ? {
              ...item,
              role_id: e.target.value,
              role_name: teamList.role.find(
                (role) => role.id === e.target.value
              )?.name,
            }
          : item
      ),
    }));
  };

  const handleRemoveMember = async () => {
    try {
      setDialog((prev) => ({
        ...prev,
        disabled: true,
        removeLoading: true,
      }));
      let payload = {
        user_ids: checkSelected.selected.map((item) => item.id),
      };
      const getData = await UserManagementRoleDelete(payload);
      await handleFetchCall();
      handleCloseRemoveMember();
      setCheckSelected({
        count: 0,
        selected: [],
        managePermission: [],
      });
      setSnackbarState({
        open: true,
        severity: "success",
        message: getData?.message,
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setDialog((prev) => ({
        ...prev,
        disabled: false,
        removeLoading: false,
      }));
    }
  };

  const handleManageMember = async () => {
    if (
      JSON.stringify(checkSelected.managePermission) ===
      JSON.stringify(checkSelected.selected)
    ) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: "Change any one of the values and update it",
      });
    } else {
      try {
        setDialog((prev) => ({
          ...prev,
          disabled: true,
          manageLoading: true,
        }));
        let payload = {
          user_roles: checkSelected.managePermission.map((item) => ({
            user_id: item.id,
            role_id: item.role_id,
          })),
        };
        let data = await UserManagementRoleManage(payload);
        await handleFetchCall();
        handleCloseRemoveMember();
        setCheckSelected({
          count: 0,
          selected: [],
          managePermission: [],
        });
        setSnackbarState({
          open: true,
          severity: "success",
          message: data?.message,
        });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error.message,
        });
        setDialog((prev) => ({
          ...prev,
          disabled: false,
          manageLoading: false,
        }));
      }
    }
  };

  return (
    <Grid2 container spacing={4}>
      <Grid2 size={12}>
        <Typography fontWeight={600} color={"#101828"} fontSize={"24px"}>
          User Management
        </Typography>
      </Grid2>

      <Grid2 size={12}>
        <CommonAccordion
          title={"Team"}
          accordionButton
          buttonTitle={"Add Member"}
          children={
            <Team
              teamList={teamList}
              handleTeamTableBodyData={handleTeamTableBodyData}
              headerData={headerData}
              checkSelected={checkSelected}
              handleSingleSelect={handleSingleSelect}
              handleSelectAll={handleSelectAll}
              handleOpenDialog={handleOpenDialog}
            />
          }
          onButtonClick={handleAddMemberClick}
          defaultOpen
        />
      </Grid2>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <RemoveMember
        open={dialog.removeOpen}
        onClose={handleCloseRemoveMember}
        onApply={handleRemoveMember}
        selectedUser={checkSelected.selected}
        dialog={dialog}
      />
      <ManagePermission
        open={dialog.manageOpen}
        onClose={handleCloseRemoveMember}
        onApply={handleManageMember}
        options={teamList.role}
        selectedUser={checkSelected.managePermission}
        handleChangeStatus={handleChangeStatus}
        dialog={dialog}
      />
      {/* <Grid2 size={12}>
        <Divider />
      </Grid2>
      <Grid2 size={4}>
        <StyledFlexColumnView>
          <Typography fontWeight={500} fontSize={"14px"} color={"#344054"}>
            {"Session Duration"}
          </Typography>
          <CommonTextField value={"60"} padding={"12px"} />
          <Typography fontWeight={500} fontSize={"14px"} color={"#344054"}>
            {"Log out after given time in minutes."}
          </Typography>
        </StyledFlexColumnView>
      </Grid2> */}
    </Grid2>
  );
}
