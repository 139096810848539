import React from "react";
import {
  CommonGrid2,
  DotColor,
  StatusChange,
} from "../../common-components/StyledComponents";
import { Tooltip, Typography } from "@mui/material";
import { PrimaryDiv, SecondaryShift } from "../style";
import { HandleSkeletion } from "../../../utils/constants";

const CommonCard = ({
  viewData,
  loading = false,
  rowSize = false,
  viewMarginTop,
}) => {
  return (
    <CommonGrid2
      container
      columnSpacing={2}
      rowSpacing={loading ? 0 : 2}
      marginTop={viewMarginTop}
    >
      {viewData?.map((item, index) => {
        return (
          <CommonGrid2
            item
            size={{
              xs: 12,
              md: rowSize ? 6 : 4,
              sm: 6,
            }}
            key={index}
          >
            {loading ? (
              <HandleSkeletion height={100} />
            ) : item.component === "split-part" ? (
              <CommonGrid2 container spacing={2}>
                {item.data.map((innerItem) => (
                  <CommonGrid2
                    size={6}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"8px"}
                  >
                    <PrimaryDiv>
                      <Tooltip arrow title={innerItem.title} placement="top">
                        <Typography
                          variant="p"
                          color="#344054"
                          fontSize="16px"
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                        >
                          {innerItem.title}
                        </Typography>
                      </Tooltip>
                      <Typography
                        variant="h4"
                        fontWeight={600}
                        fontSize={"15px"}
                        display="flex"
                        alignItems="center"
                        gap="10px"
                        style={{ wordBreak: "break-word" }}
                      >
                        {innerItem.name}
                      </Typography>
                    </PrimaryDiv>
                  </CommonGrid2>
                ))}
              </CommonGrid2>
            ) : (
              <PrimaryDiv>
                <Typography
                  variant="p"
                  color="#475467"
                  fontSize="16px"
                  display={"flex"}
                  gap={"10px"}
                >
                  {item.title}
                  {item.iconVisible && item.content}
                </Typography>
                {item.name && (
                  <Typography
                    variant="h4"
                    fontWeight={600}
                    fontSize={"18px"}
                    display="flex"
                    alignItems="center"
                    gap="10px"
                    style={{ wordBreak: "break-word" }}
                  >
                    {item.image && <img src={item.image} />}
                    {item.name}
                  </Typography>
                )}
                {item.shift && (
                  <SecondaryShift>
                    {item.shift.map((item, index) => (
                      <StatusChange key={index}>
                        <DotColor
                          color={
                            item === "Morning" || item === "Day"
                              ? "green"
                              : "#101828"
                          }
                        ></DotColor>
                        {item}
                      </StatusChange>
                    ))}
                  </SecondaryShift>
                )}
                {item.additional && (
                  <SecondaryShift>
                    {item.additional.map((subItem, index) => {
                      return subItem && subItem?.image ? (
                        <StatusChange key={index}>
                          <img src={subItem.image} width={20} />
                          {subItem.name}
                        </StatusChange>
                      ) : (
                        <StatusChange key={index}>{subItem.name}</StatusChange>
                      );
                    })}
                  </SecondaryShift>
                )}
                {item.multi_additional && (
                  <SecondaryShift>
                    {item.multi_additional.map((subItem, index) => {
                      return (
                        <StatusChange
                          key={index}
                          border={
                            subItem.name === "inferred"
                              ? "1px solid rgb(0, 112, 242)"
                              : subItem.name === "user_added"
                              ? "1px solid rgb(7, 148, 85)"
                              : "1px solid rgb(30, 31, 33)"
                          }
                          color={
                            subItem.name === "inferred"
                              ? "rgb(0, 112, 242)"
                              : subItem.name === "user_added"
                              ? "rgb(7, 148, 85)"
                              : "rgb(30, 31, 33)"
                          }
                          background={
                            subItem.name === "inferred"
                              ? "rgb(235, 248, 255)"
                              : subItem.name === "user_added"
                              ? "rgb(219, 250, 230)"
                              : "rgba(0, 0, 0, 0.08)"
                          }
                        >
                          {subItem.content}
                        </StatusChange>
                      );
                    })}
                  </SecondaryShift>
                )}
              </PrimaryDiv>
            )}
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
};
export default CommonCard;
